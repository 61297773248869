import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SimpleTableFetching } from 'fetching/DatatableFetching'
import { getStatusColorClass } from 'utils';
import { TTP_API_URL, TTP_HOME_URL } from 'Config';
import _ from 'i18n';
import ResendForm from './ResendForm';
import Viewer from "common/Viewer";

@connect((store) => {
  return {
    token: store.auth.token,
    items: store.campaigns.stats.recipients.list.items,
    fetching: store.campaigns.stats.recipients.list.fetching,
  };
})
export default class Recipients extends Component {

  constructor(props) {
    super(props);
    this.state = { showResendForm: false, recipient: [], showPreview: false };
  }

  openResendForm = (recipient) => {
    this.setState({ showResendForm: true, recipient });
  }

  togglePreview = (recipient = []) => {
    this.setState(prevState => ({ recipient, showPreview: !prevState.showPreview }));
  }

  render() {
    const { items, token, headerColor, fetching } = this.props;
    const { showResendForm, recipient, showPreview } = this.state;
    const statuses = {
      'SUCCESS': "SENT",
      'FAILED': "undelivered",
      'REFUSED': "undelivered"
    };

    return (<div className="row align-center columns">
      <div className="ttp-datatable small-12 small-centered columns">
        <div className="row ttp-datatable__header" style={{ backgroundColor: headerColor }}>
          <div className="small-4">{_('name')}</div>
          <div className="small-3">{_('email')}</div>
          <div className="small-1">{_('open')}</div>
          <div className="small-1">{_('complaint')}</div>
          <div className="small-1">{_('bounced')}</div>
          <div className="small-2">{_('status')}</div>
        </div>

        {(fetching) ? <SimpleTableFetching /> : items.map(item => {

          let name = "___ ___";
          let profileLink = null;
          if (['CONTACT', 'PROSPECT'].indexOf(item.recipientType) > -1) {
            profileLink = `${TTP_HOME_URL}${item.recipientType == "CONTACT" ? "member" : "prospect"}/${item.recipientId}/edit`;
          }

          if (item.firstName || item.lastName) {
            name = `${item.firstName} ${item.lastName ? item.lastName.toUpperCase() : ""}`;
          }

          return <div key={`recipient-${item.id}`} className="row ttp-datatable__row">
            <div className="small-4">{name}</div>
            <div className="small-3">{item.email}</div>
            <div className="small-1">{item.openCount}</div>
            <div className="small-1 text-uppercase">{item.complaintAt == null ? _('no') : _('yes')}</div>
            <div className="small-1 text-uppercase">{(item.bounceAt == null) ? _('no') : _('yes')}</div>
            <div className="small-2">
              <span className="send-status">
                <span className={`dot bg-${getStatusColorClass(item.sentStatus.toUpperCase())}`} ></span>
                {statuses[item.sentStatus] ? _(statuses[item.sentStatus]) : _(item.sentStatus)}
              </span>
              <div className="drop-down-option float-right">
                <span className="icon-options-vertical"></span>
                <ul className="drop-down-option__left">
                  <li onClick={() => this.openResendForm(item)}>{_('resend')}</li>
                  <li onClick={() => this.togglePreview(item)}>{_('preview')}</li>
                  {profileLink && <li><a href={profileLink} target="__blank">{_('view profile')}</a></li>}
                </ul>
              </div>
            </div>
          </div>
        })
        }
      </div>
      {showResendForm && <ResendForm recipient={recipient} onClose={() => this.setState({ showResendForm: false })} />}
      {recipient && <Viewer
        isOpen={showPreview}
        onClose={this.togglePreview}
        url={`${TTP_API_URL}/mailing/web-version/${recipient.id}?access_token=${token}`}
      />}
    </div>);
  }

}