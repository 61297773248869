import React, {Component} from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import _ from 'i18n';
import NoteItem from '../../../campaign/note/NoteItem';
import ManualSMSRecipients from "../recipients/ManualSMSRecipients";

@connect((store) => {
  return {
    recipientsCount: store.campaigns.recipients.recipientsTotalCount,
    manualSmsRecipients: store.currentCampaign.sms.manualSmsRecipients,
  }
})
export default class Notes extends Component {
  renderWarningData(){



    const {recipientsCount, campaignNameValid, smsMessageValid, hasEnoughCredit, onToggleSideBar,  manualSmsRecipients, recipientType} = this.props;
    let alerts = [];
    let index = 1;
    if(!campaignNameValid){
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={()=>onToggleSideBar(null)} text='invalid_campaign_name_warning' index={index++} />);
    }
    if(!smsMessageValid){
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={()=>onToggleSideBar(null)} text='sms_message_warning' index={index++} />);
    }
    if(!hasEnoughCredit){
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={()=>onToggleSideBar(null)} text="not_enough_credit_warning" index={index++} />);
    }
    if((recipientType == "MANUAL" && manualSmsRecipients.length<1) || (recipientType != "MANUAL" && recipientsCount<1)){
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={()=>onToggleSideBar("RECIPIENTS")} text="recipient_count_warning" index={index++} />);
    }
    if(alerts.length == 0){
      return null;
    }
    return <div className="row">
      <div className="columns small-12 sidebar__warning">
        <h5><i className="icon-left icon-pin"></i>Note </h5>
        <div className="notes">
          {alerts}
        </div>
      </div>
    </div>;
  }

  render() {
    const { onToggleSideBar } = this.props;
    return <div className="sidebar__actions">
              <div className="row">
                <div className="columns small-2">
                  <span className="sidebar__close icomoon icon-tt-close" onClick={()=>onToggleSideBar(null)} />
                </div>
              </div>
              {this.renderWarningData()}
            </div>;
  }
}
