import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import {SVG_CHECK_ICON, SVG_LOADING} from "../../services/svgIcones";

@connect()
export default class Controls extends Component {

  componentDidUpdate(prevProps, prevState) {
    const { updated,saveCallback } = this.props;

    if(updated) {
      setTimeout(saveCallback, 1000);
    }
  }

  handleCancel() {
    const { closeDiv, closeDivType, onCancel } = this.props;

    if(typeof onCancel === "function") {
      onCancel();
    }

    const clDiv = (closeDivType == "id") ? document.getElementById(closeDiv) :
        document.getElementsByClassName(closeDiv).item(0);
    if(clDiv){
      clDiv.click();
    }
  }

  handleOk () {
    const { dispatch, action } = this.props;
    dispatch(action());
  }

  renderBtnOK () {
    const { updating, updated, ok, action, successMessage, progressMessage } = this.props;
    if(updating) {
      return (
        <button className="btn primary">
          {progressMessage ? progressMessage : _('Processing')}
          <img
            src={"/img/icons/tail-spin.svg"}
            alt={""}
            className={"loading"}
          />
        </button>
      );
    }
    if(updated) {
      return (
        <button className="btn primary" onClick={() => this.handleCancel()}>
          {successMessage ? successMessage : _('Successfully updated!')}
          <span className="svg_check_icons">
          {SVG_CHECK_ICON}
          </span>
        </button>
      );
    }
    return <button className="btn primary" onClick={action}>{_(ok)}</button>
  }

  render () {
    const { label, controlClass} = this.props;

    return (
      <div id="controls" className={controlClass || ""}>
        <button className="btn secondary" onClick={this.handleCancel.bind(this)}>{_('cancel')}</button>
        <div className="controls__label">{label}</div>
        {this.renderBtnOK()}
      </div>
    );
  }
}
