import React from 'react';
import MyEbox from "./MyEBox";
import OtherEbox from "./OtherEBox";

const eboxMenu = (props) => {
    const {items, clients} = props;
    const allowAdminSearch = clients.some(client => client.authorisation.role == "ADMIN");
    return (
        <ul className="menu">
            <MyEbox />
            {items.indexOf('OTHER_EBOX') !== -1 && allowAdminSearch && <OtherEbox />}
        </ul>
    )
};

export default eboxMenu;