import React, { Component } from 'react';
import { connect } from 'react-redux';
import { persistClientSettings, fetchSesEmailsStatuses } from 'thunks';
import EmailItem from "./EmailItem";
import NotFound from '../../notFound/NotFound';
import { SVG_PLUS, SVG_MINUS, SVG_DELETE_ICON } from "../../../services/svgIcones";
import { setTTPDialogCustomData } from "../../../actions/actions/params";
import DIALOG_TYPES from "../../../constants/TTPDialog";
import _ from 'i18n';
import { onSuccess, onError } from '../../../services/utils';

@connect((store) => {
  return {
    mails: store.auth.currentSetting.verifiedEmails,
    sesEmailsFetched: store.params.sesEmails.fetched,
  };
}, dispatch => ({
  persistVerifiedEmails: verifiedEmails => dispatch(persistClientSettings({ verifiedEmails })),
  deleteConfirmation: (data) => dispatch(setTTPDialogCustomData(data)),
  fetchSesEmailsStatuses: () => dispatch(fetchSesEmailsStatuses()),
}))
export default class VerifiedEmails extends Component {

  state = {
    currentTab: "EMAILS",
    action: null,
    mainEmailIndex: 0
  };

  componentDidMount() {
    const { fetchSesEmailsStatuses, sesEmailsFetched, mails } = this.props;
    if (!sesEmailsFetched) {
      fetchSesEmailsStatuses()
    }
    this.setMainEmailIndex();
  }

  setMainEmailIndex = () => {
    const { mails } = this.props;
    let index = 0;
    if (mails && mails.length) {
      index = mails.findIndex(email => email.main === 1);
    }

    this.setState({ mainEmailIndex: index > 0 ? index : 0 });
  }

  setCurrentAction = action => this.setState(prevState => ({ action: (prevState.action === action) ? null : action }));

  saveEmail = (emails) => {
    let isMainThere = emails.find(element => element.main === 1);
    if (!isMainThere && emails.length) {
      emails[0].main = 1;
    }
    this.props.persistVerifiedEmails(emails).then(res => {
      onSuccess(res);
      this.setCurrentAction(null);
    }, err => onError(err));
  }

  componentDidUpdate(prevProps) {
    const { mails } = this.props;
    if (prevProps.mails !== mails) {
      this.setMainEmailIndex();
    }
  }

  handleDeleteEmail = (index, e) => {
    let { deleteConfirmation, mails } = this.props;
    const newEmails = [...mails];

    newEmails.splice(index, 1);
    deleteConfirmation({
      message: _('Are you sure you want to delete this email ?'),
      proceedBtnLabel: _('yes'),
      abortBtnLabel: _('cancel'),
      approvedAction: () => this.saveEmail(newEmails),
      title: "Confirmation of deletion",
      type: DIALOG_TYPES["ALERT"],
      svgIcon: SVG_DELETE_ICON,
    });
    e.stopPropagation();
  }

  handleSaveEmail = (data, index = null) => {
    const { mails } = this.props;
    const newEmails = [...mails];
    if (index === null) {
      newEmails.push(data);
    } else {
      newEmails[index] = data;
    }
    newEmails.forEach((email, index) => {
      newEmails[index] = { ...email, main: (index === this.state.mainEmailIndex) ? 1 : 0 }
    });

    this.saveEmail(newEmails)
  };

  handleChangeMain = (index) => this.setState({ mainEmailIndex: index });

  renderApprovalMails() {
    const { mails } = this.props;
    const { action, mainEmailIndex } = this.state;

    let items = [];
    if (mails && mails.length > 0) {
      mails.forEach((mail, index) => {
        const { email, name } = mail;

        items.push(
          <EmailItem
            key={index}
            email={email}
            main={(index === mainEmailIndex) ? 1 : 0}
            name={name}
            onSave={(data) => this.handleSaveEmail(data, index)}
            onDelete={(e) => this.handleDeleteEmail(index, e)}
            deleteState={action === REMOVE_ACTION}
            handleMainChange={() => this.handleChangeMain(index)}
            setMainEmailIndex={()=> this.setMainEmailIndex()}
            isMain={mail.main === 1}
          />)
      });
    }
    if (ADD_ACTION === action) {
      let newIndex = mails.length;
      items.push(<EmailItem
        key="empty-email"
        handleMainChange={() => this.handleChangeMain(newIndex)}
        main={(newIndex === mainEmailIndex) ? 1 : 0}
        showAction
        showButtons
        onCancel={() => this.setCurrentAction(null)}
        onSave={this.handleSaveEmail}
      />);
    }
    return (items.length > 0) ?
      <div className="row mail-list">{items}</div> :
      <NotFound mainClass="small-10" />;
  }

  render() {
    const { action } = this.state;

    return (
      <div>
        <div className="row minus-add__actions">
          <div className="row medium-11 title">
            <h3>{_("verified senders")}</h3>
            <p>{_("Manage your sending emails")}</p>
          </div>
          <div className="minus-add__actions-container">
            <span className={`${(REMOVE_ACTION == action) ? "minus__action" : "minus"}`} onClick={() => this.setCurrentAction(REMOVE_ACTION)}>{SVG_MINUS}</span>
            <span className="add" onClick={() => this.setCurrentAction(ADD_ACTION)}>{SVG_PLUS}</span>
          </div>
        </div>
        {this.renderApprovalMails()}
      </div>
    )
  }
}

const ADD_ACTION = "ADD";
const REMOVE_ACTION = "REMOVE";

