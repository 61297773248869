import React, {Component} from "react";
import PercentageChart from "./PercentageChart";
import {connect} from "react-redux";

@connect((store) => {
  return {
    dataAge: store.dashboard.dataAge,
  };
})
export default class AgeChart extends Component {
  render() {
    const {dataAge} = this.props;
    return (
      <PercentageChart dataBar={dataAge}/>
    );
  }
}
