import React, { useEffect } from "react";

import { Route, Switch } from "react-router-dom";

import HomePage from "home/HomePage";
import NotFound from "notFound/NotFound";
import ThemesListPage from "theme/ThemesListPage";
import StatsPage from "stats/StatsPage";
import ApprovalSettings from "approval/setting/ApprovalSettings";
import ApprovalResponse from "approval/feedback/ApprovalResponse";
import ThemeEditor from "theme/ThemeEditor";
import Settings from "settings/Settings";
import CampaignSMSPage from "sms/campaign/CampaignSMSPage";
import CampaignSMSListPage from "sms/CampaignSMSListPage";
import CampaignSMSStats from "sms/stats/CampaignSMSStats";
import SMSBillsListPage from "sms/bills/SMSBillsListPage";
import smsCreditValidationPage from "sms/bills/SMSCreditValidationPage";
import TypeSelector from "startup/TypeSelector";
import DragDropEditor from "editor/DragDropEditor";
import BlocksContainer from "blocks/BlocksContainer";
import GenericEmailPage from "generic-emailings/GenericEmailPage";
import TextModelForm from "../components/text-model/TextModelForm";
import GenericEmail from "generic-emailings/GenericEmail";
import MyEbox from "../components/eBox/MyEbox";
import OtherEbox from "../components/eBox/OtherEbox";
import DefaultSettings from "../components/settings/DefaultSettings";
import EmailComposer from "../components/eBox/widget/EmailComposer";
import CampaignPage from "../components/campaign/CampaignPage";
import CampaignListPage from "../components/campaign/list/CampaignListPage";
import Administration from "../components/settings/Administration";
import PrivateRoute from "./PrivateRoute";
import {
  ADMIN_ROLE,
  USER_ROLE,
  CAN_CREATE_EMAIL,
  CAN_CREATE_SMS,
  TTP_SUPERVISOR_ROLE,
  TTP_ADMIN_ROLE,
} from "./roles";
import CreateCampaignContainer from "../components/campaign/create/CreateCampaignContainer";
import MailingListsPage from "../components/MailingLists/MailingListsPage";
import ThemeCategoryList from "../components/settings/theme-category/ThemeCategoryList";
import DashboardPage from "../components/dashboard/DashboardPage";
import CommunityPage from "../components/community/CommunityPage";
import NewsletterHomePage from "../components/ua-newsletter/NewsletterHomePage";

const urls = {
  index: "/",
  notFound: "*",
  unauthorized: "/unauthorized",
};

const emailing_urls = {
  campaign_list: "/campaigns",
  campaign_type_selector: "/campaign/selector",
  campaign_form: "/campaign/:campaignId?",
  campaign_stats: "/stats/:campaignId",
  compose_email: "/compose-email",

  themes_edit: "/templates/:templateId",
  themes_list: "/templates",
  drag_drop_editor: "/drag-drop/:templateId?",
  blocks_feeds: "/blocks",

  mailing_lists: "/mailing-lists",
  list_subscribers: "/lists/:listId/subscribers",
  embed_form: "/lists/embed-form",

  settings: "/settings",
  approvers_settings: "/approvers-setting",
  theme_category: "/theme-category",
  approval_response: "/approval/:approvalId/:decision",
  send_ezine: "/ezine/:ezineId",
  administration: "/administration",

  //Generic emailing
  emails_list: "/emails",
  email: "/email/:settingId?",
  text_models_edit: "/text-model/:textModelId",
  default_settings: "/default-settings",

  //SENT NEWSLETTER CAMPAIGN
  sent_newsLetter_list: "/newsletter",

  //Statistics
  dashboard: "/dashboard",

  //UA newsletter
  newsletter_ua: "/newsletter-ua/:type",
};

const sms_urls = {
  sms_home: "/sms",
  sms_campaign_stats: "/stats-sms/:campaignSMSId",
  sms_campaign_form: "/campaign-sms/:campaignSMSId?",
  sms_campaign_list: "/smsCampaigns",
  sms_bills_list: "/sms_bills",
  sms_credit_validation: "/sms-credit-validation",
};

const ebox_urls = {
  e_box: "/e_box",
  other_ebox: "/other-ebox",
};

const notification_urls = {
  notification_list: "/notifications",
  notification_form: "/notification/:notificationId?",
};

const community_urls = {
  community_form: "/:lng?/community/:communityUrl?/:communityId?",
};

export const appsUrls = {
  EMAILING: emailing_urls,
  SMS: sms_urls,
  EBOX: ebox_urls,
  NOTIFICATION: notification_urls,
};

export const getRoutes = (store) => {
  const isAdminOfSomeCommunities = () => {
    let { auth } = store.getState();

    return (
      auth?.fetched &&
      auth?.user?.clients?.some(
        (client) => client.authorisation.role == "ADMIN",
      )
    );
  };

  const { auth } = store.getState();

  return (
    <Switch>
      <Route
        exact
        path={urls.index}
        component={
          auth?.user?.selectedOrganization?.emailingPreferences?.accessType ==
          "UA"
            ? () => <NotFound errorPageType="ACCESS_DENIED" />
            : HomePage
        }
      />

      <PrivateRoute
        path={emailing_urls.campaign_list}
        component={CampaignListPage}
        roles={[ADMIN_ROLE, USER_ROLE]}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.campaign_type_selector}
        component={TypeSelector}
        roles={ADMIN_ROLE}
        privilege={CAN_CREATE_EMAIL}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.campaign_form}
        component={CreateCampaignContainer}
        roles={ADMIN_ROLE}
        privilege={CAN_CREATE_EMAIL}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.send_ezine}
        component={CampaignPage}
        roles={ADMIN_ROLE}
        privilege={CAN_CREATE_EMAIL}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.campaign_stats}
        component={StatsPage}
        roles={[ADMIN_ROLE, USER_ROLE]}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.compose_email}
        component={EmailComposer}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.newsletter_ua}
        component={NewsletterHomePage}
        auth={auth}
      />

      {/* Templates */}
      <PrivateRoute
        path={emailing_urls.themes_edit}
        component={ThemeEditor}
        roles={ADMIN_ROLE}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.themes_list}
        component={ThemesListPage}
        roles={ADMIN_ROLE}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.drag_drop_editor}
        component={DragDropEditor}
        roles={ADMIN_ROLE}
        privilege={CAN_CREATE_EMAIL}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.blocks_feeds}
        component={BlocksContainer}
        roles={ADMIN_ROLE}
        privilege={CAN_CREATE_EMAIL}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.mailing_lists}
        component={MailingListsPage}
        roles={ADMIN_ROLE}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.dashboard}
        component={DashboardPage}
        roles={[ADMIN_ROLE, USER_ROLE]}
        auth={auth}
      />

      {/* Generic emails */}
      <PrivateRoute
        path={emailing_urls.emails_list}
        component={GenericEmailPage}
        roles={ADMIN_ROLE}
        auth={auth}
      />
      <PrivateRoute
        path={emailing_urls.email}
        component={GenericEmail}
        roles={ADMIN_ROLE}
        auth={auth}
      />
      <PrivateRoute
        path={emailing_urls.text_models_edit}
        component={TextModelForm}
        roles={ADMIN_ROLE}
        auth={auth}
      />
      <PrivateRoute
        path={emailing_urls.default_settings}
        component={DefaultSettings}
        roles={[ADMIN_ROLE]}
        auth={auth}
      />

      {/* Approval */}
      <PrivateRoute
        path={emailing_urls.settings}
        component={Settings}
        roles={ADMIN_ROLE}
        auth={auth}
      />
      <PrivateRoute
        path={emailing_urls.approvers_settings}
        component={ApprovalSettings}
        roles={[ADMIN_ROLE]}
        auth={auth}
      />
      <PrivateRoute
        path={emailing_urls.theme_category}
        component={ThemeCategoryList}
        roles={[ADMIN_ROLE]}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.approval_response}
        component={ApprovalResponse}
        auth={auth}
      />

      <PrivateRoute
        path={emailing_urls.administration}
        component={Administration}
        roles={[TTP_ADMIN_ROLE, TTP_SUPERVISOR_ROLE]}
        auth={auth}
      />

      {/* Ebox */}
      <PrivateRoute path={ebox_urls.e_box} component={MyEbox} auth={auth} />

      {isAdminOfSomeCommunities() ? (
        <Route
          path={ebox_urls.other_ebox}
          component={OtherEbox}
          roles={ADMIN_ROLE}
          auth={auth}
        />
      ) : (
        ""
      )}

      {/* SMS */}
      <Route path={sms_urls.sms_home} component={HomePage} />

      <PrivateRoute
        path={sms_urls.sms_campaign_form}
        component={CampaignSMSPage}
        roles={ADMIN_ROLE}
        auth={auth}
        privilege={CAN_CREATE_SMS}
      />

      <PrivateRoute
        path={sms_urls.sms_campaign_list}
        component={CampaignSMSListPage}
        roles={[ADMIN_ROLE, USER_ROLE]}
        auth={auth}
      />

      <PrivateRoute
        path={sms_urls.sms_campaign_stats}
        component={CampaignSMSStats}
        roles={[ADMIN_ROLE, USER_ROLE]}
        auth={auth}
      />

      <PrivateRoute
        path={sms_urls.sms_bills_list}
        component={SMSBillsListPage}
        roles={ADMIN_ROLE}
        auth={auth}
      />

      <PrivateRoute
        path={sms_urls.sms_credit_validation}
        component={smsCreditValidationPage}
        roles={TTP_SUPERVISOR_ROLE}
        auth={auth}
      />

      {/* NOTIFICATIONS */}
      {/*<PrivateRoute path={notification_urls.notification_form} component={NotificationPage} roles={[ADMIN_ROLE]} auth={auth} />*/}
      {/*<PrivateRoute path={notification_urls.notification_list} component={NotificationListPage} roles={[ADMIN_ROLE]} auth={auth} />*/}

      <Route path={community_urls.community_form} component={CommunityPage} />
      <Route
        path={urls.unauthorized}
        component={() => <NotFound errorPageType="ACCESS_DENIED" />}
      />
      <Route
        path={urls.notFound}
        component={() => <NotFound errorPageType="ERROR_404" />}
      />
    </Switch>
  );
};
