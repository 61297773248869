import React, { Component } from 'react';
import { connect } from 'react-redux';

import Breadcrumbs from 'ui/Breadcrumbs';
import SettingsStep from './step1/SettingsStep';
import ThemesSettings from '../common/theme/ThemesSettings';
import EmailsList from './step3/EmailsList';

import { saveEmailsSettings, fetchEmailSettings, fetchGenericEmails, fetchEvents, fetchSurveys } from "thunks";
import { setStep, setTTPDialogCustomData, setCurrentEmailingSetting, setWindowHeight, initCurrentEmailingSettings, setCurrentNavPage } from "actions";
import _ from 'i18n';
import { STEPS, LANGUAGES } from 'Common';
import { onError, onSuccess, mapEventsData } from 'utils';
import TTPLoader from '../ui/TTPLoader';
import {
  inIframe,
  ucFirst,
  postResizeMessage,
  getMainEmail,
  mapSurveysData,
  getAppTypes
} from "../../services/utils";
import { PAYMENT_APP } from "../../services/apps";
import SidePageHeader from 'common/SidePageHeader';
import { fetchEventCycles } from '../../actions/thunks';

class GenericEmail extends Component {

  state = {
    activeStep: STEP_1,
    pageLoading: false,
    steps: {
      STEP_1: {
        label: _('settings'),
        enabled: true,
      },
      STEP_2: {
        label: _('models'),
        enabled: false
      },
      STEP_3: {
        label: _('emails'),
        enabled: false
      }
    }
  };

  isEmbedded = inIframe();

  componentDidMount() {
    const { initCurrentEmailingSettings, toggleMenu, fetchCurrentSettings, match: { params: routeParams }, location,
      setWindowHeight, setCurrentNavPage } = this.props;
    const { currentSetting: { verifiedEmails, themeFr, themeNl, themeEn } } = this.props.auth;

    const query = new URLSearchParams(location.search);
    setCurrentNavPage("GENERIC_EMAILING");

    let filter;
    if (routeParams.settingId) {
      filter = { id: routeParams.settingId };
    } else if (query.get('app') && query.get('target')) {
      filter = { targetApp: query.get('app').toUpperCase(), targetId: query.get('target') };
    }
    if (query.get('windowHeight')) {
      setWindowHeight(query.get('windowHeight'));
    }
    if (filter && Object.keys(filter).length) {
      this.setState({ pageLoading: true });
      fetchCurrentSettings(filter)
        .then(() => {
          const newSteps = { ...this.state.steps };
          newSteps[STEP_3].enabled = newSteps[STEP_1].enabled;
          this.setState({ steps: newSteps });
          this.setActiveStep(STEP_3);
        })
        .catch(() => this.loadTarget(query.get('target'), query.get('app') ? query.get('app').toUpperCase() : null))
        .finally(() => this.setState({ pageLoading: false }));
    } else {
      const from = getMainEmail(verifiedEmails);
      let setting = { themeFr, themeNl, themeEn };
      if (from) {
        ['Fr', 'Nl'].forEach(lng => {
          setting[`fromEmail${lng}`] = from.email;
          setting[`fromName${lng}`] = from.name;
        })
      }
      initCurrentEmailingSettings(setting);
    }
    if (this.isEmbedded) {
      toggleMenu();
    }
    postResizeMessage();
  }

  loadTarget = (targetId, app) => {
    const { user: { language } } = this.props.auth;
    if (!targetId || !app) {
      return;
    }

    switch (app) {
      case 'EVENT':
        this.props.fetchEvent(targetId).then(res => {
          const events = mapEventsData(res, language);
          const target = (events && events.length) ? events[0] : null;
          if (target) {
            this.loadDataFromTarget(target, app);
          }
        })
        break;
      case 'EVENT_CYCLE':
        this.props.fetchEventCycles(targetId).then(res => {
          const cycles = mapEventsData(res, language);
          const target = (cycles && cycles.length) ? cycles[0] : null;
          if (target) {
            this.loadDataFromTarget(target, app);
          }
        })
        break;
      case 'SURVEY':
        this.props.fetchSurvey(targetId).then(res => {
          const surveys = mapSurveysData(res, language);
          const target = (surveys && surveys.length) ? surveys[0] : null;
          if (target) {
            this.loadDataFromTarget(target, app);
          }
        })
      default:
        this.props.setSettingData({
          target: {
            id: targetId,
            label: targetId
          },
          targetApp: app,
          types: getAppTypes(this.props.genericEmailTypes, app)
        });
        break;
    }
  }

  loadDataFromTarget = (target, targetApp) => {
    const { currentSetting: { verifiedEmails, themeFr, themeNl, themeEn }, user: { firstName, lastName } } = this.props.auth;
    let fromName = firstName + " " + lastName;
    let fromEmail = "";
    const from = getMainEmail(verifiedEmails);
    if (from) {
      fromEmail = from.email;
      fromName = from.name;
    }
    let setting = {};
    const languages = target ? target.languages : [];
    if ((languages && languages.length > 0) || targetApp === PAYMENT_APP) {
      setting = LANGUAGES.reduce((acc, lng) => {
        const email = target["fromEmail" + ucFirst(lng)] || fromEmail;
        acc["fromEmail" + ucFirst(lng)] = email;

        const name = target["fromName" + ucFirst(lng)] || fromName;
        acc["fromName" + ucFirst(lng)] = name;

        return acc;
      }, {});

    }

    if (targetApp === PAYMENT_APP) {
      setting = { ...setting, languages: this.props.languages, types: getAppTypes(this.props.genericEmailTypes, PAYMENT_APP) };
    } else {
      setting = { ...setting, languages, types: target ? target.types : [] };
    }

    this.props.initCurrentEmailingSettings({ target, targetApp, themeFr, themeNl, themeEn, ...setting });
  };

  componentDidUpdate(prevProps) {
    const { updated, fetched, currentSettingId } = this.props;
    const settingsFetched = prevProps.fetching && fetched;
    const settingsChanged = settingsFetched || (prevProps.updating && updated);
    if (settingsChanged || settingsFetched) {
      const newSteps = { ...this.state.steps };
      newSteps[STEP_3].enabled = newSteps[STEP_1].enabled;
      this.setState({ steps: newSteps });
      this.setActiveStep(currentSettingId ? STEP_3 : STEP_2);
    }
  }

  setActiveStep = (activeStep) => {
    const { steps } = this.state;
    this.setState({
      activeStep: (steps[activeStep] && steps[activeStep].enabled) ? activeStep : STEP_1
    });
  }

  componentWillUnmount() {
    this.props.toggleMenu(true);
  }

  settingIsValid = (isValid) => {
    let { steps } = this.state;
    const newSteps = { ...steps };
    newSteps[STEP_2].enabled = isValid;
    newSteps[STEP_3].enabled = isValid && this.props.currentSettingId != null;
    this.setState({ steps: newSteps });
  };

  saveEmailsSettings = (action) => {
    const { saveSettings, fetchEmails, currentSettingId } = this.props;
    const { activeStep } = this.state;
    saveSettings(action)
      .then(res => {
        onSuccess(res);
        this.props.history.replace(`/email/${res.value.data.data.id}`);
        this.setActiveStep((activeStep === STEP_1) ? STEP_2 : STEP_3);
        if (action == INIT_EMAILS_ACTION) {
          fetchEmails(currentSettingId);
        }
      }, err => onError(err));
  }

  handleResetEmails = (ev) => {
    const { target } = ev;
    if (this.isEmbedded && target) {
      target.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
    this.props.confirm({
      approvedAction: () => this.saveEmailsSettings(INIT_EMAILS_ACTION),
      message: _('reset_generic_emails'),
      proceedBtnLabel: _('reset emails'),
      style: this.isEmbedded ? {
        content: {
          top: `calc(${(target && target.offsetTop) || "500"}px + 14rem)`,
          bottom: "auto",
        },
        overlay: {
          backgroundColor: "hsla(0,0%,4%,.03)"
        },
      } : {}
    });
  }

  render() {
    const { activeStep, steps, pageLoading } = this.state;
    const { updating, themeEn, themeFr, themeNl, setSettingData, languages, fetchEmails, currentSettingId } = this.props;

    let next;
    const saveValid = this.state.steps[STEP_2].enabled;
    if ([STEP_1, STEP_2].indexOf(activeStep) > -1) {
      next = {
        label: _('save'),
        action: this.saveEmailsSettings,
        enabled: saveValid,
        updating: updating
      };
    }

    return (
      <div className="generic-emails-page">
        {!this.isEmbedded && <SidePageHeader subHeader="Manage your transactional mailings" />}
        {pageLoading && <TTPLoader cssClass={this.isEmbedded ? "fixed-loader" : ""} />}
        <div id="generic-email-form" className={pageLoading ? "hide" : ""}>
          {<Breadcrumbs steps={steps} activeStep={activeStep} goToStep={this.setActiveStep} next={next} />}
          <div className={(STEP_1 === activeStep) ? "" : "hide"}>
            <SettingsStep
              targetChanged={this.loadDataFromTarget}
              settingIsValid={this.settingIsValid}
            />
          </div>
          <div className={(STEP_2 === activeStep) ? "" : "hide"}>
            <ThemesSettings
              languages={languages}
              themeEn={themeEn}
              themeFr={themeFr}
              themeNl={themeNl}
              onApply={setSettingData}
            />
          </div>
          {(STEP_3 === activeStep && currentSettingId) && <div>
            <div className="row medium-12 title">
              <h3>{_("Manage your transactional mailings")}</h3>
            </div>
            <EmailsList
              fetchEmails={() => fetchEmails(currentSettingId)}
              onReset={this.handleResetEmails}
            />
          </div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentSettingId: state.genericEmail.currentSetting.id,
  updating: state.genericEmail.currentSetting.updating,
  updated: state.genericEmail.currentSetting.updated,
  fetching: state.genericEmail.currentSetting.fetching,
  fetched: state.genericEmail.currentSetting.fetched,
  themeFr: state.genericEmail.currentSetting.themeFr,
  themeNl: state.genericEmail.currentSetting.themeNl,
  themeEn: state.genericEmail.currentSetting.themeEn,
  status: state.genericEmail.currentSetting.status,
  languages: state.genericEmail.currentSetting.languages,
  targetApp: state.genericEmail.currentSetting.targetApp,
  auth: state.auth,
  genericEmailTypes: state.genericEmail.types.items,
});
const mapDispatchToProps = dispatch => ({
  toggleMenu: (show) => dispatch(setStep(show ? null : STEPS['HIDE_MENU'])),
  confirm: data => dispatch(setTTPDialogCustomData(data)),
  saveSettings: action => dispatch(saveEmailsSettings(action)),
  fetchCurrentSettings: filter => dispatch(fetchEmailSettings(filter)),
  setSettingData: (data) => dispatch(setCurrentEmailingSetting(data)),
  fetchEmails: (settingsId) => dispatch(fetchGenericEmails({ id: settingsId })),
  setWindowHeight: (windowHeight) => dispatch(setWindowHeight(windowHeight)),
  fetchEvent: (id) => dispatch(fetchEvents(id)),
  fetchSurvey: (id) => dispatch(fetchSurveys(id)),
  fetchEventCycles: (id) => dispatch(fetchEventCycles(id)),
  initCurrentEmailingSettings: (data = {}) => dispatch(initCurrentEmailingSettings(data)),
  setCurrentNavPage: () => dispatch(setCurrentNavPage("GENERIC_EMAILING")),
});
export default connect(mapStateToProps, mapDispatchToProps)(GenericEmail);

const INIT_EMAILS_ACTION = 'INIT_EMAILS';
const STEP_1 = 'STEP_1';
const STEP_2 = 'STEP_2';
const STEP_3 = 'STEP_3';
