import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'i18n';
import {
  hideEboxWidget,
  setEboxWidgetAttachments,
  setEboxStatus,
} from '../../../actions/actions';
import Dropzone from "react-dropzone";
import { ACCEPT_MIME_TYPES } from "../../../constants";
import { SVG_DELETE_ICON, SVG_ATTACH_ICON, SES_SUCCESS_STATUS, inIframe } from '../../../services/utils';
import { sendTransactionalEmail, fetchEBox } from '../../../actions/thunks';
import {SVG_ICONS} from "../../../config/Common";
import {TABS} from "../../common/editor/RichEditor";
import CheckBoxBtn from "../../common/CheckBoxBtn";

const mapStateToProps = state => ({
  eboxStatus: state.ebox.list.filter.status,
  attachments: state.ebox.widget.attachments,
  widget: state.ebox.widget,
  fields: state.ebox.widget.fields,
  loggedAs: state.auth.loggedAs,
  selectedText: state.currentCampaign.email.textModel,
  selectedTextScope: state.currentCampaign.email.textModelScope,
  updating: state.textModels.updating,
});

const mapDispatchToProps = dispatch => ({
  hideEboxWidget: () => dispatch(hideEboxWidget()),
  setEboxWidgetAttachments: (attachments) => dispatch(setEboxWidgetAttachments(attachments)),
  fetchEbox: () => dispatch(fetchEBox(false)),
  setEboxStatus: status => dispatch(setEboxStatus(status)),
  sendTransactionalEmail: data => dispatch(sendTransactionalEmail(data)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class BottomBar extends Component {
  constructor(props) {
    super(props);
    this.handleEnableResponseChange = () => this.setState({ enableResponse: !this.state.enableResponse });

    this.state = { enableResponse: false };
  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length === 0) return;
    let newAttachments = [...this.props.attachments];
    newAttachments.push(acceptedFiles[0]);
    this.props.setEboxWidgetAttachments(newAttachments);
  }

  sendEmails = () => {
    const { sendTransactionalEmail, data, eboxStatus, setEboxStatus, fetchEbox, fields } = this.props;
    const {  enableResponse} = this.state;

    function replaceFields(string, obj){
      var content = string;
      for(var prop in obj) {
        content = content.replace(new RegExp( prop,'g'), obj[prop]);
      }
      return content;
    }
    data["message"] = replaceFields(data["message"] ,fields);
    const { recipients, fromEmail, message } = data;

    /*fromEmail && fromEmail.status === SES_SUCCESS_STATUS && */
    if (message && message.length > 5 && recipients && recipients.length > 0) {
      sendTransactionalEmail({
        ...data,
        enableResponse,
        message
      }).then(() => {
        if (inIframe()) {
          return;
        }
        if (eboxStatus === 'SENT') {
          fetchEbox();
        } else {
          setEboxStatus('SENT');
        }
      })
    }
  }

  render() {
    const {hideEboxWidget, isFreeSectionTab, handleNextAction, isNext, isTextModelTab, toggleSaveTextModelForm, loggedAs, selectedText, selectedTextScope, updating, handleEditTextModel} = this.props;
    const {subject, message} = this.props.widget;
    const showAddTextModelBtn = loggedAs === "ADMIN" && subject && subject.length > 0 && message && message.length > 0 && message !== "<p>​<br></p>";

    const showDropdownBtn = selectedText && selectedTextScope === "COMMUNITY";
    const showSaveOneBtn = !selectedText || selectedText && selectedTextScope === "TAMTAM";

    if (isFreeSectionTab || (TABS.includes("free section") && !isNext)) {
      return <div className="widget_bottom-bar">
        <div className="small-12 flex-container next-container">
          <div className="small-8">
            <span>{_('enterFreeSection')}</span>
          </div>
          <button onClick={() => handleNextAction("FREE_SECTION")} className={`btn ${isNext ? 'success' : 'disabled'} `}>
            {_('next')}
            <img src={"/img/icons/" + SVG_ICONS["NEXT"]} />
          </button>
        </div>
      </div>
    } else if (isTextModelTab) {
      return <div className="widget_bottom-bar text-model">
        <div className="next-container">
          <button onClick={() => handleNextAction("TEXT_MODEL")} className="btn success">
            {_('next')}
            <img src={"/img/icons/" + SVG_ICONS["NEXT"]} />
          </button>
        </div>
      </div>
    } else {
      return <div className="widget_bottom-bar">
        <div className="flex-container">
          <button type="button" className="btn-trash" onClick={hideEboxWidget}>
            {SVG_DELETE_ICON}
          </button>
          <Dropzone className="btn-attach"
                    onDrop={this.handleDrop}
                    multiple={false}
                    accept={ACCEPT_MIME_TYPES.join(',')}>
            {SVG_ATTACH_ICON}
          </Dropzone>
          {/*<CheckBoxBtn checked={this.state.enableResponse} label={_("Create a chat conversation")}  onChange={this.handleEnableResponseChange} />*/}
        </div>

        <div className="flex-container">
          {showAddTextModelBtn && <div className={`${showDropdownBtn && "show-dropdown"}`}>
            <button onClick={() => showSaveOneBtn && toggleSaveTextModelForm()} className={`${showDropdownBtn && "save-btn"} btn primary`}>
              {showDropdownBtn ? _('save') : _('save as')}
            </button>
            <div className="dropdown-content">
              <button onClick={() => !updating && handleEditTextModel()} className={`btn primary`}>
                {updating ? _('Processing...') : _('Save text model')}
              </button>
              <button onClick={toggleSaveTextModelForm} className={`btn primary`}>
                {_('save as')}
              </button>
            </div>
          </div>}
          <button onClick={this.sendEmails} className={`btn success`}>
            {_('send')}
          </button>
        </div>
      </div>
    }
  }
}
