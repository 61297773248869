import React from 'react';
import _ from 'i18n';

const Footer = () => (
  <footer id="footer">
    <div className="main-footer row align-center p-y-m">
      <div className="column small-2">
        <img alt="" src="/img/pictos/full-logo.svg" />
      </div>
      <div className="column small-3 footer__contact">
        <ul>
          <li><i className="icomoon icon-tt-facebook" /></li>
          <li><i className="icomoon icon-tt-linkedin" /></li>
          <li><i className="icomoon icon-tt-instagram" /></li>
          <li><i className="icomoon icon-tt-contact" /></li>
        </ul>
      </div>
      <div className="column small-6 footer__about">
        <p className="p-x-s">
          <b>Tamtam.pro </b>
          {_('about_us_content')}
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
