import React, { Component } from "react";

export default class ApproverFetching extends Component {
  render() {
    return (
      <div className="approver">
        <div className="gradiant approver-avatar"></div>
        <h3 className="gradiant" style={{ width: "40%", height: "8%" }}></h3>
        <h5 className="gradiant" style={{ width: "60%", height: "8%" }}></h5>
      </div>
    );
  }
}
