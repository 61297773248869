import React, { PureComponent } from "react";
import Select, { components } from "react-select";
import TagsInput from "react-tagsinput";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import TTPSelect from "common/TTPSelect";

import _ from "i18n";
import { CAMPAIGN_LANGUAGE } from "Common";
import { IS_EMAIL_VALID_UA } from "../../../services/svgIcones";
import { setCurrentEmailCampaignData } from "../../../actions/actions";
import { isValidEmail, hasValidLength, ucFirst } from "../../../services/utils";
import StepsFooter from "./StepsFooter";

@connect((store) => {
  return {
    client: store.auth.currentClient,
    registeredEmails: store.params.sesEmails.items,
    sesEmailsFetched: store.params.sesEmails.fetched,
    recipientType: store.currentCampaign.email.recipientType,
    language: store.currentCampaign.email.language,
    replyTo: store.currentCampaign.email.replyTo,
    fromName: store.currentCampaign.email.fromName,
    from: store.currentCampaign.email.from,
    attachments: store.currentCampaign.email.attachments,
    deletedAttachmentsIds: store.currentCampaign.email.deletedAttachmentsIds,
    verifiedEmails: store.auth.currentSetting.verifiedEmails,
    filters: store.filters,
    eventFilters: store.currentCampaign.email.eventFilters,
    object: store.currentCampaign.email.object,
    currentStep: store.currentCampaign.email.currentSideBar === "PARAMS",
    newsletterPreferences: store.auth.currentSetting.newsletterPreferences,
    numberOfNewsletter: store.currentCampaign.email.numberOfNewsletter,
    lastNumNewsletter: store.currentCampaign.email.lastNumNewsletter,
    introduction: store.currentCampaign.email.introduction,
    delegationTypesList: store.campaigns.delegationTypes.items,
    delegationTypes: store.currentCampaign.email.delegationTypes,
  };
})
export default class ParamsStep extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      registrationStatus: "FAILED",
      tag: "",
      currentObject: this.props.object,
      currentIntroduction: this.props.introduction,
      hasError: {
        from: false,
        fromName: false,
        replyTo: false,
        object: false,
        introduction: false,
      },
      senders: [],
    };
  }

  componentDidMount() {
    const { newsletterPreferences } = this.props;

    if (newsletterPreferences["apps"]) {
      for (let i = 0; i < newsletterPreferences["apps"].length; i++) {
        if (newsletterPreferences["apps"][i].type === "BLOG_NL_CREATE")
          newsletterPreferences["apps"][i].senders &&
            this.setState({
              senders: newsletterPreferences["apps"][i].senders,
            });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { senders, currentObject, currentIntroduction } = this.state;
    const {
      from,
      newsletterPreferences,
      object,
      lastNumNewsletter,
      introduction,
    } = this.props;
    if (prevProps.object !== object && object !== currentObject) {
      this.setState({
        currentObject: object,
      });
    }
    if (
      prevProps.introduction !== introduction &&
      introduction !== currentIntroduction
    ) {
      this.setState({
        currentIntroduction: introduction,
      });
    }
    if (prevProps.from !== from) {
      let sender = senders.filter((option) => option.email === from);
      if (sender.length > 0) {
        this.setState({
          registrationStatus:
            sender[0].status === "Success" ? "SUCCESS" : "FAILED",
        });
        this.props.dispatch(
          setCurrentEmailCampaignData({
            fromEmailValid: sender[0].status === "Success",
          }),
        );
      }
    }
    if (prevProps.newsletterPreferences !== newsletterPreferences) {
      if (senders.length === 0 && newsletterPreferences["apps"]) {
        for (let i = 0; i < newsletterPreferences["apps"].length; i++) {
          if (newsletterPreferences["apps"][i].type === "BLOG_NL_CREATE")
            newsletterPreferences["apps"][i].senders &&
              this.setState({
                senders: newsletterPreferences["apps"][i].senders,
              });
        }
      }
    }
    if (lastNumNewsletter && lastNumNewsletter !== 0) {
      let numNewsletter = lastNumNewsletter;
      if (/^\d+$/.test(numNewsletter)) {
        // If numNewsletter is just a number, increment it by one
        numNewsletter = (parseInt(numNewsletter) + 1)
          .toString()
          .padStart(numNewsletter.length, "0");
      } else if (/^\d{4}-\d+$/.test(numNewsletter)) {
        // If numNewsletter is in the format YYYY-CampaignNumber
        const [year, campaignNumber] = numNewsletter.split("-");
        const nextCampaignNumber = parseInt(campaignNumber) + 1;

        // Reconstruct the numNewsletter string with the incremented campaign number
        numNewsletter = `${year}-${nextCampaignNumber.toString().padStart(campaignNumber.length, "0")}`;
      } else if (/^#\d{4}-\d+$/.test(numNewsletter)) {
        // If numnl is in the format #YYYY-CampaignNumber
        const [year, campaignNumber] = numNewsletter.split("#")[1].split("-");

        if (year !== undefined && campaignNumber !== undefined) {
          const nextCampaignNumber = parseInt(campaignNumber) + 1;
          numNewsletter = `#${year}-${nextCampaignNumber.toString().padStart(campaignNumber.length, "0")}`;
        }
      }
      this.props.dispatch(
        setCurrentEmailCampaignData({ numberOfNewsletter: numNewsletter }),
      );
    }
  }

  handleLanguageChange = (language) => {
    this.props.dispatch(setCurrentEmailCampaignData({ language }));
  };

  renderLang() {
    const { client, language } = this.props;
    let defaultLanguages = Object.keys(CAMPAIGN_LANGUAGE);
    let preferences =
      client &&
      client.languagePreferences &&
      client.languagePreferences.length > 0
        ? [...client.languagePreferences]
        : defaultLanguages;
    let options = [];
    if (preferences.indexOf("all") === -1) {
      preferences.push("all");
    }
    for (let i = 0; i < preferences.length; i++) {
      let lang = preferences[i];
      options.push({ id: lang, label: ucFirst(_(CAMPAIGN_LANGUAGE[lang])) });
    }
    return (
      <TTPSelect
        simple={true}
        notClearable={true}
        values={language}
        placeholder={_("lang")}
        options={options}
        onChange={this.handleLanguageChange}
      />
    );
  }

  onSuccess(res) {
    const { value } = res;
    if (res.value instanceof Error) {
      NotificationManager.error(_("incompleteOperation"), _("error"));
    } else if (value.data.result === "OK") {
      this.setState({ registrationStatus: "PENDING" });
    }
  }

  handleChangeInput(tag) {
    let tagPattern = new RegExp(TAG_REGEX);
    this.setState((prevState) => ({
      tag,
      hasError: {
        ...prevState.hasError,
        replyTo: tag && !tagPattern.test(tag),
      },
    }));
  }

  handleFromChange = (fromData) => {
    const from = fromData?.email || "";
    const fromName = fromData?.name ? fromData?.name : fromData?.label || "";
    const replyTo = [fromData?.email] || [];

    this.props.dispatch(
      setCurrentEmailCampaignData({
        from,
        fromName,
        replyTo,
      }),
    );
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        from: !isValidEmail(from),
        fromName: !hasValidLength(fromName, 3),
      },
    }));

    this.setState({
      registrationStatus:
        fromData.status === "VALIDATED" || "Success" ? "SUCCESS" : "FAILED",
    });
    this.props.dispatch(
      setCurrentEmailCampaignData({
        fromEmailValid: fromData.status === "VALIDATED",
      }),
    );
  };

  handleDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;
    const newAttachment = acceptedFiles[0];
    let attachments = [...this.props.attachments];
    attachments.push(newAttachment);

    this.props.dispatch(setCurrentEmailCampaignData({ attachments }));
  };

  handleDeleteAttachment = (index, id) => {
    let attachments = [...this.props.attachments];
    let deletedAttachmentsIds = [...this.props.deletedAttachmentsIds];

    if (attachments[index]) {
      attachments = attachments.filter((_, i) => i !== index);
    }
    if (id) {
      deletedAttachmentsIds.push(id);
    }

    this.props.dispatch(
      setCurrentEmailCampaignData({ attachments, deletedAttachmentsIds }),
    );
  };

  handleReplyToChange = (replyTo) => {
    this.props.dispatch(setCurrentEmailCampaignData({ replyTo }));
  };

  handleFromNameChange = (e) => {
    const fromName = e.target.value;
    this.props.dispatch(setCurrentEmailCampaignData({ fromName }));
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        fromName: !hasValidLength(fromName, 3),
      },
    }));
  };
  handleDelegationTypeshange = (selectedOptions) => {
    const selectedIds = selectedOptions
      ? selectedOptions.map((option) => option.id)
      : [];
    this.props.dispatch(
      setCurrentEmailCampaignData({ delegationTypes: selectedIds }),
    );
  };

  handleObjectChange = (e) => {
    const object = (e.target ? e.target.value : e).trimStart();
    this.props.dispatch(setCurrentEmailCampaignData({ object }));
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        objectHasError: !hasValidLength(object, 5, 200),
      },
      currentObject: object,
    }));
  };

  subjectInputHandler = () => {
    let textArElement = document.getElementsByClassName("params-object");
    if (this.props.object.length && textArElement && textArElement.length > 0) {
      textArElement[0].style.height = "0px";
      textArElement[0].style.height = textArElement.item(0).scrollHeight + "px";
    }
  };
  handleNumberOfNewslettersChange = (e) => {
    this.props.handleGenerateHtml(false);
    const numberOfNewsletter = e.target.value;
    this.props.dispatch(setCurrentEmailCampaignData({ numberOfNewsletter }));
  };

  handleIntroductionChange = (e) => {
    this.props.handleGenerateHtml(false);
    const introduction = e.target ? e.target.value : e;
    this.props.dispatch(setCurrentEmailCampaignData({ introduction }));
    this.setState((prevState) => ({
      hasError: {
        ...prevState.hasError,
        introductionHasError: !hasValidLength(introduction, 5, 200),
      },
      currentIntroduction: introduction,
    }));
  };
  introductionInputHandler = () => {
    let textArElement = document.getElementsByClassName("params-introduction");
    if (
      this.props.introduction.length &&
      textArElement &&
      textArElement.length > 0
    ) {
      textArElement[0].style.height = "0px";
      textArElement[0].style.height = textArElement.item(0).scrollHeight + "px";
    }
  };

  render() {
    const {
      from,
      fromName,
      replyTo,
      language,
      currentStep,
      object,
      isPlanPersonalized,
      numberOfNewsletter,
      introduction,
      delegationTypesList,
      delegationTypes,
    } = this.props;

    const {
      registrationStatus,
      hasError,
      tag,
      currentObject,
      senders,
      currentIntroduction,
    } = this.state;

    const fromCssClass = `${from && !hasError.from && "filled-box"} ${
      !from && "empty-box"
    } ${hasError.from && "is-invalid-input"}`;
    const fromNameCssClass = `tab-input ${
      fromName && !hasError.fromName && "filled-box"
    } ${!fromName && "empty-box"} ${hasError.fromName && "is-invalid-input"}`;

    var isObjectInvalid = object.length < 5 || !hasValidLength(object, 5, 200);
    var disabled =
      isObjectInvalid ||
      from.length <= 0 ||
      fromName.length <= 0 ||
      language.length <= 0;

    return (
      <div id="send-tabs__tab" className={`${!currentStep && "hide"}`}>
        <div className="tab">
          <h4 className="tab-header">{_("newsletter_config_title")}</h4>
          <div className="row">
            <div className="columns small-12 columns-padding">
              <span className="tab-label">
                {ucFirst(_("subject"))} <span className="required">*</span>
              </span>
              <div className={`small-12`}>
                <textarea
                  style={{ paddingTop: "10px" }}
                  rows={1}
                  className={`tab-input ${
                    object ? "filled-box" : "empty-box"
                  } ${hasError.objectHasError ? "is-invalid-input" : ""}`}
                  placeholder={_("Give your campaign a title")}
                  onChange={this.handleObjectChange}
                  onInput={this.subjectInputHandler}
                  value={currentObject}
                >
                  {object}
                </textarea>
                <span
                  className={
                    hasError.objectHasError
                      ? "form-error is-visible"
                      : "form-error"
                  }
                >
                  {" "}
                  {_("invalidObject") + ". "}
                  {object.length - 200 > 0 &&
                    _("subject_message_info_part_1") +
                      (object.length - 200) +
                      _("subject_message_info_part_2")}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="columns small-6  columns-padding">
              {/* New input for the number of newsletters */}
              <span className="tab-label small-10">
                {ucFirst(_("number_of_newsletter"))}{" "}
                <span className="required">*</span>
              </span>
              <div className="small-12">
                <input
                  type="text"
                  className={fromNameCssClass}
                  onChange={this.handleNumberOfNewslettersChange}
                  value={numberOfNewsletter}
                />
              </div>
            </div>
            <div className={`columns small-6 columns-padding`}>
              <span className="tab-label">
                {ucFirst(_("introduction"))} <span className="required">*</span>
              </span>
              <div className={`small-12`}>
                <textarea
                  style={{ paddingTop: "10px" }}
                  rows={1}
                  className={`tab-input ${
                    introduction ? "filled-box" : "empty-box"
                  } ${hasError.introductionHasError ? "is-invalid-input" : ""}`}
                  placeholder={_("placeholder_intro")}
                  onChange={this.handleIntroductionChange}
                  onInput={this.introductionInputHandler}
                  value={currentIntroduction}
                >
                  {introduction}
                </textarea>
                <span
                  className={
                    hasError.introductionHasError
                      ? "form-error is-visible"
                      : "form-error"
                  }
                >
                  {" "}
                  {_("invalid_introduction")}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="tab-label email-field">
                {ucFirst(_("fromEmail"))} <span className="required">*</span>
              </span>
              <div className="small-12 m-r-xs from-email-select">
                <span className="validation-icon">
                  {IS_EMAIL_VALID_UA(registrationStatus === "SUCCESS")}
                </span>
                <Select
                  isSearchable
                  placeholder=""
                  value={senders.filter((option) => option.email === from)}
                  onChange={this.handleFromChange}
                  options={senders}
                  components={{ Option: EmailOption }}
                  className={`ttp-select ${fromCssClass}`}
                  classNamePrefix="ttp-select"
                  getOptionValue={(option) => option.email}
                  getOptionLabel={(option) => option.email}
                />
                <span
                  className={
                    hasError.from ? "form-error is-visible" : "form-error"
                  }
                >
                  {_("invalidEmail")}
                </span>
              </div>
            </div>

            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">
                {ucFirst(_("fromName"))} <span className="required">*</span>
              </span>
              <div className="small-12">
                <input
                  className={fromNameCssClass}
                  type="text"
                  onChange={this.handleFromNameChange}
                  value={fromName}
                />
                <span
                  className={
                    hasError.fromName ? "form-error is-visible" : "form-error"
                  }
                >
                  {" "}
                  {_("invalidFromName")}
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="tab-label">
                {ucFirst(_("language"))} <span className="required">*</span>
                <span
                  className="tooltip-info"
                  data-tip={_("params_lang_info")}
                  data-for="lang-info"
                >
                  <img src="/img/icons/alert-circle.svg" />
                </span>
              </span>
              <ReactTooltip id="lang-info" place="bottom" multiline={true} />
              <div
                className={`small-12 m-r-xs ${
                  language ? "filled-box" : "empty-box"
                }`}
              >
                {this.renderLang()}
              </div>
            </div>
            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">
                {ucFirst(_("replyTo"))}
              </span>
              <div
                className={`small-12 tags-input-container ${
                  replyTo && replyTo.length ? "filled-box" : "empty-box"
                }`}
              >
                <TagsInput
                  value={replyTo}
                  onChange={this.handleReplyToChange}
                  addKeys={[9, 13, 186, 188]}
                  removeKeys={[]}
                  onlyUnique={true}
                  addOnPaste
                  validationRegex={TAG_REGEX}
                  inputProps={{ placeholder: _("emailAdd") }}
                  inputValue={tag}
                  onChangeInput={this.handleChangeInput.bind(this)}
                />
              </div>
              <span
                className={
                  hasError.replyTo ? "form-error is-visible" : "form-error"
                }
              >
                {_("replyToError")}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="columns small-6 columns-padding">
              <span className="tab-label email-field">
                {ucFirst(_("Type de délégation"))}
              </span>
              <div className="small-12 m-r-xs from-email-select">
                <Select
                  isMulti
                  isSearchable
                  placeholder=""
                  value={delegationTypesList.filter((option) =>
                    delegationTypes.includes(option.id),
                  )}
                  onChange={this.handleDelegationTypeshange}
                  options={delegationTypesList}
                  className={`ttp-select ${fromCssClass}`}
                  classNamePrefix="ttp-select"
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.titleFr}
                />
              </div>
            </div>
          </div>
          {/*Pieces jointes*/}
          {/* <div className="row">
            <div className="columns columns-padding">
              <span className="tab-label">{ucFirst(_("Attachments"))}</span>
              <div className="attachments-container">
                <AttachmentsContainer
                  attachments={attachments}
                  onDeleteAttachment={this.handleDeleteAttachment}
                />
                <Dropzone
                  className="attachments-dropzone"
                  onDrop={this.handleDrop}
                  multiple={false}
                  accept={ACCEPT_MIME_TYPES.join(",")}
                >
                  <span className="icon icon-cloud-upload" />
                  <p>
                    {_(
                      "Drag and drop some files here, or click to select files to upload."
                    )}
                  </p>
                </Dropzone>
              </div>
            </div>
          </div>*/}
        </div>

        <StepsFooter
          disabled={disabled}
          isPlanPersonalized={isPlanPersonalized}
        />
      </div>
    );
  }
}

const TAG_REGEX =
  /^[a-zA-Z\s]*<\s*[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?\s*>$|^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

const EmailOption = (props) => {
  const { email, name, label } = props.data;

  return (
    <components.Option {...props}>
      <div className="label-from">
        <span className="label-from__name">{name ? name : label}</span>
        <span className="label-from__email">{email}</span>
      </div>
    </components.Option>
  );
};
