import React, {Component} from 'react';

export default class UserFetching extends Component {

  getUserFetching() {
    let usersFetching = [];
    for (let i = 0; i < 8; i++) {
      usersFetching.push(<div
        className="user user-fetching column small-12 medium-6 large-4" key={i} >
        <div className="user-container"/>
        <div className="user-avatar gradiant"/>
        <h3 className="gradiant"/>
        <h4 className="separator gradiant"/>
        <h5 className="gradiant"/>
      </div>);
    }
    return usersFetching;
  }

  render() {
    return <div className="small-12 row">
      {this.getUserFetching()}
    </div>;
  }
}
