import React, { Component } from 'react';

import Approver from 'approval/teams/Approver';

import { convertDateFromUTC } from 'utils';
import _ from 'i18n';

export default class MemberFeedbackItem extends Component {

  render() {
    const { id, approverName, email, avatar, decision, reason, decideAt, attachmentPath } = this.props;
    let backClass = 'pending';

    let back = <h3>{_('review not yet submitted')}...</h3>
    let approverIcon = 'icon-clock';
    if (decision == "ACCEPT") {
      approverIcon = 'icon-check';
      backClass = 'approve';
      back = <h3>{_('approvedAt')}: {convertDateFromUTC(decideAt)}</h3>
    } else if (decision == "DENY") {
      approverIcon = 'icon-ban';
      backClass = 'reject';
      back = <div>
        <div>
          <span>{_('comment')}:</span>&nbsp; {reason}
        </div>
        <div>
          <span>{_('rejected at')}:</span>&nbsp;<span>{decideAt ? convertDateFromUTC(decideAt) : "---- -- --"}</span>
        </div>
        {attachmentPath && <a className="ttp-button info" target="_blank" href={attachmentPath}>{_('see attachment')}</a>}
      </div>
    }

    let data = { id, email, user: { firstName: approverName, lastName: '', avatar }, approverIcon };

    return <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <Approver {...data} />
        </div>
        <div className={`flip-card-back ${backClass}`}>
          {back}
        </div>
      </div>
    </div>
  }
};
