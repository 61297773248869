import React, { Component } from "react";
import { connect } from 'react-redux';

import { saveApprovalTeam } from 'thunks';
import { onError, onSuccess, modalStyle } from 'utils';
import _ from 'i18n';
import TTPModal from "../../ui/TTPModal";

@connect((store) => {
  return {
    updating: store.approval.teams.updating
  };
})
export default class TeamForm extends Component {

  constructor(props) {
    super(props);
    this.handleDescriptionChange = (e) => this.setState({ description: e.target.value });
    this.handleNameChange = (e) => this.setState({ name: e.target.value });

    this.state = { id: null, name: "", description: "" };
  }

  componentDidMount() {
    if (this.props.id) {
      const { id, name, description } = this.props;
      this.setState({
        id,
        name,
        description,
      });
    }
  }

  saveApprovalTeam() {
    const { onCloseModal, dispatch } = this.props;
    const { id, name, description } = this.state;
    if (!name || name.length < 2) {
      document.getElementById('team-name').classList.add('border-danger');
      return;
    }
    dispatch(saveApprovalTeam({ id, name, description })).then(
      (res) => { onCloseModal(); onSuccess(res); },
      (err) => onError(err)
    )
  }

  render() {
    const { id, onCloseModal, updating } = this.props;
    const { name, description } = this.state;

    const customModalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle.content,
        padding: 0,
      }
    };

    return <TTPModal isOpen={true} onClose={onCloseModal} modalStyle={customModalStyle} title={(id) ? _('Edit team') : _('Create team')}>
      <div id="list-form" className="p-b-s p-t-s">
        <div className="row align-center">
          <div className="column small-10">
            <span className="param-label">{_('name')} *</span>
            <div className="setting-param">
              <input id="team-name" type="text" value={name}
                onChange={this.handleNameChange}
                onFocus={e => e.target.classList.remove('border-danger')}
                className={`${(name) ? "filled-box" : "empty-box"}`} />
            </div>
          </div>
          <div className="column small-10">
            <span className="param-label" >{_('description')}</span>
            <div className="setting-param">
              <textarea
                className={`${(description) ? "filled-box" : "empty-box"}`}
                placeholder={_('Add a team description')}
                onChange={this.handleDescriptionChange} rows="3"
                value={description} />
            </div>
          </div>
        </div>
      </div>
      <div className="templates-settings__footer">
        <button onClick={onCloseModal} className="btn secondary">{_('cancel')}</button>
        <button onClick={!updating && this.saveApprovalTeam.bind(this)} className="btn primary">{updating ? _('Processing...') : _('save')}</button>
      </div>
    </TTPModal>
  }
}
