import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubMenuLink } from "../../SubMenuLink";

import _ from "i18n";

@connect((store) => {
  return {
    auth: store.auth,
  }
})
export default class CreateCampaignEmail extends Component {

  render() {
    const { loggedAs, preference, detached } = this.props.auth;
    const allowCreateEmail = loggedAs == "ADMIN" || (preference && preference['allowCreateCampaign'] == 1) || detached;

    if (!allowCreateEmail) {
      return null;
    }

    return <SubMenuLink cssClass={"create-campaign-link"} pathname="/campaign/selector" title="Create email campaigns" icon="EMAIL_CREATE_LIGHT" />
  }
};
