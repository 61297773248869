import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'i18n';

import Select from "react-select";
import { getEmailRegistrationStatus, SES_FAILED_STATUS, SES_SUCCESS_STATUS, SES_PENDING_STATUS, SES_UNREGISTERED_STATUS } from '../../../services/email';
import {
  fetchClientFolders,fetchContacts,
  fetchMyClientFolders,
  fetchSesEmailsStatuses
} from '../../../actions/thunks';
import {
  setIsFolderEbox,
} from "../../../actions/actions/eBox";
import { setEboxFromEmail, setEboxRecipients, setEboxCc, setEboxReply, setTargetType, setTargetFolders } from '../../../actions/actions/ebox/widget';
import EmailValidator from './EmailValidator';
import Recipient from './Recipients/Recipient';
import TargetType from './Recipients/TargetType';

const mapStateToProps = (state, ownProps) => ({
  myEmails: state.auth.user.email,
  sesEmailsFetched: state.params.sesEmails.fetched,
  fromEmail: state.ebox.widget.fromEmail,
  recipients: state.ebox.widget.recipients,
  cc: state.ebox.widget.cc,
  reply: state.ebox.widget.reply,
  targetType: state.ebox.widget.targetType,
  targetFolders: state.ebox.widget.targetFolders,
  registeredEmails: state.params.sesEmails.items,
  folders: state.filters.folders.items,
  contacts: state.filters.contacts.items,
});

const mapDispatchToProps = dispatch => ({
  fetchSesEmailsStatuses: () => dispatch(fetchSesEmailsStatuses()),
  setEboxFromEmail: email => dispatch(setEboxFromEmail(email)),
  setEboxRecipients: (contacts) => dispatch(setEboxRecipients(contacts)),
  setEboxCc: cc => dispatch(setEboxCc(cc)),
  setEboxReply: (reply) => dispatch(setEboxReply(reply)),
  setTargetType: (targetType) => dispatch(setTargetType(targetType)),
  setTargetFolders: (folders) => dispatch(setTargetFolders(folders)),
  fetchClientFolders: () => dispatch(fetchClientFolders()),
  fetchContacts: (targetFolders) => dispatch(fetchContacts(targetFolders)),
  fetchMyClientFolders: () => dispatch(fetchMyClientFolders()),
  setIsFolderEbox: (flag) => dispatch(setIsFolderEbox(flag)),

});
@connect(mapStateToProps, mapDispatchToProps)

export default class DeliveryInfo extends Component {

  constructor(props) {
    super(props);

    this.handleMyEmailsChange = myEmails => this.setState({ myEmails });
    this.toggleShowCc = () => this.setState({ showCc: !this.state.showCc });

    this.state = {
      myEmails: this.props.myEmails.map(email => ({
        ...email,
        status: SES_FAILED_STATUS
      })),
      showCc: false,
      folders: [],
      contacts: {}
    }

    ;
  }

  handleEmailChange = (emails) => {

    const newRecipients = emails.map(email => {
      const recipient = this.props.contacts.find(el => el.mainEmail == email);
      return recipient ? recipient : { email };
    })
    this.props.setEboxRecipients(newRecipients);
  }

  componentDidMount() {
    const { fetchSesEmailsStatuses, sesEmailsFetched, cc } = this.props;
    if (!sesEmailsFetched) {
      fetchSesEmailsStatuses()
    } else {
      this.updateEmailsStatus();
    }
    if (cc && cc.length) {
      this.setState({ showCc: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { registeredEmails, targetType, fetchClientFolders, folders, fetchMyClientFolders,contacts,setEboxRecipients } = this.props;
    if (prevProps.registeredEmails !== registeredEmails) {
      this.updateEmailsStatus();
    }
    if (targetType !== prevProps.targetType && targetType === 'FOLDER') {
      fetchClientFolders();
    }

    if (targetType !== prevProps.targetType && targetType === 'MY_FOLDERS') {
      fetchMyClientFolders();
    }

    if (folders !== prevProps.folders) {
      let newFolders = folders.map(({ id, abreviation, name }) => ({
          id,
          label: abreviation || name,
          recipient: {
            recipientId: id,
            recipientType: 'FOLDER',
          }
        }
      ))
      this.setState({ folders: newFolders });

    }
    if (contacts !== prevProps.contacts){

      this.props.setIsFolderEbox(true);

      let  recipients = contacts.map(({ mainEmail }) => ({
          email: mainEmail,
          recipientId: this.state.contacts,
          recipientType:"FOLDER"
        }
      ))
      setEboxRecipients(recipients);
    }
  }

  updateEmailsStatus = () => {
    let { registeredEmails, setEboxFromEmail, fromEmail } = this.props;
    let { myEmails } = this.state;

    let newFromEmail = fromEmail ? fromEmail : (myEmails && myEmails.length) ? myEmails[0] : null;

    let newEmails = myEmails.map(email => {
        const status = getEmailRegistrationStatus(registeredEmails, email.email);
        const newEmail = { ...email, status };
        if (newFromEmail.email == email.email || (status == SES_SUCCESS_STATUS && !newFromEmail)) {
          newFromEmail = newEmail;
        }
        return newEmail
      }
    )

    setEboxFromEmail(newFromEmail);
    this.handleMyEmailsChange(newEmails);
  }

  handleFoldersTargetChange = (foldersIds) => {
    const { setTargetFolders, setEboxRecipients , fetchContacts} = this.props;
    setTargetFolders(foldersIds);

    ( foldersIds && foldersIds["recipient"].recipientId  ) ?   fetchContacts(foldersIds["recipient"].recipientId)  &&  this.setState({ contacts:foldersIds["recipient"].recipientId }):  setEboxRecipients([]);

  }

  render() {
    const { fromEmail, setEboxFromEmail, recipients, cc, setEboxCc, setEboxReply, reply, targetType, setTargetType, targetFolders } = this.props;
    const { myEmails, showCc, folders } = this.state;


    const fromEmailError = !fromEmail || [SES_PENDING_STATUS, SES_UNREGISTERED_STATUS].indexOf(fromEmail.status) > -1
    const isFoldersTarget = ["FOLDER", "MY_FOLDERS"].indexOf(targetType) > -1;
    const isMyFoldersTarget = targetType === 'MY_FOLDERS';

    return (
      <div className="delivery-info">
        <div className="delivery-info__item">
          <span className="title">{_('from')} : </span>
          <Select
            isSearchable
            placeholder={_('Select email')}
            value={myEmails.filter(option => (fromEmail && option.email == fromEmail.email))}
            options={myEmails}
            onChange={setEboxFromEmail}
            className={`ttp-select ${!fromEmail ? "empty-box" : (fromEmailError ? "error-box" : "filled-box")}`}
            classNamePrefix="ttp-select"
            getOptionValue={(option) => option.email}
            getOptionLabel={(option) => option.email}
          />
          <EmailValidator
            fromEmail={fromEmail}
            myEmails={myEmails}
            onMyEmailsChange={this.handleMyEmailsChange}
          />
        </div>

        { isFoldersTarget &&
        <div className="delivery-info__item">
          <div className="flex-container align-middle flex-child-auto">
            <span className="title">{isMyFoldersTarget ? _('my folders') : _('folders')} :</span>

            <Select
              isSearchable={false}
              isClearable={true}
              values={targetFolders}
              placeholder={_("Select folder")}
              options={folders}
              className={`ttp-select filled-box`}
              classNamePrefix="ttp-select"
              onChange={this.handleFoldersTargetChange}
            />
          </div>
          <TargetType
            onTargetTypeChange={setTargetType}
            value={targetType} />
        </div>
        }
        <Recipient
          title='to'
          emails={recipients.map(recipient => recipient.email)}
          onChange={this.handleEmailChange}
        >
          {
            !isFoldersTarget && <TargetType
              onTargetTypeChange={setTargetType}
              value={targetType} />
          }
        </Recipient>

        <Recipient
          title='replyTo'
          placeholder={' '}
          emails={reply}
          onChange={setEboxReply}
        >
          {!showCc && <span className="delivery-type align-self-bottom p-l-xs" onClick={this.toggleShowCc}>
            {_('Cc')}
          </span>}
        </Recipient>

        {showCc &&
        <Recipient
          title='Cc'
          emails={cc}
          onChange={setEboxCc}
        />
        }
      </div>
    );
  }
}
