import React, { Component } from "react";
import { connect } from 'react-redux';

import { modalStyle } from 'utils';
import _ from 'i18n';
import UsersTagSuggestion from "./UsersTagSuggestion";
import TTPModal from "../ui/TTPModal";

@connect((store) => {
  return {
    user: store.auth.user
  };
})
export default class SendTestForm extends Component {

  constructor(props) {
    super(props);

    this.handleTagsChange = (recipients) => this.setState({ recipients });

    this.state = { recipients: this.getDefaultRecipients() };
  }

  getDefaultRecipients = () => {
    const { id, email, mainEmail, firstName, lastName } = this.props.user;
    let recipients = [];
    let mainEmailArr = {};
    if (mainEmail && email && email.length > 0) {
      mainEmailArr = email.filter(item => item.email == mainEmail);
    }

    if (mainEmailArr && mainEmailArr.length > 0) {
      recipients.push({ id: mainEmailArr[0]['id'], email: mainEmailArr[0]['email'], userId: id, name: `${lastName} ${firstName}` });
    }

    return recipients;
  }

  sendTest = () => {
    const { recipients } = this.state;
    if (!recipients || recipients.length == 0) {
      return;
    }
    this.props.onSave(recipients.map(item => item.id));
  }

  render() {
    const { onCloseModal, processing, customStyle } = this.props;
    const { recipients } = this.state;

    return <TTPModal
      isOpen={true}
      onClose={onCloseModal}
      modalStyle={{ ...modalStyle, content: { ...modalStyle.content, overflow: "visible", padding: 0 } }}
      title={_('sendEmailTestTo')}>
      <div id="send-test-form" className="templates-settings__body m-t-s">
        <div className="row align-center">
          <div className="small-10 column">
            <UsersTagSuggestion displayEmail={true} selectedTags={recipients} onTagsChange={this.handleTagsChange} />
          </div>
        </div>
      </div>
      <div className="templates-settings__footer">
        <button onClick={onCloseModal} className="btn secondary">{_('cancel')}</button>
        <button onClick={!processing ? this.sendTest : undefined} className="btn primary">
          {processing ? _('Processing...') : _('send')}
        </button>
      </div>
    </TTPModal>
  }
}
