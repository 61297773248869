import React, {Component} from "react";
import {connect} from "react-redux";
import styles from "./Community.module.scss"

@connect((store) => {
  return {
    auth: store.auth,  };
})
export default class CommunityComponent extends Component {
  render() {
const {user} = this.props.auth;
const {selectedOrganization} = user;
    return (
      <div className={styles.community_container}>
        {selectedOrganization.avatarUrl?
          <img src={selectedOrganization.avatarUrl}/>
          : <div className={`empty-avatar ${styles.avatar_empty}`} >
            <span style={{ fontSize: 'inherit' }}>
              <img src ="/img/default-logo.png"/>
            </span>
          </div>}
        <p className={styles.community_name}>{selectedOrganization.name}</p>
      </div>
    );
  }
}



