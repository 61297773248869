import React, { Component } from 'react';
import { connect } from 'react-redux';

import Users from 'settings/Users';
import Bubbles from 'settings/Bubbles';
import Sidebar from 'settings/Sidebar';
import SidePageHeader from 'common/SidePageHeader';
import { setCurrentNavPage, selectSearchWord, selectRole } from "actions";
import TTPFilterHorizontalBar from '../common/filter/TTPFilterHorizontalBar';

import _ from 'i18n';
import { ROLE_TYPES } from "../../constants";
import { SVG_CHECK_ICON, SVG_CLOSE_ICON } from "../../services/svgIcones";

@connect((store) => {
  return {
    filterSearchWord: store.settings.filter.searchWord,
    role: store.settings.filter.role
  }
})
export default class Settings extends Component {

  constructor(props) {
    super(props);
    this.roleOptions = Object.keys(ROLE_TYPES).map(roleType => {
      return {
        label: _(roleType),
        options: ROLE_TYPES[roleType].map(({ value, key }) => {
          return { label: _(value), value: key }
        }),
      }
    });
    this.state = {
      selectDeselectSvg: SVG_CHECK_ICON,
      settingsIsActive: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(setCurrentNavPage("SETTINGS"));

    window.addEventListener('click', function (e) {
      const rolesListItem = document.getElementsByClassName('role-list').item(0);
      const rolesList = rolesListItem ? rolesListItem.classList : "";

      const mask = document.getElementsByClassName('mask').item(0);
      const sidebar = document.getElementById('settings__sidebar');
      const controls = document.getElementById('controls');

      if (rolesList && !rolesList.contains('hide') && e.target.id != 'role-list-display') {
        rolesList.add('hide');
      } else if (e.target == mask) {
        mask.classList.add('hide');
        sidebar ? sidebar.classList.remove('display') : "";
        controls ? controls.classList.remove('display') : "";
      }
    });
  }

  handleSearchInputChange(word) {
    const { dispatch } = this.props;
    if (word && word.length >= 3) {
      dispatch(selectSearchWord(word));
    } else {
      dispatch(selectSearchWord(""));
    }
  }

  clearAll = () => {
    this.handleSearchInputChange("");
  }

  handleSelectRole = (selected) => {
    this.props.dispatch(selectRole(selected));
  };

  handleSelectDeselectSvg(status) {
    this.setState({
      selectDeselectSvg: status === "select" ? SVG_CHECK_ICON : SVG_CLOSE_ICON
    });
  }

  handleSettingsTab(isActive) {
    this.setState({
      settingsIsActive: isActive
    });
  }

  render() {
    let { filterSearchWord, role } = this.props;
    let { selectDeselectSvg, settingsIsActive } = this.state;

    return (
      <div className="settings side-no-tabs-page">
        <SidePageHeader subHeader="users"/>
        <div className="white-container">
          <div className="row white-container--title white-container--title-with-filter">
            <h3>{_("users")}</h3>
            <p>{_("Manage users")}</p>
          </div>
          <div className="medium-11 row align-center">
            <TTPFilterHorizontalBar
              filters={[
                {
                  placeholder: _("roles"),
                  options: this.roleOptions,
                  values: role,
                  action: this.handleSelectRole,
                  isSimple: true
                },
              ]}
              handleListPageSizeChange={this.handleCampaignListPageSizeChange}
              searchInputValue={filterSearchWord}
              handleSearchInputChange={this.handleSearchInputChange.bind(this)}
              handleResetFilter={this.clearAll}
              searchPlaceholder="Search by name"
              cssClass="medium-12"
            />
          </div>
          <div id="setting-users" className="row align-center">
            <Users
              handleSelectDeselectSvg={this.handleSelectDeselectSvg.bind(this)}
              handleSettingsTab={this.handleSettingsTab.bind(this)} />
          </div>
          <Bubbles selectDeselectSvg={selectDeselectSvg}
                   settingsIsActive={settingsIsActive}
                   handleSelectDeselectSvg={this.handleSelectDeselectSvg.bind(this)}
                   handleSettingsTab={this.handleSettingsTab.bind(this)} />
          <Sidebar
            handleSelectDeselectSvg={this.handleSelectDeselectSvg.bind(this)}
            handleSettingsTab={this.handleSettingsTab.bind(this)} />
        </div>
      </div>
    );
  }
};
