const initialState = {
  fetching: false,
  fetched: false,
  token: "",
  expiresIn: null,
  createdAt: null,
  user: null,
  error: null,
  detached: false,
  loggedAs: "GUEST",
  preference: [],
  currentClient: null,
  currentSetting: {
    fetching: false,
    fetched: false,
    updating: false,
    updated: false,
    error: null,
    autoSend: false,
    mandatoryTest: false,
    enableDashboard: false,
    verifiedEmails: [],
    newsletterPreferences: [],
    themeFr: null,
    themeNl: null,
    themeEn: null,
  },
  uaWithoutHeader: false,
};
export const authReducer = (state = initialState, action) => {
  let result = { ...state };

  switch (action.type) {
    case "SET_AUTH": {
      result = action.auth == null ? { ...initialState } : { ...action.auth };
      break;
    }
    case "SET_AUTH_USER": {
      result = { ...state, user: action.user };
      break;
    }
    case "SET_AUTH_TOKEN": {
      result = { ...state, token: action.token };
      break;
    }
    case "SET_CURRENT_CLIENT_PENDING": {
      result = { ...state, updating: true, updated: false };
      break;
    }
    case "SET_CURRENT_CLIENT_FULFILLED": {
      let loggedAs = "CONTACT";
      let currentClient = action.payload;
      if (
        currentClient &&
        currentClient["authorisation"] &&
        currentClient["authorisation"].role
      ) {
        loggedAs = currentClient["authorisation"].role;
      }

      result = {
        ...state,
        updating: false,
        updated: true,
        loggedAs,
        detached: false,
        preference:
          (currentClient &&
            currentClient["authorisation"] &&
            currentClient["authorisation"].preferences) ||
          [],
        currentClient,
      };
      break;
    }
    case "SET_CURRENT_CLIENT_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updating: false,
        updated: false,
        error,
      };
      break;
    }
    case "SET_DETACHED_FROM_CLIENT": {
      result = {
        ...state,
        loggedAs: "USER",
        detached: true,
        preference: [],
        currentClient: null,
      };
      break;
    }
    case "SET_EXPIRES_IN": {
      result = { ...state, expiresIn: action.expiresIn };
      break;
    }
    case "SET_TOKEN_CREATED_AT": {
      result = { ...state, createdAt: action.createdAt };
      break;
    }
    case "SET_AUTH_LOGGED_AS": {
      result = { ...state, loggedAs: action.loggedAs };
      break;
    }
    case "FETCH_AUTH_USER_PENDING": {
      result = { ...state, fetching: true };
      break;
    }
    case "FETCH_AUTH_USER_FULFILLED": {
      const user = action.payload.data.data[0];

      const { clients } = user;
      let loggedAs = "GUEST";
      let currentClient = null;
      let hasClients = clients && clients.length > 0;
      let preference = [];
      if (hasClients) {
        loggedAs = "CONTACT";
        currentClient = clients.filter(({ isSelected }) => isSelected)[0];
        if (
          currentClient &&
          currentClient["authorisation"] &&
          currentClient["authorisation"].role
        ) {
          loggedAs = currentClient["authorisation"].role;
          preference = currentClient["authorisation"].preferences;
        }
      }

      result = {
        ...state,
        fetching: false,
        fetched: true,
        loggedAs,
        preference,
        user,
        currentClient,
      };
      break;
    }
    case "FETCH_AUTH_USER_REJECTED": {
      return {
        ...state,
        fetching: false,
        error: action.payload,
      };
      break;
    }
    case "FETCH_CLIENT_SETTINGS_PENDING": {
      let currentSetting = state.currentSetting;
      return {
        ...state,
        currentSetting: { ...currentSetting, fetched: false, fetching: true },
      };
      break;
    }
    case "FETCH_CLIENT_SETTINGS_FULFILLED": {
      let currentSetting = state.currentSetting;
      let data = action.payload.data.data[0];
      const { autoSend, mandatoryTest, enableDashboard } =
        data && data.emailingTestingPreferences
          ? data.emailingTestingPreferences
          : [];
      return {
        ...state,
        currentSetting: {
          ...currentSetting,
          fetched: true,
          fetching: false,
          autoSend: autoSend || false,
          mandatoryTest: mandatoryTest || false,
          verifiedEmails: data.verifiedEmails || [],
          newsletterPreferences: data.newsletterPreferences || [],
          enableDashboard: enableDashboard || false,
          themeEn: data.themeEn || "",
          themeFr: data.themeFr || "",
          themeNl: data.themeNl || "",
        },
      };
      break;
    }
    case "FETCH_CLIENT_SETTINGS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        currentSetting: {
          ...state.currentSetting,
          fetching: false,
          error,
        },
      };
      break;
    }
    case "SAVE_CLIENT_SETTINGS_PENDING": {
      let currentSetting = state.currentSetting;
      return {
        ...state,
        currentSetting: { ...currentSetting, updating: true, updated: false },
      };
      break;
    }
    case "SAVE_CLIENT_SETTINGS_FULFILLED": {
      let currentSetting = state.currentSetting;
      let { data } = action.payload.data;
      const { autoSend, mandatoryTest, enableDashboard } =
        data && data.emailingTestingPreferences
          ? data.emailingTestingPreferences
          : [];

      return {
        ...state,
        currentSetting: {
          ...currentSetting,
          updating: false,
          updated: true,
          autoSend,
          mandatoryTest,
          enableDashboard,
          verifiedEmails: data.verifiedEmails || [],
          newsletterPreferences: data.newsletterPreferences || [],
          themeEn: data.themeEn || "",
          themeFr: data.themeFr || "",
          themeNl: data.themeNl || "",
        },
      };
      break;
    }
    case "SAVE_CLIENT_SETTINGS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updated: false,
        updating: false,
        error,
      };
      break;
    }
    case "SET_UA_WITHOUT_HEADER": {
      result = { ...state, uaWithoutHeader: action.uaWithoutHeader };
      break;
    }
    default:
      return state;
  }
  return result;
};
