import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CAMPAIGN_STATUS,
  CAMPAIGN_TYPES,
  CAMPAIGN_RECIPIENTS_TYPE,
  LIST_PAGE_SIZES,
  LANGUAGE_FLAGS,
} from "Common";
import {
  setEmailPageSize,
  setCampaignListFilterStatus,
  setCampaignListFilterLanguage,
  setCampaignListFilterSubject,
  setCampaignListFilterType,
  initCampaignListFilter,
  setCampaignListFilterRecipientType,
  setCampaignListFilterDateRange,
} from "actions";
import _ from "i18n";
import { getLanguages } from "utils";
import TTPFilterHorizontalBar from "common/filter/TTPFilterHorizontalBar";

@connect((store) => {
  return {
    campaignsFilter: store.campaigns.list.filter,
    pageSize: store.campaigns.list.emailPageSize,
  };
})
export default class CampaignListFilter extends Component {
  constructor(props) {
    super(props);

    this.statusOptions = CAMPAIGN_STATUS.map(({ label, value, color }) => {
      return { label: _(label), value, color };
    });

    this.typeOptions = Object.values(CAMPAIGN_TYPES).map((type) => {
      return { value: type, label: _(type) };
    });

    this.languageOptions = getLanguages().map((lng) => {
      return {
        value: lng.key,
        label: (
          <span>
            <span className={`flag-icon  ${LANGUAGE_FLAGS[lng.key]}`} />
            &nbsp;&nbsp;{_(lng.value)}
          </span>
        ),
      };
    });

    this.recipientTypeOptions = CAMPAIGN_RECIPIENTS_TYPE.map((type) => {
      return { value: type, label: _(type) };
    });
  }

  handleCampaignListPageSizeChange = (selected) => {
    this.props.dispatch(setEmailPageSize(selected));
  };

  handleSelectType = (selected) => {
    this.props.dispatch(setCampaignListFilterType(selected));
  };

  handleSelectStatus = (selected) => {
    this.props.dispatch(setCampaignListFilterStatus(selected));
  };

  handleSelectLanguage = (selected) => {
    this.props.dispatch(setCampaignListFilterLanguage(selected));
  };

  handleSelectRecipientType = (selected) => {
    this.props.dispatch(setCampaignListFilterRecipientType(selected));
  };

  handleResetFilter = () => {
    this.props.dispatch(setEmailPageSize(LIST_PAGE_SIZES[0]));
    this.props.dispatch(initCampaignListFilter());
  };

  handleSearchInputChange = (word) => {
    this.props.dispatch(setCampaignListFilterSubject(word));
  };

  handleDateRangeChange = (dateRange) => {
    this.props.dispatch(setCampaignListFilterDateRange(dateRange));
  };

  render() {
    let { campaignsFilter, pageSize } = this.props;

    return (
      <div className="row medium-11" >
        <TTPFilterHorizontalBar
          filters={[
            {
              placeholder: _("type"),
              options: this.typeOptions,
              values: campaignsFilter.type,
              action: this.handleSelectType,
            },
            {
              placeholder: _("language"),
              options: this.languageOptions,
              values: campaignsFilter.language,
              action: this.handleSelectLanguage,
            },
            {
              placeholder: _("recipientType"),
              options: this.recipientTypeOptions,
              values: campaignsFilter.recipientType,
              action: this.handleSelectRecipientType,
            },
          ]}
          pageSize={pageSize}
          handleListPageSizeChange={this.handleCampaignListPageSizeChange}
          searchInputValue={campaignsFilter.subject}
          handleSearchInputChange={this.handleSearchInputChange}
          handleResetFilter={this.handleResetFilter}
          handleDateRangeChange={this.handleDateRangeChange}
          dateRange={campaignsFilter.dateRange}
          searchPlaceholder="search"
          cssClass="medium-12"
          selectedTab={campaignsFilter.status || null}
          onTabChange={this.handleSelectStatus}
          tabs={this.statusOptions}
        />
      </div>
    );
  }
}
