import React from "react";
import { connect } from "react-redux";
import { DragSource } from "react-dnd";
import { compose } from "redux";
import { NotificationManager } from "react-notifications";
import {
  addFeedToCurrentLayout,
  removeFeedFromLayout,
  addFeedToNewsletter,
} from "actions";
import { TEMPLATE_TYPES } from "constants";
import { formatDateRange } from "utils";
import { S3_FOLDER_URL, TTP_EVENT_URL } from "Config";
import _ from "i18n";
import { RESIZE_ARTICLE_COVER } from "../../constants";
import ToggleButton from "../ui/ToggleButton";
import { SVG_UP_ICON } from "../../services/svgIcones";

class SlotItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
    this.toogleDetails = () =>
      this.setState((prevState) => ({ showMore: !prevState.showMore }));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.id !== this.props.id ||
      nextProps.isSelected !== this.props.isSelected ||
      nextState.showMore !== this.state.showMore ||
      nextProps.viewMode !== this.props.viewMode ||
      nextProps.order !== this.props.order
    );
  }

  addSlot = () => {
    const { currentLayout, base, blocks } = this.props;
    const slot = formatSlot(this.props);

    if (
      base === "TEMPLATE" &&
      (!currentLayout || currentLayout.type !== slot.type)
    ) {
      if (!blocks.some(({ feeds, column }) => feeds.length < column)) {
        NotificationManager.info(_("All blocks are filled"));
        return;
      }
      this.props.addFeedToNewsletter(slot);
      return;
    }
    if (!currentLayout && base !== "TEMPLATE") {
      NotificationManager.info(_("Select a layout for your block first"));
      return;
    }

    if (currentLayout.type !== "SLOT_LAYOUT") {
      NotificationManager.info(
        _("Current layout is not the appropriate block type")
      );
      return;
    }

    this.props.addFeedToCurrentLayout(slot);
  };

  removeSlot = () => {
    const { removeFeedFromLayout, id } = this.props;
    removeFeedFromLayout(id);
  };

  render() {
    const {
      languages,
      startDateTime,
      endDateTime,
      speakers,
      isSelected,
      connectDragSource,
      viewMode,
      order,
    } = this.props;
    const listMode = viewMode === "LIST";
    const { showMore } = this.state;
    let speakersNames = [];

    if (speakers) {
      speakersNames = speakers.map((speaker, i) =>
        speaker.speakerName ? (
          <div key={`speaker-${i}`}>-- {speaker.speakerName}</div>
        ) : (
          ""
        )
      );
    }

    const lngSuffix = extractSlotLanguage(languages);
    let dateTime = formatDateRange(
      startDateTime,
      endDateTime,
      lngSuffix.toLowerCase()
    );

    return connectDragSource(
      <div className={`medium-${listMode ? 12 : 6} column`} style={{ order }}>
        <div
          className={`feed ${showMore && "feed-expanded"} ${
            isSelected ? "selected" : ""
          }`}
        >
          <img className="feed__expander" src="/img/utils/expander.png" />
          <div className="flex-container flex-dir-column">
            <div className="flex-container">
              <div>
                <div
                  className="feed__media"
                  style={{
                    backgroundImage: `url(${S3_FOLDER_URL}/events-folder${
                      this.props["pictureUrl" + lngSuffix]
                    })`,
                  }}
                />
              </div>
              <div className="feed_content">
                <div className="feed_author">
                  <div className="flex-container flex-dir-column">
                    <span className="date">{dateTime}</span>
                  </div>
                </div>
                <h3 className="feed_content-title">
                  <a target="_blank">{this.props[`name${lngSuffix}`]}</a>
                </h3>
                {listMode && !showMore && (
                  <p className="feed_content-summary">
                    {this.props[`description${lngSuffix}`].slice(0, 210) +
                      "..."}
                  </p>
                )}
              </div>
            </div>
            {showMore && (
              <div className="feed-detail">
                <p className="feed_content-summary m-t-s m-b-s">
                  {this.props[`description${lngSuffix}`]}
                </p>
                {speakersNames && speakersNames.length > 0 && (
                  <div className="feed__tags">
                    <span>{speakersNames}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <span
            className="feed__category"
            style={{ backgroundColor: "rgb(103, 102, 102)" }}
          >
            {_("speakers")}
          </span>
          <ToggleButton
            cssClass={"apply-icon"}
            onToggleSelection={
              isSelected ? this.removeSlot : this.addSlot.bind(this)
            }
            checked={isSelected}
          />
          {isSelected && <div className={"feed-order"}>{order + 1}</div>}
          <div className="read-more" onClick={this.toogleDetails}>
            {_(showMore ? "close" : "see more")}
            <span className={`${!showMore && "down"}`}>{SVG_UP_ICON}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLayout: state.themes.newsletter.currentLayout,
  event: state.filters.slots.filter.event,
  blocks: state.themes.newsletter.blocks,
  base: state.themes.newsletter.base,
});
const mapDispatchToProps = (dispatch) => ({
  addFeedToCurrentLayout: (article) =>
    dispatch(addFeedToCurrentLayout(article)),
  addFeedToNewsletter: (slot) => dispatch(addFeedToNewsletter(slot)),
  removeFeedFromLayout: (id) => dispatch(removeFeedFromLayout(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragSource(
    TEMPLATE_TYPES["SLOT_LAYOUT"],
    {
      beginDrag: (props) => {
        return formatSlot(props);
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )
)(SlotItem);

const formatSlot = (props) => {
  const { id, title, languages, startDateTime, endDateTime, speakers, event } =
    props;
  const lngSuffix = extractSlotLanguage(languages);
  let dateTime = formatDateRange(
    startDateTime,
    endDateTime,
    lngSuffix.toLowerCase()
  );
  return {
    id,
    name: props[`name${lngSuffix}`],
    language: lngSuffix,
    description: props[`description${lngSuffix}`],
    picture: `${S3_FOLDER_URL}/events-folder${
      props["pictureUrl" + lngSuffix]
    }${RESIZE_ARTICLE_COVER}`,
    speakers: `-- ${speakers
      .map((speaker) => (speaker.speakerName ? speaker.speakerName : ""))
      .join(" ,")}`,
    startDate: dateTime,
    url: `${TTP_EVENT_URL}/${lngSuffix.toLowerCase()}/events/${event}/slot/${id}`,
    ReadMore: _("Read more", lngSuffix && lngSuffix.toLowerCase()),
    type: TEMPLATE_TYPES["SLOT_LAYOUT"],
  };
};

const extractSlotLanguage = (languages) => {
  let lngSuffix = "Fr";
  try {
    if (languages && JSON.parse(languages)) {
      let allSlotLngs = Object.keys(JSON.parse(languages));
      lngSuffix = (allSlotLngs.length && allSlotLngs[0]) || "Fr";
    }
  } catch (error) {
    console.log("error parsing slot languages", error);
  }

  return lngSuffix;
};
