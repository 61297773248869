import React, { Component } from "react";

export default class TypeEmailsFetching extends Component {

  render() {
    let fetchingItems = [];
    for (let i = 0; i < 3; i++) {
      fetchingItems.push(<div key={i} className="generic-email-container">
        <div key={`generic-email-item-fetching-${i}`} className="generic-email-header row">
          <h3 className="gradiant" style={{ width: '40%', height: '40%' }}></h3>
          <div className="generic-email-header__right-side">
            <span className="icon">
              <i className='icon-arrow-down' />
            </span>
          </div>
        </div>
      </div>);
    }
    return (<div>
      {fetchingItems}
    </div>
    );
  }
}
