import React, { Component } from 'react'

import _ from 'i18n';
import { SVG_ICONS } from '../../config/Common';

export default class PageHeader extends Component {

    render() {
        const {header, subHeader, cssClass, icon, children} = this.props;

        return <div className={`page-header ${cssClass || ""}`}>
                       <div className="page-header__icon">
                            <img src={"/img/icons/" + SVG_ICONS[icon] || "EMAIL.svg"} />
                       </div>
                       <div className="page-header__info">
                           <div>
                                <h3>
                                    {_(header)}
                                </h3>
                                <p>
                                    {_(subHeader)}
                                </p>
                            </div>
                       </div>
                       <div className="page-header__children">
                           {children}
                       </div>
                </div>
    }
}
