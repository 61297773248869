import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchUsers } from 'thunks';
import User from './User';
import UserFetching from '../fetching/UserFetching';
import NotFound from 'notFound/NotFound';
import { clearSourcesTokens, initUsersSettings } from 'actions';

@connect((store) => {
  return {
    users: store.users.items,
    fetching: store.users.fetching,
    usersError: store.users.error,
    role: store.settings.filter.role,
    searchWord: store.settings.filter.searchWord,
    _requests: store.params.sourcesTokens
  }
})

export default class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      requestsTokens: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(initUsersSettings());
    dispatch(fetchUsers());
  }

  componentDidUpdate(prevProps, prevState) {
    const { role, searchWord, dispatch, usersError, fetching } = this.props;
    if (prevProps.role !== role || prevProps.searchWord !== searchWord) {
      this.setState({
        currentPage: 0
      });
      dispatch(fetchUsers());
    }

    const classList = document.getElementById('setting-users').classList;
    if (!fetching && usersError != null && usersError.code == 404) {
      classList.remove('row');
      document.getElementById('setting-users').style.padding = 0;
    } else if (!classList.contains('row')) {
      classList.add('row');
      document.getElementById('setting-users').style.padding = '4rem 0';
    }
  }

  render() {
    const { fetching, users, usersError, handleSelectDeselectSvg, handleSettingsTab } = this.props;

    if (fetching) {
      return (
        <UserFetching />
      );
    }

    if (usersError != null && usersError.code == 404) {
      return <NotFound />;
    }

    if (users == undefined || users.length == 0) {
      return null;
    }

    return (
      <div className="small-12 row">
        {users.map(user => <User {...user} key={`user-${user.id}`}
          handleSelectDeselectSvg={handleSelectDeselectSvg}
          handleSettingsTab={handleSettingsTab} />)}
      </div>
    );
  }

  componentWillUnmount() {
    const { _requests } = this.props;

    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    this.props.dispatch(clearSourcesTokens());
  }
};
