import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "i18n";
import EBoxFetching from "../../fetching/EBoxFetching";
import {fetchEBoxSms, getEBoxSmsData} from "../../../actions/thunks/eBox";
import {SMSItem} from "./SMSItem";
import NotFound from "notFound/NotFound";
import TTPPaginator from "../../common/list/TTPPaginator";
import {
  initEboxSms,
  setEBoxSmsPageSize,
  setEBoxSmsPaginationPage,
  setEboxSmsSearchWord, setEboxSmsStatus
} from "../../../actions/actions/eBox";
import PageSize from "../../common/list/PageSize";
import SearchBox from "../../common/filter/SearchBox";
import SmsViewer from "../viewer/SmsViewer";
import StatusFilterSMS from "./StatusFilterSMS";

@connect()
class ListSMS extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSmsViewerOpen: false,
    }
  };

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(initEboxSms());
    dispatch(fetchEBoxSms());
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter } = prevProps;
    if (filter !== this.props.filter) {
      this.props.dispatch(fetchEBoxSms());
    }
  }

  renderPaginate() {
    const {eboxSms, filter: {pageSize, paginationPage}} = this.props;
    if (eboxSms.error != null && eboxSms.error.code === 404) {
      return "";
    }
    return <TTPPaginator onPageChange={this.handleEboxLSmsListPageChange}
                         pageSize={pageSize}
                         nbResult={eboxSms.nbResult}
                         paginationPage={paginationPage}/>;
  }

  handleEboxLSmsListPageChange = ({selected}) => {
    this.props.dispatch(setEBoxSmsPaginationPage(selected + 1));
  };

  handleSelectPageSize = (pageSize) => {
    this.props.dispatch(setEBoxSmsPageSize(pageSize));
  };

  handleSearchInputChange = (searchWord) => {
    this.props.dispatch(setEboxSmsSearchWord(searchWord));
  };

  viewEBoxSmsPreview = (item) => {
    this.props.dispatch(getEBoxSmsData(item.id));
    this.setState({ isSmsViewerOpen: true });
  };

  onViewerClose = () => {
    this.setState({ isSmsViewerOpen: false });
  };

  handleSelectStatus = (status) => {
    this.props.dispatch(setEboxSmsStatus(status));
  };

  render() {
    const {eboxSms: {items, fetching}, filter: {pageSize}, language, filter} = this.props;
    const { isSmsViewerOpen } = this.state;

    return <div className="sms-ebox-list">
      <div className="row settings-filter">
        <StatusFilterSMS
          selected={filter.status}
          onClick={this.handleSelectStatus}
        />
        <SearchBox onChange={this.handleSearchInputChange} />
        <PageSize pageSize={pageSize} onChange={this.handleSelectPageSize.bind(this)}/>
      </div>
      {fetching ? <EBoxFetching/> :
        (items && items.length > 0) ?
          <div className="small-12 small-centered columns">
            {items.map(item => <SMSItem key={item.id} item={item}
                                        language={language}
                                        viewEBoxSmsPreview={this.viewEBoxSmsPreview}
                                        status={filter.status}/>)}
            {this.renderPaginate()}
          </div> : <NotFound mainClass="small-12" text='ebox_empty'/>
      }
      {isSmsViewerOpen && <SmsViewer isOpen={true} onClose={this.onViewerClose} />}
    </div>
  }

}

const mapStateToProps = (store) => ({
  eboxSms: store.ebox.smsList,
  filter: store.ebox.smsList.filter,
  language: store.params.lng,

});

export default connect(mapStateToProps, null)(ListSMS);

