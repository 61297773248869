import * as actions from 'actions';
import {
  getClientThemes,
} from 'TamtamAPI';
import { pushSourceToken } from 'utils';

export const fetchBlocks = (params = null) => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    let clientId = currentClient.id;
    let sourceToken = pushSourceToken('FETCH_TEMPLATES', dispatch);
    const type = (params && params.type) || [getState().themes.blocks.filter.type];
    let data = { type, isBlock: true, layoutIds: params && params.layoutIds };

    return dispatch(actions.fetchBlocks(getClientThemes(token, clientId, data, null, sourceToken)));
  };
};
