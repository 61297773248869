import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LANGUAGE_FLAGS, SVG_ICONS } from 'Common';
import _ from 'i18n';
import TextForm from './TextForm';
import { CSSTransition } from 'react-transition-group';
import { initCurrentEmailData, setTTPDialogCustomData } from "actions";
import { inIframe } from "../../../../services/utils";
import EmptyEmail from './EmptyEmail';
import { resetGenericEmail } from "thunks";

class Email extends Component {

  handleCurrentEmailAction = (action) => {
    const { initCurrentEmailData, item, resetGenericEmail, confirm } = this.props;
    let currentData = {
      ...item,
      action,
    };
    if (inIframe() && this.refs.emailItem) {
      this.refs.emailItem.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
      const windowHeight = parseInt(this.props.windowHeight) || 720;
      const offsetTop = this.refs.emailItem.offsetTop;
      currentData["offsetTop"] = (offsetTop + windowHeight + 64 > window.innerHeight) ? (window.innerHeight - windowHeight - 64) : offsetTop;
    }
    if (!item.id) {
      currentData.theme = this.props[`globalTheme${ucFirst(lng)}`] || null;
    }

    if (action === "RESET") {
      confirm({
        approvedAction: () => resetGenericEmail(),
        message: _('reset_generic_email'),
        proceedBtnLabel: _('reset email'),
      });
    }

    setTimeout(() => {
      initCurrentEmailData(currentData);
    }, 400);
  };

  closeTextEditor = () => {
    this.props.initCurrentEmailData();
  }

  renderEmail = () => {
    const { item } = this.props;
    const { theme, fromName, fromEmail, object, text, language } = item;
    return (
      <div className="generic-email-list__item">
        <div className="generic-email__content">
          <div className="generic-email__preview-container">
            <img className="generic-email__preview"
              src={`${(theme && theme.preview) ? theme.preview : '/img/theme/no-theme.png'}`} />
          </div>
          <div className="generic-email__model">
            <div className="header">
              <div>
                <h6 className="generic-email__title">
                  <span>{`${_('Object')} :`}</span>
                  <span>{object}</span>
                </h6>
                <h6 className="generic-email__title">
                  <span>{`${_('from')} :`}</span>
                  <span>{fromName} {`<${fromEmail}>`}</span>
                </h6>
              </div>
              {<div className="header__actions">
                <div className="drop-down-option">
                  <span className="icon-options" />
                  <ul className="menu drop-down-option__left">
                    <li onClick={() => this.handleCurrentEmailAction('EDIT')}>
                      {_('change')}
                    </li>
                    <li onClick={() => this.handleCurrentEmailAction('RESET')}>
                      {_('reset')}
                    </li>
                  </ul>
                </div>
              </div>}
            </div>
            <div className="generic-email__model__content">
              <div className="generic-email__text"
                dangerouslySetInnerHTML={{ __html: text }} />
              <div className="generic-email__model__title">
                {<div className="title">
                  <button type="button" onClick={() => this.handleCurrentEmailAction('EDIT_TEXT')}>
                    <i className="icomoon icon-tt-edit" />
                    {_('Edit text')}
                  </button>
                </div>}
                <span
                  className={`language flag-icon  ${LANGUAGE_FLAGS[language]} icon-right`} />
              </div>
            </div>
          </div>
        </div>
        <div className="generic-email__detail">
          <div className="generic-email__actions">
            <button type="button" onClick={() => this.handleCurrentEmailAction('TEST')}>
              <img src={"/img/icons/" + SVG_ICONS["TEST"]} />
              {_('test')}
            </button>
            <button type="button" onClick={() => this.handleCurrentEmailAction('PREVIEW')}>
              <img src={"/img/icons/" + SVG_ICONS["EYE"]} />
              {_('preview')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { id, object, text, language, targetType } = this.props.item;
    const { currentEmail } = this.props;
    const showTextEditor = currentEmail.action === 'EDIT_TEXT' && currentEmail.language === language && currentEmail.targetType === targetType;

    return (
      <div className="small-11" ref={"emailItem"}>
        <CSSTransition in={showTextEditor} timeout={400} classNames="text-form" unmountOnExit>
          <TextForm closed={this.closeTextEditor} object={object} body={text ? text : ""} />
        </CSSTransition>
        {!showTextEditor && (id ? this.renderEmail() : <EmptyEmail lng={language} type={targetType} />)}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  globalThemeFr: state.genericEmail.currentSetting.themeFr,
  globalThemeNl: state.genericEmail.currentSetting.themeNl,
  globalThemeEn: state.genericEmail.currentSetting.themeNl,
  currentEmail: state.genericEmail.currentSetting.currentEmail,
  windowHeight: state.params.windowHeight,
})

const mapDispatchToProps = dispatch => ({
  initCurrentEmailData: (data) => dispatch(initCurrentEmailData(data)),
  resetGenericEmail: () => dispatch(resetGenericEmail()),
  confirm: data => dispatch(setTTPDialogCustomData(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Email);
