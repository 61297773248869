import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setCurrentEmailData } from "actions";
import _ from 'i18n';
import Viewer from 'common/Viewer';


const mapStateToProps = (store, ownProps) => ({
  currentEmail: store.genericEmail.currentSetting.currentEmail,
  windowHeight: store.params.windowHeight,
});

const mapDispatchToProps = dispatch => ({
  closePreview: () => dispatch(setCurrentEmailData({ action: null })),
});
@connect(mapStateToProps, mapDispatchToProps)
export default class Preview extends Component {

  render() {
    const { fromEmail, fromName, body, object, action, offsetTop } = this.props.currentEmail;
    const { windowHeight } = this.props;
    if (action != 'PREVIEW') {
      return null;
    }

    let viewerStyle = { content: { transform: "unset" } };
    if (windowHeight) {
      viewerStyle.content["height"] = `calc(${windowHeight}px - 4rem)`;
    }
    if (offsetTop) {
      viewerStyle.content["top"] = `calc(${offsetTop}px + 6rem)`;
    }

    return (
      <Viewer isOpen={true} onClose={this.props.closePreview} preview={body} viewerStyle={viewerStyle}>
        <div className="viewer__aside__container">
          <div className="viewer__aside__block">
            <div className="viewer__aside__title">{_('from')} :</div>
            <div className="viewer__aside__subtitle"><stong>{fromName}</stong> &lt;{fromEmail}&gt;</div>
          </div>
          <div className="viewer__aside__block">
            <div className="viewer__aside__title">{_('subject')} :</div>
            <div className="viewer__aside__text">{object}</div>
          </div>
        </div>
      </Viewer>
    )
  }
}
