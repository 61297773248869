import React, { Component } from 'react';
import { connect } from 'react-redux';

import Container from './Container';
import AddButtons from './AddButtons';
import AddBlockSideBar from './AddBlockSidebar';
import { getThemeBackgroundColor } from "../../services/block/theme";

const mapStateToProps = (state) => ({
  currentTemplate: state.themes.newsletter.currentTemplate,
});

@connect(mapStateToProps)
class TemplatePreview extends Component {
  constructor(props) {
    super(props);
    this.state = { backgroundColor: null };
  }

  componentDidMount() {
    const { currentTemplate } = this.props;
    if (currentTemplate) {
      this.setState({ backgroundColor: getThemeBackgroundColor(currentTemplate) });
    }
  }

  render() {
    const { backgroundColor } = this.state;
    return (
      <div>
        <div id="template-preview">
          <Container backgroundColor={backgroundColor} />
        </div>
        <AddButtons />
        <AddBlockSideBar />
      </div>
    );
  }
}
export default TemplatePreview;
