const initialFilter = {
  searchWord: '',
  pageSize: 8,
  paginationPage: 1,
  mandatory: null
};

const initialData = {
  id: null,
  mandatory: 0,
  nameFr: "",
  nameEn: "",
  nameNl: "",
  descriptionFr: "",
  descriptionEn: "",
  descriptionNl: "",
};

const initialStateForLists = {
  items: [],
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  nbResult: 0,
  filter: initialFilter,
  error: null,
  data: initialData
}
export const mailingListsReducer = (state = initialStateForLists, action) => {
  switch (action.type) {
    case 'INIT_MAILING_LIST': {
      return { ...state, data: initialData };
    }

    case 'INIT_MAILING_LISTS_FILTER': {
      return initialStateForLists;
      break;
    }

    case 'INIT_MAILING_LISTS_ITEMS': {
      return { ...state, items: [] };
      break;
    }

    case 'FETCH_MAILING_LIST_PENDING': {
      return { ...state, fetching: true, fetched: false };
      break;
    }

    case 'FETCH_MAILING_LIST_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        items: data,
        fetching: false,
        fetched: true,
        error: null,
        nbResult
      };
      break;
    }
    case 'FETCH_MAILING_LIST_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          }
          break;
        default:
      }
      return {
        ...state,
        items: [],
        fetching: false,
        fetched: false,
        nbResult: 0,
        error
      };
      break;
    }
    case 'SET_MAILING_LIST_DATA': {
      return { ...state, data: { ...state.data, ...action.data }};
      break;
    }
    case 'SAVE_MAILING_LIST_PENDING': {
      return { ...state, updating: true, updated: false };
      break;
    }
    case 'SAVE_MAILING_LIST_FULFILLED': {
      let { items, filter, nbResult } = state;
      let newList = action.payload.data.data;
      let exist = false;
      let newItems = items.map(item => {
        if (item.id === newList.id) {
          exist = true;
          return { ...item, ...newList };
        }
        return item;
      });

      if (!exist) {
        newItems.unshift(newList);
        nbResult++;
      }

      if (newItems.length > filter.pageSize) {
        newItems.pop();
      }

      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: true,
        updating: false,
        updated: true,
        error: null,
        filter: initialFilter,
        nbResult
      };
      break;
    }
    case 'SET_MAILING_LIST_SEARCH_WORD': {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
      break;
    }
    case 'SET_MAILING_LIST_PAGINATION_PAGE': {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber }, items: [] };
      break;
    }
    case 'SET_MAILING_LIST_PAGE_SIZE': {
      return { ...state, filter: { ...state.filter, pageSize: action.pageSize, paginationPage: 1 } };
      break;
    }
    case 'SET_MAILING_LISTS_MANDATORY_FILTER': {
      return { ...state, filter: { ...state.filter, mandatory: action.mandatory, paginationPage: 1 } };
      break;
    }
    case 'REMOVE_MAILING_LIST_FULFILLED': {
      const { payload } = action;
      let { items, nbResult } = state;
      let error = action.payload;
      error = {
        code: 404
      };
      let newItems = items.filter(item => item.id != payload.deletedId);
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: newItems.length === 0 ? error : null,
        items: newItems,
        nbResult: nbResult - 1
      };
      break;
    }
    default:
      return state;
  }
};
