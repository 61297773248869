import React, { Component } from "react";
import TTPFilterHorizontalBar from "../common/filter/TTPFilterHorizontalBar";
import { connect } from "react-redux";
import { fetchArticlePagesOptions } from "thunks";
import { getLanguages } from "utils";
import debounce from "lodash.debounce";
import _ from "i18n";
import {
  initFormations,
  setFormationsLanguage,
  setFormationsSearchWord,
  setFormationsType,
  setFormationsTags,
} from "../../actions/actions/formation";
import { fetchEventsTagsSuggestions } from "../../actions/thunks/filters";

@connect((store) => {
  return {
    filter: store.filters.formations.filter,
    lng: store.params.lng,
    articlePagesOptions: store.articles.articlePagesOptions.items,
    viewMode: store.themes.newsletter.viewMode,
  };
})
class FormationListFilter extends Component {
  constructor(props) {
    super(props);

    this.trainingOptions = [
      { value: "future", label: _("live") },
      { value: "replay", label: _("replay") },
      { value: "current", label: _("this_week") },
    ];

    this.languageOptions = getLanguages().map((lng) => {
      return { value: lng.key, label: _(lng.value) };
    });

    this.loadTagsSuggestions = debounce(this.loadTagsSuggestions, 1000);
  }

  handleSelectLanguage = (selected) => {
    this.props.dispatch(setFormationsLanguage(selected));
  };

  handleSelectFormationType = (selected) => {
    this.props.dispatch(setFormationsType(selected));
  };

  handleTagsChange = (selected) => {
    this.props.dispatch(setFormationsTags(selected));
  };

  loadTagsSuggestions = (searchWord, callback) => {
    let { lng } = this.props;
    lng = lng.charAt(0).toUpperCase() + lng.slice(1);
    this.props
      .dispatch(fetchEventsTagsSuggestions({ searchWord, lng }))
      .then((res) => {
        const tags = res.value.data.data.map((t) => {
          return { value: t.id, label: t["name" + lng], color: "#000000" };
        });
        callback(tags);
      })
      .catch((er) => {
        callback([]);
      });
  };

  handleSearchInputChange = (searchWord) => {
    this.props.dispatch(setFormationsSearchWord(searchWord));
  };

  handleResetFilter = () => {
    this.props.dispatch(initFormations());
  };

  render() {
    const { filter, lng, viewMode } = this.props;

    let filters = [
      {
        placeholder: _("language"),
        options: this.languageOptions,
        values: filter.language,
        action: this.handleSelectLanguage,
      },
    ];
    filters.push({
      placeholder: _("Training_type"),
      options: this.trainingOptions,
      values: filter.trainingType,
      action: this.handleSelectFormationType,
      isSimple: true,
    });
    let asyncFilters = [
      {
        placeholder: "tags",
        handleChange: this.handleTagsChange,
        values: filter.tags,
        loadSuggestions: this.loadTagsSuggestions,
      },
    ];
    return (
      <TTPFilterHorizontalBar
        filters={filters}
        asyncFilters={asyncFilters}
        searchInputValue={filter.searchWord}
        handleSearchInputChange={this.handleSearchInputChange}
        language={lng}
        handleResetFilter={this.handleResetFilter}
        cssClass="ttp-medium-9"
        viewMode={viewMode}
      />
    );
  }
}

export default FormationListFilter;
