import React, { Component } from 'react';
import { connect } from 'react-redux';
import TTPTab from "ui/tabs/TTPTab";
import SidePageHeader from 'common/SidePageHeader';
import { setCurrentNavPage, setThemeLanguage } from "actions";
import { persistClientSettings } from 'thunks';
import _ from 'i18n';
import { onError, onSuccess, ucFirst } from 'utils';
import ThemesSettings from "../common/theme/ThemesSettings";
import VerifiedEmails from "./emails/VerifiedEmails";
import DashboardConfiguration
  from "./DashboardConfiguration/DashboardConfiguration";

class DefaultSettings extends Component {

  constructor(props) {
    super(props);
    this.switchTab = (tab) => this.setState({ currentTab: tab });

    this.state = {
      currentTab: "DEFAULT_TEMPLATE",
      hideSave: true,
    };
  }

  componentDidMount() {
    const { setCurrentNavPage, auth: { currentSetting } } = this.props;

    const { themeFr, themeNl, themeEn } = currentSetting;
    this.setState({ themeEn, themeFr, themeNl });
    setCurrentNavPage("SETTINGS");
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeLanguage, auth: { currentSetting } } = this.props;
    const { themeFr, themeNl, themeEn, fetched } = currentSetting;

    const stateActiveTheme = this.state[`theme${ucFirst(activeLanguage)}`];
    const propsActiveTheme = this.props.auth.currentSetting[`theme${ucFirst(activeLanguage)}`];
    if (prevState[`theme${ucFirst(activeLanguage)}`] != stateActiveTheme ) {
      if (!stateActiveTheme || (stateActiveTheme.id != propsActiveTheme.id)) {
        this.setState({ hideSave: false });
      } else {
        this.setState({ hideSave: true });
      }
    }
    if (prevProps.auth.currentSetting.fetching && fetched) {
      this.setState({ themeEn, themeFr, themeNl });
    }
  }

  saveDefaultThemes = () => {
    const { themeEn, themeFr, themeNl } = this.state;
    const data = { themeEn, themeFr, themeNl };
    this.props.saveDefaultTheme(data).then(res => {
      this.setState({ hideSave: true });
      onSuccess(res);
    }, err => onError(err));
    this.props.setThemeLanguage(null);
  }

  applyDefaultThemes = (data) => {
    this.setState({ ...data, hideSave: false });
  }

  cancel = () => {
    const { currentSetting: { themeFr, themeNl, themeEn } } = this.props.auth;
    this.setState({ themeEn, themeFr, themeNl });
    this.props.setThemeLanguage(null);
  }

  render() {
    const { currentTab, themeEn, themeFr, themeNl, hideSave } = this.state;
    return <div className="default-settings-page">
      <SidePageHeader subHeader="settings"/>
      <div className="ttp-tab-container">
        <TTPTab tabs={tabs} activeTab={currentTab} selectTab={this.switchTab} />
        {currentTab == "DEFAULT_TEMPLATE" && <div>
          {(!hideSave) && <div className="button-container">
            <button className="btn text-uppercase secondary"
              onClick={this.cancel}>{_('cancel')}</button>
            <button
              className={`btn text-uppercase primary`}
              onClick={this.saveDefaultThemes}>
              {_('save')}
            </button>
          </div>
          }
          <ThemesSettings
            languages={['fr', 'nl', 'en']}
            themeEn={themeEn}
            themeFr={themeFr}
            themeNl={themeNl}
            onApply={this.applyDefaultThemes}
            isDefaultThemes={true}
          />
        </div>}
        <div className={`${currentTab != "EMAILS" ? "hide" : ""}`}>
          <VerifiedEmails />
        </div>
        <div className={`${currentTab != "CONFIGURATIONS" ? "hide" : ""}`}>
          <DashboardConfiguration />
        </div>
      </div>
    </div>
  }
}

export const tabs = [
  {
    id: 'DEFAULT_TEMPLATE',
    label: 'default model',
  },
  {
    id: 'EMAILS',
    label: _('verified senders'),
  },
  /*
  {
    id: 'CONFIGURATIONS',
    label: _('CONFIGURATIONS'),
  }*/
]

const mapStateToProps = state => ({
  auth: state.auth,
  fetching: state.auth.currentSetting.fetching,
  activeLanguage: state.themes.filter.language,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentNavPage: (currentPage) => dispatch(setCurrentNavPage(currentPage)),
  saveDefaultTheme: (data) => dispatch(persistClientSettings(data)),
  setThemeLanguage: (lng) => dispatch(setThemeLanguage(lng)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DefaultSettings);
