import * as actions from 'actions';
import { getIdentitiesVerificationStatus, getAppDynamicsFields } from 'TamtamAPI';

export const fetchSesEmailsStatuses = () => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.fetchSesEmailsStatuses(
    getIdentitiesVerificationStatus(token)
  ));
};

export const fetchAppDynamicsFields = (appName) => (dispatch, getState) => {
  const {token} = getState().auth;

  dispatch(actions.setAppNameDynamicFields(appName));
  return dispatch(
    actions.fetchAppDynamicsFields(
      getAppDynamicsFields(token, appName).then (res =>
        ({data : res.data , appName}))
    )
  );
};
