import React from 'react';

import _ from 'i18n';
import { TTPSelector } from '../common/TTPSelector';

const EditorSelectorTab = (props) => {

  const { onTypeSelect } = props;

  return (
    <div className={`tab-pane row align-center boxes active`}>
      <TTPSelector tabName={_("editor")} tabLabel={_("emailing")} icon="DRAG_DROP"
        tabDescription={_("Easily create your own custom newsletter.")}
        onClick={() => onTypeSelect("/campaign?type=dnd")} />
      <TTPSelector tabName={_("editor")} tabLabel={_('newsletter')} icon="NEWSLETTER"
        tabDescription={_('Create your custom template email with simple drag&drop.')}
        onClick={() => onTypeSelect('/campaign?type=newsletter')} />
      <TTPSelector tabName={_("editor")} tabLabel={_('Rich Text')} icon="TEXT_EDITOR"
        tabDescription={_('Use the rich text editor to create simple emails.')}
        onClick={() => onTypeSelect('/campaign?type=text')} />
      <TTPSelector tabName={_("editor")} tabLabel={_('From URL')} icon="FROM_URL"
        tabDescription={_('Create a campaign by importing the HTML code directly from a hosted URL.')}
        onClick={() => onTypeSelect('/campaign?type=web')} />
    </div >
  )
}
export default EditorSelectorTab;
