import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import { SVG_DELETE_ICON, SVG_ADD_ICON, onError, onSuccess } from 'utils';
import ThemeCategory from "./ThemeCategory";
import { setTTPDialogCustomData } from "../../../actions/actions/params";
import DIALOG_TYPES from "../../../constants/TTPDialog";
import { fetchTemplatesCategories, removeThemeCategory } from 'thunks';
import { setCurrentNavPage } from "actions";
import SidePageHeader from 'common/SidePageHeader';
import ThemeCategoryForm from "./ThemeCategoryForm";
import BackDrop from "../../ui/BackDrop";
import NotFound from 'notFound/NotFound';

@connect()
class ThemeCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listFormOpened: false,
      showDetails: false,
      expandedId: null,
    };
  }

  componentDidMount() {
    const { fetchTemplatesCategories, setCurrentNavPage } = this.props;
    fetchTemplatesCategories();
    setCurrentNavPage("THEME_CATEGORIES_LIST");
  }

  handleDeleteThemeCategory = (id) => {
    const { dispatch } = this.props;
    dispatch(setTTPDialogCustomData({
        "approvedAction": () => this.deleteThemeCategory(id),
        "message": _("confirmDeleteCategory"),
        "proceedBtnLabel": _("yesDelete"),
        "abortBtnLabel": _("no"),
        'title': "Confirmation of deletion",
        'type': DIALOG_TYPES["ALERT"],
        'svgIcon': SVG_DELETE_ICON
    }));
  };

  deleteThemeCategory = (id) => {
    const { dispatch } = this.props;
    dispatch(removeThemeCategory(id)).then((res) => {
      onSuccess(res);
    },err => onError(err));
  };

  getCategories = () => {
    const { categories } = this.props;
    return categories.map(category => {
      let labelFr = category['nameFr'];
      let labelEn = category['nameEn'];
      let labelNl = category['nameNl'];
      return { id: category.id , labelFr, labelEn, labelNl, count: category.count, organization: category.organization };
    })
  };

  toggleListFormSideBar = () => {
    this.setState({listFormOpened: !this.state.listFormOpened});
  };

  toogleDetails = (id) => {
    this.setState({ showDetails: !this.state.showDetails, expandedId: id });
  };

  render() {
    const {lng, fetching, updating} = this.props;
    const {listFormOpened, showDetails, expandedId } = this.state;

    let categories = this.getCategories().filter(category => category['organization']);

    return (
        <div id="theme-category-form" className="side-no-tabs-page">
           <SidePageHeader subHeader="theme categories"/>
          <div className="white-container">
            <div className="row columns white-container--title">
              <h3>{_("theme categories")}</h3>
              <p>{_("Gérez les catégories de vos thèmes")}</p>
            </div>
            {!fetching ? <div className="row">
                {categories.length > 0 ? categories.map(({ id, labelFr, labelEn, labelNl, count }) => {
                    return <ThemeCategory id={id} nameFr={labelFr} nameEn={labelEn} nameNl={labelNl} count={count} handleDeleteThemeCategory={this.handleDeleteThemeCategory.bind(this)} toogleDetails={this.toogleDetails.bind(this)} showDetails={showDetails} expandedId={expandedId} />
                  })
                  : <NotFound mainClass="small-10" text={_('Nothing Found')} />
                }
              </div>
              :
              <div className="row">
                <div className="columns medium-4 small-6"><div className="theme-category gradiant"/></div>
                <div className="columns medium-4 small-6"><div className="theme-category gradiant"/></div>
                <div className="columns medium-4 small-6"><div className="theme-category gradiant"/></div>
              </div>
            }
            <div className="campaign-bubbles bubbles">
              <div className="circleSvg" onClick={this.toggleListFormSideBar}>
                {SVG_ADD_ICON}
                <span
                  style={(lng === "fr" ? {paddingLeft: '.5rem'} : {paddingLeft: '1rem'})}>{_('New theme category')}
            </span>
              </div>
            </div>
            <BackDrop show={listFormOpened} clicked={this.toggleListFormSideBar}/>
            <ThemeCategoryForm
              opened={listFormOpened}
              toggleListFormSideBar={this.toggleListFormSideBar}
              updating={updating}
            />
          </div>
        </div>
    )
  }
}

const mapStateToProps = store => ({
  categories: store.themes.categories.items,
  fetching: store.themes.categories.fetching,
  updating: store.themes.updating,
  lng: store.params.lng,
});

const mapDispatchToProps = dispatch => ({
  fetchTemplatesCategories: () => dispatch(fetchTemplatesCategories()),
  setCurrentNavPage: (currentPage) => dispatch(setCurrentNavPage(currentPage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeCategoryList);
