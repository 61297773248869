import { TEMPLATE_TYPES } from 'constants';
import { LAYOUT_STRING_CODES, ALL_LAYOUT_STRING_CODES } from './helpers';

export const findRowLayoutType = (rowHtml) => {
  let type;

  for (let layoutType in LAYOUT_STRING_CODES) {
    if (layoutType == 'ALL') {
      continue;
    }
    let keys = LAYOUT_STRING_CODES[layoutType].map(layout => layout.key);
    const regExp = new RegExp(keys.join('|'));
    if (regExp.test(rowHtml)) {
      type = layoutType;
      break;
    }
  }

  return type || TEMPLATE_TYPES['UNREFERENCED_BLOCK']
}

export const findRowColumnCount = (row, type) => {
  if (TEMPLATE_TYPES['UNREFERENCED_BLOCK'] == type) {
    return 1;
  }

  let column = 1;
  LAYOUT_STRING_CODES[type].filter(code => code.main).forEach(({ key }) => {
    column = Math.max(column, (row.match(new RegExp(key, 'g')) || []).length);
  })

  return column;
}

/**
 * Index dynamic fields (ex ARTICLE_INTRODUCTION__0)
 * to support multiple article/slot/speaker per row
 */
export const indexLayoutDynamicFields = (content, column = 1) => {
  const newContent = ALL_LAYOUT_STRING_CODES.reduce((acc, { key }) => {
    let index = 0;
    const counts = (content.match(new RegExp(key, 'g')) || []).length;
    let range = 0;
    return acc.replace(new RegExp(key, 'g'), match => {
      if (range++ == (counts / column)) {
        index++;
        range = 1;
      }
      return `${match}__${index}`;
    })
  }, content + "");

  return newContent;
}

/**
 * Remove field indexes before saving template
 */
export const removeDynamicFieldsIndexes = (content) => {
  const newContent = ALL_LAYOUT_STRING_CODES.reduce((acc, { key }) => {
    return acc.replace(new RegExp(`${key}__\\d`, 'g'), () => {
      return key;
    })
  }, content + "");

  return newContent;
}
