import React, { Component } from 'react';
import TTPFilterHorizontalBar from '../common/filter/TTPFilterHorizontalBar';
import {
  setArticlesLanguage, setArticlesSearchWord, setArticlesTags, setArticlesCategories,
  initArticles, setArticlesPages
} from "actions";
import { connect } from 'react-redux';
import { fetchCategoriesSuggestions, fetchTagsSuggestions, fetchArticlePagesOptions } from 'thunks';
import { getLanguages } from 'utils';
import debounce from "lodash.debounce";
import _ from 'i18n';

@connect((store) => {
  return {
    filter: store.articles.filter,
    lng: store.params.lng,
    articlePagesOptions: store.articles.articlePagesOptions.items,
    viewMode: store.themes.newsletter.viewMode,
  }
})
class ArticlesListFilter extends Component {

  constructor(props) {
    super(props);

    this.languageOptions = getLanguages().map(lng => {
      return { value: lng.key, label: _(lng.value) }
    });

    this.loadTagsSuggestions = debounce(this.loadTagsSuggestions, 1000);
    this.loadCategorySuggestions = debounce(this.loadCategorySuggestions, 1000);
  }

  componentDidMount() {
    this.props.dispatch(fetchArticlePagesOptions());
  }

  handleSelectLanguage = (selected) => {
    this.props.dispatch(setArticlesLanguage(selected))
  };


  handleSelectArticlePages = (selected) => {
    this.props.dispatch(setArticlesPages(selected))
  };

  handleTagsChange = (selected) => {
    this.props.dispatch(setArticlesTags(selected));
  }


  loadTagsSuggestions = (searchWord, callback) => {
    let { lng } = this.props;
    lng = lng.charAt(0).toUpperCase() + lng.slice(1);
    this.props.dispatch(fetchTagsSuggestions({ searchWord, lng }))
      .then(res => {
        const tags = res.value.data.data.map(t => { return { value: t.id, label: t["name" + lng], color: "#000000" } })
        callback(tags);
      }).catch(er => {
        callback([]);
      })
  }

  handleCategoryChange = (selected) => {
    this.props.dispatch(setArticlesCategories(selected));
  }

  loadCategorySuggestions = (searchWord, callback) => {
    let { lng } = this.props;
    lng = lng.charAt(0).toUpperCase() + lng.slice(1);
    this.props.dispatch(fetchCategoriesSuggestions({ searchWord, lng }))
      .then(res => {
        const categories = res.value.data.data.map(t => { return { value: t.id, label: t["name" + lng], color: t.colorCode } })
        callback(categories);
      }).catch(er => {
        callback([]);
      })
  }


  handleSearchInputChange = (searchWord) => {
    this.props.dispatch(setArticlesSearchWord(searchWord));
  }

  handleResetFilter = () => {
    this.props.dispatch(initArticles());
  }

  render() {
    const { filter, lng, articlePagesOptions, viewMode } = this.props;
    let filters = [{ placeholder: _("language"), options: this.languageOptions, values: filter.language, action: this.handleSelectLanguage }];
    if (articlePagesOptions && articlePagesOptions.length > 0) {
      filters.push({ placeholder: _("article page"), options: articlePagesOptions, values: filter.articlePages, action: this.handleSelectArticlePages })
    }
    let asyncFilters = [
      { placeholder: "categories", handleChange: this.handleCategoryChange, values: filter.categories, loadSuggestions: this.loadCategorySuggestions },
      { placeholder: "tags", handleChange: this.handleTagsChange, values: filter.tags, loadSuggestions: this.loadTagsSuggestions },
    ];
    return <TTPFilterHorizontalBar
      filters={filters}
      asyncFilters={asyncFilters}
      searchInputValue={filter.searchWord}
      handleSearchInputChange={this.handleSearchInputChange}
      language={lng}
      handleResetFilter={this.handleResetFilter}
      cssClass="ttp-medium-9"
      viewMode={viewMode}
    />
  }
}

export default ArticlesListFilter;
