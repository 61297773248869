import React, {Component} from 'react';
import { connect } from 'react-redux';
import PercentageChart from "./PercentageChart";

@connect((store) => {
  return {
    dataAuthorizationMode: store.dashboard.dataAuthorizationMode
  };
})
export default class AuthorizationModeChart extends Component {
  render() {
    return (
      <PercentageChart dataBar={this.props.dataAuthorizationMode} />
    );
  }
}
