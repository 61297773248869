import React, { Component } from 'react';
import { SubMenuLink } from "../../SubMenuLink";
import { connect } from 'react-redux';

import _ from "i18n";
import { APP_ENV } from 'Config';

@connect((store) => {
  return {
    currentNavPage: store.params.currentNavPage,
    auth: store.auth,
  }
})

export default class SMSBills extends Component {

  render() {
    let { detached, currentClient } = this.props.auth;
    let isActive = (this.props.currentNavPage == "SMS_BILLS");
    if (detached) {
      return null;
    }
    // limit to IEC tempo
    if (APP_ENV == "production" && currentClient.id != 8) {
      return null;
    }
    return <SubMenuLink pathname="/sms_bills" title="sms finances" icon="FINANCES_SMS_LIGHT" />;
  }
};
