const initialState = {
    items: [],
    fetching: false,
    fetched: false,
    error: null,
};
export const emailTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_GENERIC_EMAIL_TYPES_PENDING":
            return {
                ...state,
                fetching: true,
                fetched: false
            };
        case "FETCH_GENERIC_EMAIL_TYPES_REJECTED":
            return {
                ...state,
                fetching: false,
                items: [],
                error: {
                    code: 404,
                },
            };
        case "FETCH_GENERIC_EMAIL_TYPES_FULFILLED":
            const {data, nbResult} = action.payload.data;
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                items: data,
                nbResult,
            };
    }

    return state;
}