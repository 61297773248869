import React, { Component } from 'react';
import TTPFilterHorizontalBar from "../../common/filter/TTPFilterHorizontalBar";
import {
  setEmailSettingsPageSize, setEmailsSettingsTargetAppFilter, setEmailsSettingsStatusFilter
} from "../../../actions/actions/generic-emailings/settings";
import { connect } from "react-redux";
import _ from 'i18n';
import { GE_SETTING_STATUS } from "constants";
import { getSupportedAppsOptions } from '../../../services/apps';

const mapStateToProps = state => ({
  pageSize: state.genericEmail.settings.pageSize,
  paginationPage: state.genericEmail.settings.paginationPage,
  filter: state.genericEmail.settings.filter
});

const mapDispatchToProps = dispatch => ({
  setEmailSettingsPageSize: (inputValue) => dispatch(setEmailSettingsPageSize(inputValue)),
  setEmailsSettingsTargetAppFilter: (select) => dispatch(setEmailsSettingsTargetAppFilter(select)),
  setEmailsSettingsStatusFilter: (select) => dispatch(setEmailsSettingsStatusFilter(select))
});


@connect(mapStateToProps, mapDispatchToProps)
export default class SettingFilter extends Component {
  constructor(props) {
    super(props);

    this.targetAppOptions = getSupportedAppsOptions();

    this.statusOptions = GE_SETTING_STATUS.map(({ key, value }) => ({ value, label: _(key) }));
  };

  handleSelectTargetApp = (selected) => {
    this.props.setEmailsSettingsTargetAppFilter(selected);
  };

  handleSelectStatus = (selected) => {
    this.props.setEmailsSettingsStatusFilter(selected);
  };

  handleEmailsSettingsPageSizeChange = (inputValue) => {
    this.props.setEmailSettingsPageSize(inputValue)
  };



  render() {
    const { pageSize, filter } = this.props;
    return (
      <div className="row">

        <TTPFilterHorizontalBar

          filters={[
            { placeholder: _("Application"), options: this.targetAppOptions, values: filter.targetApp, action: this.handleSelectTargetApp }
          ]}
          pageSize={pageSize}
          handleListPageSizeChange={this.handleEmailsSettingsPageSizeChange}
          searchInputValue={"2"}
          handleSearchInputChange={null}
          searchPlaceholder=""
          selectedTab={filter.status || null}
          onTabChange={this.handleSelectStatus}
          tabs={this.statusOptions}
          cssClass="medium-12"

        />
      </div>
    );





  }


};

