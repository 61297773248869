import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'i18n';

export default class ColoredSelect extends Component {

    constructor(props) {
        super(props);

        this.statusOptions = props.statuses.map(({ title, value, color }) => { return { label: _(title), value, color } });
        this.statusOptions.unshift({ label: _("all"), value: "", color: "#ccc" });
    }

    render() {
        const { onChange, value } = this.props;
        return <div className="float-left margin-right-auto">
            <Select
                placeholder={_("filter by status")}
                isSearchable={true}
                options={this.statusOptions}
                value={this.statusOptions.filter(s => s.value == value)}
                onChange={onChange}
                className='filter-select'
                classNamePrefix="ttp-select"
                styles={colourStyles}
            />
        </div>
    }

}

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
    },
});

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color;
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected ? data.color : isFocused ? color + "22" : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? true ? 'white' : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};