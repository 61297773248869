import React, { Component } from "react";

import {getLanguages} from 'utils';
import _ from 'i18n';

export default class SelectLanguages extends Component {
  render () {
    let { selectClass, language, onLanguageChange, all, id} = this.props;

    return (<select  id={id} name="language" className={selectClass || ""} value={language} onChange={onLanguageChange? onLanguageChange:null}>
            {all && <option value="all">{_('all').toUpperCase()}</option>}
            {getLanguages().map(lng => {
                return <option key={lng.key} value={lng.key}>{_(lng.value).toUpperCase()}</option>
              })
            }
            </select>
          );
  }
}
