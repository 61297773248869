import axios from '../axios-api';
import { updateSourceToken } from 'utils';

let getEBoxSourceToken;

export const getEBox = (token, { pageSize, paginationPage, status, searchWord, eboxOwnerId, isFolderEbox, recipientType }, sourceToken = null) => {
  const requestUrl = `/mailing/ebox-mail-list`;
  let sort = [];
  let filter = [];

  if (status) {
    filter.push({
      property: "status",
      value: status,
      operator: "eq"
    });
  }

  if (recipientType) {
    filter.push({
      property: "recipientType",
      value: recipientType,
      operator: "eq"
    });
  }

  if (searchWord) {
    filter.push({
      property: "sender",
      value: searchWord,
      operator: "like"
    });
  }

  sort.push({
    property: "createdAt",
    dir: "desc"
  });

  let params = {
    access_token: token,
    limit: pageSize,
    start: (paginationPage - 1) * pageSize,
  };

  if (eboxOwnerId) {
    params["eboxOwnerId"] = eboxOwnerId;
  }
  params["isFolderEbox"] = isFolderEbox ? 1 : 0;

  params.sort = JSON.stringify(sort);
  params.filter = JSON.stringify(filter);

  getEBoxSourceToken = updateSourceToken(getEBoxSourceToken, sourceToken);

  return axios.get(requestUrl,
    {
      cancelToken: getEBoxSourceToken.token,
      params
    }
  );
};

export const getMailData = (token, mailId) => {
  const requestUrl = `/mailing/ebox-mail-data`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      mailId
    }
  });
};

export const getEBoxAttachments = (token, otherEboxId, pageSize, paginationPage, filename) => {
  const requestUrl = `/mailing/ebox-attachments-list`;

  let params = {
    access_token: token,
    eboxOwnerId: otherEboxId,
    limit: pageSize,
    start: (paginationPage - 1) * pageSize,
    filename: filename
  };

  return axios.get(requestUrl, { params });
};

export const getEboxOwner = (token, ownerId) => {
  const requestUrl = `/mailing/contact/member`;
  const params = {
    access_token: token,
    memberId: ownerId,
  };
  return axios.get(requestUrl, { params });
};

export const getCountStatus = (token, { eboxOwnerId, isFolderEbox }) => {
  const requestUrl = `/mailing/ebox-mail-list/count`;
  let params = {
    access_token: token,
    eboxOwnerId,
    isFolderEbox: isFolderEbox ? 1 : 0
  };
  return axios.get(requestUrl, { params });
};

export const deleteEBoxMail = (token, id) => {
  const requestUrl = `/mailing/campaign-recipient/${id}`;
  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('_method', 'delete');
  return axios.post(requestUrl, formData);
};

//E-box SMS

export const getEBoxSms = (token, { pageSize, paginationPage, status, searchWord }) => {
  const requestUrl = `/mailing/ebox-sms-list`;
  let sort = [];
  let filter = [];

  if (status) {
    filter.push({
      property: "status",
      value: status,
      operator: "eq"
    });
  }

  if (searchWord) {
    filter.push({
      property: "sender",
      value: searchWord,
      operator: "like"
    });
  }

  sort.push({
    property: "createdAt",
    dir: "desc"
  });

  let params = {
    access_token: token,
    limit: pageSize,
    start: (paginationPage - 1) * pageSize,
  };

  params.sort = JSON.stringify(sort);
  params.filter = JSON.stringify(filter);

  return axios.get(requestUrl, { params });
};

export const getSmsData = (token, smsId) => {
  const requestUrl = `/mailing/ebox-sms-data`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      smsId
    }
  });
};

