import React, {Component} from 'react';
import {imageValid, getFileType, getFileContext} from "../../../services/utils";

export class Attachment extends Component {

  render() {
    const {url, onGallery, openPreview, oneColumn, type, context} = this.props;
    let imgUrl = url, show = false, isDoc = false;
    if (url.endsWith(".pdf")) {
      if (url.search("api.tamtam.pro") < 0 && url.search(/s3\..*\.amazonaws.com\/tamtam\/.*\/billing/) < 0 && (url.search(/tamtam.*amazonaws.com/) > -1 ||
        url.search("s3.tamtam.pro") > -1 || url.search(/s3\..*\.amazonaws.com\/tamtam/) > -1)) {
        imgUrl = url.replace(/(tamtam.*amazonaws.com|s3\..*\.amazonaws.com\/tamtam)/, "s3.tamtam.pro") + "/w400.jpg";
      } else {
        imgUrl = "/img/icons/pdf.svg";
        isDoc = true;
      }
    } else if (url.endsWith(".docx") || url.endsWith(".doc")) {
      imgUrl = "/img/icons/doc.svg";
      isDoc = true;
    } else if ((url.endsWith(".jpeg") || url.endsWith(".jpg") || url.endsWith(".png")) === false) {
      imageValid(imgUrl, "", imgUrl = "/img/icons/doc.svg");
      isDoc = true;
    }

    if (type === "ALL" || getFileType(url) === type) {
      show = true;
    } else if (context === "ALL" || getFileContext(url) === context) {
      show = true;
    }
    return (show &&
      <div
        className={`attachment__container ${onGallery ? (oneColumn ? "small-12" : "small-6") : ""} ${isDoc ? "icon" : ""}`}>
        <a onClick={openPreview ? openPreview : ""} target={onGallery ? "gallery-iframe" : "_blank"} href={url}>
          <img
          src={imgUrl || "/img/icons/doc.svg"}
          onError={(e)=>{e.target.onerror = null; e.target.src="/img/icons/404.svg";e.target.style.width="90%"}}
          />
        </a>
      </div>);
  }
}
