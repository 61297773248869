import React, { Component } from 'react'
import _ from 'i18n';
import Application from "../common/Application";

export class ItemHeader extends Component {
    render() {
        const { targetApp, isPublic, cssClass } = this.props;
        return (
            <div className={`item__header ${cssClass}`}>
              <Application name={targetApp}/>
                <span className="scope">
                    <span className={`scope__status ${isPublic ? "public" : "private"}`} />
                    {isPublic ? _('library') : _('my models')}
                </span>
            </div>
        )
    }
}
export default ItemHeader
