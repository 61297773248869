const initialStateForSettings = {
  items: [],
  fetching: false,
  nbResult: 0,
  error: null,
  filter: {
    targetApp: null,
    status: null,
    target: null
  },
  paginationPage: 1,
  pageSize: 8
};
export const emailsSettingsReducer = (state = initialStateForSettings, action) => {
  switch (action.type) {
    case 'INIT_EMAILS_SETTINGS': {
      return initialStateForSettings;
    }
    case 'FETCH_EMAILS_SETTINGS_PENDING': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'FETCH_EMAILS_SETTINGS_FULFILLED': {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult || 0,
      };
      break;
    }
    case 'FETCH_EMAILS_SETTINGS_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error
      };
      break;

    }
    case "SET_EMAILS_SETTINGS_PAGINATION_PAGE": {
      return { ...state, paginationPage: action.pageNumber };
    }
    case 'SET_EMAILS_SETTINGS_PAGE_SIZE': {
      return { ...state, pageSize: action.pageSize, paginationPage: 1 };
      break;
    }


    case 'SET_EMAILS_SETTINGS_STATUS_FILTER': {
      return { ...state, filter: { ...state.filter, status: action.status }, paginationPage: 1 };
      break;
    }


    case 'SET_EMAILS_SETTINGS_TARGET_APP_FILTER': {
      return { ...state, filter: { ...state.filter, targetApp: action.targetApp }, paginationPage: 1 };
      break;
    }


  }
  return state;
};

const initialStateForEmails = {
  items: [],
  fetching: false,
};

const initialStateForCurrentEmail = {
  text: "",
  originalTextId: null,
  theme: null,
  language: "fr",
  targetType: null,
  settingsId: null,
  id: null,
  fromEmail: "",
  fromName: "",
  object: "",
  body: "",
  updating: false,
  action: null,
  source: "TAMTAM",
};

const initialStateForCurrentSetting = {
  id: null,
  fromNameFr: "",
  fromNameNl: "",
  fromNameEn: "",
  fromEmailFr: "",
  fromEmailNl: "",
  fromEmailEn: "",
  initialized: 0,
  languages: ['fr', 'nl'],
  targetApp: "EVENT",
  target: null,
  types: [],
  status: null,
  themeFr: null,
  themeNl: null,
  themeEn: null,
  updatedAt: null,
  updating: false,
  updated: false,
  fetching: false,
  fetched: false,
  emails: initialStateForEmails,
  currentEmail: initialStateForCurrentEmail,
};

export const currentSettingReducer = (state = initialStateForCurrentSetting, action) => {
  switch (action.type) {
    case 'INIT_CURRENT_EMAILING_SETTING_DATA': {
      return { ...initialStateForCurrentSetting, ...action.data };
    }
    case 'FETCH_CURRENT_EMAILING_SETTING_PENDING': {
      return { ...state, fetching: true, fetched: false };
    }
    case 'FETCH_CURRENT_EMAILING_SETTING_FULFILLED': {
      const { id, fromNameFr, fromNameNl, fromNameEn, fromEmailFr, fromEmailNl, fromEmailEn, initialized, languages, targetApp, target, types, themeFr, themeNl, themeEn, status, updatedAt } = action.payload.data.data[0];
      return {
        ...state, ...{ id, fromNameFr, fromNameNl, fromNameEn, fromEmailFr, fromEmailNl, fromEmailEn, initialized, languages, targetApp, target, types, themeFr, themeNl, themeEn, status, updatedAt }
        , fetching: false, fetched: true
      };
    }
    case 'FETCH_CURRENT_EMAILING_SETTING_REJECTED': {
      return { ...state, id: null, fetching: false, fetched: false };
    }
    case 'SET_CURRENT_EMAILING_SETTING_DATA': {
      return { ...state, ...action.data };
    }
    case 'SAVE_EMAILS_SETTINGS_PENDING': {
      return { ...state, updating: true, updated: false };
    }
    case 'SAVE_EMAILS_SETTINGS_FULFILLED': {
      const { id, fromNameFr, fromNameNl, fromNameEn, fromEmailFr, fromEmailNl, fromEmailEn, initialized, languages, targetApp, target, status, updatedAt, types } = action.payload.data.data;
      return { ...state, ...{ id, fromNameFr, fromNameNl, fromNameEn, fromEmailFr, fromEmailNl, fromEmailEn, initialized, languages, targetApp, target, status, updatedAt, types }, updating: false, updated: true };
    }
    case 'SAVE_EMAILS_SETTINGS_REJECTED': {
      return { ...state, updating: false, updated: false };
    }
    case 'FETCH_GENERIC_EMAILS_PENDING': {
      return { ...state, emails: { ...state.emails, fetching: true } };
    }
    case 'FETCH_GENERIC_EMAILS_FULFILLED': {
      return { ...state, emails: { items: action.payload.data.data, fetching: false } };
    }
    case 'FETCH_GENERIC_EMAILS_REJECTED': {
      return { ...state, emails: { ...state.emails, fetching: false } };
    }
    case 'SEND_GENERIC_EMAIL_TEST_PENDING': {
      return { ...state, currentEmail: { ...state.currentEmail, updating: true } };
    }
    case 'SEND_GENERIC_EMAIL_TEST_FULFILLED':
    case 'SEND_GENERIC_EMAIL_TEST_REJECTED': {
      return { ...state, currentEmail: { ...state.currentEmail, updating: false } };
    }
    case "INIT_CURRENT_EMAIL_DATA": {
      return { ...state, currentEmail: { ...initialStateForCurrentEmail, ...action.data } };
    }
    case "SET_CURRENT_EMAIL_DATA": {
      return { ...state, currentEmail: { ...state.currentEmail, ...action.data } }
    }
    case "SAVE_CURRENT_GENERIC_EMAIL_PENDING": {
      return { ...state, currentEmail: { ...state.currentEmail, updating: true } }
    }
    case "SAVE_CURRENT_GENERIC_EMAIL_REJECTED": {
      return { ...state, currentEmail: { ...state.currentEmail, updating: false } }
    }
    case "SAVE_CURRENT_GENERIC_EMAIL_FULFILLED": {
      let { emails: { items }, currentEmail: { theme } } = state;
      const email = action.payload.data.data;
      let found = false;
      items = items.map(e => {
        if (e.id === email.id) {
          found = true;
          return { ...email, theme };
        }
        return e;
      });
      if (!found) items.push({ ...email, theme });
      return {
        ...state,
        emails: { items, fetching: false },
        currentEmail: { ...initialStateForCurrentEmail, updating: false }
      }
    }
    default:
      return state;
  }



};
