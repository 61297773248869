import React, { Component } from "react";
import { connect } from 'react-redux';

import { setCurrentEmailCampaignData, setTTPDialogCustomData } from 'actions';
import _ from 'i18n';
import Target from "common/campaign/Target";
import EmailTagsInput from "../../common/campaign/EmailTagsInput";
import RecipientType from "../params/RecipientType";
import DIALOG_TYPES from "../../../constants/TTPDialog";
import {SVG_DELETE_ICON} from "../../../services/svgIcones";

const mapStateToProps = (state) => {
  return {
    opened: state.currentCampaign.email.currentSideBar == "RECIPIENTS",
    manualRecipients: state.currentCampaign.email.manualRecipients,
    recipientType: state.currentCampaign.email.recipientType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onManualRecipientsChange: (manualRecipients) => dispatch(setCurrentEmailCampaignData({ manualRecipients })),
    onDeleteAction: (data) => dispatch(setTTPDialogCustomData(data))
  }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class ManualRecipients extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.opened || this.props.opened;
  }

  handleOpenDeleteDialog() {
    const { onDeleteAction, onManualRecipientsChange } = this.props;
    onDeleteAction({
          approvedAction: () => onManualRecipientsChange([]),
          message: _("deleteManualRecipientsConfirm"),
          proceedBtnLabel: _("delete"),
          type: DIALOG_TYPES["ALERT"],
          title: "Confirmation of deletion",
          svgIcon: SVG_DELETE_ICON
    });
  }


  render() {
    const { opened, recipientType, manualRecipients, onDeleteAction, onManualRecipientsChange } = this.props;
    return <div id="campaign-recipent__tab" className={((opened && "MANUAL" === recipientType) ? '' : 'hide')}>
      <div className="sidebar__form">
        <h4 className="sidebar__header">{_('selectTarget')}</h4>
        <Target count={manualRecipients ? manualRecipients.length : 0} type="MANUAL" />
        <div className="small-6 m-b-l">
          <span className="param__title small-10">{_('recipientType')}</span>
          <div className="small-12">
            <RecipientType />
          </div>
        </div>
        <div style={{display: 'flex'}}>
          <span className="param__title small-10">{_('sendTo')}</span>
          {manualRecipients.length > 0 &&
            <div className="param__title float-right" style={{color: "#FF636E"}}
            onClick={this.handleOpenDeleteDialog.bind(this)}>
            {_('deleteAll')}
            </div>}
        </div>
        <EmailTagsInput
          cssClass="p-xs"
          emails={manualRecipients}
          changed={onManualRecipientsChange}
        />
      </div>
    </div>;
  }
}
