import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchClientSettings, setCurrentClient } from "../../actions/thunks";
import { Redirect } from "react-router-dom";

@connect((store) => ({
    auth: store.auth,
}))
export default class CommunityPage extends Component {

  componentDidMount(){
    const { match: { params: routeParams }, auth, dispatch } = this.props;
    let communityId = parseInt(routeParams?.communityId || 0);
    const community = auth.user?.clients?.find(client => client.id === communityId);
    if (community) {
      dispatch(setCurrentClient(community)).then(
        () => {
          dispatch(fetchClientSettings());
        });
    }
  }

  render() {
    return <Redirect to={{ pathname: '/' }} />;
  }
}
