import React, { Component } from "react";
import _ from 'i18n';
import ReactTooltip from "react-tooltip";
import Loader from "react-loaders";

export default class Target extends Component {

    render() {
        const { count, type, icon, containDynamicFields, isRecipientsFetching } = this.props;
        return (
            <div className="send-header" style={isRecipientsFetching ? { justifyContent: "center" } : {}}>
              {isRecipientsFetching ? <Loader type="line-spin-fade-loader" innerClassName={"send-header__loader"}/>
                : <span className={"send-header__count"}>{count}</span>
              }
                <span className="send-header__target-type">
                    <i className={`icon-left ${icon?icon:"icon-user"}`}></i>
                    {_(type)}
                </span>
                {containDynamicFields && <div className="sh-counter-topbar tooltip-info">
                    <span data-for="preview-tooltip" className="icon-info" data-tip={_('approximateCostInfo')}></span>
                    <ReactTooltip id="preview-tooltip" type='dark' effect="solid" multiline={true} place="bottom"/>
                </div>}
            </div>
        );
    }


}
