import React from "react";
import _ from "i18n";

const BroadcasterItem = (props) => {
  let {
    broadcastEmail,
    sharedEmailSentCount,
    clicksCount,
    isProcessing,
  } = props;
  return (
    <div className="inline-item">
      <p className="item-section1">
        {broadcastEmail && !isProcessing
          ? broadcastEmail
          : broadcastEmail && isProcessing
          ? _("waiting_for_campaign_to_be_sent_to") + " " + broadcastEmail
          : null}
      </p>
      <p className="separator"></p>
      <p className="item-section3">{_("Emails_opened")}</p>
      <p className="item-section3 second-section3">{sharedEmailSentCount}</p>
      <div className="progress-bar">
        <div className="progress-bar-container">
          <div
            className="progress-bar-content"
            style={sharedEmailSentCount ? { width: "100%" } : { width: "2%" }} // Example: 50% width
          ></div>
        </div>
      </div>
      <p className="separator"></p>
      <p className="item-section2">{_("clicks_count")} :</p>
      <p className="item-section2 second-section">
        {clicksCount} {_("Clicks")}
      </p>
    </div>
  );
};

export default BroadcasterItem;
