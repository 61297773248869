import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import _ from "i18n";
import TTPSelect from "../../common/TTPSelect";
import { LANGUAGE_FLAGS } from "Common";
import { getLanguages, inIframe, getMainEmail, getAppTypes } from "utils";
import { fetchSesEmailsStatuses, fetchGenericEmailTypes } from "thunks";
import { setCurrentEmailingSetting, initCurrentEmailingSettings, setTTPDialogCustomData } from "actions";
import { getSupportedAppsOptions, WTB_APP, PAYMENT_APP, GDPR_APP, UA_APP } from "../../../services/apps";
import FromTabs from "./FromTabs";
import TargetSelects from "./TargetSelects";

class SettingsStep extends Component {
  constructor(props) {
    super(props);
    this.targetAppOptions = getSupportedAppsOptions();

    this.languageOptions = getLanguages().map(({ key, value }) => {
      return { value: key, label: <span><span className={`flag-icon  ${LANGUAGE_FLAGS[key]}`} />&nbsp;&nbsp;{_(value)}</span> }
    });

    this.setActiveLanguage = (activeLanguage) => this.setState({ activeLanguage });
    this.hasError = (hasError) => this.setState({ hasError });

    this.state = {
      hasError: false,
      activeLanguage: "fr",
    }
  }

  componentDidMount() {
    const { fetchSesEmailsStatuses, sesEmailsFetched, fetchTypes } = this.props;

    if (!sesEmailsFetched) {
      fetchSesEmailsStatuses();
    }
    this.setCurrentEmailingSetting();
    fetchTypes();
  }

  setCurrentEmailingSetting = (data = null) => {
    const { typesFetched, fetchTypes, initCurrentEmailingSettings, orgCurrentSettings: { verifiedEmails, themeFr, themeNl, themeEn }, currentClient } = this.props;

    let setting = { themeFr, themeNl, themeEn };
    const from = getMainEmail(verifiedEmails);

    if (from) {
      ['Fr', 'Nl'].forEach(lng => {
        setting[`fromEmail${lng}`] = from.email;
        setting[`fromName${lng}`] = from.name;
      })
    }

    if (data) {
      setting["targetApp"] = data.targetApp;
    }

    if (setting["targetApp"] && [WTB_APP, GDPR_APP, UA_APP].indexOf(setting["targetApp"]) > -1) {
      setting["target"] = currentClient;
      if (typesFetched) {
        setting["types"] = getAppTypes(this.props.genericEmailTypes, setting["targetApp"]);
      } else {
        fetchTypes();
      }
    }

    if (setting["targetApp"] && setting["targetApp"] === PAYMENT_APP) {
      if (typesFetched) {
        setting["types"] = getAppTypes(this.props.genericEmailTypes, PAYMENT_APP);
      } else {
        fetchTypes();
      }
    }

    initCurrentEmailingSettings(setting);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentSetting, registeredEmails, sesEmailsFetched, orgCurrentSettings, genericEmailTypes, setSettingData } = this.props;

    const { target, types, targetApp } = currentSetting;
    const { hasError } = this.state;

    if (currentSetting.id != prevProps.currentSetting.id) {
      this.props.history.replace(`/email/${currentSetting.id ? currentSetting.id : ""}`);
    }

    if ([WTB_APP, GDPR_APP, PAYMENT_APP].indexOf(targetApp) > -1 && genericEmailTypes !== prevProps.genericEmailTypes && (!types || !types.length)) {
      setSettingData({ types: getAppTypes(genericEmailTypes, targetApp) });
    }

    if (prevProps.currentSetting != currentSetting || hasError != prevState.hasError || registeredEmails !== prevProps.registeredEmails && sesEmailsFetched) {
      const isValidated = !hasError && types && types.length > 0 && target != null;
      this.props.settingIsValid(isValidated);
    }

    if (!currentSetting.id && prevProps.orgCurrentSettings.fetching && orgCurrentSettings.fetched) {
      this.setCurrentEmailingSetting();
    }
  }

  handleLanguagesChange = (languages) => {
    const { activeLanguage } = this.state;
    if (!languages.some(lng => lng === activeLanguage)) {
      this.setState({ activeLanguage: languages[0] || null });
    }
    this.props.setSettingData({ languages });
  };

  render() {
    const { activeLanguage } = this.state;
    const { currentSetting, setSettingData, targetChanged, registeredEmails, sesEmailsFetched } = this.props;
    const { id, targetApp, languages, target } = currentSetting;
    const lockTarget = target && (id || inIframe());

    return (
      <div className="setting-step">
        <div className="row medium-12 title">
          <h3>{_("informations")}</h3>
        </div>
        <div className="field-container">
          {!lockTarget && <div className="field">
            <span className="param-label">{_('application')} </span>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={targetApp}
              placeholder={_('Application')}
              options={this.targetAppOptions}
              onChange={(targetApp) => this.setCurrentEmailingSetting({ targetApp })} />
          </div>}
          <TargetSelects
            targetChanged={targetChanged}
            onStateChange={(newState) => this.setState(newState)}
            lockTarget={lockTarget}
            activeLanguage={activeLanguage}
          />
          <div className="field">
            <span className="param-label">{_('languages')} </span>
            <TTPSelect
              notClearable={true}
              values={languages}
              placeholder={_('Languages')}
              options={this.languageOptions}
              onChange={this.handleLanguagesChange} />
          </div>
        </div>
        <FromTabs
          currentSetting={currentSetting}
          activeLanguage={activeLanguage}
          setActiveLanguage={this.setActiveLanguage}
          setSettingData={setSettingData}
          fromHasError={this.hasError}
          registeredEmails={sesEmailsFetched && registeredEmails}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng,
  registeredEmails: state.params.sesEmails.items,
  sesEmailsFetched: state.params.sesEmails.fetched,
  currentSetting: state.genericEmail.currentSetting,
  currentClient: state.auth.currentClient,
  orgCurrentSettings: state.auth.currentSetting,
  typesFetched: state.genericEmail.types.fetched,
  genericEmailTypes: state.genericEmail.types.items,
});

const mapDispatchToProps = dispatch => ({
  setSettingData: (data) => dispatch(setCurrentEmailingSetting(data)),
  fetchSesEmailsStatuses: () => dispatch(fetchSesEmailsStatuses()),
  initCurrentEmailingSettings: (data = {}) => dispatch(initCurrentEmailingSettings(data)),
  confirm: data => dispatch(setTTPDialogCustomData(data)),
  fetchTypes: () => dispatch(fetchGenericEmailTypes()),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)
  (SettingsStep);
