import React, { PureComponent } from "react";

export default class FormationItemFetching extends PureComponent {
  render() {
    return (
      <div className="article-fetching">
        <span className="article-fetching__category">
          <div className="article-fetching__category-label gradiant" />
        </span>
        <img className="article-fetching__drag" src="/img/utils/expander.png" />
        <div className="flex-container flex-dir-column">
          <div className="flex-container">
            <div className="article-fetching__img gradiant" />
            <div className="article-fetching__content">
              <div className="article-fetching_author">
                <div className="article-fetching_author-avatar gradiant" />
              </div>
              <div className="article-fetching__content-p">
                <div className="cell small-12 gradiant" />
                <div className="cell small-6 gradiant" />
                <div className="cell small-4 gradiant" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
