import React, { Component } from 'react';

import { LIST_PAGE_SIZES } from 'Common';

class PageSize extends Component {

    handlePageSizeChange = (event) => {
        this.props.onChange(event.target.value);
    };

    render() {
        const { pageSize, listPageSize } = this.props;

      return (
        <select className="ttp-page-size m-l-s" value={pageSize} onChange={this.handlePageSizeChange}>
          {(listPageSize ? listPageSize : LIST_PAGE_SIZES).map((pagesize, index) =>
            <option key={`page-size-${index}`}
                    value={pagesize}>{pagesize}</option>)}
        </select>
      );
    }
}

export default PageSize;
