import React from 'react';
import _ from 'i18n';

const warning = (props) => {
    const { languages, label, required, clicked } = props;
    return (
        <div className={`notes ${required && "alert"}`}>
            <div className="notes__warning-item">
                <div className="notes__warning-item__body">
                    <span>{_(label)} </span>
                    <div className="notes__warning-item__languages">
                        {
                            languages.map(lng => (
                                <div key={`lng-${lng}`} className="notes__warning-item__language-item" onClick={clicked}>
                                    <div className="language-icon">
                                        <img alt="" src={`/img/flags/${lng}.svg`} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default warning;
