const initialStateForSubscriptionUAReducer = {
  fetching: false,
  fetched: false,
  subscriptionData: {},
  subscription: {
    cmail: null,
    subscriberId: null,
    type: "",
  },
  error: null,
  saving: false,
  saved: false,
};

export const subscriptionUAReducer = (
  state = initialStateForSubscriptionUAReducer,
  action,
) => {
  switch (action.type) {
    case "INIT_SUBSCRIPTION_DETAILS": {
      return initialStateForSubscriptionUAReducer;
    }
    case "FETCH_SUBSCRIPTION_DETAILS_PENDING": {
      return { ...state, fetching: true, fetched: false };
    }
    case "FETCH_SUBSCRIPTION_DETAILS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        subscriptionData: action.payload.data,
        error: null,
      };
    }
    case "FETCH_SUBSCRIPTION_DETAILS_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: false,
        subscriptionData: null,
        error: action.payload,
      };
    }
    case "TOGGLE_SUBSCRIPTION_PENDING": {
      return { ...state, saving: true, saved: false };
    }
    case "TOGGLE_SUBSCRIPTION_FULFILLED": {
      return {
        ...state,
        saving: false,
        saved: true,
        subscription: action.payload.data,
        error: null,
      };
    }
    case "TOGGLE_SUBSCRIPTION_REJECTED": {
      return {
        ...state,
        saving: false,
        saved: false,
        subscription: null,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};
