import React, { Component } from 'react';
import { connect } from 'react-redux';

import Recipients from './Recipients';

import {
  setRecipientsStatsFilterLinkId,
  setRecipientsStatsFilterName,
  setRecipientsStatsFilterStatus,
} from 'actions';
import {
  SMS_RECIPIENTS_STATUS
} from 'Common';
import _ from 'i18n';

import 'flag-icon-css/css/flag-icon.css'
import { initRecipientsStatsFilterStatus } from 'actions';
import TTPPaginator from 'common/list/TTPPaginator';
import SearchBox from 'common/filter/SearchBox';
import PageSize from "common/list/PageSize";
import ColoredSelect from '../../../common/ColoredSelect';
import NotFound from 'notFound/NotFound';

@connect((store) => {
  return {
    recipients: store.campaigns.stats.recipients.list,
    recipientsFilter: store.campaigns.stats.recipients.filter,
  };
})

export default class SMSRecipientsStats extends Component {

  constructor(props) {
    super(props);

    this.state = { oldSearchQuery: '' };
  }

  componentWillUnmount() {
    this.props.dispatch(initRecipientsStatsFilterStatus());
  }

  handleSelectStatus = (inputValue) => {
    const { dispatch } = this.props;
    let status = inputValue.value;
    dispatch(setRecipientsStatsFilterStatus(status));
    this.props.renderRecipientStatsDatatable(1);
  }

  handleSearchInputEnterKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSearchSubmitClick(event);
    }
  }

  reloadData() {
    const { renderRecipientStatsDatatable, dispatch } = this.props;
    dispatch(setRecipientsStatsFilterStatus(null));
    dispatch(setRecipientsStatsFilterLinkId(null));
    renderRecipientStatsDatatable(1, 10);
  }

  handleRecipientsStatsListPageClick(data) {
    let selectedPage = data.selected;
    this.props.renderRecipientStatsDatatable(selectedPage + 1);
  }

  handleSelectRecipientsStatsListPageSize(event) {
    let pageSize = event.target.value;
    this.props.renderRecipientStatsDatatable(1, pageSize);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { recipients } = nextProps;

    if (recipients?.items?.length !== this.props.recipients?.items?.length) {
      window.dispatchEvent(new Event('resize'));
    }
    let condition1 = !recipients.fetching && recipients.fetched;
    let condition2 = !recipients.items.length || !recipients.error;

    return condition1 || condition2;
  }

  handleSearchChange = (word) => {
    const { dispatch, renderRecipientStatsDatatable } = this.props;
    let { oldSearchQuery } = this.state;
    if (word != oldSearchQuery) {
      dispatch(setRecipientsStatsFilterName(word));
      renderRecipientStatsDatatable(1);
    }
    this.setState({ oldSearchQuery: word });
  }

  render() {
    const { recipients, selectedStatus, recipientsFilter } = this.props;

    let headerColor = null;
    for (let i = 0; i < SMS_RECIPIENTS_STATUS.length; i++) {
      if (SMS_RECIPIENTS_STATUS[i].value == selectedStatus) {
        headerColor = SMS_RECIPIENTS_STATUS[i].color;
        break;
      }
    }

    return (
      <div id="recipients-details" className="stats-page__block">
        <h4 className="stats-label">{_('recipients')}</h4>
        <div className="row align-center">
          <div className="filter-container columns">
            <ColoredSelect statuses={SMS_RECIPIENTS_STATUS}
              onChange={this.handleSelectStatus}
              value={recipientsFilter.status} />
            <div className="search">
              <span className="refresh" onClick={this.reloadData.bind(this)}>
                <i className="icon-refresh"></i>
              </span>
            </div>
            <SearchBox onChange={this.handleSearchChange} />
            <PageSize pageSize={recipients.pageSize}
              onChange={this.handleSelectRecipientsStatsListPageSize.bind(this)} />
          </div>
        </div>
        {(recipients.items.length > 0) ?
          <Recipients headerColor={headerColor} /> : <NotFound text="empty_recipients_list" />}
        {(recipients.items.length > 0) &&
          <TTPPaginator
            onPageChange={this.handleRecipientsStatsListPageClick.bind(this)}
            pageSize={recipients.pageSize}
            nbResult={recipients.nbResult}
            paginationPage={recipients.paginationPage} />
        }
      </div>
    )
  }
}
