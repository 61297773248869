import React, { useRef, useEffect, useState } from 'react';
import { SVG_CHECK_ICON, SVG_CLOSE_ICON, SVG_EDIT_ICON } from '../../../../services/utils';


const LinkItem = (props) => {

  const textAreaEl = useRef(null);
  const [link, setLink] = useState(props.link);

  useEffect(() => {
    if (textAreaEl?.current) {
      textAreaEl.current.style.height = "1px";
      textAreaEl.current.style.height = `${25 + textAreaEl.current.scrollHeight}px`;
    }
  })

  const undoChange = () => {
    setLink(props.link);
    props.onCancel();
  }

  return (
    <div className="row ttp-datatable__row">
      <div className="small-4 p-l-xs p-r-xs">{props.label} </div>
      <div className="small-8 p-l-xs p-r-xs">
        {(props.editMode) ?
          <>
            <textarea
              ref={textAreaEl}
              className="m-b-xs"
              onChange={() => setLink(textAreaEl.current.value)}
              rows="3"
              value={link} />
            <div className="link-actions">
              <span className="m-r-xs" onClick={undoChange}>
                {SVG_CLOSE_ICON}
              </span>
              <span className="save" onClick={() => props.onSave(link)}>{SVG_CHECK_ICON}</span>
            </div>
          </>
          :
          <>
            {props.link}
            <span className="m-l-s edit" onClick={props.onClick}>
              {SVG_EDIT_ICON}
            </span>
          </>
        }
      </div>
    </div>
  );
}

export default LinkItem;
