import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubMenuLink } from "../../SubMenuLink";

import _ from "i18n";
import { APP_ENV } from 'Config';
import { IEC_ID } from 'Common';

@connect((store) => {
  return {
    currentNavPage: store.params.currentNavPage,
    auth: store.auth,
  }
})
export default class CreateCampaignSMS extends Component {

  render() {
    const { loggedAs, preference, currentClient } = this.props.auth;
    const hasSmsPrivileges = loggedAs == "ADMIN" || (preference && preference['allowCreateSms'] == 1);
    const allowCreateSms = hasSmsPrivileges && !(APP_ENV == "production" && currentClient && currentClient.id != IEC_ID);
    if (!allowCreateSms) {
      return null;
    }
    return <SubMenuLink pathname="/campaign-sms" title="Create sms campaigns" icon="CREATE_LIGHT" />;
  }
};
