import React, {PureComponent} from "react";
import {connect} from "react-redux";
import _ from 'i18n';
import Community from "./Community";
import { setCurrentClient } from 'thunks';
import {TTP_PORTAL_URL} from 'Config';

@connect((store) => {
  return {
    auth: store.auth,
  };
})

export class SelectCommunity extends PureComponent {

  renderOrganizations() {
    const {user} = this.props.auth;
    const {clients, selectedOrganization} = user;
    let communities = [...clients];

    if (!user) {
      return null;
    }
    if (selectedOrganization && selectedOrganization.id && !clients.find(client => client.id === selectedOrganization.id)) {
      communities.unshift({...selectedOrganization, disabled: true})
    }

    return communities.map(client => (
      <Community
        key={client.id}
        community={client}
        onClick={() => this.handleSelectOrganization(client)}
      />
    ));
  }

  handleSelectOrganization = (client) => {
    const {dispatch} = this.props;
    dispatch(setCurrentClient(client)).then(() => {
      window.location.reload();
    });
  };

  render() {
    return (
      <div id="select-community">
       <div className="medium-11">
          <button onClick={() => location.href = `${TTP_PORTAL_URL}`} className="sc__back-button medium-3">
            <img src="img/icons/portal.svg" className="sc__back-button_img" alt="back home"/>
          {_('back')}
        </button>
        <div className="sc__container medium-12">
          <div className="sc__header">
            <div>
              <h3>
                {_('selectCommunity')}
              </h3>
              <span>
                {_('listCommunity')}
              </span>
              </div>
            </div>
            <div className="sc__header_logo"/>
            <div className="sc__communities grid-x grid-margin-x">
              {this.renderOrganizations()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
