import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getLanguages } from 'utils';
import { setThemeSearchWord, setThemeListSort, setThemeLanguage, initThemeFilters, setThemeApplication, setThemeVisibility, setThemeScope, clearSourcesTokens } from "actions";
import _ from 'i18n';
import TTPFilterHorizontalBar from '../../common/filter/TTPFilterHorizontalBar';
import { TAMTAM_SCOPE, COMMUNITY_SCOPE, SHARED_MODELS_SCOPE } from "../../../constants";
import { getSupportedAppsOptions } from '../../../services/apps';
import StyledTabs from '../../ui/tabs/StyledTabs';
import { fetchTemplatesCategories } from '../../../actions/thunks';
import { setThemeCategory } from '../../../actions/actions';
import { getCategoriesOptions } from '../../../services/utils';

const mapStateToProps = (store) => ({
  sort: store.themes.filter.sortDirection,
  language: store.themes.filter.language,
  scope: store.themes.filter.scope,
  application: store.themes.filter.application,
  visibility: store.themes.filter.visibility,
  type: store.themes.filter.type,
  searchWord: store.themes.filter.searchWord,
  category: store.themes.filter.category,
  lng: store.params.lng,
  categories: store.themes.categories.items,
});

const mapDispatchToProps = dispatch => ({
  setThemeListSort: (selected) => dispatch(setThemeListSort(selected)),
  setThemeLanguage: (language) => dispatch(setThemeLanguage(language)),
  setThemeApplication: (app) => dispatch(setThemeApplication(app)),
  setThemeVisibility: (visibility) => dispatch(setThemeVisibility(visibility)),
  setThemeScope: (scope) => dispatch(setThemeScope(scope)),
  setThemeSearchWord: (word) => dispatch(setThemeSearchWord(word)),
  initThemeFilters: () => dispatch(initThemeFilters()),
  clearSourcesTokens: () => dispatch(clearSourcesTokens()),
  fetchTemplatesCategories: () => dispatch(fetchTemplatesCategories()),
  setThemeCategory: (category) => dispatch(setThemeCategory(category)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class ThemeListFilter extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      currentScope: COMMUNITY_SCOPE,
      scopeOptions: getCategoriesOptions(props.categories, props.lng)
    };
    this.languageOptions = getLanguages().map(lng => {
      return { value: lng.key, label: _(lng.value) }
    });

    this.applicationOptions = getSupportedAppsOptions();
    this.visibilityOptions = [
      { value: 1, label: _("visible") },
      { value: 2, label: _("masked") },
      { value: '', label: _("all") },
    ];
  }

  componentDidMount() {
    this.props.fetchTemplatesCategories();
  }

  componentDidUpdate(prevProps) {
    const { lng, categories } = this.props;
    if (prevProps.categories != categories) {
      this.setState({ scopeOptions: getCategoriesOptions(categories, lng) });
    }
  }

  handleSelectSort = (selected) => {
    this.props.setThemeListSort(selected);
  };

  handleSelectLanguage = (selected) => {
    this.props.setThemeLanguage(selected);
  };

  handleSelectApplication = (selected) => {
    this.props.setThemeApplication(selected);
  };

  handleSelectVisibility = (selected) => {
    this.props.setThemeVisibility(selected);
  };

  handleSearchInputChange(word) {
    this.props.setThemeSearchWord(word);
  };

  handleResetFilter = () => {
    this.props.initThemeFilters()
  }

  handleScopeChange = (selected) => {
    this.setState({ currentScope: selected });
    this.props.setThemeScope(selected ? selected : [COMMUNITY_SCOPE, TAMTAM_SCOPE, SHARED_MODELS_SCOPE]);
    this.props.setThemeCategory(null);
  }

  handleCategoryClick = (selectedCategory) => {
    this.props.setThemeCategory((selectedCategory == this.props.category) ? null : selectedCategory);
  }

  getCategoriesItems = () => {
    const { currentScope, scopeOptions } = this.state;

    return scopeOptions.reduce((acc, option) => {
      if (!currentScope || option.scope == currentScope) {
        const categories = option['categories'].map(({ id, label }) => ({ key: id, title: label }));
        acc = [...acc, ...categories];
      }

      return acc;
    }, [])
  }

  render() {
    let { sort, language, searchWord, application, category, visibility } = this.props;
    const { currentScope, scopeOptions } = this.state;

    let filters = [
      { placeholder: _("language"), options: this.languageOptions, values: language, action: this.handleSelectLanguage },
      { placeholder: _("application"), options: this.applicationOptions, values: application, action: this.handleSelectApplication },
      { placeholder: _("visibility"), options: this.visibilityOptions, values: visibility, action: this.handleSelectVisibility, isSimple: true },
    ];

    return (
      <div className="row align-center">
        <TTPFilterHorizontalBar
          filters={filters}
          tabs={scopeOptions.map(({ scope, label }) => ({ label, value: scope }))}
          selectedTab={currentScope}
          onTabChange={this.handleScopeChange}
          searchInputValue={searchWord}
          handleResetFilter={this.handleResetFilter}
          handleSearchInputChange={this.handleSearchInputChange.bind(this)}
          sort={sort}
          handleListSortChange={this.handleSelectSort}
          cssClass={`medium-12`}
        />
        <div className="medium-12">
          <StyledTabs
            light
            active={category}
            tabs={this.getCategoriesItems()}
            setActiveTab={this.handleCategoryClick}
          />
        </div>
      </div>
    );
  }
}
