export const getUsersSettings = (resp) => {
  return {
    type: 'FETCH_USERS_SETTINGS',
    payload: resp
  };
};

export const getUser = (resp) => {
  return {
    type: 'FETCH_USER',
    payload: resp
  };
};

export const saveUsers = (resp) => {
  return {
    type: 'SAVE_USERS',
    payload: resp
  };
};

export const updateUsers = (payload) => {
  return {
    type: 'UPDATE_USERS',
    payload
  };
};

export const initSaveUsers = () => {
  return {
    type: 'INIT_SAVE_USERS'
  };
};

export const fixContactDefaultEmail = (payload) => {
  return {
    type: 'FIX_CONTACT_EMAIL',
    payload
  };
};
export const fixUserMainEmail = (payload) => {
  return {
    type: 'FIX_USER_MAIN_EMAIL',
    payload
  };
};
