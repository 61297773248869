import React, { Component } from "react";
import _ from 'i18n';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import PageHeader from 'common/PageHeader';

class CampaignActions extends Component {

  cancel = () => {
    this.props.history.push('/smsCampaigns');
  }

  render() {
    const { saveDisabled, onSave, updating } = this.props;

    return (
      <PageHeader header={"createSms"}
        subHeader={"Create, manage and send your sms campaigns ."}
        icon={"EDIT_SMS"}
      >
        <button className="btn secondary text-uppercase" onClick={this.cancel}>{_('cancel')}</button>
        <button disabled={saveDisabled} className={`btn text-uppercase ${!saveDisabled ? 'primary' : 'disabled'}`}
          onClick={updating ? undefined : onSave} >
          {updating ? _('Processing...') : _('save')}
        </button>
      </PageHeader>
    );
  }
}

const mapStateToProps = store => ({
  updating: store.campaigns.list.updating,
})
export default compose(
  connect(mapStateToProps, null),
  withRouter
)(CampaignActions);
