import { TEMPLATE_TYPES } from "constants";

const initial = {
  items: [],
  fetching: false,
  fetched: false,
  updating: false,
  isSaved: false,
  nbResult: 0,
};

const initialBlock = {
  ...initial,
  fetchingTemplates: false,
  filter: {
    type: TEMPLATE_TYPES[("NEWS_LAYOUT", "SLOT_LAYOUT")],
  },
};

const initialStateForNewsletter = {
  base: "TEMPLATE", //' || LAYOUT'
  currentLayout: null,
  currentTemplate: null,
  blocks: [],
  showAddBlockSideBar: false,
  viewMode: "GRID",
};

const initialThemesFilter = {
  language: null,
  sortDirection: "desc",
  searchWord: "",
  type: "",
  application: "",
  scope: [],
  visibility: 1,
  category: null,
  pageSize: 8,
  paginationPage: 1,
  isPublicTheme: false,
};

const initialStateForThemes = {
  list: { ...initial, updating: false, fetched: false },
  currentDndTheme: null,
  currentSimpleTheme: null,
  filter: initialThemesFilter,
  blocks: initialBlock,
  navigateBackUrl: null,
  categories: initial,
  error: null,
  content: "",
  newsletter: initialStateForNewsletter,
};
export const themesReducer = (state = initialStateForThemes, action) => {
  switch (action.type) {
    case "INIT_THEMES": {
      return { ...initialStateForThemes };
    }
    case "INIT_THEMES_LIST": {
      return {
        ...state,
        list: { ...initial, updating: false, fetched: false },
        filter: initialThemesFilter,
      };
    }

    case "SET_CURRENT_DND_THEME": {
      return { ...state, currentDndTheme: action.theme };
      break;
    }

    case "SET_CURRENT_SIMPLE_THEME": {
      return { ...state, currentSimpleTheme: action.theme };
      break;
    }

    case "INIT_BLOCKS": {
      return { ...state, blocks: initialBlock };
      break;
    }

    case "SET_CURRENT_NEWSLETTER_TEMPLATE": {
      return {
        ...state,
        newsletter: { ...state.newsletter, currentTemplate: action.template },
      };
      break;
    }

    case "SET_BLOCKS_TYPE": {
      return {
        ...state,
        blocks: {
          ...initialBlock,
          filter: {
            type: action.blockType,
          },
        },
      };
      break;
    }

    case "SET_VIEW_MODE": {
      return {
        ...state,
        newsletter: { ...state.newsletter, viewMode: action.viewMode },
      };
      break;
    }

    case "SET_NEWSLETTER_BASE": {
      return {
        ...state,
        newsletter: {
          ...initialStateForNewsletter,
          base: action.base,
          currentTemplate: state.newsletter.currentTemplate,
        },
      };
      break;
    }

    case "FETCH_THEMES_PENDING": {
      let items = [];
      if (1 == state.filter.paginationPage) {
        items = [];
      } else {
        items = state.list.items;
      }
      return {
        ...state,
        list: { ...state.list, items, fetching: true },
      };
      break;
    }

    case "FETCH_BLOCKS_PENDING": {
      return {
        ...state,
        blocks: { ...state.blocks, fetching: true },
      };
      break;
    }

    case "FETCH_THEMES_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      const { filter, list } = state;

      let newItems = [];
      if (1 == filter.paginationPage) {
        let seletedThemes = list.items.filter((e) => e.isAdded);
        if (seletedThemes && seletedThemes.length == 1) {
          data.unshift({ ...seletedThemes[0], isAdded: false });
        }
        newItems = data;
      } else {
        newItems = list.items;
        newItems.push(...data);
      }

      return {
        ...state,
        list: {
          ...state.list,
          items: newItems,
          fetching: false,
          fetched: true,
          nbResult,
        },
      };
      break;
    }

    case "FETCH_THEMES_REJECTED": {
      let { filter, list } = state;
      let { nbResult, items } = list;
      let newItems = items;

      if (filter.paginationPage == 1) {
        newItems = [];
        nbResult = 0;
      }
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        list: {
          ...state.list,
          items: newItems,
          fetching: false,
          fetched: false,
          nbResult,
          error,
        },
      };
      break;
    }

    case "FETCH_BLOCKS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      const { filter, blocks } = state;

      let newItems = [];
      if (1 == filter.paginationPage) {
        newItems = data;
      } else {
        newItems = blocks.items;
        newItems.push(...data);
      }

      return {
        ...state,
        blocks: {
          ...state.blocks,
          items: newItems,
          fetching: false,
          fetched: true,
          nbResult,
        },
      };
      break;
    }

    case "ADD_LAYOUT_BLOCK": {
      const { id } = action.bloc;
      let { items, nbResult } = state.blocks;

      let exist = false;
      let newblocks = items.map((item) => {
        if (item.id == id) {
          exist = true;
          return action.bloc;
        }
        return item;
      });

      if (!exist) {
        newblocks.push(action.bloc);
        nbResult++;
      }

      return {
        ...state,
        blocks: { ...state.blocks, items: newblocks, nbResult },
      };
      break;
    }

    case "FETCH_BLOCKS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        blocks: {
          ...state.blocks,
          items: [],
          fetching: false,
          fetched: false,
          nbResult: 0,
          error,
        },
      };
      break;
    }

    case "SEND_THEME_TEST_PENDING":
    case "SAVE_THEME_PENDING": {
      return { ...state, updating: true };
      break;
    }
    case "SAVE_THEME_CATEGORY_PENDING": {
      return { ...state, updating: true };
      break;
    }
    case "SAVE_THEME_CATEGORY_FULFILLED": {
      const { data } = action.payload.data;
      let { items } = state.categories;
      let exist = false;
      let newItems = items.map((item) => {
        if (item.id == data.id) {
          exist = true;
          return data;
        }
        return item;
      });
      if (!exist) {
        newItems.push(data);
      }
      return { ...state, categories: { items: newItems }, updating: false };
      break;
    }
    case "SEND_THEME_TEST_FULFILLED":
    case "SEND_THEME_TEST_REJECTED":
    case "SAVE_THEME_REJECTED": {
      return { ...state, updating: false };
      break;
    }
    case "SAVE_THEME_FULFILLED": {
      const { data } = action.payload.data;
      let { items } = state.list;
      let exist = false;
      let newItems = items.map((item) => {
        if (item.id == data.id) {
          exist = true;
          return data;
        }
        return item;
      });
      if (!exist) {
        newItems.unshift(data);
      }
      return {
        ...state,
        updating: false,
        isSaved: true,
        list: { ...state["list"], items: newItems },
      };
      break;
    }
    case "INIT_THEME_FILTERS": {
      return initialStateForThemes;
    }
    case "SET_THEME_SEARCH_WORD": {
      return {
        ...state,
        filter: {
          ...state.filter,
          searchWord: action.searchWord,
          paginationPage: 1,
        },
      };
      break;
    }
    case "SET_THEME_LIST_SORT": {
      return {
        ...state,
        filter: {
          ...state.filter,
          sortDirection: action.sortDirection,
          paginationPage: 1,
        },
      };
      break;
    }
    case "SET_THEME_LANGUAGE": {
      return {
        ...state,
        filter: {
          ...state.filter,
          language: action.language,
          paginationPage: 1,
        },
      };
      break;
    }
    case "SET_THEME_TYPE": {
      return {
        ...state,
        filter: { ...state.filter, type: action.typeT, paginationPage: 1 },
      };
      break;
    }
    case "SET_THEME_APPLICATION": {
      return {
        ...state,
        filter: {
          ...state.filter,
          application: action.application,
          paginationPage: 1,
        },
      };
      break;
    }

    case "SET_THEME_SCOPE": {
      return {
        ...state,
        filter: { ...state.filter, scope: action.scope, paginationPage: 1 },
      };
      break;
    }

    case "SET_THEME_CATEGORY": {
      return {
        ...state,
        filter: {
          ...state.filter,
          category: action.category,
          paginationPage: 1,
        },
      };
      break;
    }

    case "SET_THEME_VISIBILITY": {
      return {
        ...state,
        filter: {
          ...state.filter,
          visibility: action.visibility,
          paginationPage: 1,
        },
      };
      break;
    }

    case "SET_THEME_IS_PUBLIC": {
      return {
        ...state,
        filter: {
          ...state.filter,
          isPublicTheme: action.isPublicTheme,
          paginationPage: 1,
        },
      };
      break;
    }
    case "SET_THEMES_PAGINATION_PAGE": {
      return {
        ...state,
        filter: { ...state.filter, paginationPage: action.pageNumber },
      };
      break;
    }
    case "SET_THEMES_PAGE_SIZE": {
      return {
        ...state,
        filter: { ...state.filter, pageSize: action.pageSize },
      };
      break;
    }
    case "SET_THEME_IS_SAVED": {
      return { ...state, isSaved: action.isSaved };
      break;
    }
    case "UPDATE_THEME_VISIBILITY_FULFILLED": {
      const { payload } = action;
      const {
        list,
        filter: { visibility },
      } = state;
      return {
        ...state,
        list: {
          ...list,
          items: visibility
            ? list.items.filter((item) => item.id != payload.id)
            : list.items,
          nbResult: list.nbResult - 1,
        },
      };
      break;
    }
    case "REMOVE_THEME_CATEGORY_FULFILLED": {
      const { payload } = action;
      const { categories } = state;
      return {
        ...state,
        categories: {
          ...categories,
          items: categories.items.filter(
            (item) => item.id != payload.deletedId
          ),
          nbResult: categories.nbResult - 1,
        },
      };
      break;
    }
    case "MULTI_SAVE_THEME_PENDING": {
      let { list } = state;
      return {
        ...state,
        list: { ...list, updating: true },
      };
      break;
    }
    case "MULTI_SAVE_THEME_FULFILLED": {
      const data = action.payload.data.data;
      let { list } = state;
      if (!data) {
        return state;
      }
      let items = state.list.items.map((item) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].id == item.id) {
            return data[i];
          }
        }
        return item;
      });
      return {
        ...state,
        list: { ...list, items, updating: false },
      };
      break;
    }
    case "FETCH_THEMES_CATEGORIES_PENDING": {
      return {
        ...state,
        categories: { ...state.categories, fetching: true, fetched: false },
      };
      break;
    }
    case "FETCH_THEMES_CATEGORIES_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        categories: {
          items: data.sort(function (a, b) {
            return b.count - a.count;
          }),
          fetching: false,
          fetched: true,
          nbResult,
        },
      };
      break;
    }
    case "FETCH_THEMES_CATEGORIES_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        categories: { items: [], fetching: false, fetched: false, nbResult: 0 },
        error,
      };
    }
    case "SET_THEME_CONTENT": {
      return {
        ...state,
        content: action.payload,
      };
    }
    case "SET_NAVIGATE_BACK_URL": {
      return {
        ...state,
        navigateBackUrl: action.payload,
      };
    }

    // === DYNAMIC BLOCKS ===

    case "INIT_NEWSLETTER_BLOCKS": {
      return {
        ...state,
        newsletter: initialStateForNewsletter,
      };
      break;
    }

    case "INIT_NEWSLETTER_FEEDS": {
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          blocks: state.newsletter.blocks.map((b) => ({ ...b, feeds: [] })),
        },
      };
    }

    case "SET_NEWSLETTER_BLOCKS": {
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          currentLayout: null,
          blocks: action.blocks || [],
        },
      };
    }

    case "ADD_BLOCK_TO_TEMPLATE": {
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          currentLayout: action.block,
          blocks: state.newsletter.blocks.concat(action.block),
        },
      };
    }

    case "SET_CURRENT_LAYOUT": {
      switch (action.layout && action.layout.type) {
        case "TEMPLATE":
          return {
            ...state,
            newsletter: {
              ...state.newsletter,
              blocks: [],
              currentLayout: action.layout,
            },
          };
        default:
          return {
            ...state,
            newsletter: { ...state.newsletter, currentLayout: action.layout },
          };
      }
      break;
    }

    case "ADD_FEED_TO_CURRENT_LAYOUT": {
      const { currentLayout, blocks } = state.newsletter;
      let { base } = state.newsletter;

      if (!currentLayout) {
        return state;
      }

      let exist = false;
      let newblocks = blocks.map((item) => {
        if (item.id != currentLayout.id) {
          return item;
        }

        exist = true;
        if (
          base == "TEMPLATE" &&
          item.feeds &&
          item.feeds.length == currentLayout.column
        ) {
          item.feeds = item.feeds.concat([action.feed]).slice(1);
        } else {
          item.feeds.push(action.feed);
        }

        return item;
      });

      if (!exist) {
        newblocks.push({
          ...currentLayout,
          feeds: [action.feed],
        });
      }

      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          blocks: newblocks,
        },
      };
      break;
    }

    case "ADD_FEED_TO_NEWSLETTER": {
      const { blocks } = state.newsletter;
      let { base } = state.newsletter;

      if (base !== "TEMPLATE") {
        return state;
      }

      let exists = false;
      let newBlocks = blocks.reduce((acc, b) => {
        if (
          !exists &&
          b.type === action.feed.type &&
          b.feeds.length < b.column
        ) {
          b.feeds.push(action.feed);
          exists = true;
        }
        acc.push(b);
        return acc;
      }, []);
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          blocks: newBlocks,
        },
      };
    }

    case "REMOVE_FEED_FROM_LAYOUT": {
      const { blocks } = state.newsletter;

      let newblocks = blocks.map((item) => {
        item.feeds = item.feeds.filter((feed) => feed.id != action.feed);
        return item;
      });

      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          blocks: newblocks,
        },
      };

      break;
    }

    case "RESET_LAYOUT_BLOCKS": {
      const { blocks } = state.newsletter;

      let newblocks = blocks.map((item) => {
        if (item.id == action.layout) {
          item.feeds = [];
        }

        return item;
      });

      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          blocks: newblocks,
        },
      };
      break;
    }

    case "TOGGLE_ADD_BLOCK_SIDE_BAR": {
      return {
        ...state,
        newsletter: {
          ...state.newsletter,
          showAddBlockSideBar: action.showAddBlockSideBar,
        },
      };
    }

    case "SET_SELECTED_THEME": {
      let items = (state.list.items || []).filter(
        (t) => t.id != action.theme.id
      );
      items.unshift(action.theme);
      return { ...state, list: { ...state.list, items } };
      break;
    }

    case "FETCH_THEME_PENDING": {
      let items = [];

      items = state.list.items;

      return {
        ...state,
        list: { ...state.list, items, fetching: true },
      };
      break;
    }

    case "FETCH_THEME_FULFILLED": {
      const { data } = action.payload.data;
      const { list } = state;

      let newItems = [];
      newItems = list.items;
      newItems.push(data);

      return {
        ...state,
        list: {
          ...state.list,
          items: newItems,
          fetching: false,
          fetched: true,
        },
      };
      break;
    }

    case "FETCH_THEME_REJECTED": {
      let { items } = state.list;
      let newItems = items;

      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        list: {
          ...state.list,
          items: newItems,
          fetching: false,
          fetched: false,
          error,
        },
      };
      break;
    }

    default:
      return state;
  }
};
