import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setCurrentEmailCampaignData } from "actions";
import _ from "i18n";
import { MODERATE_MODE, RESTRICTED_MODE } from "../../../constants";
import TextModelList from "../../text-model/TextModelList";
import TTPModal from "ui/TTPModal";
import { modalStyle } from "utils";
import { setEditorAllowOpenModels } from "../../../actions/actions/editor/editor";
import FillFreeSectionForm from "../../text-model/FillFreeSectionForm";
import TTPTab from "../../ui/tabs/TTPTab";
import cheerio from "cheerio";
import { NotificationManager } from "react-notifications";
import {
  EVENT_RECIPIENT_TYPES,
  SURVEY_RECIPIENT_TYPES,
  MEMBER_RECIPIENT_TYPES,
  PAYMENT_RECIPIENT_TYPES,
} from "../../../config/Common";

@connect((store) => {
  return {
    language: store.currentCampaign.email.language,
    recipientType: store.currentCampaign.email.recipientType,
    selectedText: store.currentCampaign.email.textModel,
    selectedTextContent: store.currentCampaign.email.html,
    targetApp: store.textModels.filter.targetApp,
    allowOpenModels: store.editor.allowOpenModels,
    genericEmailBody: store.genericEmail.currentSetting.currentEmail.body,
    currentNavPage: store.params.currentNavPage,
  };
})
export default class Models extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.switchTab = (tab) => this.setState({ currentTab: tab });
    this.state = {
      showFreeSection: false,
      currentTab: "FREE_SECTION",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTextContent, genericEmailBody } = this.props;

    if (
      (prevProps.selectedTextContent !== selectedTextContent &&
        selectedTextContent.includes("data-type")) ||
      (prevProps.genericEmailBody !== genericEmailBody &&
        genericEmailBody.includes("data-type"))
    ) {
      this.switchTab("FREE_SECTION");
      this.setState({ showFreeSection: true });
    }
  }

  applyTextModel = (textModel) => {
    let { dispatch, onChange, onObjectChange } = this.props;
    let { originalTextId, object, text } = textModel;
    dispatch(
      setCurrentEmailCampaignData({
        textModel: originalTextId,
        html: text,
        textModelTitle: object,
      })
    );
    onObjectChange(object);
    onChange(text);
    if (text.includes("data-type")) {
      this.setState({ showFreeSection: true });
    } else {
      this.setState({ showFreeSection: false });
      this.props.dispatch(setEditorAllowOpenModels(false));
    }
  };

  isSaveValid = () => {
    let { selectedTextContent, genericEmailBody, currentNavPage } = this.props;
    let selectedText =
      currentNavPage === "GENERIC_EMAILING"
        ? genericEmailBody
        : selectedTextContent;

    const $ = cheerio.load(selectedText);
    let freeSections = $("span").data("type", "FREE_SECTION");
    let fsSpans = [];
    for (let i = 0; i < freeSections?.length; i++) {
      fsSpans.push(freeSections[i]);
    }
    let notReplacedFreeSections = fsSpans.filter(
      (freeSection) => freeSection.attribs["data-formated-name"] !== ""
    );
    return notReplacedFreeSections.length === 0;
  };

  toggleModelsList = () => {
    if (this.state.showFreeSection && !this.isSaveValid()) {
      NotificationManager.warning(_("enterFreeSection"), _("warning"));
    } else {
      this.props.dispatch(setEditorAllowOpenModels(false));
    }
  };

  render() {
    let {
      detached,
      selectedText,
      language,
      selectedTextContent,
      recipientType,
      allowOpenModels,
      onEditorChange,
      genericEmailBody,
      currentNavPage,
    } = this.props;
    let { showFreeSection, currentTab } = this.state;

    const customModalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle.content,
        padding: 0,
        width: "70%",
        height: "80%",
      },
    };

    const tabs = [
      {
        id: "TEXT_MODELS",
        label: "text model",
      },
      {
        id: "FREE_SECTION",
        label: "free section",
      },
    ];

    if (detached) {
      return;
    }

    let currentContent =
      currentNavPage === "GENERIC_EMAILING"
        ? genericEmailBody
        : selectedTextContent;

    const displayFreeSectionTab =
      showFreeSection ||
      (currentContent && currentContent.includes("data-type"));

    let targetApp = "WTB";
    if (EVENT_RECIPIENT_TYPES.includes(recipientType)) targetApp = "EVENT";
    if (PAYMENT_RECIPIENT_TYPES.includes(recipientType)) targetApp = "PAYMENT";
    if (SURVEY_RECIPIENT_TYPES.includes(recipientType)) targetApp = "SURVEY";
    if (MEMBER_RECIPIENT_TYPES.includes(recipientType)) targetApp = "ALL";

    return (
      <div className={"rdw-block-wrapper"}>
        <TTPModal
          isOpen={allowOpenModels}
          onClose={this.toggleModelsList}
          modalStyle={customModalStyle}
          title={!displayFreeSectionTab && _("text model")}
        >
          {displayFreeSectionTab ? (
            <div className="ttp-tab-container">
              <TTPTab
                tabs={tabs}
                activeTab={currentTab}
                selectTab={this.switchTab}
              />
              {currentTab === "TEXT_MODELS" && (
                <div>
                  <TextModelList
                    mode={
                      currentNavPage === "GENERIC_EMAILING"
                        ? RESTRICTED_MODE
                        : MODERATE_MODE
                    }
                    onApply={this.applyTextModel}
                    current={selectedText}
                    language={language !== "all" ? [language] : null}
                    targetApp={textModelTargetApp || targetApp}
                  />
                </div>
              )}

              <div className={`${currentTab !== "FREE_SECTION" ? "hide" : ""}`}>
                <FillFreeSectionForm
                  content={currentContent}
                  handleSaveFs={this.toggleModelsList}
                  onEditorChange={onEditorChange}
                />

                <div className="widget_bottom-bar">
                  <button
                    onClick={this.toggleModelsList}
                    className={`btn fs-btn ${
                      this.isSaveValid() ? "primary" : "disabled"
                    } `}
                  >
                    {_("save")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <TextModelList
              mode={
                currentNavPage === "GENERIC_EMAILING"
                  ? RESTRICTED_MODE
                  : MODERATE_MODE
              }
              onApply={this.applyTextModel}
              current={selectedText}
              language={language !== "all" ? [language] : null}
              targetApp={targetApp}
            />
          )}
        </TTPModal>
      </div>
    );
  }
}
