import React, {Component} from "react";

export default class ProgressBar extends Component {
  render() {
    let {processedRate, status} = this.props;
    let statusInProgress = ['PAUSE', 'HALT'].indexOf(status) === -1;
    processedRate = (processedRate > 100) ? 100 : processedRate;

    return (<div className="progress-bar">
      <div className="progress-bar-container">
        <div className={`progress-bar__fill ${statusInProgress && "animation"}`} style={{width: processedRate + "%"}}/>
      </div>
      <div className="progress-bar__rate">{processedRate}%</div>
    </div>);
  }
}
