import React, { Component } from 'react';
import _ from 'i18n';

class Actions extends Component {
    render() {
        const { closeForm, title, secondaryButton, primaryButton } = this.props;

        return (
            <div className="viewer-actions">
                {closeForm && <div className="close-icon">
                    <img onClick={closeForm} src="/img/icons/right-arrow.png" />
                    <h4>{title}</h4>
                </div>}
                <div>
                    {secondaryButton && secondaryButton.action && <button className="secondary-button" onClick={(!secondaryButton.updating) && secondaryButton.action}>
                        {secondaryButton.icon && <i className={`icon-left ${secondaryButton.icon}`}></i>}
                        {(secondaryButton.updating) ? _('Processing...') : secondaryButton.label}
                    </button>}
                    {primaryButton && primaryButton.action && <button className={`primary-button`} onClick={(!primaryButton.updating) && primaryButton.action}>
                        {primaryButton.icon && <i className={`icon-left ${primaryButton.icon}`}></i>}
                        {(primaryButton.updating) ? _('Processing...') : primaryButton.label}
                    </button>}
                </div>
            </div>
        )
    }
}

export default Actions;
