import React from "react";
import { connect } from "react-redux";
import { DragSource } from 'react-dnd'
import { compose } from 'redux';
import { NotificationManager } from 'react-notifications';
import { TEMPLATE_TYPES, RESIZE_SPEAKER } from 'constants';
import { addFeedToCurrentLayout, removeFeedFromLayout, addFeedToNewsletter } from "actions";
import { getUserNameForAvatar } from 'utils';
import { S3_FOLDER_URL } from "Config";
import _ from 'i18n';
import ToggleButton from "../ui/ToggleButton";

export class SpeakerItem extends React.PureComponent {

  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.id !== this.props.id) || (nextProps.isSelected !== this.props.isSelected) || nextProps.order !== this.props.order;
  }

  addSpeaker = () => {
    const { currentLayout, base, blocks } = this.props;
    const speaker = formatSpeaker(this.props);

    if (base === "TEMPLATE" && (!currentLayout || currentLayout.type !== speaker.type)) {
      if(!blocks.some(({feeds, column}) => feeds.length < column)) {
        NotificationManager.info(_("All blocks are filled"));
        return;
      }
      this.props.addFeedToNewsletter(speaker);
      return;
    }
    if (!currentLayout && base !== "TEMPLATE") {
      NotificationManager.info(_("Select a layout for your block first"));
      return;
    }

    if (currentLayout.type != "SPEAKER_LAYOUT") {
      NotificationManager.info(_('Current layout is not the appropriate block type'));
      return;
    }

    this.props.addFeedToCurrentLayout(speaker);
  }

  render() {
    const { id, firstName, lastName, headlineFr, headlineNl, pictureUrl, lng, removeFeedFromLayout, isSelected, connectDragSource, order } = this.props;

    let avatarDiv = null;

    if (pictureUrl) {
      avatarDiv = (
        <div id={`avatar-${id}`} className="avatar"
          style={{ backgroundImage: `url(${S3_FOLDER_URL + '/events-folder' + pictureUrl + RESIZE_SPEAKER})` }}>
        </div>
      );
    } else {
      avatarDiv = (
        <div id={`avatar-${id}`} className="avatar empty-avatar">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    }

    let language = lng.charAt(0).toUpperCase() + lng.slice(1).toLowerCase();

    return connectDragSource(<div className="medium-3 column" style={{order}}>
        <div className={`feed speakers-item ${isSelected ? "selected" : ""}`}>
          {isSelected && <div className={"feed-order"}>{order + 1}</div>}
          <ToggleButton
            cssClass={"apply-icon"}
            onToggleSelection={(isSelected) ? () =>  removeFeedFromLayout(id) : this.addSpeaker}
            checked={isSelected} />
          {avatarDiv}
          <div className="speakers-item__header">
            <h3><span>{firstName + ' ' + lastName}</span></h3>
            <h5>{this.props[`headline${language}`] || headlineFr || headlineNl}</h5>
          </div>

      </div>
    </div>);
  }
}


const mapStateToProps = (state) => ({
  lng: state.params.lng,
  currentLayout: state.themes.newsletter.currentLayout,
  blocks: state.themes.newsletter.blocks,
  base: state.themes.newsletter.base,
});
const mapDispatchToProps = dispatch => ({
  addFeedToCurrentLayout: (article) => dispatch(addFeedToCurrentLayout(article)),
  addFeedToNewsletter: (speaker) => dispatch(addFeedToNewsletter(speaker)),
  removeFeedFromLayout: (id) => dispatch(removeFeedFromLayout(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragSource(
    TEMPLATE_TYPES['SPEAKER_LAYOUT'],
    {
      beginDrag: (props) => {
        return formatSpeaker(props)
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )
)(SpeakerItem);

const formatSpeaker = (props) => {
  const { id, firstName, lastName, mainCv, pictureUrl, lng } = props;
  let headline = "";
  if (mainCv) {
    const { headlineFr, headlineNl, headlineEn } = mainCv;
    headline = mainCv["headline" + (lng.charAt(0).toUpperCase() + lng.slice(1))] || headlineFr || headlineNl || headlineEn;
  }
  return {
    id,
    name: firstName + ' ' + lastName,
    avatar: (pictureUrl) ? S3_FOLDER_URL + '/events-folder' + pictureUrl + RESIZE_SPEAKER :
      'https://ui-avatars.com/api/?bold=true&background=0D8ABC&color=fff&size=130&name=' + lastName + "+" + firstName,
    headline,
    type: TEMPLATE_TYPES['SPEAKER_LAYOUT']
  }
}
