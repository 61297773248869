import React, { Component } from 'react'
import { connect } from 'react-redux';

import _ from 'i18n';
import { saveTextModel, fetchTextModels, fetchGenericEmailTypes } from 'thunks';
import { setStep } from 'actions';
import { EditorState } from "draft-js";
import { CAMPAIGN_LANGUAGE, STEPS } from "Common";
import TTPSelect from "common/TTPSelect";
import PropTypes from 'prop-types';
import { stateToHTML } from "draft-js-export-html";
import { onError, onSuccess } from 'utils';
import Actions from '../common/Actions';
import { getLabel, hasValidLength } from '../../services/utils';
import { getSupportedAppsOptions } from '../../services/apps';
import RichEditor from "../common/editor/RichEditor";
import { ADMIN_MODE } from "../../constants";

const mapStateToProps = (state, ownProps) => ({
  updating: state.textModels.updating,
  fetched: state.genericEmail.types.fetched,
  fetching: state.genericEmail.types.fetching,
  genericEmailTypes: state.genericEmail.types.items,
  lng: state.params.lng,
});

const mapDispatchToProps = dispatch => ({
  hideMenu: () => dispatch(setStep(STEPS['MANAGE_TEXT_MODEL'])),
  saveTextModel: (data, isTamtamTextModel) => dispatch(saveTextModel(data, isTamtamTextModel)),
  fetchTextModels: textModelId => dispatch(fetchTextModels(null, true, textModelId)),
  fetchTypes: () => dispatch(fetchGenericEmailTypes()),
});
@connect(mapStateToProps, mapDispatchToProps)

export default class TextModelForm extends Component {

  static propTypes = {
    editorState: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.handleEditorChange = (editorState) => this.setState({ editorState });
    const { id, subject, content,name, language, targetApp, targetType, formAction, main } = this.props;

    this.state = {
      showForm: false,
      hasError: { subject: false },
      editorState: content ? content : "",
      id,
      subject,
      name,
      language: language || "all",
      targetApp,
      targetType,
      isOpenFromUrl: false,
      typesOptions: [],
      formAction,
      main
    }
  }

  componentDidMount() {
    let scrolIntoElem = document.getElementById(`text-model-form`);
    if (scrolIntoElem) {
      scrolIntoElem.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    }

    const { fetched, fetchTypes, genericEmailTypes, lng } = this.props;
    const { targetApp } = this.state;
    if (!fetched) {
      fetchTypes().then(res => {
        this.loadTypes(res.value.data.data, targetApp, lng);
      });
    } else {
      this.loadTypes(genericEmailTypes, targetApp, lng);
    }
  }

  loadTypes = (GETypes, targetApp, lng) => {
    const typesOptions = GETypes.reduce((acc, type) => {
      if (type.targetApp === targetApp) {
        acc.push({
          value: type.id,
          label: getLabel(type, lng).toUpperCase(),
        });
      }
      return acc;
    }, []);
    if (this) {
      this.setState({ typesOptions });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { genericEmailTypes, lng } = this.props;
    const { targetApp } = this.state;
    if (prevState.targetApp !== targetApp || genericEmailTypes !== prevProps.genericEmailTypes) {
      this.loadTypes(genericEmailTypes, targetApp, lng);
    }
  }

  handleObjectChange = (e) => {
    const subject = (e.target) ? e.target.value : e;
    const hasError = this.state.hasError;

    this.setState({ subject, hasError: { ...hasError, subject: !hasValidLength(subject, 5, 200) } });
  }

  handleLanguageChange(language) {
    this.setState({ language });
  }

  handleTargetAppChange(targetApp) {
    this.setState({ targetApp, targetType: null, target: null });
  }

  handleTargetTypeChange = (targetType) => {
    this.setState({ targetType });
  };
  handleNameChange = (e) => {
    const name = (e.target) ? e.target.value : e;

    this.setState({ name });
  }

  renderLang() {
    const { language } = this.state;

    let defaultLanguages = [];

    for (let lang in CAMPAIGN_LANGUAGE) {
      defaultLanguages.push({ id: lang, label: _(CAMPAIGN_LANGUAGE[lang]).toUpperCase() });
    }

    return <TTPSelect
      simple={true}
      notClearable={true}
      values={language}
      options={defaultLanguages}
      onChange={this.handleLanguageChange.bind(this)} />
  }

  handleAddTextModel = () => {
    let { saveTextModel, mode, currentClientId } = this.props;
    let {editorState, subject, title, language, name, targetType, targetApp, formAction, main} = this.state;
    if (name === undefined) {
      name = "";
    }
    let notifications = {
      CREATE: { body: 'textModelAdded', title: 'addCampaignSuccess' },
      DUPLICATE: { body: 'textModelDuplicated', title: 'duplicateSuccess' },
      UPDATE: { body: 'textModelUpdated', title: 'updateSuccess' },
    }

    let content = editorState;

    let data = { title, language, object: subject,name, content, targetApp, targetType, main };

    if (this.state.id !== "" && formAction === "UPDATE") {
      data.id = this.state.id;
    }

    const isTamtamTextModel = (formAction !== "CREATE" && !currentClientId) || (mode && ADMIN_MODE === mode);
    saveTextModel(data, isTamtamTextModel).then(
      (res) => {
        onSuccess(res, notifications[formAction || "CREATE"]);
        this.exitForm()
      },
      (err) => onError(err)
    );
  }

  isSaveDisabled() {
    let { editorState, subject, language, targetApp, targetType } = this.state;
    return !(hasValidLength(subject, 5, 200) && targetType && targetType.length > 0
      && language && language.length > 0 && targetApp && targetApp.length > 0
      && editorState.length > 11);
  }

  getTitle = () => {
    const { formAction } = this.state;
    switch (formAction) {
      case "UPDATE": return _('edit the model');
      case "DUPLICATE": return _('duplicate the model');
      default: return _('add new model');
    }
  }

  exitForm = () => {
    const { toggleForm } = this.props;
    const { isOpenFromUrl } = this.state;
    if (isOpenFromUrl) {
      window.parent.postMessage('CLOSE_VIEWER', "*");
    } else {
      toggleForm(false);
    }
  }

  handleMainChange = () => {
    this.setState(prevState => ({
      main: prevState.main === 0 ? 1 : 0
    }));
  }

  render() {
    const { updating,content, cssClass } = this.props;
    const { hasError, editorState,name, subject, language, targetApp, targetType, isOpenFromUrl, typesOptions, main } = this.state;

    const saveDisabled = this.isSaveDisabled();

    return <div id="text-model-form" className={`${isOpenFromUrl ? "form-open-form-url" : "small-10"} ${cssClass || ""}`}>
      {isOpenFromUrl && <Actions
        closeForm={this.exitForm}
        title={this.getTitle()}
        secondaryButton={{
          label: _('cancel'),
          icon: 'icon-close',
          action: this.exitForm
        }}
        primaryButton={{
          label: updating ? _('Processing...') : _('save'),
          action: this.handleAddTextModel,
          updating: updating,
        }}
      />}
      <div className={`add-text-model ${isOpenFromUrl ? "row small-8" : ""}`}>
        <div className="add-text-model__body">
          {!isOpenFromUrl && <h3 className="text-capitalize">{this.getTitle()}</h3>}
          <div className="row">
            <div className={`columns columns-padding small-6`}>
              <span className="param-label">{_('language')}</span>
              <div className="sidebar__form-control">
                <div className={`small-12 ${language ? "filled-box" : "empty-box"}`}>
                  {this.renderLang()}
                </div>
              </div>
            </div>
            <div className="columns columns-padding medium-6">

              <span className="param-label">{_('Name')} <span style={{fontStyle : "italic", marginLeft :"15px", fontSize:  "12px"}}> -{_('optionalName')}</span> </span>
              <input type={"text"}
                     placeholder={_('enterName')}
                     value={name}
                     className={name ? "filled-box" : "empty-box"}
                     onChange={this.handleNameChange}
              />

            </div>
            <div className={`columns medium-6`}>
              <span className="param-label">{_('application')}</span>
              <TTPSelect
                placeholder={_('Select application')}
                simple={true}
                notClearable={true}
                values={targetApp}
                options={getSupportedAppsOptions()}
                cssClass={targetApp ? "filled-box" : "empty-box"}
                onChange={this.handleTargetAppChange.bind(this)} />
            </div>
            {targetApp &&
              <div className="columns medium-6">
                <span className="param-label">{_('type')}</span>
                <TTPSelect
                  simple={true}
                  placeholder={_('Select type')}
                  notClearable={true}
                  values={targetType}
                  cssClass={targetType ? "filled-box" : "empty-box"}
                  options={typesOptions}
                  onChange={this.handleTargetTypeChange} />
              </div>
            }
          </div>
          <div className="row align-center ">
            <div className="main-content columns">

              <RichEditor
                content={content}
                subject={subject}
                onEditorChange={this.handleEditorChange}
                onObjectChange={this.handleObjectChange}
                fieldsType={targetApp}
                subjectHasError={hasError.subject}
                mode="TEXT_MODELS"
              />

            </div>
          </div>
        </div>
        {!isOpenFromUrl && <div className="add-text-model__footer row">
          <button onClick={this.exitForm} className="btn secondary">{_('cancel')}</button>
          <button disabled={saveDisabled} onClick={!updating && this.handleAddTextModel} className={`btn primary ${saveDisabled && 'disabled'}`}>{updating ? _('Processing...') : _('save')}</button>
        </div>}
      </div>
    </div>
  }
}
