import React, {Component} from "react";
import {connect} from "react-redux";
import styles from "./TotalMembers.module.scss";
import _ from "i18n";

@connect((store) => {
  return {
    totalMembers: store.dashboard.totalMembers
  };
})
export default class TotalMembersComponent extends Component {

  render() {
    return (
      <div className={styles.total_members}>
        <div className={styles.users_logo}>
          <span><img width="24" height="24" src="img/users.svg"/></span>
        </div>
        <div className={styles.number_content}>
          <div className={styles.number}>
            {this.props.totalMembers || 0} {_("Members")}
          </div>
        </div>
      </div>
    );
  }
}



