import React, { Component } from "react";
import { connect } from 'react-redux';

import { setEventsFilters } from 'actions';
import { fetchEventOptions, fetchEventPlan, fetchEvents } from 'thunks';
import _ from 'i18n';
import { GUEST_STATUS } from 'Common';
import TTPSelect from "common/TTPSelect";
import {
  sortEventByStartDateTime, transformDateFormat,
  ucFirst
} from "../../../../../services/common";

@connect((store) => {
  return {
    events: store.filters.events.items,
    slots: store.filters.slots.items,
    options: store.filters.options.items,
    formulas: store.filters.formulas.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters,
    campaignLanguage: store.currentCampaign.email.language
  }
}, (dispatch) => {

  const handleStepsChange = (dispatch, steps, operatorStep, operatorPrinted, operatorConfirmation) => {
    const hasPrinted = steps.includes('105');
    const hasConfirmation = steps.includes('5');
    const otherSteps = steps.filter(step => (step !== '105' && step !== '5'));
    const filters = [];


    if (otherSteps.length > 0) {
      filters.push({ property: 'step', operator: operatorStep, value: otherSteps });
    } else {
      filters.push({ property: 'step', operator: operatorStep, value: '' })
    }

    if (hasPrinted) {
      filters.push({ property: 'printed', operator: operatorPrinted, value: 1 });
    } else {
      filters.push({ property: 'printed', operator: operatorPrinted, value: '' });
    }

    if (hasConfirmation) {
      filters.push({ property: 'registeredChoice', operator: operatorConfirmation, value: '' });
    } else {
      filters.push({ property: 'registeredChoice', operator: 'in', value: '' });
    }

    filters.forEach((filter) => {
      dispatch(setEventsFilters(filter))
    });
  };

  return {
    handleEventChange: (eventId) => dispatch(setEventsFilters({ property: 'event.id', operator: 'eq', value: eventId })),
    handleSlotChange: (slots) => dispatch(setEventsFilters({ property: 'slots', operator: 'in', value: (slots.length > 0) ? slots : "" })),
    handleOptionChange: (options) => dispatch(setEventsFilters({ property: 'guestOption.option', operator: 'in', value: (options.length > 0) ? options : "" })),
    handlePlanChange: (plan) => dispatch(setEventsFilters({ property: 'guestPlan.plan', operator: 'in', value: (plan.length > 0) ? plan : "" })),
    handleStepsInChange: (steps) => handleStepsChange(dispatch, steps, 'in', 'eq', 'neq'),
    handleStepsNinChange: (steps) => handleStepsChange(dispatch, steps, 'nin', 'neq', 'eq'),
    fetchEvents: () => dispatch(fetchEvents()),
    fetchEventPlan: (eventId) => dispatch(fetchEventPlan(eventId)),
    fetchEventOptions: (eventId) => dispatch(fetchEventOptions(eventId)),
  }
})
export default class GuestFilters extends Component {

  componentWillMount() {
    const { fetchEvents, fetchEventPlan, fetchEventOptions, eventFilters } = this.props;
    fetchEvents();
    for (let criteria of eventFilters) {
      if (criteria.property == "event.id") {
        fetchEventPlan(criteria.value);
        fetchEventOptions(criteria.value);
        break;
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      eventsType: 'future',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { events, handleEventChange, eventFilters, fetchEventPlan, fetchEventOptions } = nextProps;
    if (events && events.length > 0 && eventFilters.length == 0) {
      handleEventChange(events[0]['id']);
      fetchEventPlan(events[0]['id']);
      fetchEventOptions(events[0]['id']);
    }
  }

  handleEventChange(event) {
    const { handleEventChange, fetchEventPlan, fetchEventOptions, handleOptionChange, handleStepsNinChange, handlePlanChange, handleStepsInChange } = this.props;
    fetchEventPlan(event);
    fetchEventOptions(event);
    handleEventChange(event);

    //init all
    handlePlanChange([]);
    handleOptionChange([]);
    handleStepsNinChange([]);
    handleStepsInChange([]);
  }

  render() {
    const { events, options, formulas, eventFilters, language, handlePlanChange, handleStepsNinChange, handleStepsInChange, handleOptionChange, campaignLanguage } = this.props;
    let filterMapping = eventFilters.reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});

    let selectedEvent = filterMapping['event.id-eq'] || "";
    let includedSteps = filterMapping['step-in'] || [];
    let excludedSteps = filterMapping['step-nin'] || [];
    let selectedPlan = filterMapping['guestPlan.plan-in'] || [];
    let selectedOption = filterMapping['guestOption.option-in'] || [];
    let isIncludedPrinted = filterMapping['printed-eq'] === 1;
    let isExcludedPrinted = filterMapping['printed-neq'] === 1;
    let isIncludedConfirmation = filterMapping['registeredChoice-neq'] === '';
    let isExcludedConfirmation = filterMapping['registeredChoice-eq'] === '';
    if (isIncludedPrinted && !includedSteps.includes('105')) {
      includedSteps = [...includedSteps, '105'];
    }

    if (isExcludedPrinted && !excludedSteps.includes('105')) {
      excludedSteps = [...excludedSteps, '105'];
    }

    if (isIncludedConfirmation && !includedSteps.includes('5')) {
      includedSteps = [...includedSteps, '5'];
    }

    if (isExcludedConfirmation && !excludedSteps.includes('5')) {
      excludedSteps = [...excludedSteps, '5'];
    }


    let lng = ucFirst(language);
    let campaignLng = ucFirst(campaignLanguage);
    const { eventsType } = this.state;

    let sortedEvents = sortEventByStartDateTime(events, 'DESC', eventsType)
    let eventOptions = sortedEvents?.map(event => {
      const getEventName = () => {
        if (lng !== 'All' && event[`name${lng}`]) {
          return event[`name${lng}`];
        }
        // Fallback order: Fr -> Nl -> En
        return event['nameFr'] || event['nameNl'] || event['nameEn'] || 'Unnamed Event';
      };

      const eventName = getEventName();
      const eventStartDate = transformDateFormat(event['startDateTime']);

      return {
        label: `${eventStartDate} || ${eventName}`,
        id: event.id.toString()
      };
    });

    let optionsChoices = options.map(option => {
      return {
        label: option[`name${lng}`] || option['nameFr'],
        id: option.id + ""
      };
    });

    let formulasOptions = formulas.map(formula => {
      return {
        label: formula[`name${lng}`] || formula['nameFr'],
        id: formula.id + ""
      };
    });

    return (
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('event')}
              <span style={{float: "right"}}>
                <span style={{marginLeft: '10px'}}>
                    <input type="radio" name='future' style={{margin: 'unset'}}
                           defaultChecked={true}
                           checked={this.state.eventsType === 'future'}
                           onChange={() => this.setState({
                             ...this.state,
                             eventsType: 'future'
                           })}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('future')}</span>
                  </span>
                  <span style={{margin: '0 10px 0 10px '}}>
                    <input type="radio" name='replay' style={{margin: 'unset'}}
                           defaultChecked={false}
                           checked={this.state.eventsType === 'past'}
                           onChange={() => this.setState({
                             ...this.state,
                             eventsType: 'past'
                           })}/>
                    <span className="ttp-radio"></span>
                    <span style={{marginLeft: '5px'}}>{_('past')}</span>
                  </span>
                </span>
            </h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={selectedEvent}
              placeholder={_('Select event')}
              options={eventOptions}
              onChange={this.handleEventChange.bind(this)}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Formulas')}</h1>
            <TTPSelect
              values={selectedPlan}
              placeholder={_('Select formulas')}
              options={formulasOptions}
              onChange={handlePlanChange}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Included status')}</h1>
            <TTPSelect
              values={includedSteps}
              placeholder={_('Select status')}
              options={GUEST_STATUS}
              onChange={handleStepsInChange}
              disabled={excludedSteps} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Excluded status')}</h1>
            <TTPSelect
              values={excludedSteps}
              placeholder={_('Exclude status')}
              options={GUEST_STATUS}
              onChange={handleStepsNinChange}
              disabled={includedSteps} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Options')}</h1>
            <TTPSelect
              values={selectedOption}
              placeholder={_('Select options')}
              options={optionsChoices}
              onChange={handleOptionChange} />
          </div>
        </div>
      </div>
    );
  }
}
