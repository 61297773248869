import React, { Component } from 'react';

import { setSMSBillsListSortConfig } from "actions";
import { fetchSmsBills } from 'thunks';
import { connect } from 'react-redux';

@connect((store) => {
  return {
    smsBillsSort: store.smsBills.sort
  }
})

export default class SortHeaderCell extends Component {

  constructor(props) {
    super(props);

    this.onSortChange = this.onSortChange.bind(this);
  };

  onSortChange(event) {
    event.preventDefault();
    let { dispatch, columnKey, smsBillsSort } = this.props;

    let sortDir = 'asc';
    if (smsBillsSort.sortConfig.columnKey === columnKey) {
      sortDir = this.reverseSortDirection(smsBillsSort.sortConfig.sortDirection);
    }
    dispatch(setSMSBillsListSortConfig(columnKey, sortDir));
    dispatch(fetchSmsBills(1));
  };

  reverseSortDirection(sortDir) {
    return sortDir === 'desc' ? 'asc' : 'desc';
  };

  renderSortDirection() {
    var { smsBillsSort, columnKey } = this.props;
    if (columnKey === smsBillsSort.sortConfig.columnKey) {
      return (
        smsBillsSort.sortConfig.sortDirection === 'desc' ? '↓' : '↑'
      );
    }
    return (
      '↓↑'
    );
  };

  render() {
    var { smsBillsSort, children, ...props } = this.props;
    return (
      <span className="list__sort-button" onClick={this.onSortChange}>
        {children} {this.renderSortDirection()}
      </span>
    );
  }
}
