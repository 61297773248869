import React, { Component } from 'react';

import { ucFirst, isArraysEqual } from 'utils';
import Email from './email/Email';
import { connect } from "react-redux";
import { SVG_VALID_ICON, SVG_WARNING_ICON } from "../../../services/svgIcones";

@connect(store => ({
  userLng: store.params.lng,
}))
export default class TypeEmails extends Component {

  render() {
    const { languages, type, emails, active, onToggleActive, userLng } = this.props;
    const activeLanguages = Object.keys(emails) || [];
    const label = type[`label${ucFirst(userLng)}`];
    const emptyType = !isArraysEqual(activeLanguages, languages);

    return (
      <div id={type.id} className="generic-email-container">
        <div className={`generic-email-header row ${(emptyType && type.required) && 'alert'}`}>
          <div className="generic-email-header__title">
            {emptyType ? SVG_WARNING_ICON : SVG_VALID_ICON}
            <h4 className="text-capitalize">{label}</h4>
          </div>
          <div className="generic-email-header__right-side">
            <span className="languages">
              {activeLanguages.map(lng => <img key={`${type.id}-${lng}`} src={`/img/flags/${lng}.svg`} />)}
            </span>
            <span className="icon" onClick={onToggleActive}>
              <i className={`${(active) ? 'icon-arrow-up' : 'icon-arrow-down'}`} />
            </span>
          </div>
        </div>
        <div className={`generic-email-list ${active && 'generic-email-list__open'} row`}>
          {
            languages.map(lng => (
              <Email
                key={`email-${emails[lng] ? emails[lng].id : "empty-" + lng}`}
                item={(emails && emails[lng]) ? emails[lng] : { language: lng, targetType: type.id }}
              />
            ))
          }
        </div>
      </div>
    );
  }
}
