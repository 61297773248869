import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import { setArticlesPaginationPage, initArticles } from "actions";
import { fetchArticles } from "thunks";
import NotFound from "notFound/NotFound";
import ArticleItem from "./ArticleItem";
import ArticleItemFetching from "fetching/blocks/ArticleItemFetching";
import ArticlesListFilter from "./ArticlesListFilter";
import { formatArticle } from "../../services/article";

const mapStateToProps = (state, ownProps) => ({
  articles: state.articles.items,
  nbResult: state.articles.nbResult,
  filter: state.articles.filter,
  fetching: state.articles.fetching,
  fetched: state.articles.fetched,
  error: state.articles.error,
  lng: state.params.lng,
  viewMode: state.themes.newsletter.viewMode,
});

const mapDispatchToProps = (dispatch) => ({
  fetchArticles: () => dispatch(fetchArticles()),
  setArticlesPaginationPage: (page) =>
    dispatch(setArticlesPaginationPage(page)),
  initArticles: () => dispatch(initArticles()),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class ArticlesList extends Component {
  state = {
    hasMoreItems: true,
  };

  componentDidMount() {
    const { fetchArticles, initArticles } = this.props;
    initArticles();
    fetchArticles();
  }

  componentDidUpdate(prevProps) {
    const { filter, fetchArticles, fetching } = this.props;
    if (filter !== prevProps.filter) {
      fetchArticles();
    }
    if (fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !fetching,
      });
    }
  }

  componentWillUnmount() {
    this.props.initArticles();
  }

  loadItems = () => {
    const { filter, fetching, nbResult, articles, setArticlesPaginationPage } =
      this.props;
    if (!fetching && articles.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      setArticlesPaginationPage(currentPage + 1);
    }
  };

  render() {
    const {
      articles,
      fetching,
      viewMode,
      selectedFeeds,
      showSelected,
    } = this.props;
    const { hasMoreItems } = this.state;
    const listMode = viewMode === "LIST";
    let items = [];
    let selectedFeedsIds = [];
    if (showSelected && selectedFeeds?.length > 0) {
      selectedFeeds
        .filter((item) => Object.keys(item).length)
        .forEach((item, index) => {
          selectedFeedsIds.push(item.id);
          items.push(
            <ArticleItem
              viewMode={viewMode}
              order={index}
              isSelected
              key={`article-${item.id}`}
              article={item}
            />
          );
        });
    }

    if (articles && articles.length > 0) {
      articles
        .filter(({ id }) => !selectedFeedsIds.includes(id))
        .forEach((item) => {
          items.push(
            <ArticleItem
              viewMode={viewMode}
              order={1000}
              key={`article-${item.id}`}
              article={formatArticle(item)}
            />
          );
        });
    }

    if (fetching) {
      let itemNbr = listMode ? 5 : 10;
      for (let i = 0; i < itemNbr; i++) {
        items.push(
          <div
            key={`fetch-${i}`}
            className={`medium-${listMode ? 12 : 6} column`}
            style={{ order: 1001 }}
          >
            <ArticleItemFetching />
          </div>
        );
      }
    }

    return (
      <div className="medium-12">
        <div
          id="feeds-container"
          style={{ height: `${0.9 * window.innerHeight - 20}px` }}
          ref={(ref) => (this.scrollParentRef = ref)}
          className="column"
        >
          <ArticlesListFilter />
          {items && items.length > 0 ? (
            <InfiniteScroll
              className={"feeds-list"}
              pageStart={0}
              loadMore={this.loadItems}
              hasMore={hasMoreItems}
              useWindow={false}
              getScrollParent={() => this.scrollParentRef}
            >
              {showSelected && selectedFeeds.length > 0 && (
                <div className={"masonry-separator medium-12"} />
              )}
              {items}
            </InfiniteScroll>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    );
  }
}
