import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import List from './list/List';
import Bubble from 'common/Bubble';
import BackDrop from '../ui/BackDrop';
import Gallery from './attachments/Gallery';
import { SVG_EBOX_GALLERY_ICON } from 'utils';
import { SVG_SEND_ICON } from "../../services/svgIcones";
import { showEboxWidget } from '../../actions/actions/ebox/widget';
import EmailComposer from './widget/EmailComposer';
import { FULL_SCREEN_WIDGET } from '../../services/ebox/widget';
import { setCurrentNavPage } from "actions";
import TTPTab from "../ui/tabs/TTPTab";
import ListSMS from "./listSMS/ListSMS";
import { initEbox, setEboxRecipientType } from '../../actions/actions';
import ChatWidget from "../ChatWidget/ChatWidget";
import AppendHead from "react-append-head";
import {fetchUser} from "../../actions/thunks/user";
import { APP_ENV } from "Config";


const mapStateToProps = state => ({
  auth: state.auth,
  owner: state.ebox.owner.data,
  widgetStatus: state.ebox.widget.status,
  inAdminSearchMode: state.ebox.list.inAdminSearchMode,
  lng: state.params.lng,
});

const mapDispatchToProps = dispatch => ({
  initEbox: () => dispatch(initEbox()),
  setEboxRecipientType: (type) => dispatch(setEboxRecipientType(type)),
  showEboxWidget: () => dispatch(showEboxWidget()),
  setCurrentNavPage: () => dispatch(setCurrentNavPage("E_BOX")),
  fetchUser: (id) => dispatch(fetchUser(id)),
});

@connect(mapStateToProps, mapDispatchToProps)
class EboxPage extends Component {

  state = {
    galleryOpened: false,
    currentTab: "EMAILS",
    sender: null,
  }

  componentDidMount() {
    const { setCurrentNavPage, setEboxRecipientType, fetchUser } = this.props;
    setCurrentNavPage();
    this.props.initEbox();

    const query = new URLSearchParams(location.search);
    const recipientType = query.get('type');
    const senderId = query.get('senderId');
    if (recipientType) {
      setEboxRecipientType(recipientType);
    }
    if (senderId) {
      fetchUser(senderId).then(result => {
        this.setState({ sender: result.value?.data?.data })
      });
    }
  }

  toggleGallerySideBar = () => {
    this.setState({ galleryOpened: !this.state.galleryOpened });
    let bodyTag = document.getElementsByTagName('body');

    if (!this.state.galleryOpened) {
      bodyTag[0].style.overflow = 'hidden';
    } else {
      bodyTag[0].style.overflow = 'unset';
    }
  }

  switchTab = (tab) => {
    this.setState({ currentTab: tab });
  }

  render() {
    const { owner, inAdminSearchMode, widgetStatus, showEboxWidget, auth, lng } = this.props;
    const { galleryOpened, currentTab, sender } = this.state;

    return (
      <div className="ttp-tab-container">
        {!inAdminSearchMode && <TTPTab tabs={tabs} activeTab={currentTab} selectTab={this.switchTab} />}
        {currentTab === "EMAILS" && <div>
          <List inOtherEbox={inAdminSearchMode} />
          {(owner || !inAdminSearchMode) && <div className={`campaign-bubbles`}>
            <Bubble onBubbleClick={this.toggleGallerySideBar} label="Gallery"
              svgIcon={SVG_EBOX_GALLERY_ICON}
              isActive={galleryOpened} />
            <Bubble label={_("new")} svgIcon={SVG_SEND_ICON} cssClass="mail-express"
              onBubbleClick={showEboxWidget} />
          </div>}
          <BackDrop show={galleryOpened} clicked={this.toggleGallerySideBar} />
          <Gallery inOtherEbox={inAdminSearchMode}
            opened={galleryOpened && (!inAdminSearchMode || owner)}
            ownerId={owner ? owner.id : null}
            toggleGallerySideBar={this.toggleGallerySideBar}
          />
          <div className={`overlay ${widgetStatus === FULL_SCREEN_WIDGET && 'visible'}`} />
          {widgetStatus && <EmailComposer />}
        </div>}

        <div className={`${currentTab !== "SMS" ? "hide" : ""}`}>
          <ListSMS />
        </div>
        {/*<ChatWidget currentUser={auth.user} users={sender ? [sender] : undefined} env={APP_ENV} lng={lng}/>*/}
      </div>
    );
  }
}

export const tabs = [
  {
    id: 'EMAILS',
    label: _('emails'),
  },
  {
    id: 'SMS',
    label: 'SMS',
  },
]
export default EboxPage;
