import React, { Component } from 'react'
import { connect } from 'react-redux';
import uuid from 'uuid';

import { fetchBlocks } from 'thunks';
import LayoutItem from './LayoutItem';
import LayoutItemFetching from 'fetching/blocks/LayoutItemFetching';
import NotFound from 'notFound/NotFound';

const mapStateToProps = (state, ownProps) => ({
  currentLayout: state.themes.newsletter.currentLayout,
  layouts: state.themes.blocks.items,
  filter: state.themes.blocks.filter,
  fetching: state.themes.blocks.fetching,
  nbResult: state.themes.blocks.nbResult,
});

const mapDispatchToProps = dispatch => ({
  fetchLayouts: () => dispatch(fetchBlocks()),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class LayoutsList extends Component {
  constructor(props) {
    super(props);
    this.state = { showTemplatesList: false };
  }

  componentDidMount() {
    this.props.fetchLayouts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.filter != this.props.filter) {
      this.props.fetchLayouts();
    }
    if (!this.props.currentLayout) {
      return;
    }
    let scrolIntoElem = document.getElementById(`layout-${this.props.currentLayout.id}`);
    if (scrolIntoElem) {
      scrolIntoElem.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start"
      });
    }
  }

  render() {
    const { layouts, fetching, filter, currentLayout } = this.props;

    let items = [];

    if (filter.type != "TEMPLATE" && layouts && layouts.length > 0) {
      for (let i = 0; i < layouts.length; i++) {
        items.push(<LayoutItem key={`layout-${layouts[i].id}`} {...layouts[i]}
          isCurrent={currentLayout && layouts[i].id === currentLayout.id} />);
      }
    } else if (filter.type == "TEMPLATE" && currentLayout) {
      items.push(<LayoutItem
        key={`layout-${currentLayout.id}`} {...currentLayout} />);
    }

    if (fetching) {
      for (let i = 0; i < 4; i++) {
        items.push(<LayoutItemFetching key={`layout-fetch-${uuid()}`} />);
      }
    }

    return ((items.length > 0) ?
      <div className="row small-10 columns">{items}</div> :
      <NotFound text="Oops, no layouts were found." />
    );
  }
}
