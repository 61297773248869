import React, { PureComponent } from "react";
import _ from 'i18n';
import Parser from 'html-react-parser';
import {connect} from "react-redux";

@connect((store) => {
  return {
    isFromCampaignStats: store.campaigns.stats.campaign.data.isFromCampaignStats,
    fetching: store.campaigns.stats.campaign.fetching,
  }
})
export default class Piechart extends PureComponent {

  getChartDetails = () => {
    const { onStatusClick, scrolInto } = this.props;
    let scrolIntoElem = document.getElementById(scrolInto || "recipients-details");
    if (scrolIntoElem) {
      scrolIntoElem.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
    onStatusClick();
  }

  render() {
    const { target, rate, label, extraHtml, pieColor, isFromCampaignStats, fetching } = this.props;
    const radius = 60;
    let perimeter = 2 * Math.PI * radius;
    let strokeDashoffset = ((100 - rate) / 100) * perimeter;

    return (
      <div className="columns small-4">
        <div className="piechart" onClick={this.getChartDetails}>
          <div className="piechart-wrapper">
            <div className="piechart-heading"> {label} : {target}{(extraHtml) ? Parser(extraHtml) : ''}</div>
            <div className="piechart-container">
              <div  className={`piechart__percentage ${(isFromCampaignStats || fetching) && "hover-disabled"}`}> <span className="piechart__percentage-number" style={{color: pieColor }}>{rate} %</span></div>
              {!isFromCampaignStats && !fetching && <div className="piechart__search" style={{ backgroundColor: pieColor }}><i className="icon-magnifier"/></div>}
              <svg width="140" height="140" >
                <circle className="piechart-circle__empty" r={radius} cy="70" cx="70" stroke="#F1F2F4" fill="#ffffff"/>
                <circle style={{ strokeDashoffset, strokeDasharray: perimeter }} className="piechart-circle__fill" r={radius} cy="70" cx="70" stroke={pieColor || "#47c9e5"} fill="none"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
