import React from 'react';
import _ from 'i18n';

import { LANGUAGE_FLAGS, CAMPAIGN_LANGUAGE } from 'Common';
import ApplyButton from '../../ui/ApplyButton';
import { SVG_ICONS } from "Common";
import { ucFirst } from "utils";

export const SettingTheme = (props) => {

  let { language, active, theme, clicked, onRemove } = props;
  const lng = LANGUAGE_FLAGS[language] && <span className={`flag-icon  ${LANGUAGE_FLAGS[language]}`}></span>;
    function handleClick(e)  {
      onRemove(null ,language);
      e.stopPropagation();
    }
    return (
        <div className="large-3 medium-4 small-6">
            <div className={`picked-theme center ${active ? "active" : ""}`} onClick={clicked}>
                {(!theme) ? <div className="picked-theme__lng">
                    {lng}
                    <span>{_(CAMPAIGN_LANGUAGE[language])}</span>
                </div> :
                    <span className="picked-theme__label">&#10003; {(theme.organization) ? _("my models") : _("library")}</span>
                }
                {theme && <div>
                    <div className="show-hide">
                      <div className="theme-delete__action delete-action" title={_("delete")} onClick={handleClick}>
                        <img src={"/img/icons/" + SVG_ICONS["DELETE"]} />
                      </div>
                    </div>
                    <img className="picked-theme__preview" src={theme.preview} />
                    <div className="picked-theme__detail">
                        <span className="picked-theme__title">{theme.name}</span>
                        {lng}
                    </div>
                </div>}
      </div>
    </div>
  );
}

export const SimpleTheme = (props) => {
  const { name, preview, clicked, checked } = props;
  return (
    <div className="theme-picker__item large-3 medium-4 small-6">
      <div className="simple-theme" onClick={clicked}>
        <img style={(!preview) ? { height: "66%", width: "100%" } : { height: "auto"}}
          src={(preview) ? `${preview}` : '/img/theme/no-theme.png'} />
        <span className="simple-theme__title">{name}</span>
        <ApplyButton onToggleSelection={clicked} label="Apply the model" checked={checked} />
      </div>
    </div>
  )
};
