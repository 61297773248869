import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getLanguages } from 'utils';
import { setTextModelsSearchWord, setTextModelsLanguage, setTextModelsTargetApp, setTextModelsTargetType, setTextModelsScope, initTextModels } from "actions";
import { fetchGenericEmailTypes } from 'thunks'
import _ from 'i18n';
import TTPFilterHorizontalBar from '../common/filter/TTPFilterHorizontalBar';
import { SCOPE_TYPES } from "../../config/Common";
import { SCOPE_TABS, RESTRICTED_MODE, ADMIN_MODE } from "../../constants";
import { getLabel } from '../../services/utils';
import TTPTab from "../ui/tabs/TTPTab";
import { getSupportedAppsOptions } from '../../services/apps';


const mapStateToProps = (state, ownProps) => ({
  language: state.textModels.filter.language,
  searchWord: state.textModels.filter.searchWord,
  targetApp: state.textModels.filter.targetApp,
  targetType: state.textModels.filter.targetType,
  scope: state.textModels.filter.scope,
  genericEmailTypes: state.genericEmail.types.items,
  lng: state.auth.user.language,
  typesFetched: state.genericEmail.types.fetched,
});
const mapDispatchToProps = dispatch => ({
  fetchTypes: () => dispatch(fetchGenericEmailTypes()),
  setTextModelsScope: (scope) => dispatch(setTextModelsScope(scope)),
  setTextModelsLanguage: (language) => dispatch(setTextModelsLanguage(language)),
  setTextModelsTargetApp: (targetApp) => dispatch(setTextModelsTargetApp(targetApp)),
  setTextModelsTargetType: (targetType) => dispatch(setTextModelsTargetType(targetType)),
  setTextModelsSearchWord: (word) => dispatch(setTextModelsSearchWord(word)),
  initTextModels: () => dispatch(initTextModels()),
});

@connect(mapStateToProps, mapDispatchToProps)

export default class TextModelFilters extends Component {

  constructor(props) {
    super(props);
    this.languageOptions = getLanguages().map(lng => {
      return { value: lng.key, label: _(lng.value) }
    });
    this.targetAppOptions = getSupportedAppsOptions();

    this.scopeOptions = SCOPE_TYPES.map(({ value, label, color  }) => {
      return { label: _(label), value, color  };
    });

    this.state = { targetTypeOptions: [], currentTab: "COMMUNITY" };
  }

  switchTab = (currentTab) => {
    this.setState({ currentTab });
    this.props.setTextModelsScope((currentTab == "COMMUNITY") ? ["COMMUNITY"] : ["TAMTAM"]);
  }

  componentDidMount() {
    const { fetchTypes, typesFetched, targetApp } = this.props;
    if (!typesFetched) {
      fetchTypes().then(() => {
        this.setTargetTypeOptions(targetApp);
      });
    } else {
      this.setTargetTypeOptions(targetApp);
    }
  }

  setTargetTypeOptions = (targetApp) => {
    let options = (this.props.genericEmailTypes || []).reduce((acc, type) => {
      if (type.targetApp === targetApp) {
        acc.push({
          value: type.id,
          label: getLabel(type, this.props.lng).toUpperCase(),
        });
      }
      return acc;
    }, []);

    this.setState({ targetTypeOptions: options });
  }

  componentDidUpdate(prevProps) {
    const { targetApp, scope, genericEmailTypes } = this.props;

    if (prevProps.targetApp != targetApp || genericEmailTypes != prevProps.genericEmailTypes) {
      this.setTargetTypeOptions(targetApp);
    }
    if (!scope && prevProps.scope != scope) {
      setTextModelsScope(["COMMUNITY", "TAMTAM"]);
    }
  }

  handleSelectLanguage = (selected) => {
    this.props.setTextModelsLanguage(selected);
  };

  handleSelectTargetApp = (selected) => {
    this.props.setTextModelsTargetApp(selected);
  };

  handleSelectTargetType = (selected) => {
    this.props.setTextModelsTargetType(selected);
  };

  handleSelectScope = (selected) => {
    this.props.setTextModelsScope(selected);
  };

  handleSearchInputChange(word) {
    this.props.setTextModelsSearchWord(word);
  };

  handleResetFilter = () => {
    this.props.initTextModels()
  }

  render() {
    let { language, searchWord, mode, scope, targetType, targetApp } = this.props;
    const { targetTypeOptions, currentTab } = this.state;
    let filters = [
      { placeholder: _("language"), options: this.languageOptions, values: language, action: this.handleSelectLanguage },
      { placeholder: _("application"), options: this.targetAppOptions, values: targetApp, action: this.handleSelectTargetApp, isSimple: true },
      { placeholder: _("type"), options: targetTypeOptions, values: targetType, action: this.handleSelectTargetType },
    ];

    if (!mode || (mode && ADMIN_MODE !== mode)) {
      filters.unshift({ placeholder: _("scope"), options: this.scopeOptions, values: scope, action: this.handleSelectScope })
    }

    return (
      <div className="row align-center">
        {
          RESTRICTED_MODE === mode ?
            <TTPTab tabs={SCOPE_TABS} type="simple" activeTab={currentTab} selectTab={this.switchTab} />
            :
            <TTPFilterHorizontalBar
              filters={filters}
              searchInputValue={searchWord}
              handleResetFilter={this.handleResetFilter}
              handleSearchInputChange={this.handleSearchInputChange.bind(this)}
              cssClass={(mode && ADMIN_MODE !== mode) ? "light small-11" : "light small-12"}
              autoFixed={false}
            />
        }
      </div>

    );
  }
}
