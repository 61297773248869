import * as actions from 'actions';
import { getGenericEmailTypes } from 'TamtamAPI';

export const fetchGenericEmailTypes = () => {
    return (dispatch, getState) => {
        const { token } = getState().auth;
        let targetApp = getState().settings.targetApp;
        return dispatch(actions.fetchGenericEmailTypes(
            getGenericEmailTypes(token, targetApp)
        ));
    };
};