import * as actions from "actions";
import * as api from 'TamtamAPI';

export const fetchArticles = (params = null) => {
  return (dispatch, getState) => {

    const { currentClient, token } = getState().auth;
    const { searchWord, pageSize, paginationPage, language, tags, categories, articlePages } = getState().articles.filter;
    const clientId = currentClient.id;

    return dispatch(
      actions.fetchArticles(
        api.getArticles({
          token,
          clientId,
          pageSize,
          paginationPage,
          searchWord,
          language,
          tags,
          categories,
          articlePages,
          params
        })
      )
    );
  };
};

export const fetchCategoriesSuggestions = ({ searchWord, lng }) => {
  return (dispatch, getState) => {

    let { token } = getState().auth;
    return dispatch(
      actions.setCategoriesSuggestions(api.getArticlesCategories({ token, searchWord, lng }))
    );
  };
};

export const fetchArticlePagesOptions = () => {
  return (dispatch, getState) => {
    const { currentClient, token } = getState().auth;
    const clientId = currentClient.id;

    return dispatch(
      actions.fetchArticlePagesOptions(api.getArticlePages({ token, clientId }))
    );
  };
};

export const fetchTagsSuggestions = ({ searchWord, lng }) => {
  return (dispatch, getState) => {

    let { token } = getState().auth;
    return dispatch(
      actions.setTagsSuggestions(api.getBlogTags({ token, searchWord, lng }))
    );
  };
};
