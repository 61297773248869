import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setTTPDialogCustomData, setTTPDialogShow } from 'actions';

import _ from 'i18n';
import { LANGUAGE_FLAGS } from 'Common';
import ItemHeader from 'generic-emailings/ItemHeader';
import DIALOG_TYPES from "../../constants/TTPDialog";
import { SVG_ICONS } from '../../config/Common';
import { deleteTextModel, saveTextModel, sendTextModelTest } from 'thunks';
import ApplyButton from '../ui/ApplyButton';
import {getLabel} from '../../services/utils';
import Switch from 'common/Switch';
import SendTestForm from '../common/SendTestForm';
import { SVG_DELETE_ICON } from 'utils';
import {ADMIN_MODE} from "../../constants";
import TextModelAction from "../settings/TextModelAction";

const mapStateToProps = (state, ownProps) => ({
  currentClient: state.auth.currentClient,
  lng: state.auth.user.language,
});

const mapDispatchToProps = dispatch => ({
  setTTPDialogCustomData: (textModelId) => dispatch(setTTPDialogCustomData({
    'approvedAction': () => dispatch(deleteTextModel(textModelId)),
    'message': _('deleteTextModelConfirm'),
    'proceedBtnLabel': _('delete'),
    'type': DIALOG_TYPES["ALERT"],
    'title': "Confirmation of deletion",
    'svgIcon': SVG_DELETE_ICON,
  })),
  setSaveTTPDialogCustomData:(TTPDialogData) => dispatch(setTTPDialogCustomData(TTPDialogData)),
  saveTextModel: (data, isTamtamTextModel) => dispatch(saveTextModel(data, isTamtamTextModel)),
  setTTPDialogShow:(show) => dispatch(setTTPDialogShow(show)),
  sendEmailTest: (data) => dispatch(sendTextModelTest(data)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class TextModelItem extends Component {

  constructor(props) {
    super(props);
    this.state = {main : this.props.main, showTestSendModel:false}
  }

  toogleTextModel = () => {
    const { id, content, object, client, onApply } = this.props;
    if (onApply) {
      onApply({ object, text: content, originalTextId: id, source: client ? "COMMUNITY" : "TAMTAM" });
    }
  }

  handleEditTextModel(textModelData) {
    this.props.toggleForm(true);
    this.props.setTextModelData({ ...textModelData, formAction: "UPDATE" });
  }

  handleDuplicateTextModel(textModelData) {
    this.props.toggleForm(true);
    this.props.setTextModelData({ ...textModelData, main: 0, formAction: "DUPLICATE"});
  }

  handleOpenDeleteTextModelDialog(textModelId) {
    const { setTTPDialogCustomData } = this.props;
    setTTPDialogCustomData(textModelId);
  }

  sendTest = (emails) => {
    const { sendEmailTest,id } = this.props;
    sendEmailTest({
        emails: emails,
        textModelId: id
    })
    this.setState({ ...this.state, showTestSendModel: false})
  }

  handleMainTextChange = () => {
    const {content, object, title, language, id, targetType, targetApp, saveTextModel, setSaveTTPDialogCustomData, mode} = this.props;

      this.setState({main: 1}, () => {
      if (this.props.main === 0) {
        let {main} = this.state;
        let textModelData = {title, content,name, object, language, id, targetType, targetApp, main};
        const isTamtamTextModel = mode && ADMIN_MODE === mode;
        const TTPDialogData = {
          'approvedAction': () => saveTextModel(textModelData, isTamtamTextModel),
          'abortAction': () => {
            this.cancel()
          },
          'message': (main === 0) ? _('UndoEditMainTextConfirm') : _('editMainTextConfirm'),
          'proceedBtnLabel': _('edit'),
          'type': DIALOG_TYPES["SUCCESS"],
          'title': "Confirmation of validation",
        };
        setSaveTTPDialogCustomData(TTPDialogData);
      }
    })
  };

  cancel = () => {
    const {main, setTTPDialogShow} = this.props;
    this.setState({main});
    setTTPDialogShow(false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { main } = this.props;

    if (prevProps.main !== main) {
      this.setState({ main });
    }
  }

  render() {
    const {content, object, title, language, id, name, mode, targetType, targetApp, client, currentClient, type, current} = this.props;
    const {main} = this.state;

    const unlocked = client && client.id && currentClient && (client.id === currentClient.id);
    const isAdminMode = mode && ADMIN_MODE === mode;
    let textModelData = { title, content, object,name, language, id, targetType, targetApp, main };
    const checked = current == id;

    return (
      <div className={`generic-email-list__item small-6`} >
        <ItemHeader targetApp={targetApp} isPublic={!unlocked || isAdminMode} />
        <div className="generic-email__content">
          <div className="generic-email__model">
            <div className="generic-email__model__content">
              <div className="header">
                <div className="small-11">
                  <h6 className="generic-email__title">
                    <span>{`${_('Type')} :`}</span>
                    <span>{type ? getLabel(type, this.props.lng) : _('undefined')}</span>
                    {language && LANGUAGE_FLAGS[language] && <span
                      className={`language flag-icon ${LANGUAGE_FLAGS[language]} icon-right`}/>}
                  </h6>
                  <h6 className="generic-email__title">
                    <span> {name  ? `${_('Name')} :`:`${_('Object')} :`}</span>
                    <span>{name  ? name : object}</span>
                  </h6>
                </div>
                {(unlocked || isAdminMode) && <div className="header__main">
                  <span
                    className={`text-capitalize ${main === 1 && "main-color"}`}>{_('main text')}</span>
                  <Switch name="main" isChecked={main === 1}
                          onChange={this.handleMainTextChange}/>
                </div>
                }
              </div>
              <div className="generic-email__text"
                   dangerouslySetInnerHTML={{__html: content}}/>
            </div>
            {(mode && ADMIN_MODE !== mode) &&
            <ApplyButton label="Apply the model" checked={checked}
                         onToggleSelection={this.toogleTextModel}/>}
            {(!mode || (mode && ADMIN_MODE === mode)) &&
            <TextModelAction edit={() => this.handleEditTextModel(textModelData)}
                             duplicate={() => this.handleDuplicateTextModel(textModelData)}
                             deleteModel={() => this.handleOpenDeleteTextModelDialog(id)}
                             sendModel={() => {this.setState({ ...this.state, showTestSendModel: true})}}
                             unlocked={unlocked}
                             isAdminMode={isAdminMode}/>
            }
             { this.state.showTestSendModel &&
                <SendTestForm
                  onCloseModal={() => {this.setState({ ...this.state, showTestSendModel: false})}}
                  onSave={this.sendTest}
                  />
                }
          </div>
        </div>

      </div>
    );
  }
}
