import React, { Component } from 'react';
import { connect } from 'react-redux';
import {SVG_ICONS} from "../../config/Common";
import _ from 'i18n';


export default class TextModelAction extends Component {

  render(){
    const {edit, duplicate, deleteModel, sendModel, isAdminMode, unlocked} = this.props;
    return (
    <div className="text-models__status">
      {(unlocked || isAdminMode) && <div className="text-actions__action" onClick={edit} title={_("edit the model")}>
        <img src={"/img/icons/" + SVG_ICONS["EDIT"]  }/>
      </div>}
      <div className="text-actions__action" onClick={duplicate} title={_("duplicate the model")}>
        <img src={"/img/icons/" + SVG_ICONS["DUPLICATE"] }/>
      </div>
      {(unlocked || isAdminMode) && <div className="text-actions__action delete-action" onClick={deleteModel} title={_("delete")}>
        <img src={"/img/icons/" + SVG_ICONS["DELETE"]}/>
      </div>}
      {(unlocked || isAdminMode) && <div className="text-actions__action send-action" onClick={sendModel} title={_("send a test")}>
        <img src={"/img/icons/" + SVG_ICONS["EMAIL_LIGHT"]}/>
      </div>}
    </div>
    );
  }
}
