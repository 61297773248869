import React from "react";
import SampleSelector from "../../editor/SampleSelector";
import {connect} from "react-redux";
import sample from "../../editor/sample";
import {setCurrentDndTheme, setTTPDialogCustomData, setThemeIsSaved} from "actions";
import _ from "i18n";

class ThemeSelector extends React.Component {

  componentDidMount() {
    const { childRef, currentTheme, proceed } = this.props;
    if (childRef) {
      childRef({ secondaryAction: () => currentTheme ? proceed() : this.selectTheme(null) });
    }
  }

  selectTheme = (theme) => {
    try {
      let design = theme && theme.design || sample;
      if (design && (typeof design === "string" || design instanceof String)) {
        design = JSON.parse(design);
      }
      this.props.setCurrentDndTheme({...theme, design});
      this.props.setThemeIsSaved(!!(theme && theme.id));
      this.props.proceed();
    }catch (e) {
      console.log(e);
    }
  };

  handleConfirmTheme = (theme) => {
    const {currentTheme, setTTPDialogCustomData} = this.props;

    if (currentTheme && (currentTheme.id !== theme?.id)) {
      setTTPDialogCustomData({
          'approvedAction': () => this.selectTheme(theme),
          'message': _('confirmSwitchTheme'),
          'title': "Confirmation of switching models"
        }
      )
    }
    else {
      this.selectTheme(theme);
    }
  }

  render() {
    return <SampleSelector type={["DRAG_AND_DROP"]} onSelect={this.handleConfirmTheme} addEmptyIcon={"add.svg"} currentTheme={this.props.currentTheme?.id}/>;
  }
}
const mapStateToProps = store => ({
  currentTheme: store.themes.currentDndTheme
});

const mapDispatchToProps = dispatch => ({
  setCurrentDndTheme: theme => dispatch(setCurrentDndTheme(theme)),
  setTTPDialogCustomData: data => dispatch(setTTPDialogCustomData(data)),
  setThemeIsSaved: isSaved => dispatch(setThemeIsSaved(isSaved)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSelector);
