import * as actions from 'actions';
import { sendEmails } from 'TamtamAPI';

export const sendTransactionalEmail = (data) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.sendTransactionalEmail(sendEmails(token, data)));
  };
};
