import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextModelList from "../../../text-model/TextModelList";
import _ from 'i18n';
import Viewer from "../../../ui/TTPModal";
import { setCurrentEmailData } from "actions";
import Breadcrumbs from "../../../ui/Breadcrumbs";
import { getGenericEmailPreview, onSuccess, onError, getModalStyle } from "../../../../services/utils";
import { saveCurrentGenericEmail } from "../../../../actions/thunks/generic-emailings";
import { RESTRICTED_MODE } from '../../../../constants';
import ThemePicker from '../../../common/theme/ThemePicker';
import ThemeList from './theme/ThemeList';

@connect(store => ({
  lang: store.params.lng,
  currentEmail: store.genericEmail.currentSetting.currentEmail,
  windowHeight: store.params.windowHeight,
  selectedText: store.genericEmail.currentSetting.currentEmail.originalTextId,
}), dispatch => ({
  close: () => dispatch(setCurrentEmailData({ action: null })),
  saveCurrentGenericEmail: () => dispatch(saveCurrentGenericEmail()),
  setCurrentEmailText: (data) => dispatch(setCurrentEmailData(data)),
}))
export default class Editor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      content: "",
      activeStep: "TEXT_MODEL",
      steps: {
        TEXT_MODEL: {
          label: _('text model'),
          enabled: true,
        },
        TEMPLATE: {
          label: _('models'),
          enabled: !!props.textModel,
        }
      },
    };
  }

  componentDidMount() {
    this.setState({ activeStep: "TEXT_MODEL" });
  }

  componentDidUpdate(prevProps) {
    const { object, text, theme } = this.props.currentEmail;
    const prevCurrentEmail = prevProps.currentEmail;
    const { steps } = this.state;
    if (text !== prevCurrentEmail.text || object !== prevCurrentEmail.object || theme !== prevCurrentEmail.theme) {
      const preview = getGenericEmailPreview({ text, object }, theme);
      this.setState({ preview, steps: { ...steps, TEMPLATE: { ...steps.TEMPLATE, enabled: text && object } } });
    }
    if (text !== prevCurrentEmail.text) {
      this.setState({ activeStep: "TEXT_MODEL" });
    }
  }

  setActiveStep = (activeStep) => {
    this.setState({ activeStep });
  };

  saveEmail = () => {
    this.props.saveCurrentGenericEmail().then((res) => {
      onSuccess(res);
      if (this.setState) {
        this.setState({ activeStep: "TEXT_MODEL" })
      }
    }, (err) => onError(err));
  };

  render() {
    let { currentEmail: { action, theme, object, text, updating, offsetTop }, close, windowHeight, setCurrentEmailText, selectedText } = this.props;
    let { preview, steps, activeStep } = this.state;

    if (action !== 'EDIT') {
      return null;
    }

    const modalStyle = {
      content: {
        width: "90%",
        height: "90%",
        padding: "0 15px",
      }
    };
    return <Viewer isOpen={true} onClose={close} preview={preview} modalStyle={getModalStyle(modalStyle, { content: { height: `calc(${windowHeight}px - 4rem)`, top: `calc(${offsetTop}px + 6rem)`, transform: "translateX(-50%)" } })} shouldNotCloseOnOverlayClick={true}>
      <div className="ge-viewer row">
        <div className={`ge-viewer__container small-8`}>
          <Breadcrumbs steps={steps} activeStep={activeStep} goToStep={this.setActiveStep} />
          <div className={`ge-viewer__container-content ${('TEXT_MODEL' === activeStep) ? "" : "hide"}`}>
            <TextModelList
              mode={RESTRICTED_MODE}
              onApply={setCurrentEmailText}
              current={selectedText ? selectedText : ""}
            />
          </div>
          <div className={`ge-viewer__container-content ${('TEMPLATE' === activeStep) ? "" : "hide"}`}>
            <ThemeList />
          </div>
        </div>
        <div className="ge-viewer__aside small-4">
          <div className="save-btn-container ttp-small-buttons">
            <button onClick={close} className="cancel">
              {_('cancel')}
            </button>
            <button onClick={!updating && this.saveEmail} className={`success ${!(object && text) ? "disabled" : ""}`}>
              {updating ? _("Processing...") : _('save')}
            </button>
          </div>
          <div
            className={`ge-viewer__aside-content ${!preview ? " gradiant" : ""} ${theme ? "scale-preview" : ""}`}>
            <iframe srcDoc={preview} />
          </div>
        </div>
      </div>
    </Viewer>;
  }
}

