import React from "react";
import TTPModal from "../../ui/TTPModal";
import _ from "i18n";
import "../../../styles/components/campaign/list/_share-campaign.scss";
import BroadcastedCampaign from "./shareCampaign/BroadcastedCampaign";
import EmailContainer from "./shareCampaign/EmailContainer";
import BroadcasterItem from "./shareCampaign/BroadcasterItem";
import CampaignGoTo from "./shareCampaign/CampaignGoTo";
import { connect } from "react-redux";
import {
  fetchCampaignBroadcasters,
  persistCampaign,
} from "../../../actions/thunks/campaign";
import TTPLoader from "../../ui/TTPLoader";

@connect(
  (store) => {
    return {
      currentCampaign: store.currentCampaign.email,
      broadcastedRecipients: store.campaigns.campaignBroadcasters.items,
      isBroadcastedRecipientsFetching:
        store.campaigns.campaignBroadcasters.fetching,
      allowedGroups: store.currentCampaign.email.allowedGroups,
      deniedGroups: store.currentCampaign.email.deniedGroups,
    };
  },
  (dispatch) => ({
    fetchCampaignBroadcasters: (broadcastedCampaign) =>
      dispatch(fetchCampaignBroadcasters(broadcastedCampaign)),
    persistCampaign: (data) => dispatch(persistCampaign(data)),
  })
)
export default class ShareCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.handleRecipientsChange = this.handleRecipientsChange.bind(this);

    this.state = {
      queuedRecipients: [],
      originalCampaignId: null,
    };

    this.modalStyle = {
      content: {
        border: "none",
        background: "rgb(255, 255, 255)",
        transition: "opacity 0.4s ease-out 0s",
        boxShadow:
          "0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)",
        padding: "0 0",
        width: "90%",
        height: "90%",
        top: "5%",
        left: "5%",
        transform: "translate(3%, 9%)",
        position: "fixed",
        maxHeight: "85%",
        maxWidth: "85%",
      },
      overlay: {
        zIndex: 9,
        backgroundColor: "hsla(0,0%,4%,.45)",
      },
    };
  }

  componentDidMount() {
    let { currentCampaign } = this.props;
    this.setState({ originalCampaignId: currentCampaign.id });
    this.props.fetchCampaignBroadcasters(currentCampaign.id);
  }

  handleRecipientsChange(queuedRecipients) {
    this.setState(
      (prevState) => ({
        queuedRecipients: prevState.queuedRecipients.concat(queuedRecipients),
      }),
      () => {
        if (this.state.queuedRecipients) {
          this.state.queuedRecipients.map((item) => {
            this.props.broadcastedRecipients.push({
              broadcastEmail: item,
              sharedEmailSentCount: 0,
              isProcessing: true,
              clicksCount: 0,
            });
          });
          this.props.broadcastedRecipients.reverse();
        }
      }
    );
  }

  render() {
    const {
      isOpen,
      onClose,
      broadcastedRecipients,
      isBroadcastedRecipientsFetching,
    } = this.props;

    return (
      <TTPModal isOpen={isOpen} onClose={onClose} modalStyle={this.modalStyle}>
        <div className="modal-popup">
          <BroadcastedCampaign
            campaignName={this.props.currentCampaign.object}
          />
          {isBroadcastedRecipientsFetching ? (
            <TTPLoader cssClass={"fixed-loader"} />
          ) : (
            <>
              <CampaignGoTo
                originalCampaignId={this.state.originalCampaignId}
              />
              <div className="modal-container">
                <div className="modal-content">
                  <EmailContainer
                    onRecipientSent={this.handleRecipientsChange}
                    originalCampaignId={this.state.originalCampaignId}
                  />
                </div>
                <div className="modal-headline">
                  <div className="headline-container">
                    <h2 className="title-container">
                      {_("This_campaign_is_shared_with") +
                        broadcastedRecipients?.length ?? 0}{" "}
                      {_("Contacts")}
                    </h2>
                    <p className="text-container">
                      {_("Description_add_your_broadcast_contacts")}
                    </p>
                    <div className={"broadcaster_item"}>
                      {broadcastedRecipients?.map((item) => (
                        <BroadcasterItem
                          broadcastEmail={item.broadcastEmail}
                          sharedEmailSentCount={item.sharedEmailSentCount}
                          isProcessing={item?.isProcessing ?? false}
                          clicksCount={item.clicksCount}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </TTPModal>
    );
  }
}
