import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setTextModelsScope,
  setTextModelsLanguage,
  setTextModelsTargetApp,
  setTextModelsTargetType,
} from "actions";
import _ from "i18n";
import { setCurrentEmailCampaignData } from "../../../actions/actions/currentCampaign";
import { setEditorAllowOpenModels } from "../../../actions/actions/editor/editor";
import HorizontalFilters from "../../text-model/HorizontalFilters";
import { fetchTextModels } from "thunks";
import { setTextModelsSearchWord } from "../../../actions/actions";
import TextModelItemSimple from "../../common/TextModelItemSimple";
import NotFound from "notFound/NotFound";
import TextModelFetching from "../../fetching/TextModelFetching";
import { SUPPORTED_APPS, SUPPORTED_APPS_UA } from "../../../services/apps";
import { getLabel, getLanguages } from "../../../services/utils";
import { fetchGenericEmailTypes } from "../../../actions/thunks/generic-emailings";

@connect((store) => {
  return {
    selectedText: store.currentCampaign.email.textModel,
    scope: store.textModels.filter.scope,
    isUaNewsletter: store.currentCampaign.uaNewsletter.isUaNewsletter,
  };
})
class TextModelEboxWidget extends Component {
  constructor(props) {
    super(props);
    this.languageOptions = getLanguages().map((lng) => {
      return { value: lng.key, label: _(lng.value) };
    });
    this.targetAppOptions = SUPPORTED_APPS.map(({ code, name }) => {
      return { value: code, label: _(name.toLowerCase()) };
    });
    this.state = { targetTypeOptions: [] };
  }

  componentDidMount() {
    const {
      fetchTypes,
      typesFetched,
      targetApp,
      paramstargetApp,
      URLtargetApp,
    } = this.props;
    let targetAppValue = "";

    if (targetApp) {
      targetAppValue = Array.isArray(targetApp) ? targetApp[0] : targetApp;
    } else {
      targetAppValue = paramstargetApp || URLtargetApp || "EXPRESS";
    }

    if (!typesFetched) {
      fetchTypes().then(() => {
        this.setTargetTypeOptions(targetAppValue);
      });
    } else {
      this.setTargetTypeOptions(targetAppValue);
    }
  }

  setTargetTypeOptions = (targetApp) => {
    let options = (this.props.genericEmailTypes || []).reduce((acc, type) => {
      if (type.targetApp === targetApp) {
        acc.push({
          value: type.id,
          label: getLabel(type, this.props.lng),
        });
      }
      return acc;
    }, []);

    this.setState({ targetTypeOptions: options });
  };

  componentDidUpdate(prevProps) {
    const {
      filter,
      fetchTextModels,
      fetching,
      targetApp,
      setTextModelsTargetType,
    } = this.props;
    if (filter !== prevProps.filter && !fetching) {
      if (prevProps.targetApp !== targetApp) {
        this.setTargetTypeOptions(targetApp);
        setTextModelsTargetType(null);
      }
      fetchTextModels();
    }
  }

  // removePreFilter = (filter) => {
  //   let {setTextModelsLanguage, language, targetType, lng, setTextModelsTargetType, setTextModelsTargetApp, fetchTextModels} = this.props;
  //   if (filter.label === _("language"))
  //     setTextModelsLanguage(null);
  //   if (filter.label === _("type"))
  //     setTextModelsTargetType(null);
  //   if (filter.label === _("application")) {
  //     if (lng !== null)
  //       setTextModelsLanguage([this.props.paramsLanguage || this.props.URLlanguage || language]);
  //     if (targetType != null)
  //       setTextModelsTargetType(this.props.paramstargetType || this.props.URLtargetType);
  //
  //     setTextModelsTargetApp("");
  //   }
  //   fetchTextModels();
  // }

  applyTextModel = (textModel) => {
    let { dispatch, onEditorChange, onObjectChange } = this.props;
    let { originalTextId, object, text, name, scope, language, targetApp } =
      textModel;
    dispatch(
      setCurrentEmailCampaignData({
        textModel: originalTextId,
        html: text,
        textModelTitle: object,
        textModelName: name,
        textModelScope: scope,
        textModelLanguage: language,
        textModelTargetApp: targetApp,
      }),
    );
    onObjectChange(object);
    onEditorChange(text);
    this.toggleModelsList();
  };

  toggleModelsList = () => {
    this.props.dispatch(setEditorAllowOpenModels(false));
  };

  handleSelectLanguage = (selected) => {
    this.props.setTextModelsLanguage(selected.target.value);
  };

  handleSelectTargetApp = (selected) => {
    this.props.setTextModelsTargetApp(selected.target.value);
  };

  handleSelectTargetType = (selected) => {
    this.props.setTextModelsTargetType(selected.target.value);
  };

  render() {
    const {
      selectedText,
      mode,
      textModels,
      scope,
      targetApp,
      setTextModelsTargetType,
      targetType,
      language,
      setTextModelsScope,
      setTextModelsSearchWord,
      setTextModelsLanguage,
      setTextModelsTargetApp,
      fetching,
      lng,
      embedded,
      isUaNewsletter,
    } = this.props;
    const { targetTypeOptions } = this.state;
    let items = [];
    let filters = [];
    let targetAppValue = "";
    if (targetApp) {
      targetAppValue = Array.isArray(targetApp) ? targetApp[0] : targetApp;
    } else {
      targetAppValue =
        this.props.paramstargetApp || this.props.URLtargetApp || "EXPRESS";
    }

    filters.push({
      label: _("language"),
      value:
        lng.length > 0
          ? lng
          : this.props.paramsLanguage || this.props.URLlanguage || language,
      options: this.languageOptions,
      action: this.handleSelectLanguage,
    });

    filters.push({
      label: _("application"),
      value: targetAppValue,
      options: !isUaNewsletter ? this.targetAppOptions : [],
      action: this.handleSelectTargetApp,
    });

    filters.push({
      label: _("type"),
      value:
        targetType && targetType.length > 0
          ? targetType
          : this.props.paramstargetType || this.props.URLtargetType || null,
      options: targetTypeOptions,
      action: this.handleSelectTargetType,
    });

    if (fetching) {
      for (let i = 0; i < 2; i++) {
        items.push(<TextModelFetching key={`fetch-${i}`} />);
      }
    }

    if (textModels && textModels.length > 0) {
      items = textModels.map((textModel) => {
        return (
          <div className={`large-4 medium-6 column`}>
            <TextModelItemSimple
              mode={mode}
              key={textModel.id}
              {...textModel}
              id={textModel.id}
              {...textModel}
              current={selectedText}
              content={textModel.content}
              object={textModel.object}
              name={textModel.name}
              onApply={this.applyTextModel}
            />
          </div>
        );
      });
    }

    return (
      <div>
        <HorizontalFilters
          mode={mode}
          scope={scope}
          targetApp={
            targetApp
              ? targetApp
              : this.props.paramstargetApp ||
                this.props.URLtargetApp || ["EXPRESS"]
          }
          targetType={
            this.props.paramstargetType || this.props.URLtargetType || null
          }
          language={
            lng.length > 0
              ? lng
              : this.props.paramsLanguage || this.props.URLlanguage || language
          }
          filters={filters}
          setTextModelsLanguage={setTextModelsLanguage}
          setTextModelsTargetApp={setTextModelsTargetApp}
          setTextModelsTargetType={setTextModelsTargetType}
          setTextModelsScope={setTextModelsScope}
          setTextModelsSearchWord={setTextModelsSearchWord}
          isWidget={true}
          embedded={embedded}
        />
        {items && items.length > 0 ? (
          <div className="row generic-email-container text-model-container">
            {items}
          </div>
        ) : (
          <NotFound mainClass={mode ? "small-11" : "small-10"} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  textModels: state.textModels.items,
  filter: state.textModels.filter,
  fetching: state.textModels.fetching,
  fetched: state.textModels.fetched,
  targetApp: state.textModels.filter.targetApp,
  targetType: state.textModels.filter.targetType,
  language: state.auth.user.language,
  lng: state.textModels.filter.language,
  typesFetched: state.genericEmail.types.fetched,
  genericEmailTypes: state.genericEmail.types.items,
});
const mapDispatchToProps = (dispatch) => ({
  setTextModelsScope: (scope) => dispatch(setTextModelsScope(scope)),
  fetchTextModels: () => dispatch(fetchTextModels(null, false)),
  setTextModelsLanguage: (language) =>
    dispatch(setTextModelsLanguage(language)),
  setTextModelsTargetApp: (targetApp) =>
    dispatch(setTextModelsTargetApp(targetApp)),
  setTextModelsTargetType: (targetType) =>
    dispatch(setTextModelsTargetType(targetType)),
  setTextModelsSearchWord: (word) => dispatch(setTextModelsSearchWord(word)),
  fetchTypes: () => dispatch(fetchGenericEmailTypes()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TextModelEboxWidget);
