import React, { PureComponent } from 'react';
import _ from 'i18n';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { replaceEmailContent, ucFirst } from '../../../services/utils';
import { CAMPAIGN_TYPES } from 'Common';

class Preview extends PureComponent {

  constructor(props) {
    super(props);

    this.state = { device: DESKTOP, showPreview: false };
    this.handleDeviceChange = device => this.setState({ device });
  }


  showContentPreview = () => {
    const { pageUrl, mutatedContent, html, type } = this.props;
    return pageUrl?.length > 0 || mutatedContent?.length > 0 || (html?.length > 0 && type != "PLAIN_TEXT");
  }

  componentDidMount() {
    this.setState({ showPreview: this.showContentPreview() });
  }

  componentDidUpdate(prevProps) {
    const { pageUrl, mutatedContent, html } = prevProps;

    if (this.props.pageUrl != pageUrl || mutatedContent != this.props.mutatedContent || html != this.props.html) {
      this.setState({ showPreview: this.showContentPreview() });
    }
  }

  getTemplateHtml() {
    const { object, mutatedContent, html, type } = this.props;
    let content = html;
    if ('PLAIN_TEXT' == type) {
      content = replaceEmailContent({ body: html, subject: object }, mutatedContent);
    }
    return content;
  }

  render() {
    const { pageUrl, type } = this.props;
    const { device, showPreview } = this.state;
    const isTextCampaign = type == CAMPAIGN_TYPES['text'];
    let iframeProps = {};
    if (pageUrl) {
      iframeProps.src = pageUrl;
    } else if (type != CAMPAIGN_TYPES['web']) {
      iframeProps.srcDoc = this.getTemplateHtml();
    }


    return (
      <CSSTransition in={showPreview} timeout={500} classNames="campaign-preview" unmountOnExit>
        <div className={`campaign-preview ${isTextCampaign ? "medium-4" : "medium-8"} m-l-s`}>
          <div className="campaign-preview__device">
            {
              [DESKTOP, MOBILE].map(value => (<span key={value} className={`${value == device ? 'active' : ""}`} onClick={() => this.handleDeviceChange(value)}>
                {ucFirst(_(value.toLowerCase()))}
              </span>)
              )
            }
          </div>
          <div className={`campaign-preview__content`}>
            <iframe
              className={`${MOBILE == device ? 'mobile' : (isTextCampaign ? 'text-desktop' : '')}`}
              ref="iframe"
              scrolling="auto"
              allowFullScreen=""
              {...iframeProps}
            />
          </div>
        </div>
      </CSSTransition>
    )
  }
}

const mapStateToProps = state => ({
  pageUrl: state.currentCampaign.email.pageUrl,
  type: state.currentCampaign.email.type,
  mutatedContent: state.currentCampaign.email.mutatedContent,
  html: state.currentCampaign.email.html,
  object: state.currentCampaign.email.object,
})
export default connect(mapStateToProps)(Preview);

const DESKTOP = 'DESKTOP';
const MOBILE = 'MOBILE';
