const initialStateForApprovalReducer = {
  fetching: false,
  fetched: false,
  nbResult: 0,
  updating: false,
  items: [],
  error: null,
  isCampaignApproved: false,
};
export const approvalReducer = (
  state = initialStateForApprovalReducer,
  action,
) => {
  switch (action.type) {
    case "INIT_APPROVAL_FEEDBACK": {
      return initialStateForApprovalReducer;
      break;
    }
    case "FETCH_APPROVAL_FEEDBACK_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "SAVE_APPROVAL_REQUEST_PENDING": {
      return {
        ...state,
        updating: true,
      };
      break;
    }
    case "SAVE_APPROVAL_REQUEST_REJECTED":
    case "SAVE_APPROVAL_REQUEST_FULFILLED": {
      return {
        ...state,
        updating: false,
      };
      break;
    }
    case "FETCH_APPROVAL_FEEDBACK_FULFILLED": {
      const { data, nbResult, isCampaignApproved } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
        isCampaignApproved,
      };
      break;
    }
    case "FETCH_APPROVAL_FEEDBACK_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        nbResult: 0,
        error,
      };
      break;
    }
    default:
      return state;
  }
};

const initialStateForApprovalTeamReducer = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  items: [],
  error: null,
  nbResult: 0,
  pageSize: 8,
  paginationPage: 1,
};
export const approvalTeamsReducer = (
  state = initialStateForApprovalTeamReducer,
  action,
) => {
  switch (action.type) {
    case "INIT_APPROVAL_TEAMS": {
      return initialStateForApprovalTeamReducer;
      break;
    }
    case "PUSH_APPROVAL_TEAMS_PENDING":
    case "FETCH_APPROVAL_TEAMS_PENDING": {
      return { ...state, fetching: true };
      break;
    }
    case "FETCH_APPROVAL_TEAMS_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
      break;
    }
    case "FETCH_APPROVAL_TEAMS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        nbResult: 0,
        error,
      };
      break;
    }
    case "PUSH_APPROVAL_TEAMS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      let allItems = state.items;
      allItems.push(...data);
      return {
        ...state,
        items: allItems,
        fetching: false,
        fetched: true,
        nbResult,
      };
      break;
    }
    case "PUSH_APPROVAL_TEAMS_REJECTED": {
      return { ...state, fetching: false, fetched: false };
      break;
    }
    case "SET_APPROVAL_TEAMS_PAGINATION_PAGE": {
      return { ...state, paginationPage: action.pageNumber };
      break;
    }
    case "SAVE_APPROVAL_TEAM_PENDING": {
      return {
        ...state,
        updating: true,
        updated: false,
      };
      break;
    }
    case "SAVE_APPROVAL_TEAM_FULFILLED": {
      const { items } = state;
      let newTeam = action.payload.data.data;

      let exist = false;
      let newItems = items.map((item) => {
        if (item.id === newTeam.id) {
          exist = true;
          return { ...item, ...newTeam };
        }
        return item;
      });

      if (!exist) {
        newItems.unshift(newTeam);
      }

      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: true,
        updating: false,
        updated: true,
      };
      break;
    }
    case "DELETE_APPROVAL_TEAM_FULFILLED": {
      const { deletedId } = action.payload.data;
      let { items, nbResult } = state;
      let newItems = items.filter((item) => item.id != deletedId);

      return {
        ...state,
        items: newItems,
        nbResult: nbResult - 1,
      };
      break;
    }

    case "UPDATE_DEFAULT_APPROVAL_TEAM_PENDING": {
      return {
        ...state,
        updating: true,
        updated: false,
      };
      break;
    }

    case "UPDATE_DEFAULT_APPROVAL_TEAM_FULFILLED": {
      const { items } = state;
      let { id } = action.payload.data.data;

      let newItems = items.map((item) => {
        item.isDefault = 0;
        if (item.id === id) {
          return { ...item, isDefault: 1 };
        }
        return item;
      });

      return {
        ...state,
        items: newItems,
        updating: false,
        updated: true,
      };
    }

    default:
      return state;
  }
};
