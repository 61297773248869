import React, { Component } from "react";
import LinkItem from "./LinkItem";

import _ from "i18n";
import LinkFilters from "./LinkFilters";
import { LINK_FILTERS } from "../../../constants";
import { getLinkType } from "../../../services/utils";
import LinkEditor from "./LinkEditor";

export class LinksTracking extends Component {
  constructor(props) {
    super(props);
    this.handleShowMore = this.handleShowMore.bind(this);
    this.state = {
      showItems: 10,
      linkType: "ALL",
      linkEditorData: null,
    };
  }

  setLinkEditorData = (data) => {
    this.setState({ linkEditorData: data });
  };

  handleLinkClick = (e, link) => {
    e.preventDefault();
    this.props.clicked(link);
    const scrolIntoElem = document.getElementById("recipients-details");
    if (scrolIntoElem) {
      scrolIntoElem.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  handleShowMore() {
    this.setState({
      showItems:
        this.state.showItems >= this.props.links.length
          ? this.state.showItems
          : this.state.showItems + 10,
    });
  }

  handleSelectLink = (value) => {
    this.setState({ linkType: value });
    if (value == "ALL") {
      this.setState({ showItems: 10 });
    } else {
      this.handleShowMore();
    }
  };

  render() {
    const { links } = this.props;
    const { showItems, linkType, linkEditorData } = this.state;

    if (!links || links.length == 0) {
      return null;
    }

    return (
      <div className="link-stats stats-page__block row">
        <div className="large-4 columns">
          <h4 className="text-capitalize"> {_("links tracking")} </h4>
        </div>
        <div className="large-8 columns text-right link-filters-container">
          <LinkFilters
            selected={linkType}
            onClick={this.handleSelectLink}
            data={LINK_FILTERS}
            className={"link-filters"}
          />
        </div>
        <div className="large-12 columns">
          <ul>
            {links
              .sort((a, b) => (a.clicksCount < b.clicksCount ? 1 : -1))
              .slice(0, showItems)
              .map((link) => {
                return (
                  (linkType === "ALL" ||
                    getLinkType(link.link, link.clicksCount).includes(
                      linkType
                    )) && (
                    <LinkItem
                      key={link.id}
                      link={link.link}
                      count={link.clicksCount}
                      label={link.label}
                      onLinkClick={(e) => this.handleLinkClick(e, link)}
                      onEditClick={() => this.setLinkEditorData(link)}
                    />
                  )
                );
              })}
          </ul>

          {links.length > showItems && (
            <div className="text-center">
              <span className="see-more" onClick={this.handleShowMore}>
                {_("see more")}
                <i className="icon icon-arrow-down"></i>
              </span>
            </div>
          )}
        </div>
        {linkEditorData && (
          <LinkEditor
            id={linkEditorData.id}
            link={linkEditorData.link}
            onClose={() => this.setLinkEditorData(null)}
            updatedAt={linkEditorData.updatedAt}
            updatedBy={linkEditorData.updator}
          />
        )}
      </div>
    );
  }
}
