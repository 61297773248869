import React, { Component } from "react";
import { connect } from 'react-redux';

import CheckBoxBtn from 'common/CheckBoxBtn';
import { onError, modalStyle, onSuccess, isValidEmail } from 'utils';
import { reSendToRecipient } from 'thunks';
import _ from 'i18n';
import TTPModal from "../../ui/TTPModal";
import { SVG_SEND_ICON } from 'utils';
import { IS_EMAIL_VALID } from "../../../services/svgIcones";

@connect((store) => {
    return {
        updating: store.campaigns.stats.recipients.list.updating,
    };
})
export default class ResendForm extends Component {

    constructor(props) {
        super(props);
        this.handleEmailChange = (e) => this.setState({ email: e.target.value });
        this.toogleUpdateEmail = (e) => this.setState({ updateEmail: !this.state.updateEmail });

        this.state = {
            email: props.recipient ? props.recipient.email : "",
            updateEmail: false,
            warning: null
        };
    }

    processResend() {
        const { onClose, recipient: { id } } = this.props;
        const { email, updateEmail } = this.state;
        if (!isValidEmail(email)) {
            return;
        }

        this.props.dispatch(reSendToRecipient({ id, email, updateEmail })).
            then(res => {
                let { value: { data } } = res;
                let warning = data["warning"] ? data["warning"]['message'] : null;
                this.setState({ warning });
                onSuccess(res);

                if (!warning) {
                    onClose();
                }
            }, err => onError(err));
    }

    render() {
        const { onClose, updating, recipient: { recipientType } } = this.props;
        const { email, updateEmail, warning } = this.state;
        const isValid = isValidEmail(email);

        let customModalStyle = {
            ...modalStyle,
            content: {
                ...modalStyle['content'],
                transition: 'opacity 0.4s ease-out 0s',
                border: 'none',
                boxShadow: '0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)',
                minWidth: '35rem',
                maxWidth: '40%',
                padding: '0 15px 0'
            }
        };

        return <TTPModal isOpen={true} onClose={onClose} modalStyle={customModalStyle}>
            <div id="resend-email-form" className="ttp-alert-dialog" >
                <div className={`row alert-header align-center header-primary`}>
                  <div className="icon-container">
                    {SVG_SEND_ICON}
                  </div>
                  <p className="alert-header__title">{_("Confirmation of sending")}</p>
                </div>
                <div className="alert-message resend-message row">
                  <div className="column">
                    {_('resend the email to')} :
                  </div>
                    <div className="column">
                        <div className="email-field">
                            <input type="text" value={email}
                                onChange={this.handleEmailChange}
                                className={email ? "filled-box" : "empty-box"}
                            />
                            {IS_EMAIL_VALID(isValid)}
                        </div>
                        {"CONTACT" == recipientType && <CheckBoxBtn labelClass="no-margin--left" checked={updateEmail} onChange={this.toogleUpdateEmail} label={_("Add this email to the user and marque it as main.")} />}
                        {warning && <div className="m-s p-s text-center warning">
                            {_('The email has been successfully resend, but it can not be added to the user :')}
                            {_(warning)}
                        </div>}
                    </div>
                </div>

                <div className="column alert-footer">
                    <button onClick={onClose} className="cancel-button button">{_('cancel')}</button>
                    <button onClick={!updating && this.processResend.bind(this)} className="ok-button  button bg-primary">{updating ? _('Processing...') : _('resend')}</button>
                </div>
            </div>
        </TTPModal>
    }
}
