import React from 'react';
import _ from 'i18n';

const Block = props => {
  return (
    <div className={`bloc ${props.cssClass || ""}`}>
      <h3 className="bloc__title">{_(props.title)}</h3>
      <h4 className="bloc__sub-title">{_(props.subTitle)}</h4>
      {props.children}
    </div>
  )
}

export default Block;
