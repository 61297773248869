import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "uuid";

import {
  setRecipientsListFilterName,
  setRecipientsPageSize,
  setRecipientsPaginationPage,
} from "actions";
import _ from "i18n";
import TTPPaginator from "common/list/TTPPaginator";
import SearchBox from "common/filter/SearchBox";
import PageSize from "common/list/PageSize";
import NotFound from "notFound/NotFound";
import "react-input-range/lib/css/index.css";
import {
  LIST_PAGE_SIZES_UA_RECIPIENTS_LIST,
  UA_RECIPIENT_ATTRIBUTE,
} from "../../../../config/Common";
import { setCurrentEmailCampaignData } from "../../../../actions/actions/currentCampaign";
import CheckBoxBtn from "../../../common/CheckBoxBtn";
import { InfoBar } from "../notifications/InfoBar";
import { ucFirst } from "../../../../services/common";
import { fixUserMainEmail } from "../../../../actions/thunks/user";
import {
  onError,
  onSuccess,
  SVG_ALERT_TRIANGLE_ICON,
  SVG_TRASH_ICON,
} from "../../../../services/utils";
import ValidMailSelect from "./ValidMailSelect";
import ValidMailInput from "./ValidMailInput";
import { removeBlacklistedEmail } from "../../../../actions/thunks/campaign";

@connect((store) => {
  return {
    recipients: store.campaigns.recipients,
    commitmentScoreRange: store.currentCampaign.email.commitmentScoreRange,
    searchValue: store.campaigns.recipients.filter.name,
    recipientType: store.currentCampaign.email.recipientType,
    paginationPage: store.campaigns.recipients.filter.paginationPage,
    allowedGroups: store.currentCampaign.email.allowedGroups,
    deniedGroups: store.currentCampaign.email.deniedGroups,
    manualRecipients: store.currentCampaign.email.manualRecipients,
    liteRecipients: store.currentCampaign.email.liteRecipients,
    personalizedRecipients: store.currentCampaign.email.personalizedRecipients,
    eliminatedRecipients: store.currentCampaign.email.eliminatedRecipients,
    pageSize: store.campaigns.recipients.filter.pageSize,
    nbOfRecipients: store.currentCampaign.email.nbOfRecipients,
    totalUaRecipients: store.currentCampaign.email.totalUaRecipients,
  };
})
export default class Recipients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValidEmail: null,
      focusedInput: null,
      inputEmails: {},
      emailErrorMsg: "",
      targetNonBouncedEmails: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      recipientType,
      paginationPage,
      recipientsTab,
      recipients,
      allowedGroups,
      deniedGroups,
      dispatch,
      plan,
      liteRecipients,
      manualRecipients,
      selectedTab,
    } = this.props;

    const hasItems = recipientsTab.some(
      (recipient) => recipient.recipientType === recipientType,
    );
    let itemsResult = [];
    let recipientCounts = 0;

    if (prevProps.recipients?.items !== recipients?.items) {
      const updatedRecipientsTab = hasItems
        ? recipientsTab.map((recipient) => {
            if (recipient.recipientType === recipientType) {
              return {
                ...recipient,
                items: recipients?.items || [],
                paginationPage,
                //count: recipients?.recipientsTotalCount || 0,
                ...(selectedTab !== "BOUNCED"
                  ? {
                      count: recipients?.recipientsTotalCount || 0,
                    }
                  : {
                      bouncedCount: recipients?.recipientsTotalCount || 0,
                    }),
                deniedGroups: recipient.deniedGroups,
                allowedGroups: recipient.allowedGroups,
              };
            }
            return recipient;
          })
        : [
            ...recipientsTab,
            {
              recipientType,
              items: recipients?.items || [],
              paginationPage,
              count: recipients?.recipientsTotalCount || 0,
              ...(selectedTab === "BOUNCED" && {
                bouncedCount: recipients?.recipientsTotalCount || 0,
              }),
              deniedGroups: deniedGroups,
              allowedGroups: allowedGroups,
            },
          ];
      itemsResult = recipients?.items || [];
      recipientCounts = recipients?.recipientsTotalCount || [];
      this.props.handleChangeRecipientTab(updatedRecipientsTab);

      const matchingRecipient = recipientsTab.find(
        (recipient) => recipient.recipientType === recipientType,
      );

      let selectAllRecipientValue = false;
      let selectedPageValue = false;
      if (matchingRecipient) {
        selectAllRecipientValue = matchingRecipient.selectAllRecipient;
        selectedPageValue = matchingRecipient.selectedPage;
      }
      this.props.handleChangeItemsRecipients(
        itemsResult,
        recipientCounts,
        selectAllRecipientValue,
        selectedPageValue,
      );
    }
    if (recipientType && prevProps.recipientType !== recipientType) {
      const matchingRecipient = recipientsTab.find(
        (recipient) => recipient.recipientType === recipientType,
      );

      if (matchingRecipient) {
        const {
          items,
          count,
          paginationPage,
          liteRecipients,
          personalizedRecipients,
          eliminatedRecipients,
          selectAllRecipient,
          selectedPage,
        } = matchingRecipient;
        itemsResult = items;
        recipientCounts = count;
        dispatch(setRecipientsPaginationPage(paginationPage));
        liteRecipients &&
          dispatch(
            setCurrentEmailCampaignData({
              liteRecipients: liteRecipients,
            }),
          );
        personalizedRecipients &&
          dispatch(
            setCurrentEmailCampaignData({
              personalizedRecipients: personalizedRecipients,
            }),
          );
        eliminatedRecipients &&
          dispatch(
            setCurrentEmailCampaignData({
              eliminatedRecipients: eliminatedRecipients,
            }),
          );
        this.props.handleChangeItemsRecipients(
          itemsResult,
          recipientCounts,
          selectAllRecipient,
          selectedPage,
        );
      } else {
        dispatch(setRecipientsPaginationPage(1));
      }
    }
    if (this.props.recipientsItems !== prevProps.recipientsItems) {
      this.props.updateSelectAllState(this.props.recipientsItems);
    }
    if (recipientsTab !== prevProps.recipientsTab) {
      const isLitePlan = plan === "LITE";
      const isPersonalizedPlan = plan === "PERSONALIZED";
      const isStandardPlan = plan === "STANDARD";

      let totalOfRecipients = isLitePlan
        ? (liteRecipients?.length || 0) + (manualRecipients?.length || 0)
        : isPersonalizedPlan || isStandardPlan
          ? recipientsTab.reduce((count, recipient) => {
              if (recipient && recipient.selectAllRecipient) {
                const eliminatedRecipientCount =
                  recipient.eliminatedRecipients?.[recipient.recipientType]
                    ?.length || 0;
                count = count + (recipient.count - eliminatedRecipientCount);
              } else if (recipient) {
                const personalizedRecipientCount =
                  recipient.personalizedRecipients?.[recipient.recipientType]
                    ?.length || 0;
                count = count + personalizedRecipientCount;
              }
              return count;
            }, 0) + (manualRecipients?.length || 0)
          : 0;
      dispatch(
        setCurrentEmailCampaignData({
          nbOfRecipients: totalOfRecipients,
        }),
      );
    }
  }

  handleSearch = (value) => {
    const { dispatch } = this.props;
    dispatch(setRecipientsListFilterName(value));
  };

  handleRecipientListPageClick(data) {
    const { dispatch } = this.props;
    let selectedPage = data.selected;
    dispatch(setRecipientsPaginationPage(selectedPage + 1));
  }

  handleSelectRecipientListPageSize = (pageSize) => {
    const { dispatch } = this.props;
    dispatch(setRecipientsPageSize(pageSize));
  };

  shouldComponentUpdate(nextProps, nextState) {
    let { recipients, recipientsItems } = nextProps;

    if (recipientsItems?.length !== this.props.recipientsItems?.length) {
      window.dispatchEvent(new Event("resize"));
    }
    // Don't update component during ajax fetch new data process
    let condition1 = !recipients.fetching && recipients.fetched;
    // Result not found
    let condition2 = !recipients.items?.length || !recipients.error;

    return condition1 || condition2;
  }

  getRecipientName(recipient) {
    let { recipientType } = this.props;
    const attributes = UA_RECIPIENT_ATTRIBUTE[recipientType];

    let fullName = `${recipient[attributes.lastName] || ""} ${
      attributes.firstName ? recipient[attributes.firstName] || "" : ""
    }`;

    return fullName || "--";
  }

  getRecipientEmail(recipient) {
    let { recipientType } = this.props;

    const attributes = UA_RECIPIENT_ATTRIBUTE[recipientType];

    return recipient[attributes.email] || recipient["mainEmail"] || "";
  }

  getRecipientOrganizationName(recipient) {
    let { recipientType } = this.props;

    const attributes = UA_RECIPIENT_ATTRIBUTE[recipientType];

    return (
      recipient[attributes.organizationName] || recipient["folderName"] || ""
    );
  }

  getRecipientGroups(recipient) {
    let { recipientType } = this.props;
    const attributes = UA_RECIPIENT_ATTRIBUTE[recipientType];

    if (
      recipient[attributes.groups] &&
      Array.isArray(recipient[attributes.groups])
    ) {
      const groupNames = recipient[attributes.groups].map(
        (group) => group.name,
      );
      return groupNames.join(", ");
    }

    return "";
  }

  updateSelectAllState = (targets) => {
    const { liteRecipients } = this.props;

    const recipientEmails = targets.map((recipient) => recipient.mainEmail);
    const isAllSelected = recipientEmails.every((email) =>
      liteRecipients.some((recipient) => recipient.email === email),
    );

    return isAllSelected;
  };

  handleValidEmailChange = (selectedOption, contactData) => {
    const { recipientsItems } = this.props;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const contactId = contactData["1"];
    const email = selectedOption.value;

    const targets = recipientsItems?.map((recipient) =>
      recipient.user && typeof recipient.user === "object"
        ? recipient.user
        : recipient,
    );

    const target = targets.find((target) => target["1"] === contactId);
    const targetEmails = target?.nonBouncedEmails || [];

    const isEmailValid = emailPattern.test(email);
    const isEmailNew = !targetEmails.includes(email);

    if (isEmailValid && isEmailNew) {
      targetEmails.push(email);
      this.setState({ targetNonBouncedEmails: targetEmails });
    }

    this.setState({
      emailErrorMsg: isEmailValid ? "" : _("mail_address_invalid"),
      selectedValidEmail: email,
      focusedInput: contactId,
    });
  };

  handleEmailInputChange = (e, contactData) => {
    const { value } = e.target;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    this.setState((prevState) => ({
      inputEmails: {
        ...prevState.inputEmails,
        [contactData["1"]]: value,
      },
      focusedInput: contactData["1"],
      emailErrorMsg: emailPattern.test(value) ? "" : _("mail_address_invalid"),
    }));
  };

  handleFixContactEmail = (contactData) => {
    const { dispatch, updateSelectedTab } = this.props;
    const { selectedValidEmail, inputEmails } = this.state;

    let newEmail = inputEmails[contactData["1"]] || "";
    let email = selectedValidEmail || contactData?.nonBouncedEmails[0] || "";

    dispatch(
      fixUserMainEmail(
        contactData["1"],
        newEmail || email,
        contactData.mainEmail,
      ),
    ).then(
      (res) => {
        onSuccess(res);
        updateSelectedTab(null);
        this.setState({ selectedValidEmail: "" });
      },
      (err) => onError(err),
    );
  };
  handleDeleteBlacklistedEmail = (e) => {
    const { dispatch, handleSetIsFilterChanged } = this.props;

    dispatch(removeBlacklistedEmail(e.mainEmail)).then(
      (res) => {
        onSuccess(res);
        handleSetIsFilterChanged();
      },
      (err) => {
        onError(err);
      },
    );
  };

  render() {
    const {
      recipients,
      recipientType,
      searchValue,
      liteRecipients,
      selectAll,
      recipientsItems,
      count,
      selectedPage,
      plan,
      selectAllRecipient,
      personalizedRecipients,
      eliminatedRecipients,
      pageSize,
      selectedTab,
      nbOfRecipients,
      totalUaRecipients,
      recipientsTab,
    } = this.props;
    const {
      selectedValidEmail,
      focusedInput,
      emailErrorMsg,
      inputEmails,
      targetNonBouncedEmails,
    } = this.state;

    if (!recipientType || recipientType === "MANUAL") {
      return null;
    }
    let notfoundBlock = <NotFound />;

    let targets = recipientsItems?.map((recipient) =>
      recipient.user && typeof recipient.user == "object"
        ? recipient.user
        : recipient,
    );

    const isLitePlan = plan === "LITE";
    const isPersonalizedPlan = plan === "PERSONALIZED";
    const isStandardPlan = plan === "STANDARD";
    const total =
      (totalUaRecipients && totalUaRecipients[recipientType]?.total) || 0;

    const bouncedCount = recipientsTab.find(
      (r) => r.recipientType === recipientType,
    )?.bouncedCount;

    return (
      <div className="row">
        <div className="columns small-12">
          <div className="tab-label">
            Groupe <span>{recipientType.toLowerCase().replace(/_/g, " ")}</span>
          </div>
        </div>
        <div className="small-12 columns list-filter__bar">
          {/* <span className="tab-label">
            {_("result_number")} :
            <span className="required">{count ? count : 0}</span>
          </span>
          {(isPersonalizedPlan || isStandardPlan) && (
            <div className="check_select">
              <CheckBoxBtn
                checked={selectAllRecipient}
                onChange={() => {
                  this.props.handleChangSelectAllRecipient();
                }}
                id="select-all"
                disabled={selectedTab === "BOUNCED"}
              />
              <span>
                {!selectAllRecipient ? _("selectAll") : _("deselect")}
              </span>
            </div>
          )} */}
          <div className="filter-wrapper__tabs">
            {[
              {
                label: _("valid_emails"),
                value: null,
                color: "#18A0FB",
              },
              {
                label: _("Invalid emails"),
                value: "BOUNCED",
                color: "#e6140a",
              },
            ].map(({ value, label, color }) => (
              <span
                key={"tab--" + value}
                className={`${selectedTab === value ? "active" : ""}`}
                style={color ? { borderColor: color } : {}}
                onClick={() => {
                  this.props.updateSelectedTab(value);
                }}
              >
                {_(label)}{" "}
                {value === null
                  ? "(" + total + ")"
                  : bouncedCount
                    ? "(" + bouncedCount + ")"
                    : ""}
              </span>
            ))}
          </div>

          <div className="search-filter">
            <SearchBox value={searchValue} onChange={this.handleSearch} />
            <PageSize
              pageSize={recipients.filter.pageSize}
              onChange={this.handleSelectRecipientListPageSize.bind(this)}
              listPageSize={LIST_PAGE_SIZES_UA_RECIPIENTS_LIST}
            />
          </div>
        </div>
        <div className="columns small-12">
          {(isPersonalizedPlan || isStandardPlan) && (
            <div className="check_select">
              <CheckBoxBtn
                checked={selectAllRecipient}
                onChange={() => {
                  this.props.handleChangSelectAllRecipient();
                }}
                id="select-all"
                disabled={selectedTab === "BOUNCED"}
              />
              <span>
                {!selectAllRecipient ? _("selectAll") : _("deselect")}
              </span>
            </div>
          )}
        </div>
        {(selectedPage || selectAllRecipient) && count > 20 && (
          <InfoBar
            message={
              _("the") +
              " " +
              count +
              " " +
              _("desc_selection") +
              " " +
              ucFirst(_(recipientType)).replace(/_/g, " ") +
              " " +
              _("its_selected")
            }
            description={
              !selectedPage
                ? _("desc_selection_1") +
                  " " +
                  pageSize +
                  " " +
                  _("desc_selection_2")
                : _("clear_selection")
            }
            changePersonalizedRecipients={async () => {
              if (!selectedPage) {
                await this.props.changePersonalizedRecipients(targets, true);
              } else {
                await this.props.clearSelection();
              }
              this.props.updateRecipientsTab();
            }}
          />
        )}

        {!recipientsItems || recipientsItems?.length === 0 ? (
          notfoundBlock
        ) : (
          <div className="row small-12">
            <div className="columns small-12">
              <div className="ttp-datatable p-l-xs">
                <div className="row ttp-datatable__header">
                  <div
                    className={
                      recipientType === "CONTACT_CLIENT" ||
                      recipientType === "CONTACT" ||
                      selectedTab === "BOUNCED"
                        ? "small-3"
                        : "small-5"
                    }
                    style={{ display: "flex" }}
                  >
                    {isLitePlan && selectedTab !== "BOUNCED" && (
                      <CheckBoxBtn
                        checked={selectAll}
                        onChange={() => {
                          this.props.changeLiteRecipients(targets, true);
                        }}
                        id="select-all"
                      />
                    )}

                    {_("name")}
                  </div>
                  <div
                    className={
                      recipientType === "CONTACT_CLIENT" ||
                      recipientType === "CONTACT" ||
                      selectedTab === "BOUNCED"
                        ? "small-3"
                        : "small-5"
                    }
                  >
                    {selectedTab !== "BOUNCED"
                      ? _("email")
                      : _("Invalid emails")}
                  </div>
                  {recipientType === "CONTACT_CLIENT" &&
                    selectedTab !== "BOUNCED" && (
                      <div className="small-3">{_("organization")}</div>
                    )}
                  {(recipientType === "CONTACT" ||
                    recipientType === "CONTACT_CLIENT") &&
                    selectedTab !== "BOUNCED" && (
                      <div
                        className={
                          recipientType === "CONTACT_CLIENT"
                            ? "small-2"
                            : "small-3"
                        }
                      >
                        {_("groups")}
                      </div>
                    )}
                  {selectedTab === "BOUNCED" && (
                    <div className="small-3">{_("Replace with")}</div>
                  )}
                </div>
                {targets?.map((target, i) => {
                  const recipientExists = isLitePlan
                    ? liteRecipients.some(
                        (recipient) =>
                          recipient.email === this.getRecipientEmail(target),
                      )
                    : (isPersonalizedPlan || isStandardPlan) &&
                      (personalizedRecipients[recipientType] &&
                      personalizedRecipients[recipientType]?.length > 0
                        ? personalizedRecipients[recipientType]
                            .filter(
                              (recipient) =>
                                !eliminatedRecipients[recipientType]?.includes(
                                  recipient,
                                ),
                            )
                            .some(
                              (recipient) =>
                                recipient.email ===
                                this.getRecipientEmail(target),
                            )
                        : eliminatedRecipients[recipientType] &&
                            eliminatedRecipients[recipientType]?.length > 0
                          ? !eliminatedRecipients[recipientType].some(
                              (recipient) =>
                                recipient.email ===
                                this.getRecipientEmail(target),
                            )
                          : selectAllRecipient);

                  const emailOptions =
                    targetNonBouncedEmails.length > 0 &&
                    target["1"] === focusedInput
                      ? targetNonBouncedEmails.map((email) => ({
                          label: email,
                          value: email,
                        }))
                      : target["nonBouncedEmails"]?.map((email) => ({
                          label: email,
                          value: email,
                        }));

                  return (
                    <div
                      key={uuid()}
                      className={`row ttp-datatable__row recipient-item ${recipientExists ? "recipient-item_selected" : ""}`}
                    >
                      <div
                        className={`recipient-name ${
                          recipientType === "CONTACT_CLIENT" ||
                          recipientType === "CONTACT" ||
                          selectedTab === "BOUNCED"
                            ? "small-3"
                            : "small-5"
                        }`}
                        onClick={async () => {
                          if (isLitePlan) {
                            await this.props.changeLiteRecipients(target);
                          } else {
                            await this.props.changePersonalizedRecipients(
                              target,
                            );
                          }
                          this.props.updateRecipientsTab();
                        }}
                      >
                        {selectedTab !== "BOUNCED" && (
                          <CheckBoxBtn
                            checked={recipientExists}
                            onChange={async () => {
                              if (isLitePlan) {
                                await this.props.changeLiteRecipients(target);
                              } else {
                                await this.props.changePersonalizedRecipients(
                                  target,
                                );
                              }
                              this.props.updateRecipientsTab();
                            }}
                            id={i}
                          />
                        )}
                        {this.getRecipientName(target)}
                      </div>
                      <div
                        className={
                          selectedTab === "BOUNCED"
                            ? "small-3 email-target bounced"
                            : recipientType === "CONTACT_CLIENT" ||
                                recipientType === "CONTACT"
                              ? "small-3 email-target "
                              : "small-5 email-target"
                        }
                      >
                        {selectedTab === "BOUNCED" && SVG_ALERT_TRIANGLE_ICON}
                        <span className="email-text">
                          {this.getRecipientEmail(target)}
                        </span>
                      </div>
                      {recipientType === "CONTACT_CLIENT" &&
                        selectedTab !== "BOUNCED" && (
                          <div className="small-3">
                            {this.getRecipientOrganizationName(target)}
                          </div>
                        )}
                      {(recipientType === "CONTACT_CLIENT" ||
                        recipientType === "CONTACT") &&
                        selectedTab !== "BOUNCED" && (
                          <div
                            className={
                              recipientType === "CONTACT_CLIENT"
                                ? "small-2 groups-name"
                                : "small-3 groups-name"
                            }
                          >
                            {this.getRecipientGroups(target)}
                          </div>
                        )}
                      {selectedTab === "BOUNCED" && (
                        <div
                          className="small-4"
                          style={{
                            display: "flex",
                          }}
                        >
                          <div className="valid-email">
                            {target["nonBouncedEmails"]?.length > 0 ? (
                              <div className="valid-email-container">
                                <div className="email-select">
                                  <ValidMailSelect
                                    target={target}
                                    options={emailOptions}
                                    selectedOption={
                                      selectedValidEmail &&
                                      focusedInput === target["1"]
                                        ? {
                                            label: selectedValidEmail,
                                            value: selectedValidEmail,
                                          }
                                        : target["nonBouncedEmails"]?.[0]
                                          ? {
                                              label:
                                                target["nonBouncedEmails"]?.[0],
                                              value:
                                                target["nonBouncedEmails"]?.[0],
                                            }
                                          : null
                                    }
                                    onChange={this.handleValidEmailChange}
                                    error={
                                      emailErrorMsg &&
                                      focusedInput === target["1"]
                                    }
                                  />
                                  {emailErrorMsg &&
                                    focusedInput === target["1"] && (
                                      <span className="email-error-msg">
                                        {emailErrorMsg}
                                      </span>
                                    )}
                                </div>
                                <button
                                  className="columns btn success fix-btn"
                                  style={{
                                    marginTop: "4px",
                                  }}
                                  onClick={() =>
                                    this.handleFixContactEmail(target)
                                  }
                                  disabled={
                                    emailErrorMsg &&
                                    focusedInput === target["1"]
                                  }
                                >
                                  {_("Fix")}
                                </button>
                              </div>
                            ) : (
                              <ValidMailInput
                                target={target}
                                emailValue={inputEmails[target["1"]]}
                                onInputChange={this.handleEmailInputChange}
                                onButtonClick={this.handleFixContactEmail}
                                isFocused={focusedInput === target["1"]}
                                errorMsg={
                                  focusedInput === target["1"] &&
                                  inputEmails[target["1"]]
                                    ? emailErrorMsg
                                    : ""
                                }
                              />
                            )}
                          </div>
                          <button
                            className="delete-btn"
                            onClick={() =>
                              this.handleDeleteBlacklistedEmail(target)
                            }
                          >
                            {SVG_TRASH_ICON}
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="small-12">
              <TTPPaginator
                onPageChange={this.handleRecipientListPageClick.bind(this)}
                pageSize={recipients.filter.pageSize}
                nbResult={count}
                paginationPage={recipients.filter.paginationPage}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
