import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import _ from 'i18n';
import Bubble from 'common/Bubble';
import {
  SVG_ENVELOPE_ICON,
  SVG_SEND_ICON,
  SVG_SETTINGS_ICON,
  SVG_USER_ICON
} from "../../../services/svgIcones";


class Bubbles extends Component {

  render() {
    let { onOpenSideBar, sendingDisabled, showReviewBubble, currentSideBar } = this.props;
    return (
      <div className={`campaign-bubbles ${currentSideBar && "bubbles-sticky"}`}>
        <Bubble onBubbleClick={onOpenSideBar.bind(this, 'PARAMS')}
          label="settings" svgIcon={SVG_SETTINGS_ICON}
          isActive={'PARAMS' == currentSideBar} />
        <Bubble onBubbleClick={onOpenSideBar.bind(this, 'RECIPIENTS')}
          label="recipients" svgIcon={SVG_USER_ICON}
          isActive={'RECIPIENTS' == currentSideBar} />
        {!sendingDisabled &&
          <Bubble onBubbleClick={onOpenSideBar.bind(this, 'TEST')} label="test"
            svgIcon={SVG_ENVELOPE_ICON}
            isActive={'TEST' == currentSideBar} />
        }
        {!sendingDisabled && showReviewBubble &&
          <Bubble onBubbleClick={onOpenSideBar.bind(this, 'REVIEW')}
            label="reviews" icon="icon-bubbles"
            isActive={'REVIEW' == currentSideBar} />
        }
        {!sendingDisabled &&
          <Bubble onBubbleClick={onOpenSideBar.bind(this, 'SEND')} label="send"
            svgIcon={SVG_SEND_ICON} isActive={'SEND' == currentSideBar}
            cssClass="success send" />
        }
      </div>
    );
  }
}

const mapStateToProps = store => ({
  showReviewBubble: store.currentCampaign.email.lastTestversion > 0,
  currentSideBar: store.currentCampaign.email.currentSideBar,
  campaignId: store.currentCampaign.email.id,
})
export default compose(
  connect(mapStateToProps),
  withRouter
)
  (Bubbles);
