import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'i18n';
import {SVG_UP_ICON, SVG_VECTOR_ICON} from "../../services/svgIcones";
import MailingListActions from "./MailingListActions";
import {setMailingListData} from "../../actions/actions/mailingList";

@connect()
export default class MailingListItem extends Component {

  constructor(props) {
    super(props);

    this.toggleMoreDescription = () => this.setState(prevState => ({ showMore: !prevState.showMore }));
    this.state = {
      showMore: false
    }
  }

  render() {
    const {name, description, mandatory, subscribersCount, id, nameFr, nameEn, nameNl, descriptionFr, descriptionEn, descriptionNl, closeListForm, handleChangeIcon} = this.props;
    let data = {nameFr, nameEn, nameNl, descriptionFr, descriptionEn, descriptionNl, mandatory, id};
    const { showMore } = this.state;

    let colorClassCss = `${mandatory ? 'mandatory' : 'optional'}`;

    return (
      <div onMouseOver={() => handleChangeIcon("EDIT")}
           onMouseOut={() => handleChangeIcon("ADD")}
           className={`mailing-lists__item ${colorClassCss} ${showMore && 'expanded'}`}>
        <div className="medium-12 p-l-xs p-r-xs mailing-lists__item-header">
          <div className='icon'>{SVG_VECTOR_ICON}</div>
          <span>{subscribersCount ?? 0}</span>
        </div>
        <div className="mailing-lists__item-container">
          <p className="mailing-lists__item-name"> {name} </p>
          <p
            className="mailing-lists__item-mandatory"> {mandatory ? _('mandatory') : _('optional')} </p>
          <p className="mailing-lists__item-description"
             style={!showMore ? {overflow: "hidden", height: "3rem"} : {}}>
            { description &&
            (!showMore && description.length >= 110) ? description.slice(0, 110) + "..." : description }
            {!description && <span className="fwl">{_("There is no description for this mailing list !")}</span>}
          </p>
          {description && description.length >= 110 &&
          <div className="read-more" onClick={this.toggleMoreDescription}>
            {_((showMore) ? 'close' : 'see more')}
            <span className={`${!showMore && 'down'}`}>{SVG_UP_ICON}</span>
          </div>
          }
        </div>
        <MailingListActions data={data} closeListForm={closeListForm}/>
      </div>
    )
  }
}
