import { S3_FOLDER_URL_PROD, TTP_HOME_URL, TTP_EMAILING_URL } from "../config";

export const getMenuLogo = (application, isLoggedOut = false) => {
  let app = {
    appName: "Emailing",
    appLogoUrl: `${S3_FOLDER_URL_PROD}/apps/emailing.png`,
    appUrl: isLoggedOut ? TTP_EMAILING_URL : "/",
    homeUrl: TTP_HOME_URL,
  };
  switch (application) {
    case "EBOX":
      app = {
        appName: "E-Box",
        appLogoUrl: `${S3_FOLDER_URL_PROD}/apps/ebox.png`,
        appUrl: "/e_box",
        homeUrl: TTP_HOME_URL,
      };
      break;
    case "SMS":
      app = {
        appName: "SMS",
        appLogoUrl: `${S3_FOLDER_URL_PROD}/apps/sms.png`,
        appUrl: "/sms",
        homeUrl: TTP_HOME_URL,
      };
      break;
    case "NOTIFICATION":
      app = {
        appName: "Notification",
        appLogoUrl: `${S3_FOLDER_URL_PROD}/apps/notification.png`,
        appUrl: "/notification",
        homeUrl: TTP_HOME_URL,
      };
      break;
  }
  return app;
};
