import React from 'react';
import __ from 'lodash';
import { Redirect, Route } from 'react-router-dom';
import { TTP_HOME_URL } from 'Config';

const PrivateRoute = ({
  auth,
  path,
  roles,
  privilege,
  component,
  exact,
  renderPropsToComponent,
}) => {
  const ComponentToRender = component;

  const render = (props) => {
    const componentProps = (renderPropsToComponent && typeof renderPropsToComponent === 'function') ? renderPropsToComponent(props) : props;

    /** Login required */
    if (!auth || !auth.fetched || !auth.user) {
      location.href = `${TTP_HOME_URL.replace(/\/+$/, '')}?goto=${location.href}`;
      return;
    }

    const loggedAs = auth.loggedAs;

    /** Needs only a connected user */
    if (!roles || __.isEmpty(roles)) {
      return <ComponentToRender {...componentProps} />;
    }

    const ttpRole = `TTP_${auth.user.type || ""}`.toUpperCase();

    /** Route with specific role  */
    if (
      (Array.isArray(roles) && roles.some(role => role === loggedAs || role === ttpRole))
      || roles === loggedAs || roles === ttpRole
      || (privilege && auth.preference && auth.preference[privilege] == 1)
    ) {
      return <ComponentToRender {...componentProps} />;
    }

    return <Redirect to={{ pathname: '/unauthorized', state: { from: props.location } }} />;
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={render}
    />
  );
};

export default PrivateRoute;
