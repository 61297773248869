import React, { PureComponent } from "react";
import _ from "i18n";
import { SVG_UP_ICON } from "../../../services/svgIcones";

class StyledTabs extends PureComponent {

  state = {
    prevVisible: false,
    nextVisible: true
  }

  updateControlsVisibility = (number = 0) => {
    const tabRef = this.refs.tabItems;
    if (tabRef) {
      const newLeftPosition = tabRef.scrollLeft + number;
      let maxScrollLeft = tabRef.scrollWidth - tabRef.clientWidth;

      this.setState({
        nextVisible: maxScrollLeft > newLeftPosition,
        prevVisible: newLeftPosition > 0
      });
    }
  }

  swipItems = (number) => {
    const tabRef = this.refs.tabItems;
    if (tabRef) {
      const newLeftPosition = tabRef.scrollLeft + number;

      this.updateControlsVisibility(number);

      tabRef.scrollLeft = newLeftPosition;
    }
  }

  componentDidMount() {
    this.updateControlsVisibility();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.tabs != this.props.tabs) {
      this.updateControlsVisibility();
    }
  }

  render() {
    const { children, active, tabs, setActiveTab, light } = this.props;
    const { prevVisible, nextVisible } = this.state;

    let ctrlCount = 0;
    if (prevVisible) {
      ctrlCount = 1;
    }
    if (nextVisible) {
      ctrlCount++;
    }

    return (
      <div className={`styled-tabs ${light ? 'light' : ""}`}>
        <div className="styled-tabs__header">
          {prevVisible && <span className="control previous" onClick={() => this.swipItems(-STEP)}>
            {SVG_UP_ICON}
          </span>}
          <div ref="tabItems" className={`styled-tabs__header-items ${ctrlCount && "control-" + ctrlCount}`}>
            {
              tabs.map(({ key, title }) => (
                <a key={key} onClick={() => setActiveTab(key)}
                  className={`tab ${key === active ? "active" : ""}`}>
                  {_(title)}
                </a>
              )
              )
            }
          </div>
          {nextVisible && <span className="control next" onClick={() => this.swipItems(STEP)}>
            {SVG_UP_ICON}
          </span>}
        </div>
        {children && tabs && tabs.length > 0 && <div className="styled-tabs__content">
          {children}
        </div>}
      </div>
    )
  }
}

export default StyledTabs;

const STEP = 80;
