import React, {Component} from "react";
import ReactModal from "react-modal";
import {getModalStyle, modalStyle} from "../../services/utils";
import {SVG_ICONS} from "../../config/Common";
import cheerio from 'cheerio';

export default class Viewer extends Component {

  constructor(props) {
    super(props);
    this.modalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle.content,
        top: "5%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        padding: 0,
        border: "none",
        background: "rgb(255, 255, 255)",
        borderRadius: "5px",
        outline: "none",
        width: "auto",
        transform: "initial",
        overflow: "visible"
      }
    }
  }

  render() {
    const {
      isOpen,
      preview,
      url,
      children,
      onClose,
      loading,
      viewerStyle,
      rightCell,
      isSmsEbox,
      message
    } = this.props;
    let attr = {};
    if (preview) {
      const $ = cheerio.load(preview.replace(/target=('|")_self('|")/g, ""));
      $("head").append("<base target='_blank'>");
      attr = {srcDoc: $.html()};
    } else {
      attr = {src: url};
    }
    const right = rightCell || 8;
    const left = isSmsEbox ? 6 : 12 - right;
    if (left === 6) {
      attr["style"] = {
        width: "153.7%",
        height: "153%",
        transform: "scale(0.65)",
        transformOrigin: "0 0",
      }
    }

    let customModalStyle = !isSmsEbox ? this.modalStyle : {
      ...this.modalStyle,
      content: {
        ...this.modalStyle['content'],
        inset: "5% 22%"
      }
    }

    return <ReactModal
      contentLabel="Modal"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      style={getModalStyle(customModalStyle, viewerStyle)}>
      <div className="viewer row">
        <div className="viewer__close" onClick={onClose}>
          <img src={"/img/icons/" + SVG_ICONS["CLOSE"]}/>
        </div>
        {!isSmsEbox ? <div
          className={`viewer__container small-12 ${children ? `medium-${right}` : ""}`}>
          {url && <div className="viewer__container__goto">
            <input value={url} readOnly={true}/>
            <a href={url} target="_blank">
              <img src={"/img/icons/" + SVG_ICONS["OPEN_LINK"]}/>
            </a>
          </div>}
          <div
            className={"viewer__container__content" + (loading || !preview ? " gradiant" : "")}>
            <iframe {...attr} />
          </div>
        </div> : <div className="viewer__container__content">
          <div id="theme-preview">
            <div className="container-smartphone">
              <div className="columns phone-container">
                <div className="phone-text">{loading ? "" : message}</div>
              </div>
            </div>
          </div>
        </div>
        }
        {children && <div className={`viewer__aside small-12 medium-${left}`}>
          {children}
        </div>}
      </div>
    </ReactModal>
  }
}
