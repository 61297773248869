import React from "react";

export const Copyright = () => {
    return <div className="copyright">
        <p>
            © Copyright -
            {(new Date()).getFullYear()}
            {' '}
            <strong>Tamtam International</strong>
        </p>
    </div>
};
