import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'
import {
  setStep,
  setRecipientsStatsFilterStatus,
  setRecipientsStatsPageSize,
  setRecipientsStatsPaginationPage,
  clearSourcesTokens, initCampaignStats
} from 'actions';
import { CAMPAIGN_LANGUAGE, SMS_RECIPIENTS_STATUS } from 'Common';
import _ from 'i18n';
import {
  fetchSMSCampaign,
  fetchSMSCampaignStats,
  fetchSMSRecipientsStats,
  updateCampaignSmsStatus,
} from 'thunks';
import { onError, onSuccess, getRate } from 'utils';
import SMSRecipientsStats from './datatable/SMSRecipientsStats';
import PieChart from './../../stats/PieChart';
import { setTTPDialogCustomData } from "actions";
import Parser from 'html-react-parser';
import ProgressBar from './../../common/ProgressBar';
import DIALOG_TYPES from '../../../constants/TTPDialog';
import Actions from 'common/Actions';
import SummaryFetching from '../../fetching/stats/SummaryFetching';


@connect((store) => {
  return {
    stats: store.campaigns.stats.campaign,
    campaigns: store.campaigns.list.items,
    fetching: store.campaigns.list.fetching,
    _requests: store.params.sourcesTokens,
  };
})
export default class CampaignSMSStats extends Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.refreshStatsTimerId = null;
  }

  getDefaultState() {
    return {
      campaignName: "",
      originator: "",
      smsMessage: "",
      status: null,
      sentAt: '',
      recipientType: '',
      language: '',
      selectedStatus: null,
      totalSmsRecipients: ""
    };
  }

  componentDidMount() {
    const { dispatch, match: { params: routeParams }, _requests } = this.props;
    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    if (routeParams.campaignSMSId) {
      this.refreshStats();
      dispatch(fetchSMSCampaign(routeParams.campaignSMSId));
    }
    this.renderRecipientStatsDatatable(1, 10);
    dispatch(setStep('HIDE_MENU'));
  }

  componentWillReceiveProps(nextProps) {
    const { match: { params: routeParams }, campaigns, stats } = nextProps;

    if (routeParams.campaignSMSId && campaigns && campaigns.length &&
      routeParams.campaignSMSId == campaigns[0].id) {
      let campaign = campaigns[0];
      let status = this.state.status;
      if (!status || campaign.status != this.props.campaigns[0].status) {
        status = campaign.status;
      } else if (stats.data.campaignStatus !=
        this.props.stats.data.campaignStatus) {
        status = stats.data.campaignStatus;
      }
      this.setState({
        smsMessage: campaign.message,
        campaignName: campaign.name,
        status,
        sentAt: campaign.sentAt,
        originator: campaign.originator,
        recipientType: campaign.recipientType,
        language: campaign.language,
        totalSmsRecipients: campaign.totalSmsRecipients
      });
    } else {
      this.setState(this.getDefaultState());
    }
  }

  refreshStats() {
    const { dispatch, match: { params: routeParams } } = this.props;
    const { status } = this.state;
    if (routeParams.campaignSMSId) {
      dispatch(fetchSMSCampaignStats(routeParams.campaignSMSId));
    }
    const TIME_OUT = (status == 'SENT') ? 40000 : 10000;

    this.refreshStatsTimerId = setTimeout(() => this.refreshStats(), TIME_OUT);
  }

  componentWillUnmount() {
    if (this.refreshStatsTimerId !== null) {
      clearTimeout(this.refreshStatsTimerId);
    }
    const { _requests } = this.props;

    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    this.props.dispatch(setStep(null));
    this.props.dispatch(clearSourcesTokens());
    this.props.dispatch(initCampaignStats());
  }

  renderRecipientStatsDatatable(paginationPage, pageSize = null) {
    const { dispatch, match: { params: routeParams } } = this.props;
    dispatch(setRecipientsStatsPaginationPage(paginationPage));
    if (pageSize) {
      dispatch(setRecipientsStatsPageSize(pageSize));
    }
    dispatch(fetchSMSRecipientsStats(routeParams.campaignSMSId));
  };

  handleClickStatus(status) {
    const { dispatch } = this.props;
    dispatch(setRecipientsStatsFilterStatus(status));
    this.setState({ selectedStatus: status });
    this.renderRecipientStatsDatatable(1);
  };

  handleSMSCampaignAction(status) {
    const { dispatch, match: { params: routeParams } } = this.props;
    const id = routeParams.campaignSMSId;
    dispatch(updateCampaignSmsStatus({ id, status })).
      then(res => onSuccess(res), err => onError(err));
  }

  handleOpenStopDialog() {
    const { dispatch } = this.props;
    dispatch(setTTPDialogCustomData({
      'approvedAction': this.handleSMSCampaignAction.bind(this, 'HALT'),
      'message': _('stopCampaignConfirm'),
      'proceedBtnLabel': _('stop'),
      'type': DIALOG_TYPES["ALERT"],
      'title': "Halt confirmation",
    }));
  }

  renderPauseOrPlayLink() {
    const { status } = this.state;
    if (['RECIPIENT_LIST_CREATED', 'CREATED'].indexOf(status) > -1) {
      return (<li>
        <span onClick={() => this.handleSMSCampaignAction('PAUSE')} className="sidebar__icon">
          <i className="icon-control-pause"></i>
        </span>
        <span className="sidebar__item-hover"> {_('pauseSendingSMS')}</span>
      </li>);
    } else if (status == 'PAUSE') {
      return (<span>
        <li>
          <span onClick={() => this.handleSMSCampaignAction('UNPAUSE')} className="sidebar__icon">
            <i className="icon-control-play"></i>
          </span>
          <span className="sidebar__item-hover"> {_('resume')}</span>
        </li>
        <li>
          <span onClick={this.handleOpenStopDialog.bind(this)} className="sidebar__icon">
            <i className="icomoon icon-stop warning"></i>
          </span>
          <span className="sidebar__item-hover"> {_('stopCampaign')} </span>
        </li>
      </span>
      );
    }
    return null;
  }

  renderSmsLinks() {
    const { data } = this.props.stats;
    if (!data.smsLinks) {
      return '';
    }
    return data.smsLinks.map((smsLink) => {
      return (<li>
        <a href="#" onClick={(event) => this.handleClickStatsLink(event,
          smsLink.id)}>{smsLink.smsLink}</a>
        <span className="count">{smsLink.smsLinkClicksCount}</span>
      </li>);
    });
  }

  render() {
    const { sentCount, deliveredCount, unsentCount, inProgressCount, bufferedCount, totalTargetCount, clickCount, smsLinks } = this.props.stats.data;
    let { smsMessage, campaignName, sentAt, status, recipientType, language, selectedStatus, totalSmsRecipients } = this.state;
    const { fetching } = this.props;
    let processedCount = parseInt(unsentCount) + parseInt(deliveredCount);

    let processedRate = getRate(processedCount, totalSmsRecipients);

    return (
      <div id="stats-page">
        <Actions
          closeForm={() => this.props.history.push('/smsCampaigns')}
          title={_('The campaign report')}
        />
        <ul id="sidebar">
          {this.renderPauseOrPlayLink()}
        </ul>
        <ReactTooltip id="info-tooltip" type='dark' effect="float" multiline={true} place="bottom" getContent={(dataTip) => { return Parser(_('buffered')) }} />

        <div className="stats-page">
          <div className="header-details">
            <h1> {smsMessage} </h1>
            {status != "SENT" && <ProgressBar processedRate={processedRate} />}
          </div>

          <div className="flex-container align-justify">
            <div className="w-40 stats-page__block">
              {(fetching) ? <SummaryFetching /> : <ul className="summary">
                <li><div>{_('status')}:</div> <div>{status == 'RECIPIENT_LIST_CREATED' ? 'READY' : _(status)}</div></li>
                <li><div>{_('campaignName')}:</div> <div>{campaignName}</div></li>
                <li><div>{_('Sent at')}:</div> <div>{sentAt}</div></li>
                <li><div>{_('to')}:</div> <div>{_(totalSmsRecipients ? totalSmsRecipients : 0)}<span>{recipientType == "MANUAL" ? "" : _(recipientType)}</span></div></li>
                <li><div>{_('language')}:</div> <div className="text-uppercase">{_(CAMPAIGN_LANGUAGE[language])}</div></li>
              </ul>}
            </div>
            <div className="w-58 stats-page__block">
              <div className="row align-center">
                <PieChart
                  label={_('Sent to')}
                  rate={getRate(sentCount, totalTargetCount)}
                  pieColor={SMS_RECIPIENTS_STATUS[0].color}
                  target={sentCount}
                  onStatusClick={() => this.handleClickStatus('SENT_TO')} />
                <PieChart
                  label={_('In progress')}
                  rate={getRate(inProgressCount, totalTargetCount)}
                  pieColor={SMS_RECIPIENTS_STATUS[1].color}
                  target={inProgressCount}
                  onStatusClick={() => this.handleClickStatus('IN_PROGRESS')} />
                <PieChart
                  label={_('Delivered to')}
                  rate={getRate(deliveredCount, totalTargetCount)}
                  pieColor={SMS_RECIPIENTS_STATUS[2].color}
                  target={deliveredCount}
                  onStatusClick={() => this.handleClickStatus('DELIVERED_TO')} />

              </div>
              <div className="row align-center">
                <PieChart
                  label={_('unsent')}
                  rate={getRate(unsentCount, totalTargetCount)}
                  pieColor={SMS_RECIPIENTS_STATUS[3].color}
                  target={unsentCount}
                  onStatusClick={() => this.handleClickStatus('UNSENT')} />
                <PieChart
                  label={_('linkClicks')}
                  rate={getRate(clickCount, totalTargetCount)}
                  pieColor={SMS_RECIPIENTS_STATUS[4].color}
                  target={clickCount}
                  onStatusClick={() => this.handleClickStatus('SMS_LINK_CLICKS')} />
                <PieChart
                  label={_('Buffered')}
                  extraHtml={`<span className="piechart-info" data-html={true} data-for="info-tooltip" data-tip=""><i className="icon-info"></i></span>`}
                  rate={getRate(bufferedCount, totalTargetCount)}
                  pieColor={SMS_RECIPIENTS_STATUS[5].color}
                  target={bufferedCount}
                  onStatusClick={() => this.handleClickStatus('BUFFERED')} />
              </div>
            </div>
          </div>
          {smsLinks && smsLinks.length > 0 &&
            <div className="sms-header">
              <div className="row">
                <h4 className="stats-label"> {_('links')} </h4>
              </div>
              <div className="row link-stats">
                <div className="large-8 columns">
                  <ul>{this.renderSmsLinks()}</ul>
                </div>
              </div>
            </div>
          }
          <SMSRecipientsStats selectedStatus={selectedStatus} renderRecipientStatsDatatable={this.renderRecipientStatsDatatable.bind(this)} />
        </div>
      </div>
    );
  }
}
