import React, { Component } from "react";
import _ from "i18n";
import {getDate} from "../../../services/date";
import {getStatusColorClass} from "../../../services/campaign";
import {SVG_EBOX_SMS} from "../../../services/svgIcones";
import {ucFirst} from "../../../services/common";

export class SMSItem extends Component {

    constructor(props) {
        super(props);
    }

    render() {
      let { language, item, viewEBoxSmsPreview, status } = this.props;

        const colorClass = getStatusColorClass(item.sentStatus, 0, null, status);
        if (item.smsCampaign) {
            return (
              <div key={`sms-${item.id}`} className={`row list-item border-${colorClass}`}
                   onClick={() => viewEBoxSmsPreview(item)}>
                  <div className='list-item__border'>
                    {SVG_EBOX_SMS}
                  </div>
                  <div className={`list-item__sender small-3 ${(status === 'SENT' ) && "m-b"}`}><span
                      className="e-box-from-name list-item__header ">{status !== 'SENT' ? item.smsCampaign['senderName'] ? item.smsCampaign['senderName'] : '----' : ucFirst(_("to")) + " : "} </span>
                    {status !== 'SENT' ? <br /> : "  "} {status === 'SENT' ? item.phoneNumber : item.smsCampaign['senderPhoneNumber']}<br />
                  </div>
                  <div className="small-5 p-l-s p-r-s">{item.smsCampaign['name']}</div>
                    <div className="small-2 p-l-s p-r-s">
                        <div className="padding-left">
                            <div className="list-item__sub-header">
                                <i className="icon-left icon-clock" />
                                <span>{getDate((!item.sentAt) ? item.createdAt : item.sentAt, language)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="medium-2 p-l-s p-r-s">
                        <div className={"list-item__status"}>
                            <i className={`bg-${colorClass}`} />
                           {status !== 'SENT' ? _('received') : _('SENT')}
                        </div>
                    </div>
                </div>
            )
        }
        return null;
    }
}
