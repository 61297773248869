import {
  formatDataPercentage,
  formatData,
} from "../../services/dashboard";

//Dashboard Reducer
const initialStateForDashboardReducer = {
  filter: {
    recipientType: [],
    language: [],
    year: new Date().getFullYear(),
    period: "annual",
    month: new Date(),
  },
  fetching: false,
  fetched: false,
  typeEmail: [],
  dataOpens: [],
  totalMembers: 0,
  dataGender: [],
  dataLang: [],
  dataAge: [],
  totalVeryActive: [],
  totalNotActive: [],
  totalModeratelyActive: [],
  totalLowActive: [],
  dataAuthorizationMode: [],
  dataClicks: [],
  dataSentChart: [],
  dataSubscribers: [],
  bestType: [],
  bestLinks: [],
  dataMailAction: [],
  dataTotalMembers: [],
  dataUndeliveredMail: [],
  dataScore: [],
  dataBounces: [],
  dataCampagnesFetching: false,
  dataCampagnesFetched: false,
  error: null,
};
export const dashboardReducer = (
  state = initialStateForDashboardReducer,
  action
) => {
  switch (action.type) {
    case "DASHBOARD_FILTER_RECIPIENT_TYPE": {
      return {
        ...state,
        filter: Object.assign({}, state.filter, {
          recipientType: action.selected,
        }),
      };
    }
    case "DASHBOARD_FILTER_PERIOD": {
      return {
        ...state,
        filter: Object.assign({}, state.filter, {
          period: action.selected,
        }),
      };
    }
    case "DASHBOARD_FILTER_YEAR": {
      return {
        ...state,
        filter: Object.assign({}, state.filter, {
          year: action.year.getFullYear(),
        }),
      };
    }
    case "DASHBOARD_FILTER_LANGUAGE": {
      return {
        ...state,
        filter: Object.assign({}, state.filter, {
          language: action.language,
        }),
      };
    }
    case "DASHBOARD_FILTER_MONTH": {
      return {
        ...state,
        filter: Object.assign({}, state.filter, {
          month: action.month,
          year: action.month.getFullYear()
        }),
      };
    }
    case "DASHBOARD_FILTER_TYPE": {
      return {
        ...state,
        typeEmail: action.selected,
      };
    }
    case "FETCH_DASHBOARD_STATS_HISTORY_ACTION_PENDING": {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case "FETCH_DASHBOARD_STATS_HISTORY_ACTION_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        dataClicks: formatData(data, [["totalClicks"]], state.filter),
        dataScore: formatData(data, [["averageScore"]], state.filter),
        dataSubscribers: formatData(data, [["subscribersNumber", "unsubscribersNumber"]], state.filter),
        dataOpens: formatData(data, [["totalEmailsOpen"], "totalEmailsSent"], state.filter),
        totalVeryActive: formatData(data, [["totalVeryActive"]], state.filter),
        totalModeratelyActive: formatData(data, [["totalModeratelyActive"]], state.filter),
        totalLowActive: formatData(data, [["totalLowActive"]], state.filter),
        totalNotActive: formatData(data, [["totalNotActive"]], state.filter),
        dataBounces: formatData(data, [["bouncesPermanent",
          "bouncesOldPermanent", "bouncesTransient", "bouncesUndetermined"]], state.filter),
      };

    }

    case "FETCH_DASHBOARD_STATS_HISTORY_ACTION_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: {
          error: {
            title: error?.response?.data?.errors?.length
              ? error.response.data.errors[0].message
              : "An error has occured",
            code: error?.response?.data?.errors?.length
              ? error.response.data.errors[0].code
              : 500,
          },
        },
      };
    }

    case "FETCH_DASHBOARD_LINKS_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        bestLinks: data,
      };
    }

    case "FETCH_DASHBOARD_LINKS_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        bestLinks: [],
        error: {
          error: {
            title: error?.response?.data?.errors?.length
              ? error.response.data.errors[0].message
              : "An error has occured",
            code: error?.response?.data?.errors?.length
              ? error.response.data.errors[0].code
              : 500,
          },
        },
      };
    }
    /*
    case "FETCH_DASHBOARD_OPEN_DATA_CHART_PENDING": {
      return {
        ...state,
        dataOpensFetched: false,
      };
    }
    case "FETCH_DASHBOARD_OPEN_DATA_CHART_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        dataOpensFetched: true,
        dataOpensChart: formaterData(data,'date', 'pourcentage')
      };
    }
    case "FETCH_DASHBOARD_OPEN_DATA_CHART_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        error: {
          error: error
        }
      };
    }
    case "FETCH_BEST_TYPE_PENDING": {
      return {
        ...state,
        bestTypeFetched: false,
      };
    }
    case "FETCH_BEST_TYPE_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        bestTypeFetched:true,
        bestType: data
      };
    }
    case "FETCH_BEST_TYPE_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        error: {
          error: error
        }
      };
    }
    case "FETCH_DASHBOARD_CLICKS_DATA_CHART_PENDING": {
      return {
        ...state,
        dataClicksFetched: false,
      };
    }
    case "FETCH_DASHBOARD_CLICKS_DATA_CHART_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        dataClicksFetched: true,
        dataClicksChart: formaterData(data,'date_campaign','clicksCount')
      };
    }
    case "FETCH_DASHBOARD_CLICKS_DATA_CHART_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        error: {
          error: error
        }
      };
    }*/
    case "FETCH_DASHBOARD_CAMPAIGNS_DATA_CHART_PENDING": {
      return {
        ...state,
      };
    }
    case "FETCH_DASHBOARD_CAMPAIGNS_DATA_CHART_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        dataSentChart: formatData(data, [["campaigns"]], state.filter),
      };
    }
    case "FETCH_DASHBOARD_CAMPAIGNS_DATA_CHART_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        error: {
          error: error
        }
      };
    }
    case "FETCH_TOTAL_MEMBERS_PENDING": {
      return {
        ...state,
      };
    }
    case "FETCH_TOTAL_MEMBERS_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        dataTotalMembers: data
      };
    }
    case "FETCH_TOTAL_MEMBERS_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        error: {
          error: error
        }
      };
    }


    /*
    case "FETCH_DATA_UNDELIVERED_MAIL_ACTION_PENDING":
      return {...state};
    case "FETCH_DATA_UNDELIVERED_MAIL_ACTION_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        dataUndeliveredMail: formaterData(data,'date', 'pourcentage')
      };
    }
    case "FETCH_DATA_UNDELIVERED_MAIL_ACTION_REJECTED":{
      let error = action.payload;
      return {
        ...state,
        error: {
          title: error.response.data.errors
            ? error.response.data.errors[0].message
            : "An error has occured",
          code: error.response.data.errors
            ? error.response.data.errors[0].code
            : 500
        }
      };
    }
    case "FETCH_DATA_SCORE_ACTION_PENDING":
      return {...state};
    case "FETCH_DATA_SCORE_ACTION_FULFILLED": {
      const data = action.payload.data.data;
      return {
        ...state,
        dataScore: data
      };
    }
    case "FETCH_DATA_SCORE_ACTION_REJECTED":{
      let error = action.payload;
      return {
        ...state,
        error: {
          title: error.response.data.errors
            ? error.response.data.errors[0].message
            : "An error has occured",
          code: error.response.data.errors
            ? error.response.data.errors[0].code
            : 500
        }
      };
    }*/
    case "FETCH_MEMBERS_STATS_PENDING": {
      return {
        ...state,
        memberStatesFetched: false,
      };
    }
    case "FETCH_MEMBERS_STATS_FULFILLED": {
      const data = action.payload.data.data;
      let totalMembers = 0;
      for (let i = 0; i < data["GENDER_CHART"]["data"].length; i++) {
        totalMembers += parseInt(data["GENDER_CHART"]["data"][i]);
      }
      return {
        ...state,
        memberStatesFetched: true,
        totalMembers,
        dataGender: formatDataPercentage(data["GENDER_CHART"], totalMembers),
        dataAge: formatDataPercentage(data["AGE_CHART"], totalMembers),
        dataLang: formatDataPercentage(data["LANGUAGE_CHART"], totalMembers),
        dataAuthorizationMode: formatDataPercentage(
          data["AUTHORIZATION_MODE_CHART"],
          totalMembers
        ),
      };
    }
    case "FETCH_MEMBERS_STATS_REJECTED": {
      const error = action.payload;
      return {
        ...state,
        error: {
          error: error,
        },
      };
    }

    default:
      return state;
  }
};
