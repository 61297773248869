const initialState = {
  items: [],
  fetching: false,
  fetched: false,
  error: null
}

const initialStateForArticles = {
  items: [],
  nbResult: 0,
  fetching: false,
  fetched: false,
  filter: {
    searchWord: "",
    language: null,
    pageSize: 8,
    paginationPage: 1,
    tags: null,
    categories: null,
    articlePages: null,
  },
  articlePagesOptions: initialState,
}

export const articlesReducer = (state = initialStateForArticles, action) => {
  switch (action.type) {

    case 'INIT_ARTICLES': {
      return initialStateForArticles;
      break;
    }

    case 'FETCH_ARTICLES_PENDING': {
      return {
        ...state,
        fetching: true
      };
      break;
    }

    case 'FETCH_ARTICLES_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      const { filter, items } = state;
      let newItems = [];
      if (1 == filter.paginationPage) {
        newItems = data;
      } else {
        newItems = items;
        newItems.push(...data);
      }

      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: true,
        nbResult
      };
      break;
    }

    case 'FETCH_ARTICLES_REJECTED': {
      let { filter, nbResult, items } = state;
      let newItems = items;

      if (filter.paginationPage == 1) {
        newItems = [];
        nbResult = 0;
      }
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          }
          break;
        default:
      }
      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: false,
        nbResult,
        error
      };
      break;
    }

    case 'SET_ARTICLES_SEARCH_WORD': {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
      break;
    }

    case 'SET_ARTICLES_LANGUAGE': {
      return { ...state, filter: { ...state.filter, language: action.language, paginationPage: 1 } };
      break;
    }

    case 'SET_ARTICLES_TAGS': {
      return { ...state, filter: { ...state.filter, tags: action.tags, paginationPage: 1 } };
      break;
    }

    case 'SET_ARTICLES_CATEGORIES': {
      return { ...state, filter: { ...state.filter, categories: action.categories, paginationPage: 1 } };
      break;
    }

    case 'SET_ARTICLES_PAGES': {
      return { ...state, filter: { ...state.filter, articlePages: action.articlePages, paginationPage: 1 } };
      break;
    }

    case 'SET_ARTICLES_PAGINATION_PAGE': {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
      break;
    }

    case "INIT_ARTICLE_PAGES": {
      return initialArticlePagesState;
    }
    case "FETCH_ARTICLE_PAGES_OPTIONS_PENDING": {
      return { ...state, articlePagesOptions: { ...state.articlePagesOptions, fetching: true } };
    }
    case "FETCH_ARTICLE_PAGES_OPTIONS_FULFILLED": {
      let pages = action.payload.data && action.payload.data.data;
      return {
        ...state,
        articlePagesOptions: {
          ...state.articlePagesOptions,
          fetching: false,
          fetched: true,
          items: ["fr", "en", "nl"].map(lng => {
            return {
              label: lng,
              options: pages ? pages
                .reduce((acc, { id, title, language }) => {
                  if (language == lng && !acc.some(e => e.value == id)) {
                    acc.push({ label: title, value: id });
                  }
                  return acc;
                }, []) : []
            }
          })
        }
      };
    }
    case "FETCH_ARTICLE_PAGES_OPTIONS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        articlePagesOptions: {
          ...state.articlePagesOptions,
          fetching: false,
          error
        }
      };
    }

    default:
      return state;
  }
};



const initialCategoriesSuggestionsState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null
};
export const categoriesSuggestionsReducer = (
  state = initialCategoriesSuggestionsState,
  action
) => {
  switch (action.type) {
    case "INIT_CATEGORIES_SUGGESTIONS": {
      return initialCategoriesSuggestionsState;
    }
    case "FETCH_CATEGORIES_SUGGESTIONS_PENDING": {
      return { ...state, fetching: true };
    }
    case "FETCH_CATEGORIES_SUGGESTIONS_FULFILLED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: action.payload.data.data.sort(function (a, b) {
          return a.count - b.count;
        })
      };
    }
    case "FETCH_CATEGORIES_SUGGESTIONS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error
      };
    }
    default:
      return state;
  }
};
