import React from "react";
import Application from "../components/common/Application";

export const WTB_APP = "WTB";
export const PAYMENT_APP = "PAYMENT";
export const GDPR_APP = "GDPR";
export const UA_APP = "UNITED_ASSOCIATES";

export const SUPPORTED_APPS = [
  { code: "EVENT", name: "EVENT" },
  { code: "EVENT_CYCLE", name: "EVENT_CYCLE" },
  { code: "SURVEY", name: "SURVEY" },
  { code: "WTB", name: "WHERETOBILL", logo: "directory" },
  { code: "PAYMENT", name: "PAYMENT" },
  /* { code: 'GDPR', name: 'GDPR', logo: 'other' },
  { code: 'EMAILING', name: 'EMAILING' }, */
  { code: "EXPRESS", name: "EXPRESS", logo: "emailing" },
  { code: "DASHBOARD", name: "DASHBOARD", logo: "accounting" },
  { code: "UNITED_ASSOCIATES", name: "UNITED ASSOCIATES", logo: "event" },
];

export const getSupportedAppsOptions = () => {
  return SUPPORTED_APPS.map(({ code, name, logo }) => {
    return {
      value: code,
      label: <Application name={name} logo={logo} />,
      id: code,
    };
  });
};
