import axios from '../../axios-api';

export const sendEmails = (token, data) => {
  const requestUrl = `/mailing/send/transactional-mail`;
  var formData = new FormData();
  if (!data.fromEmail || !data.fromEmail.email || !data.recipients) {
    return;
  }

  formData.append('access_token', token);
  data.recipients.forEach(recipient => {
    formData.append('recipients[]', JSON.stringify(recipient));
  });

  if (data.cc && data.cc.length) {
    formData.append('cc', JSON.stringify(data.cc));
  }

  if (data.reply && data.reply.length) {
    formData.append('reply', JSON.stringify(data.reply));
  }
  formData.append('enableResponse', data.enableResponse);
  formData.append('subject', data.subject || 'No subject');
  formData.append('fromEmail', data.fromEmail.email);
  formData.append('message', data.message);

  if (data.attachments) {
    for (var i = 0; i < data.attachments.length; i++) {
      if (data.attachments[i] instanceof File) {
        const file = data.attachments[i];
        formData.append(`file[${i}]`, file, file.name);
      }
    }
  }

  return axios.post(requestUrl, formData);
};
