import React from "react";
import {SimpleTabs} from "../../ui/tabs/SimpleTabs";
import {ucFirst} from "../../../services/common";
import _ from "i18n";
import {IS_EMAIL_VALID} from "../../../services/svgIcones";
import { LANGUAGES } from "Common";

export default class FromTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: {
        fromName: {
          fr: false,
          nl: false,
          en: false,
        },
        fromEmail: {
          fr: false,
          nl: false,
          en: false,
        },
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {currentSetting, registeredEmails, fromHasError} = this.props;
    const {languages} = currentSetting;
    const {hasError} = this.state;
    LANGUAGES.forEach(lng => {
      let fromEmail = currentSetting["fromEmail" + ucFirst(lng)];
      let fromName = currentSetting["fromName" + ucFirst(lng)];
      if (fromEmail !== prevProps.currentSetting["fromEmail" + ucFirst(lng)]
        || (registeredEmails !== prevProps.registeredEmails && fromEmail)) {
        this.validateFromEmail(fromEmail, lng);
      }
      if (fromName && fromName !== prevProps.currentSetting["fromName" + ucFirst(lng)]) {
        this.validateFromName(fromName, lng);
      }
    });

    if (prevProps.currentSetting !== currentSetting || hasError !== prevState.hasError || registeredEmails !== prevProps.registeredEmails) {
      const error = !languages || languages.length === 0 ||
        languages.some(l => hasError.fromName[l] || hasError.fromEmail[l] || !currentSetting["fromEmail" + ucFirst(l)] || !currentSetting["fromName" + ucFirst(l)]);
      fromHasError(error);
    }
  }

  validateFromEmail = (value, lng) => {
    const {hasError} = this.state;
    let newState = {hasError: {...hasError}};
    const error = value.trim().length < 5 || !this.isValidEmail(value);
    newState.hasError.fromEmail[lng] = error;
    this.setState(newState);
  }

  validateFromName = (value, lng) => {
    const {hasError} = this.state;
    let newState = {hasError: {...hasError}};
    const error = value.trim().length < 5;
    newState.hasError.fromName[lng] = error;
    this.setState(newState);
  }

  isValidEmail = (email) => {
    const {registeredEmails} = this.props;

    return registeredEmails &&
      Object.keys(registeredEmails).some(key => (
          key === email &&
          registeredEmails[key].VerificationStatus === "Success"
        )
      );
  };

  handleChange(e, inputName = "fromName", lng = "fr") {
    const value = e.target.value;
    const data = {};
    data[`${inputName}${lng}`] = value;
    this.props.setSettingData(data);
  }

  render() {
    const {currentSetting, activeLanguage, setActiveLanguage} = this.props;
    const {languages} = currentSetting;
    const {hasError} = this.state;
    const lng = ucFirst(activeLanguage);

    return <SimpleTabs
      active={activeLanguage}
      tabs={languages.map(l => ({
        lng: l,
        hasError: hasError.fromName[l] || hasError.fromEmail[l] || !currentSetting[`fromEmail${ucFirst(l)}`] || !currentSetting[`fromName${ucFirst(l)}`]
      }))}
      setActiveTab={setActiveLanguage}
      language>
      <div className="small-6 email-field column">
        <span className="param-label">{_("fromEmail")}</span>
        <input placeholder={_('Email')} type="email" value={currentSetting[`fromEmail${lng}`]}
               onChange={(ev) => this.handleChange(ev, "fromEmail", lng)}
               className={`${currentSetting[`fromEmail${lng}`] ? "filled-box" : "empty-box"}${hasError.fromEmail[activeLanguage] ? 'title__input is-invalid-input' : ""}`}/>
        {IS_EMAIL_VALID(currentSetting[`fromEmail${lng}`] && !hasError.fromEmail[activeLanguage])}
        <span
          className={hasError.fromEmail[activeLanguage] ? 'form-error is-visible' : 'form-error'}>{_('invalidEmail')}</span>
      </div>
      <div className="small-6 column">
        <span className="param-label">{_('fromName')} </span>
        <input placeholder={_('From')} type="text" value={currentSetting[`fromName${lng}`]}
               onChange={(ev) => this.handleChange(ev, "fromName", lng)}
               className={`${currentSetting[`fromName${lng}`] ? "filled-box" : "empty-box"}${hasError.fromName[activeLanguage] ? 'title__input is-invalid-input' : ""}`}/>
        <span
          className={hasError.fromName[activeLanguage] ? 'form-error is-visible' : 'form-error'}>{_('invalidFromName')}</span>
      </div>
    </SimpleTabs>
  }
}
