import React from "react";
import { connect } from 'react-redux';
import _ from 'i18n';

import { setBlocksType, toggleAddBlockSideBar } from "actions";
import {SVG_ICONS} from "../../config/Common";

const mapStateToProps = (store, ownProps) => ({
    showAddBlockSideBar: store.themes.newsletter.showAddBlockSideBar,
});
  
const mapDispatchToProps = dispatch => ({
    openAddBlockSideBar: () => dispatch(toggleAddBlockSideBar(true)),
    setBlocksType: (type) => dispatch(setBlocksType(type)),
});
@connect(mapStateToProps, mapDispatchToProps)
export default class AddButtons extends React.Component {

    openBlockSideBar = (type) => {
        const { openAddBlockSideBar, setBlocksType } = this.props;
        setBlocksType(type);
        openAddBlockSideBar();
    }
    
    render() {
        return (
            <div className="add-button-container">
                <div className="add-button" onClick={() => this.openBlockSideBar("NEWS_LAYOUT")}>{_("Article")}</div>
                <div className="add-button reverse" onClick={() => this.openBlockSideBar("SLOT_LAYOUT")}>{_("SLOT")}</div>
                <div className="add-button" onClick={() => this.openBlockSideBar("SPEAKER_LAYOUT")}>{_("SPEAKER")}</div>
                <div className="add-button reverse" onClick={() => this.openBlockSideBar("STATIC_BLOCK")}>{_("STATIC")}</div>
                <div className="add-icon">
                    <img className="icon" src={"/img/icons/" + SVG_ICONS["ADD"]}/>
                    <span>{_("add")}</span>
                </div>
            </div>
        );
    }
}