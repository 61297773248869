import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from "react-autosuggest";
import debounce from 'lodash.debounce'

import { LoadingSuggestion } from "common/LoadingSuggestion";

import _ from 'i18n';
import { setEboxOwner } from 'actions';
import { fetchEmails } from 'thunks';
import { getUserNameForAvatar } from 'utils';

const mapStateToProps = (state, ownProps) => ({
    emailsSuggestions: state.emails.items,
    emailError: state.emails.error,
    fetching: state.emails.fetching,
});

const mapDispatchToProps = dispatch => ({
    fetchEmails: (value) => dispatch(fetchEmails(value, false)),
    setEboxOwner: (user) => dispatch(setEboxOwner(user)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class SearchBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            query: "",
            emailsSuggestions: [],
        };

        this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, 1000);
    }

    fetchSuggestions = ({ value }) => {
        this.props.fetchEmails(value);
    }

    suggestionSelectedHandler = (suggestion) => {
        this.props.setEboxOwner(suggestion.user);
        this.setState({ query: '' });
    }

    render() {
        const { emailsSuggestions, fetching, emailError } = this.props;
        const { query } = this.state;
        const inputProps = {
            placeholder: _('Search for recipient'),
            value: query,
            onChange: (event, { newValue }) => { this.setState({ query: newValue }); },
            autoFocus: "true"
        };

        return (
            <div className="ebox-explorer__search">
                <Autosuggest
                    suggestions={emailsSuggestions}
                    shouldRenderSuggestions={(value) => value && value.trim().length > 2}
                    onSuggestionsFetchRequested={(value) => (this.debouncedFetchSuggestions(value))}
                    getSuggestionValue={(suggestion) => suggestion.email}
                    onSuggestionsClearRequested={() => this.setState({ emailsSuggestions: [] })}
                    onSuggestionSelected={(e, { suggestion }) => this.suggestionSelectedHandler(suggestion)}
                    renderSuggestion={(suggestion) => {
                        const { user, email } = suggestion;
                        return (
                            <div className="user-suggestion__item">
                                {user.avatarUrl ?
                                    <div className="img-wrap" style={{ backgroundImage: `url(${user.avatarUrl})` }}></div> :
                                    <div className="img-wrap empty-avatar"><span style={{ fontSize: 'inherit' }}>{getUserNameForAvatar(user.firstName, user.lastName)}</span></div>
                                }
                                <div className="content">
                                    <h4>{user.firstName} {user.lastName}</h4>
                                    <p>{email}</p>
                                </div>
                            </div>
                        );
                    }}
                    inputProps={inputProps}

                    renderSuggestionsContainer={({ containerProps, children, query }) =>
                        <LoadingSuggestion containerProps={containerProps} loadingsSuggestions={fetching} suggestionsError={emailError} value={query}>
                            {children}
                        </LoadingSuggestion>
                    }
                />
                <i className="icon-magnifier icon" />
            </div>
        );
    }
}