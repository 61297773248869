import React, { Component } from 'react';

export default class EBoxFetching extends Component {

    getFetchingLines(){
        let rows = [];
        for(let i=0; i<5; i++){
            rows.push(  <div key={`div-${i}`} className="row list-item theme-fetching e-box-fetching border-draft">
                <div className="small-4">
                    <h4 className="gradiant"></h4>
                    <h5 className="gradiant"></h5>
                </div>
                <div className="small-4">
                    <h5 className="gradiant"></h5>
                    <h5 className="gradiant"></h5>
                </div>
                <div className="small-2 padding-left">
                    <h5 className="gradiant"></h5>
                </div>
                <div className="small-2 flex-container align-right">
                    <h4 className="list-item__status gradiant"></h4>
                </div>
            </div>);
        }
        return rows;
    }
    render() {
        return <div className="small-12 e-box-fetching columns">
            {this.getFetchingLines()}
        </div>
    }
}
