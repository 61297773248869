export const fetchSpeakers = (resp) => {
    return {
        type: 'FETCH_SPEAKERS',
        payload: resp
    };
};

export const initSpeakers = () => {
    return {
      type: 'INIT_SPEAKERS'
    };
};

export const setSpeakersSearchWord = (searchWord) => {
    return {
      type: 'SET_SPEAKERS_SEARCH_WORD',
      searchWord
    }
};

export const setSpeakersEvent = (event) => {
    return {
      type: 'SET_SPEAKERS_EVENT',
      event
    }
};

export const setSpeakersPaginationPage = (pageNumber) => {
  return {
    type: 'SET_SPEAKERS_PAGINATION_PAGE',
    pageNumber
  };
};
  