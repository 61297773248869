import React, {Component} from 'react';
import { connect } from 'react-redux';
import PercentageChart from "./PercentageChart";

@connect((store) => {
  return {
    dataGender: store.dashboard.dataGender
  };
})
export default class GenderChart extends Component {
  render() {
    return <PercentageChart dataBar={this.props.dataGender} />
  }
}
