import * as actions from "actions";
import * as api from "TamtamAPI";

export const fetchNewsletterUAArticles = () => {
  return (dispatch, getState) => {
    const { currentClient, token } = getState().auth;
    const clientId = currentClient.id;

    return dispatch(
      actions.getNewsletterArticles(
        api.getNewsletterArticles({
          token,
          clientId,
        }),
      ),
    );
  };
};
export const fetchNewsletterUAArticlesByPeriod = (data) => {
  return (dispatch, getState) => {
    const { currentClient, token } = getState().auth;
    const { periodConfig, month, year, communityId } = data;
    const clientId = communityId ? communityId : currentClient.id;

    return dispatch(
      actions.getNewsletterArticlesByPeriod(
        api.getNewsletterArticlesByPeriod({
          token,
          clientId,
          periodConfig,
          month,
          year,
        }),
      ),
    );
  };
};

export const setIsUsedNewsletterArticles = (articleNewsletterId) => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    const clientId = currentClient?.id;

    return dispatch(
      actions.updateIsUsedNewsletterArticles(
        api.updateIsUsedNewsletterArticles({
          token,
          articleNewsletterId,
          clientId,
        }),
      ),
    );
  };
};
