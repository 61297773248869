import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import _ from "i18n";
import PropTypes from 'prop-types';

import {
  setTextModelsPaginationPage,
  initTextModels,
  setTextModelsTargetApp,
  setTextModelsScope,
  setTextModelsLanguage,
  setTextModelsTargetType,
  setCurrentEmailData,
  clearSourcesTokens
} from "actions";
import { fetchTextModels } from "thunks";
import TextModelItem from "./TextModelItem";
import NotFound from 'notFound/NotFound';
import InfiniteScroll from 'react-infinite-scroller';
import TextModelFetching from "../fetching/TextModelFetching";
import TextModelForm from "./TextModelForm";
import { BlankText } from "./BlankText";
import HorizontalFilters from "./HorizontalFilters";
import { RESTRICTED_MODE, MODERATE_MODE, ADMIN_MODE } from "../../constants";
import { setTextModelsSearchWord } from "../../actions/actions";
import TextModelFilters from "./TextModelFilters";
import {getLanguages} from "../../services/utils";
import {SUPPORTED_APPS} from "../../services/apps";

class TextModelList extends Component {

  static propTypes = {
    mode: PropTypes.oneOf([RESTRICTED_MODE, MODERATE_MODE, ADMIN_MODE, undefined]),
  };

  state = {
    hasMoreItems: true,
    showTextModelForm: false,
    title: "",
    name:"",
    content: "",
    language: this.props.lng,
    object: "",
    targetType: "",
    textModelId: "",
    main: 0,
  }

  constructor(props) {
    super(props);
    this.toggleForm = this.toggleForm.bind(this);
    this.initState = this.initState.bind(this);
    this.languageOptions = getLanguages().map(lng => {
      return { value: lng.key, label: _(lng.value) }
    });
    this.targetAppOptions = SUPPORTED_APPS.map(({code, name}) => {
      return {value: code, label: _(name.toLowerCase())}
    });
  }

  componentDidMount() {
    const { fetchTextModels, setTextModelsTargetApp, setTextModelsTargetType, setTextModelsLanguage, setTextModelsScope, initTextModels, fetching, genericCurrentEmailLanguage, genericCurrentEmailType, genericCurrentApp, mode, language, targetApp } = this.props;
    initTextModels();

    if(targetApp) {
      setTextModelsTargetApp(targetApp);
    }
    if (language) {
      setTextModelsLanguage(language);
    }

    const query = new URLSearchParams(this.props.location.search);

    if (query.get('targetApp')) {
      setTextModelsTargetApp(query.get('targetApp').toUpperCase());
    }
    if (!fetching) {
      if (RESTRICTED_MODE === mode) {
        setTextModelsTargetApp(genericCurrentApp);
        setTextModelsLanguage(genericCurrentEmailLanguage);
        setTextModelsTargetType(genericCurrentEmailType);
      }
      // setTextModelsScope((ADMIN_MODE === mode) ? ["TAMTAM"] : ["COMMUNITY"]);
      fetchTextModels();
    }
  }

  componentDidUpdate(prevProps) {
    const { filter, fetchTextModels, fetching, mode } = this.props;

    if (filter !== prevProps.filter && !fetching) {
      setTextModelsScope((ADMIN_MODE === mode) ? ["TAMTAM"] : []);
      fetchTextModels();
    }
    if (fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !fetching,
        textModelId: "",
      })
    }

  }

  componentWillUnmount() {
    const { _requests, clearSourcesTokens } = this.props;
    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    clearSourcesTokens();
  }

  loadItems = () => {
    const { filter, fetching, nbResult, textModels, setTextModelsPaginationPage } = this.props;
    if (!fetching && textModels.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      setTextModelsPaginationPage(currentPage + 1);
    }
  }

  toggleForm = (showForm = false) => {
    this.setState({ showTextModelForm: showForm, formAction: "CREATE" });
    this.initState();
  }

  setTextModelData = (textModelData) => {
    this.setState({
      title: textModelData.title,
      content: textModelData.content,
      language: textModelData.language,
      object: textModelData.object,
      textModelId: textModelData.id,
      name: textModelData.name,
      targetApp: textModelData.targetApp,
      targetType: textModelData.targetType,
      formAction: textModelData.formAction,
      main: textModelData.main,
    });
  }

  removePreFilter = (filter) =>{
    let { setTextModelsLanguage, setTextModelsTargetApp } = this.props;
    if(filter.label === _("language"))
      setTextModelsLanguage("");

    if(filter.label === _("application"))
      setTextModelsTargetApp("");


  }

  handleSelectLanguage = (selected) => {
    this.props.setTextModelsLanguage(selected.target.value);
  };

  handleSelectTargetApp = (selected) => {
    this.props.setTextModelsTargetApp(selected.target.value);
  };

  initState() {
    this.setState({
      title: "",
      content: "",
      language: this.props.lng,
      object: "",
      name:"",
      targetApp: "",
      targetType: "",
      textModelId: "",
      main: 0,
    });
  }

  render() {
    const { showTextModelEditor, textModels, fetching, mode, onApply, current, searchWord, scope, setTextModelsScope, targetApp,  setTextModelsLanguage,  setTextModelsTargetApp, setTextModelsSearchWord,language, textModelLng, textModelTargetApp} = this.props;
    const { hasMoreItems,name, showTextModelForm, title, content, object,  textModelId, targetType, formAction, main} = this.state;

    const isNotAdminMode = mode && ADMIN_MODE !== mode;
    let items = [];
    let filters = [];
    if(language)
      filters.push({
        label: _("language"),
        value: textModelLng || language,
        options: this.languageOptions,
        action: this.handleSelectLanguage
      });
    if(targetApp)
      filters.push({
        label: _("application"),
        value: textModelTargetApp || targetApp,
        options: this.targetAppOptions,
        action: this.handleSelectTargetApp
      });
    if (textModels && textModels.length > 0) {
      items = textModels.map((textModel) => {
        if (showTextModelForm && ["UPDATE", "DUPLICATE"].some(e => e == formAction) && textModelId == textModel.id) {
          return <TextModelForm
            key={`form-${textModelId}`}
            title={title}
            subject={object}
            name={name}
            content={content}
            language={this.state.language}
            targetType={targetType}
            targetApp={this.state.targetApp}
            id={textModelId}
            toggleForm={this.toggleForm}
            formAction={formAction}
            main={main}
            mode={mode}
            currentClientId={textModel.client ? textModel.client.id : null}
          />;
        }
        return <TextModelItem
          key={textModel.id} {...textModel}
          mode={mode}
          setTextModelData={this.setTextModelData}
          onApply={onApply}
          toggleForm={this.toggleForm}
          current={current}
        />
      });
    }

    if (fetching) {
      for (let i = 0; i < 2; i++) {
        items.push(<TextModelFetching key={`fetch-${i}`} />)
      }
    } else if (RESTRICTED_MODE === mode) {
      items.unshift(<BlankText clicked={showTextModelEditor} />);
    }

    if (formAction === "CREATE" && showTextModelForm) {
      items.unshift(<TextModelForm
        key="form"
        toggleForm={this.toggleForm}
        formAction={formAction}
        language={this.state.language}
        main={main}
        mode={mode}
      />)
    }

    return (
      <div className="medium-12 columns rich-editor-model" style={isNotAdminMode ? { overflowX: 'hidden', height: '70vh' } : {}} ref={(ref) => this.scrollParentRef = ref}>
        {(!mode || (mode && ADMIN_MODE === mode)) ?
          <TextModelFilters mode={mode}/> :
          <HorizontalFilters
            mode={mode}
            searchWord={searchWord}
            targetApp={targetApp}
            scope={scope}
            language={language}
            setTextModelsLanguage={setTextModelsLanguage}
            setTextModelsTargetApp={setTextModelsTargetApp}
            filters={filters}
            removePreFilter={this.removePreFilter}
            setTextModelsScope={setTextModelsScope}
            setTextModelsSearchWord={setTextModelsSearchWord}
          />}
        {(items && items.length > 0) ?
          <InfiniteScroll
            className="generic-email-list row align-center generic-email-container text-model-container"
            pageStart={0}
            loadMore={this.loadItems}
            hasMore={hasMoreItems}
            useWindow={isNotAdminMode ? false : true}
            getScrollParent={() => this.scrollParentRef}
          >
            {items}
          </InfiniteScroll>
          : <NotFound mainClass={mode ? "small-11" : "small-10"} />
        }

        {(!mode && !showTextModelForm || mode && ADMIN_MODE === mode) && <div className="text-model-add-button" onClick={this.toggleForm}>
          <i className="icon-plus" />
        </div>}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  textModels: state.textModels.items,
  fetching: state.textModels.fetching,
  fetched: state.textModels.fetched,
  nbResult: state.textModels.nbResult,
  filter: state.textModels.filter,
  textModelLng: state.textModels.filter.language,
  textModelTargetApp: state.textModels.filter.targetApp,
  lng: state.params.lng,
  _requests: state.params.sourcesTokens,
  genericCurrentEmailLanguage: state.genericEmail.currentSetting.currentEmail.language,
  genericCurrentEmailType: state.genericEmail.currentSetting.currentEmail.targetType,
  genericCurrentApp: state.genericEmail.currentSetting.targetApp,
  searchWord: state.textModels.filter.searchWord,
  scope: state.textModels.filter.scope,
  // lng: state.auth.user.language,
});

const mapDispatchToProps = dispatch => ({
  fetchTextModels: () => dispatch(fetchTextModels(null, true)),
  setTextModelsPaginationPage: (page) => dispatch(setTextModelsPaginationPage(page)),
  setTextModelsTargetApp: (targetApp) => dispatch(setTextModelsTargetApp(targetApp)),
  setTextModelsLanguage: (language) => dispatch(setTextModelsLanguage(language)),
  setTextModelsTargetType: (targetType) => dispatch(setTextModelsTargetType(targetType)),
  setTextModelsScope: (scope) => dispatch(setTextModelsScope(scope)),
  setTextModelsSearchWord: (word) => dispatch(setTextModelsSearchWord(word)),
  showTextModelEditor: () => dispatch(setCurrentEmailData({ action: 'EDIT_TEXT' })),
  initTextModels: () => dispatch(initTextModels()),
  clearSourcesTokens: () => dispatch(clearSourcesTokens())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)
  (TextModelList);
