import React, { Component } from "react";
import Select, { components } from "react-select";

import _ from "i18n";

export default class TTPSelect extends Component {
  onInputChange = (inputValue, items = null) => {
    const { onChange, simple, smsObject, isObjects } = this.props;
    let selected = null;

    if (simple) {
      selected =
        (inputValue && (smsObject ? inputValue : inputValue.value + "")) ||
        null;
      if (isObjects) {
        selected = inputValue;
      }
    } else if (
      inputValue &&
      inputValue.find((option) => option.value === "all")
    ) {
      selected = items.slice(1).map(({ value }) => value);
    } else {
      selected = (inputValue || []).map(({ value }) => value);
    }
    onChange(selected);
  };

  render() {
    let {
      placeholder,
      isDisabled,
      disabled,
      options,
      values,
      simple,
      notClearable,
      customOption,
      cssClass,
      selectAll,
    } = this.props;

    let items = options?.map(({ id, name, label, ...details }) => {
      return {
        id: id,
        label: label || name,
        value: id + "",
        isDisabled: disabled && disabled.includes(id + ""),
        ...details,
      };
    });

    if (selectAll) {
      items.unshift({ label: _("Select All"), value: "all" });
    }

    const value = items?.filter(
      ({ value }) =>
        values &&
        (Array.isArray(values) ? values.includes(value) : value == values),
    );
    return (
      <Select
        isMulti={!simple}
        isClearable={!notClearable}
        isSearchable
        isDisabled={isDisabled}
        value={value}
        placeholder={placeholder || ""}
        onChange={(selected) => this.onInputChange(selected, items)}
        options={items}
        components={{ Option: customOption || DefaultOption }}
        className={`ttp-select ${cssClass} ${
          (!value || !value.length) && "empty-box"
        }`}
        classNamePrefix="ttp-select"
      />
    );
  }
}

const DefaultOption = (props) => {
  const { data } = props;

  if (data.content) {
    return (
      <components.Option {...props}>
        <div className="select-option">
          <span className="select-option__label">{data.label}</span>
          <span className="select-option__content">{data.content}</span>
        </div>
      </components.Option>
    );
  } else if (data.noContent) {
    return (
      <components.Option {...props}>
        <span>{data.label}</span>
      </components.Option>
    );
  }

  return <components.Option {...props} />;
};
