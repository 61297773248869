import React from 'react';
import Component from 'react-email-editor';
import { connect } from 'react-redux';
import _ from 'i18n';
import { fetchEventCycles, fetchEvents } from '../../../../../actions/thunks';

import { ucFirst } from '../../../../../services/utils';
import TTPSelect from '../../../../common/TTPSelect';

class AppRefSelector extends Component {

  componentDidMount() {
    const { fetchEvents, fetchEventCycles } = this.props;
    fetchEvents();
    fetchEventCycles();
  }

  handleAppRefChange = (appRefs) => {
    this.props.onChange(appRefs);
  }

  getOptions = () => {
    const { eventCycles, events, lng, appType } = this.props;
    let options = ('cycle_registering' == appType) ? eventCycles : events;

    return options.map((option) => ({
      id: String(option.id),
      name: option[`name${ucFirst(lng)}`] || option[`nameFr`] || option[`nameNl`]
    }))
  }

  render() {
    const { appRefs, appType } = this.props;
    const isEventCycle = 'cycle_registering' == appType;

    return (
      <div className="columns small-6 columns-padding">
        <div className="sidebar__form-control">
          <h1 className="param__title">{_(isEventCycle ? 'event_cycle' : 'event')}</h1>
          <TTPSelect
            notClearable={true}
            values={appRefs}
            placeholder={_('Select')}
            options={this.getOptions()}
            onChange={this.handleAppRefChange} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lng: state.params.lng,
  events: state.filters.events.items,
  eventCycles: state.filters.eventCycles.items,
});

const mapDispatchToProps = dispatch => ({
  fetchEvents: () => dispatch(fetchEvents()),
  fetchEventCycles: () => dispatch(fetchEventCycles()),
})
export default connect(mapStateToProps, mapDispatchToProps)(AppRefSelector);
