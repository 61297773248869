const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};
export const campaignStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_CAMPAIGN_STATS': {
      return initialState;
      break;
    }
    case 'FETCH_CAMPAIGN_STATS_PENDING': {
      return {
        ...state,
        fetched: false,
        fetching: true
      };
    }
    case 'FETCH_CAMPAIGN_STATS_FULFILLED': {
      const { data } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data,
      };
    }
    case 'FETCH_CAMPAIGN_STATS_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        data: [],
        error
      };
    }
    case 'UPDATE_CAMPAIGN_LINK_FULFILLED': {
      const { data: { id, link } } = action.payload.data;
      let data = { ...state.data };
      data.links = (data.links || []).map((linkItem) => {
        let newLink = linkItem;
        if (linkItem.id == id) {
          newLink = {
            ...linkItem,
            link
          }
        }
        return newLink
      })

      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
};

const initialStateForRecipientStats = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
  paginationPage: 1,
  updating: false,
}

export const recipientsStatsReducer = (state = initialStateForRecipientStats, action) => {
  switch (action.type) {
    case 'FETCH_RECIPIENTS_STATS_PENDING': {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case 'FETCH_RECIPIENTS_STATS_FULFILLED': {
      const { data, nbResult } = action.payload.data
      let recipientsTotalCount = (state.isTotal) ? nbResult : state.recipientsTotalCount;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
        recipientsTotalCount
      };
      break;
    }
    case 'RE_SEND_EMAIL_PENDING': {
      return {
        ...state,
        updating: true
      };
      break;
    }
    case 'RE_SEND_EMAIL_FULFILLED': {
      let { data } = action.payload.data;

      let newItems = state['items'].map(item => {
        if (item.id == data.id) {
          return { ...item, ...data }
        }
        return item;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: newItems,
        updating: false
      };
      break;
    }
    case 'RE_SEND_EMAIL_REJECTED': {
      return {
        ...state,
        updating: false
      };
      break;
    }
    case 'FETCH_RECIPIENTS_STATS_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          }
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        nbResult: 0,
        error
      };
      break;
    }
    case 'SET_RECIPIENTS_STATS_PAGINATION_PAGE': {
      return { ...state, paginationPage: action.pageNumber };
      break;
    }
    case 'SET_RECIPIENTS_STATS_PAGE_SIZE': {
      return { ...state, pageSize: action.pageSize };
      break;
    }
    default:
      return state;
  }
};

const initialStateForRecipientsStatsFilter = {
  name: "",
  status: "",
  linkId: null,
};
export const recipientsStatsFilterReducer = (state = initialStateForRecipientsStatsFilter, action) => {
  switch (action.type) {
    case 'SET_RECIPIENTS_STATS_FILTER_NAME': {
      return { ...state, name: action.name };
      break;
    }
    case 'SET_RECIPIENTS_STATS_FILTER_STATUS': {
      return { ...state, linkId: null, status: action.status };
      break;
    }
    case 'SET_RECIPIENTS_STATS_FILTER_LINK_ID': {
      return { ...state, linkId: action.linkId, status: (action.linkId) ? 'LINK_CLICKS' : "" };
      break;
    }
    case 'INIT_RECIPIENTS_STATS_FILTER_STATUS': {
      return initialStateForRecipientsStatsFilter;
    }
    default:
      return state;
  }
};
