import React, { Component } from 'react';

export default class ImageLibraryFetching extends Component {

  render(){
      return <div key="image-3" className="add-theme-img dark-gradiant add-theme-img__preview">
           <span className="add-img">
               <i className="icon icon-note"></i>
               <i className="icon icon-check"></i>
           </span>
         </div>
  }
}
