import axios from '../axios-api';

export const getTTPUser = (userId, token, ignorePermissionFilter = 0) => {
  const filter = [
    {
      property: 'id',
      value: userId,
      operator: 'eq',
    },
  ];
  const fields = ['*', 'email', 'avatar', 'description', 'cover', 'url', 'clients', 'selectedOrganization'];
  const requestUrl = `/organization/user`;

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(','),
  };

  if (ignorePermissionFilter === 1) {
    params.ignorePermissionFilter = true;
  }

  return axios.get(requestUrl, {
    params,
  });
};

export const savePreferences = (token, data) => {
  const requestUrl = `/organization/organization-settings`;
  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('id', data.organizationSettingsId);
  if (data.emailingPreferences) {
    formData.append('emailingTestingPreferences', JSON.stringify(data.emailingPreferences));
  }
  if (data.verifiedEmails) {
    formData.append('verifiedEmails', JSON.stringify(data.verifiedEmails));
  }
  ['Fr', 'Nl', 'En'].map(lng => {
    formData.append(`theme${lng}`, (data[`theme${lng}`] && data[`theme${lng}`].id) ? data[`theme${lng}`].id : null);
  });
  return axios.post(requestUrl, formData);
};

export const getClientSettings = (token, organizationSettingsId) => {
  const fields = ['*', 'themes'];
  const requestUrl = `/organization/organization-settings`;

  const filter = [
    { property: 'id', value: organizationSettingsId, operator: 'eq' },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter)
    }
  });
};

export const setSelectedOrganization = (token, client) => {
  const requestUrl = `/organization/user/save-selected-organization`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", client.id);

  return axios.post(requestUrl, formData);
};
