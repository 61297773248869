import React, { Component } from 'react';

export default class MailingListsFetching extends Component {
  render() {
    return  <div className={`mailing-lists__item mailing-list-fetching`}>
        <div className="medium-12 p-l-xs p-r-xs mailing-lists__item-header">
          <span className="gradiant"/>
        </div>
        <div className="mailing-lists__item-container">
          <h3 className="gradiant"/>
          <h6 className="gradiant"/>
          <h4 className="gradiant"/>
          <h4 className="gradiant"/>
          <h4 className="gradiant"/>
        </div>
      </div>
  }
}
