import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'i18n';
import { persistSMSOrderPrice, getCountrySmsPrice } from 'thunks';
import { onSuccess, onError, PRICE_VALIDATION_REGEX } from 'utils';
import 'react-notifications/lib/notifications.css';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';


@connect((store) => {
  return {}
})

export default class SMSBuyCredit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buyIsOpened: false,
      price: "",
      smsCountByCountryPrice: 0,
      unitSmsPrice: 0,
      priceHasError: false,
    };
  }

  componentWillMount() {
    this.updatesOnChangeCountry("BE");
  }

  handlePriceChange(e) {
    const price = e.target.value;

    let pattern = new RegExp(PRICE_VALIDATION_REGEX);
    let isValid = pattern.test(price) && price.length > 0;
    this.setState({ price, priceHasError: !isValid });

    if (this.state.unitSmsPrice != 0 && isValid) {
      this.setState({ smsCountByCountryPrice: parseInt(price / this.state.unitSmsPrice) });
    } else {
      this.setState({ smsCountByCountryPrice: 0 });
    }
  }

  onSelectCountry(countryCode) {
    this.updatesOnChangeCountry(countryCode);
  }

  updatesOnChangeCountry(countryCode) {
    const { dispatch } = this.props;

    dispatch(getCountrySmsPrice(countryCode)).then(res => this.setState({
      unitSmsPrice: res.value.data.data.price,
      smsCountByCountryPrice: parseInt(this.state.price / res.value.data.data.price)
    }), err => onError());
  }

  saveOrderPrice() {
    const { price, priceHasError } = this.state;
    if (priceHasError || !price) {
      return;
    }
    const { dispatch } = this.props;
    let notification = { body: 'requestSent', title: 'sendRequestSuccess' };
    dispatch(persistSMSOrderPrice(price)).then(res => onSuccess(res, notification), err => onError(err));
    this.setState({ price: 0, smsCountByCountryPrice: 0 });
    this.props.onCloseSideBar();
  }


  render() {
    let { opened, onCloseSideBar } = this.props;
    let { price, smsCountByCountryPrice, unitSmsPrice, priceHasError } = this.state;
    return (
      <div className={(opened ? "sidebar sidebar--opened" : "sidebar")}>

        <span onClick={onCloseSideBar} className="sidebar__close"><i className="icomoon icon-tt-close"></i></span>

        <div className="sidebar__form">
          <h4 className="sidebar__header">+ {_('Buy credit')}</h4>
          <div className="row row-top">
            <div className="columns small-12 columns-padding">
              <div className="sidebar__form-control">
                <h6>{_('buyCreditDesc')}</h6>
                <p>{_('buyCreditInfo')}</p>
              </div>

            </div>
            <div className="columns small-12 columns-padding">
              <div className="sidebar__form-control">
                <div className="small-12 margin-left-auto">
                  <div className="input-price">
                    <input className={price && price.trim().length ? "filled-box" : "empty-box"} type="text" placeholder="0" onChange={this.handlePriceChange.bind(this)} value={price} />
                    <span className="static-value">€</span>
                    <span className="unit-price">{unitSmsPrice} € / SMS</span>
                  </div>


                  <span className={priceHasError ? 'form-error is-visible' : 'form-error'}> {_('invalid price')}</span>
                  <div className="row small-7 columns">
                    <ReactFlagsSelect
                      defaultCountry="BE"
                      onSelect={this.onSelectCountry.bind(this)}
                      selectedSize={18}
                      optionsSize={16}
                      searchable={true}
                      className={`menu-flags ${smsCountByCountryPrice ? "filled-box" : "empty-box"}`}
                    />
                  </div>
                  <div className="sidebar__form-control">
                    <div className="sms-counter">
                      <span>{smsCountByCountryPrice}</span> SMS
                                        </div>
                  </div>
                  <div className="row small-7 columns">
                    <a className="order-button hollow button" onClick={this.saveOrderPrice.bind(this)}>{_(' Request')}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
