import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { Transition } from 'react-transition-group';
import _ from 'i18n';
import { setThemeScope, setThemesPaginationPage } from 'actions';
import TTPTab from '../../ui/tabs/TTPTab';
import { SimpleTheme } from './Theme';
import ThemeFetching from 'fetching/ThemeFetching';
import NotFound from 'notFound/NotFound';
import { SCOPE_TABS, COMMUNITY_SCOPE, TAMTAM_SCOPE } from "../../../constants";

class ThemePicker extends Component {

  state = {
    currentTab: "COMMUNITY",
    hasMoreItems: true
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.language != this.props.language || prevState.currentTab != this.state.currentTab) {
      this.setState({ hasMoreItems: true });
    }
  }

  switchTab = (currentTab) => {
    this.setState({ currentTab });
    this.props.setThemeScope((currentTab == "COMMUNITY") ? COMMUNITY_SCOPE : TAMTAM_SCOPE);
  }

  loadItems = () => {
    const { filter, fetching, nbResult, themes, setThemesPaginationPage } = this.props;

    if (!fetching && themes.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      setThemesPaginationPage(currentPage + 1);
    }
  }

  applyTheme = (theme) => {
    const { onSelect, selectedTheme } = this.props;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onSelect((selectedTheme && selectedTheme.id == theme.id) ? null : theme);

  }

  renderThemes() {
    const { themes, fetching, language, selectedTheme } = this.props;
    const { hasMoreItems } = this.state;
    if (!language) {
      return null
    }
    let items = [];
    if (themes && themes.length > 0) {
      for (let theme of themes) {
        items.push(<SimpleTheme
          key={`theme-${theme.id}`}
          clicked={(e) => { e.preventDefault(); this.applyTheme(theme); }}
          preview={theme.preview}
          name={theme.name}
          checked={selectedTheme && selectedTheme.id == theme.id}
        />);
      }
    }
    if (fetching) {
      for (let index = 0; index < 4; index++) {
        items.push(<ThemeFetching key={`theme-fetching-${index}`} />);
      }
    }

    return (items.length > 0) ? <InfiniteScroll
      className={`row theme-picker__list`}
      pageStart={0}
      loadMore={this.loadItems}
      hasMore={hasMoreItems}>
      {items}
    </InfiniteScroll> : <NotFound />;
  }

  render() {
    const { currentTab } = this.state;
    return (
      <div id="theme-picker">
        <Transition in={this.props.language != null}
          timeout={{ enter: 500, exit: 100 }}
          mountOnEnter
          unmountOnExit>
          {state => (<div key={`expander-${this.props.language}`} className={`theme-picker ${state === 'entering' && 'theme-picker__open'}`}>
            <TTPTab tabs={SCOPE_TABS} type="simple" activeTab={currentTab} selectTab={this.switchTab} />
            {this.renderThemes()}
          </div>)}
        </Transition>
      </div>
    )
  }

}
const mapStateToProps = (store) => ({
  themes: store.themes.list.items,
  fetching: store.themes.list.fetching,
  nbResult: store.themes.list.nbResult,
  filter: store.themes.filter,
});
const mapDispatchToProps = (dispatch) => ({
  setThemeScope: (scope) => dispatch(setThemeScope(scope)),
  setThemesPaginationPage: (page) => dispatch(setThemesPaginationPage(page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ThemePicker);
