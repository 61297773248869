import axios from '../axios-api';

export const getArticles = ({
  token,
  clientId,
  pageSize,
  paginationPage,
  searchWord,
  language,
  tags,
  categories,
  articlePages,
  params
}) => {

  let filter = [
    {
      property: "status",
      value: "PUBLISHED",
      operator: "like"
    },
    {
      property: "isPrivate",
      value: 0,
      operator: "eq"
    }
  ];

  if (clientId || (params && params.clientId)) {
    filter.push({
      property: "organization.id",
      value: (params && params.clientId) ? params.clientId : clientId,
      operator: "eq"
    });
  }

  if (searchWord) {
    filter.push({
      property: "word",
      operator: "like",
      value: searchWord
    });
  }

  if (language && language.length > 0) {
    filter.push({
      property: "language",
      value: language,
      operator: "in"
    })
  }

  if (tags && tags.length > 0) {
    filter.push({
      property: "tag.id",
      operator: "in",
      value: tags
    })
  }

  if (categories && categories.length > 0) {
    filter.push({
      property: "category.id",
      operator: "in",
      value: categories
    })
  }

  if (articlePages && articlePages.length > 0) {
    filter.push({
      property: "page.id",
      operator: "in",
      value: articlePages
    })
  }

  if (params && params.articlesIds && params.articlesIds.length > 0) {
    filter.push({
      property: "id",
      operator: "in",
      value: params.articlesIds
    })
  }

  const sort = [
    {
      property: "publishedAt",
      dir: "desc"
    }
  ];
  const fields = [
    "*",
    "url",
    "author",
    "introduction",
    "main_media",
    "tags",
    "category",
    "image_reduced",
    "organization",
    "avatars",
    "albums",
    "pages",
  ];
  const requestUrl = `/blog/article`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit: pageSize,
        start: (paginationPage - 1) * pageSize
      }
    });
};


export const getArticlesCategories = ({ token, lng = "fr", searchWord = null }) => {
  const requestUrl = `/blog/category`;

  let filter = [];

  if (null !== searchWord) {
    filter = [
      {
        property: "name" + lng.charAt(0).toUpperCase() + lng.slice(1),
        value: searchWord,
        operator: "like"
      }
    ];
  }
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: "id, nameFr, nameEn, nameNl, colorCode",
      filter: JSON.stringify(filter)
    }
  });
};

export const getArticlePages = ({ token, clientId }) => {
  const requestUrl = `/organization/user/community-pages`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: "id, title, language",
      "communitiesIds[]": clientId
    }
  });
};

export const getBlogTags = ({ token, lng = 'fr', searchWord = null }) => {
  const requestUrl = `/blog/tag`;

  let filter = [];

  if (searchWord !== null) {
    filter = [
      {
        property: `name${lng.charAt(0).toUpperCase()}${lng.slice(1)}`,
        value: searchWord,
        operator: 'like',
      },
    ];
  }
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: 'id, nameFr, nameEn, nameNl',
      filter: JSON.stringify(filter),
    },
  });
};
