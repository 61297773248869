import React, { PureComponent } from "react";
import { modalStyle, replaceEmailContent } from "../../../../services/utils";
import { connect } from 'react-redux';

import TTPModal from "../../../ui/TTPModal";
import _ from 'i18n';
import { extractLinks } from "../../../../services/link";
import LinkItem from "./LinkItem";
import { setCurrentDndTheme, setCurrentEmailCampaignData } from "../../../../actions/actions";

class LinksInventoryModal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      links: [],
      editedIndex: null
    }
  }

  componentDidMount() {
    const links = extractLinks(this.getTemplateHtml());

    this.setState({ links });
  }

  getTemplateHtml() {
    const { object, mutatedContent, html, type } = this.props;
    let content = html;
    if ('PLAIN_TEXT' == type) {
      content = replaceEmailContent({ body: html, subject: object }, mutatedContent);
    }
    return content;
  }

  handleLinkClick = (index) => {
    this.setState({
      editedIndex: index
    });
  }

  handleCancel = () => {
    this.setState({
      editedIndex: null
    });
  }

  replaceAtOccurence = (content, oldValue, newValue) => {
    if (!content) {
      return content;
    }
    const isJson = content !== 'string';
    let text = isJson ? JSON.stringify(content) : content;

    const newText = text.replaceAll(oldValue, newValue)

    return isJson ? JSON.parse(newText) : newText;
  }

  handleSave = (oldLink, newLink) => {
    const { html, design, setCurrentCampaign } = this.props;
    const { editedIndex, links } = this.state;
    const newHtml = this.replaceAtOccurence(html, oldLink, newLink);

    const newDesign = this.replaceAtOccurence(design, oldLink, newLink);
    if (newDesign) {
      this.props.setCurrentDndTheme({ design: newDesign });
    }
    setCurrentCampaign({ html: newHtml, design: newDesign });

    if (editedIndex != null && links[editedIndex]) {
      const newLinks = [...links];
      newLinks[editedIndex].link = newLink;

      this.setState({
        editedIndex: null,
        links: newLinks
      })
    }
  }

  render() {
    const customModalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle.content,
        minHeight: '20rem',
        maxHeight: '95%',
        width: '70%',
        padding: 0,
      }
    };

    const { onClose } = this.props;

    return (
      <TTPModal isOpen={true} onClose={onClose}
        modalStyle={customModalStyle} title={_('links inventory')}>
        <div id="list-form" className="p-b-s p-t-s">
          <div className="row align-center">
            <div className="ttp-datatable small-10 links-inventory">
              <div className="row ttp-datatable__header">
                <div className="small-5 p-l-xs p-r-xs">{_('label')}</div>
                <div className="small-7 p-l-xs p-r-xs">{_('link')}</div>
              </div>
              {
                this.state.links?.length > 0 ?
                  this.state.links.map(({ label, link }, index) => {
                    return (
                      <LinkItem
                        key={`link-${index}`}
                        label={label}
                        link={link}
                        onClick={() => this.handleLinkClick(index)}
                        onCancel={this.handleCancel}
                        onSave={(newLink) => this.handleSave(link, newLink)}
                        editMode={index === this.state.editedIndex} />
                    )
                  }) :
                  <div className="row ttp-datatable__row">
                    {_('no link found')}
                  </div>
              }
            </div>
          </div>
        </div>
      </TTPModal>
    )
  }

}

const mapStateToProps = state => ({
  type: state.currentCampaign.email.type,
  mutatedContent: state.currentCampaign.email.mutatedContent,
  html: state.currentCampaign.email.html,
  design: state.currentCampaign.email.design,
  object: state.currentCampaign.email.object,
})
const mapDispatchToProps = dispatch => ({
  setCurrentCampaign: data => dispatch(setCurrentEmailCampaignData(data)),
  setCurrentDndTheme: (data = null) => dispatch(setCurrentDndTheme(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LinksInventoryModal);
