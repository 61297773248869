import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import 'react-notifications/lib/notifications.css';

import { CAMPAIGN_TYPES } from 'Common';
import ParamsTab from 'campaign/params/ParamsTab';
import RecipientsTab from 'campaign/recipients/RecipientsTab';
import CampaignWeb from 'campaign/web/CampaignWeb';
import CampaignText from 'campaign/text/CampaignText';
import TestTab from 'campaign/test/TestTab';
import SendTab from 'campaign/send/SendTab';
import Bubbles from 'campaign/actions/Bubbles';
import ReviewsTab from 'campaign/review/ReviewsTab';
import ManualRecipients from 'campaign/recipients/ManualRecipients';
import Notes from 'campaign/note/Notes';
import Preview from './preview/Preview';

import { setCurrentEmailCampaignData } from "actions";
import { persistCampaign } from 'thunks';
import { onError, onSuccess } from 'utils';

import _ from 'i18n';
import TTPLoader from '../ui/TTPLoader';
import { hasValidLength, isValidEmail } from '../../services/utils';
import { NotificationManager } from 'react-notifications';
import {fetchMembrshipClientsList} from "thunks";

@connect((store) => {
  return {
    campaigns: store.campaigns.list.items,
    updating: store.currentCampaign.email.updating,
    currentSideBar: store.currentCampaign.email.currentSideBar,
    allowedGroups: store.currentCampaign.email.allowedGroups,
    deniedGroups: store.currentCampaign.email.deniedGroups,
    fetching: store.currentCampaign.email.fetching,
    type: store.currentCampaign.email.type,
    status: store.currentCampaign.email.status,
    from: store.currentCampaign.email.from,
    fromName: store.currentCampaign.email.fromName,
    object: store.currentCampaign.email.object,
    pageUrl: store.currentCampaign.email.pageUrl,
    recipientType: store.currentCampaign.email.recipientType,
    formulas: store.currentCampaign.email.formulas,
    year: store.currentCampaign.email.year,
    statut: store.currentCampaign.email.statut,
    source: store.currentCampaign.email.source,
    language: store.currentCampaign.email.language,
    html: store.currentCampaign.email.html,
    lastTestVersion: store.currentCampaign.email.lastTestversion,
  }
}, (dispatch) => ({
  setCurrentCampaign: data => dispatch(setCurrentEmailCampaignData(data)),
  persistCampaign: data => dispatch(persistCampaign(data)),
  fetchMembrshipClientsList: (data, withoutPagination) => dispatch(fetchMembrshipClientsList(data, withoutPagination)),
  onManualRecipientsChange: (manualRecipients) => dispatch(setCurrentEmailCampaignData({ manualRecipients })),
}))
export default class CampaignPage extends Component {

  componentDidMount() {
    if (this.props.childRef) {
      this.props.childRef({ primaryAction: this.saveCampaign, isUpdating: this.isUpdating, sendAction: this.onSendAction });
    }
    this.props.triggerRender && this.props.triggerRender();
  }

  componentWillUnmount() {
    let bodyTag = document.getElementsByTagName('body');
    bodyTag[0].style.overflow = 'unset';
  }

  componentDidUpdate(prevProps) {
    const { updating } = this.props;
    if (updating !== prevProps.updating) {
      this.props.triggerRender && this.props.triggerRender();
    }
  }

  toggleSideBar = (sidebar) => {
    this.props.setCurrentCampaign({ currentSideBar: sidebar });
    let bodyTag = document.getElementsByTagName('body');
    if (sidebar) {
      bodyTag[0].style.overflow = 'hidden';
    } else {
      bodyTag[0].style.overflow = 'unset';
    }
  };

  mapCampaignGroupIds(campaignGroups) {
    let oldCampaignGroups = this.props.oldCampaignGroups;
    if (!oldCampaignGroups || !oldCampaignGroups.length) {
      return campaignGroups
    }
    oldCampaignGroups.forEach((oldCampaignGroup) => {
      for (let i = 0; i < campaignGroups.length; i++) {
        if (campaignGroups[i].group == oldCampaignGroup.group.id && campaignGroups[i].status == oldCampaignGroup.status) {
          campaignGroups[i].id = oldCampaignGroup.id;
        }
      }
    });
    return campaignGroups;
  }

  getCampaignGroups() {
    let campaignGroups = [];
    let { allowedGroups, deniedGroups } = this.props;
    if (!(allowedGroups instanceof Array)) {
      allowedGroups = allowedGroups.split(',');
    }
    if (!(deniedGroups instanceof Array)) {
      deniedGroups = deniedGroups.split(',');
    }
    if (allowedGroups.length > 0) {
      for (let i = 0; i < allowedGroups.length; i++) {
        if (allowedGroups[i]) {
          campaignGroups.push({ "group": allowedGroups[i], "status": "INCLUDE" });
        }
      }
    }
    if (deniedGroups.length > 0) {
      for (let i = 0; i < deniedGroups.length; i++) {
        if (deniedGroups[i]) {
          campaignGroups.push({ "group": deniedGroups[i], "status": "EXCLUDE" });
        }
      }
    }
    return this.mapCampaignGroupIds(campaignGroups);
  }

  canSaveData = () => {
    let { from, fromName, object, pageUrl, language, type, html } = this.props;
    let hasValidContent = (type == 'WEB_PAGE') ? (pageUrl && pageUrl.length > 0) : html;
    return isValidEmail(from) && hasValidLength(fromName, 3) && hasValidLength(object, 5, 200)
      && language && hasValidContent;
  }

  saveCampaign = async (action = 'DRAFT', sendTestOptions) => {
    let {recipientType, formulas, source, year, statut} = this.props;
    if (!this.canSaveData()) {
      NotificationManager.info(_('please fill in all the required fields'));
      return;
    }

    let data = {
      'campaignGroups': this.getCampaignGroups(), action
    };

    let notification = {body: 'campaignAdded', title: 'addCampaignSuccess'};

    if (action == 'TEST') {
      data.sendTestOptions = sendTestOptions;
      notification = {body: 'campaignTestSent', title: 'campaignTestSuccess'};
    } else if (action == 'SEND') {
      data.status = 'CREATED';
      if (recipientType === 'OECCBB_MEMBERS') {
        await this.props.fetchMembrshipClientsList({
          year,
          source,
          formulas,
          status: statut
        }, true);
      }
      notification = {body: 'campaignSent', title: 'campaignSentSuccess'};
    }

    if (action === 'DRAFT' && this.props.lastTestVersion) {
      data.status = 'TEST';
    }

    this.props.persistCampaign(data).then(res => onSuccess(res, notification), err => onError(err));
  }

  renderEditor = () => {
    let { type } = this.props;

    let editor = null;
    if (['DND_TEMPLATE', 'WEB_PAGE'].indexOf(type) > -1) {
      editor = <CampaignWeb />
    } else if (type == 'PLAIN_TEXT') {
      editor = <CampaignText />
    }

    return editor;
  }

  isUpdating = () => {
    return this.props.updating;
  };

  onSendAction = () => {
    if (!this.canSaveData()) {
      NotificationManager.info(_('please fill in all the required fields'));
      return;
    }
    this.toggleSideBar("SEND");
  };

  render() {
    let { currentSideBar, status, type, fetching } = this.props;

    if (["DRAFT", "TEST", "PAUSE"].indexOf(status) == -1 || (type && Object.values(CAMPAIGN_TYPES).indexOf(type) == -1)) {
      return <Redirect to={{ pathname: '/campaigns' }} />;
    }

    return (
      <div id="campaign-page">
        <div className="campaign-body">
          {(!type || fetching) ?
            <TTPLoader /> :
            <Fragment>
              {this.renderEditor()}
              <Preview />
            </Fragment>
          }
        </div>
        <Bubbles
          onOpenSideBar={this.toggleSideBar}
          sendingDisabled={!this.canSaveData()}
        />
        <div className="overlay" style={(currentSideBar ? { display: "block" } : { display: "none" })} onClick={() => this.toggleSideBar(null)} />
        <div className={(currentSideBar ? "sidebar sidebar--opened" : "sidebar")}>
          <Notes
            onToggleSideBar={this.toggleSideBar}
          />
          <ParamsTab />
          <ManualRecipients />

          <RecipientsTab />

          <TestTab
            onConfirm={this.saveCampaign.bind(this, "TEST")}
          />
          <ReviewsTab />

          <SendTab
            onConfirm={this.saveCampaign.bind(this, "SEND")}
            goPrevious={this.props.goPrevious}
            onRef={ref => (this.child = ref)}
          />
        </div>
      </div>
    );
  }
}
