const initialState = {
  selectedUsers: [],
  role: 'USER',
  allowCreateCampaign: false,
  allowSendCampaign: false,
  allowCreateSms: false,
  allowSendSms: false,
  filter: {
    role: null,
    searchWord: '',
  }
}

export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_USERS_SETTINGS': {
      return initialState;
      break;
    }
    case 'SET_SELECTED_USERS': {
      return { ...state, selectedUsers: action.selectedUsers };
      break;
    }
    case 'SET_ROLE': {
      return { ...state, role: action.role };
      break;
    }
    case 'SET_ALLOW_CREATE_CAMPAIGN': {
      return { ...state, allowCreateCampaign: action.allowCreateCampaign };
      break;
    }
    case 'SET_ALLOW_SEND_CAMPAIGN': {
      return { ...state, allowSendCampaign: action.allowSendCampaign };
      break;
    }
    case 'SELECT_ROLE': {
      return { ...state, filter: { ...state.filter, role: action.role } };
      break;
    }
    case 'SELECT_SEARCH_WORD': {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord } };
      break;
    }
    case 'SET_ALLOW_CREATE_SMS': {
      return { ...state, allowCreateSms: action.allowCreateSms };
      break;
    }
    case 'SET_ALLOW_SEND_SMS': {
      return { ...state, allowSendSms: action.allowSendSms };
      break;
    }
    default:
      return state;
  }
};
