import React, { Component } from 'react';
import { S3_FOLDER_URL_PROD } from 'Config';
import { SUPPORTED_APPS } from '../../services/apps';
import _ from "i18n";

export default class Application extends Component {

  render() {
    let { name, logo } = this.props;
    if(!logo) {
      const app = SUPPORTED_APPS.find((app) => (app.name == name || app.code == name));
      if (app) {
        logo = app.logo || (name ? name.toLowerCase() : 'emailing');
      }
    }

    return (
      <div className="application">
        <img alt="" className="application__logo"
          src={`${S3_FOLDER_URL_PROD}/apps/${logo}.png`} />
        <span className="application__name m-l-xs">{name ? _(name.toLowerCase()) : 'EMAILING'}</span>
      </div>
    );
  }
}
