import * as actions from 'actions';
import { persistEmailsSettings, getGenericEmails, getEmailsSettings, persistGenericEmail, sendTestEmails, getOrganisationEmailsSettings, resetCurrentGenericEmail } from 'TamtamAPI';
import { ucFirst } from '../../../services/utils';

export const saveEmailsSettings = (action = null) => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    const { emails, ...data } = getState().genericEmail.currentSetting;

    if (action) {
      data['action'] = action;
    }

    if (data.targetApp === "PAYMENT" && ["EVENT_REMINDER", "EVENT_REVIVAL"].indexOf(data.target.id) > -1){
      data.types = data.types.filter(function (type) {
        if (data.target.id === "EVENT_REVIVAL") {
          return type.id.search("RELANCE") > -1
        } else {
          return type.id.search("RELANCE") < 0
        }
      });
    }

    data['organization'] = currentClient.id;
    return dispatch(actions.saveEmailsSettings(
      persistEmailsSettings(token, data)
    ));
  };
};

export const fetchEmailSettings = (filter) => {
  return (dispatch, getState) => {
    const { token, currentClient: { id } } = getState().auth;

    const data = { organization: id, ...filter };
    return dispatch(actions.fetchCurrentEmailingSetting(getEmailsSettings(token, data)));
  };
};

export const fetchGenericEmails = (filters) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.fetchGenericEmails(getGenericEmails(token, filters)));
  };
};

export const saveCurrentGenericEmail = (data = {}) => {
  return (dispatch, getState) => {
    const { token, user } = getState().auth;
    const { currentSetting } = getState().genericEmail;
    data.updatedBy = user.id;
    let params = { ...currentSetting.currentEmail, ...data };

    const lng = ucFirst(params.language);
    const defaultTheme = currentSetting[`theme${lng}`];
    const currentTheme = params.theme;

    const hasDefaultTheme = currentTheme && currentTheme.id && defaultTheme && defaultTheme.id == currentTheme.id;
    if (hasDefaultTheme) {
      params.theme = null;
    }
    return dispatch(actions.saveCurrentGenericEmail(
      persistGenericEmail(token, params)
    ));
  };
};

export const sendGenericEmailTest = (data) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    return dispatch(actions.sendGenericEmailTest(
      sendTestEmails(token, data)
    ));
  };
};

export const fetchOrganisationEmailsSettings = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    const { pageSize, paginationPage } = getState().genericEmail.settings;
    const { targetApp } = getState().genericEmail.settings.filter;
    const { status } = getState().genericEmail.settings.filter;

    return dispatch(actions.fetchOrganisationEmailsSettings(
      getOrganisationEmailsSettings(token, currentClient.id, status, pageSize, targetApp, paginationPage) //API TAMTAM
    ));
  };
};

export const resetGenericEmail = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { currentEmail } = getState().genericEmail.currentSetting;
    const { ...data } = currentEmail;
    return dispatch(actions.saveCurrentGenericEmail(
      resetCurrentGenericEmail(token, data.id)
    ));
  };
};
