import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';

import SelectLanguages from 'common/SelectLanguages';

import { saveMedia } from 'thunks';
import _ from 'i18n';
import { onSuccess, onError } from 'utils';

@connect()
export default class ImageUploader extends Component {

  constructor(props) {
    super(props);
    this.state = { attachment: null, label: "", language: "" };
    this.handleAttachmentChange = (attachment) => this.setState({ attachment });
    this.handleLanguageChange = (e) => this.setState({ language: e.target.value });
    this.handleLabelChange = (e) => this.setState({ label: e.target.value });
  }

  handleDrop(acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length === 0) return;
    this.setState({ attachment: acceptedFiles[0] });
  }

  saveAttachment() {
    let { attachment, label, language } = this.state;
    if (!label) {
      document.getElementById('img-label').classList.add('border-danger');
    }
    if (!attachment || !label) {
      return;
    }
    this.props.dispatch(saveMedia({ attachment, label, language }))
      .then(res => this.onSuccessUpload(res), err => onError(err));
  }

  onSuccessUpload(res) {
    onSuccess(res);
    this.setState({ attachment: null, label: "", language: "" });
    this.props.closeUploadForm();
  }

  renderUploadForm() {
    let { attachment, label, language } = this.state;

    return <div className="upload-img-form">
      <h3 className="upload-img-form__header">{_('Add an image to the library')}</h3>
      <div className="upload-img-form__label">{_('label')}*</div>
      <input id="img-label" type="text" value={label} onChange={this.handleLabelChange} />
      <div className="upload-img-form__label">{_('language')}</div>
      <SelectLanguages language={language} onLanguageChange={this.handleLanguageChange} all={true} />
      <div className="attachments-container">
        <Dropzone className="attachments-dropzone"
          onDrop={this.handleDrop.bind(this)}
          multiple={false}
          accept={'image/jpeg, image/png'}
        >
          <div>
            <span className="icon icon-cloud-upload"></span>
            {(attachment) ?
              <div>{attachment.name}
                <span className="icon icon-trash" onClick={() => { this.handleAttachmentChange(null); return false; }}></span>
              </div> :
              <p>{_('Faites glisser et déposez une image')}</p>
            }
          </div>
        </Dropzone>
      </div>
      <button id="save-btn" className="btn primary" onClick={this.saveAttachment.bind(this)}>{_('save')}</button>
    </div>;
  }
  render() {
    const { isActive } = this.props;
    return <div className={`style-tools-palette column small-7 add-bloc-overflow-y ${(isActive) ? "" : "start"}`}>
      {isActive && this.renderUploadForm()}
    </div>;
  }
}
