export const initApprovalTeams = (resp) => {
  return {
    type: "INIT_APPROVAL_TEAMS",
    payload: resp,
  };
};

export const saveApprovalResponse = (resp) => {
  return {
    type: "SAVE_APPROVAL_REQUEST",
    payload: resp,
  };
};

export const initApprovalFeedback = (resp) => {
  return {
    type: "INIT_APPROVAL_FEEDBACK",
    payload: resp,
  };
};

export const fetchApprovalFeedback = (resp) => {
  return {
    type: "FETCH_APPROVAL_FEEDBACK",
    payload: resp,
  };
};

export const saveApprovalTeam = (resp) => {
  return {
    type: "SAVE_APPROVAL_TEAM",
    payload: resp,
  };
};

export const fetchApprovalTeams = (resp, mode) => {
  return {
    type: "PUSH" == mode ? "PUSH_APPROVAL_TEAMS" : "FETCH_APPROVAL_TEAMS",
    payload: resp,
  };
};

export const deleteApprovalTeam = (resp) => {
  return {
    type: "DELETE_APPROVAL_TEAM",
    payload: resp,
  };
};

export const setApprovalTeamsPaginationPage = (pageNumber) => {
  return {
    type: "SET_APPROVAL_TEAMS_PAGINATION_PAGE",
    pageNumber,
  };
};

export const updateDefaultApprovalTeam = (resp) => {
  return {
    type: "UPDATE_DEFAULT_APPROVAL_TEAM",
    payload: resp,
  };
};
