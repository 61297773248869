import * as actions from "actions";
import {
  persistApprovalResponse,
  getApprovalFeedback,
  persistTeam,
  getClientApprovalTeams,
  deleteTeam,
  updateDefaultTeam
} from "TamtamAPI";
import { pushSourceToken } from "utils";
import { getHistory } from "../../router/getHistory";

export const saveApprovalResponse = (data, handleSetCurrentPage) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { isUaNewsletter } = getState().currentCampaign.uaNewsletter;

    return dispatch(
      actions.saveApprovalResponse(
        persistApprovalResponse(token, data).then(
          (res) => {
            if (isUaNewsletter) {
              handleSetCurrentPage("LIST_PAGE");
            } else {
              const history = getHistory();
              history.push("/");
            }
            return res.data.data;
          },
          (err) => err,
        ),
      ),
    );
  };
};

export const fetchApprovalFeedback = (data) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.fetchApprovalFeedback(getApprovalFeedback(token, data)),
    );
  };
};

export const saveApprovalTeam = (data) => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    data = { ...data, clientId: currentClient.id };

    return dispatch(actions.saveApprovalTeam(persistTeam(token, data)));
  };
};

export const fetchApprovalTeams = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    let { paginationPage, items } = getState().approval.teams;
    let pageSize = getState().approval.teams.pageSize;

    let sourceToken = pushSourceToken("FETCH_APPROVAL_TEAMS", dispatch);

    let mode = paginationPage == 1 ? "FETCH" : "PUSH";

    return dispatch(
      actions.fetchApprovalTeams(
        getClientApprovalTeams(
          token,
          items ? items.length : 0,
          pageSize,
          currentClient.id,
          sourceToken,
        ),
        mode,
      ),
    );
  };
};

export const deleteApprovalTeam = (teamId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.deleteApprovalTeam(deleteTeam(token, teamId)));
  };
};

export const updateDefaultApprovalTeam = (teamId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    return dispatch(actions.updateDefaultApprovalTeam(updateDefaultTeam(token, teamId)));
  };
};
