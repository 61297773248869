export const setExpiresIn = (expiresIn) => {
  return {
    type: "SET_EXPIRES_IN",
    expiresIn,
  };
};

export const setTokenCreatedAt = (createdAt) => {
  return {
    type: "SET_TOKEN_CREATED_AT",
    createdAt,
  };
};

export const setAuthUser = (user) => {
  return {
    type: "SET_AUTH_USER",
    user,
  };
};

export const fetchAuthUser = (user) => {
  return {
    type: "FETCH_AUTH_USER",
    payload: user,
  };
};

export const setAuthToken = (token) => {
  return {
    type: "SET_AUTH_TOKEN",
    token,
  };
};

export const setAuthLoggedAs = (loggedAs) => {
  return {
    type: "SET_AUTH_LOGGED_AS",
    loggedAs,
  };
};

export const setCurrentClient = (client) => {
  return {
    type: "SET_CURRENT_CLIENT",
    payload: client,
  };
};

export const switchToDetachedMode = () => {
  return {
    type: "SET_DETACHED_FROM_CLIENT",
  };
};

export const fetchClientSettings = (resp) => {
  return {
    type: "FETCH_CLIENT_SETTINGS",
    payload: resp,
  };
};

export const saveClientSettings = (resp) => {
  return {
    type: "SAVE_CLIENT_SETTINGS",
    payload: resp,
  };
};
export const setUAHeader = (uaWithoutHeader) => {
  return {
    type: "SET_UA_WITHOUT_HEADER",
    uaWithoutHeader,
  };
};
