import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loaders';
import NotFound from 'notFound/NotFound';
import { fetchMedias, fetchTagsSuggestions } from 'thunks';
import {
  setMediasPaginationPage, initMediasFilters, setMediasSearchWord,
  setMediasLanguage, setMediasTags, setMediasScope
} from 'actions';
import TTPFilterHorizontalBar from '../../common/filter/TTPFilterHorizontalBar';
import { getLanguages } from 'utils';
import _ from 'i18n';

@connect((store) => {
  return {
    images: store.medias.items,
    nbResult: store.medias.nbResult,
    fetching: store.medias.fetching,
    origin: store.medias.origin,
    filter: store.medias.filter,
    lng: store.params.lng
  }
})
export default class ImageGallery extends PureComponent {

  state = {
    hasMoreItems: false,
    isFilterFormVisible: false,
  }

  constructor(props) {
    super(props);

    this.languageOptions = getLanguages().map(lng => {
      return { value: lng.key, label: _(lng.value) }
    });

    this.originOptions = [
      { value: true, label: "media app" },
      { value: false, label: "others" },
    ];

    this.scopeOptions = [
      { value: "private", label: _("private") },
      { value: "public", label: _("public") },
    ];
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMedias());

    this.updateListHeight();
  }

  componentWillReceiveProps(nextProps) {
    const { fetching } = nextProps;

    if (fetching != this.props.fetching) {
      this.setState({
        hasMoreItems: !fetching,
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { filter, origin, dispatch } = prevProps;

    if (filter != this.props.filter || origin != this.props.origin) {
      dispatch(fetchMedias());
    }
  }

  selectImage = (id, imgUrl) => {
    let url = imgUrl;
    if (this.refs["img-" + id].naturalWidth > 800 && url.search("s3.tamtam.pro") > -1) {
      url += "/w800.jpg";
    }
    this.props.done(url);
  };

  handleSearchInputChange(word) {
    this.props.dispatch(setMediasSearchWord(word));
  };

  loadItems = () => {
    const { filter, dispatch, fetching, nbResult, images } = this.props;

    if (!fetching && images.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      dispatch(setMediasPaginationPage(currentPage + 1));
    }
  }

  handleSelectLanguage = (selected) => {
    this.props.dispatch(setMediasLanguage(selected))
  };

  handleTagsChange = (selected) => {
    this.props.dispatch(setMediasTags(selected));
  };

  handleSelectScope = (selected) => {
    this.props.dispatch(setMediasScope(selected))
  }

  handleResetFilter = () => {
    this.props.dispatch(initMediasFilters());
  }

  loadTagsSuggestions = (searchWord, callback) => {
    let { lng } = this.props;
    lng = lng.charAt(0).toUpperCase() + lng.slice(1);
    this.props.dispatch(fetchTagsSuggestions({ searchWord, lng }))
      .then(res => {
        const tags = res.value.data.data.map(t => { return { value: t.id, label: t["name" + lng], color: "#000000" } })
        callback(tags);
      }).catch(er => {
        callback([]);
      })
  }

  filterFormIsVisible = (isFilterFormVisible) => {
    this.updateListHeight(isFilterFormVisible);
  }

  updateListHeight = (isFilterFormVisible = false) => {
    if (isFilterFormVisible) {
      this.scrollParentRef.style.height = (0.9 * window.innerHeight - 216) + "px";
    } else {
      this.scrollParentRef.style.height = (0.9 * window.innerHeight - 143) + "px";
    }
  }

  render() {
    const { images, fetching, lng, origin, filter } = this.props;
    const { hasMoreItems } = this.state;
    let items = [[], [], [], []];
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const url = (origin == "MAILING") ? image.url : image.fullMediaUrl;
        const helpTitle = (origin == "MAILING") ? 'label' : `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

        items[i % 4].push(
          <div key={image.id} className="gallery__item" onClick={() => this.selectImage(image.id, url)}>
            <img ref={"img-" + image.id} src={url} />
            {image[helpTitle] && <p>{image[helpTitle]}</p>}
          </div>);
      }
    }
    if (fetching) {
      for (let i = 0; i < 4; i++) {
        items[i % 4].push(<div key={`item-${i}`} className="gallery__item gradiant gallery__fetching-item" >
          <Loader type="line-spin-fade-loader" />
        </div>)
      }
    }

    let options = [{ placeholder: _("language"), options: this.languageOptions, values: filter.language, action: this.handleSelectLanguage }];

    let asyncFilters = [];

    if (origin == "MEDIA") {
      asyncFilters.push({ placeholder: "tags", options: filter.tags, handleChange: this.handleTagsChange, values: filter.tags, loadSuggestions: this.loadTagsSuggestions });
      options.push({ placeholder: _("scope"), options: this.scopeOptions, values: filter.scope, action: this.handleSelectScope, isSimple: true });
    }

    return (
      <div>
        <div className="row small-12 m-l-s m-r-s">
          <TTPFilterHorizontalBar
            filters={options}
            searchInputValue={filter.searchWord}
            handleResetFilter={this.handleResetFilter}
            handleSearchInputChange={this.handleSearchInputChange.bind(this)}
            asyncFilters={asyncFilters}
            filterFormIsVisible={this.filterFormIsVisible}
            cssClass="small-12 light"
          />
        </div>
        <div style={{ "overflow": "auto", "padding": "0 1rem" }} ref={(ref) => this.scrollParentRef = ref}>
          {
            (items[0] && items[0].length > 0) ?
              <InfiniteScroll
                className="gallery row"
                pageStart={0}
                loadMore={this.loadItems}
                hasMore={hasMoreItems}
                useWindow={false}
                getScrollParent={() => this.scrollParentRef}>
                {items.map((item, index) => {
                  return (
                    <div key={index} className="small-3">{item}</div>
                  )
                })}
              </InfiniteScroll> : <NotFound />
          }
          {fetching && <div style={{ "textAlign": "center" }} key="loader-status" className={`small-12`}>
            <Loader type="ball-beat" />
          </div>}
        </div>
      </div>
    );
  }
}
