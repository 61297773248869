import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactModal from 'react-modal';

import { multiSaveTheme, fetchApprovalTeams } from 'thunks';
import { onError, onSuccess, modalStyle } from 'utils';
import UsersTagSuggestion from '../common/UsersTagSuggestion';
import _ from 'i18n';
import TTPSelect from "common/TTPSelect";

@connect((store) => {
  return {
    teams: store.approval.teams.items,
    themes: store.themes.list.items,
    updating: store.themes.list.updating
  }
})
export default class ThemePreferences extends Component {

  constructor(props) {
    super(props);
    this.toggleSwitch = () => this.setState({ allow: !this.state.allow, allowedUsers: [], selectedTeams: [] });
    this.handleTeamsChange = (selectedTeams) => this.setState({ selectedTeams });
    this.handleTagsChange = (allowedUsers) => this.setState({ allowedUsers });
    this.state = { allow: true, allowedUsers: [], selectedTeams: [] };
  }

  componentDidMount() {
    const { themesIds, themes, dispatch } = this.props;
    if (!themesIds || themesIds.length != 1) {
      return;
    }
    let items = themes.filter(item => item.id == themesIds[0]);
    if (items && items.length > 0) {
      let allowedUsers = (items[0].allowedUsers) ? items[0].allowedUsers : [];
      allowedUsers = allowedUsers.map((tag) => {
        return { userId: tag.id, name: `${tag.lastName.toUpperCase()} ${tag.firstName}` }
      });
      let selectedTeams = items[0].approvalTeams;
      this.setState({ selectedTeams, allowedUsers, allow: (!allowedUsers || allowedUsers.length == 0) });
    }

    dispatch(fetchApprovalTeams());
  }

  applyPreferences() {
    const { themesIds, dispatch, onCloseModal } = this.props;
    let { selectedTeams, allowedUsers } = this.state;
    if (!themesIds) {
      return;
    }
    if (selectedTeams && !(selectedTeams instanceof Array)) {
      selectedTeams = selectedTeams.split(',');
    }

    dispatch(multiSaveTheme({
      themesIds,
      allowedUsers: allowedUsers.map((tag) => tag.userId),
      approvalTeams: selectedTeams
    })).then(
      (res) => { onCloseModal(); onSuccess(res) },
      (err) => onError(err)
    )
  }

  renderTeamOption = (option) => {
    return <div className="select-option">
      <span className="select-option__label">{option.label}</span>
      <span className="select-option__content">{option.content} {_('members')}</span>
    </div>;
  }

  render() {
    const { onCloseModal, teams, updating } = this.props;
    const { allow, allowedUsers, selectedTeams } = this.state;

    return <ReactModal isOpen={true}
      contentLabel="Modal"
      style={modalStyle}
      onRequestClose={onCloseModal}
      shouldCloseOnOverlayClick={true}>
      <span onClick={onCloseModal} className="modal-close close"></span>
      <div className="templates-settings__body">
        <h3>{_('user preferences')} </h3>
        <div className="row small-10 align-center templates-settings__item">
          <div className="small-10"><h4>1- {_('Restrict the use of this template')}</h4></div>
          <div className="small-10 radio column">
            <label>
              <input type="radio" checked={allow} onChange={this.toggleSwitch} />
              <span className="ttp-radio"><i className="icon-radio-check"></i></span>
              <h5>{_('No, allow for all users')}</h5>
            </label>
          </div>
          <div className="small-10 radio column">
            <label>
              <input type="radio" checked={!allow} onChange={this.toggleSwitch} />
              <span className="ttp-radio"><i className="icon-radio-check"></i></span>
              <h5>{_('Yes, allow for certain users')} ...</h5>
            </label>
          </div>
          {!allow && <div className="small-10 column">
            <UsersTagSuggestion displayEmail={true} selectedTags={allowedUsers} onTagsChange={this.handleTagsChange} authorizedOnly={true} />
          </div>}
        </div>
        <div className="row small-10 align-center templates-settings__item">
          <div className="small-10"><h4>2- {_("Send campaigns's approvals requests to teams")}</h4></div>
          <div className="small-10 column">
            <TTPSelect
              values={selectedTeams}
              placeholder={_('Select approval teams')}
              options={teams.map(team => { return { ...team, detail: `${(team.members) ? team.members.length : 0} ${_('members')}` } })}
              onChange={(teams) => this.handleTeamsChange(teams)} />
          </div>
        </div>
      </div>
      <div className="templates-settings__footer">
        <button onClick={onCloseModal} className="btn secondary">{_('cancel')}</button>
        <button onClick={!updating && this.applyPreferences.bind(this)} className="btn primary">{updating ? _('Processing...') : _('Apply')}</button>
      </div>
    </ReactModal>
  }

}
