import * as actions from 'actions';
import { getEBox, getMailData, getEBoxAttachments, getEboxOwner, getCountStatus, deleteEBoxMail, getEBoxSms, getSmsData } from 'TamtamAPI';
import { pushSourceToken } from "utils";

export const fetchEBox = (inMyEbox = true) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let sourceToken = pushSourceToken('FETCH_E_BOX', dispatch);

    const { list: { filter }, owner } = getState().ebox;
    return dispatch(actions.fetchEBox(
      getEBox(token, { ...filter, eboxOwnerId: (!inMyEbox && owner && owner.data) ? owner.data.id : null }, sourceToken),
    ));
  };
};

export const getEBoxMailData = (mailId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    return dispatch(actions.getMailData(
      getMailData(token, mailId)
    ));
  };
};

export const fetchEBoxAttachments = (otherEboxId = null, filename = null) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { pageSize, paginationPage } = getState().ebox.attachmentsList;
    return dispatch(actions.fetchEBoxAttachments(
      getEBoxAttachments(token, otherEboxId, pageSize, paginationPage, filename),
    ));
  };
};

export const fetchEboxOwner = (memberId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.fetchEboxOwner(
      getEboxOwner(token, memberId)
    ));
  };
};

export const fetchEboxCountStatus = (otherEbox = true) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { owner, list: { filter } } = getState().ebox;
    const eboxOwnerId = otherEbox ? owner && owner.data && owner.data.id : null;
    return dispatch(actions.getMailsStatusCount(
      getCountStatus(token, { eboxOwnerId, isFolderEbox: filter.isFolderEbox }),
    ));
  };
};

export const deleteEBoxEmail = (id) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.deleteEmail(deleteEBoxMail(token, id)));
  };
};


// Ebox - SMS

export const fetchEBoxSms = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    const { smsList: { filter } } = getState().ebox;
    return dispatch(actions.fetchEBoxSms(
      getEBoxSms(token, { ...filter }),
    ));
  };
};

export const getEBoxSmsData = (smsId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    return dispatch(actions.getEBoxSmsData(
      getSmsData(token, smsId)
    ));
  };
};
