export const setLanguage = (language) => {

  let lng = language.toLowerCase();
  localStorage.setItem('lng', lng);

  return {
    type: 'SET_LANGUAGE',
    language,
  };
};

export const setCurrentNavPage = (currentNavPage) => {
  return {
    type: 'SET_CURRENT_NAV_PAGE',
    currentNavPage,
  };
};

export const pushSourceToken = (sourceName, sourceToken) => {
  return {
    type: 'PUSH_SOURCE_TOKEN',
    sourceName,
    sourceToken,
  };
};

export const clearSourcesTokens = () => {
  return {
    type: 'CLEAR_SOURCES_TOKENS',
  };
};

export const setTTPDialogShow = (showDialog) => {
  return {
    type: 'SET_TTP_DIALOG_SHOW',
    showDialog,
  };
};

export const setTTPDialogCustomData = (customData) => {
  return {
    type: 'SET_TTP_DIALOG_CUSTOM_DATA',
    customData,
  };
};

export const setStep = (step) => {
  return {
    type: 'SET_STEP',
    step,
  };
};

export const setSubMenuDirection = subMenuDirection => ({
  type: 'SET_SUB_MENU_DIRECTION',
  subMenuDirection,
});

export const setApplication = application => ({
  type: 'SET_APPLICATION',
  application,
});

export const fetchSesEmailsStatuses = (resp) => {
  return {
    type: 'FETCH_SES_EMAILS_STATUSES',
    payload: resp
  };
};

export const setWindowHeight = windowHeight => ({
  type: "SET_WINDOW_HEIGHT",
  windowHeight,
});

export const fetchAppDynamicsFields = (resp) => {
  return {
    type: 'FETCH_APP_DYNAMICS_FIELDS',
    payload: resp
  };
};

export const setAppNameDynamicFields = appName => ({
  type: 'SET_APP_NAME',
  appName,
});

