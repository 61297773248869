import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { setCurrentEmailData } from "actions";
import { sendGenericEmailTest } from "thunks";
import _ from 'i18n';
import { onError, onSuccess } from 'utils';
import SendTestForm from '../../../common/SendTestForm';
import { inIframe } from "../../../../services/utils";

const mapStateToProps = store => ({
    updating: store.genericEmail.currentSetting.currentEmail.updating,
    offsetTop: store.genericEmail.currentSetting.currentEmail.offsetTop,
    visible: store.genericEmail.currentSetting.currentEmail.action == 'TEST',
    id: store.genericEmail.currentSetting.currentEmail.id,
});

const mapDispatchToProps = dispatch => ({
    closeTest: () => dispatch(setCurrentEmailData({ action: null })),
    sendEmailTest: (data) => dispatch(sendGenericEmailTest(data)),
});
@connect(mapStateToProps, mapDispatchToProps)
class Test extends PureComponent {

    sendTest = (emails) => {
        const { sendEmailTest, id, closeTest } = this.props;

        sendEmailTest({
            emails: emails,
            genericEmailId: id
        })
            .then(
                res => {
                    closeTest();
                    onSuccess(res);
                },
                err => onError(err)
            );
    }

    render() {
        const { closeTest, updating, visible, offsetTop } = this.props;
        if (!visible) {
            return null
        }
        return (
            <SendTestForm
                processing={updating} onCloseModal={closeTest} onSave={this.sendTest}
                customStyle={inIframe() ? { content: { top: `calc(${offsetTop}px + 14rem)`, height: "21rem", transform: "translateX(-50%)" } } : {}} />
        );
    }
}

export default Test;
