import cheerio from 'cheerio';

export const extractLinks = (text) => {
  const $ = cheerio.load(text);
  const linkTags = $('a');

  const tags = linkTags.map((_, tag) => {
    return ({
      link: $(tag).attr('href'),
      label: $(tag).text()?.replace(/(<([^>]+)>)/gi, "")
    })
  });

  let i = 0;
  let links = [];

  while (i < tags.length) {
    links.push(tags[i]);
    i++;
  }

  return links;
}
