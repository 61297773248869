import React from "react";
import TTPSelect from "../../common/TTPSelect";
import {resizeImage} from "../../../services/utils";
import {FILTER_OPTIONS, GRAVITY_OPTIONS} from "../../../config/Common";
import _ from "i18n";
import debounce from "lodash.debounce";

export default class ImageEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      w: "",
      h: "",
      g: null,
      f: null,
      imageUrl: props.imageUrl,
    };

    this.gravityOptions = [{id: null, label: _("none")}].concat(GRAVITY_OPTIONS.map(gravity => ({
      id: gravity,
      label: _(gravity)
    })));
    this.filterOptions = [{id: null, label: _("none")}].concat(FILTER_OPTIONS.map(filter => ({
      id: filter,
      label: _(filter)
    })));

    this.updateState = debounce(this.setState, 1000);
  }

  componentDidMount() {
    this.initState(this.props.imageUrl);
  }

  initState = (imageUrl) => {
    let urlBlocks = imageUrl.split("/");
    let regex = new RegExp(`(w[0-9]+|h[0-9]+|g(${GRAVITY_OPTIONS.join("|")})|f(${FILTER_OPTIONS.join("|")}))`, "g");
    let params = urlBlocks.pop().match(regex);
    if (params && params.length > 0) {
      let newState = params.reduce((acc, param) => {
        let key = param.slice(0, 1);
        let value = parseInt(param.slice(1));
        if (value) {
          acc[key] = value;
          if (this.refs[key]) {
            this.refs[key].value = value;
          }
        }
        return acc;
      }, {});
      this.setState({...newState, imageUrl: urlBlocks.join("/")});
      return;
    }
    this.setState({imageUrl});
  };

  handleSizeChange = (ev) => {
    let {value, name} = ev.target;
    value = parseInt(value);
    let newState = {};
    newState[name] = value;
    this.updateState(newState);
  };

  handleGravityChange = (g) => {
    this.setState({g: g === "null" ? null : g});
  };

  handleFilterChange = (f) => {
    this.setState({f: f === "null" ? null : f});
  };

  validateInput = (ev) => {
    const { name, value } = ev.target;
    this.refs[name].value  = value.replace(/\D/g, "") || 0;
  };

  render() {
    const {addImage, cancel} = this.props;
    const {imageUrl, w, h, g, f} = this.state;

    const url = resizeImage(imageUrl, w, h, g, f);

    return <div className={"image-editor row"}>
      <div className="image-editor__preview small-8">
        <img src={url} alt=""/>
      </div>
      <div className="image-editor__form small-4">
        <div className="">
          <span className="param-label">{_('width')}</span>
          <input ref={"w"} type="text" name={"w"} placeholder={_("width") + " (px)"} onInput={this.validateInput}
                 className={w ? "filled-box" : "empty-box"} onChange={this.handleSizeChange}/>
        </div>
        <div className="">
          <span className="param-label">{_('height')}</span>
          <input ref={"h"} type="text" name={"h"} placeholder={_("height") + " (px)"} onInput={this.validateInput}
                 className={h ? "filled-box" : "empty-box"} onChange={this.handleSizeChange}/>
        </div>
        {!!(w && h) && <div className="">
          <span className="param-label">{_('gravity')}</span>
          <TTPSelect
            simple={true}
            notClearable={true}
            values={g}
            placeholder={_('gravity')}
            options={this.gravityOptions}
            onChange={this.handleGravityChange}/>
        </div>}
        {!!(w || h) && <div className="">
          <span className="param-label">{_('filter')}</span>
          <TTPSelect
            simple={true}
            notClearable={true}
            values={f}
            placeholder={_('filter')}
            options={this.filterOptions}
            onChange={this.handleFilterChange}/>
        </div>}
        <div className="row">
          <button className={"btn primary"} onClick={() => addImage(url)}>{_("add")}</button>
          <button className={"btn secondary"} onClick={cancel}>{_("cancel")}</button>
        </div>
      </div>
    </div>
  }
}
