import React, { Component } from 'react';
import { connect } from "react-redux";
import LineChartComponent from './LineChartComponent';
import _ from 'i18n';

class BouncesChart extends Component {
  render() {
    const {dataBounces} = this.props;
    const dataKeysY = [
      { key: "bouncesPermanent", label: "Permanent bounces"},
      { key: "bouncesOldPermanent", label: "Old permanent bounces"},
      { key: "bouncesTransient", label: "Transient bounces"},
      { key: "bouncesUndetermined", label: "Undetermined bounces"}
    ];

    const yAxisTitle = "Number of bounces";
    const dataKeyX ={ key: "date", label: "date" };
    return (
      <LineChartComponent
        dataChart={dataBounces}
        dataKeysY = {dataKeysY}
        dataKeyX = {dataKeyX}
        yAxisTitle = {yAxisTitle}
        isEvolutionTooltip = {true}
      />
    );
  }
}
const mapStateToProps = (store) => ({
    dataBounces: store.dashboard.dataBounces
});
export default connect(mapStateToProps)(BouncesChart);
