import React, { Component } from "react";

export default class SpeakerItemFetching extends Component {

    render() {
        let avatarDiv = (
        <div className="avatar empty-avatar">
            <span/>
        </div>
        );

        return (<div className="speakers-item fetching">
            {avatarDiv}
            <div className="speakers-item__header">
                <h3 className="gradiant"><span/></h3>
                <h5 className="gradiant"/>
            </div>
        </div>);
    }
}
