import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./BestLinksClicked.module.scss";
import _ from "i18n";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchLinks } from "../../../actions/thunks/dashboard";
import moment from "moment";
import Select from "react-select";
import {getPeriod} from "../../../services/utils";

@connect((store) => {
  return {
    bestLinks: store.dashboard.bestLinks,
  };
})
export default class BestLinksClicked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateLink: "2015-01-01",
    };
    this.typeOptions = getPeriod().map((period) => {
      return {
        value: period.key,
        label: (
          <span>
          {_(period.value)}
          </span>
        ),
      };
    });
  }
  componentDidMount() {
    this.props.dispatch(fetchLinks(this.state.dateLink));
  }

  componentDidUpdate(prevProps, prevState) {
    const { dateLink } = prevState;
    if (dateLink !== this.state.dateLink) {
      this.props.dispatch(fetchLinks(this.state.dateLink));
    }
  }

  formatLinks = (link) => {
    let before = 0,
      after = link.length;
    for (let i = 0; i < link.length; i++) {
      if (link[i] === "/" && link[i - 1] === "/") {
        before = i + 1;
        continue;
      }
      if (link[i] === "/" && link[i + 1] !== "/") {
        after = i - 1;
        break;
      }
    }
    return link.substr(before, after - before + 1);
  };

  handleDateChange = (date) => {
    this.setState({
      dateLink: date,
    });
  };
  handleSelectType = (selected) => {
    console.log(selected.value);
    this.setState({
      dateLink: selected.value,
    });
  };
  render() {
    let data = this.props.bestLinks;
    return (
      <div className={styles.bestLinksClicked}>
        <h2>{_("Best clicked links")}</h2>
        <div className="filter_date">
          <div className="single-date-picker">
          <Select
            isSearchable={false}
            isClearable={false}
            defaultValue={this.typeOptions[0]}
            options={this.typeOptions}
            className='filter-select medium-12'
            classNamePrefix={styles.ttpSelect}
            onChange={this.handleSelectType}
          />
          </div>
        </div>
        <div className={styles.links}>
          {data.map((element, key) => {
            const onSecond = 1000, onMin = 1000 * 60, onHour = 1000 * 60 * 60, onDay = 1000 * 60 * 60 * 24;
            let createdDate = new Date(element.createdAt.date);
            if (createdDate.toString() === "Invalid Date") {
              createdDate = new Date(element.createdAt.date.replace(/-/g, "/").split(".")[0]);
            }
            const diffInTime =  new Date().getTime() - createdDate.getTime();
            let diff;

            if (Math.round(diffInTime / onDay) != 0)
              diff = Math.round(diffInTime / onDay) + " " + _("days");
            else if (Math.round(diffInTime / onHour) != 0)
              diff = Math.round(diffInTime / onHour) + " " +_("hours");
            else if (Math.round(diffInTime / onMin) != 0)
              diff = Math.round(diffInTime / onMin) +" " + _("minutes");
            else diff = Math.round(diffInTime / onSecond) +" " + _("seconds");

            return (
              <div className={styles.link} key={key}>
                <div className={styles.diffTime}>
                  <label>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.54688 3.77344V6.77348L9.53775 8.76436C9.75133 8.97794 9.75133 9.32419 9.53775 9.53775C9.32416 9.75133 8.97791 9.7513 8.76436 9.53775L6.6133 7.3867C6.51074 7.28416 6.45312 7.14503 6.45312 7V3.77344C6.45312 3.4714 6.69796 3.22656 7 3.22656C7.30204 3.22656 7.54688 3.4714 7.54688 3.77344ZM9.15105 0H4.84895C3.70352 0 2.57348 0.402555 1.66931 1.18885V0.546875C1.66931 0.244836 1.42447 0 1.12243 0C0.820395 0 0.575559 0.244836 0.575559 0.546875V2.69793C0.575559 2.99996 0.8225 3.2448 1.12243 3.2448H3.27348C3.57552 3.2448 3.82036 2.99996 3.82036 2.69793C3.82036 2.39589 3.57552 2.15105 3.27348 2.15105H2.23899C2.93117 1.48373 3.86553 1.09375 4.84895 1.09375H9.15102C11.2217 1.09375 12.9062 2.77832 12.9062 4.84895V9.15102C12.9062 11.2217 11.2217 12.9062 9.15105 12.9062H4.84895C2.77832 12.9062 1.09375 11.2217 1.09375 9.15105V7C1.09375 6.69796 0.848914 6.45312 0.546875 6.45312C0.244836 6.45312 0 6.69796 0 7V9.15105C0 11.8248 2.17522 14 4.84895 14H9.15102C11.8248 14 14 11.8248 14 9.15105V4.84895C14 2.17522 11.8248 0 9.15105 0V0Z"
                        fill="#29394D"
                      />
                    </svg>
                  </label>
                  <label> il y a {diff} </label>
                </div>
                <h3> {this.formatLinks(element.link)} </h3>
                <span> Nombre de clics: </span> {element.clicksCount}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
