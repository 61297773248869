import React, { Component } from "react";
import { connect } from "react-redux";

import BounceDetails from "./BounceDetails";
import moment from "moment";
import Header from "./Header";
import Summary from "./Summary";
import Charts from "./Charts";
import { exportDataToExcelFile } from "../../services/utils";

import {
  setStep,
  setRecipientsStatsFilterLinkId,
  setRecipientsStatsPageSize,
  setRecipientsStatsPaginationPage,
  setTTPDialogCustomData,
  clearSourcesTokens,
  initCampaignStats,
} from "actions";
import { TTP_API_URL } from "Config";
import _ from "i18n";
import {
  fetchCampaign,
  fetchCampaignStats,
  fetchRecipientsStats,
  updateCampaignStatus,
} from "thunks";
import { onError, onSuccess, getRate } from "utils";
import RecipientsStats from "./datatable/RecipientsStats";
import DIALOG_TYPES from "../../constants/TTPDialog";
import Viewer from "../common/Viewer";
import {
  initCurrentEmailCampaignData,
  setCurrentNavPage,
} from "../../actions/actions";
import SidePageHeader from "common/SidePageHeader";
import { LinksTracking } from "./links/LinksTracking";
import ChangeScheduledAtDateForm from "../campaign/datatable/ChangeScheduledAtDateForm";
import ActionsCell from "../campaign/datatable/ActionsCell";

@connect((store) => {
  return {
    token: store.auth.token,
    stats: store.campaigns.stats.campaign,
    lng: store.params.lng,
    totalRecipients: store.currentCampaign.email.totalRecipients,
    campaign: store.currentCampaign.email,
    recipients: store.campaigns.stats.recipients.list.items,
    selectedStatus: store.campaigns.stats.recipients.filter.status,
    _requests: store.params.sourcesTokens,
  };
})
export default class StatsPage extends Component {
  constructor(props) {
    super(props);
    this.handlePreview = (isOpen = false) =>
      this.setState({ previewIsOpen: isOpen });
    this.state = {
      id: null,
      status: null,
      recipientListCreated: false,
      showChangeScheduledAtDate: false,
      showSentAt: false,
      clickedLink: null,
    };
    this.refreshStatsTimerId = null;
    this.toggleChangeScheduledAtDate = (showChangeScheduledAtDate) =>
      this.setState({ showChangeScheduledAtDate });

    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
  }

  exportCompaignStats = () => {
    const { recipients } = this.props;
    if (!recipients.length) {
      return;
    }

    let dataSet = {
      columns: [
        _("firstName"),
        _("lastName"),
        _("Email"),
        _("userGroup"),
        _("openedAt"),
        _("clickedAt"),
      ],
      data: recipients.map(
        ({ firstName, lastName, email, openAt, clickAt, userGroup }) => {
          return [
            firstName,
            lastName,
            email,
            userGroup,
            openAt
              ? moment(openAt.date).format("YYYY-MM-DD, HH:mm")
              : _("notOpenYet"),
            clickAt
              ? moment(clickAt.date).format("YYYY-MM-DD, HH:mm")
              : _("notClickedYet"),
          ];
        }
      ),
    };

    const fileName = "Compaign_stats";
    return exportDataToExcelFile([dataSet], fileName);
  };

  componentDidMount() {
    const {
      dispatch,
      match: { params: routeParams },
      _requests,
    } = this.props;

    dispatch(setCurrentNavPage("CREATE_CAMPAIGN"));

    _requests.forEach((req) =>
      req.sourceToken.cancel("Operation canceled by the user.")
    );
    dispatch(initCurrentEmailCampaignData());
    if (routeParams.campaignId) {
      this.refreshStats();
      dispatch(fetchCampaign(routeParams.campaignId));
    }
    window.scroll(0, 0);
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params: routeParams },
      stats,
      selectedStatus,
      campaign,
    } = this.props;
    const { isFromCampaignStats } = this.props.stats.data;

    if (stats !== prevProps.stats) {
      let status = this.state.status;
      if (!status) {
        status = campaign.status;
      } else if (
        stats.data.campaignStatus !== prevProps.stats.data.campaignStatus
      ) {
        status = stats.data.campaignStatus;
        this.setState({ showSentAt: status === "SENT" });
      }
      const recipientListCreated =
        stats.data.recipientListCreated || campaign.recipientListCreated;
      this.setState({
        status,
        recipientListCreated,
        id: routeParams.campaignId,
      });
    }

    if (
      (prevProps.stats.data !== stats.data &&
        prevProps.stats.data.length === 0 &&
        !isFromCampaignStats) ||
      (selectedStatus !== prevProps.selectedStatus &&
        moment(this.state.scheduledDeliveryAt) <= moment() &&
        !isFromCampaignStats)
    ) {
      this.renderRecipientStatsDatatable();
    }
  }

  refreshStats() {
    const {
      dispatch,
      match: { params: routeParams },
    } = this.props;
    const { status, scheduledDeliveryAt } = this.state;

    if (
      (routeParams.campaignId && !scheduledDeliveryAt) ||
      (scheduledDeliveryAt && moment(scheduledDeliveryAt) <= moment())
    ) {
      dispatch(fetchCampaignStats(routeParams.campaignId));
    }
    const TIME_OUT = status === "SENT" ? 40000 : 10000;
    this.refreshStatsTimerId = setTimeout(() => this.refreshStats(), TIME_OUT);
  }

  componentWillUnmount() {
    if (this.refreshStatsTimerId !== null) {
      clearTimeout(this.refreshStatsTimerId);
    }
    const { _requests, dispatch } = this.props;

    _requests.forEach((req) =>
      req.sourceToken.cancel("Operation canceled by the user.")
    );
    dispatch(clearSourcesTokens());
    dispatch(setStep(null));
    dispatch(initCampaignStats());
    dispatch(initCurrentEmailCampaignData());
  }

  handleOpenStopDialog() {
    const { dispatch } = this.props;
    dispatch(
      setTTPDialogCustomData({
        approvedAction: () => this.handleCampaignAction("HALT"),
        message: _("stopCampaignConfirm"),
        proceedBtnLabel: _("stop"),
        type: DIALOG_TYPES["ALERT"],
        title: "Halt confirmation",
      })
    );
  }

  handleClickStatsLink = (linkId) => {
    const { dispatch } = this.props;
    const { isFromCampaignStats } = this.props.stats.data;

    dispatch(setRecipientsStatsFilterLinkId(linkId));
    if (!isFromCampaignStats) {
      this.renderRecipientStatsDatatable();
    }
  };

  renderRecipientStatsDatatable = (paginationPage = 1, pageSize = 10) => {
    const {
      dispatch,
      match: { params: routeParams },
    } = this.props;
    dispatch(setRecipientsStatsPaginationPage(paginationPage));
    if (pageSize) {
      dispatch(setRecipientsStatsPageSize(pageSize));
    }
    dispatch(fetchRecipientsStats(routeParams.campaignId));
  };

  handleCampaignAction(status) {
    const {
      dispatch,
      match: { params: routeParams },
    } = this.props;
    const id = routeParams.campaignId;
    dispatch(updateCampaignStatus({ id, status })).then(
      (res) => onSuccess(res),
      (err) => onError(err)
    );
  }

  renderPauseOrPlayLink() {
    const { status } = this.state;
    if (["RECIPIENT_LIST_CREATED", "CREATED"].indexOf(status) > -1) {
      return (
        <li>
          <span
            onClick={() => this.handleCampaignAction("PAUSE")}
            className="sidebar__icon"
          >
            <i className="icon-control-pause"></i>
          </span>
          <span className="sidebar__item-hover"> {_("pauseSending")}</span>
        </li>
      );
    } else if (status == "PAUSE") {
      return (
        <span>
          <li>
            <span
              onClick={() => this.handleCampaignAction("UNPAUSE")}
              className="sidebar__icon"
            >
              <i className="icon-control-play"></i>
            </span>
            <span className="sidebar__item-hover"> {_("resume")}</span>
          </li>
          <li>
            <span
              onClick={this.handleOpenStopDialog.bind(this)}
              className="sidebar__icon"
            >
              <i className="icomoon icon-stop warning"></i>
            </span>
            <span className="sidebar__item-hover"> {_("stopCampaign")} </span>
          </li>
        </span>
      );
    }
    return null;
  }
  handleLinkClick(link) {
    const { dispatch } = this.props;
    const { isFromCampaignStats } = this.props.stats.data;
    this.setState({ clickedLink: link.link });

    dispatch(setRecipientsStatsFilterLinkId(link.id));
    if (!isFromCampaignStats) {
      this.renderRecipientStatsDatatable();
    }
  }
  handleRefresh() {
    this.setState({ clickedLink: null });
  }

  render() {
    const {
      links,
      boucesDetails,
      deliveredCount,
      openCount,
      clickCount,
      unsubscribeCount,
      complaintCount,
      refusedCount,
      totalTargetCount,
      isFromCampaignStats,
    } = this.props.stats.data;
    const {
      id,
      status,
      previewIsOpen,
      recipientListCreated,
      showChangeScheduledAtDate,
      showSentAt,
    } = this.state;
    const { totalRecipients } = this.props;
    let processedCount = parseInt(refusedCount) + parseInt(deliveredCount);

    let processedRate = getRate(processedCount, totalRecipients);

    return (
      <div id="stats-page">
        <SidePageHeader
          subHeader="The campaign report"
          returnTo={"/campaigns"}
          actionButton={
            recipientListCreated
              ? {
                  label: _("preview"),
                  icon: "icon-eye",
                  action: () => this.handlePreview(true),
                }
              : null
          }
        />
        <ul id="sidebar">{this.renderPauseOrPlayLink()}</ul>
        <div className="stats-page">
          <Header processedRate={processedRate} status={status} />
          <div className="flex-container align-justify">
            <Summary
              showSentAt={showSentAt}
              exportCompaignStats={this.exportCompaignStats}
              recepientsExist={this.props.recipients?.length > 0}
              match={this.props.match}
            />
            <Charts
              deliveredCount={deliveredCount}
              openCount={openCount}
              clickCount={clickCount}
              unsubscribeCount={unsubscribeCount}
              complaintCount={complaintCount}
              refusedCount={refusedCount}
              total={totalTargetCount}
              changeScheduledAtDate={() =>
                this.toggleChangeScheduledAtDate(true)
              }
            />
          </div>
          <BounceDetails boucesDetails={boucesDetails} />
          {/* <LinksTracking links={links} clicked={this.handleClickStatsLink} /> */}
          <LinksTracking links={links} clicked={this.handleLinkClick} />
          {!isFromCampaignStats && (
            <RecipientsStats
              renderRecipientStatsDatatable={this.renderRecipientStatsDatatable.bind(
                this
              )}
              showSentAt={showSentAt}
              exportCompaignStats={this.exportCompaignStats}
              recepientsExist={this.props.recipients?.length > 0}
              match={this.props.match}
              links={links}
              clickedLink={this.state.clickedLink}
              handleRefresh={this.handleRefresh}
            />
          )}
        </div>
        <Viewer
          isOpen={previewIsOpen}
          onClose={() => this.handlePreview(false)}
          url={`${TTP_API_URL}/mailing/web-version?campaignId=${id}&access_token=${this.props.token}`}
        />
        {showChangeScheduledAtDate && (
          <ChangeScheduledAtDateForm
            isStatsPage={true}
            onCloseModal={() => this.toggleChangeScheduledAtDate(false)}
          />
        )}
      </div>
    );
  }
}
