import React, { Component } from "react";
import { connect } from "react-redux";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";

import _ from "i18n";
import ArticlesList from "articles/ArticlesList";
import LayoutsList from "./layout/LayoutsList";

import {
  initBlocks,
  setNewsletterBase,
  setBlocksType,
  setNewsletterBlocks,
  setCurrentLayout,
  setCurrentNewsletterTemplate,
  setCurrentDndTheme,
  setThemeIsSaved,
} from "actions";

import { TEMPLATE_TYPES } from "constants";
import BlockTypeSelector from "./BlockTypeSelector";
import SpeakersList from "../speakers/SpeakersList";
import SlotsList from "../slots/SlotsList";
import FormationsList from "../formations/FormationsList";
import TemplatePreview from "../newsletter/TemplatePreview";
import SaveTemplateForm from "../newsletter/SaveTemplateForm";
import { assembleNewsletter } from "../../services/block/newsletter";
import ProgressBar from "../newsletter/ProgressBar";

const mapStateToProps = (state, ownProps) => ({
  currentBlockType: state.themes.blocks.filter.type,
  blocks: state.themes.newsletter.blocks,
  base: state.themes.newsletter.base,
  currentLayout: state.themes.newsletter.currentLayout,
  template: state.themes.newsletter.currentTemplate,
  updating: state.themes.updating,
  feeds: {
    NEWS_LAYOUT: state.articles.items,
    SPEAKER_LAYOUT: state.filters.speakers.items,
    SLOT_LAYOUT: state.filters.slots.items,
    FORMATION_LAYOUT: state.filters.formations.items,
  },
});

const mapDispatchToProps = (dispatch) => ({
  initBlocks: () => dispatch(initBlocks()),
  setNewsletterBase: (base) => dispatch(setNewsletterBase(base)),
  setBlocksType: (type) => dispatch(setBlocksType(type)),
  setCurrentLayout: (data) => dispatch(setCurrentLayout(data)),
  setCurrentNewsletterTemplate: (data) =>
    dispatch(setCurrentNewsletterTemplate(data)),
  setNewsletterBlocks: (data) => dispatch(setNewsletterBlocks(data)),
  setCurrentDndTheme: (theme) => dispatch(setCurrentDndTheme(theme)),
  setThemeIsSaved: (isSaved) => dispatch(setThemeIsSaved(isSaved)),
});

@connect(mapStateToProps, mapDispatchToProps)
class BlocksContainer extends Component {
  state = {
    showTemplatesList: false,
    selectedFeeds: [],
    showSelectedFeeds: true,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { inCreateCampaign, childRef, blocks, currentBlockType } = this.props;

    this.setState({ selectedFeeds: this.getSelectedFeeds(currentBlockType) });

    if (inCreateCampaign && childRef) {
      childRef({
        primaryAction: this.toggleSaveTemplateForm,
        secondaryAction: this.generateNewsletter,
        isUpdating: this.isUpdating,
      });
      this.props.triggerRender && this.props.triggerRender();
    }
  }

  componentDidUpdate(prevProps) {
    const { blocks, currentBlockType, updating } = this.props;

    if (
      blocks !== prevProps.blocks ||
      currentBlockType !== prevProps.currentBlockType
    ) {
      const selectedFeeds = this.getSelectedFeeds(currentBlockType);
      this.setState({ selectedFeeds });
    }

    if (updating !== prevProps.updating) {
      this.props.triggerRender && this.props.triggerRender();
    }
  }

  componentWillUnmount() {
    const { initBlocks } = this.props;
    initBlocks();
  }

  generateNewsletter = () => {
    const { blocks, template, setCurrentDndTheme, proceed, setThemeIsSaved } =
      this.props;
    assembleNewsletter(template, blocks, (design) => {
      setCurrentDndTheme({ design });
      setThemeIsSaved(false);
      proceed();
    });
  };

  toggleShowSelectedFeeds = () => {
    this.setState({ showSelectedFeeds: !this.state.showSelectedFeeds });
  };

  getSelectedFeeds = (currentBlockType) => {
    const { blocks } = this.props;
    let r = blocks
      .filter(({ type }) => type === currentBlockType)
      .reduce((acc, { feeds }) => {
        return acc.concat(feeds.length > 0 ? [...feeds] : []);
      }, []);

    return r;
  };

  setLayoutBase = () => {
    const { setNewsletterBase, setBlocksType } = this.props;
    setNewsletterBase("LAYOUT");
    setBlocksType("NEWS_LAYOUT");
  };

  scrollFeedsToTop = () => {
    let feedsContainer = document.getElementById("feeds-container");
    if (feedsContainer) {
      feedsContainer.scrollTop = 0;
    }
  };

  toggleSaveTemplateForm = () => {
    this.setState({ showSaveTemplateForm: !this.state.showSaveTemplateForm });
  };

  isUpdating = () => {
    return this.props.updating;
  };

  render() {
    const { currentBlockType, base, blocks } = this.props;
    const { showSaveTemplateForm, selectedFeeds, showSelectedFeeds } =
      this.state;

    let types = blocks
      .filter(({ type }) => type !== TEMPLATE_TYPES["UNREFERENCED_BLOCK"])
      .map(({ type }) => type);
    let blockType =
      types.length === 0
        ? "NEWS_LAYOUT"
        : types.indexOf(currentBlockType) < 0
        ? types[0]
        : currentBlockType;

    let isTemplateBase = base === "TEMPLATE";
    return (
      <div id="blocks-tool">
        <div className={`row blocks-container`}>
          <div className={`medium-8`}>
            <BlockTypeSelector blockTypes={types} />
            <div className="top-scroller" onClick={this.scrollFeedsToTop}>
              <span className="icon-arrow-up" />
            </div>
            <div
              className="hide-selected"
              onClick={this.toggleShowSelectedFeeds}
            >
              <span className="icon-eye" />
            </div>
            {(types.length === 0 ||
              types.indexOf(TEMPLATE_TYPES["NEWS_LAYOUT"]) > -1) && (
              <div
                className={
                  blockType !== TEMPLATE_TYPES["NEWS_LAYOUT"] ? "hide" : ""
                }
              >
                <ArticlesList
                  showSelected={showSelectedFeeds}
                  selectedFeeds={selectedFeeds}
                />
              </div>
            )}
            {types.indexOf(TEMPLATE_TYPES["SPEAKER_LAYOUT"]) > -1 && (
              <div
                className={
                  blockType !== TEMPLATE_TYPES["SPEAKER_LAYOUT"] ? "hide" : ""
                }
              >
                <SpeakersList
                  showSelected={showSelectedFeeds}
                  selectedFeeds={selectedFeeds}
                />
              </div>
            )}
            {types.indexOf(TEMPLATE_TYPES["SLOT_LAYOUT"]) > -1 && (
              <div
                className={
                  blockType !== TEMPLATE_TYPES["SLOT_LAYOUT"] ? "hide" : ""
                }
              >
                <SlotsList
                  showSelected={showSelectedFeeds}
                  selectedFeeds={selectedFeeds}
                />
              </div>
            )}

            {types.indexOf(TEMPLATE_TYPES["FORMATION_LAYOUT"]) > -1 && (
              <div
                className={
                  blockType !== TEMPLATE_TYPES["FORMATION_LAYOUT"] ? "hide" : ""
                }
              >
                <FormationsList
                  showSelected={showSelectedFeeds}
                  selectedFeeds={selectedFeeds}
                  handleSelectedFormation={this.handleSelectedFormation}
                />
              </div>
            )}
          </div>
          <div className={`newsletter-area__left medium-4`}>
            <div className="tool-header">
              {isTemplateBase ? <ProgressBar /> : _("layouts")}
            </div>
            {isTemplateBase ? <TemplatePreview /> : <LayoutsList />}
            {showSaveTemplateForm && (
              <SaveTemplateForm onClose={this.toggleSaveTemplateForm} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DragDropContext(HTML5Backend)(BlocksContainer);
