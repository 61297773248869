export const fetchSlots = (resp) => {
  return {
      type: 'FETCH_SLOTS',
      payload: resp
  };
};

export const initSlots = () => {
    return {
      type: 'INIT_SLOTS'
    };
};

export const setSlotsSearchWord = (searchWord) => {
    return {
      type: 'SET_SLOTS_SEARCH_WORD',
      searchWord
    }
};

export const setSlotsEvent = (event) => {
    return {
      type: 'SET_SLOTS_EVENT',
      event
    }
};

export const setSlotsPaginationPage = (pageNumber) => {
  return {
    type: 'SET_SLOTS_PAGINATION_PAGE',
    pageNumber
  };
};
  