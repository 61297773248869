import React, { Component } from "react";
import { connect } from "react-redux";

import NotFound from "notFound/NotFound";
import MemberFeedbackItem from "./MemberFeedbackItem";
import TTPSelect from "common/TTPSelect";

import { fetchApprovalFeedback } from "thunks";
import _ from "i18n";
import TeamsFeedbackFetching from "../../fetching/TeamsFeedbackFetching";
import { setCurrentEmailCampaignData } from "../../../actions/actions/currentCampaign";
import { stringToColor } from "utils";

@connect((store) => {
  return {
    approvals: store.approval.list.items,
    isCampaignApproved: store.approval.list.isCampaignApproved,
    fetching: store.approval.list.fetching,
    campaignId: store.currentCampaign.email.id,
    opened: store.currentCampaign.email.currentSideBar == "REVIEW",
    lastTestversion: store.currentCampaign.email.lastTestversion,
  };
})
export default class ReviewsTab extends Component {
  constructor(props) {
    super(props);
    this.handleVersionChange = (version) => this.setState({ version });
    this.state = { version: props.lastTestversion };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lastTestversion != this.props.lastTestversion &&
      this.state.version != this.props.lastTestversion
    ) {
      this.setState({ version: this.props.lastTestversion });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { campaignId, isCampaignApproved } = nextProps;
    if (
      nextProps.opened &&
      campaignId &&
      (nextState.version != this.state.version || !this.props.opened)
    ) {
      nextProps.dispatch(
        fetchApprovalFeedback({
          campaignId,
          version: nextState.version,
        }),
      );
    }
    if (isCampaignApproved !== this.props.isCampaignApproved) {
      nextProps.dispatch(
        setCurrentEmailCampaignData({ approved: isCampaignApproved }),
      );
    }
  }

  renderVersionOptions() {
    let { version } = this.state;
    let { lastTestversion } = this.props;
    let options = [];

    for (let i = 1; i <= lastTestversion; i++) {
      options.push({ id: i + "", label: `Version ${i}` });
    }

    return (
      <TTPSelect
        simple={true}
        notClearable={true}
        values={version}
        placeholder={_("version")}
        options={options}
        onChange={this.handleVersionChange}
      />
    );
  }

  renderTeamFeedback() {
    let feedbacks = this.props.approvals || {};

    return Object.values(feedbacks).map((team) => {
      let initial = (team.name && team.name.substring(0, 1)) || "";

      return (
        <div
          key={`team-${team.id}`}
          className="team-list-item team-list-item--open"
        >
          <div className="row align-middle team-header">
            <div className="small-1">
              <span
                className="team-avatar"
                style={{ background: stringToColor(team.name) }}
              >
                {initial}
              </span>
            </div>
            <div className="small-7">
              <span className="param-label">{team.name}</span>
              <p>{team.description}</p>
            </div>
            <div className="small-4 team-header--members">
              {_("the approval threshold")} : <b>{team.minApprovers}</b>
            </div>
          </div>
          <div className="team-setting row align-middle align-spaced">
            {this.renderMembersFeedback(team.members || [])}
          </div>
        </div>
      );
    });
  }

  renderMembersFeedback(members) {
    return members.map((member) => (
      <MemberFeedbackItem {...member} key={`approval-${member.id}`} />
    ));
  }

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.opened ||
      this.props.opened ||
      nextProps.lastTestversion != this.props.lastTestversion
    );
  }

  render() {
    let { opened, approvals, fetching } = this.props;
    let teamsBlock = null;
    if (fetching) {
      teamsBlock = <TeamsFeedbackFetching />;
    } else if (Object.keys(approvals) && Object.keys(approvals).length > 0) {
      teamsBlock = this.renderTeamFeedback();
    } else {
      teamsBlock = <NotFound />;
    }

    return (
      <div
        id="campaign-reviews__tab"
        className={`approval-feedback ${!opened && "hide"}`}
      >
        <div className="sidebar__form">
          <h4 className="sidebar__header">{_("approvers's feedback")}</h4>
          <div className="row">
            <div className="small-2">{this.renderVersionOptions()}</div>
          </div>
          <div className="row small-11 team-list">{teamsBlock}</div>
        </div>
      </div>
    );
  }
}
