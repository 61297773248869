import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from "i18n";
import { fetchAppDynamicsFields } from "thunks";
import TTPModal from "ui/TTPModal";
import { CAMPAIGN_DYNAMIC_FIELDS, RECIPIENT_TYPE, EVENT_TYPE, WTB_TYPE } from '../../../constants/dynamicFields';
import StyledTabs from '../../ui/tabs/StyledTabs';
import { ucFirst } from "../../../services/common";

class FieldsSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'RECIPIENT',
      fields: this.prepareFields(),
    }
  }

  setActiveTab = activeTab => this.setState({ activeTab });
  setFields = fields => this.setState(prev => ({
    fields,
    activeTab: (fields && !fields[prev.activeTab]) ? Object.keys(fields)[0] : prev.activeTab
  }));

  prepareFields = () => {
    const { fieldsType } = this.props;
    let types = [RECIPIENT_TYPE];
    if (fieldsType) {
      types.push(fieldsType);
    }
    let fields = {};
    types.forEach(type => {
      fields[type] = CAMPAIGN_DYNAMIC_FIELDS[type] || [];
    });

    return fields;
  }

  modalStyle = {
    content: {
      top: '50%',
      border: "none",
      background: "#FAFBFB",
      outline: "none",
      width: "60%",
      // minHeight: "40%",
      transition: 'opacity 0.4s ease-out 0s',
      boxShadow: '0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)',
      minWidth: '35rem',
      padding: '0',
    }
  };

  componentDidMount() {
    const { fetchAppDynamicsFields, eventFieldsFetched, fieldsType, eventFields, wtbFields, wtbFieldsFetched } = this.props;

    if (![EVENT_TYPE, WTB_TYPE].includes(fieldsType)) {
      return;
    }
    if (fieldsType === EVENT_TYPE && eventFieldsFetched) {
      this.setFields(eventFields);
    }
    if (fieldsType === WTB_TYPE && wtbFieldsFetched) {
      this.setFields(wtbFields);
    }

    if ((fieldsType === WTB_TYPE && !wtbFieldsFetched) || (fieldsType === EVENT_TYPE && !eventFieldsFetched)) {
      fetchAppDynamicsFields(fieldsType);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updating) {
      if (this.props.fieldsType === EVENT_TYPE) {
        this.setFields(this.props.eventFields);
      } else {
        this.setFields(this.props.wtbFields);
      }
    }
  }

  render() {
    const { activeTab, fields } = this.state;
    const tabs = Object.keys(fields).map(key => ({ key, title: key.toLowerCase() }));
    const { close, lng, onAddField } = this.props;

    return (
      <TTPModal isOpen={true} onClose={close} modalStyle={this.modalStyle} title={_('Customize with dynamic fields')}>
        <div className="fields-selector">
          <StyledTabs
            active={activeTab}
            tabs={tabs}
            setActiveTab={this.setActiveTab}>
            <div className="fields-selector__items">
              {fields[activeTab] && fields[activeTab].length > 0 && fields[activeTab].map(({ code, title }) => <span key={code} onClick={() => onAddField(code)}>{(title[lng] ? title[lng] : title[ucFirst(lng)]).toUpperCase()}</span>)}
            </div>
          </StyledTabs>
        </div>
      </TTPModal>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  eventFieldsFetched: state.params.dynamicsFields.event.fetched,
  wtbFieldsFetched: state.params.dynamicsFields.wtb.fetched,
  eventFields: state.params.dynamicsFields.event.list,
  wtbFields: state.params.dynamicsFields.wtb.list,
  updating: state.params.dynamicsFields.updating,
});

const mapDispatchToProps = dispatch => ({
  fetchAppDynamicsFields: (appName) => dispatch(fetchAppDynamicsFields(appName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldsSelector);

