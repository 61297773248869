import axios from '../axios-api';
import { throwCatchedError, updateSourceToken } from 'utils';

let getNotificationRecipientsSourceToken;
let getNotificationSourceToken;

export const getNotifications = (token, clientId) => {
  const requestUrl = `/organization/notification`;

  let filter = [];
  let sort = [];

  filter.push({
    property: 'appName',
    value: ['SMS', 'EMAILING'],
    operator: 'in'
  });

  sort.push({
    property: "createdAt",
    dir: "desc"
  });

  let params = {
    access_token: token,
    limit: 5
  };

  if (clientId) {
    params.organization_id = clientId;
  }

  params.sort = JSON.stringify(sort);
  params.filter = JSON.stringify(filter);


  return axios.get(requestUrl, { params });
};

export const readWebNotification = (token, notificationId) => {
  const requestUrl = `/organization/notification-user/mark-notification-as-read/` + notificationId;
  var formData = new FormData();
  formData.append('access_token', token);

  return axios.post(requestUrl, formData);
};

export const getPushNotifications = (token, clientId, data) => {
  const requestUrl = `/organization/notification`;
  const { paginationPage, pageSize } = data;
  let filter = [
    {
      property: 'type',
      value: 'PUSH_NOTIFICATION',
      operator: 'eq'
    },
    {
      property: 'folder',
      value: clientId,
      operator: 'eq'
    }
  ];

  const sort = [{
    property: "updatedAt",
    dir: "desc"
  }];

  let params = {
    access_token: token,
    fields: "*,allUsers",
    filter: JSON.stringify(filter),
    limit: pageSize || 10,
    start: (!paginationPage) ? 0 : ((paginationPage - 1) * pageSize),
    sort: JSON.stringify(sort)
  };

  return axios.get(requestUrl, { params });
};

export const getNotificationRecipients = (token, filterData, sourceToken = null) => {
  getNotificationRecipientsSourceToken = updateSourceToken(getNotificationRecipientsSourceToken, sourceToken);
  const requestUrl = `/organization/user-device-apps`;

  const { searchWord, language, clientId, pageSize, paginationPage, filters, appName } = filterData;

  let fields = ['user'];
  let filter = [
    {
      property: 'contactClient.client',
      value: clientId,
      operator: 'eq'
    },
    {
      property: 'contactClient.actif',
      value: 1,
      operator: 'eq'
    }
  ];

  if (appName) {
    filter.push({
      property: 'appName',
      value: appName,
      operator: 'eq',
    });
  }

  if (filters && filters.length) {
    filter = filter.concat(filters);
  }

  if (language) {
    filter.push({
      property: 'user.language',
      value: language,
      operator: 'in',
    });
  }

  if (searchWord) {
    filter.push({
      property: 'name',
      value: searchWord,
      operator: 'like',
    });
  };

  return axios.get(requestUrl, {
    cancelToken: getNotificationRecipientsSourceToken.token,
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter),
      limit: pageSize,
      start: (paginationPage - 1) * pageSize,
      organization_id: clientId
    }
  }).catch(function (thrown) { throwCatchedError(thrown) });
}

export const persistNotification = (token, data, clientId) => {
  const requestUrl = `/organization/notification`;
  var formData = new FormData();

  formData.append('access_token', token);
  formData.append('language', JSON.stringify(data.language));
  formData.append('subjectFr', data.subjectFr);
  formData.append('subjectNl', data.subjectNl);
  formData.append('subjectEn', data.subjectEn);
  formData.append('contentFr', data.contentFr);
  formData.append('contentNl', data.contentNl);
  formData.append('contentEn', data.contentEn);
  formData.append('filters', JSON.stringify(data.filters));
  formData.append('status', data.status);
  formData.append('type', "PUSH_NOTIFICATION");
  formData.append('appName', data.appName);
  formData.append('folder', clientId);
  formData.append('targetsCounts', data.targetsCounts);

  if (data.id) {
    formData.append('id', data.id);
  }

  return axios.post(requestUrl, formData);
};


export const getNotification = (token, notificationId, sourceToken = null) => {
  getNotificationSourceToken = updateSourceToken(getNotificationSourceToken, sourceToken);
  const filter = [
    { property: 'id', value: notificationId, operator: 'eq' },
    { property: 'type', value: "PUSH_NOTIFICATION", operator: 'eq' },
  ];
  const fields = ['*'];
  const requestUrl = `/organization/notification`;

  return axios.get(requestUrl, {
    cancelToken: getNotificationSourceToken.token,
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter),
    },
  }).catch(function (thrown) { throwCatchedError(thrown); });
};

export const deleteNotification = (token, notificationId) => {
  const requestUrl = `/organization/notification/${notificationId}`;
  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('_method', 'delete');

  return axios.post(requestUrl, formData);
};

export const duplicateNotificationData = (token, notificationId) => {
  const requestUrl = `/organization/notification/duplicate`;
  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('id', notificationId);

  return axios.post(requestUrl, formData);
};


