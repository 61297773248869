import React, { Component } from "react";
import { connect } from "react-redux";
import { initCurrentEmailCampaignData } from "../../actions/actions/currentCampaign";
import moment from "moment/moment";
import DIALOG_TYPES from "../../constants/TTPDialog";
import Header from "../stats/Header";
import Summary from "../stats/Summary";
import Charts from "../stats/Charts";
import BounceDetails from "../stats/BounceDetails";
import { LinksTracking } from "../stats/links/LinksTracking";
import RecipientsStats from "../stats/datatable/RecipientsStats";
import Viewer from "../common/Viewer";
import ChangeScheduledAtDateForm from "../campaign/datatable/ChangeScheduledAtDateForm";
import {
  fetchCampaign,
  updateCampaignStatus
} from "../../actions/thunks/campaign";
import {
  fetchCampaignStats,
  fetchRecipientsStats
} from "../../actions/thunks/stats";
import {
  initCampaignStats,
  setRecipientsStatsFilterLinkId,
  setRecipientsStatsPageSize,
  setRecipientsStatsPaginationPage
} from "../../actions/actions/stats";
import { getRate, onError, onSuccess } from "../../services/utils";
import SidePageHeader from "../common/SidePageHeader";
import { TTP_API_URL } from "../../config";
import {
  clearSourcesTokens,
  setStep,
  setTTPDialogCustomData
} from "../../actions/actions/params";
import _ from "i18n";
@connect(store => {
  return {
    token: store.auth.token,
    stats: store.campaigns.stats.campaign,
    lng: store.params.lng,
    totalRecipients: store.currentCampaign.email.totalRecipients,
    campaign: store.currentCampaign.email,
    selectedStatus: store.campaigns.stats.recipients.filter.status,
    _requests: store.params.sourcesTokens
  };
})
export default class StatisticsPage extends Component {
  constructor(props) {
    super(props);
    this.handlePreview = (isOpen = false) =>
      this.setState({ previewIsOpen: isOpen });
    this.state = {
      id: null,
      status: null,
      recipientListCreated: false,
      showChangeScheduledAtDate: false,
      showSentAt: false
    };
    this.refreshStatsTimerId = null;
    this.toggleChangeScheduledAtDate = showChangeScheduledAtDate =>
      this.setState({ showChangeScheduledAtDate });
  }

  componentDidMount() {
    const { dispatch, newsletterId } = this.props;

    dispatch(initCurrentEmailCampaignData());

    this.refreshStats();
    dispatch(fetchCampaign(newsletterId));

    window.scroll(0, 0);
  }

  componentDidUpdate(prevProps) {
    const { stats, selectedStatus, campaign, newsletterId } = this.props;
    const { isFromCampaignStats } = this.props.stats.data;

    if (stats !== prevProps.stats) {
      let status = this.state.status;
      if (!status) {
        status = campaign.status;
      } else if (
        stats.data.campaignStatus !== prevProps.stats.data.campaignStatus
      ) {
        status = stats.data.campaignStatus;
        this.setState({ showSentAt: status === "SENT" });
      }
      const recipientListCreated =
        stats.data.recipientListCreated || campaign.recipientListCreated;
      this.setState({
        status,
        recipientListCreated,
        id: newsletterId
      });
    }

    if (
      (prevProps.stats.data !== stats.data &&
        prevProps.stats.data.length === 0 &&
        !isFromCampaignStats) ||
      (selectedStatus !== prevProps.selectedStatus &&
        moment(this.state.scheduledDeliveryAt) <= moment() &&
        !isFromCampaignStats)
    ) {
      this.renderRecipientStatsDatatable();
    }
  }

  refreshStats() {
    const { dispatch, newsletterId } = this.props;
    const { status, scheduledDeliveryAt } = this.state;

    if (
      (newsletterId && !scheduledDeliveryAt) ||
      (scheduledDeliveryAt && moment(scheduledDeliveryAt) <= moment())
    ) {
      dispatch(fetchCampaignStats(newsletterId));
    }
    const TIME_OUT = status === "SENT" ? 40000 : 10000;
    this.refreshStatsTimerId = setTimeout(() => this.refreshStats(), TIME_OUT);
  }

  componentWillUnmount() {
    if (this.refreshStatsTimerId !== null) {
      clearTimeout(this.refreshStatsTimerId);
    }
    const { _requests, dispatch } = this.props;

    _requests.forEach(req =>
      req.sourceToken.cancel("Operation canceled by the user.")
    );
    dispatch(clearSourcesTokens());
    dispatch(setStep(null));
    dispatch(initCampaignStats());
    dispatch(initCurrentEmailCampaignData());
  }

  handleOpenStopDialog() {
    const { dispatch } = this.props;
    dispatch(
      setTTPDialogCustomData({
        approvedAction: () => this.handleCampaignAction("HALT"),
        message: _("stopCampaignConfirm"),
        proceedBtnLabel: _("stop"),
        type: DIALOG_TYPES["ALERT"],
        title: "Halt confirmation"
      })
    );
  }

  handleClickStatsLink = linkId => {
    const { dispatch } = this.props;
    const { isFromCampaignStats } = this.props.stats.data;

    dispatch(setRecipientsStatsFilterLinkId(linkId));
    if (!isFromCampaignStats) {
      this.renderRecipientStatsDatatable();
    }
  };

  renderRecipientStatsDatatable = (paginationPage = 1, pageSize = null) => {
    const { dispatch, newsletterId } = this.props;
    dispatch(setRecipientsStatsPaginationPage(paginationPage));
    if (pageSize) {
      dispatch(setRecipientsStatsPageSize(pageSize));
    }
    dispatch(fetchRecipientsStats(newsletterId));
  };

  handleCampaignAction(status) {
    const { dispatch, newsletterId } = this.props;
    const id = newsletterId;
    dispatch(updateCampaignStatus({ id, status })).then(
      res => onSuccess(res),
      err => onError(err)
    );
  }

  renderPauseOrPlayLink() {
    const { status } = this.state;
    if (["RECIPIENT_LIST_CREATED", "CREATED"].indexOf(status) > -1) {
      return (
        <li onClick={() => this.handleCampaignAction("PAUSE")}>
          <span className="sidebar__icon">
            <i className="icon-control-pause" />
          </span>
          <span className="sidebar__item-hover"> {_("pauseSending")}</span>
        </li>
      );
    } else if (status == "PAUSE") {
      return (
        <span>
          <li onClick={() => this.handleCampaignAction("UNPAUSE")}>
            <span className="sidebar__icon">
              <i className="icon-control-play" />
            </span>
            <span className="sidebar__item-hover"> {_("resume")}</span>
          </li>
          <li onClick={this.handleOpenStopDialog.bind(this)}>
            <span className="sidebar__icon">
              <i className="icomoon icon-stop warning" />
            </span>
            <span className="sidebar__item-hover"> {_("stopCampaign")} </span>
          </li>
        </span>
      );
    }
    return null;
  }

  render() {
    const {
      links,
      boucesDetails,
      deliveredCount,
      openCount,
      clickCount,
      unsubscribeCount,
      complaintCount,
      refusedCount,
      totalTargetCount,
      isFromCampaignStats
    } = this.props.stats.data;
    const {
      id,
      status,
      previewIsOpen,
      recipientListCreated,
      showChangeScheduledAtDate,
      showSentAt
    } = this.state;
    const { totalRecipients } = this.props;
    let processedCount = parseInt(refusedCount) + parseInt(deliveredCount);

    let processedRate = getRate(processedCount, totalRecipients);

    return (
      <div id="stats-page">
        <SidePageHeader
          subHeader="The campaign report"
          //returnTo={"/newsletter-ua/BASIC"}
          actionButton={
            recipientListCreated
              ? {
                  label: _("preview"),
                  icon: "icon-eye",
                  action: () => this.handlePreview(true)
                }
              : null
          }
          isUAVersion={true}
          handleSetCurrentPage={this.props.handleSetCurrentPage}
        />
        <ul id="sidebar">{this.renderPauseOrPlayLink()}</ul>
        <div className="stats-page">
          <Header processedRate={processedRate} status={status} />
          <div className="flex-container align-justify">
            <Summary showSentAt={showSentAt} />
            <Charts
              deliveredCount={deliveredCount}
              openCount={openCount}
              clickCount={clickCount}
              unsubscribeCount={unsubscribeCount}
              complaintCount={complaintCount}
              refusedCount={refusedCount}
              total={totalTargetCount}
              changeScheduledAtDate={() =>
                this.toggleChangeScheduledAtDate(true)
              }
            />
          </div>
          <BounceDetails boucesDetails={boucesDetails} />
          <LinksTracking links={links} clicked={this.handleClickStatsLink} />
          {!isFromCampaignStats && (
            <RecipientsStats
              renderRecipientStatsDatatable={this.renderRecipientStatsDatatable.bind(
                this
              )}
            />
          )}
        </div>
        <Viewer
          isOpen={previewIsOpen}
          onClose={() => this.handlePreview(false)}
          url={`${TTP_API_URL}/mailing/web-version?campaignId=${id}&access_token=${
            this.props.token
          }`}
        />
        {showChangeScheduledAtDate && (
          <ChangeScheduledAtDateForm
            isStatsPage={true}
            onCloseModal={() => this.toggleChangeScheduledAtDate(false)}
          />
        )}
      </div>
    );
  }
}
