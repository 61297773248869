import React, {Component} from "react";
import {connect} from "react-redux";
import _ from "i18n";
import "react-datepicker/dist/react-datepicker.css";
import {persistClientSettings} from "../../../actions/thunks/auth";
import Switch from "../../common/Switch";
import {onError, onSuccess} from "../../../services/utils";

@connect((store) => {
  return {
    settings: store.auth.currentSetting,
  };
})
export default class DashboardConfiguration extends Component {
  constructor(props) {
    super(props);

    this.handleEnableDashboardChange = (enableDashboard) => this.setState({ enableDashboard, showButtons: (enableDashboard !== this.props.settings.enableDashboard)});

    this.state = {
      enableDashboard: false,
      showButtons: false,
    };
  }
  componentDidMount() {
    this.undoChanges();
  }

  componentDidUpdate(prevProps, prevState) {
    let { enableDashboard,  fetched} = this.props.settings;
    if (prevProps.settings.fetching && fetched) {
      this.setState({
        enableDashboard: enableDashboard,
      });
    }
  }
  persistPreferences = () => {
    let { enableDashboard } = this.state;
    const { autoSend, mandatoryTest } = this.props.settings;
    let emailingPreferences =  { autoSend, mandatoryTest, enableDashboard};
    this.props.dispatch(persistClientSettings( {emailingPreferences} ))
      .then(res => {onSuccess(res); this.setState({showButtons : false})}, err => onError(err));
  }

  undoChanges = () => {
    const { enableDashboard } = this.props.settings;
    this.setState({ enableDashboard, showButtons : false });
  }
  render() {
    const {  enableDashboard, showButtons } = this.state;
    const { updating } = this.props.settings;
    return (
        <div className="approval-page">
        <div className="ttp-tab-container">
          <div
            className={`row small-8 settings-tab align-center`}>
            <div className="column small-10">
              <div className="row medium-12 title">
                <h3>{_("CONFIGURE YOUR COMMUNITY'S STATISTICS")}</h3>
              </div>
            </div>
            <div className="column small-10">
              <div className="setting_item">
            <span
              className="param-label text-capitalize">{_('Dashboard Page')}</span>
                <div className="setting-param">
                  <p
                    className="setting-param__detail"> {_('Activate / Deactivate statistics calculation')}</p>
                  <Switch name="enableDashboard" isChecked={enableDashboard}
                          onChange={(val) => this.handleEnableDashboardChange(val)}/>
                </div>
              </div>
            </div>
            {showButtons && <div className="row small-10 align-right">
              <button onClick={this.undoChanges}
                      className="btn secondary">{_('cancel')}</button>
              <button onClick={!updating && this.persistPreferences}
                      className="btn primary">{updating ? _('Processing...') : _('save')}</button>
            </div>}
          </div>
        </div>

      </div>

    );
  }
}



