import React, {Component} from "react";
import {connect} from 'react-redux';
import {setRecipientsPaginationPage} from 'actions';
import _ from 'i18n';
import TTPPaginator from "common/list/TTPPaginator";
import NotFound from "../../notFound/NotFound";
import {RecipientsListFetching} from "../../fetching/campaign/RecipientListFetching";

@connect((store) => {
    return {
        recipients: store.campaigns.recipients,
    }
})
export default class RecipientsList extends Component {

    constructor(props) {
        super(props);
    }

    handleRecipientListPageClick(data) {
        const {dispatch} = this.props;
        let selectedPage = data.selected;
        dispatch(setRecipientsPaginationPage(selectedPage + 1));
    };

    render() {
        const {recipients: {items, filter, nbResult, fetching}} = this.props;

        return ((!items || items.length === 0) && !fetching) ? <NotFound/> :
            <div className="row align-center">
                {fetching ? <RecipientsListFetching/> :
                    <div className="ttp-datatable small-11">
                        <div className="row ttp-datatable__header">
                            <div className="small-5 p-l-xs p-r-xs">{_('name')}</div>
                            <div className="small-7 p-l-xs p-r-xs">{_('email')}</div>
                        </div>
                        {items && items.length > 0 && items.map(({lastName, firstName, email}) => {
                            return (
                                <div key={email + lastName} className="row ttp-datatable__row">
                                    <div className="small-5 p-l-xs p-r-xs">{lastName} {firstName}</div>
                                    <div className="small-7 p-l-xs p-r-xs">{email}</div>
                                </div>
                            );
                        })}
                    </div>}
                <TTPPaginator
                    onPageChange={this.handleRecipientListPageClick.bind(this)}
                    pageSize={filter.pageSize}
                    nbResult={nbResult}
                    paginationPage={filter.paginationPage}/>
            </div>
    }
}
