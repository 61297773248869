import React, { Component } from "react";
import { connect } from "react-redux";
import { SVG_SEND_ICON, isValidEmail } from "utils";
import {
  inIframe,
  ucFirst,
  modalStyle,
  onSuccess,
  onError,
} from "../../../services/utils";
import { fetchEmailSettings, saveTextModel } from "../../../actions/thunks";
import TopBar from "./TopBar";
import DeliveryInfo from "./DeliveryInfo";
import {
  setEboxSubject,
  setEboxRecipients,
  setEboxMessage,
  setEboxWidgetStatus,
  setEboxReply,
  setEboxWidgetDynamicFields,
  setFreeSectionDatePickerDateTime,
  setFreeSectionDatePickerDate,
  setFreeSectionDatePickerTime,
  setCurrentEmailCampaignData,
  setTextModelsTargetApp,
} from "../../../actions/actions";
import BottomBar from "./BottomBar";
import RichEditor from "../../common/editor/RichEditor";
import AttachmentsContainer from "../../common/AttachmentsContainer";
import {
  callBackWidget,
  postWidgetLoadedMessage,
  FULL_SCREEN_WIDGET,
} from "../../../services/ebox/widget";
import cheerio from "cheerio";
import TTPModal from "../../ui/TTPModal";
import _ from "i18n";

const mapStateToProps = (state) => ({
  widget: state.ebox.widget,
  selectedText: state.currentCampaign.email.textModel,
  selectedTextName: state.currentCampaign.email.textModelName,
  selectedTextLanguage: state.currentCampaign.email.textModelLanguage,
  selectedTextTargetApp: state.currentCampaign.email.textModelTargetApp,
  content: state.ebox.widget.message,
  lng: state.params.lng,
  fields: state.ebox.widget.fields,
  textModelsFilterData: state.textModels.filter,
  updating: state.textModels.updating,
  isUaNewsletter: state.currentCampaign.uaNewsletter.isUaNewsletter,
});

const mapDispatchToProps = (dispatch) => ({
  setEboxWidgetDynamicFields: (fields) =>
    dispatch(setEboxWidgetDynamicFields(fields)),
  setEboxSubject: (subject) => dispatch(setEboxSubject(subject)),
  setEboxRecipients: (recipients) => dispatch(setEboxRecipients(recipients)),
  setEboxReply: (reply) => dispatch(setEboxReply(reply)),
  setEboxMessage: (message) => dispatch(setEboxMessage(message)),
  setEboxWidgetStatus: (status) => dispatch(setEboxWidgetStatus(status)),
  fetchCurrentSettings: (filter) => dispatch(fetchEmailSettings(filter)),
  fetchTextModels: (lng) => dispatch(fetchTextModels(lng)),
  setFreeSectionDatePickerDateTime: (dateTime) =>
    dispatch(setFreeSectionDatePickerDateTime(dateTime)),
  setFreeSectionDatePickerDate: (date) =>
    dispatch(setFreeSectionDatePickerDate(date)),
  setFreeSectionDatePickerTime: (time) =>
    dispatch(setFreeSectionDatePickerTime(time)),
  saveTextModel: (data) => dispatch(saveTextModel(data, false)),
  setCurrentCampaign: (data) => dispatch(setCurrentEmailCampaignData(data)),
  setTextModelsTargetApp: (targetApp) =>
    dispatch(setTextModelsTargetApp(targetApp)),
});
@connect(mapStateToProps, mapDispatchToProps)
export default class EmailComposer extends Component {
  state = {
    editorState: "",
    embedded: this.props.isUaNewsletter ? false : inIframe(),
    targetId: null,
    theme: null,
    isFreeSectionTab: false,
    showForm: false,
    isNext: false,
    paramstargetApp: null,
    paramstargetType: null,
    paramsLanguage: null,
    isEditAction: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.widget.status !== this.props.widget.status) {
      callBackWidget(this.props.widget.status);
    }

    if (
      this.props.widget.message === "" &&
      prevProps.widget.message !== this.props.widget.message
    ) {
      this.setState({ editorState: "" });
    }

    if (prevProps.selectedText !== this.props.selectedText) {
      this.props.setFreeSectionDatePickerDateTime(null);
      this.props.setFreeSectionDatePickerDate(null);
      this.props.setFreeSectionDatePickerTime(null);
    }
    window.addEventListener(
      "message",
      (msg) => {
        if (
          typeof msg.data === "object" &&
          msg.data.action === "SET_RECIPIENT_CONTEXT" &&
          msg.data.params
        ) {
          const { targetApp, language, targetType } = msg.data.params;
          if (
            this.state.paramstargetApp !== targetApp ||
            this.state.paramsLanguage !== language ||
            this.state.paramstargetType !== targetType
          ) {
            this.setState({
              paramstargetApp: targetApp,
              paramstargetType: targetType,
              paramsLanguage: language,
            });
          }
        }
      },
      false,
    );
  }

  handleEditorChange = (editorState) => {
    const { setEboxMessage } = this.props;
    this.setState({ editorState });
    setEboxMessage(editorState);
  };

  handleNameChange = (e) => {
    let name = e.target ? e.target.value : "";
    this.props.setCurrentCampaign({ textModelName: name });
  };

  componentDidMount() {
    window.addEventListener(
      "message",
      (msg) => {
        if (
          typeof msg.data === "object" &&
          msg.data.action === "SET_RECIPIENT_CONTEXT" &&
          msg.data.params
        ) {
          this.applyRecipientContext(msg.data.params);
          // set EDITOR as current tab
          if (msg.data.params.body) {
            this.setState({ isNext: true });
          }
        }
      },
      false,
    );

    postWidgetLoadedMessage();
    this.props.setFreeSectionDatePickerDateTime(null);
    this.props.setFreeSectionDatePickerDate(null);
    this.props.setFreeSectionDatePickerTime(null);
  }

  loadAppropriateTheme = ({ targetApp, targetId, language }) => {
    const filter = {
      targetApp: targetApp.toUpperCase(),
      targetId: targetId,
      language: language,
    };
    this.props
      .fetchCurrentSettings(filter)
      .then((res) => {
        if (!res.value.data || !res.value.data.data.length) {
          return;
        }
        let setting = res.value.data.data[0];
        const lng = language ? ucFirst(language) : "Fr";
        let theme =
          setting[`theme${lng}`] || setting[`themeFr`] || setting[`themeNl`];

        theme
          ? this.setState({ theme: theme.content })
          : this.setState({ theme: "" });
      })
      .catch((er) => {
        this.setState({ theme: "" });
      });
  };

  applyRecipientContext = (data) => {
    const {
      email,
      dynamicFields,
      recipientId,
      targetApp,
      language,
      targetType,
      recipientType,
      targetId,
      reply,
      body,
      subject,
    } = data;
    this.props.setEboxWidgetDynamicFields(dynamicFields);

    this.props.setCurrentCampaign({
      textModel: null,
      textModelName: "",
      html: body,
      textModelTitle: subject,
    });
    this.props.setTextModelsTargetApp(targetApp);
    if (
      this.state.paramstargetApp !== targetApp &&
      this.state.paramsLanguage !== language &&
      this.state.paramstargetType !== targetType
    ) {
      this.setState({
        paramstargetApp: targetApp,
        paramstargetType: targetType,
        paramsLanguage: language,
      });
    }
    this.props.setEboxWidgetStatus("OPENED");
    if (!email || !isValidEmail(email)) {
      return;
    }

    if (this.state.targetId !== targetId && targetId && data.targetApp) {
      this.setState({ targetId });
      this.loadAppropriateTheme(data);
    }
    const { recipients } = this.props.widget;

    let newRecipients = [...recipients];
    const recipient = { email, recipientId, recipientType };

    if (body !== this.state.editorState) {
      newRecipients = [recipient];
    }

    this.props.setEboxRecipients(newRecipients);
    this.props.setEboxReply(reply || []);

    if (body) {
      this.handleEditorChange(body);
      this.setState({ editorState: body });
      this.props.setEboxMessage(body);
    }
    if (subject) {
      this.props.setEboxSubject(subject);
    }
  };

  handleObjectChange = (e) => {
    this.props.setEboxSubject(e.target ? e.target.value : e);
  };

  setIsFreeSectionTab = (isFreeSectionTab) => {
    this.setState({ isFreeSectionTab });
  };

  setIsTextModelTab = (isTextModelTab) => {
    this.setState({ isTextModelTab });
  };

  handleNextAction = (previousTab) => {
    if (previousTab === "FREE_SECTION") {
      this.setState({ isNext: this.isNextValid() });
    } else {
      this.setState({ isNextTextModel: true });
    }
  };

  isNextValid = () => {
    let { content } = this.props;
    const $ = cheerio.load(content);
    let freeSections = $("span").data("type", "FREE_SECTION");
    let fsSpans = [];
    for (let i = 0; i < freeSections?.length; i++) {
      fsSpans.push(freeSections[i]);
    }
    let notReplacedFreeSections = fsSpans.filter(
      (freeSection) => freeSection.attribs["data-formated-name"] !== "",
    );
    return notReplacedFreeSections.length === 0;
  };

  toggleSaveTextModelForm = () => {
    const { showForm } = this.state;
    this.setState({ showForm: !showForm });
  };

  handleAddTextModel = (action = "SAVE") => {
    const { language, targetApp } = this.props.textModelsFilterData;
    const { subject, message } = this.props.widget;
    const {
      selectedTextName,
      selectedText,
      selectedTextLanguage,
      selectedTextTargetApp,
    } = this.props;
    let data = {
      targetApp,
      targetType: "EXPRESS",
      name: selectedTextName,
      object: subject,
      content: message,
      main: 0,
    };
    if (action !== "SAVE") {
      data.id = selectedText;
      this.setState({ isEditAction: true });
      data.language = selectedTextLanguage;
      data.targetApp = selectedTextTargetApp;
    } else {
      data.language =
        ["ALL", "TOUS", "ALLE"].indexOf(language) > -1 && !selectedText
          ? this.props.lng
          : selectedText
            ? selectedTextLanguage
            : language;
    }
    this.props.saveTextModel(data).then(
      (res) => {
        onSuccess(res);
        this.setState({ showForm: false, isEditAction: false });
        this.props.setCurrentCampaign({
          textModel: res.value.id,
          textModelScope: "COMMUNITY",
        });
      },
      (err) => onError(err),
    );
  };

  cancelIsNext = (previousTab) => {
    if (previousTab === "FREE_SECTION") {
      this.setState({ isNext: false });
    } else {
      this.setState({ isNextTextModel: false });
    }
  };

  render() {
    const {
      embedded,
      theme,
      isFreeSectionTab,
      isNext,
      isTextModelTab,
      isNextTextModel,
      showForm,
      isEditAction,
    } = this.state;
    const { status, subject, attachments, message } = this.props.widget;
    const { updating, selectedTextName, selectedText } = this.props;

    const url = new URL(window.location.href);
    let URLtargetApp = url.searchParams.get("targetApp");
    let URLlanguage = url.searchParams.get("language");
    let URLtargetType = url.searchParams.get("targetType");

    const minimized = status === "MINIMIZED";
    const fullScreen = status === FULL_SCREEN_WIDGET;
    const customModalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle.content,
        width: "55%",
        overflow: "visible",
        padding: 0,
        maxWidth: "53%",
      },
    };
    const saveActivated = selectedTextName && selectedTextName.length > 0;

    return (
      <div
        id="email-composer"
        className={`${!embedded && "email-composer__widget"} ${minimized && "email-composer__minimized"}`}
      >
        <div className={`email-composer-model`}>
          <TopBar
            label="send an email"
            svgIcon={SVG_SEND_ICON}
            cssClass={"bar"}
          />
          <div className="email-composer-model__body">
            <DeliveryInfo />
            <div className="row align-center rich-editor-component">
              <div className="main-content columns">
                <RichEditor
                  paramstargetApp={this.state.paramstargetApp}
                  paramstargetType={this.state.paramstargetType}
                  paramsLanguage={this.state.paramsLanguage}
                  URLtargetApp={URLtargetApp}
                  URLlanguage={URLlanguage}
                  URLtargetType={URLtargetType}
                  mode={"REDUCED"}
                  content={message}
                  subject={subject}
                  onEditorChange={this.handleEditorChange}
                  onObjectChange={this.handleObjectChange}
                  theme={theme}
                  setIsFreeSectionTab={this.setIsFreeSectionTab}
                  setIsTextModelTab={this.setIsTextModelTab}
                  isNextValid={this.isNextValid()}
                  isNext={isNext}
                  isNextTextModel={isNextTextModel}
                  cancelIsNext={(previousTab) => this.cancelIsNext(previousTab)}
                  embedded={embedded}
                />
              </div>
            </div>
            {!isFreeSectionTab && !isTextModelTab && (
              <div className="attachments-container">
                <AttachmentsContainer attachments={attachments} />
              </div>
            )}
          </div>
          <BottomBar
            data={this.props.widget}
            isFreeSectionTab={isFreeSectionTab}
            isTextModelTab={isTextModelTab}
            handleNextAction={(previousTab) =>
              this.handleNextAction(previousTab)
            }
            isNext={this.isNextValid()}
            toggleSaveTextModelForm={this.toggleSaveTextModelForm}
            handleEditTextModel={() => this.handleAddTextModel("EDIT")}
          />
        </div>
        {showForm && (
          <TTPModal
            isOpen={true}
            onClose={this.toggleSaveTextModelForm}
            modalStyle={customModalStyle}
            title={_("Save text model")}
          >
            <div id="theme-form" className="templates-settings__body">
              <div className="row align-center">
                <div className="row small-8 align-justify">
                  <div className="column small-12">
                    <span className="param-label">{_("name")}</span>
                    <div className="setting-param">
                      <input
                        type="text"
                        value={selectedTextName}
                        onChange={this.handleNameChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="templates-settings__footer">
              <button
                onClick={this.toggleSaveTextModelForm}
                className="btn secondary"
              >
                {_("cancel")}
              </button>
              <button
                onClick={() =>
                  !updating && saveActivated && this.handleAddTextModel("SAVE")
                }
                className={`btn primary ${!saveActivated && "disabled"}`}
              >
                {updating ? _("Processing...") : _("save")}
              </button>
            </div>
          </TTPModal>
        )}
      </div>
    );
  }
}
