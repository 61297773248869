import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import {setEventsFilters} from 'actions';
import {fetchEvents} from 'thunks';
import { PARTNER_TYPES } from 'Common';
import TTPSelect from "common/TTPSelect";
import {ucFirst} from "../../../../../services/common";

@connect((store) => {
  return {
    events: store.filters.events.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters,
    campaignLanguage: store.currentCampaign.email.language
  }
},(dispatch) => {
  return {
    handleEventChange: (eventId) => dispatch(setEventsFilters({property: 'event.id', operator: 'eq', value: eventId})),
    handleTypeChange: (types) => dispatch(setEventsFilters({property: 'type', operator: 'in', value: (types && types.length>0)?types:""})),
    fetchEvents: () => dispatch(fetchEvents()),
  }
})
export default class PartnerFilters extends Component {

  componentWillMount(){
    this.props.fetchEvents();
  }

  componentWillReceiveProps(nextProps) {
    const {events, handleEventChange, eventFilters} = nextProps;
    if(events && events.length>0 && eventFilters.length == 0){
      handleEventChange(events[0]['id']);
    }
  }

  render () {
    const {events, campaignLanguage, handleEventChange, handleTypeChange, eventFilters} = this.props;

    let selectedEvent = null;
    let selectedTypes = null;

    for(let filter of eventFilters){
      if('event.id' == filter.property){
        selectedEvent = filter.value;
      }
      if('type' == filter.property){
        selectedTypes = filter.value;
      }
    }

    let campaignLng = ucFirst(campaignLanguage);
    let eventOptions = events.map(event => {
      return {
        label: event[`name${campaignLng}`] || event['nameFr'] || event['nameNl'] || event['nameEn'],
        id: event.id + ""
      };
    });

    let partnerTypes = PARTNER_TYPES.map((type, index) => {return {label: type.label, id: type.id};});

    return (
      <div className="row align-center">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{ _('event')}</h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={selectedEvent}
              placeholder={_('Select event')}
              options={eventOptions}
              onChange={handleEventChange}/>
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">Types</h1>
            <TTPSelect
              values={selectedTypes}
              placeholder={_('all').toUpperCase()}
              options={partnerTypes}
              onChange={handleTypeChange}/>
          </div>
        </div>
      </div>
    );
  }
}
