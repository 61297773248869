import * as actions from 'actions';
import { getClientSettings, savePreferences, setSelectedOrganization } from 'TamtamAPI';
import {
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
} from 'actions';
import { getTTPUser } from 'TamtamAPI';
import { setCookie } from 'utils';
import { APP_ENV } from 'Config';

export const setAuthUser = (user) => {
  return (dispatch, getState) => {
    dispatch(actions.setAuthToken(user.token));
    dispatch(setAuthToken(user.token));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    return dispatch(actions.fetchAuthUser(getTTPUser(user.id, user.token)));
  };
};

export const persistClientSettings = (data) => {
  return (dispatch, getState) => {

    const { token, currentClient } = getState().auth;
    const { organizationSettingsId } = currentClient;

    return dispatch(actions.saveClientSettings(
      savePreferences(token, { ...data, organizationSettingsId })
    ));
  };
};

export const fetchClientSettings = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    let organizationSettingsId = (currentClient && currentClient.organizationSettingsId) || null;

    return dispatch(actions.fetchClientSettings(
      getClientSettings(token, organizationSettingsId)
    ));
  };
};

export const setCurrentClient = client => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.setCurrentClient(
    setSelectedOrganization(token, client).then(() => {
      let clientData = {
        id: client.id,
        abbreviation: client.abbreviation,
        name: client.name,
        avatarUrl: client.avatarUrl
      }

      const dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() + 86400 * 1000 * 3); //3j
      setCookie(`ttp_community_${APP_ENV}`, JSON.stringify(clientData), dtExpire, '/', 'tamtam.pro');
      return client;
    }, err => err)))
};

