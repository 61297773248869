import _ from "i18n";
import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

import { getUserNameForAvatar } from "utils";
import { fetchEmails } from "thunks";
import debounce from "lodash.debounce";
import { LoadingSuggestion } from "./LoadingSuggestion";

@connect((store) => {
  return {
    emailsSuggestions: store.emails.items,
    loadingUsersSuggestions: store.emails.fetching,
    suggestionsError: store.emails.error,
    currentStep: store.currentCampaign.email.currentSideBar,

  };
})
export default class UsersTagSuggestion extends Component {
  constructor(props) {
    super(props);
    this.handleChangeInput = (tag) => this.setState({ tag });
    this.state = { tag: "", fetchValue: null };
    this.debouncedFetchEmailsSuggestions = debounce(
      this.fetchEmailsSuggestions,
      1000
    );
  }

  fetchEmailsSuggestions({ value }) {
    if (value != this.state.fetchValue) {
      const { dispatch, authorizedOnly } = this.props;
      dispatch(fetchEmails(value, authorizedOnly));
      this.setState({ fetchValue: value });
    }
  }

  autoSuggestRenderInput({ addTag, ...props }) {
    const {
      emailsSuggestions,
      loadingUsersSuggestions,
      suggestionsError,
    } = this.props;

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };
    let suggestions = emailsSuggestions;

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={suggestions}
        shouldRenderSuggestions={(value) => value && value.trim().length > 1}
        getSuggestionValue={(suggestion) => suggestion.email}
        renderSuggestion={(suggestion) => {
          const { user, email } = suggestion;
          return (
            <div className="user-suggestion__item">
              {user.avatarUrl ? (
                <div
                  className="img-wrap"
                  style={{ backgroundImage: `url(${user.avatarUrl})` }}
                ></div>
              ) : (
                <div className="img-wrap empty-avatar">
                  <span style={{ fontSize: "inherit" }}>
                    {getUserNameForAvatar(user.firstName, user.lastName)}
                  </span>
                </div>
              )}
              <div className="content">
                <h4>
                  {user.firstName} {user.lastName}
                </h4>
                <p>{email}</p>
              </div>
            </div>
          );
        }}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag({
            id: suggestion.id,
            userId: suggestion.user.id,
            name: `${suggestion.user.lastName.toUpperCase()} ${
              suggestion.user.firstName
            }`,
            email: suggestion.email,
          });
        }}
        onSuggestionsClearRequested={() => {}}
        onSuggestionsFetchRequested={(value) =>
          this.debouncedFetchEmailsSuggestions(value)
        }
        renderSuggestionsContainer={({ containerProps, children, query }) => (
          <LoadingSuggestion
            containerProps={containerProps}
            loadingsSuggestions={loadingUsersSuggestions}
            suggestionsError={suggestionsError}
            value={query}
          >
            {children}
          </LoadingSuggestion>
        )}
      />
    );
  }

  handleTagsChange(tags, changed, changedIndexes) {
    const { currentStep } = this.props;
  
    // Si currentStep est "PARAMS", limiter à un seul tag
    if (currentStep === "PARAMS") {
      if (tags.length > 1) {
        tags = [changed[0]];  
      }
    }
  
    // Filtrer et vérifier si l'email existe dans suggestions
    let suggestions = this.props.emailsSuggestions;
    if (
      changed &&
      changed.length > 0 &&
      suggestions.filter(
        (t) => t.email.toLowerCase() === changed[0].email.toLowerCase()
      ).length === 0
    ) {
      tags.splice(changedIndexes[0], 1); 
    }
  
    this.props.onTagsChange(tags);
  }
  
  renderCustomTag(props) {
    const { displayEmail } = this.props;
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props;
    const { email, name } = tag;
    return (
      <span key={key} {...other}>
        <div>{name}</div>
        <div>{displayEmail ? email : ""} </div>
        {!disabled && (
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        )}
      </span>
    );
  }

  render() {
    let { selectedTags, displayEmail, mainClass } = this.props;
    return (
      <div className={`columns user-suggestion ${mainClass}`}>
        <TagsInput
          renderInput={this.autoSuggestRenderInput.bind(this)}
          value={selectedTags}
          onChange={this.handleTagsChange.bind(this)}
          addKeys={[9, 13, 186, 188]}
          removeKeys={[]}
          tagDisplayProp="email"
          onlyUnique={true}
          addOnPaste
          inputProps={{
            placeholder: displayEmail ? _("emailAdd") : _("userAdd"),
          }}
          inputValue={this.state.tag}
          onChangeInput={this.handleChangeInput.bind(this)}
          renderTag={this.renderCustomTag.bind(this)}
        />
      </div>
    );
  }
}
