import React, { Component } from "react";
import { connect } from "react-redux";
import { DragSource } from "react-dnd";
import { NotificationManager } from "react-notifications";
import { compose } from "redux";

import {
  addFeedToCurrentLayout,
  removeFeedFromLayout,
  addFeedToNewsletter,
} from "actions";
import { TTP_BLOG_URL } from "Config";
import { formatArticle } from "utils";
import _ from "i18n";
import { TEMPLATE_TYPES } from "../../constants";
import { SVG_UP_ICON } from "../../services/svgIcones";
import ToggleButton from "../ui/ToggleButton";
import { SVG_ICONS } from "../../config/Common";
import { ucFirst } from "../../services/common";

class ArticleItem extends Component {
  constructor(props) {
    super(props);

    this.toogleDetails = () =>
      this.setState((prevState) => ({ showMore: !prevState.showMore }));
    this.state = {
      showMore: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.isSelected !== this.props.isSelected ||
      nextState.showMore !== this.state.showMore ||
      nextProps.viewMode !== this.props.viewMode ||
      nextProps.order !== this.props.order
    );
  }

  addArticle = () => {
    let { currentLayout, base, blocks, article} = this.props;

    if (
      base === "TEMPLATE" &&
      (!currentLayout || currentLayout.type !== article.type)
    ) {
      if (!blocks.some(({ feeds, column }) => feeds.length < column)) {
        NotificationManager.info(_("All blocks are filled"));
        return;
      }
      this.props.addFeedToNewsletter({ ...article, type: "NEWS_LAYOUT" });
      return;
    }
    if (!currentLayout && base !== "TEMPLATE") {
      NotificationManager.info(_("Select a layout for your block first"));
      return;
    }

    if (currentLayout.type !== "NEWS_LAYOUT") {
      NotificationManager.info(
        _("Current layout is not the appropriate block type")
      );
      return;
    }

    this.props.addFeedToCurrentLayout(article);
  };

  removeArticle = () => {
    const {
      removeFeedFromLayout,
      article: { id },
    } = this.props;
    removeFeedFromLayout(id);
  };

  renderArticleCover = () => {
    let { cover } = this.props.article;

    return (
      <div>
        <div
          className="feed__media"
          style={{ backgroundImage: `url(${cover})` }}
        />
      </div>
    );
  };

  renderAuthor = () => {
    const { authorName, authorAvatar, publishedAt } = this.props.article;

    return (
      <div className="feed_author">
        <div
          className="feed_author-avatar"
          style={{ backgroundImage: `url(${authorAvatar})` }}
        />
        <div className="flex-container flex-dir-column">
          <span className="feed_author-name">{authorName}</span>
          <span className="date">{publishedAt}</span>
        </div>
      </div>
    );
  };

  render() {
    const {
      lng,
      isSelected,
      connectDragSource,
      viewMode,
      order,
      article: {
        id,
        title,
        externalUrl,
        url,
        introduction,
        category,
        color,
        tagsArray,
      },
    } = this.props;

    const helpUrl =
      externalUrl && externalUrl.length > 0
        ? externalUrl
        : `${TTP_BLOG_URL}article/${url}/${id}`;

    const listMode = viewMode === "LIST";
    const { showMore } = this.state;

    return connectDragSource(
      <div className={`medium-${listMode ? 12 : 6} column`} style={{ order }}>
        <div
          className={`feed ${showMore && "feed-expanded"} ${
            isSelected ? "selected" : ""
          }`}
        >
          <img className="feed__expander" src="/img/utils/expander.png" />
          <div
            className="flex-container flex-dir-column"
            onClick={isSelected ? this.removeArticle : this.addArticle}
          >
            <div className="flex-container">
              {this.renderArticleCover()}
              <div className="feed_content">
                {this.renderAuthor()}
                <h3
                  className="feed_content-title"
                  style={
                    !showMore && !listMode
                      ? { overflow: "hidden", height: "2.2rem" }
                      : {}
                  }
                >
                  <a>{title}</a>
                </h3>
                {listMode && !showMore && (
                  <p className="feed_content-summary">
                    {introduction.slice(0, 210) + "..."}
                  </p>
                )}
              </div>
            </div>
            {showMore && (
              <div className="feed-detail">
                <p className="feed_content-summary m-t-s m-b-s">
                  {introduction}
                </p>
                <div className="feed__tags">
                  <span>
                    {tagsArray &&
                      tagsArray.map(({ id, tagName }) => (
                        <a
                          href="#"
                          className={`article_tag-${id}`}
                          key={`tag-${id}-${Math.random()}`}
                        >
                          {tagName}
                        </a>
                      ))}
                  </span>
                </div>
                <a href={helpUrl} target="_blank">
                  <img
                    src={"/img/icons/" + SVG_ICONS["OPEN_LINK"]}
                    alt="new tab"
                  />
                </a>
              </div>
            )}
          </div>
          {category ? (
            <span className="feed__category" style={{ backgroundColor: color }}>
              {category}
            </span>
          ) : null}
          <ToggleButton
            cssClass={"apply-icon"}
            onToggleSelection={
              isSelected ? this.removeArticle : this.addArticle
            }
            checked={isSelected}
          />
          {isSelected && <div className={"feed-order"}>{order + 1}</div>}
          <div className="read-more" onClick={this.toogleDetails}>
            {_(showMore ? "close" : "see more")}
            <span className={`${!showMore && "down"}`}>{SVG_UP_ICON}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lng: state.params.lng,
  currentLayout: state.themes.newsletter.currentLayout,
  blocks: state.themes.newsletter.blocks,
  base: state.themes.newsletter.base,
});

const mapDispatchToProps = (dispatch) => ({
  addFeedToCurrentLayout: (article) =>
    dispatch(addFeedToCurrentLayout(article)),
  addFeedToNewsletter: (article) => dispatch(addFeedToNewsletter(article)),
  removeFeedFromLayout: (id) => dispatch(removeFeedFromLayout(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragSource(
    TEMPLATE_TYPES["NEWS_LAYOUT"],
    {
      beginDrag: (props) => {
        return formatArticle(props.article);
      },
    },
    (connect) => ({
      connectDragSource: connect.dragSource(),
    })
  )
)(ArticleItem);
