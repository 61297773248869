import React from "react";
import { TTP_API_URL } from "Config";
import { connect } from "react-redux";

import Viewer from "../../common/Viewer";
import { completeCampaignSending, fetchCampaignEmails, fetchCampaignNewTargetsList } from "thunks";
import { setRecipientsPageSize } from "actions";
import { onSuccess, onError } from "utils";
import _ from "i18n";
import EmailTagsInput from "../../common/campaign/EmailTagsInput";
import { MANUAL_RECIPIENT_TYPE } from "../../../config/Common";
import RecipientsList from "./RecipientsList";

@connect(
  store => ({
    currentCampaign: store.currentCampaign.email,
    paginationPage: store.campaigns.recipients.filter.paginationPage,
    newTargetsCount: store.campaigns.recipients.nbResult,
  }),
  dispatch => ({
    completeCampaignSending: (data) => dispatch(completeCampaignSending(data)),
    fetchCampaignEmails: (campaignId) => dispatch(fetchCampaignEmails(campaignId)),
    fetchCampaignNewTargetsList: (campaignId) => dispatch(fetchCampaignNewTargetsList(campaignId)),
    setRecipientsPageSize: (pageSize) => dispatch(setRecipientsPageSize(pageSize)),
  })
)
export default class CampaignCompleteViewer extends React.Component {

  constructor(props) {
    super(props);
    this.handleSendedEmailsChange = emails => this.setState({ sendedEmails: emails });
    this.state = {
      updating: false,
      emails: [],
      sendedEmails: [],
      newEmails: []
    };
  }

  componentDidMount() {
    const { fetchCampaignEmails, currentCampaign: { id, recipientType }, fetchCampaignNewTargetsList, setRecipientsPageSize } = this.props;
    if (MANUAL_RECIPIENT_TYPE == recipientType) {
      fetchCampaignEmails(id).then(res => this.handleSendedEmailsChange(res.value ? res.value.data : []));
    } else {
      const pageSize = Math.floor((window.innerHeight * 0.9 - 210) / 58);
      setRecipientsPageSize(pageSize);
      fetchCampaignNewTargetsList(id);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { paginationPage, currentCampaign: { id, recipientType }, fetchCampaignNewTargetsList } = this.props;
    if (MANUAL_RECIPIENT_TYPE !== recipientType && paginationPage !== prevProps.paginationPage) {
      fetchCampaignNewTargetsList(id);
    }
  }

  completeCampaign = () => {
    let { id, recipientType } = this.props.currentCampaign;
    const { emails } = this.state;
    if (MANUAL_RECIPIENT_TYPE == recipientType && emails.length == 0) {
      return;
    }
    this.setState({ updating: true });
    let data = { id };
    if (MANUAL_RECIPIENT_TYPE == recipientType) {
      data['emails'] = emails;
    }
    this.props.completeCampaignSending(data).then(res => {
      this.setState({ updating: false });
      this.props.onClose();
      onSuccess(res)
    }, err => {
      this.setState({ updating: false });
      onError(err)
    });
  };

  handleEmailChange = (emails) => {
    this.setState(prevState => (
      {
        emails,
        newEmails: emails ? emails.filter(email => prevState.sendedEmails.indexOf(email) == -1) : []
      }
    )
    );
  }

  renderEmailTag = (props) => {
    let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
    const email = getTagDisplayValue(tag);

    return (
      <span key={key} {...other} className={`${other.className} ${this.state.sendedEmails.indexOf(email) > -1 ? 'alert' : ""}`}>
        {email}
        {!disabled &&
          <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        }
      </span>
    )
  }

  render() {
    const { onClose, currentCampaign: { id, recipientType }, newTargetsCount } = this.props;
    const { emails, newEmails } = this.state;
    const isManualType = MANUAL_RECIPIENT_TYPE == recipientType;
    const addedRecipientsCount = isManualType ? (newEmails ? newEmails.length : 0) : newTargetsCount;
    return (
      <Viewer
        isOpen={true}
        onClose={onClose}
        rightCell={6}
        url={`${TTP_API_URL}/mailing/web-version?campaignId=${id}`}>
        <div className="align-right p-b-s p-t-s viewer__info">
          <button className={`btn primary ${addedRecipientsCount == 0 ? 'disabled' : ''}`} onClick={() => addedRecipientsCount == 0 ? {} : this.completeCampaign(id)}>
            {this.state.updating ? _("Processing...") : _('complete')}
            <span className="btn-badge">
              {addedRecipientsCount}
            </span>
          </button>
        </div>
        {isManualType ?
          <EmailTagsInput
            light
            cssClass="m-s p-xs"
            emails={emails}
            changed={this.handleEmailChange}
            renderTag={this.renderEmailTag}
          />
          : <RecipientsList />}
      </Viewer>);
  }
}

