import React, { Component } from "react";
import { connect } from 'react-redux';
import Async from 'react-select/async';
import { components } from 'react-select';

import _ from 'i18n';
import { setCurrentEmailCampaignData } from 'actions';
import { fetchSurveys, fetchGroups } from 'thunks';
import { SURVEY_TYPES } from 'constants';
import TTPSelect from "common/TTPSelect";
import GroupsFilters from "../GroupsFilters";

@connect((store) => {
  return {
    language: store.params.lng,
    selectedSurvey: store.currentCampaign.email.surveyInstance,
    surveyType: store.currentCampaign.email.surveyType,
    surveyId: store.currentCampaign.email.targetId
  }
}, (dispatch) => {
  return {
    handleSurveyChange: (survey) => dispatch(setCurrentEmailCampaignData({ surveyInstance: survey })),
    handleTypeChange: (type) => dispatch(setCurrentEmailCampaignData({ surveyType: type })),
    fetchSurveys: (searchWord) => dispatch(fetchSurveys(searchWord)),
    fetchGroups: () => dispatch(fetchGroups('CONTACT')),
  }
})
export default class SurveyFilters extends Component {

  componentWillMount() {
    this.props.fetchGroups();
  }

  loadSurveysOptions = (inputValue, callback) => {
    const { language, selectedSurvey, handleSurveyChange, surveyId } = this.props;
    this.props.fetchSurveys(inputValue).then((res) => {
      let lng = language.charAt(0).toUpperCase() + language.slice(1).toLowerCase();

      if (!res.value) {
        callback([]);
      }

      let options = [];
      res.value.data.data.forEach(({ id, title, latestInstance, revivalMinDays }) => {
        if (latestInstance && latestInstance.id) {
          let label = title[lng] || title['fr'] || title['nl'];
          const survey = { label, id: latestInstance.id + "", revivalMinDays };
          if (id == surveyId) {
            handleSurveyChange(survey);
          }
          options.push(survey);
        }
      });

      if (!selectedSurvey && options && options.length > 0 && !surveyId) {
        handleSurveyChange(options[0]);
      }
      callback(options);
    }).catch(() => callback([]));
  }

  render() {
    const { language, handleSurveyChange, handleTypeChange, selectedSurvey, surveyType, surveyId } = this.props;


    let lng = language.charAt(0).toUpperCase() + language.slice(1).toLowerCase();
    let currentValue = selectedSurvey;

    if (selectedSurvey && selectedSurvey.title) {
      const { title, id, revivalMinDays } = selectedSurvey;
      currentValue = { id: id + "", label: title[lng] || title['fr'] || title['nl'], revivalMinDays };
    }

    let surveyTypes = SURVEY_TYPES.map(({ key, value, detail }) => {
      return {
        id: key,
        label: _(value).toUpperCase(),
        content: _(detail),
        revivalMinDays: (["REVIVAL", "PARTIAL_REVIVAL"].indexOf(key) != -1 && currentValue && currentValue.revivalMinDays) ? currentValue.revivalMinDays : null
      };
    });

    return (
      <div>
        <div className="row align-center">
          <div className="columns small-6 columns-padding">
            <div className="sidebar__form-control">
              <h1 className="param__title">{_('survey')}</h1>
              <Async
                cacheOptions
                className='ttp-select'
                classNamePrefix="ttp-select"
                defaultOptions
                value={currentValue}
                onChange={inputValue => handleSurveyChange(inputValue)}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.label}
                loadOptions={this.loadSurveysOptions}
                noOptionsMessage={() => _("Nothing Found")}
                loadingMessage={() => _("Loading suggestions ...")}
              />
            </div>
          </div>
          <div className="columns small-6 columns-padding">
            <div className="sidebar__form-control">
              <h1 className="param__title">{_('Type')}</h1>
              <TTPSelect
                simple={true}
                values={surveyType}
                options={surveyTypes}
                customOption={CustomTypeOption}
                notClearable={true}
                onChange={handleTypeChange} />
            </div>
          </div>
        </div>
        {('INVITATION' == surveyType) && <GroupsFilters />}
      </div>
    );
  }
}

const CustomTypeOption = props => {
  const { data } = props;

  return <components.Option {...props} >
    <div className="select-option">
      <div className="flex-container">
        <span className="select-option__label">{data.label}</span>
        {data.revivalMinDays && <span className="select-option__badge">>= {data.revivalMinDays} {_('days')}</span>}
      </div>
      <span className="select-option__content">{data.content}</span>
    </div>
  </components.Option>
};
