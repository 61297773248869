import React, { Component } from "react";
import { connect } from "react-redux";
import BarChartComponent from "./BarChartComponent";
import CampaignsFilter from "./CampaignsFilter";
import {fetchCampaignsData} from "../../../../actions/thunks/dashboard";

class CampaignsChart extends Component {
  componentDidMount() {
    this.props.dispatch(fetchCampaignsData());
  }
  componentDidUpdate(prevProps, prevState) {
    const { typeEmail} = prevProps;
    if (typeEmail !== this.props.typeEmail) {
      this.props.dispatch(fetchCampaignsData());
    }
  }
  render() {
    const { dataSentChart } = this.props;


    const dataKeysY = [
      { key: "campaigns", label: "nombre de campaigns", color: "#F7953E" },
    ];

    const yAxisTitle = "Number of emails by type";

    const dataKeyX = { key: "date", label: "date" };

    return (
      <div>
     <CampaignsFilter/>
      <BarChartComponent
        dataChart={dataSentChart}
        dataKeysY={dataKeysY}
        dataKeyX={dataKeyX}
        filter={true}
        yAxisTitle={yAxisTitle}
      />
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  dataSentChart: store.dashboard.dataSentChart,
  typeEmail: store.dashboard.typeEmail,
});
export default connect(mapStateToProps)(CampaignsChart);

