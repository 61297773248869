//SES email status
export const SES_FAILED_STATUS = 'FAILED';
export const SES_SUCCESS_STATUS = 'SUCCESS';
export const SES_PENDING_STATUS = 'PENDING';
export const SES_UNREGISTERED_STATUS = 'UNREGISTERED';

export const getMainEmail = (emails) => {
  if (!emails || !emails.length) {
    return null;
  }
  return emails.find(e => e.main == 1) || emails[0];
}

export const getEmailRegistrationStatus = (registeredEmails, email) => {
  let registrationStatus = SES_UNREGISTERED_STATUS;

  const emailKey = Object.keys(registeredEmails).find(key => key === email);
  if (emailKey) {
    registrationStatus = registeredEmails[emailKey].VerificationStatus.toUpperCase();
  }

  return (registrationStatus == SES_FAILED_STATUS) ? SES_UNREGISTERED_STATUS : registrationStatus;
}
