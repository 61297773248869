import React, {Component} from 'react';
import {connect} from "react-redux";
import _ from 'i18n';
import SearchBox from "../common/filter/SearchBox";
import {MAILING_LISTS_MANDATORY_FILTER} from "../../constants";
import {
  setMailingListsMandatoryFilter,
  setMailingListsPageSize,
  setMailingListsSearchWord
} from "../../actions/actions/mailingList";
import {LIST_PAGE_SIZES_MAILING_LISTS} from "../../config/Common";
import PageSize from "../common/list/PageSize";

@connect()
class MailingListsFilter extends Component {
  constructor(props) {
    super(props);
    this.mandatoryOptions = MAILING_LISTS_MANDATORY_FILTER.map(({key, value, color}) => ({
      value,
      label: _(key),
      color
    }));
  };

  handleSearchInputChange = (searchWord) => {
    this.props.dispatch(setMailingListsSearchWord(searchWord));
  };

  handleSelectPageSize = (pageSize) => {
    this.props.dispatch(setMailingListsPageSize(pageSize));
  };

  handleSelectMandatory = (selected) => {
    this.props.dispatch(setMailingListsMandatoryFilter(selected));
  };

  render() {
    const {filter: {pageSize, mandatory}} = this.props;
    return (
      <div id="ttp-horizontal-filter" className="medium-12">
        <div className="filter-wrapper">
          <div className="filter-wrapper__tabs">
            {[{label: _("all"), value: null}].concat(this.mandatoryOptions).map(
              ({value, label, color}) =>
                <span key={value}
                      style={color ? {borderColor: color} : {}}
                      onClick={() => this.handleSelectMandatory(value)}
                      className={`${mandatory === value ? "active" : ""}`}>
                  {_(label)}
                </span>
            )}
          </div>
          <SearchBox onChange={this.handleSearchInputChange}/>
          <PageSize pageSize={pageSize} onChange={this.handleSelectPageSize} listPageSize={LIST_PAGE_SIZES_MAILING_LISTS}/>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (store) => ({
  filter: store.mailingLists.filter
});

export default connect(mapStateToProps, null)(MailingListsFilter);


