import React, { Component } from "react";
import { connect } from 'react-redux';

import { modalStyle } from 'utils';
import { setMediasOrigin } from 'actions';
import ImageUploader from "./ImageUploader";
import ImageGallery from "./ImageGallery";
import TTPTab from "ui/tabs/TTPTab";
import TTPModal from "../../ui/TTPModal";
import ImageEditor from "./ImageEditor";

@connect((store) => {
    return {
        origin: store.medias.origin,
    }
})
export default class ImageSelector extends Component {

    constructor(props) {
        super(props);
        this.state = { ...props, activeTab: UPLOAD_TAB, imageUrl: null };
    }

    selectTab = (activeTab) => {
        const { dispatch, origin } = this.props;
        this.setState({ activeTab });
        const returnToPreviousTab = (activeTab == "GALLERY_TAB" && origin == "MAILING") || (activeTab == "MEDIA_TAB" && origin == "MEDIA");
        if (this.state.activeTab == 'UPLOAD_TAB' && returnToPreviousTab) {
            return;
        }
        if (activeTab != this.state.activeTab && (["GALLERY_TAB", "MEDIA_TAB"].indexOf(activeTab) != -1)) {
            dispatch(setMediasOrigin((activeTab == "MEDIA_TAB") ? "MEDIA" : "MAILING"));
        }
    }

    validateImage = (imageUrl) => {
      if (imageUrl.search("api.tamtam.pro") < 0 && (imageUrl.search(/tamtam.*amazonaws.com/) > -1 ||
        imageUrl.search("s3.tamtam.pro") > -1 || imageUrl.search(/s3\..*\.amazonaws.com\/tamtam/) > -1)) {
        this.setState({imageUrl, activeTab: IMAGE_EDITOR});
      } else {
        this.addImage(imageUrl);
      }
    };

    cancel = () => {
      this.setState({activeTab: UPLOAD_TAB})
    };

    addImage = (url) => {
        this.props.done({url});
        this.props.onCloseModal();
    };

    render() {
        const { onCloseModal } = this.props;
        const { activeTab, imageUrl } = this.state;
        let customModalStyle = {
            ...modalStyle,
            content: {
                ...modalStyle['content'],
                width: "95%",
                height: "95%",
                overflow: "none",
                padding: "1rem 1rem 0 1rem",
            }
        };

        let tabs = [...TABS];

        if(imageUrl) {
          tabs.unshift({id: IMAGE_EDITOR, label: "image editor"});
        }

        return <TTPModal isOpen={true} onClose={onCloseModal} modalStyle={customModalStyle}>
            <div id="image-selector" className="ttp-tab-container m-t-xl">
                <TTPTab tabs={tabs} activeTab={activeTab} selectTab={this.selectTab} />
                {activeTab === IMAGE_EDITOR && imageUrl && <ImageEditor imageUrl={imageUrl} addImage={this.addImage} cancel={onCloseModal} />}
                {activeTab === UPLOAD_TAB && <ImageUploader {...this.props} done={this.validateImage} closed={onCloseModal} />}
                {[GALLERY_TAB, MEDIA_TAB].indexOf(activeTab) > -1 && <ImageGallery done={this.validateImage} />}
            </div>
        </TTPModal>
    }
}

export const IMAGE_EDITOR = "IMAGE_EDITOR";
export const UPLOAD_TAB = "UPLOAD_TAB";
export const GALLERY_TAB = "GALLERY_TAB";
export const MEDIA_TAB = "MEDIA_TAB";
export const TABS = [
    {
        id: UPLOAD_TAB,
        label: "upload",
    }, {
        id: GALLERY_TAB,
        label: "image gallery",
    }, {
        id: MEDIA_TAB,
        label: "media",
    }
];
