import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
  replaceEmailContent,
} from '../../../services/utils';
import _ from 'i18n';

const mapStateToProps = (state) => ({
  fields: state.ebox.widget.fields,
});

const mapDispatchToProps = dispatch => ({

});
@connect(mapStateToProps, mapDispatchToProps)

export default class RichEditorPreview extends Component {

  componentDidUpdate(prevProps, prevState) {
    const {currentTab} = this.props;
    if ("preview" === currentTab && prevProps.currentTab !== currentTab) {
      this.resizeIframe();
    }
  }

  resizeIframe = () => {
    let previewContainer = document.getElementsByClassName(
      "rich-editor-preview"
    );
    if (previewContainer.length) {
      let newHeight = this.refs.iframe.contentWindow.document.body.offsetHeight;
      previewContainer[0].style.height = `${(newHeight * 2) / 3 + 10}px`;
    }
  };

  getTemplateHtml() {
    const {subject, theme, content, fields} = this.props;
    function replaceFields(string = "", obj) {
      let content = string;
      for (let prop in obj) {
        content = content.replace(new RegExp(prop, "g"), obj[prop]);
      }
      return content;
    }

    if (!theme) {
      return (subject ? `<h3>${subject}</h3>` : "") + replaceFields(content, fields);
    }

    return replaceEmailContent({
      body: replaceFields(content, fields),
      subject: replaceFields(subject, fields)
    }, theme);
  }

  render() {
    return <div className="editor-preview">
      <iframe ref="iframe" onLoad={this.resizeIframe}
              srcDoc={this.getTemplateHtml()}/>
    </div>
  }
}
