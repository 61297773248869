import React, { Component } from 'react';

export default class SummaryFetching extends Component {

  render() {
    return (
      <ul className="summary summary-fetching">
        <li><div className="gradiant" /> <div className="gradiant" /></li>
        <li><div className="gradiant" /> <div className="gradiant" /></li>
        <li><div className="gradiant" /> <div className="gradiant" /></li>
        <li><div className="gradiant" /> <div className="gradiant" /></li>
        <li><div className="gradiant" /> <div className="gradiant" /></li>
      </ul>
    )
  }
}
