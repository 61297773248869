import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isValidUrl, modalStyle, SVG_EDIT_ICON, getDate } from '../../../services/utils';
import TTPModal from "../../ui/TTPModal";
import _ from 'i18n';
import { updateCampaignLink } from "thunks";

class LinkEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      updating: false,
      link: this.props.link || '',
      updatedAt: this.props.updatedAt,
      updatedBy: this.props.updatedBy
    }

    this.setUpdating = (updating) => this.setState({ updating });
    this.handleLinkChange = (e) => this.setState({ link: e.target.value });
  }

  saveLink = () => {
    const { link } = this.state;
    const { id, updateCampaignLink, onClose } = this.props;
    if (!isValidUrl(link)) {
      return;
    }
    this.setUpdating(true);
    updateCampaignLink({
      id,
      link
    }).finally(() => onClose())
  }

  render() {
    const { onClose, lng } = this.props;
    const { link, updating, updatedAt, updatedBy } = this.state;

    let customModalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle['content'],
        transition: 'opacity 0.4s ease-out 0s',
        border: 'none',
        boxShadow: '0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)',
        minWidth: '35rem',
        maxWidth: '40%',
        padding: '0 15px 0'
      }
    };

    return <TTPModal isOpen={true} onClose={onClose} modalStyle={customModalStyle}>
      <div id="resend-email-form" className="ttp-alert-dialog" >
        <div className={`row alert-header align-center header-primary`}>
          <div className="icon-container">
            {SVG_EDIT_ICON}
          </div>
          <p className="alert-header__title">{_("Edit link")}</p>
        </div>
        <div className="alert-message row">
          <div className="column">
            <input
              type="text"
              value={link}
              onChange={this.handleLinkChange}
              className={`m-b-xs ${true ? "filled-box" : "empty-box"}`}
            />
            <span className={`form-error text-left no-margin ${!isValidUrl(link) ? "is-visible" : ''}`}>
              {_('invalid link')}
            </span>
            {updatedAt && updatedBy && <div className="text-right" style={{ fontSize: 11 }}><i className="icon-left icon-clock" /> {_('last modified on')} : {getDate(updatedAt, lng)} {_('by')} : {updatedBy}</div>}
          </div>
        </div>
        <div className="column alert-footer">
          <button onClick={onClose} className="cancel-button button">{_('cancel')}</button>
          <button
            className="ok-button  button bg-primary"
            onClick={this.saveLink}>
            {updating ? _('Processing...') : _('save')}
          </button>
        </div>
      </div>
    </TTPModal>
  }
}

const mapStateToProps = (store) => ({
  lng: store.params.lng,
});

const mapDispatchToProps = (dispatch) => ({
  updateCampaignLink: (data) => dispatch(updateCampaignLink(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(LinkEditor);
