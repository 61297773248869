import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchSesEmailsStatuses, registerFromEmail } from 'thunks';
import Switch from 'common/Switch';
import _ from 'i18n';
import { SES_FAILED_STATUS, SES_SUCCESS_STATUS, SES_PENDING_STATUS, SES_UNREGISTERED_STATUS } from "../../../services/email";
import { SVG_MINUS, IS_EMAIL_VALID } from "../../../services/svgIcones";
import { getFillClassName, isValidEmail, onError } from '../../../services/utils';

@connect(state => ({
  registeredEmails: state.params.sesEmails.items,
}), dispatch => ({
  fetchSesEmailsStatuses: () => dispatch(fetchSesEmailsStatuses()),
  registerFromEmail: email => dispatch(registerFromEmail(email)),
}))
export default class EmailItem extends Component {

  constructor(props) {
    super(props);
    this.handleNameChange = (e) => this.setState({ name: e.target.value, showButtons: true });
    this.state = {
      ...this.getInitialState(props),
      registrationStatus: SES_FAILED_STATUS,
      fromNameHasError: false
    };
  }

  componentDidMount() {
    this.updateSesEmailStatus();
  }

  validateFromName = (value) => {
    this.setState({ fromNameHasError: value && value.trim().length < 5 });
  };


  getInitialState = () => {
    const { email, name, main, showAction, showButtons } = this.props;
    return {
      email: email ? email : "",
      name: name ? name : "",
      main: main ? main : 0,
      showAction: showAction ? true : false,
      showButtons: showButtons ? true : false,
    }
  };

  updateSesEmailStatus = () => {
    const { registeredEmails } = this.props;
    const { email } = this.state;
    let registrationStatus = SES_FAILED_STATUS;
    if (isValidEmail(email)) {
      registrationStatus = SES_UNREGISTERED_STATUS;
      for (let key in registeredEmails) {
        if (key === this.state.email) {
          registrationStatus = registeredEmails[key].VerificationStatus.toUpperCase();
          break;
        }
      }
      if (registrationStatus == SES_FAILED_STATUS) {
        registrationStatus = SES_UNREGISTERED_STATUS;
      }
    }

    this.setState({ registrationStatus });
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
    this.setState({ showButtons: true });
  };

  handleMainChange = () => {
    this.props.handleMainChange();
    this.setState({ showButtons: !this.props.isMain });
  };

  handleCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    } else {
      const newState = this.getInitialState();
      this.props.setMainEmailIndex();
      this.setState({ ...newState, showAction: false });
    }
  };

  handleSave = () => {
    const { email, name, registrationStatus, fromNameHasError } = this.state;
    const { main } = this.props;
    if (!email || SES_SUCCESS_STATUS !== registrationStatus || fromNameHasError) {
      return;
    }
    this.props.onSave({ email, name, main });
    this.setState({ showAction: false, showButtons: false });

  };

  registerEmail = () => {
    const { email } = this.state;
    this.props.registerFromEmail(email).then(res => this.onSuccess(res), () => onError());
  };

  onSuccess(res) {
    const { value: { data } } = res;
    if (!(res.value instanceof Error) && data.result === "OK") {
      this.setState({ registrationStatus: SES_PENDING_STATUS });
    } else {
      onError();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { email, name, main, registeredEmails } = this.props;
    const changed = this.state.email !== email || this.state.name !== name || (this.state.main !== main && prevState.main === 0) || (this.state.main === main && prevState.main !== 0);

    if (prevState.email !== this.state.email || registeredEmails !== prevProps.registeredEmails) {
      this.updateSesEmailStatus();
    }
    if (changed !== this.state.showAction) {
      this.setState({ showAction: changed });
    }
    if ((this.state.name !== prevState.name)) {
      this.validateFromName(this.state.name);
    }

    if (prevProps.email !== email || prevProps.name != name) {
      this.setState(this.getInitialState());
    }
  }

  render() {
    const { email, name, showAction, registrationStatus, fromNameHasError, showButtons } = this.state;
    const { deleteState, onDelete, fetchSesEmailsStatuses, main } = this.props;

    return (
      <div className="medium-4 small-6" onClick={deleteState ? onDelete : null}>
        <div className={`${deleteState ? " mail-list-item delete" : "mail-list-item"}`}>
          {[SES_PENDING_STATUS, SES_UNREGISTERED_STATUS].indexOf(registrationStatus) > -1 && <div className="warning">
            {(SES_PENDING_STATUS === registrationStatus) ? _('reply_aws_email') : _('register_from_email')}
          </div>}
          <div className="mail-list-item__main">
            <div>
              <label className={`label_delete`} hidden={!deleteState}>
                {SVG_MINUS}{_('delete')}
              </label>
            </div>
            <div className="flex-container">
              <span className="text-capitalize">{_('main email')}</span>
              <Switch name="main" isChecked={main === 1} onChange={this.handleMainChange} />
            </div>
          </div>
          <span className="param-label">{_('name')}</span>
          <div className="mail-list-item__items name">
            <input type="text" className={getFillClassName(name)} onChange={this.handleNameChange} value={name} />
            <span className={fromNameHasError && showAction && name.length > 0 ? 'form-error is-visible' : 'form-error'}> {_('invalidFromName')}</span>
          </div>
          <span className="param-label">{_('sender')}</span>
          <div className="mail-list-item__items email-field">
            <input type="text" className={getFillClassName(email)} onChange={this.handleEmailChange} value={email} />
            {IS_EMAIL_VALID(SES_SUCCESS_STATUS === registrationStatus)}
          </div>
          <span className={`form-error ${showAction && email.length > 0 && (SES_FAILED_STATUS === registrationStatus) && 'is-visible'}`}>{_('invalidEmail')}</span>
        </div>
        {showAction && showButtons && <div className="ttp-small-buttons">
          <button className="cancel" onClick={this.handleCancel}>{_('cancel')}</button>
          {([SES_SUCCESS_STATUS, SES_FAILED_STATUS].indexOf(registrationStatus) > -1) && <button className="success" onClick={this.handleSave}>{_('save')}</button>}
          {SES_UNREGISTERED_STATUS === registrationStatus && <button className="info" onClick={this.registerEmail}>{_('submit')}</button>}
          {SES_PENDING_STATUS === registrationStatus && <button className="info" onClick={fetchSesEmailsStatuses}>{_('refresh')}</button>}
        </div>}
      </div>
    );
  }
}
