import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import TTPSelect from "common/TTPSelect";
import { setEventsFilters } from "actions";
import { USER_ROLE_TYPES, USER_ROLE_TYPE_STATUS } from "Common";

@connect((store) => {
  return {
    filters: store.currentCampaign.email.eventFilters
  }
}, (dispatch) => {
  return {
    setEventFilters: (filters) => dispatch(setEventsFilters(filters))
  }
})
export default class CollaboratorFilters extends Component {

  constructor(props) {
    super(props);
    this.roleTypeOptions = USER_ROLE_TYPES.map(role => ({ value: role, label: _(role).toUpperCase() }));
    this.roleTypeStatusOptions = USER_ROLE_TYPE_STATUS.map(role => ({ value: role, label: _(role).toUpperCase() }));
    this.handleRoleTypeChange = (selected) => this.props.setEventFilters({ value: selected, property: "role.type", operator: "in" });
    this.handleRoleTypeStatusChange = (selected) => this.props.setEventFilters({ value: selected, property: "role.typeStatus", operator: "in" });

    this.state = {
      roleType: [],
      roleTypeStatus: []
    }
  }

  loadFilters = () => {
    let newState = {};
    this.props.filters.forEach(({ property, value }) => {
        switch (property) {
          case "role.type":
            newState['roleType'] = value;
            break;
          case "role.typeStatus":
            newState['roleTypeStatus'] = value;
            break;

        }
      });
      this.setState(newState);
  }

  componentDidMount() {
    this.loadFilters();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.filters != prevProps.filters) {
      this.loadFilters();
    }
  }

  render() {
    const { roleType, roleTypeStatus } = this.state;

    return (
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('ROLES_TYPES')}</h1>
            <TTPSelect
              notClearable={true}
              values={roleType}
              placeholder={_('Select role')}
              options={this.roleTypeOptions}
              onChange={this.handleRoleTypeChange} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('ROLES_STATUS')}</h1>
            <TTPSelect
              notClearable={true}
              values={roleTypeStatus}
              placeholder={_('Select role status')}
              options={this.roleTypeStatusOptions}
              onChange={this.handleRoleTypeStatusChange} />
          </div>
        </div>
      </div>
    );
  }
}
