import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'i18n';

import ProgressBar from 'common/ProgressBar';
import { getDate } from 'utils';

@connect((store) => {
  return {
    lng: store.params.lng,
    campaign: store.currentCampaign.email
  };
})
export default class Header extends Component {

  getCssColor = () => {
    const { approved } = this.props.campaign;
    let cssColor = "";
    let approvalLabel = "";

    switch (approved) {
      case 0: {
        cssColor = "warning";
        approvalLabel = "unapproved";
      }
        break;
      case 1: {
        cssColor = "success";
        approvalLabel = "approved";
      }
        break;
      case 2: {
        cssColor = "alert";
        approvalLabel = "APPROVAL REJECTED";
      }
        break;
    }
    return {cssColor, approvalLabel};
  };

  render() {
    const { campaign: { object, scheduledDeliveryAt, lastTestversion }, lng, processedRate, status } = this.props;
    var sd = new Date(scheduledDeliveryAt);
    const tmp = {
      date: sd.getFullYear() + "-" + (sd.getMonth() + 1) + "-" + sd.getDate() + " " + sd.getHours() + ":" + sd.getMinutes() + ":" + sd.getSeconds(),
      timezone: "UTC",
    };

    return (
      <div className="header-details">
        <h1> {object} </h1>
        {lastTestversion && <span className={`badge text-capitalize ${this.getCssColor().cssColor}`}>{_(this.getCssColor().approvalLabel)}</span>}
        {
          scheduledDeliveryAt &&
          <h2> {_("scheduledFor")} {getDate(tmp, lng, true)} </h2>
        }
        {status !== "SENT" && <ProgressBar processedRate={processedRate} status={status}/>}
      </div>
    );
  }
}
