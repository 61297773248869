import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';

import UsersTagSuggestion from 'common/UsersTagSuggestion';

import { setCurrentEmailCampaignData, initApprovalTeams } from 'actions';
import { fetchApprovalTeams } from 'thunks';
import InlineToolbarEditor from './InlineToolbarEditor';
import { TeamOption } from './TeamOption';
import TTPSelect from 'common/TTPSelect';
import CheckBoxBtn from 'common/CheckBoxBtn';

import _ from 'i18n';

@connect((store) => {
  return {
    userEmails: store.auth.user.email,
    _requests: store.params.sourcesTokens,
    opened: store.currentCampaign.email.currentSideBar == "TEST",
    approvalTeams: store.currentCampaign.email.approvalTeams,
    selectedTags: store.currentCampaign.email.testEmails,
    testingDisabled: !store.currentCampaign.email.fromEmailValid,
    teams: store.approval.teams.items,
    detached: store.auth.detached,
    mandatoryTest: store.auth.currentSetting.mandatoryTest,
    autoSend: store.auth.currentSetting.autoSend,
    updating: store.currentCampaign.email.updating,
  };
})
export default class TestTab extends Component {

  constructor(props) {
    super(props);
    this.handleSendToChange = () => this.setState({ sendTo: !this.state.sendTo });
    this.handleSendMeChange = () => this.setState({ sendMe: !this.state.sendMe });
    this.toogleDetails = () => this.setState({ showDetails: !this.state.showDetails });

    this.state = { showDetails: false, sendTo: false, sendMe: true, sendToApprovers: false };
  }

  componentWillMount() {
    let { userEmails, dispatch } = this.props;
    let mainUserEmail = "";
    dispatch(initApprovalTeams());
    for (let i = 0; i < userEmails.length; i++) {
      if (userEmails[i]['main'] == 1) {
        mainUserEmail = userEmails[i];
        break;
      }
    }
    if (!mainUserEmail) {
      mainUserEmail = userEmails[0];
    }
    dispatch(fetchApprovalTeams());
    this.setState({ mainUserEmail });
  }

  handleApprovalTeamsChange = (teams) => {
    const { dispatch } = this.props;
    dispatch(setCurrentEmailCampaignData({ approvalTeams: teams }));
  }

  handleSendApproversChange = () => {
    let sendToApprovers = !this.state.sendToApprovers;
    this.setState({ sendToApprovers, showDetails: sendToApprovers });
  }

  handleTestClick() {
    let { onConfirm, selectedTags } = this.props;
    const { sendTo, sendMe, sendToApprovers, mainUserEmail } = this.state;
    if (sendTo && selectedTags.length === 0) {
      NotificationManager.warning(_('typeTestEmail'), _('warning'));
      return;
    }
    if (!(sendTo || sendMe || sendToApprovers)) {
      return;
    }
    let sentOptions = { sendToApprovers, sendTo, sendMe };
    if (sendMe) {
      sentOptions.email = mainUserEmail.id;
    }
    onConfirm(sentOptions);
  }

  handleTagsChange = (tags) => {
    this.props.dispatch(setCurrentEmailCampaignData({ testEmails: tags }));
  }

  renderTestBlock() {
    let { selectedTags, detached, teams, approvalTeams, mandatoryTest, autoSend } = this.props;
    const { sendTo, sendMe, sendToApprovers, mainUserEmail, showDetails } = this.state;

    if (detached) {
      return (<div className="columns small-10 sidebar__form-control">
        <span className="param__title">{_('send me a test email')} :</span>
        <span className="detail"> - {mainUserEmail.email}</span>
      </div>);
    }
    return <div className="row">
      <div className="columns small-6 columns-padding">
        <span className="param__title">{_('sendEmailTestTo')}</span>
        <div className="small-10 flex-container align-middle">
          <CheckBoxBtn id="send-to" checked={sendTo} onChange={this.handleSendToChange} />
          <UsersTagSuggestion displayEmail={true} selectedTags={selectedTags} onTagsChange={this.handleTagsChange} />
        </div>
      </div>

      <div className="columns small-6 columns-padding">
        <span className="param__title">{_('send me a test at')}</span>
        <div className="small-10 flex-container align-middle">
          <CheckBoxBtn checked={sendMe} onChange={this.handleSendMeChange} id="send-me" />
          <input className="filled-box" type="text" value={mainUserEmail.email} disabled={true} />
        </div>
      </div>


      <div className="columns small-6 columns-padding">
        <div className="small-10 param__title">{_("send to approval teams")}
          <span className="see-more" onClick={this.toogleDetails}>
            {_(showDetails ? 'see less' : 'see more')}
            <i className={`icon ${showDetails ? 'icon-arrow-up' : 'icon-arrow-down'}`}></i>
          </span>
        </div>
        {showDetails && <div className="columns approvers-block">
          <div>- {_('approval is required')} : <b>{mandatoryTest ? _('yes') : _('no')}</b></div>
          <div>- {_('automatic sending')} :  <b>{autoSend ? _('yes') : _('no')}</b></div>
        </div>}

        <div className="small-10 flex-container align-middle">
          <CheckBoxBtn checked={sendToApprovers} onChange={this.handleSendApproversChange} id="send-tester" />
          <TTPSelect
            simple={true}
            values={approvalTeams}
            placeholder={_('Select approval teams')}
            options={teams.filter(team => team.members && team.members.length)}
            onChange={this.handleApprovalTeamsChange}
            cssClass={(!approvalTeams || !approvalTeams.length) && "empty-box"}
            customOption={TeamOption} />
        </div>
      </div>
      <div className="columns small-6 columns-padding">
        <span className="param__title">{_('Add a comment')}</span>
        <div className="small-10">
          <InlineToolbarEditor />
        </div>
      </div>
    </div>
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.opened || this.props.opened;
  }

  render() {
    let { opened, testingDisabled, updating, approvalTeams, selectedTags } = this.props;
    const { sendTo, sendMe, sendToApprovers } = this.state;

    var testingEnabled = !testingDisabled && ((sendToApprovers && approvalTeams && approvalTeams.length > 0)
      || (sendTo && selectedTags && selectedTags.length > 0) || sendMe);

    return (
      <div id="campaign-test__tab" className={`campaign-test ${opened ? '' : 'hide'}`}>
        <div className="sidebar__form">
          <h4 className="sidebar__header">{_('sendTest')}</h4>
          {this.renderTestBlock()}
          <button disabled={!testingEnabled}
            onClick={!updating && this.handleTestClick.bind(this)}
            className={`float-right ttp-button ${testingEnabled ? 'info' : 'disabled'}`}>{updating ? _('Processing...') : _('sendTest')}
          </button>
        </div>
      </div>
    );
  }


}
