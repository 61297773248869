import React, { Component } from "react";
import { connect } from "react-redux";
import EmailEditor from "react-email-editor";
import sample from "../editor/sample";
import { UNLAYER_PROJECT_ID } from "../../config";
import {
  assembleNewsletter,
  decomposeNewsletter,
} from "../../services/block/newsletter";
import {
  setCurrentDndTheme,
  setCurrentNewsletterTemplate,
  setNewsletterBlocks,
} from "../../actions/actions/theme";
import _ from "i18n";
import ParamsStep from "./campaign/ParamsStep";
import RecipientsStep from "./campaign/RecipientsStep";
import TestStep from "./campaign/TestStep";
import SendStep from "./campaign/SendStep";
import ApprovalStep from "./campaign/ApprovalStep";
import {
  initCurrentEmailCampaignData,
  setCurrentEmailCampaignData,
  setTitleSelection,
} from "../../actions/actions/currentCampaign";
import { hasValidLength, isValidEmail } from "../../services/validation";
import Parser from "html-react-parser";
import {
  SVG_ALERT_CERCLE_ICON,
  SVG_SIMPLE_CHECK_ICON,
} from "../../services/svgIcones";
import { NotificationManager } from "react-notifications";
import { onError, onSuccess } from "../../services/utils";
import { persistCampaign } from "thunks";
import {
  fetchCampaign,
  fetchCountsRecipientsByTypes,
  fetchDelegationTypes,
  fetchRecipientsCriteria,
  persistRecipientsCriteria,
} from "../../actions/thunks/campaign";
import { setIsUsedNewsletterArticles } from "../../actions/thunks/newsletterUA";
import { fetchApprovalFeedback } from "../../actions/thunks";

const mapStateToProps = (state) => ({
  allowedGroups: state.currentCampaign.email.allowedGroups,
  deniedGroups: state.currentCampaign.email.deniedGroups,
  currentTheme: state.themes.currentDndTheme,
  fetchingArticles: state.articles.fetching,
  blocks: state.themes.newsletter.blocks,
  currentTemplate: state.themes.newsletter.currentTemplate,
  currentStep: state.currentCampaign.email.currentSideBar,
  object: state.currentCampaign.email.object,
  recipientType: state.currentCampaign.email.recipientType,
  recipientsCount: state.campaigns.recipients.recipientsTotalCount,
  manualSmsRecipients: state.currentCampaign.sms.manualSmsRecipients,
  language: state.currentCampaign.email.language,
  approvalRequired: state.auth.currentSetting.mandatoryTest,
  approved: state.currentCampaign.email.approved,
  approvalTeams: state.currentCampaign.email.approvalTeams,
  scheduledDeliveryAt: state.currentCampaign.email.scheduledDeliveryAt,
  lastTestVersion: state.currentCampaign.email.lastTestversion,
  campaignId: state.currentCampaign.email.id,
  from: state.currentCampaign.email.from,
  fromName: state.currentCampaign.email.fromName,
  pageUrl: state.currentCampaign.email.pageUrl,
  type: state.currentCampaign.email.type,
  html: state.currentCampaign.email.html,
  manualRecipients: state.currentCampaign.email.manualRecipients,
  uaNewsletterId: state.currentCampaign.uaNewsletter.uaNewsletterId,
  isUaNewsletter: state.currentCampaign.uaNewsletter.isUaNewsletter,
  programmedDate: state.currentCampaign.uaNewsletter.programmedDate,
  uaRecipientsCount: state.campaigns.recipients.uaRecipientsTotalCount,
  liteRecipients: state.currentCampaign.email.liteRecipients,
  nbOfRecipients: state.currentCampaign.email.nbOfRecipients,
  articleNewsletterId: state.currentCampaign.email.articleNewsletterId,
  uaRecipientType: state.currentCampaign.email.uaRecipientType,
  totalUaRecipients: state.currentCampaign.email.totalUaRecipients,
  numberOfNewsletter: state.currentCampaign.email.numberOfNewsletter,
  uaFilters: state.currentCampaign.email.uaFilters,
  introduction: state.currentCampaign.email.introduction,
  titleSelection: state.currentCampaign.email.titleSelection,
  recipientCriteria: state.campaigns.list.recipientCriteria,
  isTested: state.currentCampaign.email.isTested,
  fetching: state.approval.list.fetching,
  approvals: state.approval.list.items,
  totalUniqueRecipients: state.campaigns.recipients.totalUniqueRecipients,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentDndTheme: (theme) => dispatch(setCurrentDndTheme(theme)),
  initCurrentCampaign: (data) => dispatch(initCurrentEmailCampaignData(data)),
  setCurrentCampaign: (data) => dispatch(setCurrentEmailCampaignData(data)),
  persistCampaign: (data) => dispatch(persistCampaign(data, true)),
  fetchCampaign: (id) => dispatch(fetchCampaign(id)),
  setCurrentNewsletterTemplate: (data = null) =>
    dispatch(setCurrentNewsletterTemplate(data)),
  setNewsletterBlocks: (data) => dispatch(setNewsletterBlocks(data)),
  fetchRecipientsCriteria: () => dispatch(fetchRecipientsCriteria()),
  setIsUsedNewsletterArticles: (articleNewsletterId) =>
    dispatch(setIsUsedNewsletterArticles(articleNewsletterId)),
  setTitleSelection: (data) => dispatch(setTitleSelection(data)),
  persistRecipientsCriteria: (data) =>
    dispatch(persistRecipientsCriteria(data)),
  fetchCountsRecipientsByTypes: (criteriaArray, filteredCriteria) =>
    dispatch(fetchCountsRecipientsByTypes(criteriaArray, filteredCriteria)),
  fetchApprovalFeedback: (data) => dispatch(fetchApprovalFeedback(data)),
  fetchApprovalTeams: () => dispatch(fetchApprovalTeams()),
  fetchDelegationTypes: () => dispatch(fetchDelegationTypes()),
});
@connect(mapStateToProps, mapDispatchToProps)
export default class NewsletterUASend extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isSuccesTest: false,
      isApproval: false,
      recipientsTab: [],
      isRecipientCriteriaLoading: false,
      selectedRecipientCriteria: null,
      campaignSteps: [
        {
          reference: "PARAMS",
          label: "params",
        },
        {
          reference: "RECIPIENTS",
          label: "recipients",
        },
        {
          reference: "TEST",
          label: "test",
        },
        {
          reference: "APPROVAL",
          label: "approval",
        },
        {
          reference: "SEND",
          label: "send",
        },
      ],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      constructDesign,
      setCurrentDndTheme,
      blocks,
      currentTemplate,
      initCurrentCampaign,
      newsletterId,
      setNewsletterBlocks,
      setCurrentNewsletterTemplate,
      setCurrentCampaign,
      fetchCampaign,
      fetchRecipientsCriteria,
      plan,
      fetchAllArticles,
      fetchApprovalFeedback,
      object,
      from,
      fromName,
      language,
      fetchDelegationTypes,
    } = this.props;

    let currentSideBar = plan === "PERSONALIZED" ? "PERIODS" : "PARAMS";
    initCurrentCampaign({ currentSideBar: currentSideBar });
    fetchRecipientsCriteria();
    fetchDelegationTypes();

    if (plan !== "PERSONALIZED") {
      fetchAllArticles();
    } else {
      this.addCampaignSteps({ reference: "PERIODS", label: "periods" });
    }

    if (newsletterId !== null) {
      if (
        object.length < 5 ||
        (!hasValidLength(object, 5, 200) &&
          from.length > 0 &&
          fromName.length > 0 &&
          language.length > 0)
      ) {
        currentSideBar = "RECIPIENTS";
        this.setActiveStep(currentSideBar);
      }

      fetchCampaign(newsletterId).then((res) => {
        const {
          design,
          html,
          fields,
          totalUaRecipients,
          personalizedRecipients,
          eliminatedRecipients,
          liteRecipients,
          approvalTeams,
          isTested,
          lastTestversion,
          totalRecipients,
          approved,
        } = res.value?.data.data[0];

        fetchApprovalFeedback({
          campaignId: newsletterId,
          version: lastTestversion,
        });

        setCurrentCampaign({
          totalUaRecipients,
          personalizedRecipients,
          eliminatedRecipients,
          liteRecipients,
        });
        this.updateRecipientTabWithTotalUaRecipients(
          totalUaRecipients,
          personalizedRecipients,
          eliminatedRecipients,
          liteRecipients,
        );

        if (design) {
          setCurrentDndTheme({ design });
          setCurrentCampaign({ currentSideBar: currentSideBar });
          const template = { design, content: html };
          try {
            setNewsletterBlocks(JSON.parse(fields));
          } catch (error) {
            decomposeNewsletter(template, (blocks) =>
              setNewsletterBlocks(blocks),
            );
          }
          setCurrentNewsletterTemplate(template);
          this.props.changeHasArticles(true);
          this.props.handleGenerateHtml(true);
        }

        if (totalRecipients > 0) {
          currentSideBar = "TEST";
          this.setActiveStep(currentSideBar);
        }
        if (
          lastTestversion > 0 &&
          this.canSaveData() &&
          isTested &&
          approvalTeams &&
          approvalTeams.length > 0
        ) {
          currentSideBar = "APPROVAL";
          this.setActiveStep(currentSideBar);
        }
        if (approved) {
          currentSideBar = "SEND";
          this.setActiveStep(currentSideBar);
        }
      });
    } else {
      fetchApprovalFeedback({});

      if (constructDesign) {
        assembleNewsletter(currentTemplate, blocks, (design) => {
          setCurrentDndTheme({ design });
          this.props.handleGenerateHtml(true);
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      constructDesign,
      setCurrentDndTheme,
      currentTemplate,
      blocks,
      numberOfNewsletter,
      introduction,
    } = this.props;

    if (
      (constructDesign &&
        prevProps.constructDesign !== constructDesign &&
        currentTemplate !== null) ||
      (numberOfNewsletter !== prevProps.numberOfNewsletter &&
        constructDesign &&
        currentTemplate !== null) ||
      (introduction !== prevProps.introduction &&
        constructDesign &&
        currentTemplate !== null)
    ) {
      assembleNewsletter(currentTemplate, blocks, (design) => {
        setCurrentDndTheme({ design });
        this.props.handleGenerateHtml(true);
      });
      let feeds = blocks.reduce((acc, { feeds }) => {
        return [...acc, ...feeds];
      }, []);
      decomposeNewsletter(currentTemplate, (blocks) => {
        let i = 0;
        blocks =
          feeds && feeds.length > 0
            ? blocks.map((block) => {
                if (block.type !== "UNREFERENCED_BLOCK" && feeds.length > i) {
                  block.feeds = feeds.slice(i, (i += block.column));
                  return block;
                }
                return block;
              })
            : blocks;
        setNewsletterBlocks(blocks);
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.currentStep || this.props.currentStep;
  }

  updateRecipientTabWithTotalUaRecipients = (
    totalUaRecipients,
    personalizedRecipients,
    eliminatedRecipients,
    liteRecipients,
  ) => {
    const { uaFilters } = this.props;
    let updatedRecipientTab = this.state.recipientsTab.slice();
    if (totalUaRecipients) {
      Object.keys(totalUaRecipients).forEach((key) => {
        const uaFilterForRecipientType = uaFilters.find(
          (filter) => filter.type === key,
        );

        const allowedGroups = uaFilterForRecipientType
          ? uaFilterForRecipientType.allowedGroups.map((group) => group.id)
          : [];
        const deniedGroups = uaFilterForRecipientType
          ? uaFilterForRecipientType.deniedGroups.map((group) => group.id)
          : [];

        const newRecipientObject = {
          recipientType: key,
          personalizedRecipients,
          eliminatedRecipients,
          count: totalUaRecipients[key]?.total || 0,
          selectAllRecipient:
            totalUaRecipients[key]?.selectedCount ===
              totalUaRecipients[key]?.total ||
            (eliminatedRecipients.length > 0 &&
              eliminatedRecipients[key] &&
              !personalizedRecipients[key]),
          paginationPage: 1,
          allowedGroups: allowedGroups,
          deniedGroups: deniedGroups,
          liteRecipients: liteRecipients,
        };

        updatedRecipientTab = updatedRecipientTab.map((recipient) => {
          if (recipient.recipientType === key) {
            return {
              ...recipient,
              personalizedRecipients,
              eliminatedRecipients,
              count: newRecipientObject.count,
              selectAllRecipient: newRecipientObject.selectAllRecipient,
              paginationPage: newRecipientObject.paginationPage,
              allowedGroups: newRecipientObject.allowedGroups,
              deniedGroups: newRecipientObject.deniedGroups,
              liteRecipients: newRecipientObject.liteRecipients,
            };
          }
          return recipient;
        });

        updatedRecipientTab = updatedRecipientTab.some(
          (recipient) => recipient.recipientType === key,
        )
          ? updatedRecipientTab
          : [...updatedRecipientTab, newRecipientObject];
      });
    }
    if (this._isMounted) {
      this.handleSetRecipientTab(updatedRecipientTab);
    }
  };

  setActiveStep = (activeStep) => {
    this.props.setCurrentCampaign({ currentSideBar: activeStep });
  };

  canSaveData = () => {
    let { from, fromName, object, pageUrl, language, type, html } = this.props;
    let hasValidContent =
      type === "WEB_PAGE"
        ? pageUrl && pageUrl.length > 0
        : html && html.length > 0;

    return (
      isValidEmail(from) &&
      hasValidLength(fromName, 3) &&
      hasValidLength(object, 5, 200) &&
      language &&
      hasValidContent
    );
  };

  mapCampaignGroupIds(campaignGroups) {
    let oldCampaignGroups = this.props.oldCampaignGroups;
    if (!oldCampaignGroups || !oldCampaignGroups.length) {
      return campaignGroups;
    }
    oldCampaignGroups.forEach((oldCampaignGroup) => {
      for (let i = 0; i < campaignGroups.length; i++) {
        if (
          campaignGroups[i].group === oldCampaignGroup.group.id &&
          campaignGroups[i].status === oldCampaignGroup.status
        ) {
          campaignGroups[i].id = oldCampaignGroup.id;
        }
      }
    });
    return campaignGroups;
  }

  getCampaignGroups() {
    let campaignGroups = [];
    let { allowedGroups, deniedGroups } = this.props;
    if (!(allowedGroups instanceof Array)) {
      allowedGroups = allowedGroups.split(",");
    }
    if (!(deniedGroups instanceof Array)) {
      deniedGroups = deniedGroups.split(",");
    }
    if (allowedGroups.length > 0) {
      for (let i = 0; i < allowedGroups.length; i++) {
        if (allowedGroups[i]) {
          campaignGroups.push({ group: allowedGroups[i], status: "INCLUDE" });
        }
      }
    }
    if (deniedGroups.length > 0) {
      for (let i = 0; i < deniedGroups.length; i++) {
        if (deniedGroups[i]) {
          campaignGroups.push({ group: deniedGroups[i], status: "EXCLUDE" });
        }
      }
    }
    return this.mapCampaignGroupIds(campaignGroups);
  }

  saveCampaign = (action = "DRAFT", sendTestOptions) => {
    const {
      plan,
      persistCampaign,
      setIsUsedNewsletterArticles,
      articleNewsletterId,
      lastTestVersion,
      setCurrentCampaign,
      approvalTeams,
      totalUniqueRecipients,
      manualRecipients,
    } = this.props;
    const { recipientsTab } = this.state;

    if (!this.canSaveData()) {
      //NotificationManager.info(_("please fill in all the required fields"));
      NotificationManager.info(
        _("Merci de vérifier l'aperçu avant d'envoyer la newsletter"),
      );
      return;
    }
    this.props.setCurrentCampaign({ type: "DND_TEMPLATE" });

    const selectAllRecipientsTypes =
      recipientsTab.length > 0 &&
      recipientsTab.every((recipient) => recipient.selectAllRecipient === true);

    let data = {
      campaignGroups: this.getCampaignGroups(),
      action,
      isUAVersion: true,
    };

    if (selectAllRecipientsTypes) {
      data.nbUniqueRecipients = totalUniqueRecipients
        ? totalUniqueRecipients +
          (manualRecipients ? manualRecipients.length : 0)
        : 0;
    }

    let notification = { body: "campaignAdded", title: "addCampaignSuccess" };

    if (action === "TEST") {
      data.sendTestOptions = sendTestOptions;
      notification = { body: "campaignTestSent", title: "campaignTestSuccess" };
    } else if (action === "SEND") {
      data.status = "CREATED";
      notification = { body: "campaignSent", title: "campaignSentSuccess" };
    }

    if (action === "DRAFT" && lastTestVersion) {
      data.status = "TEST";
    }
    plan === "PERSONALIZED" &&
      action === "SEND" &&
      setIsUsedNewsletterArticles(articleNewsletterId).then((res) => {
        console.log("Successfully updated.");
      });
    persistCampaign(data).then(
      (res) => {
        if (action === "TEST") {
          if (sendTestOptions.sendToApprovers) {
            setCurrentCampaign({ currentSideBar: "APPROVAL" });
          } else {
            this.setState({ isSuccesTest: true });
            setCurrentCampaign({ currentSideBar: "TEST" });
          }
        } else if (action !== "TEST" && action !== "SEND") {
          setCurrentCampaign({ currentSideBar: action });
          onSuccess(res, notification);
        } else if (action === "SEND") {
          this.props.handleSetCurrentPage("LIST_PAGE", null);
          onSuccess(res, notification);
        }
      },
      (err) => onError(err),
    );
  };

  handleChangeStep = (
    stepReference,
    disabledRecipientsStep,
    isRecipientsNull,
    typeWithNoRecipients,
    isApproved,
  ) => {
    let { lastTestVersion } = this.props;

    if (stepReference === "PERIODS") {
      this.setActiveStep("PERIODS");
    } else if (stepReference === "PARAMS") {
      if (this.props.plan === "PERSONALIZED") {
        if (this.props.isPeriodProgrammed) {
          this.setActiveStep(stepReference);
        }
      } else {
        this.setActiveStep(stepReference);
      }
    } else if (stepReference === "RECIPIENTS") {
      if (!disabledRecipientsStep) {
        this.setActiveStep(stepReference);
      }
    } else if (stepReference === "APPROVAL") {
      if (lastTestVersion > 0 && this.canSaveData() && !isRecipientsNull) {
        this.setActiveStep(stepReference);
      }
    } else if (stepReference === "TEST") {
      if (
        !disabledRecipientsStep &&
        !isRecipientsNull &&
        (!typeWithNoRecipients || typeWithNoRecipients?.length === 0)
      ) {
        this.setActiveStep(stepReference);
      }
    } else if (stepReference === "SEND") {
      if (isApproved) this.setActiveStep(stepReference);
    }
  };

  handleSetRecipientTab = (tabs) => {
    let { uaRecipientType } = this.props;

    const filteredTabs = tabs.filter((tab) =>
      uaRecipientType.includes(tab.recipientType),
    );

    this.setState({
      recipientsTab: filteredTabs,
    });
    this.props.changeAllFiltersTab(filteredTabs);
  };

  addCampaignSteps = (newStep) => {
    const stepsArray = [...this.state.campaignSteps];
    const existingIndex = stepsArray.findIndex(
      (step) => step.reference === newStep.reference,
    );

    if (existingIndex === -1) {
      stepsArray.unshift(newStep);
    }

    this.setState({ campaignSteps: stepsArray });
  };

  saveRecipientCriteria = () => {
    const {
      uaRecipientType,
      titleSelection,
      uaFilters,
      manualRecipients,
      persistRecipientsCriteria,
      fetchRecipientsCriteria,
      setCurrentCampaign,
    } = this.props;
    let filtersSelected = [...uaFilters];
    if (uaRecipientType.includes("MANUAL") && manualRecipients.length > 0) {
      filtersSelected = [
        ...filtersSelected,
        { type: "MANUAL", emails: manualRecipients },
      ];
    }

    let data = {
      title: titleSelection,
      criteria: uaRecipientType,
      criteriaFilter: filtersSelected,
    };
    let notification = { body: "success", title: "success" };
    persistRecipientsCriteria(data)
      .then((res) => {
        onSuccess(res, notification);
        fetchRecipientsCriteria();
        setCurrentCampaign({ currentSideBar: "RECIPIENTS" });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  handleTitleChange = (event) => {
    this.props.setTitleSelection(event.target.value);
  };
  handleChangeRecipientCriteria = (value) => {
    const { recipientCriteria, uaRecipientType } = this.props;
    this.setState({
      selectedRecipientCriteria: value,
    });
    if (value === null) {
      return;
    }
    this.setState({
      isRecipientCriteriaLoading: true,
    });
    let criteria = recipientCriteria?.find(
      (obj) => String(obj.id) === String(value),
    );
    if (criteria) {
      {
        const criteriaArray = criteria["criteria"]
          ? criteria["criteria"].split(",")
          : uaRecipientType;

        const criteriaFilterArray = JSON.parse(criteria["criteriaFilter"]);

        const filteredCriteria = criteriaFilterArray.filter(
          (criteria) =>
            criteria.type === "CONTACT" || criteria.type === "CONTACT_CLIENT",
        );

        const manualRecipientsSelected = criteriaFilterArray.find(
          (item) => item.type === "MANUAL",
        );

        this.props
          .fetchCountsRecipientsByTypes(criteriaArray, filteredCriteria)
          .then((res) => {
            let data = res.action.payload.data;
            let totalUaRecipients = {};
            for (const key in data) {
              if (data.hasOwnProperty(key)) {
                totalUaRecipients[key] = {
                  selectedCount: data[key],
                  total: data[key],
                };
              }
            }
            this.updateRecipientTabWithTotalUaRecipients(
              totalUaRecipients,
              [],
              [],
              [],
            );
            this.props.setCurrentCampaign({
              totalUaRecipients,
            });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });

        this.props.setCurrentCampaign({
          uaRecipientType: criteriaArray,
          recipientType:
            criteriaArray.length > 0
              ? criteriaArray[criteriaArray.length - 1]
              : "MANUAL",
          manualRecipients: manualRecipientsSelected?.emails
            ? manualRecipientsSelected.emails
            : [],
          uaFilters: filteredCriteria,
        });
      }
      this.setState({
        isRecipientCriteriaLoading: false,
      });
    }
  };

  render() {
    let {
      generateHtml,
      currentStep,
      object,
      language,
      approvalRequired,
      approved,
      scheduledDeliveryAt,
      from,
      fromName,
      nbOfRecipients,
      plan,
      uaRecipientType,
      liteRecipients,
      totalUaRecipients,
      approvals,
      fetching,
      approvalTeams,
      isTested,
      totalUniqueRecipients,
      manualRecipients,
    } = this.props;
    let {
      selectedRecipientCriteria,
      isRecipientCriteriaLoading,
      campaignSteps,
      recipientsTab,
      isSuccesTest,
    } = this.state;

    var isRecipientsNull = (nbOfRecipients || 0) < 1;

    var isObjectInvalid = object.length < 5 || !hasValidLength(object, 5, 200);
    var disabledRecipientsStep =
      isObjectInvalid ||
      from.length <= 0 ||
      fromName.length <= 0 ||
      language.length <= 0;
    var isPersonalizedPlan = plan === "PERSONALIZED";
    const isStandardPlan = plan === "STANDARD";

    const stepFiltred = campaignSteps.findIndex(
      (el) => el.reference === currentStep,
    );

    const isLitePlan = plan === "LITE";
    const typeWithNoRecipients =
      isPersonalizedPlan || isStandardPlan
        ? Object.entries(totalUaRecipients || {}).find(
            ([key, value]) => value.selectedCount === 0,
          )
        : isLitePlan &&
          uaRecipientType
            .filter((type) => type !== "MANUAL")
            .filter(
              (type) =>
                !liteRecipients.some(
                  (recipient) => recipient.recipientType === type,
                ),
            );

    let totalApprovers = Object.values(approvals || {})
      .map((el) => el.members?.length || 0)
      .reduce((acc, curr) => acc + curr, 0);

    let minApprovers = approvals ? approvals[approvalTeams]?.minApprovers : 0;

    // Extract members list using approvalTeams ID
    let members = approvals ? approvals[approvalTeams]?.members : [];

    // Count the number of members with decision "ACCEPT"
    let approvalsCount = members
      ? members.reduce((count, member) => {
          if (member.decision === "ACCEPT") {
            count++;
          }
          return count;
        }, 0)
      : 0;

    let isApproval =
      (approvalTeams && !Array.isArray(approvalTeams)) ||
      (Array.isArray(approvalTeams) && approvalTeams.length > 0);
    let isApproved = approvalsCount >= minApprovers;

    const uniqueTotalRecipients = totalUniqueRecipients
      ? totalUniqueRecipients + (manualRecipients ? manualRecipients.length : 0)
      : 0;

    const selectAllRecipientsTypes =
      recipientsTab.length > 0 &&
      recipientsTab.every((recipient) => recipient.selectAllRecipient === true);
    return (
      <div className={`newsletter-ua-page__send`}>
        <div className="newsletter-ua-page__send-steps">
          <h5>{_("sending_steps")}</h5>
          <h6>{_("steps_paragraph")}:</h6>
          {campaignSteps.map((step, index) => (
            <div
              className={`small-10 step-item ${currentStep === step.reference || (stepFiltred && stepFiltred > index) ? "active" : "disabled"}`}
              onClick={() => {
                this.handleChangeStep(
                  step.reference,
                  disabledRecipientsStep,
                  isRecipientsNull,
                  typeWithNoRecipients,
                  isApproved,
                );
              }}
            >
              <div>
                <span className="step-item__id">
                  {step.reference === "PARAMS"
                    ? !isObjectInvalid &&
                      from.length > 0 &&
                      fromName.length > 0 &&
                      language.length > 0
                      ? SVG_SIMPLE_CHECK_ICON
                      : index + 1
                    : step.reference === "RECIPIENTS"
                      ? !isRecipientsNull
                        ? SVG_SIMPLE_CHECK_ICON
                        : index + 1
                      : stepFiltred && stepFiltred > index
                        ? SVG_SIMPLE_CHECK_ICON
                        : index + 1}
                </span>
                <span className="step-item__title">{_(step.label)}</span>
              </div>
              {step.reference !== "SEND" &&
                (step.reference === "PARAMS" ? (
                  isObjectInvalid ? (
                    <div className="container__warning">
                      <span className="alert_circle">
                        {SVG_ALERT_CERCLE_ICON}
                      </span>
                      <span>{Parser(_("invalid_object_warning"))}</span>
                    </div>
                  ) : (
                    <div className="container__info">
                      <ul>
                        <li>{object}</li>
                        <li>
                          {language === "fr"
                            ? _("french")
                            : language === "en"
                              ? _("english")
                              : language === "nl"
                                ? _("dutch")
                                : _("all")}
                        </li>
                      </ul>
                    </div>
                  )
                ) : step.reference === "RECIPIENTS" ? (
                  nbOfRecipients < 1 ? (
                    <div className="container__warning">
                      <span className="alert_circle">
                        {SVG_ALERT_CERCLE_ICON}
                      </span>
                      <span>{Parser(_("recipient_count_warning"))}</span>
                    </div>
                  ) : (
                    <div className="container__info">
                      <ul>
                        <li>
                          {_("Nombre de déstinataires :")} &nbsp;
                          {/*  {uaRecipientsCount}*/}
                          {selectAllRecipientsTypes
                            ? uniqueTotalRecipients
                            : nbOfRecipients}
                        </li>
                      </ul>
                    </div>
                  )
                ) : step.reference === "APPROVAL" ? (
                  !isApproval || approvalsCount === 0 ? (
                    <div className="container__warning">
                      <span className="alert_circle">
                        {SVG_ALERT_CERCLE_ICON}
                      </span>
                      <span>{Parser(_("approval_required_warning"))}</span>
                    </div>
                  ) : fetching ? (
                    <div className="container__info">
                      <ul>
                        <li>
                          {_("acceptedApproval")}
                          {" :"} &nbsp;
                        </li>
                      </ul>
                    </div>
                  ) : isTested ? (
                    <div className="container__info">
                      <ul>
                        <li>
                          {_("acceptedApproval")}
                          {" :"} &nbsp;
                          {approvalsCount}
                          {"/"}
                          {totalApprovers}
                        </li>
                      </ul>
                    </div>
                  ) : null
                ) : null)}
            </div>
          ))}
        </div>
        <div className="newsletter-ua-page__send-tabs medium-8">
          {currentStep === "PERIODS" && this.props.handleShowIframe()}
          <ParamsStep
            isPlanPersonalized={isPersonalizedPlan}
            handleGenerateHtml={this.props.handleGenerateHtml}
          />
          <RecipientsStep
            recipientsTab={recipientsTab}
            handleSetRecipientTab={this.handleSetRecipientTab}
            saveRecipientCriteria={this.saveRecipientCriteria}
            handleTitleChange={this.handleTitleChange}
            updateRecipientTab={this.updateRecipientTabWithTotalUaRecipients}
            handleChangeRecipientCriteria={this.handleChangeRecipientCriteria}
            isRecipientCriteriaLoading={isRecipientCriteriaLoading}
            selectedRecipientCriteria={selectedRecipientCriteria}
          />
          <TestStep
            onConfirm={this.saveCampaign.bind(this, "TEST")}
            isSuccesTest={isSuccesTest}
          />
          <ApprovalStep
            disabled={!isApproved && !scheduledDeliveryAt}
            onConfirm={this.saveCampaign.bind(this, "TEST")}
            plan={plan}
          />
          <SendStep onConfirm={this.saveCampaign.bind(this, "SEND")} />
        </div>
        <div className="hide">
          {generateHtml && (
            <EmailEditor
              id="drag-n-drop-editor"
              ref={(editor) => (this.editor = editor)}
              projectId={Number.parseInt(UNLAYER_PROJECT_ID)}
              onLoad={this.onLoad}
              locale={"fr-FR"}
            />
          )}
        </div>
      </div>
    );
  }

  loadDesign = (design) => {
    let editor = this.editor || unlayer;
    this.props.triggerRender && this.props.triggerRender();
    try {
      if (design && (typeof design === "string" || design instanceof String)) {
        design = JSON.parse(design);
      }
      editor.loadDesign(design);
    } catch (e) {
      console.warn(e);
      editor.loadDesign(sample);
    }
  };

  onLoad = () => {
    let { currentTheme } = this.props;
    if (currentTheme && currentTheme.design) {
      this.loadDesign(currentTheme.design);
    }
    this.props.handleSetNewsletter(true);
  };
}
