import React, {Component} from 'react';
import Parser from 'html-react-parser';

import _ from 'i18n';

export default class NoteItem extends Component {

  render() {
    const { text, onToggle, index } = this.props;
    return (
      <div className="notes__item" onClick={onToggle}>
        <div className="notes__item-head">
          {index}
        </div>
        {Parser(_(text))}
      </div>
    );
  }
}
