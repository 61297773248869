import { applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { composeWithDevTools } from 'redux-devtools-extension';
import { APP_ENV } from 'Config';
import { getHistory } from '../router/getHistory';

const history = getHistory();

const composeEnhancers = APP_ENV === 'production' ? compose : composeWithDevTools;

// PROD Middlewares
const prodMiddlewares = [
  routerMiddleware(history),
  thunk,
  promise,
  loadingBarMiddleware()
];

// DEV Middlewares
const devMiddlewares = [...prodMiddlewares, createLogger()];

const middlewares = APP_ENV === 'v2' ? prodMiddlewares : devMiddlewares;

export const createEnhancer = () => composeEnhancers(applyMiddleware(...middlewares));
