import React, { Component } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import _ from 'i18n';
import TTPModal from "../../ui/TTPModal";
import InputMoment from "input-moment";
import moment from "moment";
import {setCurrentEmailCampaignData} from "../../../actions/actions/currentCampaign";
import {SVG_CALENDAR_ICON} from "../../../services/svgIcones";
import {getDate} from "../../../services/date";
import {changeCampaignScheduledAt} from "../../../actions/thunks/campaign";
import {onError, onSuccess} from "../../../services/utils";

class ChangeScheduledAtDateForm extends Component {

  constructor(props) {
    super(props);
    let date = {};

    let scheduledAt = this.props.currentCampaign.scheduledDeliveryAt;
    if (scheduledAt) {
      var sd = new Date(scheduledAt);
      if (sd.toString() === "Invalid Date") {
        sd = new Date(scheduledAt.replace(/-/g, "/"));
      }
      const tmp = {
        date: sd.getFullYear() + "-" + (sd.getMonth() + 1) + "-" + sd.getDate() + " " + sd.getHours() + ":" + sd.getMinutes() + ":" + sd.getSeconds(),
        timezone: "UTC",
      };
      date = {date: tmp};
    }

    this.state = {showInputMoment: false, newScheduledDeliveryAt: getDate(date.date, this.props.lng, this.props.isStatsPage, true) || ""};
    this.toggleInputMoment = (showInputMoment) => this.setState({ showInputMoment });

    this.modalStyle = {
      content: {
        border: "none",
        background: "rgb(255, 255, 255)",
        outline: "none",
        width: "60%",
        maxWidth: "40%",
        transition: 'opacity 0.4s ease-out 0s',
        boxShadow: '0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)',
        minWidth: '35rem',
        padding: '0 0',
      }
    };
  }

  handleScheduledDeliveryAtChange = (scheduledDeliveryAt) => {
    this.setState({newScheduledDeliveryAt: scheduledDeliveryAt});
  };

  handleSave = () => {
    let { id } = this.props.currentCampaign;
    let { newScheduledDeliveryAt } = this.state;
    let data = { id };
    data['scheduledDeliveryAt'] = newScheduledDeliveryAt;
    this.props.changeCampaignScheduledAt(data).then(res => {
      this.props.setCurrentEmailCampaignData({ scheduledDeliveryAt: newScheduledDeliveryAt });
      this.props.onCloseModal();
      !this.props.isStatsPage ? this.props.history.push(`/stats/${id}`) : this.props.history.push(`/campaigns`);
      onSuccess(res);
    }, err => {
      onError(err);
    });
  };

  render() {
    const { onCloseModal } = this.props;
    let { showInputMoment, newScheduledDeliveryAt } = this.state;

    return <TTPModal isOpen={true} onClose={onCloseModal} modalStyle={this.modalStyle}>
      <div className="ttp-alert-dialog" >
        <div className={`row alert-header align-center header-success`}>
          <div className="icon-container">
            {SVG_CALENDAR_ICON}
          </div>
          <p className="alert-header__title">{_('Change delivery date')}</p>
        </div>

        <div className="alert-message row ttp-alert-scheduled">
          <div className="columns">
            <input
              placeholder={_("delivery date")}
              type="text"
              value={newScheduledDeliveryAt.format(DATE_FORMAT)}
              className={newScheduledDeliveryAt ? "filled-box" : "empty-box"}
              onFocus={() => this.toggleInputMoment(true)}
            />
            {showInputMoment && <div className="input-moment">
              <InputMoment
                moment={newScheduledDeliveryAt || moment()}
                onChange={this.handleScheduledDeliveryAtChange}
                prevMonthIcon="icon-arrow-left"
                nextMonthIcon="icon-arrow-right"
              />
            </div>
            }
          </div>

        </div>

        <div className="column alert-footer">
          <button onClick={onCloseModal} className="cancel-button button">{_('cancel')}</button>
          <button onClick={this.handleSave} className="ok-button  button bg-success">{_('save')}</button>
        </div>
      </div>
    </TTPModal>
  }
}


const DATE_FORMAT = "DD-MM-YYYY HH:mm";

const mapStateToProps = state => ({
  currentCampaign: state.currentCampaign.email,
  lng: state.params.lng,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentEmailCampaignData: (scheduledDeliveryAt) => dispatch(setCurrentEmailCampaignData(scheduledDeliveryAt)),
  changeCampaignScheduledAt: (data) => dispatch(changeCampaignScheduledAt(data)),
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)
(ChangeScheduledAtDateForm);
