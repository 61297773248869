import React, { PureComponent } from "react";
import TagsInput from "react-tagsinput";

import { EMAIL_VALIDATION_REGEX } from "utils";
import _ from "i18n";

export default class EmailTagsInput extends PureComponent {
  state = { tag: "" };

  //handleChangeInput = tag => this.setState({ tag });
  handleChangeInput = tag => {
    if (this.props.isLite) {
      return;
    }

    this.setState({ tag });
  };
  pasteSplit(data) {
    const separators = [
      ",",
      ";",
      "\\(",
      "\\)",
      "\\*",
      "/",
      ":",
      "\\?",
      "\n",
      "\r"
    ];
    return data.split(new RegExp(separators.join("|"))).map(d => d.trim());
  }

  render() {
    const {
      changed,
      emails,
      light,
      renderTag,
      cssClass,
      placeholder,
      maxTags,
      isLite
    } = this.props;
    let props = {
      value: emails,
      onChange: changed,
      inputValue: this.state.tag,
      addOnPaste: true,
      pasteSplit: this.pasteSplit,
      onlyUnique: true,
      addOnBlur: true,
      validationRegex: EMAIL_VALIDATION_REGEX,
      maxTags: maxTags ? maxTags : -1,
      inputProps: {
        placeholder: placeholder
          ? placeholder
          : _("insertYourTargetsEmails") + " ..."
      },
      onChangeInput: this.handleChangeInput,
      readOnly: isLite
    };
    if (renderTag) {
      props.renderTag = renderTag;
    }

    return (
      <div
        className={`email-tags__input ${light &&
          "light"} ${cssClass} ${(!emails || !emails.length) &&
          "empty-box"} ${isLite && "lite"}`}
      >
        <TagsInput {...props} />
      </div>
    );
  }
}
