export const getUserNameForAvatar = (firstName, lastName = '') => {
  let fName = (firstName) ? firstName.split(' ') : "";

  if (fName.length >= 3) {
    return extractFirstLetter(fName, 3);
  }

  let lName = (lastName) ? lastName.split(' ') : "";
  return extractFirstLetter(fName.concat(lName), 3);
};

function extractFirstLetter(arrayStr, length) {
  let result = '';

  for (let i = 0; i < arrayStr.length; i++) {
    if (arrayStr[i] !== undefined) {
      result += arrayStr[i].substring(0, 1);
    }
  }

  return result.toUpperCase().replace(/[^A-Z+]/g, "");
}
