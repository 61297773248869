import React, { Component } from 'react';
import { connect } from 'react-redux';
import Explorer from './explorer/Explorer';
import PageHeader from '../common/PageHeader';
import { fetchEBox, fetchEboxOwner, fetchEboxCountStatus } from "../../actions/thunks/eBox";
import { setInAdminSearchMode, initEboxAttachments, setCurrentNavPage } from "actions";
import { NotificationManager } from "react-notifications";
import _ from "i18n";
import EboxPage from './EboxPage';

const mapStateToProps = (state, ownProps) => ({
  token: state.auth.token,
  owner: state.ebox.owner.data,
  filter: state.ebox.list.filter,
});

const mapDispatchToProps = dispatch => ({
  fetchEboxOwner: (memberId) => dispatch(fetchEboxOwner(memberId)),
  fetchEbox: () => dispatch(fetchEBox(false)),
  setInAdminSearchMode: (inAdminSearchMode) => dispatch(setInAdminSearchMode(inAdminSearchMode)),
  initEboxAttachments: () => dispatch(initEboxAttachments()),
  fetchEboxCountStatus: () => dispatch(fetchEboxCountStatus(true)),
  setCurrentNavPage: () => dispatch(setCurrentNavPage("E_BOX"))
});

@connect(mapStateToProps, mapDispatchToProps)
export default class OtherEbox extends Component {

  componentDidMount() {
    const { owner, setCurrentNavPage } = this.props;
    const query = new URLSearchParams(location.search);
    const ownerId = query.get('ownerId');
    this.props.setInAdminSearchMode(true);
    if (ownerId) {
      this.props.fetchEboxOwner(ownerId).catch(() => {
        NotificationManager.error(_('E-Box not found'), _('error'));
      });
    } else if (owner) {
      this.props.fetchEbox();
      this.props.fetchEboxCountStatus();
    }
    setCurrentNavPage();
  }

  componentDidUpdate(prevProps) {
    const { owner, filter } = prevProps;
    const ownerChanged = this.props.owner !== owner;
    if (ownerChanged) {
      this.props.initEboxAttachments();
    }
    if (ownerChanged || (filter !== this.props.filter) && this.props.owner) {
      this.props.fetchEbox();
      this.props.fetchEboxCountStatus();
    }
  }

  render() {
    return (
      <div id="other-ebox-page" className="ebox-page">
        <PageHeader header="find other E-box"
          subHeader="view other email accounts"
          icon="OTHER_EBOX"
        />
        <Explorer />
        <EboxPage />
      </div>
    )
  }
}
