import React, {Component} from 'react';
import {connect} from 'react-redux';
import SidePageHeader from 'common/SidePageHeader';
import {setCurrentNavPage} from "actions";
import TextModelList from "../text-model/TextModelList";
import {ADMIN_MODE} from "../../constants";
import _ from 'i18n';

class Administration extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {setCurrentNavPage} = this.props;
    setCurrentNavPage("SETTINGS");
  }

  render() {
    return <div className="administration-page side-no-tabs-page">
      <SidePageHeader subHeader="administration"/>
      <div className="white-container">
        <div className="row white-container--title white-container--title-with-filter">
          <h3>{_("texts")}</h3>
          <p>{_("Manage private texts")}</p>
        </div>
        <TextModelList mode={ADMIN_MODE}/>
      </div>
    </div>
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentNavPage: (currentPage) => dispatch(setCurrentNavPage(currentPage)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Administration);
