import React, {PureComponent} from "react";
import {
  getCommunityDisplayName,
  getCommunityLogoUrl
} from "../../services/auth";
import _ from 'i18n';

export default class Community extends PureComponent {
  render() {
    const {community, onClick} = this.props;

    return (
      <div className={`sc__community ${community.disabled && "disabled"}`} onClick={!community.disabled && onClick}>
        <div className="sc__community-container"/>
        <div className="sc__community-logo">
          <img src={getCommunityLogoUrl(community)} alt={community.name}/>
        </div>
        <span className="sc__community-title" title={community.name}>
          {getCommunityDisplayName(community)}
        </span>
        {community.disabled &&
        <div className="sc__community-unauthorised">
          <img src="img/icons/info.svg" alt="unauthorised community"/>
          <span>{_('CommunityUnauthorised')}</span>
        </div>
        }
      </div>
    );
  }
}
