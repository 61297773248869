import React, { Component } from 'react'

import _ from 'i18n';
import { EBOX_TARGET_TYPE_SLIDER } from '../../../services/ebox/widget';

class EboxType extends Component {

  render() {
    const { isFolderEbox, onChange } = this.props;
    const selectType = isFolderEbox ? 'FOLDER' : 'EMAIL';


    return (
      <div className="medium-12">
        <div className="tab-slider">
          {
            Object.keys(EBOX_TARGET_TYPE_SLIDER).map((type) => {
              const { icon, label } = EBOX_TARGET_TYPE_SLIDER[type];
              return (
                <div className={`tab-slider__item ${(selectType == type) ? "active" : ""}`}
                  onClick={() => onChange(!isFolderEbox)}>
                  {icon}
                  {_(label)}
                </div>
              )
            }
            )
          }
        </div>
      </div>

    )
  }
}

export default EboxType;
