import React from 'react';

import _ from 'i18n';
import { TTPSelector } from '../common/TTPSelector';

const EditorSelectorTabSend = (props) => {

  const { onTypeSelect, currentPage ,handleSetCurrentPage} = props;

  return (
    <div className={`tab-pane row align-center boxes active`}>
     
      <TTPSelector tabName={_("editor")} tabLabel={_('newsletter')} icon="NEWSLETTER"
        tabDescription={_('Create your custom template email with simple drag&drop.')}
        onClick={() => handleSetCurrentPage('SEND_PAGE', null)} />
      <TTPSelector tabName={_("editor")} tabLabel={_('Rich Text')} icon="TEXT_EDITOR"
        tabDescription={_('Use the rich text editor to create simple emails.')}
        onClick={() => handleSetCurrentPage('RICH_TEXT_PAGE', null)} />
     
    </div >
  )
}
export default EditorSelectorTabSend;
