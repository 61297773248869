import React, { Component } from 'react';
import { connect } from 'react-redux';

import SMSActionsCell from './datatable/SMSActionsCell';
import NotFound from 'notFound/NotFound';
import GroupsCell from './../campaign/datatable/GroupsCell';
import CampaignsFetching from 'fetching/CampaignFetching';

import { CAMPAIGN_LANGUAGE } from 'Common';
import { deleteSMSCampaign, persistSMSCampaign } from 'thunks';
import { onSuccess, onError, getStatusColorClass, getDate } from 'utils';
import { setTTPDialogCustomData } from "actions";

import _ from 'i18n';
import 'react-notifications/lib/notifications.css';
import DIALOG_TYPES from '../../constants/TTPDialog';
import { SVG_DELETE_ICON } from 'utils';
import {SVG_VECTOR_ICON} from "../../services/svgIcones";


@connect((store) => {
    return {
        campaignsError: store.campaigns.list.error,
        token: store.auth.token,
        fetching: store.campaigns.list.fetching,
        updated: store.campaigns.list.updated,
        updating: store.campaigns.list.updating,
        lng: store.params.lng,
    };
})

export default class CampaignSMSList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            campaignSMSId: null,
            status: null,
            haltStatusSMSCampaignId: null,
        };
        this.setSelectedCampaignSMSId = this.setSelectedCampaignSMSId.bind(this);
    };

    handleOpenStopDialog() {
        const { dispatch } = this.props;
        dispatch(setTTPDialogCustomData({
            'approvedAction': this.stopSMSCampaign.bind(this),
            'message': _('stopCampaignConfirm'),
            'proceedBtnLabel': _('stop'),
            'type': DIALOG_TYPES["ALERT"],
            'title': "Halt confirmation",
        }));
    }

    stopSMSCampaign() {
        const { dispatch } = this.props;
        let id = this.state.haltStatusSMSCampaignId;
        let status = this.state.status;
        dispatch(persistSMSCampaign({ id, status })).then(res => onSuccess(res), err => onError(err));
    }

    handleHaltStatus = (id, status) => {
        this.setState({ haltStatusSMSCampaignId: id, status: status });
    }

    handleOpenDeleteDialog() {
        const { dispatch } = this.props;
        dispatch(setTTPDialogCustomData({
            'approvedAction': this.deleteSMSCampaign.bind(this),
            'message': _('deleteCampaignConfirm'),
            'proceedBtnLabel': _('delete'),
            'type': DIALOG_TYPES["ALERT"],
            'title': "Confirmation of deletion",
            'svgIcon': SVG_DELETE_ICON
        }));
    }

    deleteSMSCampaign() {
        let campaignSMSId = this.state.campaignSMSId;
        let message = { body: 'campaignDeleted', title: 'deleteCampaignSuccess' };
        this.props.dispatch(deleteSMSCampaign(campaignSMSId)).then(res => onSuccess(res, message), err => onError(err));
    }

    setSelectedCampaignSMSId(campaignSMSId) {
        this.setState({
            campaignSMSId: campaignSMSId
        });
    }

    render() {
        let { campaigns, campaignsError, fetching, cssClass } = this.props;
        if (fetching) {
            return <CampaignsFetching />
        }
        if (campaignsError != null && campaignsError.code == 404) {
            return <NotFound />;
        }

        return (
            <div className={`${cssClass} columns`}>
                {campaigns.items.map(item => {
                    const colorClass = getStatusColorClass(item.status);
                    const date = { label: "updatedAt", date: item.updatedAt };

                    return <div key={item.id} className={`row list-item border-${colorClass}`}>
                        <div className="medium-3 p-r-xs">
                            <div className="list-item__header">{item.name}</div>
                        </div>
                        <div className="medium-3 p-l-xs p-r-xs">
                            <div className="list-item__sub-header">
                                <i className="icon-left icon-clock"></i>
                                <span>{_(date.label)} {getDate(date, this.props.lng)}</span></div>
                            <div className="list-item__sub-header"><i className="icon-left icon-globe"></i><span>{_(CAMPAIGN_LANGUAGE[item.language])}</span></div>
                        </div>
                        <div className="medium-3 flex-container align-right p-l-xs p-r-xs"><GroupsCell groups={item.smsCampaignGroups} type="SMS" /></div>
                        <div className="medium-1 list-item__contacts p-l-xs p-r-xs">
                          <div className='icon'>{SVG_VECTOR_ICON}</div>
                          <span>{(item.totalSmsRecipients) ? item.totalSmsRecipients : 0} {_(item.recipientType).toLowerCase()}</span>
                        </div>
                        <SMSActionsCell item={item}
                            colorClass={colorClass}
                            selectedCampaignSMSId={this.setSelectedCampaignSMSId}
                            deleteAction={this.handleOpenDeleteDialog.bind(this)}
                            stopAction={this.handleOpenStopDialog.bind(this)}
                            onStatus={this.handleHaltStatus} />
                    </div>
                })}
            </div>
        );
    }
};
