import React, { Component } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid';

import _ from 'i18n';
import NoteItem from './NoteItem';
import { hasValidLength } from '../../../services/utils';

@connect((store) => {
  return {
    recipientsCount: store.campaigns.recipients.nbResult,
    approved: store.currentCampaign.email.approved,
    manualRecipients: store.currentCampaign.email.manualRecipients,
    recipientType: store.currentCampaign.email.recipientType,
    object: store.currentCampaign.email.object,
    fromName: store.currentCampaign.email.fromName,
    from: store.currentCampaign.email.from,
    isFromEmailValid: store.currentCampaign.email.fromEmailValid,
    html: store.currentCampaign.email.html,
    type: store.currentCampaign.email.type,
    pageUrl: store.currentCampaign.email.pageUrl,
    approvalRequired: store.auth.currentSetting.mandatoryTest,
    currentSideBar: store.currentCampaign.email.currentSideBar,
    scheduledDeliveryAt: store.currentCampaign.email.scheduledDeliveryAt,
  }
})
export default class Notes extends Component {

  renderWarningData() {
    const {isFromEmailValid, recipientsCount, approvalRequired, approved, onToggleSideBar, fromName, recipientType, manualRecipients, type, object, pageUrl, html, scheduledDeliveryAt} = this.props;
    let alerts = [];
    let index = 1;

    let contentValid = false;
    if (type === 'WEB_PAGE') {
      contentValid = pageUrl && pageUrl.length > 0;
    } else {
      contentValid = html;
    }

    if (!hasValidLength(object, 5, 200)) {
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={() => onToggleSideBar(null)} text='invalid_object_warning' index={index++} />);
    }
    if (!contentValid) {
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={() => onToggleSideBar(null)} text='content_warning' index={index++} />);
    }
    if (!hasValidLength(fromName, 3)) {
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={() => onToggleSideBar("PARAMS")} text="from_name_warning" index={index++} />);
    }
    if (!isFromEmailValid) {
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={() => onToggleSideBar("PARAMS")} text="invalid_from_email_warning" index={index++} />);
    }
    if ((recipientType === "MANUAL" && manualRecipients.length < 1) || (recipientType !== "MANUAL" && recipientsCount < 1)) {
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={() => onToggleSideBar("RECIPIENTS")} text="recipient_count_warning" index={index++} />);
    }

    if (approvalRequired && (!approved || approved === 0) && !scheduledDeliveryAt) {
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={() => onToggleSideBar("TEST")} text="approval_required_warning" index={index++} />);
    }

    if (approvalRequired && (!approved || approved === 0) && scheduledDeliveryAt) {
      alerts.push(<NoteItem key={`${uuid()}`} onToggle={() => onToggleSideBar("TEST")} text="scheduled_approval_required_warning" index={index++} />);
    }

    if (alerts.length === 0) {
      return null;
    }
    return <div className="row">
      <div className="columns small-12 sidebar__warning">
        <h5><i className="icon-left icon-pin"/>Note </h5>
        <div className="notes">
          {alerts}
        </div>
      </div>
    </div>;
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.currentSideBar;
  }

  render() {
    const { onToggleSideBar } = this.props;

    return (
      <div className="sidebar__actions">
        <div className="row">
          <div className="columns small-2">
            <span className="sidebar__close icomoon icon-tt-close" onClick={() => onToggleSideBar(null)} />
          </div>
        </div>
        {this.renderWarningData()}
      </div>
    );
  }
}
