export const getLocaleLanguage = () => {
    return localStorage.getItem('lng');
};

export const getEmailingRedirectStorage = () => {
    return localStorage.getItem('emailing_redirect_url');
};

export const setEmailingRedirectStorage = (uri) => {
    if(uri) {
        localStorage.setItem('emailing_redirect_url', uri);
        return uri;
    }
    localStorage.removeItem('emailing_redirect_url');
};
