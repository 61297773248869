import React, {Component} from "react";
import TagsInput from 'react-tagsinput';
import Autosuggest from 'react-autosuggest';
import {NotificationManager} from 'react-notifications';
import { connect } from 'react-redux';
import 'react-tagsinput/react-tagsinput.css';

import _ from 'i18n';
import {fetchPhoneNumbers} from 'thunks';
import {clearSourcesTokens, setCurrentSmsCampaignData} from 'actions';
import debounce from "lodash.debounce";
import { getBeSMSPriceWithMargin } from 'utils';
import {LoadingSuggestion} from "../../../common/LoadingSuggestion";


@connect((store) => {
    return {
        phoneNumbersSuggestions: store.phoneNumbers.items,
        loadingSuggestion: store.phoneNumbers.fetching,
        selectedTags: store.currentCampaign.sms.testPhoneNumbers,
        _requests: store.params.sourcesTokens,
        sending: store.campaigns.list.sending,
        smsCredit: store.auth.currentClient.smsCredit,
        margin: store.auth.currentClient.profitMargin,
        suggestionsError: store.phoneNumbers.error,
    }
})
export default class TestTab extends Component {

    constructor(props){
        super(props);
        this.state = {tag: ''};
        this.debouncedFetchPhoneNumbersSuggestions = debounce(this.fetchPhoneNumbersSuggestions, 1000);
    }

    fetchPhoneNumbersSuggestions({value}) {
        const {dispatch} = this.props;
        dispatch(fetchPhoneNumbers(value));
    }

    autoSuggestRenderInput ({addTag, ...props}) {
        const handleOnChange = (e, {newValue, method}) => {
            if (method === 'enter') {
                e.preventDefault()
            } else {
                props.onChange(e)
            }
        }
        const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
        const inputLength = inputValue.length

        let suggestions = this.props.phoneNumbersSuggestions.filter((state) => {
            return state.user.lastName.length != 0 ? state.user.lastName.toLowerCase().slice(0, inputLength) : state.user.firstName.slice(0, inputLength) === inputValue
        });

        return (
            <Autosuggest
                ref={props.ref}
                suggestions={suggestions}
                shouldRenderSuggestions={(value) => value && value.trim().length > 0}
                getSuggestionValue={(suggestion) => suggestion.user.lastName}
                renderSuggestion={(suggestion) =>{
                    const {user, intlFormat} = suggestion;
                    return <span>{user.lastName.toUpperCase()} {user.firstName} - <span className="suggestion-header">{intlFormat}</span></span>;
                }}
                inputProps={{...props, onChange: handleOnChange}}
                onSuggestionSelected={(e, {suggestion}) => {
                    addTag(suggestion)
                }}
                onSuggestionsClearRequested={() => {}}
                onSuggestionsFetchRequested={(value) => this.debouncedFetchPhoneNumbersSuggestions(value)}
                renderSuggestionsContainer={ ({ containerProps , children, query }) =>
                    <LoadingSuggestion containerProps={containerProps} loadingsSuggestions={this.props.loadingSuggestion} suggestionsError={this.props.suggestionsError} value={query}>
                        { children }
                    </LoadingSuggestion>
                }
            />
        )
    }

    handleTagsChange(tags, changed, changedIndexes) {
        let {selectedTags, dispatch} = this.props;
        if(changed && changed.length > 0  && tags && tags.length > selectedTags.length) {
            let tag = changed[0];
            let suggestions = this.props.phoneNumbersSuggestions;
            if(suggestions.filter(t => t.number === tag['number'].toLowerCase()).length === 0) {
                tags.splice(changedIndexes[0], 1);
            }
        }
        dispatch(setCurrentSmsCampaignData({testPhoneNumbers: tags}))
    }

    handleChangeInput(tag) {
        this.setState({tag});
    }

    handleTestClick() {
        let {onConfirm, selectedTags} = this.props;
        if(selectedTags.length == 0){
            NotificationManager.warning( _('typeTestRecipients'), _('warning'));
            return;
        }
        onConfirm();
    }

    renderCustomTag(props) {
        let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
        const {intlFormat, user} = tag;
        return (
          <span key={key} {...other}>
            <span>{user.lastName.toUpperCase()} {user.firstName} (
                {intlFormat})
            </span>
            {!disabled &&
              <a className={classNameRemove} onClick={(e) => onRemove(key)} />
            }
          </span>
        )
    }

    getApproximateTestCost() {
        let { smsCount, selectedTags, margin } = this.props;
        if (!selectedTags || selectedTags.length == 0) {
            return 0;
        }
        return smsCount * getBeSMSPriceWithMargin(margin) * selectedTags.length;
    }

    render() {
       let {selectedTags, sending, smsCredit} = this.props;

       let areEmptyTags = !(selectedTags.length > 0);

       const approximateTestCost = this.getApproximateTestCost();

       let sendingDisabled = approximateTestCost > smsCredit;

        return (
              <div className="row align-center">
                  <div className={`columns medium-12`}>
                        <h1 className="param__title">{_('sendTestTo')}</h1>
                            <div className={`${(selectedTags && selectedTags.length)?"filled-box":"empty-box"}`}>
                                <TagsInput
                                    renderInput={this.autoSuggestRenderInput.bind(this)}
                                    value={selectedTags}
                                    onChange={this.handleTagsChange.bind(this)}
                                    addKeys={[9, 13, 186, 188]}
                                    removeKeys={[]}
                                    tagDisplayProp='number'
                                    onlyUnique={true}
                                    addOnPaste
                                    inputProps={{placeholder: _('testRecipientsNameAdd')}}
                                    inputValue={this.state.tag}
                                    onChangeInput={this.handleChangeInput.bind(this)}
                                    renderTag={this.renderCustomTag.bind(this)}
                                />
                            </div>
                  </div>
                  <div className="columns medium-12">
                    {sendingDisabled && <strong className="warning">{_('warning')} : {_('poorCredit')}</strong>}
                    <button type="button" disabled={sendingDisabled || areEmptyTags}
                            onClick={(sendingDisabled || areEmptyTags)? "" : !sending && this.handleTestClick.bind(this)}
                            className={`float-right ttp-button ${(!sendingDisabled && !areEmptyTags) ? 'info' : 'disabled'}`}>{sending ? _('Processing...'):_('sendTest')}</button>
                  </div>
              </div>
        );
    }

    componentWillUnmount(){
      const { _requests } = this.props;

      _requests.forEach(
        req => req.sourceToken.cancel("Operation canceled by the user.")
      );
      this.props.dispatch(clearSourcesTokens());
    }
}
