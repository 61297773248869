import * as actions from 'actions';
import {pushSourceToken} from 'utils';
import {ucFirst} from "../../services/common";
import {
  getClientMailingLists,
  persistMailingList,
  deleteMailingList
} from "../../api/TamtamAPI/mailinglist";

export const fetchMailingLists = () => {
  return (dispatch, getState) => {
    const {token, currentClient, user: {language}} = getState().auth;
    const {filter} = getState().mailingLists;
    let clientId = currentClient.id;
    let sourceToken = pushSourceToken('FETCH_MAILING_LISTS', dispatch);

    filter.language = ucFirst(language);
    return dispatch(actions.fetchMailingLists(
      getClientMailingLists(token, clientId, filter, sourceToken)
    ));
  };
};

export const saveMailingList = (data) => {
  return (dispatch, getState) => {
    const {token, currentClient} = getState().auth;
    const {mailingLists} = getState();
    data = {...data, ...mailingLists.data};
    data.clientId = currentClient.id;

    return dispatch(actions.saveMailingList(
      persistMailingList(token, data)
    ));
  };
};

export const removeMailingList = (id) => (dispatch, getState) => {
  const {token} = getState().auth;

  return dispatch(actions.removeMailingList(
    deleteMailingList(token, id).then((res) => {
      return res.data;
    }, err => err)
  ));
};
