import React from 'react';
import NotFound from '../notFound/NotFound';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <NotFound errorPageType="ERROR_500" />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
