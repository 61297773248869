import React, { Component } from "react";
import TTPModal from "../../ui/TTPModal";
import TTPLoader from "../../ui/TTPLoader";
import "../../../styles/components/campaign/list/_campaign-approval.scss";
import _ from "i18n";
import TTPSelect from "../../common/TTPSelect";
import { connect } from "react-redux";
import TeamsFeedbackFetching from "../../fetching/TeamsFeedbackFetching";
import { initApprovalTeams } from "actions";
import { fetchApprovalFeedback, fetchApprovalTeams } from "thunks";
import NotFound from "../../notFound/NotFound";
import { stringToColor } from "../../../services/colors";
import TTPCard from "../../common/card/TTPCard";

@connect((store) => {
  return {
    lastTestversion: store.currentCampaign.email.lastTestversion,
    campaignId: store.currentCampaign.email.id,
    fetching: store.approval.list.fetching,
    approvals: store.approval.list.items,
    teams: store.approval.teams.items,
    fetchingTeams: store.approval.teams.fetching,
  };
})
export default class CampaignApprovalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: props.lastTestversion,
    };

    this.handleVersionChange = (version) => this.setState({ version });

    this.modalStyle = {
      content: {
        inset: "5% 40px auto 4%",
        border: "none",
        background: "rgb(255, 255, 255)",
        transition: "opacity 0.4s ease-out 0s",
        boxShadow:
          "0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)",
        padding: "0 0",
        width: "65%",
        height: "80%",
        top: "5%",
        left: "5%",
        transform: "translate(25%, 9%)",
        position: "fixed",
        maxHeight: "85%",
        maxWidth: "80%",
      },
      overlay: {
        zIndex: 9,
        backgroundColor: "hsla(0,0%,4%,.45)",
      },
    };
  }

  componentDidMount() {
    const { dispatch, campaignId } = this.props;
    dispatch(
      fetchApprovalFeedback({
        campaignId,
        version: this.state.version,
      }),
    );

    dispatch(fetchApprovalTeams());
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(initApprovalTeams());
    dispatch(fetchApprovalTeams());
  }

  componentWillUpdate(nextProps, nextState) {
    const { campaignId } = nextProps;
    if (campaignId && nextState.version != this.state.version) {
      nextProps.dispatch(
        fetchApprovalFeedback({
          campaignId,
          version: nextState.version,
        }),
      );
    }
  }

  renderVersionOptions() {
    let { version } = this.state;
    let { lastTestversion } = this.props;

    let options = [];

    for (let i = 1; i <= lastTestversion; i++) {
      options.push({ id: i + "", label: `Version ${i}` });
    }

    return (
      <TTPSelect
        simple={true}
        notClearable={true}
        values={version}
        placeholder={_("version")}
        options={options}
        onChange={this.handleVersionChange}
        cssClass="version-select"
      />
    );
  }

  renderTeamFeedback() {
    const { approvals, teams } = this.props;

    const teamsFeed = Object.values(approvals);
    if (teamsFeed.length === 0) return null;

    const team = teamsFeed[0];
    const initial = team.name?.substring(0, 1) || "";

    const approvalsCount =
      team.members?.reduce((count, member) => {
        if (member.decision === "ACCEPT") count++;
        return count;
      }, 0) || 0;

    const teamId = Object.keys(approvals)[0];
    const teamFounded = teams.find((team) => team.id === Number(teamId));
    const teamMembers = teamFounded?.members || [];
    const approvalMembers = team.members || [];
    const approvalEmails = new Set(
      approvalMembers.map((member) => member.email),
    );
    const notInApprovalMembers = teamMembers.filter(
      (member) => !approvalEmails.has(member.email),
    );

    const updatedApprovalMembers = [
      ...approvalMembers,
      ...notInApprovalMembers.map((member) => ({
        id: member.id,
        email: member.email,
        avatar: member.user?.avatarUrl,
        approverName: `${member.user?.lastName || ""} ${member.user?.firstName || ""}`,
        decision: "PENDING",
      })),
    ];

    return (
      <div
        key={`team-${team.id}`}
        className="team-list-item team-list-item--open"
      >
        <div className="row align-middle team-header">
          <div className="small-1">
            <span
              className="team-avatar"
              style={{ background: stringToColor(team.name) }}
            >
              {initial}
            </span>
          </div>
          <div className="small-7">
            <span className="param-label">{team.name}</span>
            <p>{team.description}</p>
          </div>
          <div className="small-4 team-header--members">
            <div>
              {_("the approval threshold")} : <b>{team.minApprovers}</b>
            </div>
            <div>
              {_("acceptedApproval")} :&nbsp;
              <b>
                {approvalsCount}/{teamMembers.length}
              </b>
            </div>
          </div>
        </div>
        <div className="team-setting">
          <div style={{ columnCount: 3 }}>
            {updatedApprovalMembers.map((member) => (
              <TTPCard
                member={member}
                key={`approval-${member.id}`}
                hideSendButton={true}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { isOpen, onClose, fetching, fetchingTeams, approvals, teams } =
      this.props;
    let teamsBlock = null;

    if (fetching || fetchingTeams) {
      teamsBlock = <TeamsFeedbackFetching />;
    } else if (
      Object.keys(approvals || {}) &&
      Object.keys(approvals || {}).length > 0 &&
      Object.keys(teams || {}) &&
      Object.keys(teams || {}).length > 0
    ) {
      teamsBlock = this.renderTeamFeedback();
    }

    return (
      <TTPModal isOpen={isOpen} onClose={onClose} modalStyle={this.modalStyle}>
        <div className="tab-container">
          {(fetching || fetchingTeams) && (
            <TTPLoader cssClass={"fixed-loader"} />
          )}
          <div className="row text-center">
            <h4 className="small-12 tab-header">{_("approvers's feedback")}</h4>
          </div>
          <div className="row">
            <div className="small-2" style={{ marginLeft: "3%" }}>
              {this.renderVersionOptions()}
            </div>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="small-11 team-list">{teamsBlock}</div>
          </div>
        </div>
      </TTPModal>
    );
  }
}
