const initialStateForFormations = {
  fetching: false,
  items: [],
  error: null,
  nbResult: 0,
  filter: {
    searchWord: "",
    language: null,
    tags: null,
    trainingType: null,
    paginationPage: 1,
    pageSize: 10,
    formationPages: null,
  },
};

export const formationsReducer = (
  state = initialStateForFormations,
  action
) => {
  switch (action.type) {
    case "INIT_FORMATIONS": {
      return initialStateForFormations;
    }

    case "SET_FORMATIONS_PAGINATION_PAGE": {
      return {
        ...state,
        filter: {
          ...state.filter,
          paginationPage: action.paginationPage,
        },
      };
    }

    case "SET_FORMATIONS_SEARCH_WORD": {
      return {
        ...state,
        filter: {
          ...state.filter,
          searchWord: action.searchWord,
          paginationPage: 1,
        },
      };
    }

    case "SET_FORMATIONS_TAGS": {
      return {
        ...state,
        filter: {
          ...state.filter,
          tags: action.tags,
          paginationPage: 1,
        },
      };
    }

    case "SET_FORMATIONS_TYPE": {
      return {
        ...state,
        filter: {
          ...state.filter,
          trainingType: action.trainingType,
          paginationPage: 1,
        },
      };
    }

    case "SET_FORMATIONS_EVENT": {
      return {
        ...state,
        filter: {
          ...state.filter,
          event: action.event,
          paginationPage: 1,
        },
      };
    }
    case "FETCH_FORMATIONS_PENDING": {
      return {
        ...state,
        fetching: true,
      };
    }

    case "SET_FORMATIONS_LANGUAGE": {
      return {
        ...state,
        filter: {
          ...state.filter,
          language: action.language,
          paginationPage: 1,
        },
      };
    }

    case "FETCH_FORMATIONS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      const { filter, items } = state;
      let newItems = [];
      if (filter.paginationPage == 1) {
        newItems = data;
      } else {
        newItems = items;
        newItems.push(...data);
      }

      return {
        ...state,
        items: newItems,
        fetching: false,
        fetched: true,
        nbResult,
      };
    }

    default:
      return state;
  }
};
