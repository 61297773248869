import React, { Component } from 'react';
import uuid from 'uuid';

export class SimpleTableFetching extends Component {

  getRows() {
    let rows = [];
    for (let i = 0; i < 5; i++) {
      rows.push(<div key={uuid()} className="row ttp-datatable__row datatable-fetching">
        <div className="small-3">
          <h4 className="gradiant"></h4>
        </div>
        <div className="small-3">
          <h5 className="gradiant"></h5>
        </div>
        <div className="small-1">
          <h6 className="gradiant"></h6>
        </div>
        <div className="small-1">
          <h6 className="gradiant"></h6>
        </div>
        <div className="small-1">
          <h5 className="gradiant"></h5>
        </div>
        <div className="small-2">
          <h4 className="gradiant"></h4>
        </div>
        <div className="small-1">
          <h5 className="gradiant"></h5>
        </div>
      </div>);
    }
    return rows;
  }

  render() {
    return <div>{this.getRows()}</div>;
  }
}

export class SMSBillsDatatableFetching extends Component {
  getFetchingRow() {
    let rowsCount = this.props.rowsCount || 5;
    let rows = [];
    for (let i = 0; i < rowsCount; i++) {
      rows.push(<div key={uuid()} className="row ttp-datatable__row">
        <div className="small-2">
          <h4 className="gradiant"></h4>
        </div>
        <div className="small-1">
          <h4 className="gradiant"></h4>
        </div>
        <div className="small-3">
          <h3 className="gradiant"></h3>
        </div>
        <div className="small-2">
          <h5 className="gradiant"></h5>
        </div>
        <div className="small-2">
          <h4 className="gradiant"></h4>
        </div>
        <div className="small-2">
          <h6 className="gradiant"></h6>
        </div>
      </div>);
    }
    return rows;
  }

  render() {
    return <div className="ttp-datatable columns datatable-fetching">
      <div className="row ttp-datatable__header">
        <div className="small-2">
          <h5 className="gradiant"></h5>
        </div>
        <div className="small-1">
          <h5 className="gradiant"></h5>
        </div>
        <div className="small-3">
          <h3 className="gradiant"></h3>
        </div>
        <div className="small-2">
          <h4 className="gradiant"></h4>
        </div>
        <div className="small-2">
          <h6 className="gradiant"></h6>
        </div>
        <div className="small-2">
          <h6 className="gradiant"></h6>
        </div>
      </div>
      {this.getFetchingRow()}
    </div>
  }
}

export class NotificationsTableFetching extends Component {

  render() {

      let items = [];
      for (let i = 0; i < 3; i++) {
          items.push(<div key={i} className={`row ttp-datatable__row list-item border-draft datatable-fetching`}>
                  <div className="medium-2">
                      <h3 className="list-item__header gradiant"></h3>
                  </div>
                  <div className="medium-2">
                      <div className="small-2">
                           <ul className="notifications-list__lang">
                               <span className="gradiant"></span>
                               <span className="gradiant"></span>
                               <span className="gradiant"></span>
                           </ul>
                      </div>
                  </div>
                  <div className="medium-2">
                      <h4 className="gradiant"></h4>
                  </div>
                  <div className="medium-3">
                      <h4 className="gradiant"></h4>
                  </div>
                  <div className="medium-1">
                      <h4 className="list-item__sub-header gradiant"></h4>
                      <h4 className="list-item__sub-header gradiant"></h4>
                      <h4 className="list-item__sub-header gradiant"></h4>
                      <h4 className="list-item__sub-header gradiant"></h4>
                  </div>
                  <div className="medium-2 flex-container align-right">
                      <h4 className="list-item__status gradiant"></h4>
                  </div>
              </div>
          );
      }
      return <div className="small-12 columns ttp-datatable notifications-list campaign-fetching">{items}</div>
  }
}
