import * as actions from 'actions';
import {
  saveSMSCampaign, getSMSCampaigns, getSMSCampaign, removeSMSCampaign, getSMSRecipients,
  getPhoneNumbers, getApproximateCost, getClientAuthorizedBalance, getClientRealCredit, updateSmsStatus
} from 'TamtamAPI';
import { pushSourceToken } from 'utils';
import { getHistory } from '../../router/getHistory';

export const persistSMSCampaign = (data) => {
  return (dispatch, getState) => {
    const { token, currentClient, user } = getState().auth;
    const { campaigns } = getState();
    let clientId = (currentClient && currentClient.id) || null;

    let totalSmsRecipients = 0;
    if (data.recipientType == "MANUAL") {
      totalSmsRecipients = data.manualSmsRecipients ? data.manualSmsRecipients.length : 0;
    } else if (campaigns.recipients && campaigns.recipients.recipientsTotalCount) {
      totalSmsRecipients = campaigns.recipients.recipientsTotalCount;
    }

    data.totalSmsRecipients = totalSmsRecipients;
    data.sender = user;

    return dispatch(actions.createCampaign(
      saveSMSCampaign(token, data, clientId).then((res) => {
        const history = getHistory();
        if (data.action == "DRAFT") {
          history.push('/smsCampaigns');
        } else if (data.action == "SEND") {
          history.push('/stats-sms/' + res.data.data.id);
        } else if (!data.id) {
          history.push('/campaign-sms/' + res.data.data.id);
        }
        return res.data.data;
      }, err => err)
    ));
  };
};

export const fetchSMSCampaigns = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    const { smsPaginationPage, smsPageSize } = getState().campaigns.list;
    const { status, language, campaignName, dateRange } = getState().campaigns.list.filter;
    const { sortConfig } = getState().campaigns.sort;

    let clientId = (currentClient && currentClient.id) || null;

    return dispatch(actions.fetchCampaigns(
      getSMSCampaigns(token, clientId, smsPaginationPage, smsPageSize, status, language, campaignName, sortConfig, dateRange)
    ));
  };
};

export const fetchSMSCampaign = (campaignSMSId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.fetchCampaigns(
      getSMSCampaign(token, campaignSMSId)
    ));

  };
};

export const deleteSMSCampaign = (campaignSMSId) => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;

    let clientId = (currentClient && currentClient.id) || null;

    return dispatch(actions.removeCampaign(
      removeSMSCampaign(token, campaignSMSId, clientId).then((res) => {
        return res.data.data;
      }, err => err)
    ));
  };
};

export const fetchSMSRecipients = (filterData) => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    const { name, paginationPage, pageSize } = getState().campaigns.recipients.filter;
    let clientId = (currentClient && currentClient.id) || null;

    filterData.name = name;
    return dispatch(actions.fetchRecipients(
      getSMSRecipients(token, clientId, paginationPage, pageSize, filterData)
    ));
  };
};

export const fetchPhoneNumbers = (userLastName) => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken('FETCH_PHONES', dispatch);
    const { token, currentClient } = getState().auth;
    let client = (currentClient && currentClient.id) || null;

    return dispatch(actions.fetchPhoneNumbers(
      getPhoneNumbers(token, client, userLastName, sourceToken)
    ));
  };
};

export const getSmsApproximateCost = (filterData) => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken('FETCH_SMS_APPROXIMATE_COST', dispatch);
    const { token, currentClient } = getState().auth;
    let clientId = (currentClient && currentClient.id) || null;

    return dispatch(actions.getApproximateCost(
      getApproximateCost(token, clientId, filterData, sourceToken)
    ));
  };
};

export const getAuthorizedCredit = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    let clientId = (currentClient && currentClient.id) || null;

    return dispatch(actions.getClientAuthorizedBalance(
      getClientAuthorizedBalance(token, clientId)
    ));
  };
};


export const getRealCredit = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    let organizationSettingsId = (currentClient && currentClient.organizationSettingsId) || null;

    return dispatch(actions.getClientRealCredit(
      getClientRealCredit(token, organizationSettingsId)
    ));
  };
};

export const updateCampaignSmsStatus = (data) => (dispatch, getState) => {
  const { token } = getState().auth;
  return dispatch(actions.setCampaignStatus(updateSmsStatus(token, data)));
};
