import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "i18n";
import { IS_EMAIL_VALID_UA } from "../../../services/svgIcones";
import LinksInventoryModal from "../../campaign/send/LinksInventory/LinksInventoryModal";
import { CAMPAIGN_LANGUAGE } from "../../../config/Common";
import { ucFirst } from "../../../services/common";
import StepsFooter from "./StepsFooter";
import { ModalConfirm } from "tamtam-components";
import { WarningBar } from "./notifications/WarningBar";
import Parser from "html-react-parser";
import moment from "moment";

@connect((store) => {
  return {
    auth: store.auth,
    tamtamRecipientsCount: store.campaigns.recipients.nbResult,
    currentStep: store.currentCampaign.email.currentSideBar === "SEND",
    manualRecipientsCount: store.currentCampaign.email.manualRecipients.length,
    attachmentsCount: store.currentCampaign.email.attachments?.length || 0,
    recipientType: store.currentCampaign.email.recipientType,
    fromName: store.currentCampaign.email.fromName,
    from: store.currentCampaign.email.from,
    object: store.currentCampaign.email.object,
    isFromEmailValid: store.currentCampaign.email.fromEmailValid,
    language: store.currentCampaign.email.language,
    scheduledDeliveryAt: store.currentCampaign.email.scheduledDeliveryAt,
    approved: store.currentCampaign.email.approved,
    typePage: store.currentCampaign.email.type,
    notifyCommunityChannel: store.currentCampaign.email.notifyCommunityChannel,
    newsLetter: store.currentCampaign.email.newsLetter,
    approvalRequired: store.auth.currentSetting.mandatoryTest,
    updating: store.currentCampaign.email.updating,
    currentCampaign: store.currentCampaign.email,
    approvalTeams: store.currentCampaign.email.approvalTeams,
    programmedDate: store.currentCampaign.uaNewsletter.programmedDate,
    manualRecipients: store.currentCampaign.email.manualRecipients,
    liteRecipients: store.currentCampaign.email.liteRecipients,
    nbOfRecipients: store.currentCampaign.email.nbOfRecipients,
  };
})
export default class SendStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
    };
  }

  handleShowDialog = () => {
    const { currentCampaign } = this.props;
    let themeContent = currentCampaign.mutatedContent
      ? currentCampaign.mutatedContent
      : currentCampaign.html;
    let count = (themeContent.match(/<a[^>]*href=["']["']/g) || []).length;
    this.setState({ isOpenModal: true });
  };

  render() {
    const {
      currentStep,
      language,
      from,
      fromName,
      typePage,
      attachmentsCount,
      isFromEmailValid,
      approved,
      scheduledDeliveryAt,
      object,
      approvalRequired,
      approvalTeams,
      onConfirm,
      nbOfRecipients,
      auth,
    } = this.props;
    let { isOpenModal } = this.state;

    const { loggedAs, preference, detached } = this.props.auth;

    const authorisedToSend =
      detached ||
      loggedAs === "ADMIN" ||
      (preference && preference["allowSendCampaign"] === 1);

    const approvalCondition =
      approvalRequired && (!approved || approved === 0) && !scheduledDeliveryAt;

    const isApproved =
      (!approvalCondition &&
        ((approvalTeams && !Array.isArray(approvalTeams)) ||
          (Array.isArray(approvalTeams) && approvalTeams.length > 0))) ||
      auth.currentClient.id !== 4;

    const allowSend =
      authorisedToSend &&
      isFromEmailValid &&
      nbOfRecipients > 0 &&
      (isApproved || scheduledDeliveryAt);

    return (
      <div id="send-tabs__tab" className={`${!currentStep && "hide"}`}>
        <div className="tab">
          <h4 className="tab-header">{_("sendTitle")}</h4>
          <div className="row">
            <div className="small-12 send-summary">
              <li>
                <div>{ucFirst(_("subject"))} :</div>
                <div>{object}</div>
              </li>
              <li>
                <div>{_("fromEmail")} :</div>
                <div>
                  {from} {IS_EMAIL_VALID_UA(isFromEmailValid)}
                </div>
              </li>
              <li>
                <div>{_("fromName")}:</div>
                <div>{ucFirst(fromName)}</div>
              </li>
              <li>
                <div>{_("language")} :</div>
                <div>{ucFirst(_(CAMPAIGN_LANGUAGE[language]))}</div>
              </li>
              {/*<li>
                <div>{_("attachments")} :</div>
                <div>{attachmentsCount}</div>
              </li>*/}
              {auth.currentClient.id === 4 && (
                <li>
                  <div>{_("approved")} :</div>
                  <div>{ucFirst(approved === 1 ? _("yes") : _("no"))}</div>
                </li>
              )}
              <li>
                <div>{_("scheduled sending")} :</div>
                <div>{ucFirst(scheduledDeliveryAt ? _("yes") : _("no"))}</div>
              </li>
              {scheduledDeliveryAt && (
                <li>
                  <div>{_("scheduledFor")} :</div>
                  <div>
                    {moment(scheduledDeliveryAt).format(
                      "MMMM Do YYYY, h:mm:ss a",
                    )}
                  </div>
                </li>
              )}
              {/*<li>
                <div>{_("links inventory")} :</div>
                <div
                  onClick={() => this.setShowLinks(true)}
                  className="flex-container"
                >
                  {ucFirst(_("view"))}
                  <a>
                    <i className="icon-right icon-share-alt"/>
                  </a>
                </div>
              </li>*/}
              {this.state.showLinks && (
                <LinksInventoryModal onClose={() => this.setShowLinks(false)} />
              )}
            </div>
          </div>
          {!isApproved && (
            <WarningBar message={Parser(_("approval_required_warning"))} />
          )}
        </div>
        <ModalConfirm
          type={"send"}
          isOpen={isOpenModal}
          onCancel={() => this.setState({ isOpenModal: false })}
          onConfirm={() => {
            onConfirm();
            this.setState({ isOpenModal: false });
          }}
          inProcess={false}
          actionFailed={false}
          title={_("Confirmation of sending")}
          text={_("confirmSendCampaign")}
          labelNo={_("cancel")}
          labelYes={_("yesSend")}
          labelError={"Error"}
        />
        <StepsFooter
          allowSend={allowSend}
          handleShowDialog={this.handleShowDialog}
        />
      </div>
    );
  }
}
