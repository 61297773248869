import React, { Component } from 'react';

export default class ThemeFetching extends Component {
  render() {
    const { inGenericEmailing } = this.props;
    return <div className={`${inGenericEmailing ? "small-6 medium-4" : "large-3 medium-4 small-6 theme__item-container"}`}>
      <div className={`theme__item fetching ${inGenericEmailing ? "in-generic-emailing" : ""}`}>
        {inGenericEmailing && <div className="item__header">
          <span className="app-name gradiant" />
          <span className="scope" />
        </div>}
        <div className="theme__item__preview" style={{ backgroundImage: "url('img/theme/no-theme.png'})" }} />
        <div className="theme__item__status">
          <div className="item-name gradiant" />
          <div className="item-tags">
            <span className="item-tag gradiant" />
            <span className="item-tag gradiant" />
            <span className="item-tag gradiant" />
          </div>
        </div>
      </div>
    </div>
  }
}
