import React, { Component } from 'react';

export default class ChartContainer extends Component {
  render() {
    return (
      <div className="chartContainer m-r-xs m-l-xs m-b-s">
        <div className="chartHeader">
            {this.props.title}
        </div>
        {this.props.children}
      </div>
    );
  }
}
