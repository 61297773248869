const initialStateForSpeakers = {
    fetching: false,
    items: [],
    error: null,
    nbResult: 0,
    filter: {
      event: null,
      searchWord: null,
      paginationPage: 1,
      pageSize: 12
    }
  };
  
  export const speakersReducer = (state = initialStateForSpeakers, action) => {
    switch (action.type) {
        case "INIT_SPEAKERS": {
            return initialStateForSpeakers;
        }

        case 'SET_SPEAKERS_PAGINATION_PAGE': {      
            return {...state, filter:
                {
                ...state.filter, 
                paginationPage: action.pageNumber
                }
            };
            break;
        }

        case "SET_SPEAKERS_SEARCH_WORD": {
            return {
                ...state,
                filter: { 
                    ...state.filter,
                    searchWord: action.searchWord,
                    paginationPage: 1,
                }
            };
        }

        case "SET_SPEAKERS_EVENT": {
            return {
                ...state,
                filter: { 
                    ...state.filter,
                    event: action.event,
                    paginationPage: 1,
                }
            };
        }

        case 'FETCH_SPEAKERS_PENDING': {
            return {
              ...state,
              fetching: true
            };
            break;
        }

        case 'FETCH_SPEAKERS_FULFILLED': {
            const { data, nbResult } = action.payload.data;
            const { filter, items } = state;
            let newItems = [];
            if(1 == filter.paginationPage){
              newItems = data;
            }else{
              newItems = items;
              newItems.push(...data);
            }

            let newState = {
              ...state,
              items: newItems, 
              fetching: false, 
              nbResult: (!nbResult && filter.paginationPage!= 1)?0:nbResult,
              nbResult: (nbResult)?nbResult:0,
            };

            if(nbResult){
              newState.nbResult = nbResult;
            }else if(filter.paginationPage == 1) {
              newState.nbResult = 0;
            }

            return newState;
            
            break;
        }
        
        default:
        return state;
    }
  };