import * as actions from 'actions';
import {
    getClientTextModel,
    persistTextModel,
    removeTextModel,
    sendTextModelEmailTest
} from 'TamtamAPI';
import _ from 'i18n';
import { onSuccess } from 'utils';
import { NotificationManager } from "react-notifications";
import { onError } from '../../services/utils';

export const fetchTextModels = (lng, isLimited = false, textModelId = null) => {
    return (dispatch, getState) => {
        const { token, currentClient } = getState().auth;
        const { language, targetApp,targetType, paginationPage } = getState().textModels.filter;
        let { filter, items } = getState().textModels;

        if (textModelId) {
            filter = { textModelId };
        } else {
            filter = { ...filter, language: lng || language, targetApp,targetType, isLimited }
        }

        filter.offset = (paginationPage == 1 || !items) ? 0 : items.length;
        const { location } = getState().router;
        const query = new URLSearchParams(location.search);
        let clientId = query.get("communityId") ? query.get("communityId") : currentClient.id;

        return dispatch(actions.fetchTextModels(
            getClientTextModel(token, clientId, filter)
        ));
    };
};

export const saveTextModel = (data, isAdminMode = false) => {
    return (dispatch, getState) => {
        const { token, currentClient } = getState().auth;
        data.clientId = currentClient.id;
        data.isAdminMode = isAdminMode;

        return dispatch(actions.saveTextModel(
            persistTextModel(token, data).then((res) => {
                let newItem = res.data.data;
                return { ...newItem};
            })
        ));
    };
};

export const deleteTextModel = (textModelId) => {
    return (dispatch, getState) => {
        const { token, currentClient } = getState().auth;
        let clientId = currentClient.id;

      return dispatch(actions.removeTextModel(
        removeTextModel(token, textModelId, clientId).then((res) => {
            onSuccess(res);
            return res.data;
          }, (err) => {
            if (err.response.data.submittedData.main === 1) {
              NotificationManager.warning(_('mainTextModel'), _('warning'));
            } else {
              NotificationManager.warning(_('textModelUsed'), _('warning'));
            }
          })
      ));
    };
};

export const sendTextModelTest = (data) => {
    return (dispatch, getState) => {
      const { token } = getState().auth;
      return dispatch(actions.sendTextModelTest(
        sendTextModelEmailTest(token, data).then(
            (res) => {
              onSuccess(res);
            },
            (err) => onError(err)
          )
        ))
    }
  };
