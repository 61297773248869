import axios from '../axios-api';
import { throwCatchedError, updateSourceToken } from 'utils';

let getClientListsSourceToken;

export const getClientMailingLists = (token, clientId, data, sourceToken = null) => {
  getClientListsSourceToken = updateSourceToken(getClientListsSourceToken, sourceToken);
  const fields = ['*', 'subscribersCount'];
  const requestUrl = `/mailing/mailing-list`;

  let filter = [
    {
      property: 'client',
      value: clientId,
      operator: 'eq'
    }
  ];
  if (data.id) {
    filter.push({
      property: 'id',
      value: data.id,
      operator: 'eq'
    });
  }
  if (data.searchWord) {
    filter.push({
      property: `name${data.language}`,
      value: data.searchWord,
      operator: 'like'
    });
  }
  if (data.mandatory) {
    filter.push({
      property: 'mandatory',
      value: data.mandatory === "true" ? 1 : 0,
      operator: 'eq'
    });
  }
  let sort = [{
    property: 'createdAt',
    dir: 'desc',
  }];
  let params = {
    access_token: token,
    fields: fields.join(','),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
  };
  if (data.pageSize && data.paginationPage) {
    params = { ...params, start: ((data.paginationPage - 1) * data.pageSize) }
  }
  if (data.pageSize) {
    params.limit = data.pageSize;
  }
  return axios.get(requestUrl, {
    cancelToken: getClientListsSourceToken.token,
    params
  }).catch(function (thrown) { throwCatchedError(thrown); });
};

export const persistMailingList = (token, data) => {
  const requestUrl = `/mailing/mailing-list`;
  var formData = new FormData();
  formData.append('access_token', token);
  if (data.id) {
    formData.append('id', data.id);
  }
  formData.append('client', data.clientId);

  ['Fr', 'Nl', 'En'].map(lng => {
    formData.append(`name${lng}`, data[`name${lng}`]);
    formData.append(`description${lng}`, data[`description${lng}`]);
  });

  formData.append('description', data.description);
  formData.append('mandatory', data.mandatory);

  if (data.subscribersFilter) {
    formData.append('subscribersFilter', JSON.stringify(data.subscribersFilter));
  }

  return axios.post(requestUrl, formData);
}

export const deleteMailingList = (token, id) => {
  const requestUrl = `/mailing/delete-mailing-list`;
  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('id', id);

  return axios.post(requestUrl, formData);
};

