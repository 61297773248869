import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchEvents } from 'thunks';

import TTPFilterHorizontalBar from 'common/filter/TTPFilterHorizontalBar';

import debounce from "lodash.debounce";
import _ from 'i18n';

const mapStateToProps = (state, ownProps) => ({
    events: state.filters.events.items,
    language: state.params.lng,
    viewMode: state.themes.newsletter.viewMode,
});

const mapDispatchToProps = dispatch => ({
    fetchEvents: () => dispatch(fetchEvents()),
});

@connect(mapStateToProps, mapDispatchToProps)
class EventFilter extends Component {

    constructor(props) {
        super(props);

        this.handleSearchInputChange = debounce(this.handleSearchInputChange, 1000);
    }

    componentDidMount() {
        this.props.fetchEvents();
    }

    componentWillReceiveProps(nextProps) {
        const {events, filter} = nextProps;
        if(!filter.event && events && events.length){
            this.handleSelectEvent(events[0].id);
        }
    }

    handleSelectEvent = (selected) => {
        this.props.onEventChange(selected);
    };

    handleSearchInputChange = (searchWord) => {
        this.props.onSearchWordChange(searchWord);
    }

    render() {
        const { filter,language, events, viewMode, hideViewModes } = this.props;
        let filters = [];
        let eventOptions = [];
        if(events && events.length > 0){
            let lng = language.charAt(0).toUpperCase() + language.slice(1).toLowerCase();
            eventOptions = events.map( event => {
                return { value: event.id, label: event[`name${lng}`] || event['nameFr'] }
            });
        }
        filters.push({ placeholder: _("event"), options: eventOptions, values: filter.event, action: this.handleSelectEvent, isSimple: true, cssClass: 'medium-6' })

        return <TTPFilterHorizontalBar
            isSimple={true}
            filters={filters}
            searchInputValue={filter.searchWord}
            handleSearchInputChange={this.handleSearchInputChange}
            cssClass="ttp-medium-9"
            viewMode={!hideViewModes && viewMode}
        />
    }
}

export default EventFilter;
