import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubMenuLink } from "../../SubMenuLink";

import _ from "i18n";

@connect((store) => {
  return {
    detached: store.auth.detached,
    currentClient: store.auth.currentClient,
  }
})
export default class CampaignsSMS extends Component {

  render() {
    return <SubMenuLink pathname="/smsCampaigns" title="smsCampaigns" icon="SMS_LIGHT" />;
  }
};
