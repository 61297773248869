import React, { Component } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer } from "recharts";
import Loader from "react-loaders";
import _ from 'i18n';

export default class PercentageChart extends Component {
  render() {
    const colors = ["#18A0FB", "#FFC43A", "#F7953E", "#2AF1E2", "#F9626D", "#cec9c9"];
    const { dataBar } = this.props;
    if (!dataBar) return null;

    return (
      <div className="percentChart cell">
        {(dataBar.length === 0) &&
          <div className="percentChart__loader">
            <Loader type="line-spin-fade-loader" />
          </div>
        }
        <div>
          <div className="percentBars">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart width={100} height={40} data={dataBar}>
                <Bar dataKey="value" barSize={8}  minPointSize={3}>
                  {
                    dataBar.map((entry, index) => (
                      <Cell
                        fill={entry.name === "undefined" ? colors[5] : colors[index]}
                        key={`cell-${index}`}
                        radius={[4, 4, 4, 4]}
                        animationEasing={"ease-in-out"}
                        animationDuration={800}
                        minPointSize={3}
                        type="monotone" />
                    ))
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="percentKeys_container ">
          <ul className="keyItems">
            {
              dataBar.map((entry, index) => (
                <li key={entry.name}><span style={{ color: (entry.name === "undefined" ? colors[5] : colors[index]), fontWeight: 'bold', marginRight: '.3rem' }}>{entry.value}%</span><span>{_(entry.name)}</span></li>
              ))
            }
          </ul>
        </div>
      </div>
    )
  }
}
