import _ from "i18n";
import moment from "moment";

export const formatData = (data, attributes, filter) => {
  let keyAxis = 31;
  if (filter.period == "annual") {
    keyAxis = _("month");
  } else {
    let month = (new Date(filter.month)).getMonth() + 1;
    keyAxis = (([4, 6, 9, 11]).includes(month)) ? 30 : keyAxis;
    keyAxis = (month == 2) ? ((filter.year % 4 == 0 && (filter.year % 100 != 0 || filter.year % 400 == 0)) ? 29 : 28) : keyAxis;
  }

  return dataFormated(data, attributes, keyAxis);
}

const dataFormated = (data, attributes, keyAxis) => {
  let length = (data) ? data.length : 0;
  let j = 0;
  let dataTmp = [];
  let nbr = Array.isArray(keyAxis) ? keyAxis.length : keyAxis;
  for (let index = 1; index <= nbr; index++) {
    let date = 0;
    let obj = {};
    if (nbr == keyAxis) {
      obj.date = index;
      date = (j < length) ? (new Date(data[j]['date'])).getDate() : date;
    }
    else {
      obj.date = keyAxis[index - 1].substr(0, 3);
      date = (j < length) ? data[j]['date'] : date;
    }
    if (j < length && index == date) {
      if (attributes.length == 2)
        attributes[0].forEach(attribute => {
          obj[attribute] = (data[j][attributes[1]] && data[j][attributes[1]] != 0) ? parseFloat(data[j][attribute]) / parseFloat(data[j][attributes[1]]) * 100 : 0;
        });
      else
        attributes[0].forEach(attribute => { obj[attribute] = (data[j][attribute])?parseFloat(data[j][attribute]):0; });
      j++;
    }
    else
      attributes[0].forEach(attribute => {
        obj[attribute] = 0;
      });
    /*let lent = dataTmp.length; 
    attributes[0].forEach(attribute => {
      if (lent !== 0 && obj[attribute] < dataTmp[dataTmp.length-1][attribute]){
        obj['Evolution_'+attribute] = '-';
        continue;
      }    
      obj['Evolution_'+attribute] = '+';
    });*/
    dataTmp.push(obj);
  }
  return dataTmp;
}

export const formatDataPercentage = (stats = {}, total) => {
  let percentages = [];
  const labels = Object.values(stats.labels);
  const data = stats.data;
  for (let i = 0; i < data.length; i++) {
    percentages.push({
      name: _(labels[i]),
      value: data[i] ? parseFloat((data[i] / total * 100).toFixed(2)) : 0
    })
  }
  return percentages;
};







/*
export const formaterData = (data, date, champ) => {
  let year = (data && data[0]) ? (new Date(data[0][date]).getFullYear()) : 0;
  //debut de line de moyenne mobile
  let minDate = moment(year+"-01-15").valueOf();
  //fin de line de moyenne mobile
  let maxDate = moment(year+"-12-15").valueOf();
  let lent = (data && data.length)? data.length : 0;

  /*

  * calcul de moyenne mobile
  * la somme de tous les campaigne dans la période date actuel + 15 jours et moins 15jours
  * et on les divise sur le ombres de ces campaignes
  */
/*for (let i = 0; i < lent; i++) {
  data[i][champ] = parseInt(data[i][champ]);
  let currentDate = moment(data[i][date]).valueOf();
  if(currentDate >= minDate && currentDate <= maxDate){
    let tmp=1000*60*60*24*15;
    let j=1;
    //prends les date de data j-j et i+j
    let tmpDateInf = (data[i-j])? moment(data[i-j][date]).valueOf(): currentDate - tmp;
    let tmpDateSup = (data[i+j])? moment(data[i+j][date]).valueOf(): currentDate + tmp;
    let somme = data[i][champ];
    let count =1;
    //while la date de data i-j superieur à la date de 15 jours avant la date de i ou
    //la date de data i+j inférieur à la date de 15 jours aprés la date de i
    while(tmpDateInf > (currentDate - tmp) || tmpDateSup < (currentDate + tmp)){
      tmpDateInf = (data[i-j])? moment(data[i-j][date]).valueOf(): currentDate - tmp;
      tmpDateSup = (data[i+j])? moment(data[i+j][date]).valueOf(): currentDate + tmp;
      if(tmpDateInf > (currentDate - tmp )){
        somme += parseInt(data[i-j][champ]);
        count++;
      }
      if(tmpDateSup < (currentDate + tmp) && (data[i+j][champ])){
        somme += parseInt(data[i+j][champ]);
        count++;
      }
      j++;
    }
    data[i].moyenne_mobile = Math.round(somme/count);
  }
}
return data;
};
*/