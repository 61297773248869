import axios from "../axios-api";

import { throwCatchedError, updateSourceToken } from "utils";

let getApprovalTeamsSourceToken;

export const persistApprovalResponse = (token, data) => {
  if (["deny", "accept"].indexOf(data.decision) === -1) {
    return;
  }
  const requestUrl = `/mailing/approval/${data.decision}`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  if ("deny" === data.decision) {
    formData.append("reason", data.reason);
    if (data.attachment && data.attachment instanceof File) {
      formData.append("attachment", data.attachment, data.attachment.name);
    }
  }
  return axios.post(requestUrl, formData);
};

export const getApprovalFeedback = (token, data) => {
  const requestUrl = `/mailing/approval`;
  let fields = ["*"];

  let filter = [];
  if (data.campaignId) {
    filter.push({
      property: "campaign",
      value: data.campaignId,
      operator: "eq",
    });
  }

  if (data.version) {
    filter.push({
      property: "version",
      value: data.version,
      operator: "eq",
    });
  }

  if (data.id) {
    filter.push({
      property: "id",
      value: data.id,
      operator: "eq",
    });
    fields.push("campaign");
  } else {
    fields.push("teams");
    fields.push("isCampaignApproved");
  }

  let sort = [
    {
      property: "version",
      dir: "desc",
    },
  ];
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      nolimit: 1,
      campaignId: data.campaignId,
    },
  });
};

export const persistTeam = (token, data) => {
  const requestUrl = `/mailing/approval-team`;

  var formData = new FormData();
  if (data.id) {
    formData.append("id", data.id);
  }
  formData.append("client", data.clientId);
  formData.append("name", data.name);
  formData.append("description", data.description);
  formData.append("access_token", token);
  if (data.minApprovers) {
    formData.append("minApprovers", parseInt(data.minApprovers));
  }
  if (data.membersEmailsIds) {
    formData.append("membersEmailsIds", JSON.stringify(data.membersEmailsIds));
  }

  return axios.post(requestUrl, formData);
};

export const getClientApprovalTeams = (
  token,
  start,
  pageSize,
  clientId,
  sourceToken = null,
) => {
  getApprovalTeamsSourceToken = updateSourceToken(
    getApprovalTeamsSourceToken,
    sourceToken,
  );
  const fields = ["*"];
  const requestUrl = `/mailing/approval-team`;

  let filter = [
    {
      property: "client",
      value: clientId,
      operator: "eq",
    },
  ];

  let sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];

  let params = {
    access_token: token,
    fields: fields.join(","),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
  };

  if (pageSize) {
    params = { ...params, limit: pageSize, start };
  } else {
    params.nolimit = 1;
  }

  return axios
    .get(requestUrl, {
      cancelToken: getApprovalTeamsSourceToken.token,
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const deleteTeam = (token, teamId) => {
  const requestUrl = `/mailing/approval-team/${teamId}`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const updateDefaultTeam = (token, teamId) => {
  const requestUrl = `/mailing/approval-team/update-is-default`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", teamId);

  return axios.post(requestUrl, formData);
};
