import React, {Component} from 'react';
import _ from 'i18n';

class LinkFilters extends Component {

  render() {

    const {selected, onClick, data, className} = this.props;

    return (
      <div className={`settings-filter__status ${className}`}>

        {data.map(
          ({key, value}) =>
            <span key={key}
                  onClick={() => onClick(value)}
                  className={`${selected === value ? "active" : ""}`}>
                    {_(key)}
                  </span>)

        }
      </div>
    )
  }
}

export default LinkFilters;