import React from "react";
import { SVG_ALERT_TRIANGLE_ICON } from "../../../../services/svgIcones";

export const WarningBar = props => {
  const { message, description, plan } = props;
  return (
    <div
      className={`grid-x align-middle
        ${"warning"}`}
    >
      <div
        className={`col small-11
        ${"message"}`}
      >
        <div className={`m-r-s ${"content"}`}>
          {SVG_ALERT_TRIANGLE_ICON}
          <div className="message-content">
            <span>
              {message} {plan}
            </span>
            <span>{description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
