import React, { PureComponent } from "react";
import { connect } from "react-redux";

import _ from "i18n";
import { setCurrentEmailCampaignData } from "actions";
import TTPSelect from "common/TTPSelect";
import { setRecipientsListFilterName } from "../../../../../actions/actions/campaign";
import { fetchCupboards, fetchExternalType, fetchProfession } from "thunks";

@connect((store) => {
  return {
    cupboards: store.filters.cupboards.items,
    recipientType: store.currentCampaign.email.recipientType,
    uaFilters: store.currentCampaign.email.uaFilters,
    fetching: store.filters.cupboards.fetching,
    fetched: store.filters.cupboards.fetched,
    externalTypes: store.filters.externalTypes.items,
    fetchingExternalTypes: store.filters.externalTypes.fetching,
    fetchedExternalTypes: store.filters.externalTypes.fetched,
    professions: store.filters.professions.items,
    fetchingProfessions: store.filters.professions.fetching,
    fetchedProfessions: store.filters.professions.fetched,
  };
})
export default class ClientFilters extends PureComponent {
  /*  componentDidUpdate(prevProps, prevState) {
      const { dispatch, recipientType } = this.props;
      if (
        prevProps.recipientType !== recipientType &&
        (recipientType === "CONTACT" || recipientType === "CONTACT_CLIENT")
      ) {
        dispatch(fetchGroups("CONTACT"));
      }
    }*/

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchCupboards());
    dispatch(fetchExternalType());
    dispatch(fetchProfession());
  }

  handleChangeCupBoards = (value) => {
    const { dispatch, recipientType, uaFilters } = this.props;
    dispatch(setRecipientsListFilterName(""));

    let updatedFilterSelection;

    const existingFilterObjectIndex = uaFilters.findIndex(
      (filter) => filter.type === recipientType,
    );

    if (existingFilterObjectIndex !== -1) {
      updatedFilterSelection = [...uaFilters];

      updatedFilterSelection[existingFilterObjectIndex].cupboard = value;
    } else {
      const newFilterObject = {
        type: recipientType,
        cupboard: value,
        allowedGroups: [],
        deniedGroups: [],
      };
      updatedFilterSelection = [...uaFilters, newFilterObject];
    }
    dispatch(
      setCurrentEmailCampaignData({
        uaFilters: updatedFilterSelection,
      }),
    );
    this.props.handleSetIsFilterChanged();
  };
  handleChangeExternalTypes = (value) => {
    const { dispatch, recipientType, uaFilters } = this.props;
    dispatch(setRecipientsListFilterName(""));

    let updatedFilterSelection;

    const existingFilterObjectIndex = uaFilters.findIndex(
      (filter) => filter.type === recipientType,
    );

    if (existingFilterObjectIndex !== -1) {
      updatedFilterSelection = [...uaFilters];

      updatedFilterSelection[existingFilterObjectIndex].externalType = value;
    } else {
      const newFilterObject = {
        type: recipientType,
        externalType: value,
        allowedGroups: [],
        deniedGroups: [],
      };
      updatedFilterSelection = [...uaFilters, newFilterObject];
    }
    dispatch(
      setCurrentEmailCampaignData({
        uaFilters: updatedFilterSelection,
      }),
    );
    this.props.handleSetIsFilterChanged();
  };
  handleChangeProfessions = (value) => {
    const { dispatch, recipientType, uaFilters } = this.props;
    dispatch(setRecipientsListFilterName(""));

    let updatedFilterSelection;

    const existingFilterObjectIndex = uaFilters.findIndex(
      (filter) => filter.type === recipientType,
    );

    if (existingFilterObjectIndex !== -1) {
      updatedFilterSelection = [...uaFilters];

      updatedFilterSelection[existingFilterObjectIndex].profession = value;
    } else {
      const newFilterObject = {
        type: recipientType,
        profession: value,
        allowedGroups: [],
        deniedGroups: [],
      };
      updatedFilterSelection = [...uaFilters, newFilterObject];
    }
    dispatch(
      setCurrentEmailCampaignData({
        uaFilters: updatedFilterSelection,
      }),
    );
    this.props.handleSetIsFilterChanged();
  };

  render() {
    const {
      cupboards,
      uaFilters,
      recipientType,
      fetching,
      fetched,
      externalTypes,
      professions,
    } = this.props;
    const recipientTypeObject = uaFilters.find(
      (selection) => selection.type === recipientType,
    );
    const profession = recipientTypeObject
      ? recipientTypeObject.profession
      : [];
    const cupboard = recipientTypeObject ? recipientTypeObject.cupboard : [];
    const externalType = recipientTypeObject
      ? recipientTypeObject.externalType
      : [];

    const cupboardsAsObjects =
      cupboards
        ?.filter((value) => value !== "")
        .map((value, index) => {
          return { id: index + 1, label: value };
        }) ?? [];

    const externalTypesAsObjects =
      externalTypes
        ?.filter((value) => value !== "")
        .map((value, index) => {
          return { id: index + 1, label: value };
        }) ?? [];
    const professionsAsObjects =
      professions
        ?.filter((value) => value !== "")
        .map((value, index) => {
          return { id: index + 1, label: value };
        }) ?? [];
    const cupboardsIds = Array.isArray(cupboard)
      ? cupboard.map((label) => {
          const cupboard = cupboardsAsObjects.find((p) => p.label === label);
          return cupboard ? String(cupboard.id) : null;
        })
      : [];

    const externalTypeIds = Array.isArray(externalType)
      ? externalType.map((label) => {
          const externalType = externalTypesAsObjects.find(
            (p) => p.label === label,
          );
          return externalType ? String(externalType.id) : null;
        })
      : [];
    const professionIds = Array.isArray(profession)
      ? profession.map((label) => {
          const profession = professionsAsObjects.find(
            (p) => p.label === label,
          );
          return profession ? String(profession.id) : null;
        })
      : [];

    return (
      <div className="row">
        {fetching && !fetched ? (
          <div>Loading...</div>
        ) : (
          <>
            {cupboardsAsObjects && cupboardsAsObjects.length > 0 && (
              <div className="columns small-6 columns-padding">
                <span className="tab-label small-10">{_("cupboard")}</span>
                <div className="small-12 m-r-xs">
                  <TTPSelect
                    values={cupboardsIds}
                    placeholder={_("selectGroups")}
                    options={cupboardsAsObjects}
                    onChange={(selectedOption) => {
                      const selectedLabels = selectedOption.map(
                        (id) =>
                          cupboardsAsObjects.find(
                            (cupboard) => cupboard.id === parseInt(id),
                          )?.label,
                      );
                      this.handleChangeCupBoards(selectedLabels);
                    }}
                  />
                </div>
              </div>
            )}
            {externalTypesAsObjects && externalTypesAsObjects.length > 0 && (
              <div className="columns small-6 columns-padding">
                <span className="tab-label small-10">{_("groups")}</span>
                <div className="small-12">
                  <TTPSelect
                    values={externalTypeIds}
                    placeholder={_("excludeGroups")}
                    options={externalTypesAsObjects}
                    onChange={(selectedOption) => {
                      const selectedLabels = selectedOption.map(
                        (id) =>
                          externalTypesAsObjects.find(
                            (externalType) => externalType.id === parseInt(id),
                          )?.label,
                      );
                      this.handleChangeExternalTypes(selectedLabels);
                    }}
                  />
                </div>
              </div>
            )}
            {professionsAsObjects && professionsAsObjects.length > 0 && (
              <div className="columns small-6 columns-padding">
                <span className="tab-label small-10">{_("profession")}</span>
                <div className="small-12">
                  <TTPSelect
                    values={professionIds}
                    placeholder={_("excludeGroups")}
                    options={professionsAsObjects}
                    onChange={(selectedProfessionLabel) => {
                      const selectedLabels = selectedProfessionLabel.map(
                        (id) =>
                          professionsAsObjects.find(
                            (profession) => profession.id === parseInt(id),
                          )?.label,
                      );
                      this.handleChangeProfessions(selectedLabels);
                    }}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
