import React, { Component } from 'react';
import { connect } from 'react-redux';

import SMSCreditRequests from './SMSCreditRequests';
import { SMS_CREDIT_REQUESTS_STATUS } from 'Common';
import _ from 'i18n';
import { setCurrentNavPage, setSMSBillsPaginationPage, setSMSBillsPageSize, setSmsBillsListFilterStatus } from 'actions';
import { fetchSmsBills } from 'thunks';
import TTPPaginator from 'common/list/TTPPaginator';
import PageHeader from "../../common/PageHeader";
import TTPFilterHorizontalBar from "../../common/filter/TTPFilterHorizontalBar";

@connect((store) => {
    return {
        smsCreditBills: store.smsBills.list,
    };
})
export default class SMSCreditValidationPage extends Component {

    constructor(props) {
        super(props);
        this.statusOptions = SMS_CREDIT_REQUESTS_STATUS.map(status => {
            return { label: _(status), value: status }
        })
    }

    componentWillMount() {
        const { dispatch } = this.props;
        dispatch(setCurrentNavPage('SMS_CREDIT_VALIDATION'));
        dispatch(setSmsBillsListFilterStatus("PENDING"));
        this.renderSmsCreditBillsDatatable(1, 10);
    }

    handleSmsCreditBillsListPageClick(data) {
        let selectedPage = data.selected;
        this.renderSmsCreditBillsDatatable(selectedPage + 1);
    }

    handleSelectSmsCreditBillsListPageSize(event) {
        let pageSize = event.target.value;
        this.renderSmsCreditBillsDatatable(1, pageSize);
    }

    renderSmsCreditBillsDatatable(paginationPage, pageSize = null) {
        const { dispatch } = this.props;
        dispatch(setSMSBillsPaginationPage(paginationPage));
        if (pageSize) {
            dispatch(setSMSBillsPageSize(pageSize));
        }
        dispatch(fetchSmsBills());
    };

    handleSelectRequestStatus(event) {
        const { dispatch } = this.props;
        dispatch(setSmsBillsListFilterStatus(event));
        this.renderSmsCreditBillsDatatable(1);
    };

    render() {
        const { smsCreditBills } = this.props;

        return (
            <div id="sms-credit-validation-page" className="page">
                <PageHeader header="sms credit validation" subHeader="validate users sms credit requests."
                    icon="FINANCES_SMS">
                </PageHeader>

                <div className="row align-center">
                    <TTPFilterHorizontalBar
                        filters={[
                            { placeholder: _("filter by status"), options: this.statusOptions, values: smsCreditBills.status, action: this.handleSelectRequestStatus.bind(this), isSimple: true },
                        ]}
                        pageSize={smsCreditBills.pageSize}
                        handleListPageSizeChange={this.handleSelectSmsCreditBillsListPageSize.bind(this)}
                        cssClass="medium-12 columns"
                    />
                </div>
                {(smsCreditBills.items.length > 0) && <SMSCreditRequests items={smsCreditBills.items} />}
                {(0 === smsCreditBills.items.length) &&
                    <div className="row align-center">
                        <div className="small-12 columns nothing-found">
                            <div className="nothing-found__table-cell">
                                <i className="icon-compass"/>
                            </div>
                            <div className="nothing-found__table-cell">
                                <h1 className="nothing-found__title">{_('Nothing Found')}</h1>
                                <p>{_('empty_sms_credit_purchase_list')} </p>
                            </div>
                        </div>
                    </div>
                }
                {(smsCreditBills.items.length > 0) &&
                    <TTPPaginator onPageChange={this.handleSmsCreditBillsListPageClick.bind(this)}
                        pageSize={smsCreditBills.pageSize}
                        nbResult={smsCreditBills.nbResult}
                        paginationPage={smsCreditBills.paginationPage} />
                }
            </div>
        )
    }
}
