import React, { useState } from "react";
import _ from 'i18n';
import { EBOX_TARGET_TYPE } from "../../../../services/ebox/widget";

const TargetType = (props) => {

  const [menuVisible, setMenuVisible] = useState(false);
  const { value } = props;

  const selectedType = EBOX_TARGET_TYPE[value || 'EMAIL'];

  const handleTargetTypeChange = (type) => {
    console.log("===", type);
    props.onTargetTypeChange(type);
    setMenuVisible(false);
  }

  if (!selectedType) {
    return null;
  }

  return (
    <div className="target-type__menu">
      <div>
        {selectedType.icon}
        <span className="title"> {_(selectedType.label)}</span>
        <i
          className={`icon ${menuVisible ? "icon-arrow-up" : "icon-arrow-down"}`}
          onClick={() => setMenuVisible(!menuVisible)}>
        </i>
      </div>
      {menuVisible && <ul>
        {
          Object.keys(EBOX_TARGET_TYPE).filter(type => type != value).map(type => (
            <li key={type}>
              <div onClick={() => handleTargetTypeChange(type)}>
                {_(EBOX_TARGET_TYPE[type].icon)}
                <span className="title"> {_(EBOX_TARGET_TYPE[type].label)}</span>
              </div>
            </li>
          ))
        }
      </ul>
      }
    </div>
  );
}

export default TargetType;
