const initialStateForEboxWidgetReducer = {
  fromEmail: "",
  subject: "",
  message: "",
  fields:[],
  recipients: [],
  cc: [],
  reply: [],
  sending: false,
  status: null,
  attachments: [],
  targetFolders: [],
  targetType: 'EMAIL'
};

export const eboxWidgetReducer = (state = initialStateForEboxWidgetReducer, action) => {
  switch (action.type) {
    case "SHOW_EBOX_WIDGET": {
      return {
        ...state,
        status: 'OPENED'
      };
    }
    case "SET_EBOX_DYNAMIC_FIELDS_WIDGET": {
      return {
        ...state,
        fields: action.fields
      };
    }
    case "SET_EBOX_WIDGET_STATUS": {
      return {
        ...state,
        status: action.status
      };
    }
    case "SET_EBOX_FROM_EMAIL": {
      return {
        ...state,
        fromEmail: action.email
      };
    }
    case "SET_EBOX_SUBJECT": {
      return {
        ...state,
        subject: action.subject
      };
    }
    case "SET_EBOX_MESSAGE": {
      return {
        ...state,
        message: action.message
      };
    }

    case "SET_EBOX_RECIPIENTS": {
      return {
        ...state,
        recipients: action.recipients
      };
    }

    case "SET_EBOX_CC": {
      return {
        ...state,
        cc: action.cc
      };
    }

    case "SET_EBOX_REPLY": {
      return {
        ...state,
        reply: action.reply
      };
    }

    case "SET_EBOX_WIDGET_TARGET_TYPE": {
      const { targetType } = action;

      return {
        ...state,
        targetType,
        targetFolders: [],
        recipients: []
      };
    }

    case "SET_EBOX_WIDGET_TARGET_FOLDERS": {
      return {
        ...state,
        targetFolders: action.targetFolders
      };
    }

    case "SET_EBOX_WIDGET_ATTACHMENTS": {
      return {
        ...state,
        attachments: action.attachments
      };
    }

    case "SEND_TRANSACTIONAL_EMAIL_PENDING": {
      return {
        ...state,
        status: "MINIMIZED",
        sending: true
      };
    }
    case "SEND_TRANSACTIONAL_EMAIL_FULFILLED":
    case "HIDE_EBOX_WIDGET": {
      return initialStateForEboxWidgetReducer;
    }
    case "SEND_TRANSACTIONAL_EMAIL_REJECTED": {
      return {
        ...state,
        status: "OPENED",
        sending: false
      };
    }
    default:
      return state;
  }
};
