import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserNameForAvatar,ucFirst } from 'utils';
import Controls from 'common/Controls';
import { updateUsersSettings } from 'thunks';
import { initSaveUsers, setRole, setAllowCreateCampaign, setAllowSendCampaign, setAllowCreateSms, setAllowSendSms } from 'actions';
import Switch from './../common/Switch';
import MultiSwitch from './../common/MultiSwitch';

import _ from 'i18n';

@connect((store) => {
  return {
    selectedIds: store.settings.selectedUsers,
    users: store.users.items,
    fetched: store.users.fetched,
    updating: store.users.updating,
    updated: store.users.updated,
    role: store.settings.role,
    allowCreateCampaign: store.settings.allowCreateCampaign,
    allowSendCampaign: store.settings.allowSendCampaign,
    allowCreateSms: store.settings.allowCreateSms,
    allowSendSms: store.settings.allowSendSms
  }
})

export default class Sidebar extends Component {

  componentDidUpdate(prevProps, prevState) {
    const { selectedIds, users, dispatch } = this.props;
    const selectedUsers = users.filter(user => selectedIds.indexOf(user.id) > -1);
    if (!!selectedUsers && selectedUsers.length != 0 && prevProps.selectedIds != selectedIds) {
      const authorisations = selectedUsers[0].emailingAuthorisation;
      const withoutPreferences = selectedUsers.length > 1 || !authorisations || !authorisations.preferences;
      const selectedRole = selectedUsers.length > 1 || authorisations.role ? authorisations.role : "NONE";
      const allowCreation = withoutPreferences || authorisations.preferences.allowCreateCampaign == 0 ? false : true;
      const allowSending = withoutPreferences || authorisations.preferences.allowSendCampaign == 0 ? false : true;
      const allowSmsCreation = withoutPreferences || authorisations.preferences.allowCreateSms == 0 ? false : true;
      const allowSmsSending = withoutPreferences || authorisations.preferences.allowSendSms == 0 ? false : true;
      dispatch(setAllowCreateCampaign(allowCreation));
      dispatch(setAllowSendCampaign(allowSending));
      dispatch(setAllowCreateSms(allowSmsCreation));
      dispatch(setAllowSendSms(allowSmsSending));
      dispatch(setRole(selectedRole));
    }
  }

  deselectUsers() {
    const {handleSelectDeselectSvg, handleSettingsTab} = this.props;

    const selectAllIcon = document.getElementsByClassName('bubble__select-all').item(0);
    const usersAvatar = document.getElementsByClassName('employee');
    for (let i = 0; i < usersAvatar.length; i++) {
      usersAvatar[i].classList.remove('selected');
      usersAvatar[i].getElementsByClassName('icon').item(0).classList.remove('active');
    }
    selectAllIcon.classList.remove('deselect');
    selectAllIcon.getElementsByTagName('span').item(0).textContent = _('Select All');
    handleSelectDeselectSvg("select");
    handleSettingsTab(false);
  }

  init() {
    this.props.dispatch(initSaveUsers());
    const clDiv = document.getElementsByClassName("mask").item(0);
    clDiv.click();
    this.deselectUsers();
  }

  saveUsersSettings() {
    this.props.dispatch(updateUsersSettings());
  }

  renderSingleUser(user) {

    const { firstName, lastName, avatarUrl, role, emailingAuthorisation } = user;

    let avatarDiv = null;
    if (avatarUrl != undefined) {
      avatarDiv = (
        <div className="user-avatar" style={{ backgroundImage: `url(${avatarUrl})` }}></div>
      );
    } else {
      avatarDiv = (
        <div className="user-avatar empty-avatar">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    }

    let signatureDiv = [];
    signatureDiv.push(<h3 key="sh3">{firstName + ' ' + lastName}</h3>);
    if (role && role.id != undefined) {
      signatureDiv.push(<h4 key="sh4">{_(role.type)}</h4>);
    }
    signatureDiv.push(<h5 key="h5">
      {(emailingAuthorisation && emailingAuthorisation.role != undefined) ? emailingAuthorisation.role : "____"}
    </h5>);
    return (
      <div className="user sidebar__user">
        {avatarDiv}
        <div className="user-header">
          {signatureDiv}
        </div>
      </div>
    );
  }

  renderGroupUsers(users) {

    let avatarsDiv = [];
    let names = [];

    for (let i = 0; i < users.length; i++) {
      if (i >= 3) {
        break;
      }
      let { id, firstName, lastName, avatarUrl } = users[i];

      names.push(users.length <= 3 ? firstName + " " + lastName : firstName);
      if (avatarUrl) {
        avatarsDiv.push(<div key={`user-${id}`} className="user-avatar" style={{ backgroundImage: `url(${avatarUrl})` }}></div>)
      } else {
        avatarsDiv.push((
          <div key={`user-${id}`} className="user-avatar empty-avatar">
            <span>{getUserNameForAvatar(firstName, lastName)}</span>
          </div>
        ));
      }
    }
    let other = 0;
    if (users.length > 3) {
      other = users.length - 3;
    }
    return (
      <div className="side__bar-group">
        <div className="side__bar-group-avatar">
          {avatarsDiv}
        </div>
        <div className="side__bar-group-names">
          {names.join((users.length <= 3) ? _(' and ') : ', ')}
          {(other > 0) ? <span> {_(' and ')} <strong>{other} {_('other user(s)')}.</strong></span> : null}
        </div>
      </div>
    );

  }

  renderUsers() {
    const { users, selectedIds } = this.props;
    const selectedUsers = users.filter(user => selectedIds.indexOf(user.id) > -1);

    if (!selectedUsers || selectedUsers.length == 0) {
      return null;
    }

    if (selectedUsers.length == 1) {
      return this.renderSingleUser(selectedUsers[0]);
    } else {
      return this.renderGroupUsers(selectedUsers);
    }
  }

  setMandatedValue(val) {
    this.props.dispatch(setIsMandated(val));
  }

  setRoleValue(val) {
    this.props.dispatch(setRole(val));
  }

  setAllowCreateCampaign(allowCreation) {
    this.props.dispatch(setAllowCreateCampaign(allowCreation));
  }

  setAllowSendCampaign(allowSending) {
    this.props.dispatch(setAllowSendCampaign(allowSending));
  }

  setAllowCreateSms(allowSmsCreation) {
    this.props.dispatch(setAllowCreateSms(allowSmsCreation));
  }

  setAllowSendSms(allowSmsSending) {
    this.props.dispatch(setAllowSendSms(allowSmsSending));
  }

  renderPrivilegesBlock() {
    const { role, allowCreateCampaign, allowSendCampaign, allowCreateSms, allowSendSms } = this.props;
    if (role == "ADMIN" || role =="NONE") {
      return null;
    }
    return <div>
      <div className="separator">{_('emailing privileges')}</div>
      <div className="preferences-switch">
        <div className="switch">
          <span className="sidebar_role">{ucFirst(_('allow create campaign'))}</span>
          <Switch name="mailingCreate" keyAttr="mailing-create" isChecked={allowCreateCampaign} onChange={(val) => this.setAllowCreateCampaign(val)} />
        </div>
        <div className="switch">
          <span className="sidebar_role">{ucFirst(_('allow send campaign'))}</span>
          <Switch name="mailingSend" keyAttr="mailing-send" isChecked={allowSendCampaign} onChange={(val) => this.setAllowSendCampaign(val)} />
        </div>
      </div>

      <div className="separator">{_('SMS privileges')}</div>
      <div className="preferences-switch">
        <div className="switch">
          <span className="sidebar_role">{ucFirst(_('allow create campaign'))}</span>
          <Switch name="smsCreate" keyAttr="sms-create" isChecked={allowCreateSms} onChange={(val) => this.setAllowCreateSms(val)} />
        </div>
        <div className="switch">
          <span className="sidebar_role">{ucFirst(_('allow send campaign'))}</span>
          <Switch name="smsSend" keyAttr="sms-send" isChecked={allowSendSms} onChange={(val) => this.setAllowSendSms(val)} />
        </div>
      </div>
    </div>
  }

  render() {
    const { fetched, updating, updated, role } = this.props;
    if (!fetched) {
      return null;
    }
    return (
      <div>
        <div id="settings__sidebar">
          {this.renderUsers()}
          <div className="separator">{_('role')}</div>
          <div className="switches">
            <MultiSwitch labels={[_('NONE'), _('ADMIN'), _('USER')]} selectedValue={role}
                         vals={['NONE', 'ADMIN', 'USER']} name="emailingRole"
                         onChange={(val) => this.setRoleValue(val)}
                         customClass="sidebar_role"/>
          </div>
          {this.renderPrivilegesBlock()}
        </div>
        <Controls ok="Apply" cancel="Cancel" updated={updated}
                  updating={updating} saveCallback={this.init.bind(this)}
                  label={_('Apply authorization to selected users')}
                  closeDiv="mask" closeDivType="class"
                  action={() => this.saveUsersSettings()}
                  onCancel={this.init.bind(this)}
        />
      </div>
    );
  }
};
