import React from "react";
import { components } from 'react-select';
import { stringToColor } from 'utils';

import _ from 'i18n';

export const TeamOption = props => {
  const { data: { label, members, minApprovers} } = props;

  return <components.Option {...props} >
   <div className="approver-team">
      <div className="approver-team__avatar">
        <span className="team-avatar" style={{ background: stringToColor(label) }}>{label.substring(0, 1)}</span>
      </div>
      <div className="approver-team__detail">
          <span>{label}</span>
          <span>{_('number of approvers')}: {(members)?members.length:0} </span>
          <span>{_('the approval threshold')}: {minApprovers} </span>
      </div>
    </div>
  </components.Option>
};
