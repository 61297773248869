import React, { Component } from "react";
import { connect } from "react-redux";

import NewsletterUAPage from "./NewsletterUAPage";
import AppLoader from "../common/loader/AppLoader";

const mapStateToProps = (state) => ({
  isUaVersion: state.campaigns.recipients.isUaVersion,
});

@connect(mapStateToProps)
export default class NewsletterHomePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { isUaVersion } = this.props;

    return (
      <>
        {isUaVersion ? (
          <NewsletterUAPage />
        ) : (
          <>
            <AppLoader />
          </>
        )}
      </>
    );
  }
}
