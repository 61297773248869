import React, { Component } from 'react';
import {connect} from "react-redux";

@connect((store) => {
  return {
    isFromCampaignStats: store.campaigns.stats.campaign.data.isFromCampaignStats,
  }
})
class LinkItem extends Component {
  state = {
    showLabel: false
  }

  slideLeft(e) {
    if (this.isOverflown(e.target)) {
      e.target.classList.add("slide-left");
    }
  }

  isOverflown(e) {
    return e.parentElement.clientWidth < e.clientWidth + 70;
  }

  stopSlide(e) {
    e.target.classList.remove("slide-left");
  }
  changeToLabel() {
    const { label } = this.props;
    if (label && label.trim().length > 0) {
      this.setState({ showLabel: true })
    }
  }

  changeToUrl() {
    this.setState({ showLabel: false })
  }

  render() {
    const { url, cssClass, link, count, label, onEditClick, onLinkClick, isFromCampaignStats } = this.props;
    const { showLabel } = this.state;

    return (
      <li>
        <div className="stat-link-container" onClick={onLinkClick}>
          <a href={url || "#"} className={`stat-link ${cssClass || ""}`} style={isFromCampaignStats ? { cursor: 'default' } : {}} onMouseOver={this.changeToLabel.bind(this)} onMouseOut={this.changeToUrl.bind(this)}>
            <span className="marquee"
              onMouseOver={this.slideLeft.bind(this)}
              onMouseOut={this.stopSlide.bind(this)}>
              {showLabel ? label : link}
            </span>
          </a>
          <div className="badge">
            <span className="counter">
              {count}
            </span>
            <span className="editor" onClick={onEditClick}>
              <i className="icon-pencil" />
            </span>
          </div>
        </div>
      </li>
    )
  }
}

export default LinkItem;
