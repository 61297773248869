export * from "./NavPages";
export * from "./TTPDialog";
export * from "./types";

export const VERTICAL = "VERTICAL";
export const HORIZONTAL = "HORIZONTAL";

export const RESIZE_SPEAKER = "/w200-h200.jpg";
export const RESIZE_ARTICLE_COVER = "/w800.jpg";
export const RESIZE_TRAINING_COVER = "/w800.jpg";

export const MODERATE_MODE = "MODERATE";
export const RESTRICTED_MODE = "RESTRICTED";
export const ADMIN_MODE = "ADMINISTRATION";

export const SCOPE_TABS = [
  {
    id: "COMMUNITY",
    label: "library"
  },
  {
    id: "TAMTAM",
    label: "my models"
  }
];

export const ACCEPT_MIME_TYPES = [
  "image/*",
  "application/pdf",
  "text/plain",
  "application/vnd.ms-excel",
  "application/msword",
  "application/vnd.ms-powerpoint",
  ".csv",
  ".dotx",
  ".docx",
  ".xlsx",
  ".xltx",
  ".xlsb",
  ".potx",
  ".pptx"
];

export const CAMPAIGN_UA_STEPS = [
  {
    reference: "PARAMS",
    label: "configuration"
  },
  {
    reference: "RECIPIENTS",
    label: "recipients"
  },
  {
    reference: "TEST",
    label: "test"
  },
  {
    reference: "SEND",
    label: "send"
  }
];
export const CAMPAIGN_UA_NL_PERSO_STEPS = [
  {
    reference: "PERIODS",
    label: "periods"
  },
  {
    reference: "PARAMS",
    label: "configuration"
  },
  {
    reference: "RECIPIENTS",
    label: "recipients"
  },
  {
    reference: "TEST",
    label: "test"
  },
  {
    reference: "SEND",
    label: "send"
  }
];
