import React, {Component} from 'react';
import _ from 'i18n';


export default class CampaignSMSCountWarning extends Component {
  render() {
    let {encoding} = this.props;
    let isUtf16 = encoding === 'UTF16';
    let isGsm7BitEx = encoding === 'GSM_7BIT_EX';
    return (
      <div className="campaign-sms-warning">
        <div className="row align-center">
          <div className="columns small-12 columns-padding">
            <div className="callout radius small-12 alert">
              <p className="lead"><i className="icon icon-info"></i>&nbsp;&nbsp;{_('sms_warning_lead')}
              </p>
              <p><strong>{_('sms_warning_length_disclaimer')}</strong></p>
              <p>{_('sms_warning_explanation')}</p>
              <p>{_('sms_waring_more_precisely')},
                <br/>
                <ul>
                  <li>
                    {isUtf16 ?
                      <dl><strong>{_('sms_warning_utf16_guideline')}</strong>
                      </dl> : ''}
                    {isGsm7BitEx ? <dl>
                      <dd><strong>{_('sms_warning_gsm_7bit_guideline')}</strong>
                      </dd>
                      <dt>{_('sms_warning_gsm_7bit_ext_list')}</dt>
                    </dl> : ''}
                  </li>
                </ul>
              </p>
              <p>{_('sms_warning_advice')}</p>
              <hr/>
              <i className="icon icon-pin"></i>&nbsp;&nbsp;
              <strong>{_('sms_warning_counter_disclaimer')}</strong>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
