// Notifications reducer
const initialStateForWebNotifications = {
    fetching: false,
    fetched: false,
    items: [],
    error: null,
    updated: false,
    updating: false,
    nbResult: 0,
}

export const webNotificationsReducer = (state = initialStateForWebNotifications, action) => {
    switch (action.type) {
        case 'FETCH_WEB_NOTIFICATIONS_PENDING': {
            return { ...state, fetched: false, fetching: true };
            break;
        }
        case 'FETCH_WEB_NOTIFICATIONS_FULFILLED': {
            const { data, nbResult } = action.payload.data
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                items: data,
                nbResult: nbResult
            };
            break;
        }
        case 'FETCH_WEB_NOTIFICATIONS_REJECTED': {
            let error = action.payload;
            switch (error && error.response && error.response.status) {
                case 404:
                    error = {
                        title: error.response.data.title,
                        code: 404
                    }
                    break;
                default:
            }
            return {
                ...state,
                fetching: false,
                items: [],
                error
            };
            break;
        }
        case 'READ_NOTIFICATION_PENDING': {
            return { ...state, updated: false, updating: true };
            break;
        }
        case 'READ_NOTIFICATION_FULFILLED': {
            const { payload } = action;
            let updatedItems = state.items.map(item => {
                if (item.id === payload.id) {
                    return { ...item, status: "READ" };
                }
                return item;
            })
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                updated: true,
                updating: false,
                items: updatedItems
            };
            break;
        }
        default:
            return state;
    }
};

const initialStateForList = {
    fetching: false,
    fetched: false,
    items: [],
    error: null,
    nbResult: 0,
    filter: {
        pageSize: 10,
        paginationPage: 1,
        searchWord: ""
    }
}

const initialStateForPushNotifications = {
    ...initialStateForList,
    updated: false,
    updating: false,
    sent: false,
    sending: false,
}

export const pushNotificationsReducer = (state = initialStateForPushNotifications, action) => {
    switch (action.type) {
        case 'INIT_PUSH_NOTIFICATIONS': {
            return initialStateForPushNotifications;
        }
        case 'FETCH_PUSH_NOTIFICATIONS_PENDING': {
            return { ...state, fetched: false, fetching: true };
            break;
        }
        case 'FETCH_PUSH_NOTIFICATIONS_FULFILLED': {
            const { data, nbResult } = action.payload.data

            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                items: data,
                nbResult: nbResult
            };
            break;
        }
        case 'FETCH_PUSH_NOTIFICATIONS_REJECTED': {
            let error = action.payload;
            switch (error && error.response && error.response.status) {
                case 404:
                    error = {
                        title: error.response.data.title,
                        code: 404
                    }
                    break;
                default:
            }
            return {
                ...state,
                fetching: false,
                items: [],
                error
            };
            break;
        }
        case 'SET_PUSH_NOTIFICATIONS_PAGINATION_PAGE': {
            return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
            break;
        }
        case 'SET_PUSH_NOTIFICATIONS_PAGE_SIZE': {
            return { ...state, filter: { ...state.filter, pageSize: action.pageSize, paginationPage: 1 } };
            break;
        }

        case "CREATE_NOTIFICATION_PENDING": {
            return { ...state, fetched: false, fetching: true, updating: true, sent: false, sending: true };
            break;
        }
        case "CREATE_NOTIFICATION_FULFILLED": {
            const notification = action.payload;
            let exist = false;
            const updatedItems = state.items.map(item => {
                if (item.id === notification.id) {
                    exist = true;
                    return { ...item, ...notification };
                }
                return item;
            });
            return {
                ...state,
                fetching: false,
                fetched: true,
                updating: false,
                updated: true,
                sent: true,
                sending: false,
                error: null,
                items: exist ? updatedItems : [{ ...notification, usersNotificationsStatus: [] }],
            };
        }
        case "CREATE_NOTIFICATION_REJECTED": {
            let error = action.payload;
            switch (error && error.response && error.response.status) {
                case 404:
                    error = {
                        title: error.response.data.title,
                        code: 404
                    };
                    break;
                default:
            }
            return {
                ...state,
                fetching: false,
                updating: false,
                sending: false,
                items: [],
                error
            };
        }
        case 'DELETE_NOTIFICATION_FULFILLED': {
            const { deletedId } = action.payload.data;
            let { items } = state;
            let newItems = items.filter(item => item.id != deletedId);

            return {
                ...state,
                items: newItems,
            };
            break;
        }
        default:
            return state;
    }
};

const initalStateForCurrentNotification = {
    id: null,
    language: ['fr', 'nl'],
    appName: "EVENT",
    subjectEn: "",
    subjectFr: "",
    subjectNl: "",
    contentEn: "",
    contentFr: "",
    contentNl: "",
    filters: [],
    currentSideBar: "",
    recipients: { ...initialStateForList, recipientsTotalCount: 0, }
}

export const currentNotificationReducer = (state = initalStateForCurrentNotification, action) => {
    switch (action.type) {
        case 'SET_CURRENT_NOTIFICATION_DATA': {
            return { ...state, ...action.data };
            break;
        }
        case 'INIT_CURRENT_NOTIFICATION_DATA': {
            return { ...initalStateForCurrentNotification };
            break;
        }
        case 'SET_CURRENT_NOTIFICATION_FILTERS': {
            let { data } = action;
            let exists = false;

            let newFilters = [];
            state.filters.forEach(filter => {
                if (filter.property != data.property || filter.operator != data.operator) {
                    newFilters.push(filter);
                } else if (data.value && data.value.length > 0) {
                    newFilters.push(data);
                    exists = true;
                } else {
                    exists = true;
                }
            });

            if (!exists && data.value && data.value.length > 0) {
                newFilters.push(data);
            }

            return { ...state, filters: newFilters };
            break;
        }
        case 'FETCH_NOTIFICATION_RECIPIENTS_PENDING': {
            return { ...state, recipients: { ...state.recipients, fetched: false, fetching: true } };
            break;
        }
        case 'FETCH_NOTIFICATION_RECIPIENTS_FULFILLED': {
            const { data, nbResult } = action.payload.data
            const { filter } = state.recipients;
            const recipientsTotalCount = (filter.searchWord) ? state.recipientsTotalCount : nbResult;
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    fetching: false,
                    fetched: true,
                    error: null,
                    items: data,
                    nbResult: nbResult || 0,
                    recipientsTotalCount
                }
            };
            break;
        }
        case 'FETCH_NOTIFICATION_RECIPIENTS_REJECTED': {
            let error = action.payload;
            const { filter: { searchWord }, recipientsTotalCount } = state.recipients;
            const total = (searchWord) ? recipientsTotalCount : 0;

            switch (error && error.response && error.response.status) {
                case 404:
                    error = {
                        title: error.response.data.title,
                        code: 404
                    }
                    break;
                default:
            }
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    fetching: false,
                    items: [],
                    nbResult: 0,
                    recipientsTotalCount: total,
                    error
                }
            };
            break;
        }
        case 'SET_NOTIFICATION_RECIPIENTS_PAGINATION_PAGE': {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    filter: {
                        ...state.recipients.filter,
                        paginationPage: action.pageNumber
                    }
                }
            };
            break;
        }
        case 'SET_NOTIFICATION_RECIPIENTS_SEARCH_WORD': {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    filter: {
                        ...state.recipients.filter,
                        searchWord: action.searchWord
                    }
                }
            };
            break;
        }
        case 'SET_NOTIFICATION_RECIPIENTS_PAGE_SIZE': {
            return {
                ...state,
                recipients: {
                    ...state.recipients,
                    filter: {
                        ...state.recipients.filter,
                        pageSize: action.pageSize
                    }
                }
            };
            break;
        }
        default:
            return state;
    }
};
