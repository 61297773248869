import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isValidUrl } from 'utils';
import { NotificationManager } from 'react-notifications';
import { TTP_API_URL } from 'Config';
import _ from 'i18n';
import CustomCreatable from "../../ui/select/CustomCreatable";
import { setCurrentEmailCampaignData } from '../../../actions/actions';
import SubjectBox from '../text/SubjectBox';
import Block from '../common/Block';

@connect((store) => {
  return {
    currentClient: store.auth.currentClient,
    pageUrl: store.currentCampaign.email.pageUrl,
    type: store.currentCampaign.email.type,
  }
})
export default class CampaignWeb extends Component {

  componentWillMount() {
    let { pageUrl } = this.props;
    if (pageUrl !== "" && !isValidUrl(pageUrl)) {
      NotificationManager.error(_('invalidUrl'), _('error'));
    }
  }

  getTemplatesUrls() {
    const { pageUrl, currentClient: { emailingTemplates, emailingPreferences, ezineNl } } = this.props;
    let urls = [];
    if (ezineNl && ezineNl.length > 0) {
      urls = ezineNl.map(ezine => {
        return { value: TTP_API_URL + '/' + ezine.media_path, label: ezine.title };
      });
    }
    if (isValidUrl(pageUrl)) {
      urls.push({ value: pageUrl, label: pageUrl });
    }
    if (!emailingPreferences || !emailingPreferences['base_url']) {
      return urls;
    }
    let baseUrl = emailingPreferences['base_url'] || '';
    for (let key in emailingTemplates) {
      let url = emailingTemplates[key].replace(/^\//g, '');
      urls.push({ value: `${baseUrl.replace(/\/$/g, '')}/${url}`, label: url });
    }
    return urls;
  };

  handleUrlChange = (url) => {
    let newUrl = "";
    if (url && url.value && isValidUrl(url.value)) {
      newUrl = url.value;
    }
    this.props.dispatch(setCurrentEmailCampaignData({ pageUrl: newUrl || "" }));
  };

  render() {
    let { pageUrl, type } = this.props;
    const mailUrls = this.getTemplatesUrls();
    const isValid = isValidUrl(pageUrl);

    return (
      <div id="campaign-web" className={(type == "WEB_PAGE" && !pageUrl) ? "medium-8" : "medium-4"}>

        {type == "WEB_PAGE" && <Block title="page url" subTitle="To get started, set a URL to import." cssClass="m-b-xs">
          <div className={`${(pageUrl && !isValid) && "error"} ${pageUrl ? "filled-box" : "empty-box"}`}>
            <CustomCreatable
              value={(pageUrl && isValid) ? pageUrl : ""}
              placeholder={_('enterUrl')}
              onChange={this.handleUrlChange}
              options={mailUrls}
            />
          </div>
        </Block>}

        <Block title="subject" subTitle="give a subject to your campaign">
          <SubjectBox />
        </Block>

      </div>
    );
  }
}
