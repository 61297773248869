import React, { useState } from "react";
import UsersTagSuggestion from "../../../common/UsersTagSuggestion";
import _ from "i18n";
import { SVG_SEND_ICON } from "../../../../services/svgIcones";
import { onError, onSuccess } from "../../../../services/utils";
import { setTTPDialogCustomData } from "../../../../actions/actions/params";
import { connect } from "react-redux";
import {
  fetchCampaignBroadcasters,
  persistCampaign,
} from "../../../../actions/thunks/campaign";

@connect(
  (store) => {
    return {
      currentCampaign: store.currentCampaign.email,
      allowedGroups: store.currentCampaign.email.allowedGroups,
      deniedGroups: store.currentCampaign.email.deniedGroups,
      fromName: store.currentCampaign.email.fromName,
      from: store.currentCampaign.email.from,
    };
  },
  (dispatch) => ({
    fetchCampaignBroadcasters: (broadcastedCampaign) =>
      dispatch(fetchCampaignBroadcasters(broadcastedCampaign)),
    persistCampaign: (data) => dispatch(persistCampaign(data)),
    setTTPDialogCustomData: (data) => dispatch(setTTPDialogCustomData(data)),
  })
)
export default class EmailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleTagsChange = this.handleTagsChange.bind(this);

    this.state = {
      recipients: [],
    };
  }

  handleTagsChange = (newRecipient) => {
    this.setState({ recipients: newRecipient });
  };

  confirmSaveCampaign = async () => {
    let data = {
      campaignGroups: this.getCampaignGroups(),
    };

    data.status = "CREATED";
    data.manualRecipients = [];
    data.broadcastRecipients =
      this.state.recipients?.map((item) => {
        return item.email;
      }) ?? [];
    data.action = "SEND";
    data.id = null;
    data.updatedAt = null;
    data.createdAt = null;
    data.sentAt = null;
    data.isRebroadcasted = 1;
    data.recipientType = "REBROADCAST";
    data.rebroadcastedFrom = this.props.originalCampaignId;
    let notification = {
      body: "campaignSent",
      title: "campaignSentSuccess",
    };

    this.props.persistCampaign(data).then(
      (res) => {
        onSuccess(res, notification);
        this.setState({ recipients: [] });
        setTimeout(() => {
          this.props.fetchCampaignBroadcasters(this.props.originalCampaignId);
        }, 16000);
      },
      (err) => onError(err)
    );
  };

  afterSend = () => {
    this.props.onRecipientSent(
      this.state.recipients?.map((item) => {
        return item.email;
      }) ?? []
    );
  };

  getCampaignGroups = () => {
    let campaignGroups = [];
    let { allowedGroups, deniedGroups } = this.props;
    if (!(allowedGroups instanceof Array)) {
      allowedGroups = allowedGroups.split(",");
    }
    if (!(deniedGroups instanceof Array)) {
      deniedGroups = deniedGroups.split(",");
    }
    if (allowedGroups.length > 0) {
      for (let i = 0; i < allowedGroups.length; i++) {
        if (allowedGroups[i]) {
          campaignGroups.push({ group: allowedGroups[i], status: "INCLUDE" });
        }
      }
    }
    if (deniedGroups.length > 0) {
      for (let i = 0; i < deniedGroups.length; i++) {
        if (deniedGroups[i]) {
          campaignGroups.push({ group: deniedGroups[i], status: "EXCLUDE" });
        }
      }
    }
    return this.mapCampaignGroupIds(campaignGroups);
  };

  mapCampaignGroupIds = (campaignGroups) => {
    let oldCampaignGroups = this.props.oldCampaignGroups;
    if (!oldCampaignGroups || !oldCampaignGroups.length) {
      return campaignGroups;
    }
    oldCampaignGroups.forEach((oldCampaignGroup) => {
      for (let i = 0; i < campaignGroups.length; i++) {
        if (
          campaignGroups[i].group === oldCampaignGroup.group.id &&
          campaignGroups[i].status === oldCampaignGroup.status
        ) {
          campaignGroups[i].id = oldCampaignGroup.id;
        }
      }
    });
    return campaignGroups;
  };

  handleShowDialog = () => {
    this.props.setTTPDialogCustomData({
      approvedAction: this.confirmSaveCampaign,
      message: _("confirmSendCampaign"),
      proceedBtnLabel: _("yesSend"),
      abortBtnLabel: _("no"),
      title: "Confirmation of sending",
      svgIcon: SVG_SEND_ICON,
    });
  };

  render() {
    return (
      <div className="email-container">
        <h2 className="title-container">{_("Add_your_broadcast_contacts")}</h2>
        <p className="text-container">
          {_("Description_waiting_for_campaign_to_be_sent_to")}
        </p>
        <div>
          <UsersTagSuggestion
            displayEmail={true}
            selectedTags={this.state.recipients}
            onTagsChange={this.handleTagsChange}
          />
        </div>
        <button
          className={`button ${
            !this.state.recipients?.length ? "disabled" : ""
          } `}
          onClick={() => {
            this.handleShowDialog();
            this.afterSend();
          }}
        >
          {_("Send")}
        </button>
      </div>
    );
  }
}
