// E-box reducer
const initialStateForCounts = {
  UNREAD: 0,
  RECEIVED: 0,
  DELETED: 0,
  REFUSED: 0,
  SENT: 0,
};
const initialStateForEBoxReducer = {
  fetching: false,
  fetched: false,
  items: [],
  count: initialStateForCounts,
  error: null,
  updated: false,
  updating: false,
  nbResult: 0,
  eBoxMailData: { mailData: {}, fetching: false, fetched: false },
  inAdminSearchMode: false,
  filter: {
    paginationPage: 1,
    pageSize: 10,
    status: "RECEIVED",
    searchWord: "",
    recipientType: "",
    isFolderEbox: false,
  },
};

export const eBoxReducer = (state = initialStateForEBoxReducer, action) => {
  switch (action.type) {
    case "INIT_E_BOX": {
      return initialStateForEBoxReducer;
    }
    case 'FETCH_E_BOX_PENDING': {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case 'FETCH_E_BOX_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult
      };
      break;
    }
    case 'FETCH_E_BOX_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          }
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
        nbResult: 0
      };
      break;
    }
    case "SET_E_BOX_PAGINATION_PAGE": {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
    }
    case "SET_E_BOX_PAGE_SIZE": {
      return { ...state, filter: { ...state.filter, pageSize: action.pageSize, paginationPage: 1 } };
    }
    case "SET_E_BOX_STATUS": {
      return { ...state, filter: { ...state.filter, status: action.status, paginationPage: 1 } };
    }
    case "SET_E_BOX_SEARCH_WORD": {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
    }
    case "SET_E_BOX_RECIPIENT_TYPE": {
      return { ...state, filter: { ...state.filter, recipientType: action.recipientType, paginationPage: 1 } };
    }
    case "SET_IS_FOLDER_EBOX": {
      return { ...state, filter: { ...state.filter, isFolderEbox: action.isFolderEbox, paginationPage: 1 } };
    }
    case 'GET_MAIL_DATA_PENDING': {
      return {
        ...state,
        eBoxMailData: { ...state['eBoxMailData'], fetching: true, fetched: false }
      };
      break;
    }
    case 'REMOVE_EMAIL_PENDING': {
      return {
        ...state,
        updating: true
      };
      break;
    }
    case 'REMOVE_EMAIL_FULFILLED': {
      const { deletedId } = action.payload.data;
      let { items, count } = state;
      let { status } = state.filter;
      let newItems = items.filter(item => item.id != deletedId);
      count['DELETED']++;
      count[status]--;
      return {
        ...state,
        count: count,
        items: newItems,
      };
      break;
    }

    case 'GET_MAIL_DATA_FULFILLED': {
      const { data } = action.payload;
      let { count, items, inAdminSearchMode } = state;
      let { id, mailDataStatus } = state.eBoxMailData.mailData
      let { status } = state.filter;
      let newItems = items.reduce((acc, item) => {
        if (!inAdminSearchMode && item.id === id && item.status === "UNREAD" && status === "UNREAD") {
          return acc;
        }
        if (!inAdminSearchMode && item.id === id && item.status === "UNREAD" && status !== "SENT") {
          acc.push({ ...item, status: "OPEN" })
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
      return {
        ...state,
        eBoxMailData: { mailData: { ...state.eBoxMailData.mailData, ...data }, fetching: false, fetched: true },
        count: { ...count, ...!inAdminSearchMode && (status !== 'REFUSED' && status !== 'DELETED' && status !== 'SENT' && mailDataStatus !== 'OPEN') ? count['UNREAD']-- : count },
        items: newItems,
      };
      break;
    }
    case "SET_E_BOX_MAIL_DATA": {
      return { ...state, eBoxMailData: { ...state.eBoxMailData, mailData: { ...state.mailData, ...action.mailData } } };
    }
    case 'SET_E_BOX_FILTER_MAIL': {
      return { ...state, mail: action.mail };
      break;
    }
    case 'SET_OTHER_E_BOX_FILTER_MAIL': {
      return { ...state, searchMailData: action.searchMailData };
      break;
    }

    case 'SET_IN_ADMIN_SEARCH_MODE': {
      return { ...state, inAdminSearchMode: action.inAdminSearchMode };
      break;
    }
    case 'GET_MAILS_STATUSES_COUNT_PENDING': {
      return {
        ...state, fetching: true, fetched: false
      };
      break;
    }
    case 'GET_MAILS_STATUSES_COUNT_FULFILLED': {
      const { data } = action.payload.data;
      return {
        ...state,
        count: {
          RECEIVED: data.RECEIVED || 0,
          UNREAD: data.UNREAD || 0,
          REFUSED: data.REFUSED || 0,
          DELETED: data.DELETED || 0,
          SENT: data.SENT,
        },
      };
      break;
    }
    case 'GET_MAILS_STATUSES_COUNT_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        error,
        count: initialStateForCounts,
      }
    }
    default:
      return state;
  }
};

const initialStateForEBoxAttachmentsReducer = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  updated: false,
  updating: false,
  pageSize: 8,
  paginationPage: 0,
  nbResult: 0
}

export const eBoxAttachmentsReducer = (state = initialStateForEBoxAttachmentsReducer, action) => {
  switch (action.type) {
    case 'INIT_E_BOX_ATTACHMENTS': {
      return initialStateForEBoxAttachmentsReducer;
      break;
    }
    case 'FETCH_E_BOX_ATTACHMENTS_PENDING': {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case 'FETCH_E_BOX_ATTACHMENTS_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      const { items } = state;
      let newItems = [];
      if (1 == state.paginationPage) {
        newItems = data;
      } else {
        newItems = items || [];
        newItems.push(...data);
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: newItems,
        nbResult
      };
      break;
    }
    case 'FETCH_E_BOX_ATTACHMENTS_REJECTED': {
      let { paginationPage, nbResult, items } = state;
      let newItems = items;

      if (paginationPage == 1) {
        newItems = [];
        nbResult = 0;
      }
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          }
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: newItems,
        error,
        nbResult
      };
      break;
    }
    case 'FETCH_E_BOX_ATTACHMENTS': {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case 'SET_ATTACHMENTS_PAGINATION_PAGE': {
      return { ...state, paginationPage: action.pageNumber };
      break;
    }
    default:
      return state;
  }
};

const eboxOwnerInitialState = {
  fetching: false,
  fetched: false,
  data: null,
  error: null,
};

export const eboxOwnerReducer = (state = eboxOwnerInitialState, action) => {
  switch (action.type) {
    case "SET_EBOX_OWNER": {
      const { id, firstName, lastName, language, mainPhone, avatar, avatarUrl, mainEmail, userMobilePhone, email } = action.user;
      return {
        ...state,
        data: {
          ...state.data,
          id,
          firstName,
          lastName,
          avatar,
          avatarUrl,
          email,
          mainEmail,
          intlPhone: userMobilePhone ? userMobilePhone.intlFormat : "",
          phone: mainPhone ? mainPhone : "",
          language
        }
      };
    }
    case "FETCH_EBOX_OWNER_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_EBOX_OWNER_FULFILLED": {
      const { data } = action.payload.data;
      const { id, firstName, lastName, language, mainPhone, avatar, avatarUrl, mainEmail, email } = data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        data: {
          ...state.data,
          id,
          firstName,
          lastName,
          avatar,
          avatarUrl,
          email,
          mainEmail,
          phone: mainPhone,
          language
        }
      };
    }
    case "FETCH_EBOX_OWNER_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        data: null,
        error,
      };
    }
    default:
      return state;
  }
};

//E-box SMS reducer

const initialStateForEBoxSmsReducer = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  updated: false,
  updating: false,
  nbResult: 0,
  filter: {
    paginationPage: 1,
    pageSize: 10,
    status: "RECEIVED",
    searchWord: "",
  },
  eBoxSmsData: { smsData: {}, fetching: false, fetched: false },
};

export const eBoxSmsReducer = (state = initialStateForEBoxSmsReducer, action) => {
  switch (action.type) {
    case "INIT_E_BOX_SMS": {
      return initialStateForEBoxSmsReducer;
    }
    case 'FETCH_E_BOX_SMS_PENDING': {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case 'FETCH_E_BOX_SMS_FULFILLED': {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult
      };
      break;
    }
    case 'FETCH_E_BOX_SMS_REJECTED': {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404
          }
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
        nbResult: 0
      };
      break;
    }
    case "SET_E_BOX_SMS_PAGINATION_PAGE": {
      return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
    }
    case "SET_E_BOX_SMS_PAGE_SIZE": {
      return { ...state, filter: { ...state.filter, pageSize: action.pageSize, paginationPage: 1 } };
    }
    case "SET_E_BOX_SMS_SEARCH_WORD": {
      return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
    }
    case 'GET_EBOX_SMS_DATA_PENDING': {
      return {
        ...state,
        eBoxSmsData: { ...state['eBoxSmsData'], fetching: true, fetched: false }
      };
      break;
    }
    case 'GET_EBOX_SMS_DATA_FULFILLED': {
      const { data } = action.payload;
      return {
        ...state,
        eBoxSmsData: { smsData: { ...state.eBoxSmsData.smsData, ...data }, fetching: false, fetched: true },
      };
      break;
    }
    case "SET_E_BOX_SMS_STATUS": {
      return { ...state, filter: { ...state.filter, status: action.status, paginationPage: 1 } };
    }
    default:
      return state;
  }
};


