import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import { setTTPDialogShow } from "actions";
import TTPModal from "../ui/TTPModal";
import { SVG_QUESTION_ICON } from "utils";

@connect((store) => {
  return {
    TTPDialog: store.TTPDialog,
  };
})
export default class TTPDialog extends Component {
  constructor(props) {
    super(props);
    this.cancel = () => {
      const { dispatch } = this.props;
      dispatch(setTTPDialogShow(false));
      clearInterval(this.interval);
    };

    this.state = {
      ttl: 0,
    };
    this.interval = null;
  }

  proceed() {
    let { TTPDialog } = this.props;
    let approvedAction = TTPDialog.customData.approvedAction;
    if (approvedAction) {
      approvedAction();
    }
    this.cancel();
  }

  abort = () => {
    let { TTPDialog } = this.props;
    let abortAction = TTPDialog.customData.abortAction;
    if (abortAction) {
      abortAction();
    }
    this.cancel();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      showDialog,
      customData: { autoClosed },
    } = this.props.TTPDialog;
    if (
      showDialog &&
      showDialog !== prevProps.TTPDialog.showDialog &&
      autoClosed > 500
    ) {
      this.setState({ ttl: autoClosed });
      this.interval = setInterval(() => {
        this.setState({ ttl: this.state.ttl - 1000 });
        if (this.state.ttl < 0) {
          this.cancel();
        }
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let { TTPDialog } = this.props;
    let { ttl } = this.state;
    if (!TTPDialog.showDialog) {
      return null;
    }
    let customData = TTPDialog.customData;
    let modalStyle = {
      overlay: {
        ...customModalStyles.overlay,
        ...(customData.style || { overlay: {} }).overlay,
      },
      content: {
        ...customModalStyles.content,
        ...(customData.style || { content: {} }).content,
      },
    };
    let modalMessage = customData.message || _("Do you want to proceed ?");
    let modalProceedBtnLabel = customData.proceedBtnLabel || _("yes");
    let modalAbortBtnLabel = customData.abortBtnLabel || _("cancel");
    const modalType =
      customData.type && customData.type !== "warning"
        ? customData.type
        : "primary";
    const svgIcon = customData.svgIcon || SVG_QUESTION_ICON;
    const title = customData.title || "Confirmation";
    modalStyle.content.borderRadius = modalStyle.content.borderRadius || "0px";
    return (
      <TTPModal
        isOpen={TTPDialog.showDialog}
        onClose={this.cancel}
        modalStyle={modalStyle}
      >
        <div className="ttp-alert-dialog">
          <div className={`alert-header align-center header-${modalType}`}>
            <div className="icon-container">{svgIcon}</div>
            <p className="alert-header__title">{_(title)}</p>
          </div>
          <div className="row align-center">
            <div className="column small-10 ">
              <p className="alert-message">{modalMessage}</p>
            </div>
          </div>
          <div className="row alert-footer align-center">
            <button onClick={this.abort} className="cancel-button button">
              {modalAbortBtnLabel}{" "}
              {TTPDialog.customData.autoClosed &&
                ` ... ${Math.floor(ttl / 1000)}`}
            </button>
            <button
              onClick={this.proceed.bind(this)}
              className={`ok-button  button bg-${modalType}`}
            >
              {modalProceedBtnLabel}
            </button>
          </div>
        </div>
      </TTPModal>
    );
  }
}

export const customModalStyles = {
  overlay: {
    zIndex: 10,
    backgroundColor: "hsla(0,0%,4%,.45)",
  },
  content: {
    transition: "opacity 0.4s ease-out 0s",
    border: "none",
    boxShadow:
      "0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)",
    minWidth: "35rem",
    maxWidth: "40%",
    padding: "0",
  },
};
