export const fetchEBox = (resp) => {
  return {
    type: 'FETCH_E_BOX',
    payload: resp
  };
};

export const initEbox = () => {
  return { type: "INIT_E_BOX" };
};

export const setEBoxPaginationPage = (pageNumber) => {
  return {
    type: 'SET_E_BOX_PAGINATION_PAGE',
    pageNumber
  };
};

export const setEBoxPageSize = (pageSize) => {
  return {
    type: 'SET_E_BOX_PAGE_SIZE',
    pageSize
  };
};

export const setEboxSearchWord = (searchWord) => {
  return {
    type: "SET_E_BOX_SEARCH_WORD",
    searchWord
  };
};

export const setEboxRecipientType = (recipientType) => {
  return {
    type: "SET_E_BOX_RECIPIENT_TYPE",
    recipientType
  };
};

export const setIsFolderEbox = (isFolderEbox) => {
  return {
    type: 'SET_IS_FOLDER_EBOX',
    isFolderEbox
  };
};

export const setEboxStatus = (status) => {
  return {
    type: "SET_E_BOX_STATUS",
    status
  };
};

export const getMailData = (resp) => {
  return {
    type: 'GET_MAIL_DATA',
    payload: resp
  };
};

export const setEBoxFilterMail = (mail) => {
  return {
    type: 'SET_E_BOX_FILTER_MAIL',
    mail
  };
};

export const setOtherEBoxFilterMail = (searchMailData) => {
  return {
    type: 'SET_OTHER_E_BOX_FILTER_MAIL',
    searchMailData
  };
};

export const setInAdminSearchMode = (inAdminSearchMode) => {
  return {
    type: 'SET_IN_ADMIN_SEARCH_MODE',
    inAdminSearchMode
  };
};

export const fetchEBoxAttachments = (resp) => {
  return {
    type: 'FETCH_E_BOX_ATTACHMENTS',
    payload: resp
  };
};


export const setEboxOwner = (user) => {
  return {
    type: 'SET_EBOX_OWNER',
    user
  };
};

export const setEboxMailData = (mailData) => {
  return {
    type: "SET_E_BOX_MAIL_DATA",
    mailData
  }
};

export const fetchEboxOwner = (resp) => {
  return {
    type: "FETCH_EBOX_OWNER",
    payload: resp
  };
};

export const setAttachmentsPaginationPage = (pageNumber) => {
  return {
    type: 'SET_ATTACHMENTS_PAGINATION_PAGE',
    pageNumber
  };
};

export const initEboxAttachments = () => {
  return {
    type: "INIT_E_BOX_ATTACHMENTS",
  };
};

export const getMailsStatusCount = (resp) => {
  return {
    type: "GET_MAILS_STATUSES_COUNT",
    payload: resp
  };
};

export const deleteEmail = (resp) => {
  return {
    type: 'REMOVE_EMAIL',
    payload: resp
  };
};

// E-box SMS

export const fetchEBoxSms = (resp) => {
  return {
    type: 'FETCH_E_BOX_SMS',
    payload: resp
  };
};

export const initEboxSms = () => {
  return { type: "INIT_E_BOX_SMS" };
};

export const setEBoxSmsPaginationPage = (pageNumber) => {
  return {
    type: 'SET_E_BOX_SMS_PAGINATION_PAGE',
    pageNumber
  };
};

export const setEBoxSmsPageSize = (pageSize) => {
  return {
    type: 'SET_E_BOX_SMS_PAGE_SIZE',
    pageSize
  };
};

export const setEboxSmsSearchWord = (searchWord) => {
  return {
    type: "SET_E_BOX_SMS_SEARCH_WORD",
    searchWord
  };
};

export const getEBoxSmsData = (resp) => {
  return {
    type: 'GET_EBOX_SMS_DATA',
    payload: resp
  };
};

export const setEboxSmsStatus = (status) => {
  return {
    type: "SET_E_BOX_SMS_STATUS",
    status
  };
};

