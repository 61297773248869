import React, { Component } from 'react';
import _ from 'i18n';

class Breadcrumbs extends Component {

    goTo = (ev, stepId) => {
        ev.preventDefault();
        this.props.goToStep(stepId);
    }

    shift = (increment) => {
        const { steps, activeStep, goToStep } = this.props;
        const stepsKeys = Object.keys(steps);
        const activeIndex = stepsKeys.findIndex(step => step == activeStep);
        const newStep = stepsKeys[activeIndex + increment];
        goToStep(newStep ? newStep : stepsKeys[0]);
    }

    render() {
        const { steps, activeStep, next } = this.props;
        const stepsKeys = Object.keys(steps);
        const currentIndex = stepsKeys.findIndex(step => step == activeStep);
        let nextAction = undefined;
        if (next && next.action && next.enabled && !next.updating) {
            nextAction = next.action;
        }
        return (
            <div id="breadcrumbs" >
                <ul>
                    {
                        stepsKeys.map((id, i) => {
                            let cssClass = (activeStep == id) ? "active" : "enabled";
                            const { enabled, label } = steps[id];
                            if (!enabled) {
                                cssClass = "disabled";
                            } else if (i < currentIndex) {
                                cssClass = "active";
                            }
                            return (
                                <li key={id} className={cssClass} onClick={enabled ? (ev) => this.goTo(ev, id) : undefined} >
                                    <span>
                                        {label ? _(label) : `${_('step')}`}
                                        <i className="step-number">{i + 1}</i>
                                    </span>
                                </li>
                            )
                        })
                    }
                </ul>
                <div>
                    {(steps && stepsKeys[0] != activeStep) && <button onClick={() => this.shift(-1)}><i className="icon-arrow-left" /></button>}
                    {next && <button className={!next.enabled && "disabled"} onClick={nextAction}>{_(next.updating ? _('Processing...') : next.label)} <i className="icon-arrow-right" /></button>}
                </div>
            </div>
        );
    }
}

export default Breadcrumbs;
