import React, { Component } from 'react';
import { SMS_CAMPAIGN_DYNAMIC_FIELDS, SMS_CAMPAIGN_RECIPIENTS_TYPE } from "Common";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'

import _ from 'i18n';
import SMSModels from './SMSModels';
import TTPSelect from "common/TTPSelect";
import TestTab from './test/TestTab';
import { setCurrentSmsCampaignData } from 'actions'
import { saveTextModel } from 'thunks';
import { onError, onSuccess, getLanguages } from 'utils';

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    recipientType: state.currentCampaign.sms.recipientType,
    language: state.currentCampaign.sms.language,
    smsMessage: state.currentCampaign.sms.smsMessage,
    campaignName: state.currentCampaign.sms.campaignName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleCampaignDataChange: (key, value) => {
      const data = {};
      data[key] = value;
      dispatch(setCurrentSmsCampaignData(data));
    },

    saveTextModel: (data) => dispatch(saveTextModel(data)).then(
      (res) => { onSuccess(res); },
      (err) => onError(err)
    ),
  }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class Params extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showSMSModelsModal: false,
      showSmsPreviewModal: false,
    };
    this.toggleSmsModelsSideBar = () => this.setState({ showSMSModelsModal: true });
    this.toggleSmsPreviewSideBar = () => this.setState({ showSmsPreviewModal: true });
  }

  renderLang() {
    const { language, handleCampaignDataChange } = this.props;
    return <TTPSelect
      simple={true}
      notClearable={true}
      values={language}
      placeholder={_('lang')}
      cssClass={language ? "filled-box" : "empty-box"}
      options={getLanguages().map(({ key, value }) => ({ id: key, label: _(value).toUpperCase() }))}
      onChange={(lng) => handleCampaignDataChange("language", lng)} />;
  }

  renderRecipientType() {
    const { recipientType, handleCampaignDataChange } = this.props;
    return <TTPSelect
      simple={true}
      notClearable={true}
      values={recipientType}
      placeholder={_('recipientType')}
      cssClass={recipientType ? "filled-box" : "empty-box"}
      options={SMS_CAMPAIGN_RECIPIENTS_TYPE.map(type => ({ id: type, label: _(type).toUpperCase() }))}
      onChange={(type) => handleCampaignDataChange("recipientType", type)} />;
  }

  textAreaAdjust(e) {
    e.target.style.height = "1px";
    e.target.style.height = `${25 + e.target.scrollHeight}px`;
  }

  replaceDynamicFieldsInPreview(smsMessage) {
    let { firstName, lastName } = this.props.auth.user;

    let newSmsMessage = (smsMessage.split("(FIRST_NAME)").join(firstName)).split("(LAST_NAME)").join(lastName);
    return newSmsMessage.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }

  replaceDynamicFieldsInSmsModelsPreview(smsModelsContent) {
    const { recipientType } = this.props;

    if (recipientType !== "MANUAL") {
      return smsModelsContent;
    } else {
      let newSmsModelsContent = (smsModelsContent.split("(FIRST_NAME)").join("")).split("(LAST_NAME)").join("");
      return newSmsModelsContent.split("  ").join(" ");
    }
  }

  closeModelsSideBar(modelType) {
    if (modelType == "models") {
      this.setState({ showSMSModelsModal: false });
      this.props.applySmsModelMessage();
    } else {
      this.setState({ showSmsPreviewModal: false });
    }
  }

  renderDynamicField() {
    const { recipientType, onAddDynamicField } = this.props;

    if (recipientType != "CONTACT") {
      return null
    }
    return <div className="fields-box">
      {SMS_CAMPAIGN_DYNAMIC_FIELDS.map((item) => <div key={item.name} onClick={() => onAddDynamicField(item.value)}> {_(item.name)}</div>)}
    </div>
  }

  applySmsTextModel = (smsModel) => {
    let { onSmsModelMessageChange, applySmsModelMessage, handleCampaignDataChange } = this.props;

    if (!smsModel) {
      handleCampaignDataChange("smsTextModel", null);
      handleCampaignDataChange("smsTextModelTitle", null);
      onSmsModelMessageChange("");
      return;
    }
    let { value, label, content } = smsModel;
    handleCampaignDataChange("smsTextModel", value);
    handleCampaignDataChange("smsTextModelTitle", label);

    let contentWithDynamicFields = this.replaceDynamicFieldsInSmsModelsPreview(content);
    let modelContent = (content != "" && (content.indexOf("(FIRST_NAME)") != -1 || content.indexOf("(LAST_NAME)") != -1)) ? contentWithDynamicFields : content;
    onSmsModelMessageChange(modelContent);
    applySmsModelMessage();
  }

  persistSmsTextModel = (data) => {
    let { saveTextModel, smsModelMessage } = this.props;
    if (smsModelMessage.length == 0) {
      return;
    }
    data = { ...data, content: smsModelMessage, targetApp: "SMS" };
    saveTextModel(data);
  }


  render() {
    let {
      smsMessage, onSMSMessageChange, campaignName, onCampaignNameChange,
      recipientType, smsCount, smsLength, perMessage,
      remaining, hasError, language, onSmsModelMessageChange, smsModelMessage, onAddModelDynamicField, onTest } = this.props;
    let { showSMSModelsModal, showSmsPreviewModal } = this.state;
    let inPreviewMessage = this.replaceDynamicFieldsInPreview(smsMessage);

    return (
      <div className="campaign-sms">
        <div className="row align-center">
          <div className="medium-5">
            <div className="columns">
              <h1 className="param__title">{_('campaignName')} </h1>
              <div>
                <input type="text" className={`${campaignName ? "filled-box" : "empty-box"} ${hasError.campaignName ? 'is-invalid-input' : ""}`}
                  onChange={onCampaignNameChange} value={campaignName} />
                <span
                  className={hasError.campaignName ? 'form-error is-visible' : 'form-error'}> {_('invalid campaign name')}</span>
              </div>
            </div>
            <div className="columns">
              <h1 className="param__title">{_('recipientType')}</h1>
              {this.renderRecipientType()}
            </div>
          </div>
          <div className="medium-7 columns">
            <div className="flex-container align-justify align-middle">
              <h1 className="param__title">{_('smsMessage')} </h1>
              <div className="action-header">
                <div data-for="models-tooltip" data-tip={_('models')}>
                  <img src="/img/icons/models.svg" onClick={this.toggleSmsModelsSideBar} className="icon-docs sms-models" />
                  <ReactTooltip id="models-tooltip" type='dark' effect="solid" multiline={true} place="bottom" />
                </div>
                <div data-for="preview-tooltip" data-tip={_('preview')}>
                  <Link onClick={this.toggleSmsPreviewSideBar} className="icon-eye sms-models" data-tip={_('preview')}/>
                  <ReactTooltip id="preview-tooltip" type='dark' effect="solid" multiline={true} place="bottom" />
                </div>
              </div>
            </div>
            {this.renderDynamicField()}
            <textarea
              value={smsMessage}
              rows={4}
              placeholder={_('message')}
              onKeyUp={this.textAreaAdjust}
              className={(smsMessage && smsMessage.length) ? "filled-box" : "empty-box"}
              onChange={onSMSMessageChange}>{smsMessage}</textarea>
            <div className="message-counts">
              <span>{remaining} {_('remainingChars')}</span>
              <div className="float-right">
                <span>{smsLength}/{perMessage} {_('chars')} | </span >
                <b>{smsCount}/9 {_('SMS')}</b>
              </div>
            </div>
          </div>

        </div>
        <div className="row align-center">
          <div className="medium-5 columns">
            <h1 className="param__title">{_('language')}</h1>
            {this.renderLang()}
          </div>
          <div className="medium-7 columns">
            <TestTab onConfirm={onTest} smsCount={smsCount} />
          </div>
        </div>
        {showSMSModelsModal && <SMSModels
          onCloseModal={this.closeModelsSideBar.bind(this, "models")}
          canUseSmsModels={smsModelMessage.length != 0}
          language={language}
          onApplySmsTextModel={this.applySmsTextModel}
          onSaveSmsTextModel={this.persistSmsTextModel}
          showSMSModelsModal={showSMSModelsModal}
          onSMSMessageChange={onSMSMessageChange}
          onCancelAction={() => this.setState({ showSMSModelsModal: false })}
          recipientType={recipientType}
          onSmsModelMessageChange={onSmsModelMessageChange}
          smsModelMessage={smsModelMessage}
          onAddModelDynamicField={onAddModelDynamicField}
        />
        }

        <div className="overlay" style={(showSmsPreviewModal ? { display: "block", marginTop: 0 } : { display: "none" })} onClick={this.closeModelsSideBar.bind(this)}></div>

        <div id="sms-preview">
          <div className={(showSmsPreviewModal ? "sidebar sidebar--opened" : "sidebar sidebar--opened side-sidebar")}>
            <span id="theme-preview_close" onClick={this.closeModelsSideBar.bind(this, "preview")}>
              <i className="icomoon icon-tt-close" />
            </span>
            {showSmsPreviewModal && <div className="sidebar__form">
              <div id="theme-preview">
                <div className="container-smartphone">
                  <div className="columns phone-container">
                    <div className="phone-text">{inPreviewMessage}</div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>

    );
  }
}
