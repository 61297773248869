import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'i18n';
import { setCurrentEmailCampaignData } from '../../../actions/actions';
import { hasValidLength } from '../../../services/utils';

class SubjectBox extends Component {
  state = {
    objectHasError: false,
  };

  componentDidMount() {
    this.props.onRef ? this.props.onRef(this) : {};
  }

  subjectFocusHandler(e) {
    const savedValue = e.target.value;
    const { onFocus } = this.props;
    e.target.value = "";
    e.target.baseScrollHeight = e.target.scrollHeight;
    e.target.value = savedValue;
    if (onFocus) {
      onFocus();
    }
  }

  subjectInputHandler() {
    let textArElement = document.getElementsByClassName('subject-box');
    if (this.props.object.length && textArElement && textArElement.length > 0) {
      textArElement[0].style.height = "0px";
      textArElement[0].style.height = textArElement.item(0).scrollHeight + 'px';
    }
  }

  handleObjectChange = (e) => {
    const object = (e.target) ? e.target.value : e;
    this.props.setCurrentEmailCampaignData({ object });
    this.setState({ objectHasError: !hasValidLength(object, 5, 200), currentValue: object });
  };

  render() {
    const { object, disabled } = this.props;
    const { objectHasError } = this.state;

    return (
      <div className="object-container">
        <textarea
          rows={2}
          className={`subject-box ${disabled ? "disabled" : ""}  ${object ? "filled-box" : "empty-box"} ${objectHasError ? 'is-invalid-input' : ""}`}
          placeholder={_('subject')}
          onChange={this.handleObjectChange}
          onFocus={this.subjectFocusHandler.bind(this)}
          onInput={this.subjectInputHandler.bind(this)}
          value={this.state.currentValue}
        >{object}</textarea>
        <span className={objectHasError ? 'form-error is-visible' : 'form-error'}> {_('invalidObject')}</span>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  object: state.currentCampaign.email.object,
})

const mapDispatchToProps = dispatch => ({
  setCurrentEmailCampaignData: data => dispatch(setCurrentEmailCampaignData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SubjectBox);
