import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";
import { compose } from "redux";

import {
  setCurrentLayout,
  setBlocksType,
  addFeedToCurrentLayout,
} from "actions";
import { TEMPLATE_TYPES } from "constants";
import { SVG_ICONS } from "../../config/Common";
import {
  SVG_DELETE_ICON,
  SVG_DOUBLE_UP_ICON,
  SVG_UP_ICON,
  SVG_DUPLICATE_ICON,
} from "../../services/svgIcones";
import {
  replaceContentFeed,
  replaceUnreferencedBlock,
} from "../../services/block/newsletter";
import { FAKE_FEEDS } from "../../services/block/helpers";
import { isArraysEqual } from "../../services/utils";

class FeedCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prepareToRemove: false,
      highlighted: false,
      feedsIds: [],
    };
  }

  componentDidUpdate() {
    const {
      card: { id, feeds },
    } = this.props;
    const newFeedsIds = feeds.map(({ id }) => id);

    if (!isArraysEqual(newFeedsIds, this.state.feedsIds)) {
      const elem = document.getElementById(`card-${id}`);
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => this.setState({ highlighted: false }), 300);
      this.setState({ feedsIds: newFeedsIds, highlighted: true });
    }
  }

  toogleSelection = (e) => {
    const { setBlocksType, setCurrentLayout, currentLayout, card, blockType } =
      this.props;
    const { type, id } = card;
    if (
      [
        TEMPLATE_TYPES["NEWS_LAYOUT"],
        TEMPLATE_TYPES["SPEAKER_LAYOUT"],
        TEMPLATE_TYPES["SLOT_LAYOUT"],
        TEMPLATE_TYPES["FORMATION_LAYOUT"],
      ].indexOf(type) == -1
    ) {
      return;
    }
    setCurrentLayout(currentLayout && currentLayout.id == id ? null : card);
    if (type != blockType) {
      setBlocksType(type);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  resizeIframe = (refId) => {
    let previewContainer = document.getElementById(refId);
    if (previewContainer) {
      let obj = this.refs[refId];
      let newHeight = obj.contentWindow.document.body.offsetHeight;
      previewContainer.style.height = `${newHeight * 0.55}px`;
      obj.contentWindow.scrollTo(
        (obj.contentDocument.body.scrollWidth -
          obj.contentDocument.body.clientWidth) /
          2,
        0
      );
    }

    this.refs[refId].contentDocument.body.addEventListener(
      "click",
      this.toogleSelection
    );
  };

  removeCard = () => {
    this.setState({ prepareToRemove: true });
    setTimeout(() => {
      this.props.removeCard();
    }, 500);
  };

  render() {
    const {
      card,
      connectDragSource,
      connectDropTarget,
      index,
      moveCard,
      currentLayout,
      lastIndex,
      isOver,
      canDrop,
    } = this.props;
    const { id, column, feeds, type, content } = card;
    const { highlighted, prepareToRemove } = this.state;

    let preview;
    let maxColumn = column || 1;

    let cssClass = `${
      feeds && feeds.length == maxColumn ? "" : "border-alert"
    }`;
    if (currentLayout && currentLayout.id == id) {
      cssClass = "active";
    }
    const ref = `iframe-${id}`;

    if (
      [
        TEMPLATE_TYPES["STATIC_BLOCK"],
        TEMPLATE_TYPES["UNREFERENCED_BLOCK"],
      ].indexOf(type) > -1
    ) {
      preview = (
        <div key={ref} id={ref}>
          <iframe
            scrolling="no"
            className="unreferenced"
            srcDoc={`<body style='margin: 0;'>${replaceUnreferencedBlock(
              content
            )}</body>`}
            ref={ref}
            onLoad={() => this.resizeIframe(ref)}
          />
        </div>
      );
    } else {
      const data = [...new Array(maxColumn)].map(
        (_, index) => feeds[index] || FAKE_FEEDS[type]
      );

      const style = maxColumn == 1 ? { position: "absolute" } : {};
      let replacedContent = `<body style='margin: 0;'>${replaceContentFeed(
        content,
        data
      )}</body>`;
      preview = (
        <div
          className={"iframe-container " + cssClass}
          key={ref}
          onClick={this.toogleSelection}
          id={ref}
        >
          <iframe
            style={style}
            srcDoc={replacedContent}
            ref={ref}
            onLoad={() => this.resizeIframe(ref)}
            scrolling="no"
          />
          <img src={`${"/img/icons/" + SVG_ICONS["ARROW_LEFT"]}`} />
        </div>
      );
    }

    return connectDragSource(
      connectDropTarget(
        <div
          id={`card-${id}`}
          className={`layout-item flex-container ${
            prepareToRemove && "remove"
          } ${highlighted && "highlight"}`}
        >
          {canDrop && isOver && <div className="drop_placeholder" />}
          <div className="card-actions">
            {index > 1 && (
              <div
                className="double-icon-top"
                onClick={() => moveCard(index, 0)}
              >
                <span className={"double-up"}>{SVG_DOUBLE_UP_ICON}</span>
              </div>
            )}
            {index != 0 && (
              <div
                className="single-icon"
                onClick={() => moveCard(index, index - 1)}
              >
                <span className={"up"}>{SVG_UP_ICON}</span>
              </div>
            )}
            {index != lastIndex && (
              <div onClick={() => moveCard(index, index + 1)}>
                <span className={"down"}>{SVG_UP_ICON}</span>
              </div>
            )}
            {index < lastIndex - 1 && (
              <div
                className="double-icon-bottom"
                onClick={() => moveCard(index, lastIndex)}
              >
                <span className="double-down">{SVG_DOUBLE_UP_ICON}</span>
              </div>
            )}
          </div>
          <div className="card-actions float-right">
            <div onClick={this.removeCard}>{SVG_DELETE_ICON}</div>
            <div onClick={this.props.duplicateCard}>{SVG_DUPLICATE_ICON}</div>
          </div>
          <div className={`layout-item__img`}>{preview}</div>
        </div>
      )
    );
  }
}

const cardSource = {
  beginDrag(props) {
    return {
      index: props.index,
      card: props.card,
      type: "CARD",
    };
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    if (monitor.getItem().type != "CARD") {
      return;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }
    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
  drop(props, monitor) {
    if (monitor.getItem().type == "CARD") {
      return;
    }
    props.setCurrentLayout(props.card);
    props.addFeedToCurrentLayout(monitor.getItem());
  },
};
const mapStateToProps = (state) => ({
  currentLayout: state.themes.newsletter.currentLayout,
  blockType: state.themes.blocks.filter.type,
});

const mapDispatchToProps = (dispatch) => ({
  setBlocksType: (type) => dispatch(setBlocksType(type)),
  setCurrentLayout: (layout) => dispatch(setCurrentLayout(layout)),
  addFeedToCurrentLayout: (feed) => dispatch(addFeedToCurrentLayout(feed)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DropTarget(
    (props) => [props.card.type, "CARD"],
    cardTarget,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    })
  ),
  DragSource("CARD", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))
)(FeedCard);
