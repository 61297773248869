import React, { Component } from 'react'
import { connect } from 'react-redux';
import _ from 'i18n';

import { SES_PENDING_STATUS, SES_UNREGISTERED_STATUS } from '../../../services/email';
import { fetchSesEmailsStatuses, registerFromEmail } from '../../../actions/thunks';
import { setEboxFromEmail } from '../../../actions/actions/ebox/widget';
import { SVG_INFO_ICON } from '../../../services/utils';

const mapStateToProps = (state, ownProps) => ({
  myEmails: state.auth.user.email,
});

const mapDispatchToProps = dispatch => ({
  fetchSesEmailsStatuses: () => dispatch(fetchSesEmailsStatuses()),
  setEboxFromEmail: email => dispatch(setEboxFromEmail(email)),
  registerFromEmail: email => dispatch(registerFromEmail(email)),
});
@connect(mapStateToProps, mapDispatchToProps)

export default class EmailValidator extends Component {

  registerEmail = () => {
    const { fromEmail, setEboxFromEmail, onMyEmailsChange, myEmails } = this.props;

    if (!fromEmail) {
      return;
    }

    this.props.registerFromEmail(fromEmail.email).then(() => {
      const newFromEmail = { ...fromEmail, status: SES_PENDING_STATUS };
      const newEmails = myEmails.map(myEmail => (myEmail.email == fromEmail.email) ? newFromEmail : myEmail);

      onMyEmailsChange(newEmails);
      setEboxFromEmail(newFromEmail);
    }
    );
  };

  render() {
    const { fromEmail, fetchSesEmailsStatuses } = this.props;
    if (!fromEmail || [SES_PENDING_STATUS, SES_UNREGISTERED_STATUS].indexOf(fromEmail.status) == -1) {
      return null;
    }

    return (
      <div className="flex-container">
        <button className={`btn secondary small`}
          onClick={(SES_PENDING_STATUS === fromEmail.status) ? fetchSesEmailsStatuses : this.registerEmail}>
          {(SES_PENDING_STATUS === fromEmail.status) ? _('refresh') : _('submit')}
        </button>
        <span className="info_body p-l-l">
          {(SES_PENDING_STATUS === fromEmail.status) ? _('reply_aws_email') : _('register_from_email')}
        </span>
      </div>
    )
  }
}
