import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import _ from 'i18n';
import SubjectBox from './SubjectBox';
import {
  EVENT_RECIPIENT_TYPES,
  SURVEY_RECIPIENT_TYPES,
  PAYMENT_RECIPIENT_TYPES
} from '../../../config/Common';
import {
  setCurrentEmailCampaignData, setFreeSectionDatePickerDate,
  setFreeSectionDatePickerDateTime, setFreeSectionDatePickerTime
} from '../../../actions/actions';
import Block from '../common/Block';
import SunEdit from "../../common/editor/SunEdit";
import Banner from 'react-js-banner';
import {SVG_CLOSE_ICON} from "../../../services/svgIcones";
import { NotificationManager } from 'react-notifications';

class CampaignText extends PureComponent {

  constructor(props) {
    super(props);

    let {lng, location} = props;
    const query = new URLSearchParams(location.search);
    let language = (query.get('language')) ? query.get('language') : lng;

    this.state = {
      stickyToolbar: false,
      activeInput: 'editor', // editor, subject,
      language,
      showBanner: false
    };
    this.handleShowBanner = () => this.setState(prevState => ({showBanner: !prevState.showBanner}));
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
    if (this.props.currentTextModel) {
      this.setState({showBanner: true});
    }
    this.props.setFreeSectionDatePickerDateTime(null);
    this.props.setFreeSectionDatePickerDate(null);
    this.props.setFreeSectionDatePickerTime(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentTextModel !== this.props.currentTextModel) {
      if (prevProps.currentTextModel) {
        NotificationManager.info(_('Text model changed successfully'));
      }
      this.setState({showBanner: true});
    }
  }

  handleEditorChange = (editorState) => {
    console.log("OnChange: ", editorState);
    this.props.setCampaignHtml(editorState);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  handleScroll = () => {
    if (window.scrollY > 234 && !this.state.stickyToolbar) {
      this.setState({ stickyToolbar: true });
    } else if (window.scrollY <= 234 && this.state.stickyToolbar) {
      this.setState({ stickyToolbar: false });
    }
  };

  handleAddField = (field) => {
    const { object } = this.props;
    this.onObjectChange(`${object ? object + " " : ""}${field} `);
  };

  onObjectChange = (object) => {
    this.child.handleObjectChange(object);
  };

  render() {
    let {html, currentTextModelTitle, recipientType} = this.props;
    const {showBanner, stickyToolbar, activeInput, editorState} = this.state;

    let fieldsType;
    if (EVENT_RECIPIENT_TYPES.indexOf(recipientType) > -1) {
      fieldsType = "EVENT";
    } else if (SURVEY_RECIPIENT_TYPES.indexOf(recipientType) > -1) {
      fieldsType = "SURVEY";
    }
    if (PAYMENT_RECIPIENT_TYPES.indexOf(recipientType) > -1) {
      fieldsType = "PAYMENT";
    }

    return (

      <div id="campaign-text" className={`campaign-text medium-8`}>
        <Banner showBanner={showBanner}>
          <div className="banner">
            <span>{_("Your campaign is now based on the text model")}: <span
              className="title">{currentTextModelTitle}</span></span>
            <span onClick={this.handleShowBanner}
                  className="banner-close">{SVG_CLOSE_ICON} </span>
          </div>
        </Banner>
        <Block
          title="write your email"
          subTitle="add the subject and content of your email">
          <div className={`campaign-text__left`}>
            <SubjectBox
              onFocus={() => this.setState({activeInput: 'subject'})}
              onRef={ref => (this.child = ref)}/>
          </div>
          <SunEdit
            changed={this.handleEditorChange}
            content={html}
            fieldsType={fieldsType}
            onObjectChange={this.onObjectChange}
            onAddField={this.handleAddField}
            onClick={() => this.setState({ activeInput: 'editor' })}
            editorActive={activeInput === 'editor'}

          />
        </Block>
      </div>
    )
  }
}

const mapStateToProps = (store) => ({
  step: store.params.step,
  lng: store.params.lng,
  recipientType: store.currentCampaign.email.recipientType,
  object: store.currentCampaign.email.object,
  html: store.currentCampaign.email.html,
  currentTextModel: store.currentCampaign.email.textModel,
  currentTextModelTitle: store.currentCampaign.email.textModelTitle
});

const mapDispatchToProps = (dispatch) => ({
  setCampaignHtml: html => dispatch(setCurrentEmailCampaignData({ html })),
  setFreeSectionDatePickerDateTime: (dateTime) => dispatch(setFreeSectionDatePickerDateTime(dateTime)),
  setFreeSectionDatePickerDate: (date) => dispatch(setFreeSectionDatePickerDate(date)),
  setFreeSectionDatePickerTime: (time) => dispatch(setFreeSectionDatePickerTime(time)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)
  (CampaignText);
