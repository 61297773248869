import React from "react";
import _ from "i18n";
import { connect } from "react-redux";
import { fetchEvents, fetchSurveys, fetchEmailSettings, fetchEventCycles } from "thunks";
import Async from 'react-select/async';
import { BadgeOption } from "../../ui/select/CustomOption";
import { mapEventsData, mapSurveysData } from "utils";
import TTPSelect from "../../common/TTPSelect";
import { getSupportedAppsOptions } from "../../../services/apps";
import Application from "../../common/Application";

const mapStateToProps = (state) => ({
  lng: state.params.lng,
  currentSetting: state.genericEmail.currentSetting,
});

const mapDispatchToProps = dispatch => ({
  fetchSurveys: () => dispatch(fetchSurveys()),
  fetchEvents: (id) => dispatch(fetchEvents(id, true)),
  fetchEventCycles: (id) => dispatch(fetchEventCycles(id, true)),
  fetchCurrentSettings: filter => dispatch(fetchEmailSettings(filter)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class TargetSelects extends React.Component {
  constructor(props) {
    super(props);
    this.targetAppOptions = [
      { value: 'EVENT_REVIVAL', label: <Application name={'EVENT REVIVAL'} logo={"event"} />, id: 'EVENT_REVIVAL' },
      { value: 'EVENT_REMINDER', label: <Application name={'EVENT REMINDER'} logo={"event"} />, id: 'EVENT_REMINDER' },
      ...getSupportedAppsOptions().filter(app => {
        return ["EVENT", "PAYMENT"].indexOf(app.value) < 0;
      })
    ];
  }

  loadTargetsOptions = (inputValue, callback) => {
    const { fetchEvents, fetchSurveys, fetchEventCycles, lng, currentSetting: { targetApp } } = this.props;

    switch (targetApp) {
      case "EVENT":
        fetchEvents(inputValue).then((res) => {
          const options = mapEventsData(res, lng);
          callback(options);
        }).catch(() => callback([]));
        break;

      case "SURVEY":
        fetchSurveys(inputValue).then((res) => {
          const options = mapSurveysData(res, lng);
          callback(options);
        }).catch(() => callback([]));
        break;

      case "EVENT_CYCLE":
        fetchEventCycles(inputValue).then((res) => {
          const options = mapEventsData(res, lng);
          callback(options);
        }).catch(() => callback([]));
        break;

      default:
        callback([]);
        break;
    }
  };

  handleTargetChange = (target) => {
    const { activeLanguage, targetChanged, fetchCurrentSettings, currentSetting: { targetApp }, onStateChange } = this.props;
    targetChanged(target, targetApp);
    if (target && target.id) {
      fetchCurrentSettings({ targetApp, targetId: target.id });
    }

    if (target && target.languages && !target.languages.some(lng => lng === activeLanguage)) {
      onStateChange({ activeLanguage: target.languages[0] || null });
    }
  };

  render() {
    const { currentSetting: { targetApp, target, types }, lockTarget } = this.props;

    if (["SURVEY", "EVENT", "PAYMENT", "EVENT_CYCLE"].indexOf(targetApp) === -1) {
      return null;
    }

    let select = null;
    let app = targetApp.toLowerCase();
    if (lockTarget) {
      let typesCount = types?.length || 0 ;
      if (targetApp === "PAYMENT" && ["EVENT_REMINDER", "EVENT_REVIVAL"].indexOf(target.id) > -1){
        typesCount = types.filter(function (t) {
          if (target.id === "EVENT_REVIVAL") {
            return t.id.search("RELANCE") > -1
          } else {
            return t.id.search("RELANCE") < 0
          }
        }).length;
      }
      select = <div className="target-label">
        {_(target?.label)}
        <span>{typesCount} {_('emails types')}</span>
      </div>
    } else if (["EVENT", "SURVEY", "EVENT_CYCLE"].indexOf(targetApp) > -1) {
      select = <Async
        inputId={`${app}-select`}
        cacheOptions
        defaultOptions
        className={`ttp-select ${!target && "empty-box"}`}
        classNamePrefix="ttp-select"
        placeholder={_(`Select`)}
        value={target}
        onChange={target => this.handleTargetChange(target)}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.label}
        loadOptions={this.loadTargetsOptions}
        components={{ Option: BadgeOption }}
        noOptionsMessage={() => _("Nothing Found")}
        loadingMessage={() => _("Loading suggestions ...")}
      />
    } else if ("PAYMENT" === targetApp) {
      select = <TTPSelect
        isObjects
        simple
        notClearable
        values={target?.id}
        placeholder={_('target')}
        options={this.targetAppOptions}
        onChange={this.handleTargetChange}
      />
    }

    return (
      <div className="field" key={`${app}-select-input`}>
        <span className="param-label">{_(targetApp && targetApp !== "PAYMENT" ? targetApp.toLowerCase() : 'target')} </span>
        {select}
      </div>
    )
  }
}
