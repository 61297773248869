import React, { Component } from "react";
import _ from "i18n";
import { connect } from "react-redux";
import classnames from "classnames";

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({});
@connect(
  mapStateToProps,
  mapDispatchToProps
)
export default class TopBar extends Component {
  render() {
    const { children, onClose, plan } = this.props;

    return (
      <div className="top-bar">
        <div
          className={`columns columns-padding flex-container align-middle title-container`}
        >
          <img src="/img/icons/newsletterUA.svg" />
          <span>NEWSLETTER</span>
          <span className={classnames("m-l-xs", "tag-plan")}>
            {_(plan.toLowerCase())}
          </span>
        </div>
        <div className="top-bar__buttons">{children}</div>
        {onClose && (
          <div
            className={"top-bar_close resize flex-container align-middle"}
            onClick={() => onClose("LIST_PAGE")}
          >
            <span className="m-l-xs icomoon icon-tt-close" />
          </div>
        )}
      </div>
    );
  }
}
