export const createCampaign = (resp) => {
  return {
    type: "SAVE_CAMPAIGN",
    payload: resp,
  };
};

export const setProcessCampaign = (updating, sending) => {
  return {
    type: "SET_PROCESS_CAMPAIGN",
    updating,
    sending,
  };
};

export const removeCampaign = (resp) => {
  return {
    type: "REMOVE_CAMPAIGN",
    payload: resp,
  };
};

export const duplicateCampaign = (resp) => {
  return {
    type: "DUPLICATE_CAMPAIGN",
    payload: resp,
  };
};

export const fetchCampaigns = (resp) => {
  return {
    type: "FETCH_CAMPAIGNS",
    payload: resp,
  };
};

export const fetchMembrshipClientsList = (resp) => {
  return {
    type: "FETCH_MEMBRSHIP_CLIENTS_LIST",
    payload: resp,
  };
};

export const fetchMembrshipClientsStats = (resp) => {
  return {
    type: "FETCH_MEMBRSHIP_CLIENTS_STATS",
    payload: resp,
  };
};

export const setEmailPaginationPage = (pageNumber) => {
  return {
    type: "SET_EMAIL_PAGINATION_PAGE",
    pageNumber,
  };
};

export const setSmsPaginationPage = (pageNumber) => {
  return {
    type: "SET_SMS_PAGINATION_PAGE",
    pageNumber,
  };
};

export const setEmailPageSize = (emailPageSize) => {
  return {
    type: "SET_EMAIL_PAGE_SIZE",
    emailPageSize,
  };
};

export const setSmsPageSize = (smsPageSize) => {
  return {
    type: "SET_SMS_PAGE_SIZE",
    smsPageSize,
  };
};

export const initCampaignListFilter = () => {
  return {
    type: "INIT_CAMPAIGN_LIST_FILTER",
  };
};

export const setCampaignListFilterStatus = (status) => {
  return {
    type: "SET_CAMPAIGN_LIST_FILTER_STATUS",
    status,
  };
};

export const setCampaignListFilterLanguage = (language) => {
  return {
    type: "SET_CAMPAIGN_LIST_FILTER_LANGUAGE",
    language,
  };
};

export const setCampaignListFilterSubject = (subject) => {
  return {
    type: "SET_CAMPAIGN_LIST_FILTER_SUBJECT",
    subject,
  };
};

export const setCampaignListFilterType = (typeC) => {
  return {
    type: "SET_CAMPAIGN_LIST_FILTER_TYPE",
    typeC,
  };
};

export const setCampaignListFilterRecipientType = (typeR) => {
  return {
    type: "SET_CAMPAIGN_LIST_FILTER_RECIPIENT_TYPE",
    typeR,
  };
};

export const setCampaignListFilterCampaignName = (campaignName) => {
  return {
    type: "SET_CAMPAIGN_LIST_FILTER_CAMPAIGN_NAME",
    campaignName,
  };
};
export const setCampaignListSortConfig = (columnKey, sortDirection) => {
  return {
    type: "SET_CAMPAIGN_LIST_SORT_CONFIG",
    config: {
      columnKey: columnKey,
      sortDirection: sortDirection,
    },
  };
};
export const initRecipients = () => {
  return {
    type: "INIT_CAMPAIGN_RECIPIENTS",
  };
};
export const fetchRecipients = (resp) => {
  return {
    type: "FETCH_CAMPAIGN_RECIPIENTS",
    payload: resp,
  };
};
export const setRecipientsPaginationPage = (pageNumber) => {
  return {
    type: "SET_RECIPIENTS_PAGINATION_PAGE",
    pageNumber,
  };
};

export const setRecipientsPageSize = (pageSize) => {
  return {
    type: "SET_RECIPIENTS_PAGE_SIZE",
    pageSize,
  };
};

export const setRecipientsListFilterName = (name) => {
  return {
    type: "SET_RECIPIENTS_LIST_FILTER_NAME",
    name,
  };
};

export const setCampaignListFilterDateRange = (dateRange) => {
  return {
    type: "SET_CAMPAIGN_LIST_FILTER_DATE_RANGE",
    dateRange,
  };
};

export const initCampaignRecipientsFilter = () => {
  return {
    type: "INIT_CAMPAIGN_RECIPIENTS_FILTERS",
  };
};

export const registerEmail = (resp) => {
  return {
    type: "REGISTER_EMAIL",
    payload: resp,
  };
};

export const fetchEmails = (resp) => {
  return {
    type: "FETCH_EMAILS",
    payload: resp,
  };
};
export const setCampaignStatus = (resp) => {
  return {
    type: "SET_CAMPAIGN_STATUS",
    payload: resp,
  };
};

export const fetchPhoneNumbers = (resp) => {
  return {
    type: "FETCH_PHONE_NUMBERS",
    payload: resp,
  };
};

export const getApproximateCost = (resp) => {
  return {
    type: "GET_SMS_APPROXIMATE_COST",
    payload: resp,
  };
};

export const getClientAuthorizedBalance = (resp) => {
  return {
    type: "GET_CLIENT_AUTHORIZED_SMS_BALANCE",
    payload: resp,
  };
};

export const getClientRealCredit = (resp) => {
  return {
    type: "GET_CLIENT_REAL_SMS_CREDIT",
    payload: resp,
  };
};

export const initCampaignListItems = () => {
  return {
    type: "INIT_CAMPAIGN_LIST_ITEMS",
  };
};

export const completeCampaign = (payload) => {
  return {
    type: "COMPLETE_CAMPAIGN",
    payload,
  };
};

export const fetchCampaignBroadcasters = (payload) => {
  return {
    type: "FETCH_CAMPAIGN_BROADCASTERS",
    payload,
  };
};

export const fetchCampaignEmails = (payload) => ({
  type: "FETCH_CAMPAIGN_EMAILS",
  payload,
});

export const changeScheduledAtDate = (payload) => {
  return {
    type: "CHANGE_SCHEDULED_AT_DATE",
    payload,
  };
};
export const setIsUaVersion = (isUaVersion) => {
  return {
    type: "SET_IS_UA_VERSION",
    isUaVersion,
  };
};
export const setUaRecipientsTotalCount = (uaRecipientsTotalCount) => {
  return {
    type: "SET_UA_RECIPIENTS_TOTAL_COUNT",
    uaRecipientsTotalCount,
  };
};

export const fetchRecipientsCriteria = (payload) => {
  return {
    type: "FETCH_RECIPIENT_CRITERIA",
    payload,
  };
};

export const fetchLastSentCampaign = (payload) => {
  return {
    type: "FETCH_LAST_SENT_CAMPAIGN",
    payload,
  };
};
export const fetchCountsRecipientsByTypes = (payload) => {
  return {
    type: "FETCH_COUNTS_RECIPIENTS_BY_TYPES",
    payload,
  };
};

export const fetchTotalRecipients = (resp) => {
  return {
    type: "FETCH_CAMPAIGN_TOTAL_RECIPIENTS",
    payload: resp,
  };
};
export const fetchDelegationTypes = (resp) => {
  return {
    type: "FETCH_DELEGATION_TYPE",
    payload: resp,
  };
};
export const deleteBlacklistedEmail = (resp) => {
  return {
    type: "DELETE_BLACKLISTED_EMAIL",
    payload: resp,
  };
};
