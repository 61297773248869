import React, { Component } from 'react'
import { EBOX_SMS_STATUS } from 'constants';

import _ from 'i18n';

class StatusFilterSMS extends Component {

  render() {
    const { selected, onClick } = this.props;
    return (
      <div className="settings-filter__status">
        {EBOX_SMS_STATUS.map(
          ({ key, value, color }) =>
            <span key={key}
              onClick={() => onClick(value)}
              className={`${selected === value ? "active" : ""}`}
              style={{ borderColor: color }}>
              {_(key)}
            </span>)
        }
      </div>
    )
  }
}

export default StatusFilterSMS;
