import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'i18n';
import { setThemeLanguage, initTemplates } from 'actions';
import { fetchTemplates } from 'thunks';
import { TEMPLATE_TYPES } from 'constants';
import ThemePicker from './ThemePicker';
import { SettingTheme } from './Theme';
import { ucFirst } from 'utils';

class ThemesSettings extends Component {

    state = {
        activeLng: null,
    }

  componentDidMount() {
    const { initThemes } = this.props;
    initThemes();
  }

  componentDidUpdate(prevProps, prevState) {
    const filter = { ...this.props.filter };
    if ((this.state.activeLng && !prevState.activeLng) || (this.state.activeLng && prevProps.filter !== this.props.filter)) {
      filter.type = [TEMPLATE_TYPES['SIMPLE'], TEMPLATE_TYPES['WRAPPER']];
      filter.scope = (!filter.scope || !filter.scope.length || filter.scope !== "TAMTAM") ? "COMMUNITY" : filter.scope;
      this.props.fetchThemes(filter);
    }
  }

  setActiveLng = (activeLng) => {
    const { setThemeLanguage, initThemes } = this.props;
    initThemes();
    this.setState({ activeLng: null });
    if (activeLng === this.state.activeLng) {
      return setThemeLanguage(null);
    }
    setTimeout(() => {
      this.setState({ activeLng });
    }, 100);
    const expander = document.getElementById('theme-picker');
    if (expander) {
      expander.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setThemeLanguage(activeLng);
  }

  applyTheme = (theme) => {
    const { activeLng } = this.state;
    if (!activeLng) {
      return;
    }
    const data = {};
    data[`theme${ucFirst(activeLng)}`] = theme;
    this.props.onApply(data);
  }


  removeAppliedTheme = (theme, lng) => {
    const data = {};
    data[`theme${ucFirst(lng)}`] = theme;
    this.props.onApply(data);
  }

  render() {
    const { activeLng } = this.state;
    const { languages, activeLanguage, isDefaultThemes } = this.props;
    return (
      <div>
        {isDefaultThemes ? <div className="row medium-10 title">
            <h3>{_("default model")}</h3>
            <p>{_("Select a model to apply for each language")}</p>
          </div> :
          <div className="row medium-12 title">
            <h3>{_("Select a model to apply for each language")}</h3>
          </div>}
        <div className="row align-center">
          {
            languages.map(lng => (
              <SettingTheme
                key={`theme-${lng}`}
                active={activeLng === lng && activeLanguage}
                language={lng}
                theme={this.props[`theme${ucFirst(lng)}`]}
                clicked={() => this.setActiveLng(lng)}
                onRemove={this.removeAppliedTheme}
              />)
            )
          }
        </div>
        {activeLanguage && <ThemePicker
          language={activeLng}
          selectedTheme={activeLng ? this.props[`theme${ucFirst(activeLng)}`] : null}
          onSelect={this.applyTheme}
        />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filter: state.themes.filter,
  activeLanguage: state.themes.filter.language,
});
const mapDispatchToProps = (dispatch) => ({
  fetchThemes: (filter) => dispatch(fetchTemplates(filter)),
  setThemeLanguage: (lng) => dispatch(setThemeLanguage(lng)),
  initThemes: () => dispatch(initTemplates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemesSettings);
