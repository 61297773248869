import React from "react";
import SampleSelector from "../../editor/SampleSelector";
import { connect } from "react-redux";
import { setCurrentCampaignTheme, setTTPDialogCustomData, setCurrentDndTheme, setThemeIsSaved } from "actions";
import _ from "i18n";

class WrapperSelector extends React.Component {

  componentDidMount() {
    const { childRef, currentTheme, proceed } = this.props;
    if (childRef) {
      childRef({ secondaryAction: () => currentTheme ? proceed() : this.loadWrapper(null) });
    }
  }

  loadWrapper = (wrapper) => {
    const { proceed, setCurrentCampaignTheme, setCurrentDndTheme, setThemeIsSaved } = this.props;
    setCurrentCampaignTheme(wrapper);
    setCurrentDndTheme({ ...wrapper });
    setThemeIsSaved(!!(wrapper && wrapper.id));
    proceed(2);
  };

  handleConfirmTheme = (wrapper) => {
    const { currentTheme, setTTPDialogCustomData } = this.props;

    if (currentTheme && (currentTheme.id !== wrapper?.id)) {
      setTTPDialogCustomData({
        'approvedAction': () => this.loadWrapper(wrapper),
        'message': _('confirmSwitchTheme'),
        'title': "Confirmation of switching models"
      }
      )
    }
    else {
      this.loadWrapper(wrapper);
    }
  }

  render() {
    return <SampleSelector type={["WRAPPER", "SIMPLE"]} onSelect={this.handleConfirmTheme} addEmptyIcon={"pencil-square.svg"} currentTheme={this.props.currentTheme?.id} />;
  }
}

const mapStateToProps = store => ({
  currentTheme: store.currentCampaign.email.theme
});

const mapDispatchToProps = dispatch => ({
  setCurrentCampaignTheme: theme => dispatch(setCurrentCampaignTheme(theme)),
  setTTPDialogCustomData: data => dispatch(setTTPDialogCustomData(data)),
  setCurrentDndTheme: theme => dispatch(setCurrentDndTheme(theme)),
  setThemeIsSaved: isSaved => dispatch(setThemeIsSaved(isSaved)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapperSelector);
