import React, { Component } from "react";
import { connect } from "react-redux";
import { CAMPAIGN_LANGUAGE } from "Common";
import { getDate } from "utils";
import _ from "i18n";
import SummaryFetching from "../fetching/stats/SummaryFetching";
import FiltersModal from "./FiltersModal";
import { formatNumber } from "../../services/utils";
import { fetchRecipientsStats } from "thunks";

@connect((store) => {
  return {
    campaign: store.currentCampaign.email,
    fetching: store.currentCampaign.email.fetching,
    lng: store.params.lng,
  };
})
export default class Summary extends Component {
  constructor(props) {
    super(props);
    this.toggleFiltersModal = () =>
      this.setState({
        showFiltersModal: !this.state.showFiltersModal,
      });

    this.state = {
      showFiltersModal: false,
    };
  }

  downloadCSV = async () => {
    const {
      dispatch,
      exportCompaignStats,
      match: { params: routeParams },
    } = this.props;
    await dispatch(fetchRecipientsStats(routeParams.campaignId, 1));
    exportCompaignStats();
  };

  render() {
    const {
      fetching,
      campaign: {
        status,
        language,
        from,
        fromName,
        type,
        totalRecipients,
        recipientType,
        sentAt,
        createdAt,
        filters,
      },
      lng,
      showSentAt,
      recepientsExist,
    } = this.props;
    const { showFiltersModal } = this.state;

    return (
      <div className="w-40 stats-page__block">
        {fetching ? (
          <SummaryFetching />
        ) : (
          <ul className="summary">
            <li>
              <div>{_("status")}:</div>
              <div>{status === "CREATED" ? _("READY") : _(status)}</div>
            </li>
            <li>
              <div>{_("fromEmail")}:</div>
              <div>{from}</div>
            </li>
            <li>
              <div>{_("fromName")}:</div>
              <div>{fromName}</div>
            </li>
            <li>
              <div>{_("to")}:</div>
              <div>
                {totalRecipients ? formatNumber(totalRecipients) : 0}
                <span>
                  {recipientType === "MANUAL"
                    ? ""
                    : " " + _(recipientType).toLowerCase()}
                  {recipientType !== "MANUAL" && totalRecipients > 1 && "s"}
                </span>
              </div>
            </li>
            <li>
              <div>{_("type")}:</div>
              <div className="text-uppercase">{_(type)}</div>
            </li>
            <li>
              <div>{_("language")}:</div>
              <div className="text-uppercase">
                {_(CAMPAIGN_LANGUAGE[language])}
              </div>
            </li>
            <li>
              <div>{_("createdAt")}:</div>
              <div className="text-uppercase">
                {createdAt ? getDate(createdAt, lng) : "_"}
              </div>
            </li>
            {showSentAt && (
              <li>
                <div>{_("Sent at")}:</div>
                <div className="text-uppercase">{getDate(sentAt, lng)}</div>
              </li>
            )}
            {filters?.length > 0 && (
              <li>
                <div>{_("target")}:</div>
                <div
                  onClick={this.toggleFiltersModal}
                  className="flex-container"
                >
                  {_("view")}
                  <i className="icon-right icon-share-alt" />
                </div>
              </li>
            )}
            {recepientsExist && (
              <li>
                <div>{_("DownloadCSV")}:</div>
                <div onClick={this.downloadCSV} className="flex-container">
                  {_("Download")}
                  <i className="icon-right icon-cloud-download" />
                </div>
              </li>
            )}
          </ul>
        )}
        {showFiltersModal && (
          <FiltersModal toggleFiltersModal={this.toggleFiltersModal} />
        )}
      </div>
    );
  }
}
