import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import { initFormations, setFormationsPaginationPage } from "actions";
import { fetchEventFormation } from "thunks";

import FormationItem from "./FormationItem";
import NotFound from "notFound/NotFound";
import FormationItemFetching from "fetching/blocks/FormationItemFetching";
import FormationsListFilter from "./FormationsListFilter";

const mapStateToProps = (state) => ({
  formations: state.filters.formations.items,
  filter: state.filters.formations.filter,
  nbResult: state.filters.formations.nbResult,
  fetching: state.filters.formations.fetching,
  eventFetching: state.filters.formations.fetching,
  viewMode: state.themes.newsletter.viewMode,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventFormations: (trainingType, eventId, searchWord, languages, tags) =>
    dispatch(
      fetchEventFormation(trainingType, eventId, searchWord, languages, tags)
    ),
  initFormations: () => dispatch(initFormations()),
  setFormationsPaginationPage: (page) =>
    dispatch(setFormationsPaginationPage(page)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class FormationsList extends Component {
  state = {
    hasMoreItems: true,
  };

  componentDidMount() {
    this.props.initFormations();
    this.props.fetchEventFormations("future");
  }

  componentDidUpdate(prevProps) {
    const { filter, fetchEventFormations, fetching } = this.props;

    if (filter !== prevProps.filter) {
      fetchEventFormations(
        filter.trainingType,
        null,
        filter.searchWord,
        filter.language?.join(","),
        filter.tags?.join(",")
      );
    }

    if (fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !fetching,
      });
    }
  }

  componentWillUnmount() {
    this.props.initFormations();
  }

  loadItems = () => {
    const {
      filter,
      fetching,
      nbResult,
      formations,
      setFormationsPaginationPage,
    } = this.props;

    if (!fetching && formations.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      setFormationsPaginationPage(currentPage + 1);
    }
  };

  render() {
    const {
      formations,
      fetching,
      eventFetching,
      viewMode,
      showSelected,
      selectedFeeds,
    } = this.props;

    const { hasMoreItems } = this.state;

    const listMode = viewMode === "LIST";

    let items = [];

    {
      selectedFeeds.length > 0 &&
        selectedFeeds.map((formation, index) => (
          <FormationItem
            viewMode={viewMode}
            order={index}
            key={`formation-${index}`}
            isSelected
            speakers={formation.speakers}
            event={formation}
            {...formation}
          />
        ));
    }

    if (formations && formations.length > 0) {
      items.push(
        formations
          .filter(({ id }) => !selectedFeeds.map((el) => el.id).includes(id))
          .map((event, index) => {
            const order = selectedFeeds.indexOf(event.id);

            return showSelected || order < 0 ? (
              <FormationItem
                viewMode={viewMode}
                order={order}
                key={`formation-${index}`}
                isSelected={selectedFeeds.includes(event.id)}
                speakers={event["speakers-abstract"].speakers}
                event={event}
                {...event}
              />
            ) : null;
          })
      );
    }

    if (fetching || eventFetching) {
      let itemNbr = listMode ? 5 : 10;
      for (let i = 0; i < itemNbr; i++) {
        items.push(
          <div
            className={`medium-${listMode ? 12 : 6} column`}
            style={{ order: 1001 }}
          >
            <FormationItemFetching key={`fetch-formation-${i}`} />
          </div>
        );
      }
    }

    let showFormations = items && items.length > 0;

    return (
      <div className="medium-12">
        <div
          id="feeds-container"
          style={{ height: `${0.9 * window.innerHeight - 20}px` }}
          ref={(ref) => (this.scrollParentRef = ref)}
          className="column"
        >
          <FormationsListFilter />

          <div className="feeds-list">
            {showSelected && selectedFeeds?.length > 0 && (
              <div className={"masonry-separator medium-12"} />
            )}

            {selectedFeeds?.length > 0 &&
              selectedFeeds.map((formation, index) => (
                <FormationItem
                  viewMode={viewMode}
                  order={index}
                  key={`formation-${index}`}
                  isSelected
                  speakers={formation.speakers}
                  event={formation}
                  {...formation}
                />
              ))}
          </div>

          {showFormations ? (
            <InfiniteScroll
              className={"feeds-list"}
              pageStart={0}
              loadMore={this.loadItems}
              useWindow={false}
              hasMore={hasMoreItems}
              getScrollParent={() => this.scrollParentRef}
            >
              {items}
            </InfiniteScroll>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    );
  }
}
