import React, { Component } from 'react';
import SearchBox from './SearchBox';
import OwnerDetails from './OwnerDetails';

export default class Explorer extends Component {

    render() {
        return (
            <div className="ebox-explorer row small-10">
                <SearchBox />
                <OwnerDetails />
            </div>
        );
    }
}