import React, { Component } from 'react';
import { connect } from 'react-redux';

import { validateCreditRequest } from 'thunks';
import { onError, onSuccess } from 'utils';
import _ from 'i18n';
import { setTTPDialogCustomData } from "actions";
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import DIALOG_TYPES from '../../../constants/TTPDialog';

@connect()
export default class SMSCreditRequests extends Component {

  constructor(props) {
    super(props);
    this.state = { creditRequestId: null };
  };

  handleValidateClick = (creditRequestId) => {
    this.setState({ creditRequestId: creditRequestId });
    const { dispatch } = this.props;
    dispatch(setTTPDialogCustomData({
      'approvedAction': this.validateCreditRequest.bind(this, "VALIDATED"),
      'message': _('validationCreditRequestConfirm'),
      'proceedBtnLabel': _('validate'),
      'type': DIALOG_TYPES["SUCCESS"],
      'title': "Confirmation of validation",
    }));
  }

  handleRejectClick = (creditRequestId) => {
    this.setState({ creditRequestId: creditRequestId });
    const { dispatch } = this.props;
    dispatch(setTTPDialogCustomData({
      'approvedAction': this.validateCreditRequest.bind(this, "REJECTED"),
      'message': _('rejectionCreditRequestConfirm'),
      'proceedBtnLabel': _('Reject'),
      'type': DIALOG_TYPES["ALERT"],
      'title': "Confirmation of rejection",
    }));
  }

  validateCreditRequest(status) {
    const { dispatch } = this.props;
    let creditRequestId = this.state.creditRequestId;
    let message = "";
    (status === "VALIDATED") ? message = { body: 'creditRequestValidated', title: 'validateCreditSuccess' } :
      message = { body: 'creditRequestRejected', title: 'rejectCreditSuccess' };
    dispatch(validateCreditRequest(creditRequestId, status)).then(res => onSuccess(res, message), err => onError(err));
  }

  renderRequestStatus(requestStatus) {
    let icon = "";
    switch (requestStatus) {
      case 'REJECTED':
        icon = <i className="icon-close"/>;
        break;
      case 'VALIDATED':
        icon = <i className="icon-check"/>;
        break;
      case 'PENDING':
        icon = <i className="icon-clock"/>;
        break;
    }
    return (<span> {icon} {_(requestStatus)}</span>);
  }

  render() {
    let { items } = this.props;
    return (<div className="row small-10 align-center">
      <div className="ttp-datatable small-12 small-centered columns">
        <div className="row ttp-datatable__header">
          <div className="small-3 padding-left">{_('date')}</div>
          <div className="small-3">{_('client name')}</div>
          <div className="small-2">{_('Credit')}</div>
          <div className="small-2">{_('status')}</div>
          <div className="small-2">{_('actions')}</div>
        </div>
        {items.map(item => <div className="row ttp-datatable__row">
          <div className="small-3 padding-left">{item.createdAt}</div>
          <div className="small-3">{item.client['abbreviation'] != null ? item.client['abbreviation'] : item.client['name']}</div>
          <div className="small-2">€ {item.amount}</div>
          <div className="small-2">{this.renderRequestStatus(item.status)}</div>
          <div className="small-2">{item.status === 'PENDING' ?
            <span><Link to={{}} data-for={"validate"} data-tip={_('validate')} activeClassName="active" onClick={() => this.handleValidateClick(item.id)}
              className="list__row-action-cell-button"><i className="icon-check"/></Link>
              <Link to={{}} data-for={"reject"} data-tip={_('Reject')} activeClassName="active" onClick={() => this.handleRejectClick(item.id)}
                className="list__row-action-cell-button"><i className="icon-close"/></Link></span> : '-'}
            <ReactTooltip id={"validate"} type='dark' place="left" />
            <ReactTooltip id={"reject"} type='dark' place="left" />
          </div>
        </div>)
        }
      </div>
    </div>);
  }

}
