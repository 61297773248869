import React, { Component } from 'react';
import { connect } from "react-redux";
import LineChartComponent from './LineChartComponent';
import _ from 'i18n';

class SubscribersEvoultionChart extends Component {
  render() {
    const {dataSubscribers} = this.props;
    const dataKeysY = [
      { key: "subscribersNumber", label: "le nombre d'abonnées"},
      { key: "unsubscribersNumber", label: "le nombre de désabonnées"}
    ];
    const yAxisTitle = "Number of subscribers / unsubscribers";
    const dataKeyX ={ key: "date", label: "date" };
    return (
      <LineChartComponent
        dataChart={dataSubscribers}
        dataKeysY = {dataKeysY}
        dataKeyX = {dataKeyX}
        yAxisTitle = {yAxisTitle}
        isEvolutionTooltip = {true}
      />
    );
  }
}
const mapStateToProps = (store) => ({
    dataSubscribers: store.dashboard.dataSubscribers
});
export default connect(mapStateToProps)(SubscribersEvoultionChart);
