import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from "lodash.debounce";

import { CAMPAIGN_STATUS, LIST_PAGE_SIZES} from 'Common';
import {setCampaignListFilterStatus, setCampaignListFilterLanguage, initCampaignListFilter, setSmsPageSize, setCampaignListFilterCampaignName, setCampaignListFilterDateRange} from "actions";
import _ from 'i18n';
import { getLanguages } from 'utils';
import TTPFilterHorizontalBar from 'common/filter/TTPFilterHorizontalBar';

@connect((store) => {
    return {
        campaignsFilter: store.campaigns.list.filter,
        smsPageSize: store.campaigns.list.smsPageSize,
    }
})
export default class CampaignSMSListFilter extends Component {

    constructor(props) {
        super(props);

        this.handleSearchInputChange = debounce(this.handleSearchInputChange, 1000);

        this.statusOptions = CAMPAIGN_STATUS.map(({label, value, color}) => {
            return { label: _(label), value, color }
        })

        this.languageOptions = getLanguages().map(lng => {
            return { value: lng.key, label: _(lng.value) }
        })
    };

    handleCampaignListPageSizeChange = (inputValue) => {
        this.props.dispatch(setSmsPageSize(inputValue));
    };

    handleSelectStatus = (selected) => {
        this.props.dispatch(setCampaignListFilterStatus(selected));
    };

    handleSelectLanguage = (selected) => {
        this.props.dispatch(setCampaignListFilterLanguage(selected));
    };

    handleResetFilter = () => {
        this.props.dispatch(setSmsPageSize(LIST_PAGE_SIZES[0]));
        this.props.dispatch(initCampaignListFilter());
    };

    handleSearchInputChange(word) {
        this.props.dispatch(setCampaignListFilterCampaignName(word));
    };

    handleDateRangeChange = (dateRange) => {
        this.props.dispatch(setCampaignListFilterDateRange(dateRange));
    }

    render () {
        let {campaignsFilter, smsPageSize} = this.props;

        return (
            <div className="row align-center medium-11">
                <TTPFilterHorizontalBar
                    filters={[
                        { placeholder: _("language"), options: this.languageOptions, values: campaignsFilter.language, action: this.handleSelectLanguage },
                    ]}
                    searchInputValue={campaignsFilter.campaignName}
                    pageSize={smsPageSize}
                    handleResetFilter={this.handleResetFilter}
                    handleListPageSizeChange={this.handleCampaignListPageSizeChange}
                    handleSearchInputChange={this.handleSearchInputChange.bind(this)}
                    handleDateRangeChange={this.handleDateRangeChange}
                    dateRange={campaignsFilter.dateRange}
                    cssClass="medium-12"
                    selectedTab={campaignsFilter.status || null}
                    onTabChange={this.handleSelectStatus}
                    tabs={this.statusOptions}
                />
            </div>
        );
    }
};
