import React, { Component } from "react";
import { MENUS } from "Common";
import { connect } from "react-redux";
import { setSubMenuDirection } from "actions";
import { HORIZONTAL, VERTICAL } from "constants";
import { STEPS } from "../../config/Common";
import EmailingMenu from "./sub-menu/emailing/EmailingMenu";
import EboxMenu from "./sub-menu/ebox/EboxMenu";
import SmsMenu from "./sub-menu/sms/SmsMenu";
import NotificationMenu from "./sub-menu/notification/NotificationMenu";

@connect((store) => {
  return {
    settings: store.auth.currentSetting,
  };
})
class SubMenu extends Component {
  handleClick = () =>
    this.props.setSubMenuDirection(
      this.props.subMenuDirection === VERTICAL ? HORIZONTAL : VERTICAL
    );

  render() {
    const {
      auth: { loggedAs, user, preference, detached },
      settings: { enableDashboard },
      subMenuDirection,
      step,
      application,
      stickyMenu,
    } = this.props;
    const menuItems = MENUS[loggedAs];

    if (
      user == null ||
      user.id == null ||
      !loggedAs ||
      loggedAs == "GUEST" ||
      user?.selectedOrganization?.emailingPreferences?.accessType == "UA"
    ) {
      return null;
    }

    let hideMenu =
      [
        STEPS["MANAGE_THEME"],
        STEPS["THEME_PREVIEW"],
        STEPS["SELECT_NEWS"],
        STEPS["MANAGE_TEXT_MODEL"],
        "HIDE_MENU",
      ].indexOf(step) != -1;
    if (hideMenu) return null;

    const allowEmailCreation =
      "ADMIN" == loggedAs ||
      ("USER" == loggedAs &&
        preference &&
        preference["allowCreateCampaign"] == 1) ||
      detached;

    let menu = (
      <EmailingMenu
        allowCreation={allowEmailCreation}
        items={menuItems}
        allowDashboardPage={enableDashboard}
      />
    );
    let visibleItems = menuItems.length - 4;
    switch (application) {
      case "EBOX":
        menu = <EboxMenu items={menuItems} clients={user.clients} />;
        visibleItems = 1;
        break;
      case "SMS":
        const allowSmsCreation =
          "ADMIN" == loggedAs ||
          ("USER" == loggedAs &&
            preference &&
            preference["allowCreateSms"] == 1);
        menu = <SmsMenu allowCreation={allowSmsCreation} items={menuItems} />;
        break;
      case "NOTIFICATION":
        menu = <NotificationMenu items={menuItems} />;
        break;
    }
    return (
      <nav
        ref="subMenu"
        id="subMenu"
        className={`sub-menu ${
          subMenuDirection === VERTICAL ? "vertical" : ""
        } ${stickyMenu ? "fixed" : ""}`}
      >
        {visibleItems > 0 && (
          <span
            className="flip-menu flex-column-center"
            onClick={this.handleClick}
          >
            <i className="icomoon icon-tt-menu" />
          </span>
        )}
        {menu}
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  subMenuDirection: state.params.subMenuDirection,
  step: state.params.step,
  application: state.params.application,
});

const mapDispatchToProps = (dispatch) => ({
  setSubMenuDirection: (direction) => dispatch(setSubMenuDirection(direction)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
