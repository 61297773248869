import dialog from "suneditor/src/plugins/modules/dialog";
import _ from "i18n";
import moment from 'moment';
import React from "react";

export let customFreesection = {
  name: "customFreeSection",
  display: "dialog",
  title: _("free section"),
  buttonClass: "se-btn-select se-btn-tool-format se-btn-custom",
  innerHTML: `<div class="custom-container"><i class="icon icon-note"></i> <span>${_("free section")}</span></div>`,

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add: function (core) {
    core.addModule([dialog]);

    const context = core.context;

    context.customFreeSection = {
      name: null,
      question: null,
      formatSelect: null,
      options: [],
      _freeSectionSpan: null
    };

    /** free section dialog */
    let fs_dialog = this.setDialog(core);
    context.customFreeSection.modal = fs_dialog;
    context.customFreeSection.name = fs_dialog.querySelector("._se_fs_name");
    context.customFreeSection.question = fs_dialog.querySelector("._se_fs_question");
    context.customFreeSection.formatSelect = fs_dialog.querySelector('.se-input-select');
    context.customFreeSection.options = Object.values(fs_dialog.querySelectorAll('._se_fs_opt'));


    /** free section controller */
    let fs_controller = this.setController_fsButton(core);
    context.customFreeSection.fsController = fs_controller;
    context.customFreeSection._freeSectionSpan = null;

    /** add event listeners */
    fs_dialog.querySelector("form").addEventListener("submit", this.submit.bind(core));
    fs_dialog.querySelector('._se_fs_add').addEventListener("click", this.addItem.bind(core));
    fs_dialog.querySelector('._se_fs_delete').addEventListener("click", this.deleteItem.bind(core));


    fs_controller.addEventListener("click", this.onClick_fsController.bind(core));
    context.customFreeSection.formatSelect.addEventListener("change", this.onClickFormatSelectController.bind(core));


    /** append html */
    context.dialog.modal.appendChild(fs_dialog);

    /** append controller */
    context.element.relative.appendChild(fs_controller);

    /** empty memory */
    fs_dialog = null;
    fs_controller = null;
  },


  /** dialog */
  setDialog: function (core) {

    const lang = core.lang;
    const dialog = core.util.createElement("DIV");

    dialog.className = "se-dialog-content";
    dialog.style.display = "none";

    const FREE_SECTION_FORMATS = [
      {format: 'TEXT', selected: true},
      {format: 'NUMBER'},
      {format: 'CURRENCY'},
      {format: 'LIST'},
      {format: 'DATE TIME (LLLL)'},
      {format: 'DATE TIME (llll)'},
      {format: 'DATE TIME (LLL)'},
      {format: 'DATE TIME (lll)'},
      {format: 'DATE (LL)'},
      {format: 'DATE (ll)'},
      {format: 'DATE (L)'},
      {format: 'DATE (l)'},
      {format: 'TIME (LT)'},
      {format: 'TIME (LTS)'},
    ];

    const formatsOptions = FREE_SECTION_FORMATS.map(({format, selected}) => {
      let isSelected = selected ? ' selected' : '';
      const regExp = /\(([^)]+)\)/;
      let formatDate = regExp.exec(format);
      let isSelectedDateExample = formatDate ? moment().format(formatDate[1]) : '';
      if (format === "CURRENCY") {
        isSelectedDateExample = "1234.10 €";
      }

      return `<option class="se-select-option" value="${format}" ${isSelected} data-example="${isSelectedDateExample}"> ${_(format)} </option>`;
    });

    dialog.innerHTML = `
      <form class="editor_fs">
        <div class="se-dialog-header">
          <button type="button" data-command="close" class="se-btn se-dialog-close" aria-label="Close" title="${lang.dialogBox.close}">
            ${core.icons.cancel}
          </button>
          <span class="se-modal-title"> ${_("Add a free section")} </span>
        </div>
        <div class="se-dialog-body">
          <div class="se-dialog-form">
            <label> ${_("Name")} </label>
            <input class="se-input-form _se_fs_name" type="text" />
          </div>
          <div class="se-dialog-form">
            <label> ${_("Question")} </label><input class="se-input-form _se_fs_question" type="text" />
          </div>
             <div class="se-dialog-form se-select">
                  <label> ${_("Format")} </label>
                  <select class="se-input-select"  >
                       ${formatsOptions}
                  </select>
                  <div class="example-div">
                  </div>

                   <div class="options-div">
                   <span>${_("Please enter options") + ":"}</span>
                  <div id="options" class="options-scroll">
                    <div class="se-dialog-form">
                    <label id="label"> ${_("Option")} </label>
                   <input id="inputvalue" class="se-input-form _se_fs_opt" type="text" />
                  </div>
                  <div class="se-dialog-form">
                 <label id="label"> ${_("Option")} </label>
                <input id="inputvalue" class="se-input-form _se_fs_opt" type="text" />
                </div>
                 </div >
                <button type="button" class="se-btn-primary _se_fs_add" title="button">${_("Add option")}</button>
                <button type="button" class="se-btn-primary _se_fs_delete" title="button">${_("Delete option")}</button>

                 </div>
                  </div>
              </div>
        </div>
          <div class="se-dialog-footer">
          <button type="submit" class="btn primary" title="${lang.dialogBox.submitButton}"><span> ${lang.dialogBox.submitButton}</span></button>
        </div>
      </form>
     `;

    return dialog;
  },

  /** modify controller button */
  setController_fsButton: function (core) {
    const lang = core.lang;
    const icons = core.icons;
    const fs_btn = core.util.createElement("DIV");

    fs_btn.className = "se-controller";
    fs_btn.innerHTML = `
      <div class="se-arrow se-arrow-up"></div>
      <div>
        <span class="content-text"></span>
        <div class="se-btn-group d-i-g">
          <button type="button" data-command="update" tabindex="-1" class="se-tooltip">
            ${icons.edit}
            <span class="se-tooltip-inner"><span class="se-tooltip-text"> ${lang.controller.edit} </span></span>
          </button>
          <button type="button" data-command="delete" tabindex="-1" class="se-tooltip">
            ${icons.delete}
            <span class="se-tooltip-inner"><span class="se-tooltip-text"> ${lang.controller.remove} </span></span>
          </button>
        </div>
      </div>
    `;

    return fs_btn;
  },

  // @Required, @Override dialog
  // This method is called when the plugin button is clicked.
  // Open the modal window here.
  open: function () {
    this.plugins.dialog.open.call(this, "customFreeSection", "customFreeSection" === this.currentControllerName);
  },

  submit: function (e) {
    this.showLoading();

    e.preventDefault();
    e.stopPropagation();

    const submitAction = function () {
      if (this.context.customFreeSection.name.value.trim().length === 0 ||
        this.context.customFreeSection.question.value.trim().length === 0) return false;

      const contextFreeSection = this.context.customFreeSection;
      const name = contextFreeSection.name.value;
      const question = contextFreeSection.question.value;
      const selectedFormatOption = contextFreeSection.formatSelect.selectedOptions[0].value;
      const selectedOptionExample = contextFreeSection.formatSelect.selectedOptions[0].getAttribute('data-example');

      const options = contextFreeSection.options.map(function (item) {
        return item.value;
      });

      const spanText = "[[" + name.toUpperCase().replace(/ /g, "_") + "]]";

      // When opened for modification "this.context.dialog.updateModal" is true
      if (!this.context.dialog.updateModal) {
        const oSpan = this.util.createElement("span");
        oSpan.textContent = spanText;
        oSpan.dataset.format = selectedFormatOption;
        oSpan.dataset.example = selectedOptionExample;
        oSpan.dataset.options = JSON.stringify(options);
        oSpan.dataset.name = name;
        oSpan.dataset.question = question;
        oSpan.dataset.formatedName = spanText;
        oSpan.dataset.type = "FREE_SECTION";
        oSpan.setAttribute("style", "color: rgb(251, 31, 91); cursor: pointer;");
        const regExp = /\(([^)]+)\)/;
        let formatDate = regExp.exec(selectedFormatOption);
        oSpan.dataset.formatDate = formatDate ? formatDate[1] : '';

        this.insertNode(oSpan);
        this.setRange(oSpan.childNodes[0], 0, oSpan.childNodes[0], oSpan.textContent.length);
      } else {
        contextFreeSection._freeSectionSpan.textContent = spanText;
        contextFreeSection._freeSectionSpan.dataset.name = name;
        contextFreeSection._freeSectionSpan.dataset.question = question;
        contextFreeSection._freeSectionSpan.dataset.format = selectedFormatOption;
        contextFreeSection._freeSectionSpan.dataset.options = JSON.stringify(options);

        // set range
        this.setRange(contextFreeSection._freeSectionSpan.childNodes[0], 0, contextFreeSection._freeSectionSpan.childNodes[0], contextFreeSection._freeSectionSpan.textContent.length);
      }

      // history stack
      this.history.push(false);
    }.bind(this);

    try {
      submitAction();
    } finally {
      this.plugins.dialog.close.call(this);
      this.closeLoading();
      this.focus();
    }

    return false;
  },

  addItem: function () {
    const addItemAction = function () {
      const options = document.getElementById('options');
      const label = options.getElementsByTagName('label');
      const input_tags = options.getElementsByTagName('input');
      if (input_tags.length > 2 && label.length > 2) {
        options.classList.add("scrolling-div");
      }
      const dd = document.createElement('label');
      dd.setAttribute('id', 'label');
      dd.innerHTML = "Option";
      options.appendChild(dd);
      const newOption = document.createElement('input');
      newOption.setAttribute('type', 'text');
      newOption.setAttribute('name', 'options');
      newOption.setAttribute('id', 'inputvalue');
      newOption.setAttribute('class', 'se-input-form _se_fs_opt');
      newOption.setAttribute('siz', 50);
      options.appendChild(newOption);
      this.context.customFreeSection.options.push(newOption);

    }.bind(this);

    addItemAction();
  },

  deleteItem: function (e) {
    const deleteItemAction = function () {
      const options = document.getElementById('options');
      const label = options.getElementsByTagName('label');
      const input_tags = options.getElementsByTagName('input');
      if (input_tags.length > 2 && label.length > 2) {
        options.removeChild(input_tags[(input_tags.length) - 1]);
        options.removeChild(label[(label.length) - 1]);
        this.context.customFreeSection.options.pop();
      }
    }.bind(this);

    deleteItemAction();
  },

  // @Override core
  // Plugins with active methods load immediately when the editor loads.
  // Called each time the selection is moved.
  active: function (element) {
    let isSpan = () => {
      return element && /^span$/i.test(typeof element === 'string' ? element : element.nodeName)
    };
    if (!element) {
      if (this.controllerArray.indexOf(this.context.customFreeSection.fsController) > -1) {
        this.controllersOff();
      }
    } else if (isSpan()) {
      if (this.controllerArray.indexOf(this.context.customFreeSection.fsController) < 0) {
        this.plugins.customFreeSection.call_controller.call(this, element);
      }
      return true;
    }

    return false;
  },

  // @Override dialog
  // This method is called just before the dialog opens.
  // If "update" argument is true, it is not a new call, but a call to modify an already created element.
  on: function (update) {
    if (!update) {
      this.plugins.customFreeSection.init.call(this);
    } else if (this.context.customFreeSection._freeSectionSpan) {
      this.context.dialog.updateModal = true;
      this.context.customFreeSection.name.value = this.context.customFreeSection._freeSectionSpan.getAttribute("data-name");
      this.context.customFreeSection.question.value = this.context.customFreeSection._freeSectionSpan.getAttribute("data-question");
      this.context.customFreeSection.formatSelect.value = this.context.customFreeSection._freeSectionSpan.getAttribute("data-format");
      let dive = document.querySelector(".options-div");
      if (this.context.customFreeSection._freeSectionSpan.getAttribute("data-format") === "LIST") {
        dive.classList.add("visible");
      } else {
        dive.classList.remove("visible");
      }
    }
  },

  call_controller: function (selectionSpanTag) {
    this.editFs = this.context.customFreeSection._freeSectionSpan = selectionSpanTag;
    const fsBtn = this.context.customFreeSection.fsController;
    const freeSection = fsBtn.querySelector("span");

    freeSection.title = selectionSpanTag.getAttribute("data-name");
    freeSection.textContent = selectionSpanTag.getAttribute("data-question");

    const offset = this.util.getOffset(selectionSpanTag, this.context.element.wysiwygFrame);
    fsBtn.style.top = (offset.top + selectionSpanTag.offsetHeight + 10) + "px";
    fsBtn.style.left = (offset.left - this.context.element.wysiwygFrame.scrollLeft) + "px";

    fsBtn.style.display = "block";

    const overLeft = this.context.element.wysiwygFrame.offsetWidth - (fsBtn.offsetLeft + fsBtn.offsetWidth);
    if (overLeft < 0) {
      fsBtn.style.left = (fsBtn.offsetLeft + overLeft) + "px";
      fsBtn.firstElementChild.style.left = (20 - overLeft) + "px";
    } else {
      fsBtn.firstElementChild.style.left = "20px";
    }

    // Show controller at editor area (controller elements, function, "controller target element(@Required)", "controller name(@Required)", etc..)
    this.controllersOn(fsBtn, selectionSpanTag, "customFreeSection");
  },

  onClickFormatSelectController: function (e) {
    let dive = document.querySelector(".options-div");
    if (e.target.value === "LIST") {
      dive.classList.add("visible");
      dive.querySelector("#options").innerHTML = "";
      for (let i = 0; i < 2; i++) {
        this.plugins.customFreeSection.addItem.call(this);
      }
    } else {
      dive.classList.remove("visible");
    }
    const selectedDateExample = e.target.selectedOptions[0].getAttribute("data-example");
    let dateExampleDiv = document.querySelector(".example-div");

    if (!selectedDateExample) {
      dateExampleDiv.classList.add("hide");
      dateExampleDiv.innerHTML = "";
    } else {
      dateExampleDiv.classList.remove("hide");
      dateExampleDiv.innerHTML = `<span> ${_('example')} : ${selectedDateExample}</span>`;
    }

    e.stopPropagation();
  },

  onClick_fsController: function (e) {
    e.stopPropagation();
    const command = e.target.getAttribute("data-command");
    if (!command) return;

    e.preventDefault();

    if (/update/.test(command)) {
      const contextFreeSection = this.context.customFreeSection;
      contextFreeSection.name.value = contextFreeSection._freeSectionSpan.getAttribute("data-name");
      contextFreeSection.question.value = contextFreeSection._freeSectionSpan.getAttribute("data-question");
      contextFreeSection.formatSelect.value = contextFreeSection._freeSectionSpan.getAttribute("data-format");

      if (this.context.customFreeSection._freeSectionSpan.getAttribute("data-format") === "LIST") {
        contextFreeSection.options = [];
        const dataOptions = JSON.parse(contextFreeSection._freeSectionSpan.getAttribute("data-options"));
        const options = document.getElementById('options');
        options.innerHTML = "";
        dataOptions.map(function (item) {
          const dd = document.createElement('label');
          dd.setAttribute('id', 'label');
          dd.innerHTML = "Option";
          options.appendChild(dd);
          const newOption = document.createElement('input');
          newOption.setAttribute('type', 'text');
          newOption.setAttribute('name', 'options');
          newOption.setAttribute('id', 'inputvalue');
          newOption.setAttribute('class', 'se-input-form _se_fs_opt');
          newOption.setAttribute('siz', 50);
          newOption.setAttribute('value', item);
          options.appendChild(newOption);
          contextFreeSection.options.push(newOption);
        });

        if (options.getElementsByTagName('input').length > 2) {
          options.classList.add("scrolling-div");
        }
      }

      this.plugins.dialog.open.call(this, "customFreeSection", true);
    } else {
      /** delete */
      this.util.removeItem(this.context.customFreeSection._freeSectionSpan);
      this.context.customFreeSection._freeSectionSpan = null;
      this.focus();

      // history stack
      this.history.push(false);
    }
  },

  // @Required, @Override dialog
  // This method is called when the dialog window is closed.
  // Initialize the properties.
  init: function () {
    const contextFreeSection = this.context.customFreeSection;
    contextFreeSection.fsController.style.display = "none";
    contextFreeSection._freeSectionSpan = null;
    contextFreeSection.name.value = "";
    contextFreeSection.question.value = "";
    contextFreeSection.formatSelect.selectedIndex = 0;
    let dateExampleDiv = document.querySelector(".example-div");
    dateExampleDiv.classList.add("hide");
    dateExampleDiv.innerHTML = "";
    let dive = document.querySelector(".options-div");
    dive.classList.remove("visible");
    dive.querySelector("#options").innerHTML = "";
    dive.querySelector("#options").classList.remove("scrolling-div");
    contextFreeSection.options = [];
  }
};
