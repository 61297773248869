import React, {Component} from "react";
import {connect} from "react-redux";
import { setFilterYear, setFilterPeriod, setFilterMonth, setDashboardFilterLanguage } from "actions";
import _ from "i18n";
import {ucFirst} from "../../services/common";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import {getLanguages} from "../../services/utils";
import {LANGUAGE_FLAGS} from "../../config/Common";

@connect((store) => {
  return {
    dateRange: store.dashboard.dateRange,
    filter: store.dashboard.filter
  };
})
export default class DashboardFilter extends Component {
  period = [ 'monthly','annual'];

  constructor(props) {
    super(props);

    this.languageOptions = getLanguages().map((lng) => {
      return {
        value: lng.key,
        label: (
          <span>
            <span className={`flag-icon  ${LANGUAGE_FLAGS[lng.key]}`}/>
            &nbsp;&nbsp;{_(lng.value)}
          </span>
        ),
      };
    });
  }

  handleYearChange = (year) => {
    this.props.dispatch(setFilterYear(year));
  };

  handleMonthChange = (month) => {
    this.props.dispatch(setFilterMonth(month));
  };

  handleSelectRecipientType = (selected) => {
    this.props.dispatch(setRecipientTypeFilter(selected));
  };

  handleSelectLanguage = (selected) => {
    this.props.dispatch(setDashboardFilterLanguage(selected?.value));
  };

  handlePeriodChange = (selected) => {
    this.props.dispatch(setFilterPeriod(selected));
  };

  render() {
    let {filter} = this.props;
    let {period, month, year} = filter;
    let FullYear = new Date(Date.parse(year));

    return (
      <div className="m-b-xs m-r-xs dashboard_filter">
        <div className="dashboard_filter__container medium-12 row">
          <div className="ttp-dateRange m-l-s">
            {this.period.map((periodItem, index) =>
              <span key={index}
                    className={`ttp-dateRange__item ${period === periodItem ? "is-active" : "is-inactive"} `}
                    onClick={() => this.handlePeriodChange(periodItem)}>
                {ucFirst(_(periodItem))}
               </span>
            )}
          </div>
          <div className="filter_date m-r-m m-l-m">
            <div className="single-date-picker">
              {period === "monthly" && <DatePicker
                selected={month}
                onChange={this.handleMonthChange}
                dateFormat="MMMM"
                showMonthYearPicker
              />}
              {period === "annual" && <DatePicker
                selected={FullYear}
                onChange={this.handleYearChange}
                dateFormat="yyyy"
                showYearPicker
                yearItemNumber={6}
              />}
            </div>
          </div>
          <Select
            isSearchable={false}
            isClearable={true}
            placeholder={_("language")}
            options={this.languageOptions}
            className='filter-select medium-3'
            classNamePrefix="ttp-select"
            onChange={this.handleSelectLanguage}
          />
        </div>
      </div>
    );
  }
}
