import React from "react";
import {saveAs} from "file-saver";

export * from "./campaign";
export * from "./auth";
export * from "./user";
export * from "./common";
export * from "./date";
export * from "./block/theme";
export * from "./article";
export * from "./filter";
export * from "./colors";
export * from "./svgIcones";
export * from "./email";
export * from "./mapper";
export * from "./validation";
export * from "./misc";

import _ from "i18n";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {BE_BASIC_SMS_PRICE, DEFAULT_CLIENT_MARGE} from "../config/Common";
import {ucFirst} from "./common";
import {SVG_MY_MODELS_ICON, SVG_LIBRARY_ICON} from "./svgIcones";
import {
  COMMUNITY_SCOPE,
  SHARED_MODELS_SCOPE,
  TAMTAM_SCOPE,
} from "../constants";
import moment from "moment";
import {write, utils, SSF} from "xlsx";
import CryptoJS from "crypto-js";
import {URL_HASH_KEY} from "../config";
import {getUserNameForAvatar} from "utils";

export function onSuccess(res, message = null) {
  const body = message && message.body ? message.body : "operationCompleted";
  const title = message && message.title ? message.title : "successfulAction";
  if (res.value instanceof Error) {
    NotificationManager.error(_("incompleteOperation"), _("error"));
  } else {
    NotificationManager.success(_(body), _(title));
  }
}

export function onError() {
  NotificationManager.error(_("errorOccured"), _("error"));
}

export function convertToStringTag(replyTo = []) {
  let tags = [];

  for (let i = 0; i < replyTo.length; i++) {
    let tag = replyTo[i].email;
    if (replyTo[i].name) {
      tag = replyTo[i].name + " <" + replyTo[i].email + ">";
    }
    tags.push(tag);
  }

  return tags;
}

export const modalStyle = {
  overlay: {
    zIndex: 9,
    backgroundColor: "hsla(0,0%,4%,.45)",
  },
  content: {
    top: "50%",
    left: "50%",
    width: "60%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    transition: "opacity 0.4s ease-out 0s",
    border: "none",
    borderRadius: 0,
    paddingBottom: 0,
    overflow: "scroll",
  },
};

export function throwCatchedError(thrown) {
  if (axios.isCancel(thrown)) {
    throw {
      response: {status: 700, message: "Operation canceled by the user."},
    };
  }
  throw thrown;
}

export function getUrlParams() {
  const search = location.search.substring(1);
  if (!search) {
    return null;
  }
  return JSON.parse(
    '{"' +
    decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}'
  );
}

export function getRate(n, total) {
  let rate = total == 0 ? 0 : (n * 100) / total;
  return !rate ? 0 : rate.toFixed(2).replace(/\.?0+$/, "");
}

export function getLanguages(all = false) {
  let lngs = [
    {key: "en", value: "english"},
    {key: "fr", value: "french"},
    {key: "nl", value: "dutch"},
  ];

  if (all) {
    lngs.unshift({key: "all", value: "all"});
  }
  return lngs;
}

export function getType(all = false) {
  let types = [
    {key: "WEB_PAGE", value: "WEB_PAGE"},
    {key: "PLAIN_TEXT", value: "PLAIN_TEXT"},
    {key: "DND_TEMPLATE", value: "DND_TEMPLATE"},
    {key: "NEWS_LETTER", value: "NEWS_LETTER"},
  ];

  if (all) {
    types.unshift({key: "all", value: "all"});
  }
  return types;
}

export function getPeriod() {
  let periods = [
    {
      key: moment(new Date("2015-01-01")).format("YYYY-MM-DD"),
      value: "All the time",
    },
    {key: moment(new Date()).format("YYYY-MM-DD"), value: "Today"},
    {
      key: moment(new Date()).subtract(1, "week").format("YYYY-MM-DD"),
      value: "Last Week",
    },
    {
      key: moment(new Date()).subtract(1, "month").format("YYYY-MM-DD"),
      value: "Last Month",
    },
    {
      key: moment(new Date()).subtract(1, "year").format("YYYY-MM-DD"),
      value: "Last Year",
    },
  ];
  return periods;
}

export const PRICE_VALIDATION_REGEX = /^([0-9]*[.])?[0-9]+$/;

export function getBeSMSPriceWithMargin(margin = null) {
  if (!margin || margin <= 0) {
    margin = DEFAULT_CLIENT_MARGE;
  }
  return BE_BASIC_SMS_PRICE / (1 - margin / 100);
}

export function findKey(object, value) {
  for (let key in object) {
    if (object[key] == value) return key;
  }
  return null;
}

export function isArraysEqual(array1, array2) {
  if (
    !Array.isArray(array1) ||
    !Array.isArray(array2) ||
    array1.length !== array2.length
  )
    return false;

  let arr1 = [...array1].sort();
  let arr2 = [...array2].sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export function truncs(desc) {
  if (desc && desc.length > 75) return desc.slice(0, 75) + "...";
  else return desc;
}

export const replaceEmailContent = ({
  body,
  subject,
  fullName,
  profession,
  imageUrl,
  fiduciaryLogoUrl,
  fullName2,
  profession2,
  avatar2,
}, theme) => {
  const bodyRegex = /(<xp-corps>|{{BODY}}|BODY)/;
  const objectRegex = /(<xp-sujet>|{{SUBJECT}}|SUBJECT)/;
  const fullNameRegex = /(<xp-nom-complet>|FULL_NAME)/;
  const professionRegex = /(<xp-profession>|PROFESSION)/;
  const imageRegex = /(<img src="votre_image_url_ici" alt="Photo de profil">)/;
  const logoRegex = /FIDUCIARY_LOGO/g;

  const hasSecondSignatureData = fullName2 ;

  // Build the second signature avatar or placeholder
  const imageUrl2 = avatar2 !==  undefined && avatar2 !== "" && avatar2 !== null
    ? `<img src="${avatar2}" alt="Photo de profil" style="width: 50px; height: 50px; border-radius: 10px;margin-left: 25px;">`
    : `<div class="image-placeholder" style="width: 50px; height: 50px; border-radius: 10px; border: 2px solid var(--Gris-4, #F1F2F4); background: lightgray 50% / cover no-repeat; text-align: center; align-content: center; font-weight: bold; font-size: 24px; color: white;margin-left: 25px;line-height:50px;">${getUserNameForAvatar(fullName2) || ''}</div>`;
  const containerWidth = hasSecondSignatureData ? '60%' : '36%';
  const secondSignatureBlock = `
    <div class="signature" style="display:flex;align-items:center;">
      <div class="signature-img-container" style="border-radius: 10px; display: flex; align-items: center;">
        ${imageUrl2}
      </div>
      <div class="signature-info" style="display:inline-block;color: white;margin-top: 3px;">
        <div class="signature-name" style="font-size: 18px;font-weight: bold;display: block;margin-left: 15px;">${fullName2 || ''}</div>
        <div class="signature-profession" style="font-size: 12px; display: block;margin-left: 15px;">${profession2 || ''}</div>
      </div>
    </div>
  `;

  if (!theme || (body && !bodyRegex.test(theme))) {
    return body;
  }

  let content = theme;

  content = content.replace(bodyRegex, body);
  content = content.replace(objectRegex, subject);
  content = content.replace(fullNameRegex, fullName);
  content = content.replace(professionRegex, profession);
  content = content.replace(imageRegex, imageUrl);

  content = content.replace(logoRegex, match => {
    if (match.includes('url(')) {
      return `url('${fiduciaryLogoUrl.replace(/&apos;/g, "'")}')`;
    }
    return fiduciaryLogoUrl?.replace(/&apos;/g, "'");
  });

  content = content.replace(/<div class="signature-container"[^>]*>/, match => {
    return match.replace(/width:\d+%;/, `width:${containerWidth};`);
  });

  if (hasSecondSignatureData) {
    const signatureEndRegex = /(<\/div>\s*<\/div>\s*<\/div>\s*<\/div>)/;
    content = content.replace(signatureEndRegex, `</div></div>${secondSignatureBlock}</div></div>`);
  }

  return content;
};



export const getGenericEmailPreview = (textModel, theme) => {
  if (!textModel) return "";
  else if (textModel && (!theme || !theme.content)) return textModel.text;
  return theme.content
    .replace("{{SUBJECT}}", textModel.object)
    .replace("{{BODY}}", textModel.text)
    .replace("<xp-corps>", textModel.text);
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getFillClassName = (value, minLength = 2) => {
  const filled = value && value.trim().length > minLength;
  return filled ? "filled-box" : "empty-box";
};

export const getModalStyle = (defaultStyle, mandatoryStyle) => {
  if (!mandatoryStyle) {
    mandatoryStyle = {content: {}, overlay: {}};
  }
  if (inIframe()) {
    return {
      content: {
        ...defaultStyle.content,
        top: "2rem",
        bottom: "auto",
        height: `calc(720px - 2.5rem)`,
        boxShadow:
          "0 9px 46px 8px rgba(0, 0, 0, .14), 0 11px 15px -7px rgba(0, 0, 0, .12), 0 24px 38px 3px rgba(0, 0, 0, .2)",
        ...mandatoryStyle.content,
      },
      overlay: {
        ...defaultStyle.overlay,
        backgroundColor: "hsla(0,0%,4%,.1)",
        ...mandatoryStyle.overlay,
        overflow: "auto"
      },
    };
  }
  return defaultStyle;
};

export const getLabel = (item = [], lng = fr) => {
  return item[`label${ucFirst(lng)}`] || item["labelFr"] || "";
};

export const postResizeMessage = () => {
  if (window.parent) {
    console.log("height =", document.body.scrollHeight);
    window.parent.postMessage(
      {
        message: "RESIZE_IFRAME",
        height: document.body.scrollHeight,
      },
      "*"
    );
  }
};

export const formatNumber = (number) => {
  return number.toLocaleString(navigator.language, {
    minimumFractionDigits: 0,
  });
};

export const imageValid = (src, loaded, error) => {
  const img = new Image();
  img.src = src;
  img.onload = loaded;
  img.onerror = error;
};

export const fileConvertSize = (octets, decimal = 2) => {
  if (0 === octets) return 0;
  let ko = 1000;
  let size = ["Octets", "Ko", "Mo", "Go", "To"];
  let result = Math.floor(Math.log(octets) / Math.log(ko));

  if (["Octets", "Ko"].indexOf(size[result]) > -1) {
    decimal = 0;
  }

  return (
    parseFloat((octets / Math.pow(ko, result)).toFixed(decimal)) +
    " " +
    size[result]
  );
};

export const getFileType = (url) => {
  let extension = null;
  if (url.endsWith(".pdf")) {
    extension = "PDF";
  } else if (url.endsWith(".docx") || url.endsWith(".doc")) {
    extension = "DOC";
  } else if (
    url.endsWith(".mp4") ||
    url.endsWith(".m4a") ||
    url.endsWith(".webm") ||
    url.endsWith(".m4v") ||
    url.endsWith(".avi")
  ) {
    extension = "VIDEO";
  } else if (url.endsWith(".ppt")) {
    extension = "PPT";
  } else if (
    url.endsWith(".jpeg") ||
    url.endsWith(".jpg") ||
    url.endsWith(".png")
  ) {
    extension = "IMAGE";
  }
  return extension;
};

export const getFileContext = (url) => {
  let context = null;
  if (url.search("order") > -1) {
    context = "ORDER";
  } else if (url.search("invoice") > -1) {
    context = "INVOICE";
  } else if (url.search("badges") > -1) {
    context = "BADGE";
  } else if (url.search("attestation") > -1) {
    context = "ATTESTATION";
  } else if (url.search("creditNote") > -1) {
    context = "CREDIT";
  }
  return context;
};

export const getLinkType = (url, count) => {
  let types = [];

  if (count > 0) {
    types.push("CLICKED");
  }
  if (url.search("tag") > -1) {
    types.push("TAG");
  } else if (url.search("article") > -1) {
    types.push("ARTICLE");
  }
  return types;
};

export const resizeImage = (
  url,
  width = null,
  height = null,
  gravity = null,
  filter = null
) => {
  if (!width && !height) {
    return url;
  }
  let result = url + "/";
  if (width) {
    result += "w" + width;
  }
  if (width && height) {
    result += "-";
  }
  if (height) {
    result += "h" + height;
  }
  if (width && height && gravity) {
    result += "-g" + gravity;
  }
  if ((width || height) && filter) {
    result += "-f" + filter;
  }
  result += ".jpg";
  return result;
};

/**
 * Get Categories options grouped by scope
 */
export const getCategoriesOptions = (categories = [], lng = "fr") => {
  let libraryOptions = [];
  let modelsOptions = [];
  let sharedOptions = [];

  categories.forEach((category) => {
    if (category["count"] == 0) {
      return;
    }
    let label =
      category[`name${ucFirst(lng)}`] ||
      category[`nameFr`] ||
      category[`nameNl`] ||
      "";
    const categoryOption = {id: category.id, label};

    if (category["organization"]) {
      modelsOptions.push(categoryOption);
      sharedOptions.push(categoryOption);
    } else {
      libraryOptions.push(categoryOption);
    }
  });

  return [
    {
      code: "MY_MODELS",
      label: "my models",
      icon: SVG_MY_MODELS_ICON,
      scope: COMMUNITY_SCOPE,
      categories: modelsOptions,
    },
    {
      code: "MODEL_GALLERY",
      label: "library",
      icon: SVG_LIBRARY_ICON,
      scope: TAMTAM_SCOPE,
      categories: libraryOptions,
    },
    {
      code: "SHARED_MODELS",
      label: "shared models",
      scope: SHARED_MODELS_SCOPE,
      categories: sharedOptions,
    },
  ];
};

const dateToNumber = (v, date1904) => {
  if (date1904) {
    v += 1462;
  }

  let epoch = Date.parse(v);

  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
};

const excelSheetFromDataSet = (dataSet) => {
  if (dataSet === undefined || dataSet.length === 0) {
    return {};
  }

  let ws = {};
  let range = {s: {c: 10000000, r: 10000000}, e: {c: 0, r: 0}};
  let rowCount = 0;

  dataSet.forEach((dataSetItem) => {
    let columns = dataSetItem.columns;
    let xSteps =
      typeof dataSetItem.xSteps === "number" ? dataSetItem.xSteps : 0;
    let ySteps =
      typeof dataSetItem.ySteps === "number" ? dataSetItem.ySteps : 0;
    let data = dataSetItem.data;
    if (dataSet === undefined || dataSet.length === 0) {
      return;
    }

    rowCount += ySteps;

    if (Array.isArray(columns) && columns.length >= 0) {
      columns.forEach((col, index) => {
        let cellRef = utils.encode_cell({
          c: xSteps + index,
          r: rowCount,
        });
        fixRange(range, 0, 0, rowCount, xSteps, ySteps);
        getCell(col, cellRef, ws);
      });

      rowCount += 1;
    }

    for (let R = 0; R != data.length; ++R, rowCount++) {
      for (let C = 0; C != data[R].length; ++C) {
        let cellRef = utils.encode_cell({c: C + xSteps, r: rowCount});
        fixRange(range, R, C, rowCount, xSteps, ySteps);
        getCell(data[R][C], cellRef, ws);
      }
    }
  });

  if (range.s.c < 10000000) {
    ws["!ref"] = utils.encode_range(range);
  }

  // set column width
  ws["!cols"] = setColumnWidth(dataSet);

  return ws;
};

/**
 * set column width
 */
function setColumnWidth(dataSet) {
  let columnWidths = [];

  // set colum width
  if (dataSet) {
    for (let i = 0; i < dataSet.length; i++) {
      const data = dataSet[i];
      const columns = data.columns;

      if (columns) {
        for (let j = 0; j < columns.length; j++) {
          const column = columns[j];

          if (column.widthPx) {
            columnWidths.push({
              wpx: column.widthPx,
            });
            continue;
          }

          if (column.widthCh) {
            columnWidths.push({
              wpx: column.widthCh,
            });
            continue;
          }

          columnWidths.push({
            wpx: 180, // 64px is default column width in excel
          });
        }
      }
    }
  }

  return columnWidths;
}

function fixRange(range, R, C, rowCount, xSteps, ySteps) {
  if (range.s.r > R + rowCount) {
    range.s.r = R + rowCount;
  }

  if (range.s.c > C + xSteps) {
    range.s.c = C + xSteps;
  }

  if (range.e.r < R + rowCount) {
    range.e.r = R + rowCount;
  }

  if (range.e.c < C + xSteps) {
    range.e.c = C + xSteps;
  }
}

function getCell(v, cellRef, ws) {
  let cell = {};
  if (v === null) {
    return;
  }
  if (typeof v === "number") {
    cell.v = v;
    cell.t = "n";
  } else if (typeof v === "boolean") {
    cell.v = v;
    cell.t = "b";
  } else if (v instanceof Date) {
    cell.t = "n";
    cell.z = SSF._table[14];
    cell.v = dateToNumber(cell.v);
  } else if (typeof v === "object") {
    cell.v = v.value;
    cell.s = v.style;
  } else {
    cell.v = v;
    cell.t = "s";
  }
  ws[cellRef] = cell;
}

const FileExtensionType = "xlsx" | "xls" | "csv" | "txt" | "html";

const ALLOWED_FILE_EXTENSIONS = ["xlsx", "xls", "csv", "txt", "html"];

const DEFAULT_FILE_EXTENSION = "xlsx";

const strToArrBuffer = (s) => {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);

  for (let i = 0; i != s.length; ++i) {
    view[i] = s.charCodeAt(i) & 0xff;
  }

  return buf;
};

const getFileExtension = (fileExtension, filename) => {
  let extension = fileExtension;

  if (extension?.length === 0) {
    const slugs = filename.split(".");
    if (slugs?.length === 0) {
      throw Error("Invalid file name provided");
    }
    extension = slugs[slugs.length - 1];
  }

  if (ALLOWED_FILE_EXTENSIONS.indexOf(extension) !== -1) {
    return extension;
  }

  return DEFAULT_FILE_EXTENSION;
};

export const exportDataToExcelFile = (dataSet, fileName, extension) => {
  const wb = {
    SheetNames: ["sheet1"],
    Sheets: {
      sheet1: excelSheetFromDataSet(dataSet),
    },
  };

  const fileExtension = getFileExtension(extension, fileName);
  const wbout = write(wb, {
    bookType: fileExtension,
    bookSST: true,
    type: "binary",
  });

  saveAs(
    new Blob([strToArrBuffer(wbout)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    fileName
  );
};

export const decryptWithAES = (ciphertext) => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  var encryptMethodLength = parseInt(aesNumber);

  var json = JSON.parse(
    CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(ciphertext))
  );

  var salt = CryptoJS.enc.Hex.parse(json.salt);
  var iv = CryptoJS.enc.Hex.parse(json.iv);

  var encrypted = json.ciphertext; // no need to base64 decode.

  var iterations = parseInt(json.iterations);
  if (iterations <= 0) {
    iterations = 999;
  }
  var encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};
