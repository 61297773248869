import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'

import { SimpleTableFetching } from 'fetching/DatatableFetching'
import { TTP_HOME_URL } from 'Config';
import _ from 'i18n';

@connect((store) => {
  return {
    items: store.campaigns.stats.recipients.list.items,
    fetching: store.campaigns.stats.recipients.list.fetching,
  };
})
export default class Recipients extends Component {

  handleSMSCampaignCostByRecipient(count, price) {
    let amount = count * price;
    return "€" + amount.toFixed(2);
  }

  render() {
    let { fetching, items, headerColor } = this.props;

    return (<div className="row align-center columns">
      <ReactTooltip id="info-tooltip" type='dark' effect="float" multiline={true} place="bottom" />
      <div className="ttp-datatable small-12 small-centered columns">
        <div className="row ttp-datatable__header" style={{ backgroundColor: headerColor }}>
          <div className="small-3">{_('Name')}</div>
          <div className="small-2">
            {_('Phone number')}&nbsp;<span data-for="info-tooltip" className="icon-info"
              data-tip={`${_('Phone numbers with check mark') + '<br>' + _('have been verified by our system')}`}></span></div>
          <div className="small-2">{_('Country')}</div>
          <div className="small-2">{_('SMS Count')}</div>
          <div className="small-2">{_('status')}</div>
          <div className="small-1">{_('Cost')}</div>
        </div>
        {(fetching) ? <SimpleTableFetching /> : items.map(item => {
          let name = "___ ___";
          let profileLink = null;
          if (['CONTACT', 'PROSPECT'].indexOf(item.recipientType) > -1) {
            profileLink = `${TTP_HOME_URL}${item.recipientType == "CONTACT" ? "member" : "prospect"}/${item.recipientId}/edit`;
          }
          if (item.firstName || item.lastName) {
            name = `${item.firstName} ${item.lastName ? item.lastName.toUpperCase() : ""}`;
          }

          return <div className="row ttp-datatable__row">
            <div className="small-3">{name}</div>
            <div className="small-2">{item.phoneNumber}&nbsp;<span className="icon-check"></span></div>
            <div className="small-2"><span className={`flag-icon flag-icon-${item.countryCode}`}></span></div>
            <div className="small-2">{item.smsCount}</div>
            <div className="small-2">{item.sentStatus}</div>
            <div className="small-1">
              <span className="cost">{this.handleSMSCampaignCostByRecipient(item.smsPrice, item.smsCount)}</span>
              {profileLink && <div className="drop-down-option float-right">
                <span className="icon-options-vertical"></span>
                <ul className="drop-down-option__left">
                  <li><a href={profileLink} target="__blank">{_('view profile')}</a></li>
                </ul>
              </div>
              }
            </div>
          </div>
        })
        }
      </div>
    </div>);
  }

}
