import React, { Component } from 'react'
import _ from 'i18n';
import ToggleButton from './ToggleButton';

export class ApplyButton extends Component {
    render() {
        const { checked, onToggleSelection, label } = this.props;
        return (
            <div className={`apply-button ${checked ? "applied" : ""}`}>
                <a>
                    {_(checked ? "Applied" : label)}
                </a>
                <ToggleButton cssClass={"apply-icon"} onToggleSelection={onToggleSelection} checked={checked} />
            </div>
        )
    }
}

export default ApplyButton
