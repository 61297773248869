import React, { Component } from "react";
import { connect } from "react-redux";
import BarChartComponent from "./BarChartComponent";

class TotalClicksChart extends Component {
  render() {
    const { dataClicks } = this.props;
    console.log(dataClicks);
    const dataKeysY = [
      { key: "totalClicks", label: "nombre de clics", color: "#F7953E" },
    ];

    const yAxisTitle = "Number of clicks";

    const dataKeyX = { key: "date", label: "date" };
    return (
      <BarChartComponent
        dataChart={dataClicks}
        dataKeysY={dataKeysY}
        dataKeyX={dataKeyX}
        yAxisTitle={yAxisTitle}
      />
    );
  }
}
const mapStateToProps = (store) => ({
  dataClicks: store.dashboard.dataClicks,
});
export default connect(mapStateToProps)(TotalClicksChart);
