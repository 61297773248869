import React, { Component } from 'react';
import { LANGUAGE_FLAGS } from "../../../config/Common";
import { truncs } from "../../../services/utils";
import { CAMPAIGN_LANGUAGE } from 'Common';
import _ from 'i18n';
import { ucFirst } from "utils";
import 'flag-icon-css/css/flag-icon.css';
import Application from "../../common/Application";
import {convertDateFromUTC} from "../../../services/date";

class SettingsItem extends Component {

  render() {
    const { targetApp, status, updatedAt, target, languages, clicked } = this.props;

    return (
      <div className="ge-settings-item" onClick={clicked}>
        <div className="status_top">
          <div className={`icon-${status.toLowerCase()}`} />
          <p>&nbsp;&nbsp;&nbsp;{_(status)}</p>
          <Application name={targetApp} />
        </div>
        <div className="labels m-t-xs">
          <h4>{_("updatedAt")} {convertDateFromUTC(updatedAt)}</h4>
          <p>
            {_(truncs(target))}
          </p>
          {languages.map(lng =>
            <div className="lng" key={lng}>
              <div className="lng-item">
                <div className={`flag flag-icon ${LANGUAGE_FLAGS[lng]}`} />
                <span>
                  {ucFirst(_(CAMPAIGN_LANGUAGE[lng]))}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SettingsItem;


