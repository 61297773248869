import React, {Component} from 'react';
import { connect } from 'react-redux';

import { setCurrentSmsCampaignData } from "actions";
import _ from 'i18n';
import Bubble from 'common/Bubble';
import { SVG_SEND_ICON, SVG_USER_ICON } from "../../../../services/svgIcones";

const mapStateToProps = (state, ownProps) => ({
    recipientType: state.currentCampaign.sms.recipientType,
    currentSideBar: state.currentCampaign.sms.currentSideBar
});

const mapDispatchToProps = dispatch => ({
    toogleSideBar : (sidebar) => dispatch(setCurrentSmsCampaignData({currentSideBar: sidebar})),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class Bubbles extends Component {

  render() {
    let {toggleSideBar, sendingDisabled, currentSideBar, amount} = this.props;
    return (
      <div className={`campaign-bubbles ${currentSideBar && "bubbles-sticky"}`}>
        <div className="info">
          <img src="/img/icons/money.svg"/>
          <div>
            <span>{_('authorized_balance')}</span>
            <span><b>{amount}</b></span>
          </div>
        </div>
        <Bubble onBubbleClick={() => toggleSideBar('RECIPIENTS')}
                label="recipients" svgIcon={SVG_USER_ICON}
                isActive={'RECIPIENTS' == currentSideBar}/>

        {!sendingDisabled &&
        <Bubble onBubbleClick={() => toggleSideBar('SEND')} label="send"
                svgIcon={SVG_SEND_ICON} isActive={'SEND' == currentSideBar}
                cssClass="success send"/>
        }
      </div>
    );
  }
}
