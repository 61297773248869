import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentNavPage, setCurrentDndTheme } from "actions";

import _ from 'i18n';
import EditorSelectorTabSend from 'startup/EditorSelectorTabSend';
import ThemePreview from 'editor/ThemePreview';
import { STEPS } from 'Common';
import PageHeader from 'common/PageHeader';

@connect((store) => {
  return {
    step: store.params.step,
    content: store.themes.content,
  }
})
export default class ChoicePage extends Component {

  componentDidMount() {
    const { dispatch } = this.props;
    // dispatch(setStep(STEPS["CREATE_CAMPAIGN"]));
    dispatch(setCurrentNavPage("CREATE_CAMPAIGN"));
  }

  onTypeSelect = (type) => {
    if (type === "/drag-drop") {
      this.props.dispatch(setCurrentDndTheme(null));
    }
    this.props.history.push(type);
  }

  render() {
    const { content, step ,currentPage,handleSetCurrentPage} = this.props;
    const showPreview = step == STEPS["THEME_PREVIEW"]

    return (
      <div>
        {showPreview && <ThemePreview dispatch={this.props.dispatch} content={content} previous={{ step: STEPS["CREATE_CAMPAIGN"], url: "/campaign/selector" }} />}
        <div id="home-page" className={`campaign-type__selector ${showPreview ? "hide" : ""}`}>
          <PageHeader header="Create email campaigns" subHeader="Create, manage and send your email campaigns ." icon="EDIT_EMAIL" />
          <EditorSelectorTabSend onTypeSelect={this.onTypeSelect} 
          currentPage={currentPage}
          handleSetCurrentPage={handleSetCurrentPage}
          />
        </div>
      </div>
    )
  }
}
