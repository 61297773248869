import React, { Component } from 'react';
import { connect } from 'react-redux';

import { initCampaignListFilter, initCampaignListItems, setCurrentNavPage, setSmsPaginationPage } from "actions";
import CampaignSMSList from 'sms/CampaignSMSList';
import CampaignSMSListFilter from 'sms/CampaignSMSListFilter';
import { fetchSMSCampaigns } from 'thunks';

import _ from 'i18n';
import PageHeader from 'common/PageHeader';
import TTPPaginator from 'common/list/TTPPaginator';

@connect((store) => {
  return {
    campaigns: store.campaigns.list,
    auth: store.auth,
    smsPaginationPage: store.campaigns.list.smsPaginationPage,
    smsPageSize: store.campaigns.list.smsPageSize,
    filter: store.campaigns.list.filter
  }
})
export default class CampaignSMSListPage extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentNavPage("CAMPAIGN_LIST"));

    dispatch(initCampaignListFilter());
    dispatch(initCampaignListItems());

    dispatch(fetchSMSCampaigns());
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter, smsPaginationPage, smsPageSize } = prevProps;
    if (filter !== this.props.filter || smsPaginationPage != this.props.smsPaginationPage ||
      smsPageSize != this.props.smsPageSize) {
      this.props.dispatch(fetchSMSCampaigns());
    }
  }

  handleCampaignListPageChange({ selected }) {
    this.props.dispatch(setSmsPaginationPage(selected + 1));
  };

  renderPaginate() {
    let { campaigns, smsPaginationPage, smsPageSize } = this.props;
    if (campaigns.error != null && campaigns.error.code == 404) {
      return "";
    }
    return <TTPPaginator onPageChange={this.handleCampaignListPageChange.bind(this)}
      pageSize={smsPageSize}
      nbResult={campaigns.nbResult}
      paginationPage={smsPaginationPage} />
  }

  render() {
    let { campaigns } = this.props;
    return (
      <div className="campaign campaign-sms">
        <PageHeader header="smsCampaigns"
          subHeader="Create, manage and send your sms campaigns ."
          icon="SMS">
          <button className="btn secondary text-uppercase"
            onClick={() => this.props.history.push("campaign-sms")}>
            <img src="img/icons/add.svg" />
            {_('create')}
          </button>
        </PageHeader>
        <CampaignSMSListFilter />
        <div className={`row align-center medium-11 m-t-s`}>
          <CampaignSMSList campaigns={campaigns} cssClass={"medium-12"} />
        </div>
        {!campaigns.fetching && <div className="row align-center  margin-bottom-2">
          <div className="column medium-11">
            {this.renderPaginate()}
          </div>
        </div>}
      </div>
    );
  }
}
