import React, {Component} from "react";
import AppendHead from "react-append-head";

class ChatContainer extends Component {
  constructor(props) {
    super(props);
    this.widgetRef = React.createRef();
    this.state = {
      showWidget: false,
    }
  }
  componentDidMount() {
      let maxIter = 100;
      const interval = setInterval(() => {
        if (window.ChatWidget !== undefined) {
          this.setState({ showWidget: true});
          clearInterval(interval);
          return;
        }
        if (--maxIter < 0) {
          clearInterval(interval);
        }
      }, 1000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentUser !== this.props.currentUser || prevProps.users !== this.props.users || prevState !== this.state) {
      if (window.ChatWidget !== undefined) {
        window.ChatWidget.mount(this.props, this.widgetRef.current);
      }
    }
  }

  componentWillUnmount() {
    window.ChatWidget?.unmount(this.widgetRef.current);
  }
  render() {
    return <div id={"chat-widget"} ref={this.widgetRef}></div>
  }
}

const ChatWidget = (props) => {
    const { env, lng } = props;
    return <>
      <AppendHead onLoad={() => {}}>
        <link
          name="chat-widget"
          rel="stylesheet"
          href={`https://tamtam.s3.eu-west-1.amazonaws.com/cdn/chat-widget/${env}/widget.css`}
        />
        <script
          name="chat-widget-script"
          src={`https://tamtam.s3.eu-west-1.amazonaws.com/cdn/chat-widget/${env}/widget.js`}
        />
      </AppendHead>
      {props.currentUser ? <ChatContainer currentUser={props.currentUser} users={props.users} env={env} lng={lng}/> : null }
    </>;
}

export default ChatWidget;
