import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";

import UsersTagSuggestion from "common/UsersTagSuggestion";
import { setCurrentEmailCampaignData } from "actions";
import CheckBoxBtn from "common/CheckBoxBtn";

import _ from "i18n";
import { ucFirst } from "../../../services/common";
import StepsFooter from "./StepsFooter";
import { NotificationBar } from "./notifications/NotificationBar";

@connect((store) => {
  return {
    userEmails: store.auth.user.email,
    currentStep: store.currentCampaign.email.currentSideBar === "TEST",
    selectedTags: store.currentCampaign.email.testEmails,
    testingDisabled: !store.currentCampaign.email.fromEmailValid,
    detached: store.auth.detached,
  };
})
export default class TestStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sendTo: false,
      sendMe: true,
    };

    this.handleSendToChange = () =>
      this.setState({ sendTo: !this.state.sendTo });
    this.handleSendMeChange = () =>
      this.setState({ sendMe: !this.state.sendMe });
  }

  componentWillMount() {
    let { userEmails } = this.props;
    let mainUserEmail = "";
    for (let i = 0; i < userEmails.length; i++) {
      if (userEmails[i]["main"] === 1) {
        mainUserEmail = userEmails[i];
        break;
      }
    }
    if (!mainUserEmail) {
      mainUserEmail = userEmails[0];
    }
    this.setState({ mainUserEmail });
  }

  handleTestClick() {
    let { onConfirm, selectedTags } = this.props;
    const { sendTo, sendMe, mainUserEmail } = this.state;
    if (sendTo && selectedTags.length === 0) {
      NotificationManager.warning(_("typeTestEmail"), _("warning"));
      return;
    }
    if (!(sendTo || sendMe)) {
      return;
    }
    let sentOptions = {
      sendTo,
      sendMe,
    };
    if (sendMe) {
      sentOptions.email = mainUserEmail.id;
    }
    onConfirm(sentOptions);
  }

  handleTagsChange = (tags) => {
    this.props.dispatch(setCurrentEmailCampaignData({ testEmails: tags }));
  };

  renderTestBlock() {
    let { selectedTags, detached } = this.props;
    const { sendTo, sendMe, mainUserEmail } = this.state;

    if (detached) {
      return (
        <div className="columns small-10">
          <span className="tab-label">
            {ucFirst(_("send me a test email"))} :
          </span>
          <span className="detail"> - {mainUserEmail.email}</span>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="columns small-6 columns-padding">
          <span className="tab-label">{ucFirst(_("sendEmailTestTo"))}</span>
          <div className="small-12 flex-container align-middle m-r-xs ">
            <CheckBoxBtn
              id="send-to"
              checked={sendTo}
              onChange={this.handleSendToChange}
            />
            <UsersTagSuggestion
              displayEmail={true}
              selectedTags={selectedTags}
              onTagsChange={this.handleTagsChange}
              mainClass={`tags-input-container ${
                selectedTags && selectedTags.length ? "filled-box" : "empty-box"
              }`}
             
            />
          </div>
        </div>

        <div className="columns small-6 columns-padding">
          <span className="tab-label">{ucFirst(_("send me a test at"))}</span>
          <div className="small-12 flex-container align-middle">
            <CheckBoxBtn
              checked={sendMe}
              onChange={this.handleSendMeChange}
              id="send-me"
            />
            <input
              className="filled-box"
              type="text"
              value={mainUserEmail.email}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { testingDisabled, selectedTags, isSuccesTest, currentStep } =
      this.props;
    const { sendTo, sendMe } = this.state;

    var testingEnabled =
      !testingDisabled &&
      ((sendTo && selectedTags && selectedTags.length > 0) || sendMe);

    return (
      <div id="send-tabs__tab" className={`${!currentStep && "hide"}`}>
        <div className="tab">
          <h4 className="tab-header">{ucFirst(_("sendTest"))}</h4>
          {this.renderTestBlock()}
        </div>
        {isSuccesTest && <NotificationBar message={_("campaignTestSent")} />}
        <StepsFooter
          testingEnabled={testingEnabled}
          handleTestClick={this.handleTestClick.bind(this)}
        />
      </div>
    );
  }
}
