import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import {
  setSlotsPaginationPage,
  initSlots,
  setSlotsSearchWord,
  setSlotsEvent,
} from "actions";
import { fetchEventSlot } from "thunks";
import NotFound from "notFound/NotFound";
import SlotItem from "./SlotItem";
import EventFilter from "blocks/EventFilter";

import _ from "i18n";
import SlotItemFetching from "fetching/blocks/SlotItemFetching";

const mapStateToProps = (state, ownProps) => ({
  slots: state.filters.slots.items,
  nbResult: state.filters.slots.nbResult,
  filter: state.filters.slots.filter,
  fetching: state.filters.slots.fetching,
  eventFetching: state.filters.events.fetching,
  viewMode: state.themes.newsletter.viewMode,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventSlots: () => dispatch(fetchEventSlot()),
  setSlotsPaginationPage: (page) => dispatch(setSlotsPaginationPage(page)),
  initSlots: () => dispatch(initSlots()),
  setSlotsEvent: (event) => dispatch(setSlotsEvent(event)),
  setSlotsSearchWord: (searchWord) => dispatch(setSlotsSearchWord(searchWord)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class SlotsList extends Component {
  state = {
    hasMoreItems: false,
  };

  componentDidMount() {
    this.props.initSlots();
    console.log("======test");
  }

  componentDidUpdate(prevProps) {
    const { filter, fetchEventSlots, fetching } = this.props;
    if (filter !== prevProps.filter) {
      fetchEventSlots();
    }
    if (fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !fetching,
      });
    }
  }

  loadItems = () => {
    const { filter, fetching, nbResult, slots, setSlotsPaginationPage } =
      this.props;

    if (!fetching && slots.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      setSlotsPaginationPage(currentPage + 1);
    }
  };

  render() {
    const {
      slots,
      fetching,
      eventFetching,
      filter,
      setSlotsEvent,
      setSlotsSearchWord,
      viewMode,
      showSelected,
      selectedFeeds,
    } = this.props;
    const { hasMoreItems } = this.state;
    const listMode = viewMode === "LIST";
    let items = [];

    if (slots && slots.length) {
      items = slots.map((item) => {
        const order = selectedFeeds.indexOf(item.id);
        return showSelected || order < 0 ? (
          <SlotItem
            viewMode={viewMode}
            order={order >= 0 ? order : 1000}
            key={`slot-${item.id}`}
            isSelected={selectedFeeds.includes(item.id)}
            {...item}
          />
        ) : null;
      });
    }

    if (fetching || eventFetching) {
      let itemNbr = listMode ? 5 : 10;
      for (let i = 0; i < itemNbr; i++) {
        items.push(
          <div
            className={`medium-${listMode ? 12 : 6} column`}
            style={{ order: 1001 }}
          >
            <SlotItemFetching key={`fetch-slot-${i}`} />
          </div>
        );
      }
    }

    let showSlots = filter.event && items && items.length;

    return (
      <div className="medium-12">
        <div
          id="feeds-container"
          style={{ height: `${0.9 * window.innerHeight - 20}px` }}
          ref={(ref) => (this.scrollParentRef = ref)}
          className="column"
        >
          <EventFilter
            filter={filter}
            onEventChange={setSlotsEvent}
            onSearchWordChange={setSlotsSearchWord}
          />
          {showSlots || eventFetching ? (
            <InfiniteScroll
              className={"feeds-list"}
              pageStart={0}
              loadMore={this.loadItems}
              useWindow={false}
              hasMore={hasMoreItems}
            >
              {showSelected && selectedFeeds.length > 0 && (
                <div className={"masonry-separator medium-12"} />
              )}
              {items}
            </InfiniteScroll>
          ) : (
            <NotFound
              mainClass="medium-10"
              text={!filter.event && _("Select event")}
            />
          )}
        </div>
      </div>
    );
  }
}
