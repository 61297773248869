import React, {Component} from 'react';
import {getUserNameForAvatar} from 'utils';

import _ from 'i18n';
import {SVG_CHECK_ICON} from "../../services/svgIcones";

export default class User extends Component {

  selectUser() {
    const {id, handleSelectDeselectSvg, handleSettingsTab} = this.props;
    const el = document.getElementById('avatar-' + id).classList;
    el.toggle('selected', !el.contains('selected'));

    const iconCheck = document.getElementById('check-' + id).classList;
    setTimeout(() => {
      iconCheck.toggle('active', !iconCheck.contains('active'));
    }, 100);

    const selectedEl = document.getElementsByClassName('selected');
    const avatarEl = document.getElementsByClassName('employee');
    const selectAll = document.getElementsByClassName('bubble__select-all').item(0);
    const childSpan = selectAll.getElementsByTagName('span').item(0);

    if (selectedEl.length > 0) {
      if (selectedEl.length == avatarEl.length+1) {
        selectAll.classList.add('deselect');
        handleSelectDeselectSvg("deselect");
        childSpan.textContent = _('Deselect All');
      } else if (!selectAll.classList.contains('select')) {
        selectAll.classList.remove('deselect');
        handleSelectDeselectSvg("select");
        childSpan.textContent = _('Select All');
      }
      handleSettingsTab(true);
    } else {
      handleSettingsTab(false);
    }
  }

  render() {
    const {id, firstName, lastName, avatarUrl, role, emailingAuthorisation} = this.props;

    let avatarDiv = null;
    const checkDiv = (
      <span id={`check-${id}`} className="user-check icon">
          {SVG_CHECK_ICON}
      </span>
    );
    if (avatarUrl != undefined) {
      avatarDiv = (

        <div id={`avatar-${id}`} className="user-avatar employee"
             onClick={this.selectUser.bind(this)}
             style={{backgroundImage: `url(${avatarUrl})`}}>
          {checkDiv}
        </div>
      );
    } else {
      avatarDiv = (
        <div id={`avatar-${id}`} className="user-avatar empty-avatar employee"
             onClick={this.selectUser.bind(this)}>
          <span
            className="empty-avatar__initials">{getUserNameForAvatar(firstName, lastName)}</span>
          {checkDiv}
        </div>
      );
    }

    let signatureDiv = [];
    signatureDiv.push(<h3 key="h3">{firstName + ' ' + lastName}</h3>);
    signatureDiv.push(<h4 key="h4">
      <span>{(role && role.id != undefined) ? _(role.type) : "____"}</span>
    </h4>);
    signatureDiv.push(<h5 key="h5">
      {(emailingAuthorisation && emailingAuthorisation.role != undefined) ? _(emailingAuthorisation.role) : "____"}</h5>);

    return (
      <div className="user column small-12 medium-6 large-4">
        <div className="user-container"/>
        {avatarDiv}
        <div className="user-header">
          {signatureDiv}
        </div>
      </div>
    );
  }
};
