import React, { Component } from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";

import { fetchEmails } from "thunks";

import _ from "i18n";
import { getUserNameForAvatar } from "utils";
import debounce from "lodash.debounce";
import { LoadingSuggestion } from "./LoadingSuggestion";

@connect((store) => {
  return {
    usersSuggestions: store.emails.items,
    loadingUsersSuggestions: store.emails.fetching,
    suggestionsError: store.emails.error,
  };
})
export default class UsersSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInputValue: "",
      fetchValue: null,
      hasMore: true,
      allUsers: this.props.usersSuggestions,
      start: 0,
      fetching: false,
    };
    this.debouncedLoadUsersSuggestions = debounce(
      this.loadUsersSuggestions,
      1000,
    );
    this.handleScroll = debounce(this.handleScroll.bind(this), 200);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  loadUsersSuggestions(value, newStart) {
    const { fetchValue, allUsers, hasMore } = this.state;
    const { dispatch } = this.props;
    if (value !== fetchValue || hasMore) {
      dispatch(fetchEmails(value, true, newStart)).then(
        (response) => {
          const fetchedUsers = response.value.data.data;
          const nbResult = response.value.data.nbResult;
          const updatedUsers =
            value !== fetchValue
              ? fetchedUsers
              : [...allUsers, ...fetchedUsers];

          this.setState({ start: newStart ? newStart : 0 });

          this.setState({
            hasMore: nbResult > updatedUsers.length,
            allUsers: updatedUsers,
            fetchValue: value,
            fetching: false,
          });
        },
        (err) => {
          this.setState({
            hasMore: false,
            allUsers: [],
            fetchValue: value,
            fetching: false,
          });
          console.log("error", err);
        },
      );
    }
  }

  handleScroll = (e) => {
    const { hasMore, fetchValue, start } = this.state;
    const { loadingUsersSuggestions } = this.props;
    const element = e.target;

    const isAtBottom =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 1;

    if (
      !loadingUsersSuggestions &&
      hasMore &&
      (Math.ceil(element.scrollHeight - element.scrollTop) ===
        element.clientHeight ||
        isAtBottom)
    ) {
      this.setState({ fetching: true });
      this.loadUsersSuggestions(fetchValue, start + 20);
    }
  };

  handleSelectUser = (user) => {
    this.props.onSelectUser(user);
    this.setState({ userInputValue: "" });
  };

  render() {
    const { loadingUsersSuggestions, suggestionsError } = this.props;
    const { userInputValue, allUsers, fetching, start } = this.state;
    const inputProps = {
      placeholder: _("Add a member") + " ...",
      value: userInputValue,
      onChange: (event, { newValue }) =>
        this.setState({ userInputValue: newValue }),
      autoFocus: "true",
    };

    return (
      <div className={fetching && "auto-suggestion-loader"}>
        <Autosuggest
          suggestions={allUsers}
          shouldRenderSuggestions={(value) => value && value.trim().length > 2}
          onSuggestionsFetchRequested={({ value }) =>
            this.debouncedLoadUsersSuggestions(value)
          }
          onSuggestionsClearRequested={() =>
            this.setState({ usersSuggestions: [] })
          }
          onSuggestionSelected={(e, { suggestion }) => {
            this.handleSelectUser(suggestion);
          }}
          getSuggestionValue={({ user }) =>
            user.firstName + " " + user.lastName
          }
          renderSuggestion={(suggestion) => {
            const { user, email } = suggestion;
            return (
              <div className="user-suggestion__item">
                {user.avatarUrl ? (
                  <div
                    className="img-wrap"
                    style={{ backgroundImage: `url(${user.avatarUrl})` }}
                  ></div>
                ) : (
                  <div className="img-wrap empty-avatar">
                    <span style={{ fontSize: "inherit" }}>
                      {getUserNameForAvatar(user.firstName, user.lastName)}
                    </span>
                  </div>
                )}
                <div className="content">
                  <h4>
                    {user.firstName} {user.lastName}
                  </h4>
                  <p>{email}</p>
                </div>
              </div>
            );
          }}
          inputProps={inputProps}
          renderSuggestionsContainer={({ containerProps, children, query }) => (
            <LoadingSuggestion
              containerProps={containerProps}
              loadingsSuggestions={loadingUsersSuggestions}
              suggestionsError={suggestionsError}
              value={query}
              onScroll={this.handleScroll}
              allUsers={allUsers}
              fetching={fetching}
            >
              {children}
            </LoadingSuggestion>
          )}
        />
      </div>
    );
  }
}
