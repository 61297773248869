import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import TTPSelect from "common/TTPSelect";
import { setEventsFilters } from "actions";
import { USER_ROLE_TYPES } from "Common";
import {fetchClientFolders} from "../../../../actions/thunks/filters";

@connect((store) => {
  return {
    filters: store.currentCampaign.email.eventFilters,
    folders: store.filters.folders.items,
  }
}, (dispatch) => {
  return {
    setEventFilters: (filters) => dispatch(setEventsFilters(filters)),
    fetchClientFolders: () => dispatch(fetchClientFolders()),
  }
})
export default class FolderFilters extends Component {

  constructor(props) {
    super(props);
    this.roleTypeOptions = USER_ROLE_TYPES.map(role => ({ value: role, label: _(role).toUpperCase() }));
    this.handleRoleTypeChange = (selected) => this.props.setEventFilters({ value: selected, property: "role.type", operator: "in" });
    this.handleRoleFolderChange = (selected) => {
      this.props.setEventFilters({ value: selected, property: "role.organization", operator: "eq" });
    }

    this.state = {
      selectedTypes: [],
      roleFolders: [],
      selectedFolder: null
    }
  }

  loadFilters = () => {
    let newState = {};
    this.props.filters.forEach(({ property, value }) => {
      switch (property) {
        case "role.type":
          newState['selectedTypes'] = value;
          break;
        case "role.organization":
          newState['selectedFolder'] = value;
          break;

      }
    });
    this.setState(newState);
  }

  componentDidMount() {
    this.loadFilters();
    this.props.fetchClientFolders();
  }

  componentDidUpdate(prevProps) {
    const { folders } = this.props;

    if (this.props.filters !== prevProps.filters) {
      this.loadFilters();
    }

    if (folders !== prevProps.folders) {
      let newFolders = folders.map(({ id, abreviation, name }) => ({ label: abreviation || name, id }));
      this.setState({ roleFolders: newFolders });
    }
  }

  render() {
    const { selectedTypes, roleFolders, selectedFolder } = this.state;

    return (
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('client folder')}</h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={selectedFolder}
              placeholder={_('Select client folder')}
              options={roleFolders}
              onChange={this.handleRoleFolderChange} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('ROLES_TYPES')}</h1>
            <TTPSelect
              notClearable={true}
              values={selectedTypes}
              placeholder={_('Select role')}
              options={this.roleTypeOptions}
              onChange={this.handleRoleTypeChange} />
          </div>
        </div>
      </div>
    );
  }
}
