import { setLanguage, setApplication } from "actions";

import { createStore } from "./store/createStore";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { getRoutes, appsUrls } from "./router/getRoutes";
import { getHistory } from "./router/getHistory";

import { setAuthUser, fetchClientSettings, setCurrentClient } from "thunks";

import { getCookie } from "utils";
import { initSentry } from "./sentry";
import { APP_ENV, URL_HASH_KEY } from "Config";
import {
  getLocaleLanguage,
  getEmailingRedirectStorage,
  setEmailingRedirectStorage,
} from "EmailingAPI";

// App css
import "applicationStyles";

import TTPLoader from "./components/ui/TTPLoader";
import Layout from "./components/Layout";
import { fetchWebNotifications } from "./actions/thunks/notifications";

import { clearAllCookies } from "./services/auth";
import {
  setIsNlBlog,
  setIsUaNewsletter,
  setProgrammedDate,
  setUaApprovalAction,
  setUaEbox,
  setUaIdApproval,
  setUaNewsletterId,
} from "./actions/actions/currentCampaign";
import moment from "moment";
import { decryptWithAES } from "./services/utils";
import { setIsUaVersion } from "./actions/actions/campaign";
import { setUAHeader } from "./actions/actions/auth";

// Configure Store
export const store = createStore();

// Install Talk Widget
//handleTalkWidget(store);

// Init Sentry
initSentry(store, {
  APP_ENV,
  APP_NAME: "sending",
  APP_RELEASE: "2",
  APP_SENTRY_KEY: "73109b7a14014106a0570f0aecb6566f",
  APP_SENTRY_ID: "9",
});

const now = Math.floor(new Date().getTime() / 1000);
const userCookie = getCookie("ttp_auth_" + APP_ENV);
let loading = false;

const getAppFromPathname = (pathname = "*") => {
  let application = "EMAILING";
  for (let app in appsUrls) {
    if (
      Object.values(appsUrls[app]).some((url) =>
        new RegExp(url.replace(/\/:.+/g, "") + "(|/[0-9]*(/)?)$").test(
          pathname,
        ),
      )
    ) {
      application = app;
      break;
    }
  }
  return application;
};

const setCurrentAppName = (pathname = "*") => {
  const application = getAppFromPathname(pathname);
  const prevApp = store.getState().params.application;

  if (prevApp != application) {
    store.dispatch(setApplication(application));
  }
};
// force community if id exists as a query param
const setCurrentCommunity = async () => {
  const { auth } = store.getState();
  const history = getHistory();

  const query = new URLSearchParams(history.location.search);
  const communityId = query.get("communityId");
  if (
    !communityId ||
    !auth?.user?.clients?.length ||
    communityId === auth?.currentClient.id
  ) {
    return;
  }
  const community = auth.user?.clients.find(
    (client) => client.id === communityId,
  );
  if (community) {
    await store.dispatch(setCurrentClient(community));
    await store.dispatch(fetchClientSettings());
  }
};

const history = getHistory();
history.listen((location) => {
  setCurrentAppName(location?.pathname);
});

setCurrentAppName(history?.location?.pathname);

const App = () => <Layout>{getRoutes(store)}</Layout>;
if (history.location.search) {
  const searchParams = new URLSearchParams(history.location.search);
  let hashParams = searchParams.get("params") || null;
  const { lng } = store.getState().params;

  let tab = {
    queryToken: "",
    userId: null,
    organization_id: null,
    portal: null,
    newsletterId: null,
    isUaNewsletter: false,
    scheduledDeliveryAt: null,
    idApproval: null,
    action: null,
    isNlBlog: false,
    ebox: false,
    language: lng,
  };
  if (hashParams) {
    clearAllCookies();

    hashParams = decryptWithAES(hashParams);
    const tabQueryParams = hashParams.split(",");
    tabQueryParams.forEach((item) => {
      const tmp = item.split("=");
      if (tmp.length > 0) {
        if (tmp[0] === "token") {
          tab.queryToken = tmp[1];
        } else if (tmp[0] === "userId") {
          tab.userId = tmp[1];
        } else if (tmp[0] === "without_header") {
          tab.uaWithoutHeader = true;
          store.dispatch(setUAHeader(true));
        } else if (tmp[0] === "fiduciary") {
          tab.organization_id = tmp[1];
        } else if (tmp[0] === "newsletterId") {
          tab.newsletterId = tmp[1];
          store.dispatch(setUaNewsletterId(tmp[1]));
        } else if (tmp[0] === "isUaNewsletter") {
          tab.isUaNewsletter = tmp[1];
          store.dispatch(setIsUaNewsletter(tmp[1]));
        } else if (tmp[0] === "scheduledDeliveryAt") {
          tab.scheduledDeliveryAt = tmp[1];
          let scheduledDeliveryAt = moment(tmp[1]);
          store.dispatch(setProgrammedDate(scheduledDeliveryAt));
        } else if (tmp[0] === "idApproval") {
          tab.idApproval = tmp[1];
          store.dispatch(setUaIdApproval(tmp[1]));
        } else if (tmp[0] === "action") {
          tab.action = tmp[1];
          store.dispatch(setUaApprovalAction(tmp[1]));
        } else if (tmp[0] === "isNlBlog") {
          tab.isNlBlog = tmp[1];
          tmp[1] && store.dispatch(setIsNlBlog(tmp[1]));
        } else if (tmp[0] === "ebox") {
          tab.ebox = tmp[1];
          store.dispatch(setUaEbox(tmp[1]));
        } else if (tmp[0] === "lng") {
          tab.language = tmp[1];
          store.dispatch(setLanguage(tmp[1]));
        }
      }
    });
    const authInfos = {
      token: tab.queryToken,
      createdAt: Date.now(),
      expiresIn: 10000,
      id: tab.userId,
    };
    if (
      authInfos &&
      typeof authInfos === "object" &&
      now < parseInt(authInfos.createdAt) + parseInt(authInfos.expiresIn)
    ) {
      loading = true;
      store
        .dispatch(setAuthUser(authInfos))
        .then(async (res) => {
          let lng = getLocaleLanguage();
          let { language, clients } = res.value.data.data[0];
          store.dispatch(setLanguage(tab.language || lng || language || "fr"));
          store.dispatch(fetchWebNotifications());
          const community = clients.find(
            (client) => client.id == tab.organization_id,
          );
          if (community) {
            await store.dispatch(setCurrentClient(community));
            await store.dispatch(fetchClientSettings());
          }
          const uri = getEmailingRedirectStorage();
          if (uri) {
            history.push(uri);
          }
          setEmailingRedirectStorage(null);
          store.dispatch(setIsUaVersion(true));
          await setCurrentCommunity();
        })
        .finally(() => {
          ReactDOM.render(
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <Route component={App} />
              </ConnectedRouter>
            </Provider>,
            document.getElementById("app"),
          );
        });
    }
  }
}

if (userCookie && userCookie.length > 0) {
  const authInfos = JSON.parse(userCookie);
  if (
    authInfos &&
    typeof authInfos === "object" &&
    now < parseInt(authInfos.createdAt) + parseInt(authInfos.expiresIn)
  ) {
    loading = true;
    store
      .dispatch(setAuthUser(authInfos))
      .then(async (res) => {
        let lng = getLocaleLanguage();
        let { language } = res.value.data.data[0];
        store.dispatch(setLanguage(lng || language || "fr"));
        store.dispatch(fetchWebNotifications());
        store.dispatch(fetchClientSettings());
        const uri = getEmailingRedirectStorage();
        if (uri) {
          history.push(uri);
        }
        setEmailingRedirectStorage(null);
        await setCurrentCommunity();
      })
      .finally(() => {
        ReactDOM.render(
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Route component={App} />
            </ConnectedRouter>
          </Provider>,
          document.getElementById("app"),
        );
      });
  }
}

ReactDOM.render(
  loading ? (
    <TTPLoader cssClass={"fixed-loader"} />
  ) : (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route component={App} />
      </ConnectedRouter>
    </Provider>
  ),
  document.getElementById("app"),
);
