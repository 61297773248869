import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import ThemePreferences from './ThemePreferences';
import ThemeItem from './ThemeItem';
import ThemeFetching from 'fetching/ThemeFetching';
import NotFound from 'notFound/NotFound';
import { setCurrentNavPage, clearSourcesTokens, setThemesPaginationPage, setThemeType, setThemeScope } from "actions";
import { fetchTemplates } from 'thunks';
import { TEMPLATE_TYPES } from 'constants';
import _ from 'i18n';
import PageHeader from 'common/PageHeader';
import { STEPS } from 'Common';
import ThemePreview from '../editor/ThemePreview';
import ThemeListFilter from "./filter/ThemeListFilter";
import TTPTab from '../ui/tabs/TTPTab';
import { initTemplatesList } from '../../actions/actions';
import { COMMUNITY_SCOPE } from '../../constants';

@connect()
class ThemesListPage extends Component {

  constructor(props) {
    super(props);
    this.openSettingsModal = () => this.setState({ showSettingsModal: true });

    this.state = {
      selectedItemsIds: [],
      showSettingsModal: false,
      hasMoreItems: true,
      currentTab: "STATIC"
    };
  }

  componentDidMount() {
    let { dispatch, initThemesList, setThemeScope } = this.props;
    dispatch(setCurrentNavPage("TEMPLATES"));
    initThemesList();
    setThemeScope(COMMUNITY_SCOPE);

    dispatch(setThemeType(TEMPLATE_TYPES['DRAG_AND_DROP']));
  }

  closeSettingsModal() {
    let items = document.getElementById('template-page').getElementsByClassName('template-checkbox');
    for (let i = 0; i < items.length; i++) {
      items[i].checked = false;
    }
    this.setState({ showSettingsModal: false, selectedItemsIds: [] });
  }

  showOneThemeSettings(themeId) {
    this.setState({ showSettingsModal: true, selectedItemsIds: [themeId] });
  }

  componentDidUpdate(prevProps) {
    let { filter, dispatch, fetching } = this.props;

    if (fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !this.props.fetching,
      })
    }

    if (prevProps.filter === filter) {
      return;
    }

    filter = { ...filter, getUserAvatar: true, isBlock: true };
    dispatch(fetchTemplates(filter));
  }

  componentWillUnmount() {
    const { _requests } = this.props;
    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    this.props.dispatch(clearSourcesTokens());
  }

  handleItemChange(e) {
    let { selectedItemsIds } = this.state;
    const checked = e.target.checked;
    if (checked) {
      selectedItemsIds.push(e.target.value);
    } else {
      selectedItemsIds = selectedItemsIds.filter(item => item != e.target.value);
    }
    this.setState({ selectedItemsIds });
  }

  loadItems = () => {
    const { filter, dispatch, fetching, nbResult, themes } = this.props;

    if (!fetching && themes.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      dispatch(setThemesPaginationPage(currentPage + 1));
    }
  };

  createTemplate = (type) => {
    this.props.history.push({ pathname: "/drag-drop", state: { type } }
    )
  };

  renderThemes() {
    const { themes, fetching } = this.props;
    const { hasMoreItems } = this.state;

    let items = [];
    if (themes && themes.length > 0) {
      for (let theme of themes) {
        items.push(<ThemeItem
          key={`theme-${theme.id}`}
          {...theme}
          onSettingClick={this.showOneThemeSettings.bind(this, theme.id)}
          onItemChange={this.handleItemChange.bind(this)} />);
      }
    }
    if (fetching) {
      for (let index = 0; index < 5; index++) {
        items.push(<ThemeFetching key={`theme-fetching-${index}`} />);
      }
    }

    return (items.length > 0) ? <InfiniteScroll
      className={`template-list row`}
      pageStart={0}
      loadMore={this.loadItems}
      hasMore={hasMoreItems}
    >
      {items}
    </InfiniteScroll> : <NotFound mainClass={"small-10"} />;
  }

  switchCurrentTab = (tabId) => {
    this.setState({ currentTab: tabId });
    const currentTab = THEME_TYPE_TABS.find(tab => tab.id == tabId);
    this.props.dispatch(setThemeType(currentTab.type));
  }

  render() {
    let { selectedItemsIds, showSettingsModal, currentTab } = this.state;
    const { step, content } = this.props;

    return <div id="template-page"
      ref={(ref) => this.scrollParentRef = ref}>
      <PageHeader {...PAGE_HEADER}>
        <button className="btn secondary text-uppercase"
          onClick={() => this.createTemplate("DRAG_AND_DROP")}>
          <img src="img/icons/add.svg" />
          {_('create')}
        </button>
      </PageHeader>
      <div className={`ttp-tab-container`}>
        <TTPTab tabs={THEME_TYPE_TABS} activeTab={currentTab} selectTab={this.switchCurrentTab} />
        <ThemeListFilter />
        {this.renderThemes()}
      </div>
      {showSettingsModal && <ThemePreferences themesIds={selectedItemsIds} onCloseModal={this.closeSettingsModal.bind(this)} />}
      {step == STEPS["THEME_PREVIEW"] && <ThemePreview dispatch={this.props.dispatch} content={content} previous={{ step: STEPS["THEMES_LIST"], url: "/templates" }} />}
    </div>;
  }
}

const mapStateToProps = (store) => ({
  themes: store.themes.list.items,
  nbResult: store.themes.list.nbResult,
  paginationPage: store.themes.filter.paginationPage,
  fetching: store.themes.list.fetching,
  filter: store.themes.filter,
  currentClient: store.auth.currentClient,
  _requests: store.params.sourcesTokens,
  step: store.params.step,
  content: store.themes.content,
});

const mapDispatchToProps = (dispatch) => ({
  setThemeScope: (scope) => dispatch(setThemeScope(scope)),
  initThemesList: () => dispatch(initTemplatesList()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ThemesListPage);


const THEME_TYPE_TABS = [
  { id: "STATIC", label: "emailing", type: ["DRAG_AND_DROP"] },
  { id: "DYNAMIC", label: "newsletter", type: ["TEMPLATE"] },
  { id: "TEXT", label: "Rich Text", type: ["SIMPLE", "WRAPPER"] }
];

const PAGE_HEADER = {
  header: "models",
  subHeader: "Manage email models and newsletters that are ready to be sent .",
  icon: 'TEMPLATES',
  action: "drag-drop"
};
