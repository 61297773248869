const initialStateForMedia = {
    items: [],
    nbResult: 0,
    fetching: false,
    fetched: false,
    updating: false,
    origin: 'MAILING',  // || MEDIA
    filter: {
        searchWord: "",
        language: null,
        pageSize: 8,
        paginationPage: 1,
        tags: [],
        scope: "private",
    }
}
export const mediaReducer = (state = initialStateForMedia, action) => {
    switch (action.type) {
        case 'INIT_MEDIAS': {
            return initialStateForMedia;
            break;
        }
        case 'FETCH_MEDIAS_PENDING': {
            return {
                ...state,
                fetching: true
            };
            break;
        }

        case 'FETCH_MEDIAS_FULFILLED': {
            const { data, nbResult } = action.payload.data || {};
            const { filter, items } = state;
            let newItems = [];
            if (!data || action.payload.origin != state.origin) {
                newItems = [...items];
            } else if (1 == filter.paginationPage) {
                newItems = data;
            } else {
                newItems = [...items];
                newItems.push(...data);
            }

            return {
                ...state,
                items: newItems,
                fetching: false,
                fetched: true,
                nbResult
            };
            break;
        }

        case 'FETCH_MEDIAS_REJECTED': {
            let { filter, nbResult, items } = state;
            let newItems = items;

            if (filter.paginationPage == 1) {
                newItems = [];
                nbResult = 0;
            }
            let error = action.payload;
            switch (error && error.response && error.response.status) {
                case 404:
                    error = {
                        title: error.response.data.title,
                        code: 404
                    }
                    break;
                default:
            }
            return {
                ...state,
                items: newItems,
                fetching: false,
                fetched: false,
                nbResult,
                error
            };
            break;
        }

        case 'SET_MEDIAS_SEARCH_WORD': {
            return { ...state, filter: { ...state.filter, searchWord: action.searchWord, paginationPage: 1 } };
            break;
        }

        case 'SET_MEDIAS_LANGUAGE': {
            return { ...state, filter: { ...state.filter, language: action.language, paginationPage: 1 } };
            break;
        }

        case 'SET_MEDIAS_PAGINATION_PAGE': {
            return { ...state, filter: { ...state.filter, paginationPage: action.pageNumber } };
            break;
        }

        case 'SET_MEDIAS_ORIGIN': {
            return { ...initialStateForMedia, origin: action.origin };
            break;
        }

        case 'SET_MEDIAS_TAGS': {
            return { ...state, filter: { ...state.filter, tags: action.tags, paginationPage: 1 } };
            break;
        }

        case 'SET_MEDIAS_SCOPE': {
            return { ...state, filter: { ...state.filter, scope: action.scope, paginationPage: 1 } };
            break;
        }

        case 'INIT_MEDIAS_FILTERS': {
            return initialStateForMedia;
        }

        case 'SET_MEDIAS_LIST_SORT': {
            return { ...state, filter: { ...state.filter, sortDirection: action.sortDirection, paginationPage: 1 } };
            break;
        }

        case 'SAVE_MEDIA_PENDING': {
            return { ...state, updating: true };
            break;
        }

        case 'SAVE_MEDIA_FULFILLED': {
            const { data } = action.payload.data;
            let items = state.items;

            let exist = false;
            let newItems = items.map(item => {
                if (item.id === data.id) {
                    exist = true;
                    return data;
                }
                return item;
            })
            if (!exist) {
                newItems.push(data);
            }

            return {
                ...state,
                updating: false,
                fetched: true,
                items: newItems
            };
            break;
        }

        default:
            return state;
    }
};
