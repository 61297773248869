import axios from 'axios';
import {TTP_API_URL} from 'Config';
import moment from 'moment';
import { convertDateToUTC } from '../../services/date';
const getFilter = (filter) => {
  const filters = [];
  if (filter.typeEmail && filter.typeEmail.length > 0) {
    filters.push({
      property: "type",
      value: filter.typeEmail,
      operator: "in"
    });
  }
  if (filter.recipientType && filter.recipientType.length > 0) {
    filters.push({
      property: "recipientType",
      value: filter.recipientType,
      operator: "in"
    });
  }
  if (filter.language && filter.language.length > 0) {
    filters.push({
      property: "language",
      value: filter.language,
      operator: "in"
    });
  }
  return filters;
}

export const getundeliveredMailData = (clientId, filter, token) => {
  const requestUrl = `${TTP_API_URL}/mailing/dashboard/undeliveredMailChart`;
  const filters = getFilter(filter);

  let params = {
    access_token: token,
    year: filter.year,
    clientId: clientId
  };
  params.filter = JSON.stringify(filters);
  return axios.get(requestUrl, {
    params
  });
}

export const getCampaignsData = (clientId, token, filter, typeEmail )=>{
  const requestUrl = `${TTP_API_URL}/mailing/campaign/mails-send`;
  let params = {
    access_token: token,
    period: filter.period,
    year:filter.year,
    month : filter.month.getMonth()+1,
    clientId: clientId,
    language: filter.language,
    type: typeEmail
  }

  return axios.get(requestUrl, {
    params
  });

}

export const getOpensCount = (token,clientId, filter) => {
    const requestUrl = `${TTP_API_URL}/mailing/dashboard/opens`;
    const filters = getFilter(filter);

    let params = {
      access_token: token,
      year: filter.year,
      clientId: clientId
    };

  params.filter = JSON.stringify(filters);
    return axios.get(requestUrl, { params });
}
export const getBestType = (token,clientId, filter) => {
  const requestUrl = `${TTP_API_URL}/mailing/dashboard/besttype`;
  const fields = [];
  const filters = getFilter(filter);
  let params = {
    access_token: token,
    year: filter.year,
    clientId: clientId
  };
  params.filter = JSON.stringify(filters);
  return axios.get(requestUrl, { params });
};

export const getClicksCount = (token,clientId, filter) => {
  const requestUrl = `${TTP_API_URL}/mailing/dashboard/clicks`;
  const filters = getFilter(filter);

  let params = {
    access_token: token,
    year: filter.year,
    clientId: clientId
  };
  params.filter = JSON.stringify(filters);
  return axios.get(requestUrl, { params });
}

export const getScoreData = (clientId, filter, token) => {
  const requestUrl = `${TTP_API_URL}/mailing/commitment-history-chart`;

  let params = {
    access_token: token,
    year:filter.year,
    clientId: clientId
  };

  return axios.get(requestUrl, {
    params
  });
}

export const getStatsHistory = (clientId, token, filter )=>{
  const requestUrl = `${TTP_API_URL}/mailing/stats-history`;
  let params = {
    access_token: token,
    period: filter.period,
    year:filter.year,
    month : filter.month.getMonth()+1,
    clientId: clientId,
    language: filter.language
  }

  return axios.get(requestUrl, {
    params
  });

}

export const getStats = (clientId, token) => {
  const requestUrl = `${TTP_API_URL}/mailing/contact/stats`;
  const charts = [
    {name: "GENDER_CHART", base: ["gender"]},
    {name: "AGE_CHART", base: ["age"]},
    {name: "LANGUAGE_CHART", base: ["language"]},
    {name: "AUTHORIZATION_MODE_CHART", base: ["authorizationMode"]},
  ];

  let params = {
    access_token: token,
    charts: JSON.stringify(charts),
    organization_id: clientId,
  };

  return axios.get(requestUrl, {
    params
  });
}

export const getLinks = (clientId, token, date) => {
  const requestUrl = `${TTP_API_URL}/mailing/link`;
  let filter = [];

  filter.push({
    property: 'createdAt',
    operator: 'gte',
    value: `SPECIAL_CHAR_TO_REPLACEu0027${convertDateToUTC(date)}SPECIAL_CHAR_TO_REPLACEu0027`,
  });
  filter.push({
    property: 'createdAt',
    operator: 'lte',
    value: `SPECIAL_CHAR_TO_REPLACEu0027${convertDateToUTC(moment().subtract(-1,'days').format("YYYY-MM-DD"))}SPECIAL_CHAR_TO_REPLACEu0027`,
  });
  let sort = [
    {
      property: 'clicksCount',
      dir: 'desc'
    }
  ];
  let params = {
    access_token: token,
    limit: 10,
  }

  params.sort = JSON.stringify(sort);
  params.filter = JSON.stringify(filter).replace(/SPECIAL_CHAR_TO_REPLACE/g, '\\');

  return axios.get(requestUrl, {
    params
  });
}
