import React, {Component} from 'react';
import { connect } from 'react-redux';
import PercentageChart from "./PercentageChart";

@connect((store) => {
  return {
    dataLang: store.dashboard.dataLang
  };
})
export default class LanguageChart extends Component {
  render() {
    return (
      <PercentageChart dataBar={this.props.dataLang} />
    );
  }
}
