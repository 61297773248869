import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-notifications/lib/notifications.css';

import { setCurrentNavPage, initRecipients, setProcessCampaign, setCurrentSmsCampaignData, initCurrentSmsCampaignData } from "actions";
import CampaignSMSCountWarning from 'sms/CampaignSMSCountWarning';
import RecipientsTab from './recipients/RecipientsTab';
import SendTab from './send/SendTab';
import ManualSMSRecipients from './recipients/ManualSMSRecipients';
import Params from './Params';
import Bubbles from './actions/Bubbles';
import CampaignActions from './actions/CampaignActions';

import { persistSMSCampaign, fetchSMSCampaign, getAuthorizedCredit } from 'thunks';
import { onSuccess, onError, getBeSMSPriceWithMargin } from 'utils';
import { smsCounter } from "smsCounter"

import _ from 'i18n';
import { amountFormatter } from "utils";
import Notes from "./note/Notes";

@connect((store) => {
  return {
    margin: store.auth.currentClient.profitMargin,
    lng: store.params.lng,
    campaigns: store.campaigns.list.items,
    fetched: store.campaigns.list.fetched,
    smsCredit: store.auth.currentClient.smsCredit,
    currentCampaign: store.currentCampaign.sms
  }
})
export default class CampaignSMSPage extends Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    let { smsCredit } = this.props;

    return {
      sendIsOpened: false,
      status: "DRAFT",
      type: "SMS",
      hasError: { smsMessage: false, campaignName: false },
      maxChars: 160,
      smsCount: 1,
      remaining: 160,
      perMessage: 160,
      smsLength: 0,
      smsApproximateCost: 0,
      encoding: 'GSM_7_BIT',
      displaySMSCountWarning: false,
      containDynamicFields: false,
      smsCredit: smsCredit,
      authorizedCredit: smsCredit,
      modelsIsOpened: false,
      smsModelMessage: ""
    };
  }

  smsModelsCancelAction() {
    let { location, dispatch } = this.props;
    const query = new URLSearchParams(location.search);

    dispatch(setCurrentSmsCampaignData({ smsMessage: (query.get('smsMessage')) ? query.get('smsMessage') : ""  }));
  }

  getSmsCount(smsMessage) {
    let count = smsCounter(smsMessage);

    let smsCount = count.messages;
    let maxChars = count.per_message;
    let remaining = count.remaining;
    let smsLength = (count.length) ? count.length : 0;
    let perMessage = (smsCount) ? count.per_message * smsCount : 160;
    let encoding = count.encoding;

    this.handleSmsMessageEncodingChange(encoding);

    this.props.dispatch(setCurrentSmsCampaignData({ smsMessage}));
    this.setState({
      maxChars,
      smsCount,
      smsLength,
      remaining,
      perMessage,
      encoding,
    });
  }

  handleSmsMessageEncodingChange(encoding) {
    this.setState({ displaySMSCountWarning: 'GSM_7BIT' !== encoding, encoding: encoding });
  }
  handleSmsMessageChange(e) {
    const smsMessage = (e.target) ? e.target.value : e;
    this.props.dispatch(setCurrentSmsCampaignData({ smsMessage }));
    this.getSmsCount(smsMessage);
  }

  handleSmsModelMessageChange() {
    this.props.dispatch(setCurrentSmsCampaignData({ smsMessage: this.state.smsModelMessage }));
    let count = smsCounter(this.state.smsModelMessage);
    let encoding = count.encoding;
    this.setState({ displaySMSCountWarning: 'GSM_7BIT' !== encoding });
  }

  handleCampaignNameChange(e) {
    const campaignName = (e.target) ? e.target.value : e;
    const hasError = this.state.hasError;
    this.props.dispatch(setCurrentSmsCampaignData({ campaignName }));

    this.setState({ hasError: { ...hasError, campaignName: campaignName.length < 2 } });
  }

  componentDidMount() {
    const { dispatch, match: { params: routeParams } } = this.props;
    dispatch(setCurrentNavPage("CREATE_CAMPAIGN_SMS"));
    dispatch(initRecipients());
    dispatch(initCurrentSmsCampaignData());
    if (routeParams.campaignSMSId) {
      dispatch(fetchSMSCampaign(routeParams.campaignSMSId)).then((res) => {
        const { smsCampaignGroups, message, manualSmsRecipients, recipientType, language, name, status, type } = res.value?.data.data[0];
        let allowedGroups = [];
        let deniedGroups = [];
          if (smsCampaignGroups && smsCampaignGroups.length > 0) {
            smsCampaignGroups.forEach((campaignGroup) => {
              if (campaignGroup.status === "INCLUDE") {
                allowedGroups.push(campaignGroup.group.id.toString());
              } else if (campaignGroup.status === "EXCLUDE") {
                deniedGroups.push(campaignGroup.group.id.toString());
              }
            });
          }
          this.getSmsCount(message);
          this.setState({
            status: status,
            type: type,
          });
          dispatch(setCurrentSmsCampaignData({
            recipientType,
            language,
            allowedGroups,
            deniedGroups,
            campaignName: name,
            smsMessage: message,
            manualSmsRecipients: (manualSmsRecipients || [])
          }));
      });
    }
    this.getClientAuthorizedCredit();
  }

  getClientAuthorizedCredit() {
    const { dispatch } = this.props;
    dispatch(getAuthorizedCredit()).then(res =>
      this.handleAuthorizedCredit(res.value.data.data.result), err => onError());
  }

  componentDidUpdate(prevProps) {
    let { currentCampaign } = this.props;
    if (currentCampaign !== prevProps.currentCampaign) {
      return;
    }
  }

  componentWillUnmount() {
    let bodyTag = document.getElementsByTagName('body');
    bodyTag[0].style.overflow = 'unset';
  }

  isInValidData() {
    const { campaignName, smsMessage } = this.state.hasError;
    return (smsMessage || campaignName);
  }

  mapSmsCampaignGroupIds(smsCampaignGroups) {
    let oldSmsCampaignGroups = [];
    let { match: { params: routeParams } } = this.props;

    if (smsCampaignGroups.length === 0 || !routeParams.campaignSMSId || !this.props.campaigns) {
      return smsCampaignGroups
    }
    oldSmsCampaignGroups = this.props.campaigns[0].smsCampaignGroups;
    oldSmsCampaignGroups.forEach((oldSmsCampaignGroup) => {
      for (let i = 0; i < smsCampaignGroups.length; i++) {
        if (smsCampaignGroups[i].group === oldSmsCampaignGroup.group.id && smsCampaignGroups[i].status === oldSmsCampaignGroup.status) {
          smsCampaignGroups[i].id = oldSmsCampaignGroup.id;
        }
      }
    });
    return smsCampaignGroups;
  }

  getSmsCampaignGroups() {
    let smsCampaignGroups = [];
    let { allowedGroups, deniedGroups } = this.props.currentCampaign;
    if (!(allowedGroups instanceof Array)) {
      allowedGroups = allowedGroups.split(',');
    }
    if (!(deniedGroups instanceof Array)) {
      deniedGroups = deniedGroups.split(',');
    }
    if (allowedGroups.length > 0) {
      for (let i = 0; i < allowedGroups.length; i++) {
        if (allowedGroups[i]) {
          smsCampaignGroups.push({ "group": allowedGroups[i], "status": "INCLUDE" });
        }
      }
    }
    if (deniedGroups.length > 0) {
      for (let i = 0; i < deniedGroups.length; i++) {
        if (deniedGroups[i]) {
          smsCampaignGroups.push({ "group": deniedGroups[i], "status": "EXCLUDE" });
        }
      }
    }
    return this.mapSmsCampaignGroupIds(smsCampaignGroups);
  }

  saveCampaign(action) {
    if (this.isInValidData()) {
      return;
    }
    const { status, type, smsCount, smsApproximateCost } = this.state;
    const { match: { params: routeParams }, dispatch, currentCampaign: { manualSmsRecipients, recipientType, language, testPhoneNumbers, campaignName, smsMessage } } = this.props;
    let data = {
      campaignName, language, smsMessage, recipientType, status, type, action, smsApproximateCost,
      'smsCampaignGroups': this.getSmsCampaignGroups(), smsCount, manualSmsRecipients
    };
    let notification = { body: 'campaignAdded', title: 'addCampaignSuccess' };

    if (recipientType === "MANUAL") {
      data.smsApproximateCost = this.getApproximateCost();
    }

    action === 'DRAFT' ? dispatch(setProcessCampaign(true, false)) : dispatch(setProcessCampaign(false, true));

    if (routeParams.campaignSMSId) {
      data.id = routeParams.campaignSMSId;
    }

    if (action === 'TEST') {
      data.testPhoneNumbers = testPhoneNumbers.map((phoneNumber) => phoneNumber.id);
      notification = { body: 'campaignSMSTestSent', title: 'campaignTestSuccess' };
      this.getClientAuthorizedCredit();
    } else if (action === 'SEND') {
      data.status = 'CREATED';
      notification = { body: 'campaignSent', title: 'campaignSentSuccess' };
    }

    dispatch(persistSMSCampaign(data)).then(res => onSuccess(res, notification), err => onError(err));
  }

  isSendingDisabled() {
    const { language, recipientType, campaignName, smsMessage } = this.props.currentCampaign;
    return !(campaignName && campaignName.length > 1 && smsMessage && smsMessage.length > 0
      && language && language.length > 0 && recipientType && recipientType.length > 0);
  }

  isCreditSufficient() {
    let { authorizedCredit } = this.state;
    return (authorizedCredit > 0);
  }

  addDynamicField(selectedField) {
    const { currentCampaign: { smsMessage } } = this.props;

    this.props.dispatch(setCurrentSmsCampaignData({ smsMessage: smsMessage + selectedField }));
    this.getSmsCount(smsMessage + selectedField);
  }

  handleSmsApproximateCostChange(totalPriceByCountry) {
    let { smsCount } = this.state;
    this.setState({ smsApproximateCost: totalPriceByCountry * smsCount });
  }

  addModelDynamicField(selectedField) {
    this.setState({ smsModelMessage: this.state.smsModelMessage + selectedField });
  }

  containDynamicFields(e) {
    this.setState({ containDynamicFields: e });
  }

  handleAuthorizedCredit(credit) {
    this.setState({ authorizedCredit: credit });
  }

  getApproximateCost() {
    let { smsCount } = this.state;
    const { manualSmsRecipients } = this.props.currentCampaign;
    if (!manualSmsRecipients || manualSmsRecipients.length === 0) {
      return 0;
    }
    return smsCount * getBeSMSPriceWithMargin(this.props.margin) * manualSmsRecipients.length;
  }

  toggleSideBar(sidebar) {
    this.props.dispatch(setCurrentSmsCampaignData({ currentSideBar: sidebar }));
    let bodyTag = document.getElementsByTagName('body');
    if (sidebar) {
      this.handleScroll();
      bodyTag[0].style.overflow = 'hidden';
    } else {
      bodyTag[0].style.overflow = 'unset';
    }
  };

  handleScroll() {
    let mainMenu = document.getElementById('mainMenu');
    if (!mainMenu) {
      return;
    }
    if (window.scrollY > 0) {
      let newHeight = mainMenu.offsetHeight - window.scrollY;
      this.handleSideBarMarginTop(newHeight > 0 ? newHeight : 0);
    } else {
      this.handleSideBarMarginTop(mainMenu.offsetHeight);
    }
  }

  handleSideBarMarginTop(mainMenuHeight) {

    let campaignPage = document.getElementById('sms-campaign-page');

    if (!campaignPage) {
      return;
    }
    let overlay = campaignPage.getElementsByClassName('overlay');
    if (overlay && overlay.length > 0) {
      overlay[0].style.marginTop = `${mainMenuHeight}px`;
    }
    let sidebar = campaignPage.getElementsByClassName('sidebar');
    if (sidebar && sidebar.length) {
      for (let i = 0; i < sidebar.length; i++) {
        sidebar[i].style.marginTop = `${mainMenuHeight}px`;
      }
    }
  }

  renderNotes() {
    let { smsApproximateCost } = this.state;
    const { currentCampaign: { recipientType, campaignName, smsMessage }, smsCredit } = this.props;
    let manualSmsApproximateCost = this.getApproximateCost();

    const approximateCost = ('MANUAL' === recipientType) ? manualSmsApproximateCost : smsApproximateCost;

    return <Notes
      onToggleSideBar={this.toggleSideBar.bind(this)}
      campaignNameValid={campaignName && campaignName.length > 2}
      smsMessageValid={smsMessage && smsMessage.length > 0}
      hasEnoughCredit={approximateCost < smsCredit}
      recipientType={recipientType}
    />;
  }

  render() {
    let { type, displaySMSCountWarning, encoding, maxChars, smsCount, authorizedCredit,
      smsLength, perMessage, remaining, hasError, smsApproximateCost, containDynamicFields, manualSmsRecipients, smsModelMessage } = this.state;

    const { currentCampaign: { language, recipientType, currentSideBar }, match: { params: routeParams }} = this.props;
    let manualSmsApproximateCost = this.getApproximateCost();
    const sendingDisabled = this.isSendingDisabled();

    return (
      <div id="sms-campaign-page">
        <CampaignActions
          onSave={this.saveCampaign.bind(this, "DRAFT")}
          saveDisabled={sendingDisabled}
          typeCampaign={type}
        />
        <Params
          hasError={hasError}
          onSMSMessageChange={this.handleSmsMessageChange.bind(this)}
          onCampaignNameChange={this.handleCampaignNameChange.bind(this)}
          maxChars={maxChars}
          smsCount={smsCount}
          smsLength={smsLength}
          perMessage={perMessage}
          remaining={remaining}
          onAddDynamicField={this.addDynamicField.bind(this)}
          onContainDynamicFields={this.containDynamicFields.bind(this)}
          onCancelAction={this.smsModelsCancelAction.bind(this)}
          onSmsModelMessageChange={(e) => this.setState({ smsModelMessage: (e.target) ? e.target.value : e })}
          smsModelMessage={smsModelMessage}
          applySmsModelMessage={this.handleSmsModelMessageChange.bind(this)}
          onAddModelDynamicField={this.addModelDynamicField.bind(this)}
          onTest={this.saveCampaign.bind(this, "TEST")}
          sendingDisabled={sendingDisabled || !this.isCreditSufficient()}
          smsApproximateCost={smsApproximateCost}
          routeParams={routeParams}
        />
        {displaySMSCountWarning && <CampaignSMSCountWarning encoding={encoding} />}
        <Bubbles
          amount={amountFormatter(authorizedCredit, 2, language)}
          sendingDisabled={sendingDisabled}
          toggleSideBar={this.toggleSideBar.bind(this)} />
        <div className="overlay" style={(currentSideBar ? { display: "block" } : { display: "none" })} onClick={this.toggleSideBar.bind(this, null)} />

        <div className={(currentSideBar ? "sidebar sidebar--opened" : "sidebar")}>
          {this.renderNotes()}
          <ManualSMSRecipients
            recipientType={recipientType}
            smsCount={smsCount}
            language={language}
            manualSmsApproximateCost={manualSmsApproximateCost}
          />
          <RecipientsTab
            recipientType={recipientType}
            language={language}
            smsCount={smsCount}
            onSmsApproximateCostChange={this.handleSmsApproximateCostChange.bind(this)}
            containDynamicFields={containDynamicFields}
          />
          <SendTab
            recipientType={recipientType}
            language={language}
            typePage={type}
            onConfirm={this.saveCampaign.bind(this, "SEND")}
            onRef={ref => (this.child = ref)}
            smsCount={smsCount}
            remaining={remaining}
            smsApproximateCost={smsApproximateCost}
            containDynamicFields={containDynamicFields}
            manualSmsRecipients={manualSmsRecipients}
            manualSmsApproximateCost={manualSmsApproximateCost}
          />
        </div>
      </div>
    );
  }
}
