import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  EditorState,
  Modifier,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
} from 'draft-js';
import _ from "i18n";
import FieldsSelector from './FieldsSelector';
import {replaceEmailContent} from '../../../services/utils';
import {EVENT_TYPE, WTB_TYPE} from "../../../constants/dynamicFields";
import {fetchAppDynamicsFields} from "../../../actions/thunks/params";
import SunEdit from "./SunEdit";
import {
  setTextModelsLanguage,
  setTextModelsTargetApp,
} from "actions";
import FillFreeSectionForm from "../../text-model/FillFreeSectionForm";
import TextModelEboxWidget from "../../eBox/widget/TextModelEboxWidget";
import {NotificationManager} from "react-notifications";
import {setCurrentEmailCampaignData} from "../../../actions/actions/currentCampaign";
import {setEboxMessage} from "../../../actions/actions/ebox/widget";
import RichEditorPreview from "../../eBox/widget/RichEditorPreview";

class RichEditor extends Component {
  state = {
    showFieldsSelector: false,
    currentTab: this.props.mode !== "REDUCED" ? "editor" : "text model",
    activeInput: "", // editor|subject
  };

  keyBindingFn = (event) => {
    if (KeyBindingUtil.hasCommandModifier(event) && event.keyCode === 65) {
      return "selectAll";
    }
    return getDefaultKeyBinding(event);
  };

  handleKeyCommand = (command) => {
    const {content, onEditorChange} = this.props;
    const currentContent = content.getCurrentContent();
    const firstBlock = currentContent.getBlockMap().first();
    const lastBlock = currentContent.getBlockMap().last();
    const firstBlockKey = firstBlock.getKey();
    const lastBlockKey = lastBlock.getKey();
    const lengthOfLastBlock = lastBlock.getLength();
    let selectionState = content.getSelection();
    let selection = selectionState.merge({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: lastBlockKey,
      focusOffset: lengthOfLastBlock,
    });

    if (command === "selectAll") {
      onEditorChange(EditorState.forceSelection(content, selection));
      return "handled";
    }
    return "not-handled";
  };

  handleReturn = () => {
    const {content, onEditorChange} = this.props;
    onEditorChange(RichUtils.insertSoftNewline(content));
    return "handled";
  };

  toggleFieldsSelector = () => {
    this.setState((prevState) => ({
      showFieldsSelector: !prevState.showFieldsSelector,
    }));
  };

  addField = (field) => {
    const {content, onEditorChange, onObjectChange, subject} = this.props;
    if (this.state.activeInput === "editor") {
      let currentContent = content.getCurrentContent();
      let selectionState = content.getSelection();
      let selection = selectionState.merge({
        anchorKey:
          selectionState.getAnchorKey() !== selectionState.getFocusKey()
            ? selectionState.getFocusKey()
            : selectionState.getAnchorKey(),
        anchorOffset: selectionState.getFocusOffset(),
        focusOffset: selectionState.getFocusOffset(),
        focusKey: selectionState.getFocusKey(),
      });

      const contentState = Modifier.insertText(
        currentContent,
        selection,
        " " + field + " "
      );
      onEditorChange(
        EditorState.push(content, contentState, "insert-fragment")
      );
    } else {
      onObjectChange(`${subject ? subject + " " : ""}${field}`);
    }
  };

  selectTab = (currentTab) => {
    if (currentTab === "free section") {
      this.props.setIsFreeSectionTab(true);
    } else if (currentTab === "text model") {
      this.props.setIsFreeSectionTab(false);
      this.props.setIsTextModelTab(true);
    }
    else {
      this.props.setIsFreeSectionTab(false);
      this.props.setIsTextModelTab(false);
    }

    if (this.props.editorContent.includes("data-type") && ['editor', 'preview'].indexOf(currentTab) > -1 && !this.props.isNextValid) {
      this.setState({currentTab: "free section"});
      NotificationManager.warning(_('enterFreeSection'), _('warning'));
    } else {
      this.setState({currentTab});
    }
  };

  onFocusChange = (activeInput) => {
    this.setState({activeInput});
  };

  initDynamicFields = () => {
    const {fetchAppDynamicsFields, eventFieldsFetched, fieldsType, wtbFieldsFetched} = this.props;

    if (![EVENT_TYPE, WTB_TYPE].includes(fieldsType)) {
      return;
    }

    if ((fieldsType === WTB_TYPE && !wtbFieldsFetched) || (fieldsType === EVENT_TYPE && !eventFieldsFetched)) {
      fetchAppDynamicsFields(fieldsType);
    }
  };

  componentDidMount() {
    this.initDynamicFields();
    this.props.setEboxMessage("");
    this.props.setCurrentCampaign({ textModel: null, html: "", textModelTitle: ""});
    if (this.state.currentTab === "text model") {
      this.props.setIsTextModelTab(true);
    }

  }

  componentDidUpdate(prevProps, prevState) {
    const {editorContent, isNextValid, isNext, isNextTextModel} = this.props;
    if (prevProps.fieldsType !== this.props.fieldsType) {
      this.initDynamicFields();
    }

    // Manage free section | text model tabs' scenarios
    if (prevProps.editorContent !== editorContent && editorContent.includes("data-type") && !TABS.includes("free section")) {
      TABS.splice(1, 0, "free section");
      this.selectTab("free section");
    } else if ((TABS[1] === "free section" && prevProps.editorContent !== editorContent && !editorContent.includes("data-type"))) {
      this.selectTab("editor");
      TABS.splice(1, 1);
    } else if (TABS[1] === "free section" && prevProps.editorContent !== editorContent && editorContent.includes("data-type")) {
      this.selectTab("free section");
    } else if ((prevProps.isNextValid !== isNextValid && isNextValid) || (prevProps.isNext !== isNext && isNext)) {
      this.selectTab("editor");
      this.props.cancelIsNext("FREE_SECTION");
    } else if ( prevState.currentTab !== this.state.currentTab && this.state.currentTab !== "free section") {
      this.props.cancelIsNext("FREE_SECTION");
    } else if (isNextTextModel && this.state.currentTab === "text model"){
      TABS[1] === "free section" ? this.selectTab("free section") : this.selectTab("editor");
      this.props.cancelIsNext("TEXT_MODEL");
    }
  }

  render() {
    const {lng, content, onEditorChange, fieldsType, onObjectChange, subject, mode, subjectHasError, editorContent, theme, embedded} = this.props;
    const {currentTab, activeInput} = this.state;

    if (!editorContent && TABS[1] === "free section") {
      TABS.splice(1, 1);
    }
    return (
      <div className={`rich-editor-container`}>
        {mode === "REDUCED" && <div className={"rich-editor-tabs"}>
          {TABS.map(tab => (
            <div key={tab} className={`${currentTab === tab ? "active" : ""}`}
                 onClick={() => this.selectTab(tab)}>{_(tab)}</div>))}
          <span style={{left: (TABS.indexOf(currentTab) * 150) + "px"}}/>
        </div>}
        <div className={`rich-editor ${currentTab !== "editor" ? "hide" : ""}`}>
          <Subject
            subject={subject}
            onChange={onObjectChange}
            hasError={subjectHasError}
            onFocus={this.onFocusChange}
            isActive={activeInput === "subject"}
          />
          <SunEdit
            onAddField={this.addField}
            changed={onEditorChange}
            onObjectChange={onObjectChange}
            content={content}
            editorActive={activeInput === 'editor'}
            fieldsType={fieldsType}
            onClick={() => this.onFocusChange("editor")}
            mode={mode}
          />
        </div>
        {(currentTab === "preview") && (
          <div
            className={`rich-editor-preview ${(currentTab !== "preview") ? 'hide' : ""}`}>
            <RichEditorPreview currentTab={currentTab}
                               content={content}
                               subject={subject}
                               theme={theme}/>
          </div>
        )
        }
        { (currentTab === "free section") && (
            <div className={`${(currentTab !== "free section") ? 'hide' : ""}`}>
            <FillFreeSectionForm content={editorContent} isExpressEditor={true}
                                 handleSaveFs={() => this.selectTab('editor')}/>
          </div>
          )
        }
        { (currentTab === "text model") && (
            <div className={`rich-editor-model`}>
              <TextModelEboxWidget   URLtargetApp={this.props.URLtargetApp}
                                     URLlanguage={this.props.URLlanguage}
                                     URLtargetType={this.props.URLtargetType}
                                     paramstargetApp={this.props.paramstargetApp} paramstargetType={this.props.paramstargetType} paramsLanguage={this.props.paramsLanguage} mode={mode} onObjectChange={onObjectChange}
                               onEditorChange={onEditorChange} embedded={embedded}/>
            </div>
          )
        }

        {this.state.showFieldsSelector &&
        <FieldsSelector fieldsType={fieldsType} lng={lng}
                        close={this.toggleFieldsSelector}
                        onAddField={this.addField}/>}
      </div>
    );
  }
}

export const TABS = ["text model", "editor", "preview"];
const mapDispatchToProps = (dispatch) => ({
  fetchAppDynamicsFields: (appName) => dispatch(fetchAppDynamicsFields(appName)),
  setTextModelsTargetApp: (targetApp) => dispatch(setTextModelsTargetApp(targetApp)),
  setTextModelsLanguage: (language) => dispatch(setTextModelsLanguage(language)),
  setCurrentCampaign: data => dispatch(setCurrentEmailCampaignData(data)),
  setEboxMessage: message => dispatch(setEboxMessage(message)),
});
const mapStateToProps = (state) => ({
  lng: state.params.lng,
  fields: state.ebox.widget.fields,
  eventFieldsFetched: state.params.dynamicsFields.event.fetched,
  wtbFieldsFetched: state.params.dynamicsFields.wtb.fetched,
  editorContent: state.ebox.widget.message,
  language: state.auth.user.language,
});
export default connect(mapStateToProps, mapDispatchToProps)(RichEditor);

const CustomizeButton = (props) => {
  const {label, icon, onClick} = props;
  return <div className={"rdw-block-wrapper"}>
    <div className="toolbar__option-custom-wide rdw-dropdown-wrapper">
      <a className="rdw-dropdown-selectedtext" onClick={onClick}>
        <i className={`icon ${icon}`}/>
        <span className="">{label}</span>
      </a>
    </div>
  </div>;
};

const Subject = ({subject, hasError, onChange, onFocus, isActive}) => <div
  className={`rich-editor__subject ${isActive ? "is-active" : ""}`}>
  <input type={"text"}
         className={`${hasError ? "is-invalid" : ""} ${subject ? "filled-box" : "empty-box"}`}
         placeholder={_('subject')}
         value={subject}
         onChange={onChange}
         onFocus={() => onFocus("subject")}/>
  <span
    className={hasError ? 'form-error is-visible' : 'form-error'}> {_('invalidObject')}</span>
</div>;
