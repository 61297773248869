import React, { Component } from "react";
import { getLanguages } from "utils";
import _ from "i18n";
import SearchBox from "../common/filter/SearchBox";
import { SCOPE_TYPES } from "../../config/Common";
import { SCOPE_TABS, RESTRICTED_MODE } from "../../constants";
import TTPTab from "../ui/tabs/TTPTab";
import { connect } from "react-redux";
import { initTextModels } from "../../actions/actions/textModel";

@connect()
export default class HorizontalFilters extends Component {
  constructor(props) {
    super(props);

    this.scopeOptions = SCOPE_TYPES.map(({ value, label }) => {
      return { label: _(label), value };
    });

    this.state = { currentTab: "COMMUNITY", filters: this.props.filters };
  }

  componentDidMount() {
    if (this.props.language) {
      this.props.setTextModelsLanguage(this.props.language);
    }
    if (this.props.targetApp) {
      this.props.setTextModelsTargetApp(this.props.targetApp);
    }
    if (this.props.targetType) {
      this.props.setTextModelsTargetType(this.props.targetType);
    }
  }

  switchTab = (currentTab) => {
    this.setState({ ...this.state, currentTab });
    this.props.setTextModelsScope(
      currentTab === "COMMUNITY" ? ["COMMUNITY"] : ["TAMTAM"],
    );
  };

  componentDidUpdate(prevProps) {
    const { scope, filters } = this.props;
    if (!scope && prevProps.scope !== scope) {
      setTextModelsScope(["COMMUNITY", "TAMTAM"]);
    }
    if (filters !== prevProps.filters) {
      this.setState({ ...this.state, filters });
    }
  }

  handleSelectScope = (selected) => {
    this.props.setTextModelsScope([selected]);
  };

  handleSearchInputChange(word) {
    this.props.setTextModelsSearchWord(word);
  }

  render() {
    let { searchWord, mode, scope, isWidget, embedded } = this.props;
    const { currentTab, filters } = this.state;

    let PreFilter = (
      <div
        className={`filter-container `}
        style={
          !isWidget
            ? { marginRight: "-1rem" }
            : isWidget && filters[2].options.length > 0
              ? {}
              : { marginRight: "10.5rem" }
        }
      >
        {filters.map(
          (filter) =>
            (filter.value ||
              (filter.options.length > 0 &&
                !filter.value &&
                filter.label === "type")) && (
              <select
                className={`ttp-page-size m-l-xs ${
                  !embedded && "select-filter"
                }`}
                value={filter.value}
                onChange={filter.action}
              >
                {[{ label: _("all").toUpperCase(), value: null }]
                  .concat(filter.options)
                  .map(({ value, label }) => {
                    return <option value={value}>{label}</option>;
                  })}
              </select>
            ),
        )}
      </div>
    );

    return (
      <div className="row column">
        {RESTRICTED_MODE === mode ? (
          <TTPTab
            tabs={SCOPE_TABS}
            type="simple"
            activeTab={currentTab}
            selectTab={this.switchTab}
          />
        ) : (
          <div id="ttp-horizontal-filter" className="medium-12">
            <div
              className="filter-wrapper"
              style={{ justifyContent: "space-between" }}
            >
              <div className="filter-wrapper__tabs" style={{ flexGrow: 0 }}>
                {[{ label: _("all"), value: null }]
                  .concat(this.scopeOptions)
                  .map(({ value, label }) => (
                    <span
                      key={value}
                      onClick={() => this.handleSelectScope(value)}
                      className={`${
                        scope[0] === value || (!scope[0] && !value)
                          ? "active"
                          : ""
                      }`}
                    >
                      {_(label)}
                    </span>
                  ))}
              </div>
              <div>{PreFilter}</div>
              <SearchBox
                value={searchWord}
                onChange={this.handleSearchInputChange.bind(this)}
                cssClass={`medium-3`}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
