import React, { Component } from "react";
import { components } from "react-select";
import Creatable from 'react-select/creatable';
import debounce from "lodash.debounce";

export default class CustomCreatable extends Component {

  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      createdOption: value ? { label: value, value } : null,
      options: props.options,
      value: null
    };
    this.publishChange = props.withoutDebounce ? this.props.onChange : debounce(this.props.onChange, 1000);
  }

  onEditChange = (e) => {
    e.stopPropagation();
    const newOption = {
      id: e.target.id,
      label: e.target.label,
      value: e.target.value
    };

    this.setState({
      createdOption: newOption
    });

    this.publishChange(newOption);
  }

  SingleValue = props => {
    return true || props.data.__isNew__ ? (
      <input
        type="text"
        value={props.data.label}
        onChange={this.onEditChange}
        className={"custom-creatable__input"}
        onKeyDown={e => {
          if (e.target.value.length > 0) {
            // important: this will allow the use of backspace in the input
            e.stopPropagation();
          } else if (e.key === "Enter") {
            // bonus: if value is empty, press enter to delete custom option
            e.stopPropagation();
            props.setValue(null);
          }
        }}
      />
    ) : (
        <components.SingleValue {...props} />
      );
  };

  onChange = (option, action) => {
    if (action.action === "create-option") {
      // save custom option into seperate state attribute
      this.setState({
        createdOption: option,
        value: null
      });
    } else {
      this.setState({
        value: option,
        createdOption: null
      });
    }
    this.publishChange(option);
  };

  render() {
    const { placeholder, onCreateOption, isLoading, selectedOption } = this.props;
    const { options, value, createdOption } = this.state;

    return <Creatable
      className="ttp-select custom-creatable"
      classNamePrefix="ttp-select"
      options={options}
      onChange={this.onChange}
      isLoading={isLoading}
      isDisabled={isLoading}
      onCreateOption={onCreateOption}
      value={createdOption || value || (onCreateOption && selectedOption) }
      placeholder={placeholder}
      components={{
        SingleValue: this.SingleValue
      }}
      isClearable
    />
  }
}
