import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SMS_BILLS_TYPE, SMS_BILLS_STATUS } from 'Common';
import { setSMSBillsPageSize, setSMSBillsPaginationPage, setSmsBillsListFilterType, setSmsBillsListFilterStatus } from "actions";
import {fetchSmsBills} from 'thunks';

import _ from 'i18n';
import TTPFilterHorizontalBar from 'common/filter/TTPFilterHorizontalBar';

@connect((store) => {
    return {
        smsBills: store.smsBills.list,
        pageSize: store.smsBills.list.pageSize,
    }
})
export default class SMSBillsFilter extends Component {

    constructor(props) {
        super(props);

        this.typeOptions = SMS_BILLS_TYPE.map(type => {
            return { label: _(type), value: type }
        });
        this.statusOptions = SMS_BILLS_STATUS.map(type => {
            return { label: _(type), value: type }
        })
    };

    handleSMSBillsPageSizeChange = (inputValue) => {
        this.props.dispatch(setSMSBillsPageSize(inputValue));
        this.renderSmsBillsListPage(1);
    };

    handleSelectBillType = (smsBillType) => {
        const {dispatch} = this.props;
        dispatch(setSmsBillsListFilterType(smsBillType));
        this.renderSmsBillsListPage(1);
    };

    handleSelectBillStatus = (smsBillStatus) => {
        const {dispatch} = this.props;
        dispatch(setSmsBillsListFilterStatus(smsBillStatus));
        this.renderSmsBillsListPage(1);
    };

    renderSmsBillsListPage(paginationPage, pageSize = null) {
        const {dispatch} = this.props;
        dispatch(setSMSBillsPaginationPage(paginationPage));
        if (pageSize) {
            dispatch(setSMSBillsPageSize(pageSize));
        }
        dispatch(fetchSmsBills(true));
    };

    render () {
        let {smsBills, pageSize} = this.props;

        return (
            <div className="row align-center">
                <TTPFilterHorizontalBar
                    filters={[
                        { placeholder: _("type"), options: this.typeOptions, values: smsBills.smsBillType, action: this.handleSelectBillType, isSimple: true },
                        { placeholder: _("status"), options: this.statusOptions, values: smsBills.status, action: this.handleSelectBillStatus, isSimple: true },
                    ]}
                    pageSize={pageSize}
                    handleListPageSizeChange={this.handleSMSBillsPageSizeChange}
                    cssClass="medium-12 columns"
                />
            </div>
        );
    }
};
