import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import _ from 'i18n';
import { setStep } from "actions";

class ThemePreview extends Component {
  constructor(props) {
    super(props);
  }

  closePreview = () => {
    const { previous: { step, url }, history } = this.props;
    url && history.push(url);
    this.props.dispatch(setStep(step));
  }

  componentWillUnmount() {
    this.closePreview();
  }

  render() {
    const { content } = this.props;

    return (
      <div>
        <span id="theme-preview_close" className="" onClick={this.closePreview}>
          <i className="icomoon icon-tt-close" />
        </span>
        <div id="theme-preview">
          <div className="container-desktop">
            <div className="content" >
              <iframe id="frame" srcDoc={content} />
            </div>
          </div>
          <div className="container-smartphone">
            <div className="content" >
              <iframe id="frame" srcDoc={content} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ThemePreview);
