import * as actions from 'actions';

import { saveSmsBill, getSMSBills, getCountryPrice, getSMSBill, validateSmsCreditRequest } from 'TamtamAPI';
import { getHistory } from '../../router/getHistory';

export const persistSMSOrderPrice = (price) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    let userId = getState().auth.user.id;

    return dispatch(actions.createSMSBill(
      saveSmsBill(token, price, clientId, userId).then((res) => {
        const history = getHistory();
        history.push('/sms_bills');
        let newItem = res.data.data;
        return res.data.data;
      })
    ));
  };
};

export const fetchSmsBills = (isBillsPage = false) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    const { paginationPage, pageSize, status, smsBillType } = getState().smsBills.list;
    const { sortConfig } = getState().smsBills.sort;
    let clientId = null;

    if (isBillsPage) {
      clientId = getState().auth.currentClient.id;
    }

    return dispatch(actions.fetchSMSBills(
      getSMSBills(token, clientId, status, smsBillType, isBillsPage, paginationPage, pageSize, sortConfig)
    ));
  };
};

export const getCountrySmsPrice = (countryCode) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;

    return dispatch(actions.getCountryPrice(
      getCountryPrice(token, clientId, countryCode)
    ));

  };
};

export const getSMSBillById = (smsBillId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.getSMSBill(
      getSMSBill(token, smsBillId)
    ));

  };
};

export const validateCreditRequest = (creditRequestId, status) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.validateSmsBill(
      validateSmsCreditRequest(token, creditRequestId, status).then((res) => {
        return res.data.data;
      }, err => err)
    ));
  };
};


