import React, { PureComponent } from "react";

export default class LayoutItemFetching extends PureComponent {

  render() {
    return (<div className="layout-item flex-container">
        <div className="layout-fetching gradiant"/>
        <div className="drop-down-option">
            <span className="icon-options-vertical"></span>
        </div>
    </div>);
  }
}
