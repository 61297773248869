export const TEMPLATE_TYPES = {
  SIMPLE: "SIMPLE",
  DRAG_AND_DROP: "DRAG_AND_DROP",
  NEWS_LAYOUT: "NEWS_LAYOUT",
  SPEAKER_LAYOUT: "SPEAKER_LAYOUT",
  SLOT_LAYOUT: "SLOT_LAYOUT",
  FORMATION_LAYOUT: "FORMATION_LAYOUT",
  TEMPLATE: "TEMPLATE",
  STATIC_BLOCK: "STATIC_BLOCK",
  WRAPPER: "WRAPPER",
  UNREFERENCED_BLOCK: "UNREFERENCED_BLOCK",
};

export const COMMUNITY_SCOPE = "COMMUNITY";
export const TAMTAM_SCOPE = "TAMTAM";
export const SHARED_MODELS_SCOPE = "SHARED_MODELS";

export const SURVEY_TYPES = [
  {
    key: "INVITATION",
    value: "invitation",
    detail: "survey_invitation_detail",
  },
  { key: "REVIVAL", value: "revival", detail: "survey_revival_detail" },
  {
    key: "PARTIAL_REVIVAL",
    value: "partial revival",
    detail: "survey_partial_revival_detail",
  },
  {
    key: "PARTICIPATIONS",
    value: "participations",
    detail: "survey_participations_detail",
  },
  {
    key: "SURVEY_RESULTS",
    value: "survey results",
    detail: "survey_results_detail",
  },
];

export const EVENT_VISITOR_TYPES = [
  { id: "-1", label: "undefined" },
  { id: "0", label: "invited" },
  { id: "1", label: "congress" },
  { id: "2", label: "staff member" },
  { id: "3", label: "partner" },
  { id: "4", label: "institute" },
  { id: "5", label: "speaker" },
  { id: "6", label: "organization" },
  { id: "10", label: "guest" },
];

export const ROLE_TYPES = {
  // key: 'ALL', value: 'All',
  "Emailing roles": [
    { key: "1ADMIN", value: "admin" },
    { key: "1USER", value: "user" },
  ],
  "Organization roles": [
    { key: "2LEGAL_REPRESENTATIVE", value: "Legal Representative" },
    { key: "2MANAGER", value: "Manager" },
    { key: "2OFFICIAL", value: "Official" },
    { key: "2EXTERNAL", value: "External" },
  ],
};

export const GE_SETTING_STATUS = [
  { key: "DRAFT", value: "DRAFT" },
  { key: "PENDING", value: "PENDING" },
];

export const EBOX_SETTING_STATUS = [
  { key: "SENT", value: "SENT", color: "#59A6EF" },
  { key: "RECEIVED", value: "RECEIVED", color: "#b2bcc6" },
  { key: "UNREAD", value: "UNREAD", color: "#59A6EF" },
  { key: "undelivered", value: "REFUSED", color: "#e6140a" },
  { key: "DELETED", value: "DELETED", color: "#f7953e" },
];

export const EBOX_SMS_STATUS = [
  { key: "SENT", value: "SENT", color: "#62DEB0" },
  { key: "RECEIVED", value: "RECEIVED", color: "#59A6EF" },
];

export const ATTACHMENT_TYPES = [
  { key: "ALL", value: "ALL" },
  { key: "IMAGE", value: "IMAGE" },
  { key: "VIDEO", value: "VIDEO" },
  { key: "PDF", value: "PDF" },
  { key: "DOC", value: "DOC" },
  { key: "PPT", value: "PPT" },
];

export const ATTACHMENT_CONTEXTS = [
  { key: "ALL", value: "ALL" },
  { key: "ORDER", value: "ORDER" },
  { key: "INVOICE", value: "INVOICE" },
  { key: "BADGE", value: "BADGE" },
  { key: "ATTESTATION", value: "ATTESTATION" },
  { key: "CREDIT", value: "CREDIT" },
];

export const LINK_FILTERS = [
  { key: "ALL", value: "ALL" },
  { key: "TAG", value: "TAG" },
  { key: "ARTICLE", value: "ARTICLE" },
  { key: "CLICKED", value: "CLICKED" },
];

export const MAILING_LISTS_MANDATORY_FILTER = [
  { key: "optional", value: "false", color: "#12D2DE" },
  { key: "mandatory", value: "true", color: "#F9626D" },
];
