import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { getCategoriesOptions } from '../../../services/utils';
import { fetchTemplatesCategories } from '../../../actions/thunks';
import { setThemesPaginationPage, setThemeCategory, setThemeScope } from '../../../actions/actions';

import _ from 'i18n';

class ThemeCategoryFilter extends Component {

  componentDidMount() {
    this.props.fetchTemplatesCategories();
  }

  handleCategoryChange = (ev, category) => {
    this.setState({ category });
    this.props.setThemeCategory(category);
    ev.stopPropagation();
  };

  switchScope = (scope) => {
    this.props.setThemeCategory(null);
    this.props.setThemeScope(scope);
  };

  renderCategoriesOptions(categories) {
    const { filter: { category } } = this.props;
    if (!categories || categories.length == 0) {
      return null;
    }

    return (
      <ul style={{ height: (categories.length * 44) + "px" }}>
        {
          categories.map(o => <li key={o.id}
            className={o.id === category ? "active" : ""}
            onClick={(ev) => this.handleCategoryChange(ev, o.id)}>
            {_(o.label)}
          </li>)
        }
      </ul>
    )
  }

  render() {
    const { categories, filter, lng } = this.props;
    const filters = getCategoriesOptions(categories, lng);

    return (
      <div className="theme-category__filter">
        <ul>
          {
            filters.map(({ code, label, icon, categories, scope }) => {
              const scopeActive = scope === filter.scope;
              return <li
                key={code}
                className={`${(scopeActive) ? "highlight" : ""} ${(scopeActive && !filter.category) ? "active" : ""} ${(categories && categories.length > 0) ? "is-drop-down" : ""}`}
                onClick={() => this.switchScope(scope)}>
                <div className="filter-header">
                  {icon}
                  <span>{_(label)}</span>
                </div>
                {categories?.length > 0 &&
                  <Fragment>
                    <span className="sidebar-arrow">
                      <i className={`icon ${scopeActive ? "icon-arrow-up" : "icon-arrow-down"}`}></i>
                    </span>
                    {scopeActive && this.renderCategoriesOptions(categories)}
                  </Fragment>
                }
              </li>
            }
            )
          }
        </ul>
      </div>);
  }
}

const mapStateToProps = store => ({
  samples: store.themes.list.items,
  filter: store.themes.filter,
  lng: store.params.lng,
  categories: store.themes.categories.items,
});

const mapDispatchToProps = dispatch => ({
  fetchTemplatesCategories: () => dispatch(fetchTemplatesCategories()),
  setThemesPaginationPage: (page) => dispatch(setThemesPaginationPage(page)),
  setThemeCategory: (category) => dispatch(setThemeCategory(category)),
  setThemeScope: (scope) => dispatch(setThemeScope(scope)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeCategoryFilter);
