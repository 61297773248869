export const fetchCampaignStats = (resp) => {
  return {
    type: 'FETCH_CAMPAIGN_STATS',
    payload: resp
  };
};

export const fetchRecipientsStats = (resp) => {
  return {
    type: 'FETCH_RECIPIENTS_STATS',
    payload: resp
  };
};
export const initCampaignStats = (resp) => {
  return {
    type: 'INIT_CAMPAIGN_STATS',
    payload: resp
  };
};
export const setRecipientsStatsPaginationPage = (pageNumber) => {
  return {
    type: 'SET_RECIPIENTS_STATS_PAGINATION_PAGE',
    pageNumber
  };
};

export const setRecipientsStatsPageSize = (pageSize) => {
  return {
    type: 'SET_RECIPIENTS_STATS_PAGE_SIZE',
    pageSize
  };
};

export const setRecipientsStatsFilterName = (name) => {
  return {
    type: 'SET_RECIPIENTS_STATS_FILTER_NAME',
    name
  };
};

export const setRecipientsStatsFilterStatus = (status) => {
  return {
    type: 'SET_RECIPIENTS_STATS_FILTER_STATUS',
    status
  };
};

export const setRecipientsStatsFilterLinkId = (linkId) => {
  return {
    type: 'SET_RECIPIENTS_STATS_FILTER_LINK_ID',
    linkId
  };
};

export const reSendEmail = (resp) => {
  return {
    type: 'RE_SEND_EMAIL',
    payload: resp
  };
};



export const initRecipientsStatsFilterStatus = () => {
  return {
    type: 'INIT_RECIPIENTS_STATS_FILTER_STATUS',
  };
};

export const updateCampaignLink = (resp) => {
  return {
    type: 'UPDATE_CAMPAIGN_LINK',
    payload: resp
  };
};
