import React from 'react';
import { components } from 'react-select';

export const BadgeOption = props => {
    const { data } = props;

    return <components.Option {...props} >
        <div className="select-option">
            <div className="flex-container">
                <span className="select-option__label">{data.label}</span>
                <span className="select-option__badge">{data.count}</span>
            </div>
        </div>
    </components.Option>
};