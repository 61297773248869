import axios from '../axios-api';
import { throwCatchedError, updateSourceToken, convertDateToUTC } from 'utils';

let getPhonesSourceToken;
let getApproximateCostSourceToken;

export const saveSMSCampaign = (token, data, clientId) => {
  const requestUrl = `/sms/sms-campaign`;


  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('status', data.status);
  if (data.action && ["TEST", "SEND", "DRAFT"].indexOf(data.action) > -1) {
    formData.append('type', data.type);
    formData.append('message', data.smsMessage);
    formData.append('name', data.campaignName);
    formData.append('recipientType', data.recipientType);
    formData.append('language', data.language);
    formData.append('client', clientId);
    formData.append('smsCount', data.smsCount);
    formData.append('smsApproximateCost', data.smsApproximateCost);
    formData.append('action', data.action);
    formData.append('totalSmsRecipients', data.totalSmsRecipients);
    formData.append('senderPhoneNumber', data.sender.userMobilePhone.intlFormat);
    formData.append('senderName', data.sender.firstName + " " + data.sender.lastName);

    if (data.smsCampaignGroups && data.smsCampaignGroups.length > 0 && data.recipientType != "MANUAL") {
      data.smsCampaignGroups.forEach((smsCampaignGroup, i) => {
        if (smsCampaignGroup.id) {
          formData.append(`smsCampaignGroup[${i}][id]`, smsCampaignGroup.id);
        }
        formData.append(`smsCampaignGroup[${i}][group]`, smsCampaignGroup.group);
        formData.append(`smsCampaignGroup[${i}][status]`, smsCampaignGroup.status);
      });
    } else {
      formData.append(`smsCampaignGroup`, "");
    }

    if (data.action == "TEST") {
      formData.append('testPhoneNumbers', JSON.stringify(data.testPhoneNumbers));
    }

    if (data.recipientType == "MANUAL" && ["SEND", "DRAFT"].indexOf(data.action) > -1) {
      if (data.manualSmsRecipients) {
        for (let i = 0; i < data.manualSmsRecipients.length; i++) {
          formData.append('manualSmsRecipients[' + i + ']', data.manualSmsRecipients[i]);
        }
      }
    }
  }
  if (data.id) {
    formData.append('id', data.id);
  }
  return axios.post(requestUrl, formData);
};

export const getSMSCampaigns = (token, clientId, smsPaginationPage = 1, smsPageSize = 10, status = null, language = null, campaignName = null, sortConfig = null, dateRange = null) => {
  const fields = ['id', 'createdAt', 'name', 'language', 'status', 'smsCampaignGroups', 'totalSmsRecipients', 'recipientType'];
  const requestUrl = `/sms/sms-campaign`;

  let sort = [];

  let filter = [
    {
      property: 'client',
      value: clientId,
      operator: 'eq'
    }
  ];
  if (status && status.length > 0) {
    filter.push({
      property: 'status',
      value: status,
      operator: status instanceof Array ? "in" : "eq",
    });
  }
  if (language && language.length > 0) {
    filter.push({
      property: 'language',
      value: language,
      operator: 'in'
    });
  }
  if (campaignName) {
    filter.push({
      property: 'name',
      value: campaignName.replace("'", ""),
      operator: 'like'
    });
  }

  if (dateRange && dateRange.startDate) {
    filter.push({
      property: "updatedAt",
      operator: "gte",
      value: "SPECIAL_CHAR_TO_REPLACEu0027" + convertDateToUTC(dateRange.startDate) + "SPECIAL_CHAR_TO_REPLACEu0027",
    })
  }
  if (dateRange && dateRange.endDate) {
    filter.push({
      property: "updatedAt",
      operator: "lte",
      value: "SPECIAL_CHAR_TO_REPLACEu0027" + convertDateToUTC(dateRange.endDate) + "SPECIAL_CHAR_TO_REPLACEu0027",
    })
  }

  sort.push({
    property: (sortConfig) ? sortConfig.columnKey : "createdAt",
    dir: (sortConfig) ? sortConfig.sortDirection : "desc",
  });

  let params = {
    access_token: token,
    fields: fields.join(','),
    limit: smsPageSize,
    start: (smsPaginationPage - 1) * smsPageSize
  };

  params.sort = JSON.stringify(sort);
  params.filter = JSON.stringify(filter).replace(/SPECIAL_CHAR_TO_REPLACE/g, "\\");

  return axios.get(requestUrl, { params });
};

export const getSMSCampaign = (token, campaignSMSId) => {
  const filter = [
    { property: 'id', value: campaignSMSId, operator: 'eq' }
  ];
  const fields = ['*', 'smsCampaignGroups'];
  const requestUrl = `/sms/sms-campaign`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter)
    }
  });
};


export const removeSMSCampaign = (token, campaignSMSId, clientId) => {
  const requestUrl = `/sms/sms-campaign/` + campaignSMSId;
  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('status', 'DELETED');
  formData.append('client', clientId);

  return axios.post(requestUrl, formData);
};

export const getSMSRecipients = (token, clientId, page = 1, pageSize = 10, filterData) => {
  let lowerRecipientType = filterData.recipientType.toLowerCase();
  const requestUrl = `/mailing/${lowerRecipientType}`;

  let fields = ['id', 'lastName', 'firstName', 'contactClient'];
  let filter = [
    {
      property: 'client.id',
      value: clientId,
      operator: 'eq'
    },
    {
      property: 'language',
      value: filterData.language,
      operator: 'eq'
    },
    {
      property: 'phone.lookupType',
      value: 'MOBILE',
      operator: 'eq'
    },
    {
      property: 'phone.intlFormat',
      value: null,
      operator: 'neq',
    }
  ];

  if (filterData.groupsIn && filterData.groupsIn.length > 0) {
    filter.push({
      property: 'group.id',
      value: filterData.groupsIn,
      operator: 'in'
    });
  }
  if (filterData.groupsNin && filterData.groupsNin.length > 0) {
    filter.push({
      property: 'group.id',
      value: filterData.groupsNin,
      operator: 'nin'
    });
  }
  if (filterData.name) {
    filter.push({
      property: 'name',
      value: filterData.name,
      operator: 'like'
    });
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter),
      limit: pageSize,
      start: (page - 1) * pageSize,
      organization_id: clientId
    }
  });
};

export const getPhoneNumbers = (token, client, userLastName, sourceToken) => {
  getPhonesSourceToken = updateSourceToken(getPhonesSourceToken, sourceToken);
  const fields = ['*', 'user'];
  const requestUrl = `/profile/phone`;

  let filter = [];
  if (userLastName) {
    filter = [
      {
        property: 'name',
        value: userLastName.trim(),
        operator: 'like'
      }
    ];
  }

  if (client) {
    filter.push({
      property: 'contactClient.client',
      value: client,
      operator: 'eq'
    });
  }

  filter.push({
    property: 'lookupType',
    value: 'MOBILE',
    operator: 'eq'
  });

  filter.push({
    property: 'main',
    value: 1,
    operator: 'eq'
  });

  let sort = [
    {
      property: 'user.lastName',
      dir: 'asc'
    }
  ];

  return axios.get(requestUrl, {
    cancelToken: getPhonesSourceToken.token,
    params: {
      access_token: token,
      fields: fields.join(','),
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort)
    }
  }).catch(function (thrown) { throwCatchedError(thrown) });
};

export const getApproximateCost = (token, clientId, filterData, sourceToken = null) => {
  const requestUrl = `/sms/sms-campaign-recipient/sms-approximate-cost`;
  getApproximateCostSourceToken = updateSourceToken(getApproximateCostSourceToken, sourceToken);

  return axios.get(requestUrl, {
    cancelToken: getApproximateCostSourceToken.token,
    params: {
      access_token: token,
      clientId: clientId,
      language: filterData.language,
      groupsIn: filterData.groupsIn,
      groupsNin: filterData.groupsNin,
    }
  });
};


export const getClientAuthorizedBalance = (token, clientId) => {
  const requestUrl = `/sms/get-client-authorized-balance`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      clientId: clientId,
    }
  });
};

export const getClientRealCredit = (token, organizationSettingsId) => {
  const requestUrl = `/organization/organization-settings/` + organizationSettingsId;

  return axios.get(requestUrl, {
    params: {
      access_token: token
    }
  });
};

export const updateSmsStatus = (token, data) => {
  if (!data.id) {
    return;
  }
  const requestUrl = `/sms/sms-campaign/${data.id}`;
  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('status', data.status);

  return axios.post(requestUrl, formData);
};
