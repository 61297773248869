export * from "./blocks";

import * as actions from "actions";
import {
  getClientThemes,
  persistTheme,
  persistMultiTheme,
  editThemeVisibility,
  getThemesCategories,
  sendTest,
  manageShareTemplate,
  persistThemeCategory,
  deleteThemeCategory,
  updateThemeCategory,
  getClientTheme
} from "TamtamAPI";
import { pushSourceToken } from "utils";

export const fetchTemplates = (data = []) => {
  return (dispatch, getState) => {
    const { token, loggedAs, user } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    let userId = loggedAs == "ADMIN" ? null : user.id;
    let sourceToken = pushSourceToken("FETCH_TEMPLATES", dispatch);
    const { items } = getState().themes.list;
    const offset = data.paginationPage == 1 || !items ? 0 : items.length;

    return dispatch(
      actions.fetchTemplates(
        getClientThemes(
          token,
          clientId,
          { ...data, offset },
          userId,
          sourceToken
        )
      )
    );
  };
};

export const fetchTemplate = id => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.fetchTemplate(getClientTheme(token, id)));
  };
};

export const saveTheme = data => {
  return (dispatch, getState) => {
    const { token, currentClient, user } = getState().auth;
    data = { ...data, clientId: currentClient.id, updatedBy: user.id };

    return dispatch(actions.saveTheme(persistTheme(token, data)));
  };
};

export const manageShareTheme = (id, isShare = 0) => {
  return (dispatch, getState) => {
    const { token, user } = getState().auth;

    return dispatch(
      actions.saveTheme(
        manageShareTemplate(token, { id, updatedBy: user.id, isShare })
      )
    );
  };
};

export const multiSaveTheme = data => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.multiSaveTheme(persistMultiTheme(token, data)));
  };
};

export const updateThemeVisibility = (id, visibility) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.updateThemeVisibility(
        editThemeVisibility(token, id, visibility).then(res => {
          return res.data.data;
        }, err => err)
      )
    );
  };
};

export const fetchTemplatesCategories = () => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken("FETCH_TEMPLATES_CATEGORIES", dispatch);
    const { token, currentClient } = getState().auth;
    let clientId = currentClient.id;
    return dispatch(
      actions.fetchTemplatesCategories(
        getThemesCategories(token, clientId, sourceToken)
      )
    );
  };
};

export const sendThemeTest = data => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.sendThemeTest(sendTest(token, data)));
  };
};

export const saveThemeCategory = data => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    data = { ...data, clientId: currentClient.id };

    return dispatch(
      actions.saveThemeCategory(persistThemeCategory(token, data))
    );
  };
};

export const removeThemeCategory = id => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.removeThemeCategory(
        deleteThemeCategory(token, id).then(res => {
          return res.data;
        }, err => err)
      )
    );
  };
};

export const editThemeCategory = data => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.editThemeCategory(
        updateThemeCategory(token, data).then(res => {
          return res.data.data;
        }, err => err)
      )
    );
  };
};
