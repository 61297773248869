import React from "react";
import EmailTagsInput from "../../../common/campaign/EmailTagsInput";
import _ from 'i18n';

const Recipient = (props) => {

  const { title, emails, onChange, children, placeholder } = props;

  return (
    <div className="delivery-info__item">
      <span className="title">{_(title)} : </span>
      <EmailTagsInput
        cssClass={"recipients-emails__input"}
        maxTags={"20"}
        placeholder={placeholder || _('recipients')}
        emails={emails}
        changed={onChange}
      />
      {children}
    </div>
  );
}

export default Recipient;
