export const setEditorAllowOpenModels = (allowOpenModels) => {
  return {
    type: 'SET_EDITOR_ALLOW_OPEN_MODELS',
    allowOpenModels
  }
};

export const setEditorCustomizeModal = modalFieldsOpened => ({
  type: 'SET_FIELDS_EDITOR_MODAL',
  modalFieldsOpened,
});
