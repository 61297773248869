import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import _ from 'i18n';
import { onSuccess, onError, SVG_DELETE_ICON, SVG_EDIT_ICON, SVG_STOP_ICON, SVG_PAUSE_ICON, SVG_PLAY_ICON, SVG_STATS_ICON } from 'utils';
import { updateCampaignSmsStatus } from 'thunks';

class SMSActionsCell extends Component {

  handleDeleteClick(campaignSMSId) {
    this.props.deleteAction();
    this.props.selectedCampaignSMSId(campaignSMSId);
  }

  handleSMSCampaignAction = (id, status) => {
    const { dispatch } = this.props;
    if (status == "HALT") {
      this.props.stopAction();
      this.props.onStatus(id, status);
    }
    else {
      dispatch(updateCampaignSmsStatus({ id, status })).then(res => onSuccess(res), err => onError(err));
    }
  }

  render() {
    const { item, colorClass } = this.props;
    const { id, status } = item;

    return (
      <div className="small-2">
        <div className="list-item__status"><i className={`bg-${colorClass}`}></i>{status == "RECIPIENT_LIST_CREATED" ? _("READY") : _(status)}</div>
        <div className="list-item__actions">
          {(["DRAFT", "TEST"].indexOf(status) != -1) ?
            <div data-for={"edit" + id} data-tip={_('edit')} onClick={() => this.props.history.push(`/campaign-sms/${id}`)}>
              {SVG_EDIT_ICON}
              <ReactTooltip id={"edit" + id} type='dark' place="bottom" />
            </div> :
            <div data-for={"report" + id} data-tip={_('report')} onClick={() => this.props.history.push(`/stats-sms/${id}`)}>
              {SVG_STATS_ICON}
              <ReactTooltip id={"report" + id} type='dark' place="bottom" />
            </div>}
          {(["RECIPIENT_LIST_CREATED", "CREATED"].indexOf(status) > -1) &&
            <div data-for={"pause" + id} data-tip={_('pause')} onClick={() => this.handleSMSCampaignAction(id, 'PAUSE')}>
              {SVG_PAUSE_ICON}
              <ReactTooltip id={"pause" + id} type='dark' place="bottom" />
            </div>}

          {(status == "PAUSE") &&
            <div data-for={"resume" + id} data-tip={_('resume')} onClick={() => this.handleSMSCampaignAction(id, 'UNPAUSE')}>
              {SVG_PLAY_ICON}
              <ReactTooltip id={"resume" + id} type='dark' place="bottom" />
            </div>}

          {status == "PAUSE" && <div data-for={"stop" + id} data-tip={_('stop definitely')} onClick={() => this.handleSMSCampaignAction(id, 'HALT')} >
            {SVG_STOP_ICON}
            <ReactTooltip id={"stop" + id} type='dark' place="bottom" />
          </div>}
          <div data-for={"delete" + id} data-tip={_('delete')} onClick={() => this.handleDeleteClick(id)}>
            {SVG_DELETE_ICON}
            <ReactTooltip id={"delete" + id} type='dark' place="bottom" />
          </div>
        </div>
      </div>
    );
  };
}

export default compose(
  connect(),
  withRouter
)
  (SMSActionsCell);
