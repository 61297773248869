import React, { Component } from "react";
import ReactModal from "react-modal";
import { modalStyle } from "../../services/utils";
import { SVG_ICONS } from "../../config/Common";

export default class Viewer extends Component {
  constructor(props) {
    super(props);
    this.modalStyle = {
      ...modalStyle,
      ...props.modalStyle,
      content: {
        ...modalStyle.content,
        ...this.props.modalStyle.content,
        overflow: props.scroll ? "scroll" : "unset",
        borderRadius: "5px"
      }
    };
  }

  render() {
    const { isOpen, children, onClose, title, shouldNotCloseOnOverlayClick } = this.props;

    return <ReactModal
      contentLabel="Modal"
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={!shouldNotCloseOnOverlayClick}
      isOpen={isOpen}
      ariaHideApp={false}
      style={this.modalStyle}>
      <div className="ttp-modal row">
        <div className="ttp-modal__close" onClick={onClose}>
          <img src={"/img/icons/" + SVG_ICONS["CLOSE"]} />
        </div>
        {title && <div className={"ttp-modal__header"}>
          <span>{title}</span>
        </div>}
        <div className="small-12">
          {children}
        </div>
      </div>
    </ReactModal>
  }
}
