export const fetchArticles = resp => {
  return {
    type: "FETCH_ARTICLES",
    payload: resp
  };
};

export const initArticles = () => {
  return {
    type: 'INIT_ARTICLES'
  };
};

export const setArticlesPaginationPage = (pageNumber) => {
  return {
    type: 'SET_ARTICLES_PAGINATION_PAGE',
    pageNumber
  };
};

export const setArticlesSearchWord = (searchWord) => {
  return {
    type: 'SET_ARTICLES_SEARCH_WORD',
    searchWord
  }
};

export const setArticlesLanguage = (language) => {
  return {
    type: 'SET_ARTICLES_LANGUAGE',
    language
  }
};

export const setArticlesTags = (tags) => {
  return {
    type: "SET_ARTICLES_TAGS",
    tags
  }
}

export const setArticlesCategories = (categories) => {
  return {
    type: "SET_ARTICLES_CATEGORIES",
    categories
  }
}


export const setCategoriesSuggestions = payload => {
  return {
      type: "FETCH_CATEGORIES_SUGGESTIONS",
      payload
  };
};

export const setArticlesPages = (articlePages) => {
  return {
    type: "SET_ARTICLES_PAGES",
    articlePages
  }
}

export const fetchArticlePagesOptions = payload => {
  return {
      type: "FETCH_ARTICLE_PAGES_OPTIONS",
      payload
  };
};
