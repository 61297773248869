import React, { PureComponent } from "react";
import { connect } from "react-redux";

import _ from "i18n";
import { setCurrentEmailCampaignData } from "actions";
import { fetchGroups } from "thunks";
import TTPSelect from "common/TTPSelect";
import { setRecipientsListFilterName } from "../../../../../actions/actions/campaign";

@connect((store) => {
  return {
    groups: store.filters.groups.items,
    allowedGroups: store.currentCampaign.email.allowedGroups,
    deniedGroups: store.currentCampaign.email.deniedGroups,
    recipientType: store.currentCampaign.email.recipientType,
    uaFilters: store.currentCampaign.email.uaFilters,
    fetching: store.filters.groups.fetching,
    fetched: store.filters.groups.fetched,
  };
})
export default class GroupsFilters extends PureComponent {
  componentDidUpdate(prevProps, prevState) {
    const { dispatch, recipientType } = this.props;
    if (
      prevProps.recipientType !== recipientType &&
      (recipientType === "CONTACT" || recipientType === "CONTACT_CLIENT")
    ) {
      dispatch(fetchGroups("CONTACT"));
    }
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(fetchGroups("CONTACT"));
  }

  handleAllowedGroupChange = (value) => {
    const { dispatch, recipientType, uaFilters, groups, deniedGroups } =
      this.props;
    dispatch(setRecipientsListFilterName(""));

    let updatedFilterSelection;

    const existingFilterObjectIndex = uaFilters.findIndex(
      (filter) => filter.type === recipientType,
    );

    if (existingFilterObjectIndex !== -1) {
      updatedFilterSelection = [...uaFilters];

      updatedFilterSelection[existingFilterObjectIndex].allowedGroups =
        value.map((groupId) => {
          const group = groups.find((group) => group.id == groupId); // don't add 3 '=' because we use the == operator to compare values with different data types.
          return {
            id: groupId,
            name: group ? group.name : "",
          };
        });
    } else {
      const newFilterObject = {
        type: recipientType,
        allowedGroups: value.map((groupId) => {
          const group = groups.find((group) => group.id == groupId); // don't add 3 '=' because we use the == operator to compare values with different data types.
          return {
            id: groupId,
            name: group ? group.name : "",
          };
        }),
        deniedGroups: [],
      };
      updatedFilterSelection = [...uaFilters, newFilterObject];
    }
    dispatch(
      setCurrentEmailCampaignData({
        deniedGroups: deniedGroups,
        allowedGroups: value,
        uaFilters: updatedFilterSelection,
      }),
    );
  };

  handleDeniedGroupChange = (value) => {
    const { dispatch, recipientType, uaFilters, groups, allowedGroups } =
      this.props;
    this.props.dispatch(setRecipientsListFilterName(""));

    let updatedFilterSelection;

    const existingFilterObjectIndex = uaFilters.findIndex(
      (filter) => filter.type === recipientType,
    );

    if (existingFilterObjectIndex !== -1) {
      updatedFilterSelection = [...uaFilters];

      updatedFilterSelection[existingFilterObjectIndex].deniedGroups =
        value.map((groupId) => {
          const group = groups.find((group) => group.id == groupId); // don't add 3 '=' because we use the == operator to compare values with different data types.
          return {
            id: groupId,
            name: group.name,
          };
        });
    } else {
      const newFilterObject = {
        type: recipientType,
        allowedGroups: [],
        deniedGroups: value.map((groupId) => {
          const group = groups.find((group) => group.id == groupId); // don't add 3 '=' because we use the == operator to compare values with different data types.
          return {
            id: groupId,
            name: group.name,
          };
        }),
      };
      updatedFilterSelection = [...uaFilters, newFilterObject];
    }
    dispatch(
      setCurrentEmailCampaignData({
        allowedGroups: allowedGroups,
        deniedGroups: value,
        uaFilters: updatedFilterSelection,
      }),
    );
  };

  render() {
    const { groups, uaFilters, recipientType, fetching, fetched } = this.props;
    const recipientTypeObject = uaFilters.find(
      (selection) => selection.type === recipientType,
    );
    const allowedGroupIds = recipientTypeObject
      ? recipientTypeObject.allowedGroups.map((group) => group.id)
      : [];
    const deniedGroupIds = recipientTypeObject
      ? recipientTypeObject.deniedGroups.map((group) => group.id)
      : [];

    return (
      <div className="row">
        {fetching && !fetched ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">{_("groupsIncluded")}</span>
              <div className="small-12 m-r-xs">
                <TTPSelect
                  values={allowedGroupIds}
                  placeholder={_("selectGroups")}
                  options={groups}
                  onChange={this.handleAllowedGroupChange}
                  disabled={deniedGroupIds}
                />
              </div>
            </div>
            <div className="columns small-6 columns-padding">
              <span className="tab-label small-10">{_("excludedGroups")}</span>
              <div className="small-12">
                <TTPSelect
                  values={deniedGroupIds}
                  placeholder={_("excludeGroups")}
                  options={groups}
                  onChange={this.handleDeniedGroupChange}
                  disabled={allowedGroupIds}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
