export const initBlocks = () => {
  return {
    type: "INIT_BLOCKS"
  };
};

export const fetchBlocks = resp => {
  return {
    type: "FETCH_BLOCKS",
    payload: resp
  };
};

export const addLayoutBlock = bloc => {
  return {
    type: "ADD_LAYOUT_BLOCK",
    bloc
  };
};

export const setBlocksType = blockType => {
  return {
    type: "SET_BLOCKS_TYPE",
    blockType
  };
};

export const setCurrentLayout = layout => {
  return {
    type: "SET_CURRENT_LAYOUT",
    layout
  };
};

export const setCurrentNewsletterTemplate = template => {
  return {
    type: "SET_CURRENT_NEWSLETTER_TEMPLATE",
    template
  };
};

export const addFeedToCurrentLayout = feed => {
  return {
    type: "ADD_FEED_TO_CURRENT_LAYOUT",
    feed
  };
};

export const addFeedToNewsletter = feed => {
  return {
    type: "ADD_FEED_TO_NEWSLETTER",
    feed
  };
};

export const initNewsletterBlocks = () => {
  return {
    type: "INIT_NEWSLETTER_BLOCKS"
  };
};

export const initNewsletterFeeds = () => {
  return {
    type: "INIT_NEWSLETTER_FEEDS"
  };
};

export const resetLayoutBlocs = layout => {
  return {
    type: "RESET_LAYOUT_BLOCKS",
    layout
  };
};

export const removeFeedFromLayout = feed => {
  return {
    type: "REMOVE_FEED_FROM_LAYOUT",
    feed
  };
};

export const setNewsletterBlocks = blocks => {
  return {
    type: "SET_NEWSLETTER_BLOCKS",
    blocks
  };
};

export const setNewsletterBase = base => {
  return {
    type: "SET_NEWSLETTER_BASE",
    base
  };
};

export const setViewMode = viewMode => {
  return {
    type: "SET_VIEW_MODE",
    viewMode
  };
};

export const addBlockToTemplate = block => {
  return {
    type: "ADD_BLOCK_TO_TEMPLATE",
    block
  };
};

export const toggleAddBlockSideBar = showAddBlockSideBar => {
  return {
    type: "TOGGLE_ADD_BLOCK_SIDE_BAR",
    showAddBlockSideBar
  };
};
