import React from "react";
import SampleSelector from "../../editor/SampleSelector";
import {connect} from "react-redux";
import {decomposeNewsletter} from "../../../services/block/newsletter";
import {setCurrentLayout, setCurrentNewsletterTemplate, setNewsletterBlocks, initNewsletterBlocks} from "actions";
import _ from "i18n";
import {setTTPDialogCustomData} from "../../../actions/actions/params";
import {NotificationManager} from "react-notifications";

class NSTemplateSelector extends React.Component {
  state = {
    loadingTemplate: false,
  };

  componentDidMount() {
    const { childRef, currentTemplate, proceed } = this.props;
    if (childRef) {
      childRef({ secondaryAction: () => currentTemplate ? proceed() : this.showWarning() });
    }
  }

  showWarning = () => {
    NotificationManager.warning(_("Please select a template to proceed"), _("warning"));
  };

  loadTemplate = (template) => {
    if (!template || !template.id) {
      return;
    }
    const {setCurrentLayout, setCurrentNewsletterTemplate, setNewsletterBlocks, initNewsletterBlocks, proceed, blocks} = this.props;

    initNewsletterBlocks();
    setCurrentLayout(template.id);
    setCurrentNewsletterTemplate(template);

    if (!this.state.loadingTemplate) {
      this.setState({loadingTemplate: true});
      let feeds = blocks.reduce((acc, {feeds}) => {
        return [...acc, ...feeds];
      }, []);
      decomposeNewsletter(template, blocks => {
        let i = 0;
        blocks = feeds && feeds.length > 0 ? blocks.map(block => {
          if(block.type !== "UNREFERENCED_BLOCK" && feeds.length > i) {
            block.feeds = feeds.slice(i, i += block.column);
            return block;
          }
          return block;
        }) : blocks;
        setNewsletterBlocks(blocks);
        this.setState({loadingTemplate: false});
        proceed();
      });
    }
  };

  handleConfirmTemplate = (template) => {
    const {currentTemplate, setTTPDialogCustomData} = this.props;

    if (currentTemplate && (currentTemplate.id !== template?.id)) {
      setTTPDialogCustomData({
          'approvedAction': () => this.loadTemplate(template),
          'message': _('confirmSwitchTheme'),
          'title': "Confirmation of switching models"
        }
      )
    }
    else {
      this.loadTemplate(template);
    }
  }

  render() {
    return <SampleSelector type={["TEMPLATE"]} onSelect={this.handleConfirmTemplate} currentTheme={this.props.currentTemplate?.id}/>;
  }
}

const mapStateToProps = store => ({
  currentTemplate: store.themes.newsletter.currentTemplate,
  blocks: store.themes.newsletter.blocks,
});

const mapDispatchToProps = dispatch => ({
  setCurrentLayout: (data) => dispatch(setCurrentLayout(data)),
  setCurrentNewsletterTemplate: (data) => dispatch(setCurrentNewsletterTemplate(data)),
  setNewsletterBlocks: (data) => dispatch(setNewsletterBlocks(data)),
  initNewsletterBlocks: () => dispatch(initNewsletterBlocks()),
  setTTPDialogCustomData: data => dispatch(setTTPDialogCustomData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NSTemplateSelector);
