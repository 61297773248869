export const fetchFormations = (resp) => {
  return {
    type: "FETCH_FORMATIONS",
    payload: resp,
  };
};

export const initFormations = () => {
  return {
    type: "INIT_FORMATIONS",
  };
};

export const setFormationsSearchWord = (searchWord) => {
  return {
    type: "SET_FORMATIONS_SEARCH_WORD",
    searchWord,
  };
};

export const setFormationsType = (trainingType) => {
  return {
    type: "SET_FORMATIONS_TYPE",
    trainingType,
  };
};

export const setFormationsLanguage = (language) => {
  return {
    type: "SET_FORMATIONS_LANGUAGE",
    language,
  };
};

export const setFormationsTags = (tags) => {
  return {
    type: "SET_FORMATIONS_TAGS",
    tags,
  };
};

export const setFormationsEvent = (event) => {
  return {
    type: "SET_FORMATIONS_EVENT",
    event,
  };
};

export const fetchEventsTagsSuggestions = (payload) => {
  return {
    type: "FETCH_EVENTS_TAGS_SUGGESTIONS",
    payload,
  };
};

export const setFormationsPaginationPage = (paginationPage) => {
  return {
    type: "SET_FORMATIONS_PAGINATION_PAGE",
    paginationPage,
  };
};
