import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'i18n';
import { modalStyle, getLanguages } from 'utils';
import 'react-notifications/lib/notifications.css';
import 'react-flags-select/css/react-flags-select.css';
import { fetchTextModels } from 'thunks';
import { SMS_CAMPAIGN_DYNAMIC_FIELDS } from "Common";
import { setTextModelsTargetApp } from "actions";
import TTPSelect from "../../common/TTPSelect";
import TTPModal from "../../ui/TTPModal";

@connect((store) => {
  return {
    smsTextModels: store.textModels.items,
    currentSmsModel: store.currentCampaign.sms.smsTextModel,
    currentSmsModeTitle: store.currentCampaign.sms.smsTextModelTitle,
  }
})

export default class SMSModels extends Component {

  constructor(props) {
    super(props);
    this.state = {
      language: this.props.language,
      currentModelAction: null,
      modelError: false,
      showDropDownBtn: false,
      modelTitle: '',
      smsModelOptions: [],
    };
    this.toogleDropDownBtn = () => this.setState({ showDropDownBtn: !this.state.showDropDownBtn });
  }

  componentWillMount() {
    const { dispatch, detached, showSMSModelsModal } = this.props;
    if (!detached && showSMSModelsModal) {
      dispatch(setTextModelsTargetApp(["SMS"]));
      dispatch(fetchTextModels(this.state.language));
    }
  }

  closeAllPopups() {
    this.setState({
      showDropDownBtn: false,
      modelTitle: "",
      modelError: false,
      currentModelAction: null,
    });
    this.props.onCloseModal();
  }

  componentWillReceiveProps(nextProps) {
    const { smsTextModels, canUseSmsModels } = nextProps;
    if (smsTextModels !== this.props.smsTextModels) {
      let smsModelOptions = [];
      nextProps.smsTextModels.forEach(smsModel => {
        let { id, object, content } = smsModel;
        smsModelOptions.push({ value: id, label: object, content, id });
      });
      this.setState({ smsModelOptions });
    }
    if (canUseSmsModels !== this.props.canUseSmsModels) {
      this.setState({ currentModelAction: null });
    }
  }

  setCurrentAction(currentModelAction) {
    if (currentModelAction === "UPDATE") {
      this.setState({ modelTitle: this.props.currentSmsModeTitle });
    }
    this.setState({ currentModelAction, showDropDownBtn: false, modelError: false });
  }

  renderLang() {
    let { currentModelAction, language } = this.state;

    return <TTPSelect
      simple={true}
      notClearable={true}
      values={language}
      placeholder={_('lang')}
      cssClass={language ? "filled-box" : "empty-box"}
      options={getLanguages(!currentModelAction).map(({ key, value }) => ({ id: key, label: _(value).toUpperCase() }))}
      onChange={this.handleLanguageChange.bind(this)} />;
  }

  handleLanguageChange(e) {
    let { currentModelAction } = this.state;
    this.setState({ language: e });
    if (!currentModelAction) {
      this.props.dispatch(setTextModelsTargetApp(["SMS"]));
      this.props.dispatch(fetchTextModels(e));
    }
  }

  processModelAction(action) {
    const { modelTitle, language } = this.state;
    if (modelTitle.trim().length === 0) {
      this.setState({ modelError: true });
      return;
    }
    const { currentSmsModel, onSaveSmsTextModel } = this.props;
    let data = { object: modelTitle.trim(), language };
    if (action == "UPDATE" && currentSmsModel) {
      data.id = currentSmsModel;
    }
    onSaveSmsTextModel(data);
    this.closeAllPopups();
  }

  renderTextModelBtn() {
    let { showDropDownBtn, currentModelAction } = this.state;
    let { canUseSmsModels, currentSmsModel } = this.props;

    let btnTxt = (currentModelAction == "UPDATE") ? 'update the model' : 'add new model';
    if (!canUseSmsModels || !currentModelAction) {
      btnTxt = 'actions'
    }
    let action = "";
    if (canUseSmsModels) {
      action = currentModelAction ? () => this.processModelAction(currentModelAction) : this.toogleDropDownBtn;
    }
    return <div className={`dropdown-button ttp-button info btn ${!canUseSmsModels && "disabled"}`}>
      <div className="action-label separator" onClick={action}>{_(btnTxt)}</div>
      <div className="arrow-label" onClick={this.toogleDropDownBtn}>
        <span className={`icon ${showDropDownBtn ? "icon-arrow-up" : "icon-arrow-down"}`} />
      </div>
      {showDropDownBtn && canUseSmsModels && <ul>
        <li onClick={this.setCurrentAction.bind(this, "ADD")}>{_('add new model')}</li>
        {currentSmsModel && <li onClick={this.setCurrentAction.bind(this, "UPDATE")}>{_('update the model')}</li>}
        {currentModelAction && <li onClick={this.setCurrentAction.bind(this, "")}>{_('cancel')}</li>}
      </ul>}
    </div>;
  }

  handleModelTitleChange(e) {
    const modelTitle = e.target.value;
    this.setState({ modelTitle, modelError: modelTitle.length < 2 });
  }

  onCancelAction = () => {
    this.props.onCancelAction();
  }

  renderDynamicField() {
    const { recipientType, onAddModelDynamicField } = this.props;

    if (recipientType !== "CONTACT") {
      return null
    }
    return <div className="fields-box">
      {SMS_CAMPAIGN_DYNAMIC_FIELDS.map((item) => <div key={item.name} onClick={() => onAddModelDynamicField(item.value)}> {_(item.name)}</div>)}
    </div>
  }

  render() {
    let { onCloseModal, currentSmsModel, onApplySmsTextModel, showSMSModelsModal, onSmsModelMessageChange, recipientType, smsModelMessage } = this.props;
    let { smsModelOptions, currentModelAction, modelTitle, modelError } = this.state;

    let customModalStyle = {
      content: {
        width: "75%",
        maxWidth: "56rem",
      }
    };

    return (<TTPModal isOpen={true} onClose={this.onCancelAction} modalStyle={customModalStyle}>
      <div className="templates-settings__body sms-models-modal">
        <div className="row small-10 align-center templates-settings__item">
          <div className="columns small-7 phone-container">
            <img src="/img/pictos/iphone-icon.svg" />
            <div className="phone-text">{smsModelMessage} </div>
          </div>
          <div className="columns small-7 row-top">
            <div className="sidebar__form-control">
              <div className="small-12 margin-left-auto">
                <h3>{_('sms models')}</h3>
                <div>
                  {currentModelAction ? <div>
                    <input type="text" placeholder={_('subject')}
                      value={modelTitle}
                      className={`${modelTitle ? "filled-box" : "empty-box"} ${modelError ? 'is-invalid-input' : ""}`}
                      onChange={this.handleModelTitleChange.bind(this)} />
                    {modelError ? <span
                      className={modelError ? 'form-error is-visible' : 'form-error'}>{_('The subject is required!')}</span> : null}
                  </div> : <TTPSelect
                      smsObject
                      simple={true}
                      notClearable={true}
                      values={currentSmsModel == null ? "" : currentSmsModel}
                      placeholder={_('apply a model')}
                      cssClass={recipientType ? "filled-box" : "empty-box"}
                      options={smsModelOptions}
                      onChange={(smsModel) => onApplySmsTextModel(smsModel)} />
                  }
                  <div className="row margin">
                    <div className="columns small-12 columns-padding">
                      <div className="sidebar__form-control">
                        <div className="small-12">
                          {this.renderLang()}
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.renderDynamicField()}
                  <textarea value={smsModelMessage}
                    rows={5}
                    placeholder={_('message')}
                    onChange={onSmsModelMessageChange}
                    className={(smsModelMessage && smsModelMessage.length) ? "filled-box" : "empty-box"}
                  >{smsModelMessage}</textarea>
                  {this.renderTextModelBtn()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSMSModelsModal && <div className="templates-settings__footer">
        <button onClick={this.onCancelAction}
          className="btn secondary">{_('cancel')}</button>
        <button onClick={onCloseModal}
          className="btn primary">{_('apply')}</button>
      </div>}
    </TTPModal>
    );
  }
}
