import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  clearSourcesTokens,
  initCampaignRecipientsFilter,
} from 'actions';

import _ from 'i18n';
import { EVENT_RECIPIENT_TYPES } from 'Common';

import { fetchRecipients } from 'thunks';
import Recipients from './Recipients';
import GuestFilters from './filter/event/GuestFilters';
import PartnerFilters from './filter/event/PartnerFilters';
import SpeakerFilters from './filter/event/SpeakerFilters';
import ContactFilters from './filter/ContactFilters';
import SurveyFilters from './filter/survey/SurveyFilters';
import PartnerGuestFilters from './filter/event/PartnerGuestFilters';
import Target from 'common/campaign/Target';
import CollaboratorFilters from "./filter/CollaboratorFilters";
import MailingListFilters from "./filter/MailingListFilters";
import PaymentFilters from './filter/payment/PaymentFilters';
import FolderFilters from "./filter/FolderFilters";
import {setRecipientsListFilterCommitmentScore} from "../../../actions/actions/campaign";
import CycleGuestFilters from "./filter/event/CycleGuestFilters";
import RecipientType from "../params/RecipientType";
import {getHistory} from "../../../router/getHistory";
import OeccbbMembersFilters from "./filter/OeccbbMembersFilters";

@connect((store) => {
  return {
    recipients: store.campaigns.recipients,
    _requests: store.params.sourcesTokens,
    opened: store.currentCampaign.email.currentSideBar == "RECIPIENTS",
    eventFilters: store.currentCampaign.email.eventFilters,
    language: store.currentCampaign.email.language,
    recipientType: store.currentCampaign.email.recipientType,
    allowedGroups: store.currentCampaign.email.allowedGroups,
    deniedGroups: store.currentCampaign.email.deniedGroups,
    surveyType: store.currentCampaign.email.surveyType,
    mailingLists: store.currentCampaign.email.mailingLists,
    surveyInstance: store.currentCampaign.email.surveyInstance,
    commitmentScoreRange: store.currentCampaign.email.commitmentScoreRange
  };
})
export default class RecipientsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidContactsEmail: 0,
      contactType: 'Default',
    }
  }

  handleSetContactType = (value) => {
    this.setState({ contactType: value});
  };
  componentWillReceiveProps(nextProps) {
    const { eventFilters, recipientType } = nextProps;

    if (['MANUAL', ''].indexOf(recipientType) != -1) {
      return;
    }
    if (EVENT_RECIPIENT_TYPES.indexOf(recipientType) > -1 && eventFilters.length == 0) {
      return;
    }
    const { dispatch, language, deniedGroups, allowedGroups, surveyInstance, surveyType, mailingLists, commitmentScoreRange } = nextProps;
    if (recipientType != this.props.recipientType || language != this.props.language || eventFilters != this.props.eventFilters || deniedGroups != this.props.deniedGroups ||
      allowedGroups != this.props.allowedGroups || surveyType != this.props.surveyType || mailingLists != this.props.mailingLists
      || surveyInstance != this.props.surveyInstance || commitmentScoreRange !== this.props.commitmentScoreRange) {
      dispatch(initCampaignRecipientsFilter());
      this.renderRecipientDatatable(nextProps);
    }
  }

  componentDidMount() {
    this.props.dispatch(initCampaignRecipientsFilter());
    const isOeccbbMembers = this.props.eventFilters?.some(item => item.property === "memberShipOrder.year");
    if (isOeccbbMembers){
      this.setState({...this.state, contactType: 'OECCBB_MEMBERS'});
    } else {
      this.renderRecipientDatatable(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { filter } = this.props.recipients;
    let { invalidContactsEmail, contactType } = this.state;
    if (filter !== prevProps.recipients.filter || prevState.contactType !== contactType && contactType === 'Default') {
      this.renderRecipientDatatable(this.props);
    }
    if (invalidContactsEmail !== prevState.invalidContactsEmail) {
      this.setState({invalidContactsEmail});
    }
  }

  renderRecipientDatatable(filter, bouncedFilterStatus = null) {
    let { deniedGroups, allowedGroups, language, surveyType, surveyInstance, recipientType, eventFilters } = filter;
    const { dispatch } = this.props;
    const { contactType } = this.state;

    if (['', 'MANUAL'].indexOf(recipientType) !== -1) {
      return;
    }
    if (EVENT_RECIPIENT_TYPES.indexOf(recipientType) > -1 && eventFilters.length === 0) {
      return;
    }

    let filterData = {
      groupsIn: allowedGroups,
      groupsNin: deniedGroups,
      language,
      recipientType,
      surveyType,
      surveyInstance
    };

    if (recipientType === "CONTACT" && contactType === 'Default') {
      filterData.isBouncedList = false;

      if (bouncedFilterStatus) {
        filterData.isBouncedList = true;
        dispatch(fetchRecipients(filterData));
      } else {
        dispatch(fetchRecipients(filterData)).then((res) => {
          this.setState({invalidContactsEmail: res.value.data.nbResult});
        }, err => {
          if (err.response.status === 404) {
            this.setState({invalidContactsEmail: 0});
            if (!bouncedFilterStatus) {
              filterData.isBouncedList = false;
              dispatch(fetchRecipients(filterData));
            }
          }
        });
      }
    } else if (recipientType !== "CONTACT") {
      dispatch(fetchRecipients(filterData));
    }
  };

  renderFilters() {
    const { recipientType, targetId } = this.props;
    switch (recipientType) {
      case 'SPEAKER':
        return <SpeakerFilters />;
      case 'GUEST':
        return <GuestFilters />;
      case 'PARTNER':
        return <PartnerFilters />;
      case 'CONTACT':
      case 'PROSPECT':
        return <ContactFilters
          recipientType={recipientType}
        />;
      case 'MAILING_LIST':
        return <MailingListFilters />;
      case 'COLLABORATOR':
        return <CollaboratorFilters />;
      case 'SURVEY_TARGETS':
        return <SurveyFilters surveyId={targetId} />
      case 'PARTNER_GUEST':
        return <PartnerGuestFilters />;
      case 'PAYMENT_REMINDER':
        return <PaymentFilters />
      case 'FOLDER':
        return <FolderFilters />;
      case 'OECCBB_MEMBERS':
        return <OeccbbMembersFilters />;
      case 'CYCLE_GUEST' :
        return <CycleGuestFilters />

    }
    return null;
  }

  render() {
    const { opened, recipients, recipientType, language } = this.props;
    const { invalidContactsEmail, contactType } = this.state;

    return (
      <div id="campaign-recipent__tab" className={`${(opened && recipientType !== "MANUAL") ? '' : 'hide'}`}>
        <h4 className="sidebar__header">{_('selectTarget')}</h4>
        <Target count={recipients.recipientsTotalCount ? recipients.recipientsTotalCount : 0} type={recipientType}
                isRecipientsFetching={recipients.fetching} />
        <div className="row">
          <div className="columns small-6 columns-padding">
            <span className="param__title small-10">{_('recipientType')}</span>
            <div className="small-12">
              <RecipientType />
            </div>
          </div>
        </div>
        {recipientType === 'CONTACT' ?
          <OeccbbMembersFilters handleSetContactType={this.handleSetContactType} contactType={contactType} /> : null
        }
        {contactType === 'Default' ||  recipientType !== 'CONTACT'?
          <div className="recipient-filters">
            {this.renderFilters()}
          </div>
          : null}
        <Recipients
          language={language}
          recipientType={recipientType}
          contactType={contactType}
          invalidContactsEmail={invalidContactsEmail}
          renderRecipientDatatable={(bouncedFilterStatus) => this.renderRecipientDatatable(this.props, bouncedFilterStatus)}
        />
      </div>
    );
  }

  componentWillUnmount() {
    const { _requests, dispatch } = this.props;

    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    dispatch(clearSourcesTokens());
  }
}
