import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { DropTarget } from 'react-dnd'
import uuid from 'uuid';

import debounce from "lodash.debounce";
import { setCurrentLayout, resetLayoutBlocs, addLayoutBlock, addBlockToTemplate, toggleAddBlockSideBar, addFeedToCurrentLayout } from 'actions';
import _ from 'i18n';
import { createBlockDesign, createBlockHtml } from '../../../services/utils';
import { indexLayoutDynamicFields } from '../../../services/block/rowParser';

class LayoutItem extends React.PureComponent {
  constructor(props) {
    super(props);
    if ("TEMPLATE" == this.props.base) {
      this.toogleLayoutSelection = debounce(this.toogleLayoutSelection, 200);
    }
  };

  toogleLayoutSelection = () => {
    const { setCurrentLayout, id, design, name, currentLayout, column, type, base, content } = this.props;

    if (base == "TEMPLATE") {
      const layout = {
        id: `${id}-col-${column}-${uuid()}`,
        design,
        type,
        column: column || 1,
        feeds: [],
        content
      };

      this.addLayoutToTemplate(layout);
      return;
    }

    let layout = {
      id,
      design,
      name,
      column: column || 1,
      type
    };

    setCurrentLayout(("STATIC_BLOCK" == type && currentLayout && currentLayout.id == id) ? null : layout);
  }

  resetArticles = (e) => {
    const { resetLayoutBlocs, id } = this.props;
    resetLayoutBlocs(id);
    e.stopPropagation();
  }

  combineLayout = (column) => {
    const { addLayoutBlock, setCurrentLayout, id, design, name, type, content, base } = this.props;

    let layout = {
      id: `${id}-col-${column}`,
      design,
      name,
      column,
      type,
      content,
      feeds: [],
    };

    if (base == "TEMPLATE") {
      this.addLayoutToTemplate(layout);
    } else {
      setCurrentLayout(layout);
      addLayoutBlock(layout);
    }
  }

  addLayoutToTemplate = (layout) => {
    const { design, content, column } = layout;
    let blocks = createBlockDesign(new Array(column).fill(JSON.parse(design)));
    layout.design = indexLayoutDynamicFields(JSON.stringify(blocks.data), column);
    const layoutHtml = createBlockHtml(content, column);
    layout.content = indexLayoutDynamicFields(layoutHtml, column);

    this.props.addBlockToTemplate(layout);
    this.props.closeAddBlockSideBar();
  }

  render() {
    const { currentLayout, preview, id, blocs, column, design, type, base, connectDropTarget, isOver, canDrop } = this.props;
    let count = 0;
    let layoutBlocs = blocs.filter(bloc => bloc.id == id);
    if (layoutBlocs && layoutBlocs.length == 1) {
      count = (layoutBlocs[0].feeds && layoutBlocs[0].feeds.length) || 0;
    }
    let isSelected = currentLayout && (currentLayout.id == id);

    let previews = [];
    let maxColumn = column || 1;
    for (let index = 0; index < maxColumn; index++) {
      previews.push(<Preview imgSrc={preview} key={`preview-${index}`} />);
    }

    let bloc = JSON.parse(design);
    const isColumnBloc = (bloc.body.rows[0] && bloc.body.rows[0].cells.length == 1);

    let padding = "";
    if (type === "SPEAKER_LAYOUT") {
      padding = "speaker-layout padding-x-";
      switch (column) {
        case 2: padding += 5;
          break;
        case 3: padding += 2;
          break;
        case 4: padding += 0;
          break;
        default: padding += 9;
      }
    }
    const isActive = isOver && canDrop;

    return connectDropTarget(<div id={`layout-${id}`} className={`layout-item flex-container ${(isActive || isSelected) && "layout-selected"} ${isActive && "layout-item__is-over"}`}
      onClick={this.toogleLayoutSelection}>
      {base == "LAYOUT" && <div className="counter">{count}</div>}
      <div className={`layout-item__img ${padding}`}>
        {previews}
      </div>
      {type != "STATIC_BLOCK" && <div className="drop-down-option">
        <span className="icon-options-vertical"></span>
        <ul className="drop-down-option__left">
          {base == "LAYOUT" && <li onClick={this.resetArticles}>{_("reset")} </li>}
          {(!column || column == 1) &&
            <li>
              <div>{_("combine columns")} </div>
              <div className="flex-container colspan-container">
                {(('SPEAKER_LAYOUT' == type) ? [2, 3, 4] : [2, 3]).map(number => {
                  const isActive = isColumnBloc || number == 2;
                  return <span key={`multi-${number}`} className={`colspan-item ${(isActive) ? "active" : "disabled"}`}
                    onClick={e => {
                      if (isActive) {
                        this.combineLayout(number);
                      }
                      e.stopPropagation();
                    }}>
                    X {number}</span>
                })
                }
              </div>
            </li>
          }
        </ul>
      </div>}
    </div>);
  }
}

const mapStateToProps = (store, ownProps) => ({
  currentLayout: store.themes.newsletter.currentLayout,
  blocs: store.themes.newsletter.blocks,
  base: store.themes.newsletter.base
});

const mapDispatchToProps = dispatch => ({
  addBlockToTemplate: (block) => dispatch(addBlockToTemplate(block)),
  closeAddBlockSideBar: () => dispatch(toggleAddBlockSideBar(false)),
  setCurrentLayout: (layout) => dispatch(setCurrentLayout(layout)),
  addLayoutBlock: (layout) => dispatch(addLayoutBlock(layout)),
  resetLayoutBlocs: (id) => dispatch(resetLayoutBlocs(id)),
  addFeedToCurrentLayout: (feed) => dispatch(addFeedToCurrentLayout(feed)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DropTarget(
    (props) => props.type,
    {
      drop(props, monitor) {
        const { id, design, name, column, type } = props;
        const layout = {
          id,
          design,
          name,
          column: column || 1,
          type
        };
        props.setCurrentLayout(layout);
        props.addFeedToCurrentLayout(monitor.getItem());
      },
    },
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  )
)(LayoutItem)


export class Preview extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.imgSrc !== this.props.imgSrc);
  }
  render() {
    const { imgSrc } = this.props;
    return <img src={(imgSrc) ? `${imgSrc}` : 'img/theme/no-theme.png'} />;
  }
};
