import React, { Component } from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";

import ActionsCell from "../datatable/ActionsCell";

import { CAMPAIGN_LANGUAGE } from "Common";
import { deleteCampaign, updateCampaignStatus, fetchCampaigns } from "thunks";
import { setTTPDialogCustomData } from "actions";
import { onSuccess, onError, getStatusColorClass, getDate } from "utils";
import _ from "i18n";
import "react-notifications/lib/notifications.css";
import DuplicateCampaignForm from "../datatable/DuplicateCampaignForm";
import DIALOG_TYPES from "../../../constants/TTPDialog";
import CampaignsFetching from "fetching/CampaignFetching";
import { SVG_DELETE_ICON } from "utils";
import CampaignCompleteViewer from "./CampaignCompleteViewer";
import ShareCampaign from "./ShareCampaign";
import { SVG_CONTACTS_ICON } from "../../../services/svgIcones";
import ChangeScheduledAtDateForm from "../datatable/ChangeScheduledAtDateForm";
import moment from "moment";
import { ModalConfirm } from "tamtam-components";
import { ucFirst } from "../../../services/common";
import CampaignApprovalModal from "./CampaignApprovalModal";

@connect((store) => {
  return {
    token: store.auth.token,
    lng: store.params.lng,
  };
})
export default class CampaignList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: null,
      campaignType: null,
      haltStatusCampaignId: null,
      status: null,
      showDuplicateForm: false,
      showCompleteCampaign: false,
      showCampaignApproval: false,
      showShareCampaign: false,
      showChangeScheduledAtDate: false,
      campaignAttachments: [],
      isOpenDeleteModal: false,
      isOpenDuplicateModal: false,
    };

    this.toggleDuplicateForm = (showDuplicateForm) =>
      this.setState({ showDuplicateForm });
    this.toggleCompleteCampaign = (showCompleteCampaign) =>
      this.setState({ showCompleteCampaign });
    this.toggleCampaignApproval = (showCampaignApproval) =>
      this.setState({ showCampaignApproval });
    this.toggleShareCampaign = (showShareCampaign) =>
      this.setState({ showShareCampaign });
    this.toggleChangeScheduledAtDate = (showChangeScheduledAtDate) =>
      this.setState({ showChangeScheduledAtDate });
    this.handleHaltStatus = this.handleHaltStatus.bind(this);
    this.setSelectedCampaignId = this.setSelectedCampaignId.bind(this);
  }

  handleOpenDeleteDialog() {
    const { dispatch, isUAVersion } = this.props;
    if (isUAVersion) {
      this.setState({ isOpenDeleteModal: true });
    } else {
      dispatch(
        setTTPDialogCustomData({
          approvedAction: this.deleteCampaign.bind(this),
          message: _("deleteCampaignConfirm"),
          proceedBtnLabel: _("delete"),
          type: DIALOG_TYPES["ALERT"],
          title: "Confirmation of deletion",
          svgIcon: SVG_DELETE_ICON,
        }),
      );
    }
  }

  handleOpenStopDialog() {
    const { dispatch } = this.props;
    dispatch(
      setTTPDialogCustomData({
        approvedAction: this.stopCampaign.bind(this),
        message: _("stopCampaignConfirm"),
        proceedBtnLabel: _("stop"),
        type: DIALOG_TYPES["ALERT"],
        title: "Halt confirmation",
      }),
    );
  }

  handleHaltStatus(id, status) {
    this.setState({ haltStatusCampaignId: id, status: status });
  }

  stopCampaign() {
    const { dispatch } = this.props;
    let id = this.state.haltStatusCampaignId;
    let status = this.state.status;
    dispatch(updateCampaignStatus({ id, status })).then(
      (res) => onSuccess(res),
      (err) => onError(err),
    );
  }

  deleteCampaign() {
    let campaignId = this.state.campaignId;
    this.props.dispatch(deleteCampaign(campaignId)).then(
      (res) => {
        this.props.dispatch(fetchCampaigns());
        this.onSuccess(res);
      },
      (err) => this.onFailure(),
    );
  }

  onSuccess(res, msg) {
    if (res.value instanceof Error) {
      NotificationManager.error(_("incompleteOperation"), _("error"));
    } else {
      NotificationManager.success(
        _("campaignDeleted"),
        _("deleteCampaignSuccess"),
      );
    }
  }

  onFailure() {
    NotificationManager.error(_("errorOccured"), _("error"));
  }

  setSelectedCampaignId(campaignId) {
    this.setState({
      campaignId: campaignId,
    });
  }

  handleDuplicateCampaign = (campaign) => {
    this.setState({
      campaignId: campaign.id,
      campaignAttachments: campaign.attachments ? campaign.attachments : [],
      campaignType: campaign.type,
      showDuplicateForm: true,
    });
  };

  render() {
    let { campaigns, lng, isUAVersion } = this.props;
    let {
      showDuplicateForm,
      campaignId,
      campaignAttachments,
      showCompleteCampaign,
      showCampaignApproval,
      showChangeScheduledAtDate,
      isOpenDeleteModal,
      isOpenDuplicateModal,
      showShareCampaign,
    } = this.state;

    if (campaigns.fetching) {
      return <CampaignsFetching />;
    }

    return (
      <div className="medium-12 columns">
        {(campaigns.items || []).map((item) => {
          let colorClass = getStatusColorClass(
            item.status,
            0,
            null,
            null,
            item.approved,
            item.lastTestversion,
            item.scheduledDeliveryAt,
          );
          const { scheduledDeliveryAt, updatedAt, createdAt, status, sentAt } =
            item;

          let date = {
            label: "createdAt",
            date: createdAt,
            icon: "icon-clock",
          };

          if (["DRAFT", "TEST"].indexOf(item.status) !== -1) {
            date = { label: "updatedAt", date: updatedAt, icon: "icon-clock" };
          } else if (scheduledDeliveryAt && status !== "SENT") {
            let sd = new Date(scheduledDeliveryAt);
            if (sd.toString() === "Invalid Date") {
              sd = new Date(scheduledDeliveryAt.replace(/-/g, "/"));
            }
            const tmp = {
              date:
                sd.getFullYear() +
                "-" +
                (sd.getMonth() + 1) +
                "-" +
                sd.getDate() +
                " " +
                sd.getHours() +
                ":" +
                sd.getMinutes() +
                ":" +
                sd.getSeconds(),
              timezone: "UTC",
            };
            date = { label: "scheduledFor", date: tmp, icon: "icon-calendar" };
            let isScheduledAtExpired =
              getDate(tmp, lng, false, true) < moment();
            colorClass = getStatusColorClass(
              item.status,
              0,
              null,
              null,
              item.approved,
              item.lastTestversion,
              item.scheduledDeliveryAt,
              isScheduledAtExpired,
            );
          } else if (sentAt) {
            date = { label: "Sent at", date: sentAt, icon: "icon-clock" };
          }

          return (
            <div key={item.id} className={`row list-item border-${colorClass}`}>
              <div className="medium-4 p-r-xs flex-container flex-dir-column align-center">
                <div className="list-item__header">{item.object}</div>
              </div>
              <div className="medium-3 p-l-s p-r-xs flex-container flex-dir-column align-center">
                <div className="list-item__sub-header">
                  <span className="item-number">
                    {item.newsLetter
                      ? ucFirst(_("newsletter"))
                      : ucFirst(_(item.type).toLowerCase())}{" "}
                    {item.numberOfNewsletter === "0"
                      ? ""
                      : item.numberOfNewsletter}
                  </span>
                </div>
                <div className="list-item__sub-header">
                  <span>
                    {" "}
                    {_(date.label)} {getDate(date.date, lng)}
                  </span>
                </div>
                <div className="list-item__sub-header">
                  <span className="lang">
                    {_(CAMPAIGN_LANGUAGE[item.language])}
                  </span>
                </div>
              </div>
              <div className="medium-2 list-item__contacts p-l-s p-r-xs">
                {item.uaRecipientType?.length > 0 &&
                item.isUaNewsletter === 1 ? (
                  item.uaRecipientType.map((el) => {
                    return el.split(" ").map((word) => {
                      return (
                        <span className="recipient-type">
                          {ucFirst(_(word)).replace(/_/g, "-")}
                        </span>
                      );
                    });
                  })
                ) : (
                  <span className="recipient-type">
                    {ucFirst(_(item.recipientType).toLowerCase())}
                  </span>
                )}
              </div>
              <div className="medium-1 list-item__destinataires p-l-s p-r-xs">
                <div className="container-dest">
                  <div>
                    <div className="icon-dest">{SVG_CONTACTS_ICON}</div>
                    <span>{item.totalRecipients ?? 0}</span>
                  </div>
                  <span>{ucFirst(_("recipients"))}</span>
                </div>
              </div>
              {
                <ActionsCell
                  item={item}
                  colorClass={colorClass}
                  selectedCampaignId={this.setSelectedCampaignId}
                  deleteAction={this.handleOpenDeleteDialog.bind(this)}
                  stopAction={this.handleOpenStopDialog.bind(this)}
                  completeCampaign={() => this.toggleCompleteCampaign(true)}
                  campaignApproval={() => this.toggleCampaignApproval(true)}
                  shareCampaign={() => this.toggleShareCampaign(true)}
                  changeScheduledAtDate={() =>
                    this.toggleChangeScheduledAtDate(true)
                  }
                  onDuplicateAction={() => this.handleDuplicateCampaign(item)}
                  language={lng}
                  onStatus={this.handleHaltStatus}
                  isUAVersion={isUAVersion}
                  handleSetCurrentPage={this.props.handleSetCurrentPage}
                />
              }
            </div>
          );
        })}
        {showDuplicateForm && (
          <DuplicateCampaignForm
            id={campaignId}
            campaignAttachments={campaignAttachments}
            onCloseModal={() => this.toggleDuplicateForm(false)}
            isUAVersion={isUAVersion}
            handleSetCurrentPage={this.props.handleSetCurrentPage}
            campaignType={this.state.campaignType}
          />
        )}
        {showCompleteCampaign && (
          <CampaignCompleteViewer
            isOpen={showCompleteCampaign}
            onClose={() => this.toggleCompleteCampaign(false)}
            completeCampaign={this.completeCampaign}
          />
        )}
        {showCampaignApproval && (
          <CampaignApprovalModal
            // campaignId={campaignId}
            isOpen={showCampaignApproval}
            onClose={() => this.toggleCampaignApproval(false)}
          />
        )}
        {showChangeScheduledAtDate && (
          <ChangeScheduledAtDateForm
            onCloseModal={() => this.toggleChangeScheduledAtDate(false)}
          />
        )}
        {showShareCampaign && (
          <ShareCampaign
            isOpen={showShareCampaign}
            onClose={() => this.toggleShareCampaign(false)}
          />
        )}
        <ModalConfirm
          type={"delete"}
          isOpen={isOpenDeleteModal}
          onCancel={() => this.setState({ isOpenDeleteModal: false })}
          onConfirm={() => {
            this.deleteCampaign();
            this.setState({ isOpenDeleteModal: false });
          }}
          inProcess={false}
          actionFailed={false}
          title={_("Confirmation of deletion")}
          text={_("deleteCampaignConfirm")}
          labelNo={_("cancel")}
          labelYes={_("delete")}
          labelError={"Error"}
        />
        {/* <ModalConfirm
          type={"duplicate"}
          isOpen={isOpenDuplicateModal}
          onCancel={() => this.setState({ isOpenDuplicateModal: false })}
          onConfirm={() => {
            this.deleteCampaign();
            this.setState({ isOpenDuplicateModal: false });
          }}
          inProcess={false}
          actionFailed={false}
          title={_("Confirmation of deletion")}
          text={_("deleteCampaignConfirm")}
          labelNo={_("cancel")}
          labelYes={_("delete")}
          labelError={"Error"}
        />*/}
      </div>
    );
  }
}
