import * as api from "./../../api/TamtamAPI/dashboard";
import * as actions from "./../actions/dashboard";

export const fetchundeliveredMailData = () => {
  return (dispatch, getState) => {
    let clientId = getState().auth.currentClient.id;
    const {filter} = getState().dashboard;
    const {token} = getState().auth;
    return dispatch(
      actions.fetchPercentageDataUndeliveredMail(
        api.getundeliveredMailData(clientId, filter, token)
      )
    );
  }
};
export const fetchCampaignsData = () => {
  return (dispatch, getState) => {
    const { token} = getState().auth;
    let clientId = getState().auth.currentClient.id;
    const { filter } = getState().dashboard;
    const {typeEmail} = getState().dashboard;

    return dispatch(
      actions.fetchCampaignsChartData(
        api.getCampaignsData(clientId, token, filter, typeEmail)
      )
    );
  };
};
export const fetchBestType = () => {
  return (dispatch, getState) => {
    const { token} = getState().auth;
    let clientId = getState().auth.currentClient.id;
    const { filter } = getState().dashboard;
    return dispatch(actions.fetchBestType(api.getBestType(token,clientId, filter))); };
};
export const fetchOpensCount = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    const { filter } = getState().dashboard;
    return dispatch(
      actions.fetchOpensChartData(
        api.getOpensCount(token,clientId, filter)
      )
    );
  };
};
export const fetchClicksCount = () => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let clientId = getState().auth.currentClient.id;
    const { filter } = getState().dashboard;
    return dispatch(
      actions.fetchClicksChartData(
        api.getClicksCount(token,clientId, filter)
      )
    );
  };
};
export const fetchDataScoreAction = () => {
  return (dispatch, getState) => {
    let clientId = getState().auth.currentClient.id;
    const {filter} = getState().dashboard;
    const {token} = getState().auth;
    return dispatch(
      actions.fetchScoreData(
        api.getScoreData(clientId, filter, token)
      )
    );
  }
};

export const fetchMembersStats = () => {
  return (dispatch, getState) => {
    const {token} = getState().auth;
    let clientId = getState().auth.currentClient.id;
    return dispatch(
      actions.fetchMembersStats(
        api.getStats(clientId, token)
      )
    );
  }
};

export const fetchStatsHistory = () =>{
  return (dispatch, getState) => {
    const {token} = getState().auth;
    let clientId = getState().auth.currentClient.id;
    const {filter} = getState().dashboard;
    return dispatch(
      actions.fetchStatsHistory(
        api.getStatsHistory(clientId, token, filter)
      )
    );
  }
};

export const fetchLinks = (date) => {
  return (dispatch, getState) => {
    const {token} = getState().auth;
    let clientId = getState().auth.currentClient.id;
    const {filter} = getState().dashboard;
    return dispatch(
      actions.fetchLinks(
        api.getLinks(clientId, token, date)
      )
    );
  }
}
