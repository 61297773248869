import React from 'react';
import _ from 'i18n';

export const BlankText = (props) => {

  return (
    <div className="generic-email-list__item blank-text empty-block small-5" onClick={props.clicked}>
      <span className="icon-wrapper" >
        <img src="/img/icons/add.svg" alt="Add" />
      </span>
      <div className="message">
        <span>{_('Add your custom text')}</span>
      </div>
    </div>
  );
}
