import axios from '../../axios-api';

export const getGenericEmailTypes = (token, targetApp) => {
  const requestUrl = `/mailing/generic-email-type`;

  const filter = [];

  if (targetApp) {
    filter.push({
      property: 'targetApp',
      value: targetApp,
      operator: 'eq',
    });
  }

  const sort = [
    {
      property: 'id',
      dir: 'asc',
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
      sort: JSON.stringify(sort),
    },
  });
};
