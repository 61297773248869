import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'i18n';
import { inIframe } from 'utils';
import Lottie from "react-lottie";
import error404Data from "../common/lottie-animations/error-404.json"
import accessDenied from "../common/lottie-animations/access-denied.json";
import nothingFoundData from "../common/lottie-animations/nothing-found.json"

export default class NotFound extends Component {

  state = {
    showBack: !inIframe()
  }

  defaultOptions = {
    loop: true,
    autoplay: true,
  };

  render() {
    let { mainClass, children, text, errorPageType, principalText } = this.props;
    let renderedTxt;
    const backBtn = this.state.showBack ? (
      <button className="btn secondary">
        <i className="icon-arrow-left"></i> &nbsp;&nbsp;&nbsp;&nbsp;
        <Link to="/">{_('goBackToHome')}</Link>
      </button>
    ) : null;

    switch (errorPageType) {
      case 'ERROR_404':
        renderedTxt = (
          <div className={"not-found__main"}>
            <Lottie options={{ ...this.defaultOptions, animationData: error404Data }} height={360} isClickToPauseDisabled={true} />
            <h2>{_("Oops")}</h2>
            <h5>{_("You've moved so fast and got lost...")}</h5>
            <p>{_('pageNotFound')}</p>
            {backBtn}
          </div>
        )
        break;
      case "ACCESS_DENIED":
        renderedTxt = (
          <div className={"not-found__main"}>
            <Lottie
              options={{ ...this.defaultOptions, animationData: accessDenied }}
              height={360}
              isClickToPauseDisabled={true}
            />
            <h2>{_("Oops")}</h2>
            <p>{text ? _(text) : _('looks like you are not authorized to access this page')}!</p>
            <p>{_('contact your community admin')}</p>
            {backBtn}
          </div>
        );
        break;
      case "ERROR_500":
        renderedTxt = (
          <div className={"not-found__main"}>
            <Lottie
              options={{ ...this.defaultOptions, animationData: nothingFoundData }}
              height={360}
              isClickToPauseDisabled={true}
            />
            <h2>{_("Oops")}</h2>
            <p>{_('Sorry there was a problem processing your request')}</p>
            <button className="btn secondary m-t-m" onClick={() => window.location.reload()}>
              {_('reload the page')}
            </button>
          </div>
        )
        break;
      default:
        renderedTxt = (
          <div className={"not-found__main"}>
            <Lottie options={{ ...this.defaultOptions, animationData: nothingFoundData }} height={300} isClickToPauseDisabled={true} />
            <h3>{_(principalText || "Nothing Found")}</h3>
            <p>{_(text || "nothing_found")}</p>
          </div>
        )
        break;
    }
    return (
      <div className={`not-found ${mainClass || ''}`}>
        {children || renderedTxt}
      </div>
    );
  }
}
