import React, { Component } from 'react';

export default class CampaignsFetching extends Component {

  render() {

    let items = [];
    for (let i = 0; i < 3; i++) {
      items.push(<div key={i} className={`row list-item border-draft`}>
        <div className="medium-3">
          <h3 className="list-item__header gradiant"></h3>
        </div>
        <div className="medium-3">
          <h4 className="list-item__sub-header gradiant"></h4>
          <h4 className="list-item__sub-header gradiant"></h4>
        </div>
        <div className="medium-1">
          <h4 className="gradiant"></h4>
        </div>
        <div className="medium-2">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="medium-1">
          <h3 className="gradiant"></h3>
        </div>
        <div className="medium-2 flex-container align-right">
          <h4 className="list-item__status gradiant"></h4>
        </div>
      </div>
      );
    }
    return <div className="small-12 columns campaign-fetching">{items}</div>
  }
}
