import React, { Component } from 'react';

import _ from 'i18n';

export default class ActionsCell extends Component {

  render() {
    const { item: { campaign, status, type }, onShowDetail } = this.props;
    let statusColorClass = "bg-draft";
    if (["VALIDATED"].indexOf(status) > -1) {
      statusColorClass = "bg-info";
    } if (["SENT"].indexOf(status) > -1) {
      statusColorClass = "bg-success";
    } else if (status == "PENDING") {
      statusColorClass = "bg-warning";
    } else if (status == "REJECTED") {
      statusColorClass = "bg-alert";
    }

    return (
      <div className="small-2">
        <span className={type == "CREDIT" ? `campaign-status display-unset` : `campaign-status show-actions`}
          onClick={() => onShowDetail(campaign.id)} >
          <span className={`status-color ${statusColorClass}`} />
          <i className="icon-eye"></i>
          <span className="status-label">{_(status)}</span>
          <span className="campaign-action">
            {_("bill")}
          </span>
        </span>
      </div>
    );
  };
}
