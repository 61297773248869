import dialog from "suneditor/src/plugins/modules/dialog";
import _icons from "suneditor/src/assets/defaultIcons";
import colorPicker from "suneditor/src/plugins/modules/_colorPicker";
import _ from "i18n";

const BASE_STYLE = "margin: 0 6px; font-size: 14px; padding: 10px 28px; border-radius: 5px; font-weight: 500;";

const ICON = {
  fontUp: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve" width="80" height="80">
              <g>
                <g>
                  <polygon points="63.5,52.5 63.5,43.5 54.5,43.5 54.5,36.5 63.5,36.5 63.5,27.5 70.5,27.5 70.5,36.5     79.5,36.5 79.5,43.5 70.5,43.5 70.5,52.5   "/>
                </g>
              </g>
              <g>
                <g>
                  <path d="M41.968,69.5l-3.426-12H16.002l-3.425,12H0.695l19.787-59h14.042l19.332,59H41.968z M19.142,46.5    h16.26l-7.504-26.289h-1.253L19.142,46.5z"/>
                </g>
              </g>
            </svg>`,
  fontDown: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 80 80" style="enable-background:new 0 0 80 80;" xml:space="preserve" width="80" height="80">
              <g>
                <rect x="54.5" y="36.5" width="25" height="7"/>
              </g>
              <g>
                <g>
                    <path d="M41.968,69.5l-3.426-12H16.003l-3.426,12H0.695l19.786-59h14.042l19.332,59H41.968z M19.142,46.5    h16.261l-7.505-26.289h-1.252L19.142,46.5z"/>
                </g>
              </g>
              </svg>`,
}

// ex) ex) A link dialog plugin with multiple target options.
export let customLink = {
  // @Required
  // plugin name
  name: "customLink",

  // @Required
  // data display
  display: "dialog",

  title: _("link"),
  buttonClass: "",
  innerHTML: _icons.link,

  // @Required
  // add function - It is called only once when the plugin is first run.
  // This function generates HTML to append and register the event.
  // arguments - (core : core object, targetElement : clicked button element)
  add: function (core) {

    // If you are using a module, you must register the module using the "addModule" method.
    core.addModule([dialog, colorPicker]);

    // @Required
    // Registering a namespace for caching as a plugin name in the context object
    const context = core.context;

    context.customLink = {
      focusElement: null, // @Override // This element has focus when the dialog is opened.
      linkAnchorText: null,
      _linkAnchor: null,
      textColor: null,
      bgColor: null,
      borderColor: null,
    };

    /** link dialog */
    let link_dialog = this.setDialog(core);
    context.customLink.modal = link_dialog;
    context.customLink.focusElement = link_dialog.querySelector("._se_link_url");
    context.customLink.linkAnchorText = link_dialog.querySelector("._se_link_text");
    context.customLink.textColor = link_dialog.querySelector("._se_link_text_color input");
    context.customLink.bgColor = link_dialog.querySelector("._se_link_bg_color input");
    context.customLink.borderColor = link_dialog.querySelector("._se_link_border_color input");

    /** link controller */
    let link_controller = this.setController_LinkButton(core);
    context.customLink.linkController = link_controller;
    context.customLink._linkAnchor = null;

    /** add event listeners */
    link_dialog.querySelector("form").addEventListener("submit", this.submit.bind(core));
    link_controller.addEventListener("click", this.onClick_linkController.bind(core));
    context.customLink.bgColor.addEventListener("change", this.onColorChanged.bind(core));
    context.customLink.borderColor.addEventListener("change", this.onColorChanged.bind(core));
    /** append html */
    context.dialog.modal.appendChild(link_dialog);

    /** append controller */
    context.element.relative.appendChild(link_controller);

    /** empty memory */
    link_dialog = null;
    link_controller = null;
  },

  /** dialog */
  setDialog: function (core) {
    const lang = core.lang;
    const dialog = core.util.createElement("DIV");
    dialog.className = "se-dialog-content";
    dialog.style.display = "none";

    let html = `
      <form class="editor_link">
        <div class="se-dialog-header">
          <button type="button" data-command="close" class="se-btn se-dialog-close" aria-label="Close" title="${lang.dialogBox.close}">
            ${core.icons.cancel}
          </button>
          <span class="se-modal-title"> ${lang.dialogBox.linkBox.title} </span>
        </div>
        <div class="se-dialog-body">
          <div class="se-dialog-form">
            <label> ${lang.dialogBox.linkBox.url} </label>
            <input class="se-input-form _se_link_url" type="text" />
          </div>
          <div class="se-dialog-form">
            <label> ${lang.dialogBox.linkBox.text} </label><input class="se-input-form _se_link_text" type="text" />
          </div>
           <div class="se-dialog-form">
            <label> ${_("Text color")} </label>
            <div class="se-tag-container _se_link_text_color">
                <input type="color" value="#18A0FB">
            </div>
          </div>
          <div class="se-dialog-form">
            <label> ${_("Background color")} </label>
            <div class="se-tag-container _se_link_bg_color">
                <input type="color" value="#ffffff">
            </div>
          </div>
          <div class="se-dialog-form">
            <label> ${_("Border color")} </label>
            <div class="se-tag-container _se_link_border_color">
                <input type="color" value="#ffffff">
            </div>
          </div>
        </div>
          <div class="se-dialog-footer">
          <button type="submit" class="se-btn-primary" title="${lang.dialogBox.submitButton}"><span> ${lang.dialogBox.submitButton} </span></button>
        </div>
      </form>
     `;
    dialog.innerHTML = html;

    return dialog;
  },

  /** modify controller button */
  setController_LinkButton: function (core) {
    const lang = core.lang;
    const icons = core.icons;
    const link_btn = core.util.createElement("DIV");

    link_btn.className = "se-controller se-controller-link";
    link_btn.innerHTML = `
      <div class="se-arrow se-arrow-up"></div>
      <div class="link-content">
        <span><a target="_blank" href=""></a>&nbsp;</span>
        <div class="se-btn-group">
           <button type="button" data-command="font-up" tabindex="-1" class="se-tooltip">
            ${ICON.fontUp}
            <span class="se-tooltip-inner"><span class="se-tooltip-text"> ${lang.toolbar.fontSize} + </span></span>
          </button>
          <button type="button" data-command="font-down" tabindex="-1" class="se-tooltip">
            ${ICON.fontDown}
            <span class="se-tooltip-inner"><span class="se-tooltip-text"> ${lang.toolbar.fontSize} - </span></span>
          </button>
          <button type="button" data-command="update" tabindex="-1" class="se-tooltip">
            ${icons.edit}
            <span class="se-tooltip-inner"><span class="se-tooltip-text"> ${lang.controller.edit} </span></span>
          </button>
          <button type="button" data-command="unlink" tabindex="-1" class="se-tooltip">
            ${icons.unlink}
            <span class="se-tooltip-inner"><span class="se-tooltip-text"> ${lang.controller.unlink} </span></span>
          </button>
          <button type="button" data-command="delete" tabindex="-1" class="se-tooltip">
            ${icons.delete}
            <span class="se-tooltip-inner"><span class="se-tooltip-text"> ${lang.controller.remove} </span></span>
          </button>
        </div>
      </div>
    `;

    return link_btn;
  },

  // @Required, @Override dialog
  // This method is called when the plugin button is clicked.
  // Open the modal window here.
  open: function () {
    // open.call(core, pluginName, isModify)
    this.plugins.dialog.open.call(this, "customLink", "customLink" === this.currentControllerName);
  },

  submit: function (e) {
    this.showLoading();

    e.preventDefault();
    e.stopPropagation();

    const submitAction = function () {
      if (this.context.customLink.focusElement.value.trim().length === 0) return false;

      const contextLink = this.context.customLink;
      const url = contextLink.focusElement.value;
      const anchor = contextLink.linkAnchorText;
      const color = contextLink.textColor?.value || "";
      const background = contextLink.bgColor.classList.contains("color-changed") ? contextLink.bgColor?.value : "";
      const borderColor = contextLink.borderColor.classList.contains("color-changed") ? contextLink.borderColor?.value : "";
      const linkStyle = `${(background || borderColor) ? BASE_STYLE : ""}; color: ${color}; background-color: ${background}; ${borderColor ? "border: 1px solid " + borderColor : "" };`;
      const anchorText = anchor.value.length === 0 ? url : anchor.value;

      // When opened for modification "this.context.dialog.updateModal" is true
      if (!this.context.dialog.updateModal) {
        const oA = this.util.createElement("A");
        oA.href = url;
        oA.textContent = anchorText;
        oA.target = "_blank";
        oA.setAttribute("style", linkStyle);

        const selectedFormats = this.getSelectedElements();
        if (selectedFormats.length > 1) {
          const oFormat = this.util.createElement(selectedFormats[0].nodeName);
          oFormat.appendChild(oA);
          this.insertNode(oFormat);
        } else {
          this.insertNode(oA);
        }

        this.setRange(oA.childNodes[0], 0, oA.childNodes[0], oA.textContent.length);
      } else {
        contextLink._linkAnchor.href = url;
        contextLink._linkAnchor.textContent = anchorText;
        contextLink._linkAnchor.setAttribute("style", linkStyle);

        // set range
        this.setRange(contextLink._linkAnchor.childNodes[0], 0, contextLink._linkAnchor.childNodes[0], contextLink._linkAnchor.textContent.length);
      }

      // history stack
      this.history.push(false);

      contextLink.focusElement.value = "";
      contextLink.linkAnchorText.value = "";
    }.bind(this);

    try {
      submitAction();
    } finally {
      this.plugins.dialog.close.call(this);
      this.closeLoading();
      this.focus();
    }

    return false;
  },

  // @Override core
  // Plugins with active methods load immediately when the editor loads.
  // Called each time the selection is moved.
  active: function (element) {
    if (!element) {
      if (this.controllerArray.indexOf(this.context.customLink.linkController) > -1) {
        this.controllersOff();
      }
    } else if (this.util.isAnchor(element) && element.getAttribute("data-image-link") === null) {
      if (this.controllerArray.indexOf(this.context.customLink.linkController) < 0) {
        this.plugins.customLink.call_controller.call(this, element);
      }
      return true;
    }

    return false;
  },

  // @Override dialog
  // This method is called just before the dialog opens.
  // If "update" argument is true, it is not a new call, but a call to modify an already created element.
  on: function (update) {
    if (!update) {
      this.plugins.customLink.init.call(this);
      this.context.customLink.linkAnchorText.value = this.getSelection().toString();
    } else if (this.context.customLink._linkAnchor) {
      // "update" and "this.context.dialog.updateModal" are always the same value.
      // This code is an exception to the "link" plugin.
      this.context.dialog.updateModal = true;
      this.context.customLink.focusElement.value = this.context.customLink._linkAnchor.getAttribute("href");
      this.context.customLink.linkAnchorText.value = this.context.customLink._linkAnchor.textContent;
    }
  },

  call_controller: function (selectionATag) {
    this.editLink = this.context.customLink._linkAnchor = selectionATag;
    const linkBtn = this.context.customLink.linkController;
    const link = linkBtn.querySelector("a");

    link.href = selectionATag.getAttribute("href");
    link.title = selectionATag.textContent;
    link.textContent = selectionATag.textContent;

    const offset = this.util.getOffset(selectionATag, this.context.element.wysiwygFrame);
    linkBtn.style.top = (offset.top + selectionATag.offsetHeight + 10) + "px";
    linkBtn.style.left = (offset.left - this.context.element.wysiwygFrame.scrollLeft) + "px";

    linkBtn.style.display = "block";

    const overLeft = this.context.element.wysiwygFrame.offsetWidth - (linkBtn.offsetLeft + linkBtn.offsetWidth);
    if (overLeft < 0) {
      linkBtn.style.left = (linkBtn.offsetLeft + overLeft) + "px";
      linkBtn.firstElementChild.style.left = (20 - overLeft) + "px";
    } else {
      linkBtn.firstElementChild.style.left = "20px";
    }

    // Show controller at editor area (controller elements, function, "controller target element(@Required)", "controller name(@Required)", etc..)
    this.controllersOn(linkBtn, selectionATag, "customLink");
  },

  onClick_linkController: function (e) {
    e.stopPropagation();

    const command = e.target.getAttribute("data-command");
    if (!command) return;

    e.preventDefault();

    if (/update/.test(command)) {
      const contextLink = this.context.customLink;
      contextLink.focusElement.value = contextLink._linkAnchor.getAttribute("href");
      contextLink.linkAnchorText.value = contextLink._linkAnchor.textContent;
      contextLink.textColor.value = this.plugins.customLink.getHexColor(this, contextLink._linkAnchor.style.color);
      const bgColor = this.plugins.customLink.getHexColor(this, contextLink._linkAnchor.style.backgroundColor);
      if (bgColor) contextLink.bgColor.classList.add("color-changed");
      contextLink.bgColor.value = bgColor || "#ffffff";
      const borderColor = this.plugins.customLink.getHexColor(this, contextLink._linkAnchor.style.borderColor);
      if (borderColor) contextLink.borderColor.classList.add("color-changed");
      contextLink.borderColor.value = borderColor || "#ffffff";
      this.plugins.dialog.open.call(this, "customLink", true);
    } else if (/unlink/.test(command)) {
      const sc = this.util.getChildElement(this.context.customLink._linkAnchor, function (current) {
        return current.childNodes.length === 0 || current.nodeType === 3;
      }, false);
      const ec = this.util.getChildElement(this.context.customLink._linkAnchor, function (current) {
        return current.childNodes.length === 0 || current.nodeType === 3;
      }, true);
      this.setRange(sc, 0, ec, ec.textContent.length);
      this.nodeChange(null, null, ["A"], false);
    } else if (/font-up/.test(command)) {
      const contextLink = this.context.customLink;
      contextLink._linkAnchor.style.fontSize = parseInt(contextLink._linkAnchor.style.fontSize) + 2 + "px";
    } else if (/font-down/.test(command)) {
      const contextLink = this.context.customLink;
      contextLink._linkAnchor.style.fontSize = Math.max(parseInt(contextLink._linkAnchor.style.fontSize) - 2, 4) + "px";
    } else {
      /** delete */
      this.util.removeItem(this.context.customLink._linkAnchor);
      this.context.customLink._linkAnchor = null;
      this.focus();

      // history stack
      this.history.push(false);
    }

    if(!/(font-up|font-down)/.test(command)){
      this.controllersOff();
    }
  },
  onColorChanged: function(ev) {
    ev.target.classList.add("color-changed");
    ev.stopPropagation();
  },
  getHexColor: function(self, color) {
    return color ? self.plugins.colorPicker.isHexColor(color) ? color : self.plugins.colorPicker.rgb2hex(color) : "";
  },
  // @Required, @Override dialog
  // This method is called when the dialog window is closed.
  // Initialize the properties.
  init: function () {
    const contextLink = this.context.customLink;
    contextLink.linkController.style.display = "none";
    contextLink._linkAnchor = null;
    contextLink.focusElement.value = "";
    contextLink.linkAnchorText.value = "";
    contextLink.textColor.value = "#18A0FB";
    contextLink.bgColor.value = "#ffffff";
    contextLink.bgColor.classList.remove("color-changed");
    contextLink.borderColor.value = "#ffffff";
    contextLink.borderColor.classList.remove("color-changed");
  }
};
