import React, { Component } from 'react'
import { connect } from 'react-redux';
import {setEboxWidgetAttachments} from "../../actions/actions/ebox/widget";
import {fileConvertSize} from "../../services/utils";

const mapDispatchToProps = dispatch => ({
  setEboxWidgetAttachments: (attachments) => dispatch(setEboxWidgetAttachments(attachments))
});
@connect(null, mapDispatchToProps)

export default class AttachmentsContainer extends Component {

  renderAttachments = () => {
    let {attachments} = this.props;
    return attachments.map((a, i) => {
      return (
        <li key={i} className="attachment">
          <span><a className={`${a.url && "attachment-open"}`} href={a.url && a.url} target="__blank">{a.name}</a></span>
          <span className="size">({fileConvertSize(a.size)})</span>
          <a className="attachment-delete" onClick={() => this.handleDelete(i, a.id)}/>
        </li>
      );
    });
  }

  handleDelete = (index, id) => {
    let {attachments, setEboxWidgetAttachments, onDeleteAttachment} = this.props;
    if (!attachments || attachments.length === 0) return;
    if (onDeleteAttachment) {
      onDeleteAttachment(index, id);
    } else if (attachments[index]) {
      setEboxWidgetAttachments(attachments.filter((_, i) => i !== index));
    }
  }

  render() {
    return <ul className="attachments-list">
        {this.renderAttachments()}
      </ul>
  }
}
