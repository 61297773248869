import React, { Component } from "react";
import styles from "./TTPCard.module.scss";
import moment from "moment";
import _ from "i18n";
import {
  SVG_CHECK_APPROVER_ICON,
  SVG_CLOCK_ICON,
  SVG_REJECT_ICON,
} from "../../../services/svgIcones";
import { connect } from "react-redux";
import { getUserNameForAvatar } from "utils";

class TTPCard extends Component {
  state = {
    showFullText: false,
  };

  toggleShowFullText = () => {
    this.setState((prevState) => ({
      showFullText: !prevState.showFullText,
    }));
  };

  render() {
    const { member, sentAt, handleSingleApprovalClick, hideSendButton } =
      this.props;
    const { showFullText } = this.state;
    let approvalIcon, statusStyle, approvalStatusStyle;

    if (member.decision === "ACCEPT") {
      approvalIcon = SVG_CHECK_APPROVER_ICON;
      statusStyle = styles.acceptStatus;
      approvalStatusStyle = styles.acceptApproval;
    } else if (member.decision === "DENY") {
      approvalIcon = SVG_REJECT_ICON;
      statusStyle = styles.rejectStatus;
      approvalStatusStyle = styles.rejectApproval;
    } else {
      approvalIcon = SVG_CLOCK_ICON;
      statusStyle = styles.pendingStatus;
      approvalStatusStyle = styles.pendingApproval;
    }
    let formattedDate, decisionText;
    if (member.decision === "ACCEPT") {
      formattedDate = moment(member.decideAt).fromNow();
      decisionText = _("approved");
    } else if (member.decision === "DENY") {
      formattedDate = moment.utc(member.decideAt).fromNow();
      decisionText = _("rejected");
    } else {
      formattedDate = sentAt?.date
        ? moment.utc(sentAt.date).fromNow()
        : "Date not available";
      decisionText = _("sent");
    }

    return (
      <div className={styles.cardCollab}>
        <div className={styles.frameGlobal}>
          <div className={styles.collabFrame}>
            <div className={styles.imageFrame}>
              {member.avatar !== undefined ? (
                <img
                  src={`${member.avatar}`}
                  className={styles.approverImage}
                />
              ) : (
                <div className={styles.approverImage}>
                  {getUserNameForAvatar(member.approverName)}
                </div>
              )}
            </div>
            <div className={styles.group}>
              <div className={styles.approverName}>{member.approverName}</div>
              <div className={styles.approverEmail}>{member.email}</div>
            </div>
            <div className={styles.frame}>
              <div className={styles.approval}>
                <div className={approvalStatusStyle}>
                  <span className={styles.approvalIcon}>{approvalIcon}</span>{" "}
                  <div className={statusStyle}>
                    {member.decision === "ACCEPT"
                      ? _("approvalAccepted")
                      : member.decision === "DENY"
                        ? _("approvalRejected")
                        : _("pendingApproval")}
                  </div>
                </div>
                <div className={styles.approvalDate}>
                  {decisionText} {formattedDate}
                </div>
              </div>
              {member.decision !== "ACCEPT" && !hideSendButton && (
                <button
                  className={styles.approveButton}
                  onClick={() => handleSingleApprovalClick(member.email)}
                >
                  {_("resendApproval")}
                </button>
              )}
            </div>
          </div>

          {member.decision === "DENY" && (
            <div style={{ width: "100%" }}>
              <div
                className={styles.textFrame}
                style={{
                  height:
                    showFullText || member.reason.split(" ").length <= 21
                      ? "100%"
                      : "4.5em",
                  overflow: "hidden",
                }}
              >
                {member.reason}
              </div>
              {member.reason.split(" ").length > 21 && (
                <span
                  className={styles.readMore}
                  onClick={this.toggleShowFullText}
                >
                  {showFullText ? _("Reduce") : _("readMore")}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    sentAt: store.currentCampaign.email.sentAt,
  };
};

export default connect(mapStateToProps)(TTPCard);
