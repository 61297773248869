export const initUsersSettings = (resp) => {
  return {
    type: 'INIT_USERS_SETTINGS',
  };
};

export const selectRole = (role) => {
  return {
    type: 'SELECT_ROLE',
    role
  }
}

export const setRole = (role) => {
  return {
    type: 'SET_ROLE',
    role
  }
}

export const setSelectedUsers = (selectedUsers) => {
  return {
    type: 'SET_SELECTED_USERS',
    selectedUsers
  }
}

export const selectSearchWord = (searchWord) => {
  return {
    type: 'SELECT_SEARCH_WORD',
    searchWord
  }
}

export const setAllowCreateCampaign = (allowCreateCampaign) => {
  return {
    type: 'SET_ALLOW_CREATE_CAMPAIGN',
    allowCreateCampaign
  }
}

export const setAllowSendCampaign = (allowSendCampaign) => {
  return {
    type: 'SET_ALLOW_SEND_CAMPAIGN',
    allowSendCampaign
  }
}

export const setAllowCreateSms = (allowCreateSms) => {
  return {
    type: 'SET_ALLOW_CREATE_SMS',
    allowCreateSms
  }
}

export const setAllowSendSms = (allowSendSms) => {
  return {
    type: 'SET_ALLOW_SEND_SMS',
    allowSendSms
  }
}
