import axios from '../axios-api';

export const saveSmsBill = (token, price, clientId, userId) => {
  const requestUrl = `/sms/sms-bill`;

  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('amount', parseFloat(price));
  formData.append('client', clientId);
  formData.append('user', userId);
  return axios.post(requestUrl, formData);
};

export const getSMSBills = (token, clientId, status, smsBillType, isBillsPage, page = 1, pageSize = 10, sortConfig = null) => {
  const fields = ['*', 'client'];
  const requestUrl = `/sms/sms-bill`;

  let filter = [];
  let sort = [];

  if (clientId) {
    filter.push({
      property: 'client',
      value: clientId,
      operator: 'eq'
    });
  }

  if (smsBillType) {
    filter.push({
      property: 'type',
      value: smsBillType,
      operator: 'eq'
    })
  }

  if (status) {
    filter.push({
      property: 'status',
      value: status,
      operator: 'eq'
    })
  }

  if (!isBillsPage) {
    filter.push({
      property: 'type',
      value: 'CREDIT',
      operator: 'eq'
    });
  }

  if (sortConfig) {
    sort.push({
      property: sortConfig.columnKey,
      dir: sortConfig.sortDirection,
    });
  }

  let params = {
    access_token: token,
    fields: fields.join(','),
    limit: pageSize,
    start: (page - 1) * pageSize
  };

  params.sort = JSON.stringify(sort);
  params.filter = JSON.stringify(filter);


  return axios.get(requestUrl, { params });
};

export const getCountryPrice = (token, clientId, countryCode) => {
  const requestUrl = `/sms/sms-price-by-country`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      countryCode: countryCode.toLowerCase(),
      clientId: clientId
    }
  });
};

export const getSMSBill = (token, campaignId) => {
  const requestUrl = `/sms/sms-campaign/detail`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      campaignId: campaignId
    }
  });
};

export const validateSmsCreditRequest = (token, creditRequestId, status) => {
  const requestUrl = `/sms/sms-bill/` + creditRequestId;
  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('status', status);

  return axios.post(requestUrl, formData);
};

