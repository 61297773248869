import React, { Component } from "react";
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "react-loaders";

import _ from 'i18n';
import { getDate } from 'utils';
import { setAttachmentsPaginationPage, initEboxAttachments } from 'actions';
import { fetchEBoxAttachments } from 'thunks';
import { Attachment } from "./Attachment";
import AttachmentFilter from "./AttachmentFilter";
import SearchBox from "../../common/filter/SearchBox";
import TTPTab from "../../ui/tabs/TTPTab";
import { ATTACHMENT_TYPES, ATTACHMENT_CONTEXTS } from 'constants';
import { getFileType, getFileContext } from "../../../services/utils";
import debounce from "lodash.debounce";

@connect(store => {
    return {
        language: store.params.lng,
        fetching: store.ebox.attachmentsList.fetching,
        attachments: store.ebox.attachmentsList.items,
        nbResult: store.ebox.attachmentsList.nbResult,
        paginationPage: store.ebox.attachmentsList.paginationPage,
    }
}, dispatch => ({
    setAttachmentsPaginationPage: (paginationPage) => dispatch(setAttachmentsPaginationPage(paginationPage)),
    fetchEBoxAttachments: (ownerId, filename) => dispatch(fetchEBoxAttachments(ownerId, filename)),
    initEboxAttachments: () => dispatch(initEboxAttachments())
})
)

export default class Gallery extends Component {

    constructor(props) {
        super(props);
        this.openPreview = () => this.setState({ previewIsOpened: true });
        this.closePreview = () => this.setState({ previewIsOpened: false });
        this.switchTab = (tab) => this.setState({ currentTab: tab, type: "ALL", context: "ALL" });
        this.handleSearchInputChange = debounce(this.handleSearchInputChange, 1000);
        this.state = {
            previewIsOpened: false,
            hideIframe: false,
            hasMoreItems: false,
            type: "ALL",
            context: "ALL",
            currentTab: "TYPE",
            filename: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { paginationPage, fetchEBoxAttachments, opened, setAttachmentsPaginationPage, ownerId, inOtherEbox, fetching } = this.props;
        if (fetching != prevProps.fetching) {
            this.setState({
                hasMoreItems: !fetching,
            })
        }
        if (prevProps.opened != opened) {
          this.setState({ currentTab: "TYPE", type: "ALL", filename: "" });
        }
        if (prevProps.opened != opened && this.state.filename != "") {
          fetchEBoxAttachments(inOtherEbox ? ownerId : null);
          return;
        }
        if (paginationPage == 0 && !prevProps.opened) {
            setAttachmentsPaginationPage(1);
        } else if (prevProps.paginationPage != this.props.paginationPage) {
            fetchEBoxAttachments(inOtherEbox ? ownerId : null, this.state.filename);
        }
    }

    loadItems = () => {
        const { paginationPage, fetching, nbResult, attachments, setAttachmentsPaginationPage } = this.props;

        if (!fetching && attachments.length < nbResult) {
            this.setState({ hasMoreItems: false });
            setAttachmentsPaginationPage(paginationPage + 1);
        }
    }

    handleSelectAttachment = (value) => {
      let {currentTab} = this.state;
      if (currentTab === "TYPE") {
        this.setState({type: value, context: ''});
      } else {
        this.setState({context: value, type: ''});
      }
    };

    handleSearchInputChange = (filename) => {
      const {fetchEBoxAttachments, setAttachmentsPaginationPage} = this.props;
      setAttachmentsPaginationPage(1);
      this.setState({ filename: filename });
      fetchEBoxAttachments(this.props.inOtherEbox ? this.props.ownerId : null, this.state.filename);
    };

    getExtensions = (attachment) => {
      let extension = [];
      attachment.urls.map((url, i) => {
          extension.push(getFileType(url));
      });
      return extension;
    };

    getContexts = (attachment) => {
      let context = [];
      attachment.urls.map((url, i) => {
        context.push(getFileContext(url));
      });
      return context;
    };

    renderEBoxAttachments() {
        let { attachments, language, fetching } = this.props;
        let { type, hasMoreItems, context } = this.state;
        if (!fetching && (!attachments || attachments.length == 0)) {
            return <div className="attachment__container notfound">{_("no attachment found")}</div>
        }

        return (
            <div className="gallery-content" ref={(ref) => this.scrollParentRef = ref}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadItems}
                    hasMore={hasMoreItems}
                    useWindow={false}
                    getScrollParent={() => this.scrollParentRef}
                >
                    {attachments.map((attachment, index) => (
                      ((type === "ALL" ||  this.getExtensions(attachment).includes(type)) || (context === "ALL" || this.getContexts(attachment).includes(context))) && <div key={`${attachment.id}-${index}`} className="row">
                              <div className="h7 small-8">{(attachment.object) ? attachment.object + ',' : ''}</div>
                              <div className="h8 small-4">{attachment.sentAt ? getDate(attachment.sentAt, language) : '---'}</div>
                              {attachment.urls && attachment.urls.length > 0 && attachment.urls.map((url, i) =>
                                    <Attachment openPreview={this.openPreview}
                                                key={`attachment-${i}-${index}`}
                                                onGallery={true} url={url}
                                                oneColumn={attachment.urls.length === 1}
                                                type={type}
                                                context={context}
                                    />
                                                )}
                            </div>
                        )
                        )
                    }
                </InfiniteScroll>
                {fetching && <div className="flex-container align-center"><Loader type="ball-grid-pulse" /></div>}
            </div>
        );
    }

    onCloseSideBar = () => {
        this.props.toggleGallerySideBar();
        this.closePreview();
    }

    render() {
        let { opened } = this.props;
        let { previewIsOpened, type, currentTab, context } = this.state;

        return (
            <div id="gallery">
                <div className={`${opened ? "sidebar sidebar--opened" : "sidebar sidebar--opened side-sidebar"}`}>
                    {opened && <div className="sidebar__actions">
                        <div className="row columns small-12">
                            <span className="sidebar__close icomoon icon-tt-close" onClick={this.onCloseSideBar} />
                        </div>
                    </div>}
                    {opened && <div className="sidebar__form">
                        <div className="gallery-header">
                          <h4 className="sidebar__header">{_('Gallery')}</h4>
                          <h5 className="sidebar__subheader">{_('List of attachments received')}</h5>
                        </div>
                      <div className="tabs-filters row">
                        <div className="small-4">
                          <TTPTab tabs={tabfilter} activeTab={currentTab} selectTab={this.switchTab} />
                        </div>
                        <div className="small-8"><SearchBox
                          onChange={this.handleSearchInputChange}/></div>
                      </div>
                        <AttachmentFilter
                          selected={(currentTab === "TYPE")?type:context}
                          onClick={this.handleSelectAttachment}
                          data={(currentTab === "TYPE")?ATTACHMENT_TYPES:ATTACHMENT_CONTEXTS}
                          className={(currentTab === "TYPE")?"type":"context"}
                        />
                      {this.renderEBoxAttachments()}
                    </div>}
                </div>
                <div className={`${previewIsOpened ? "sidebar sidebar--opened preview-sidebar" : "sidebar"}`}>
                    <div className="sidebar__actions">
                        <div className="row columns small-12">
                            <span onClick={this.closePreview} className="sidebar__close icomoon icon-tt-close"></span>
                        </div>
                    </div>
                    <div className="sidebar__form">
                        <iframe ref={"galleryIframe"} name="gallery-iframe" />
                    </div>
                </div>
            </div>
        );
    }
}
export const tabfilter = [
  {
    id: 'TYPE',
    label: _('Type'),
  }
  , {
    id: 'CONTEXT',
    label: _('Context'),
  }
]
