import React, { Component } from "react";
import { connect } from 'react-redux';

import { getRate } from 'utils';
import PieChart from './PieChart';
import moment from 'moment';
import _ from 'i18n';
import { setRecipientsStatsFilterStatus } from 'actions';
import { RECIPIENTS_STATUS } from 'Common';
import {SVG_CALENDAR_ICON} from "../../services/svgIcones";

@connect((store) => {
    return {
        scheduledDeliveryAt: store.currentCampaign.email.scheduledDeliveryAt,
    }
})
export default class Charts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            days: "",
            hours: "",
            minutes: "",
            seconds: ""
        };
    }

    handleClickStatus = (status) => {
        const { dispatch } = this.props;
        dispatch(setRecipientsStatsFilterStatus(status));
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.scheduledDeliveryAt !== this.props.scheduledDeliveryAt) {
          this.startTimer();
        }
    }

    startTimer() {
        const { scheduledDeliveryAt } = this.props;
        let countDownDate = new Date(scheduledDeliveryAt).getTime();
        let self = this;
        let x = setInterval(function () {
            let now = new Date().getTime();

            let distance = countDownDate - now;
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);


          self.setState({ days, hours, minutes, seconds });
            if (distance < 0) {
                clearInterval(x);
            }
        }, 1000);
    }

    render() {
        const { days, hours, minutes, seconds } = this.state;
        const { scheduledDeliveryAt, deliveredCount, openCount, clickCount, unsubscribeCount, complaintCount, refusedCount, total } = this.props;
        const showCountDown = moment(scheduledDeliveryAt) > moment();
        return (
            <div className="w-58 stats-page__block" style={(showCountDown ? { position: "relative" } : { position: "unset" })} >
                {showCountDown && <div className="scheduled-info">
                  <button className="btn secondary text-uppercase"
                          onClick={() => this.props.changeScheduledAtDate()}>
                    {SVG_CALENDAR_ICON}
                    {_('change sending date')}
                  </button>
                    <span>{_('Your campaign will be sent in')} :</span>
                    <div className="scheduled-date">
                        <div className="days">
                            <span>{days}</span>
                            <div>{_("days")}</div>
                        </div>
                        <div>
                            <span>{hours}</span>
                            <div>{_("hours")}</div>
                        </div>
                        <div>
                            <span>{minutes}</span>
                            <div>{_("minutes")}</div>
                        </div>
                        <div>
                            <span>{seconds}</span>
                            <div>{_("seconds")}</div>
                        </div>
                    </div>
                </div>}

                {!showCountDown && <div className="row">
                    <PieChart
                        label={_('deliveredTo')}
                        rate={getRate(deliveredCount, total)}
                        pieColor={RECIPIENTS_STATUS[0].color}
                        target={deliveredCount}
                        onStatusClick={() => this.handleClickStatus('DELIVERED_TO')} />
                    <PieChart
                        label={_('emailsOpen')}
                        rate={getRate(openCount, total)}
                        pieColor={RECIPIENTS_STATUS[1].color}
                        target={openCount}
                        onStatusClick={() => this.handleClickStatus('EMAILS_OPEN')} />
                    <PieChart
                        label={_('linkClicks')}
                        rate={getRate(clickCount, total)}
                        pieColor={RECIPIENTS_STATUS[2].color}
                        target={clickCount}
                        onStatusClick={() => this.handleClickStatus('LINK_CLICKS')} />
                </div>}
                {!showCountDown && <div className="row">
                    <PieChart
                        label={_('undelivered')}
                        rate={getRate(refusedCount, total)}
                        target={refusedCount}
                        pieColor={RECIPIENTS_STATUS[3].color}
                        onStatusClick={() => this.handleClickStatus('REFUSED')} />
                    <PieChart
                        label={_('complaint')}
                        rate={getRate(complaintCount, total)}
                        pieColor={RECIPIENTS_STATUS[4].color}
                        target={complaintCount}
                        onStatusClick={() => this.handleClickStatus('SPAM')} />
                    <PieChart
                        label={_('unsubscribed')}
                        rate={getRate(unsubscribeCount, total)}
                        pieColor={RECIPIENTS_STATUS[5].color}
                        target={unsubscribeCount}
                        onStatusClick={() => this.handleClickStatus('UNSUBSCRIBE')} />
                </div>}
            </div>
        )
    }
}
