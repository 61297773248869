import React, { Component } from "react";

export default class MultiSwitch extends Component {

  static get defaultProps () {
    return {
      onChange: function(value) {}
    }
  }

  constructor ( props ) {
    super( props );

    this.state = {
      selectedOption: props.selectedValue || props.vals[0]
    }
  }
  componentWillReceiveProps (nextProps) {
    if(this.state.selectedOption != nextProps.selectedValue){
      this.setState( { selectedOption: nextProps.selectedValue } )
    }
  }

  render () {

    let { labels, vals, name, customClass } = this.props;

    if(!labels || labels.length == 0) {
      return null;
    }

    let returnDiv = [];
    for(let i = 0; i < labels.length; i++) {
      returnDiv.push(
        <li key={`switch-${vals[i]}`} className="switch">
          <span className={customClass}>{labels[i]}</span>
            <div className="switch-container">
              <label>
                <input value={vals[i]}  ref="switch" name={name} checked={ this.state.selectedOption === vals[i] }
                  onChange={ this.handleChange.bind(this) } className="ttp-switch" type="radio" />
                <div>
                  <span><g className="icon icon-toolbar grid-view"></g></span>
                  <span><g className="icon icon-toolbar ticket-view"></g></span>
                  <div></div>
                </div>
              </label>
            </div>
        </li>
      );
    }

    return(
      <ul>{returnDiv}</ul>
    );
  }

  handleChange (e) {
    this.setState( { selectedOption: e.target.value } );
    this.props.onChange(e.target.value);
  }
}
