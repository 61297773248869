import React, { Component } from 'react';
import { connect } from "react-redux";
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { setEmailsSettingsPaginationPage } from "../../../actions/actions/generic-emailings/settings";
import SettingsItem from "./SettingsItem";
import { fetchOrganisationEmailsSettings } from 'thunks';
import SettingFilter from "./SettingFilter";
import TTPPaginator from "../../common/list/TTPPaginator";
import NotFound from "notFound/NotFound";


class SettingsList extends Component {

  componentDidUpdate(prevProps, prevState) {
    const { filter, paginationPage, pageSize } = prevProps;
    if (filter !== this.props.filter || paginationPage !== this.props.paginationPage || pageSize !== this.props.pageSize) {
      this.props.fetchOrganisationEmailsSettings();
    }
  }

  handleEmailsSettingsPaginationPage = ({ selected }) => {
    this.props.setEmailsSettingsPaginationPage(selected + 1);
  };

  componentDidMount() {
    this.props.fetchOrganisationEmailsSettings();
  }

  renderPaginate() {
    let { error, paginationPage, pageSize, nbResult } = this.props;
    if (error != null && error.code === 404) {
      return "";
    }
    return <TTPPaginator onPageChange={this.handleEmailsSettingsPaginationPage}
      pageSize={pageSize}
      nbResult={nbResult}
      paginationPage={paginationPage} />;


  }

  render() {
    const { items, error, fetching, visible } = this.props;


    const ListFetching = [1, 2, 3, 4].map(i => <div key={`list-${i}`} className="ge-settings-item" />);

    let target = "";

    return (
      <div className={!visible && "hide"}>
        <SettingFilter />

        <div className="ge-settings-list p-l">
          {(error != null && error.code === 404) ? <NotFound /> :
            fetching ?
              ListFetching :
              (items && items.length > 0) ?
                items.map(item => {
                  if (item.targetApp === "PAYMENT") {
                    target = item.targetId ? item.targetId : "";
                  } else {
                    target = (item.target) ? (item.target.label) : "";
                  }
                  return (
                    <SettingsItem key={`ge-${item.id}`}
                                updatedAt={item.updatedAt}
                                target={target}
                                targetApp={item.targetApp}
                                languages={item.languages}
                                status={item.status}
                                clicked={() => this.props.history.push(`/email/${item.id}`)}
                  />
                  )

          }) : <NotFound />
          }
        </div>
        {fetching || !items || items.length === 0 ? "" : this.renderPaginate()}

      </div>
    );
  }
}

const mapStateToProps = state => ({
  paginationPage: state.genericEmail.settings.paginationPage,
  pageSize: state.genericEmail.settings.pageSize,
  items: state.genericEmail.settings.items,
  fetching: state.genericEmail.settings.fetching,
  filter: state.genericEmail.settings.filter,
  nbResult: state.genericEmail.settings.nbResult,
  error: state.genericEmail.settings.error,

});

const mapDispatchToProps = dispatch => ({
  fetchOrganisationEmailsSettings: () => dispatch(fetchOrganisationEmailsSettings()),
  setEmailsSettingsPaginationPage: (selected) => dispatch(setEmailsSettingsPaginationPage(selected))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)
  (SettingsList);
