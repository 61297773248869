// SMS bills reducer
const initialStateForSMSBillsReducer = {
    fetching: false,
    fetched: false,
    items: [],
    detail: { detailedBill: [], fetching: false, fetched: false },
    error: null,
    updated: false,
    updating: false,
    nbResult: 0,
    paginationPage: 1,
    pageSize: 10,
    status: "",
    smsBillType: "",
}

export const smsBillsReducer = (state = initialStateForSMSBillsReducer, action) => {
    switch (action.type) {
        case 'FETCH_SMS_BILLS_PENDING': {
            return { ...state, fetched: false, fetching: true };
            break;
        }
        case 'FETCH_SMS_BILLS_FULFILLED': {
            const { data, nbResult } = action.payload.data
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                items: data,
                nbResult: nbResult
            };
            break;
        }
        case 'FETCH_SMS_BILLS_REJECTED': {
            let error = action.payload;
            switch (error && error.response && error.response.status) {
                case 404:
                    error = {
                        title: error.response.data.title,
                        code: 404
                    }
                    break;
                default:
            }
            return {
                ...state,
                fetching: false,
                items: [],
                error
            };
            break;
        }
        case 'SAVE_SMS_BILL_PENDING': {
            return { ...state, fetched: false, fetching: true };
            break;
        }
        case 'SAVE_SMS_BILL_FULFILLED': {
          const smsBill = action.payload;
          let exist = false;
          const updatedItems = state.items.map(item => {
            if (item.id === smsBill.id) {
              exist = true;
              return {...item, ...smsBill};
            }
            return item;
          });

          if (!exist) {
            updatedItems.unshift(smsBill);
          }
          return {
            ...state,
            fetching: false,
            fetched: true,
            error: null,
            items: updatedItems,
          };
          break;
        }
        case 'SAVE_SMS_BILL_REJECTED': {
            let error = action.payload;
            switch (error && error.response && error.response.status) {
                case 404:
                    error = {
                        title: error.response.data.title,
                        code: 404
                    }
                    break;
                default:
            }
            return {
                ...state,
                fetching: false,
                items: [],
                error
            };
            break;
        }
        case 'SET_SMS_BILLS_PAGINATION_PAGE': {
            return { ...state, paginationPage: action.pageNumber };
            break;
        }
        case 'SET_SMS_BILLS_PAGE_SIZE': {
            return { ...state, pageSize: action.pageSize };
            break;
        }
        case 'GET_SMS_BILL_PENDING': {
            return {
                ...state,
                detail: { ...state['detail'], fetching: true, fetched: false }
            };
            break;
        }
        case 'GET_SMS_BILL_FULFILLED': {
            const { result } = action.payload.data.data
            return {
                ...state,
                detail: { detailedBill: result, fetching: false, fetched: true }
            };
            break;
        }
        case 'VALIDATE_SMS_BILL_PENDING': {
            return { ...state, updated: false, updating: true };
            break;
        }
        case 'VALIDATE_SMS_BILL_FULFILLED': {
            const { payload } = action;
            let updatedItems = state.items.map(item => {
                if (item.id == payload.id) {
                    return { ...item, ...payload };
                }
                return item;
            })
            return {
                ...state,
                fetching: false,
                fetched: true,
                error: null,
                updated: true,
                updating: false,
                items: updatedItems
            };
            break;
        }
        case 'SET_SMS_BILLS_LIST_FILTER_STATUS': {
            return { ...state, status: action.status, paginationPage: 1 };
            break;
        }

        case 'SET_SMS_BILLS_LIST_FILTER_TYPE': {
            return { ...state, smsBillType: action.smsBillType, paginationPage: 1 };
            break;
        }
        default:
            return state;
    }
};

const initialStateForSmsBillsSort = {
    sortConfig: {
        columnKey: "updatedAt",
        sortDirection: "desc"
    }
}

export const smsBillsSortReducer = (state = initialStateForSmsBillsSort, action) => {
    switch (action.type) {
        case 'SET_SMS_BILLS_LIST_SORT_CONFIG': {
            return { ...state, sortConfig: action.config };
            break;
        }
        default:
            return state;
    }
};

