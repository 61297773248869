import cheerio from 'cheerio';
import _ from 'i18n';

import { fetchBlocks, fetchArticles } from '../../actions/thunks';
import { initArticles } from "../../actions/actions";
import { formatArticle } from "utils";
import { TEMPLATE_TYPES } from 'constants';
import { ALL_LAYOUT_STRING_CODES, escapeJsonSpecialChars } from './helpers';

const SEP = "_SEP_";

export const replaceHeaderFooter = (sample, images, callBack) => {
  let imgs = images.reduce((acc, img) => {
    acc[img.mediaType] = img.url;
    return acc;
  }, []);
  if (imgs["HEADER"]) {
    sample.body.rows[0].columns[0].contents[0] = injectImageUrl(imgs["HEADER"], 0);
  }
  if (imgs["FOOTER"]) {
    sample.body.rows[2].columns[0].contents[0] = injectImageUrl(imgs["FOOTER"], 2);
  }
  callBack(sample);
}

export const injectImageUrl = (url, index) => {
  return {
    "type": "image",
    "values": {
      "containerPadding": "0px",
      "_meta": {
        "htmlID": "u_content_image_" + index,
        "htmlClassNames": "u_content_image"
      },
      "selectable": true,
      "draggable": true,
      "deletable": true,
      "src": {
        "url": url,
        "width": 500,
        "height": 100
      },
      "fullWidth": true,
      "textAlign": "center",
      "maxWidth": "100%",
      "altText": "Image",
      "action": {
        "url": "",
        "target": ""
      }
    }
  }
}

export const generateBlocs = (blocs, doNotSort = false, layoutBase = false) => {
  if (!doNotSort) {
    blocs = blocs.sort((a, b) => { return (b.name + b.column) > (a.name + a.column) ? -1 : 1 });
  }
  return blocs.reduce((generatedBlocs, bloc) => {
    return generatedBlocs.concat(generateBloc(bloc, layoutBase));
  }, []);
}

export const generateBloc = (bloc, layoutBase = false) => {
  let { feeds, design, name, column, type } = bloc;

  if (TEMPLATE_TYPES['UNREFERENCED_BLOCK'] == type || (type == TEMPLATE_TYPES["STATIC_BLOCK"] && feeds.length == 0)) {
    return extractDesignFromBlocs(bloc);
  } else if (type != "STATIC_BLOCK" && feeds.length == 0) {
    return [];
  }

  let feedsDesign = createFeedsDesign(feeds, design);

  let generatedBlocs = [];
  try {
    let i = 0;
    let { cells } = feedsDesign[0].body.rows[0];
    const step = Math.min(column, 4 / cells.length);
    while (i < feedsDesign.length) {
      let feedSegment = feedsDesign.slice(i, i += step);
      generatedBlocs.push(createBlockDesign(feedSegment, name, column, layoutBase));
    }
  } catch (error) {
    console.error("JSON format was invalid");
    return [];
  }

  return generatedBlocs;
}

export const createFeedsDesign = (feeds, layoutDesign) => {

  return feeds.map(content => {
    let design = layoutDesign + "";

    try {
      const d = ALL_LAYOUT_STRING_CODES.reduce((acc, e) => {
        let value = escapeJsonSpecialChars(content[e.property] || "");
        return acc.replace(new RegExp(e.key, "g"), value);
      }, design);

      return JSON.parse(d);
    } catch (ex) {
      console.error({ content, design });
    }
  });
}

export const createBlockDesign = (content, name = "blocs", column = 1, layoutBase = false) => {
  let customBloc = JSON.parse(JSON.stringify(CUSTOM_BLOC_TEMPLATE));
  return content.reduce((customBloc, article) => {
    let { cells, columns, values } = article.body.rows[0];
    customBloc.category = _(name) + (column > 1 ? " x " + column : "");
    customBloc.data.cells = customBloc.data.cells.concat(cells);
    customBloc.data.columns = customBloc.data.columns.concat(columns);
    customBloc.data.values = { ...values, backgroundColor: (layoutBase) ? values.backgroundColor : "" };
    return customBloc;
  }, customBloc);
}

export const createBlockHtml = (content, column = 1) => {
  const _$ = cheerio.load(content);
  _$(".email-col").removeClass().addClass("email-col").css("min-width", "unset").css("max-width", "unset");
  _$(".email-row-container > .email-row").css("max-width", "unset");
  let row = _$("body > table > tbody > tr > td > div:nth-child(1) > div > div").html();
  for (let i = 1; i < column; i++) {
    _$("body > table > tbody > tr > td > div:nth-child(1) > div > div").append(row);
  }

  return _$("body > table > tbody > tr > td").html();
}

export const CUSTOM_BLOC_TEMPLATE = {
  "category": "General",
  "tags": [],
  "data": {
    "cells": [],
    "columns": [],
    "values": {
      "columns": false,
      "backgroundColor": "",
      "columnsBackgroundColor": "",
      "backgroundImage": {
        "url": "",
        "fullWidth": true,
        "repeat": false,
        "center": true,
        "cover": false
      },
      "padding": "0px",
      "hideMobile": false,
      "noStackMobile": false,
      "selectable": true,
      "draggable": true,
      "deletable": true
    }
  },
  "displayMode": "email"
};

export const extractDesignFromBlocs = (blocs) => {

  const rows = (Array.isArray(blocs)) ? blocs : [blocs];

  return rows.reduce((acc, b) => {
    try {
      const json = (TEMPLATE_TYPES['UNREFERENCED_BLOCK'] == b.type) ? { data: b.design } : themeToStaticBlock(JSON.parse(b.design), b.name);
      acc.push(json);
    } catch (ex) {
    }

    return acc;
  }, []);
}

const themeToStaticBlock = (theme, name = "") => {
  let customBloc = JSON.parse(JSON.stringify(CUSTOM_BLOC_TEMPLATE));

  let { cells, columns, values } = theme.body.rows[0];
  customBloc.category = name;
  customBloc.tags = name.split(" ");
  customBloc.data.cells = cells;
  customBloc.data.columns = columns;
  customBloc.data.values = { ...values };

  return customBloc;
}

// GENERATE NEWSLETTER
export const generateNewsletter = (newsletterTemplate, dispatch, callback) => {
  let { design, feeds } = newsletterTemplate;
  design = JSON.parse(design);
  refreshNewsletter(design, feeds, dispatch, callback);
}

// REFRESH NEWSLETTER
export const refreshNewsletter = (newsletter, feeds = null, dispatch, callback) => {

  let skeleton = [];
  newsletter.body.rows.forEach(row => {
    let data = extractDatafromRow(row);
    if (data && data.length > 2 && data[1] == "NEWS_LAYOUT") {
      let ids = data.slice(4, data.length - 1);
      skeleton.push({ type: data[1], layoutId: data[2].split("-")[0], column: data[3], feedsIds: ids });
    }
  });

  getLayouts(skeleton.map(s => s.layoutId), dispatch, layouts => {
    if (!feeds) {
      let feedsIds = skeleton.reduce((acc, s) => {
        return acc.concat(s.feedsIds);
      }, [])

      getFeeds(feedsIds, dispatch, feeds => {
        syncNewsLetter(skeleton, layouts, feeds, newsletter, callback);
      })
    } else {
      syncNewsLetter(skeleton, layouts, feeds, newsletter, callback);
    }
  })
}

const getLayouts = (layoutIds, dispatch, layoutCallback) => {
  dispatch(fetchBlocks({ layoutIds, type: ["NEWS_LAYOUT"] }))
    .then(res => {
      const layouts = res.value.data.data.reduce((acc, e) => {
        acc[e.id] = e.design;
        return acc;
      }, []);
      layoutCallback(layouts);
    })
}

const getFeeds = (feedsIds, dispatch, feedsCallback) => {
  // Support only articles
  dispatch(initArticles());
  dispatch(fetchArticles({ articlesIds: feedsIds }))
    .then(res => {
      const articles = res.value.data.data.reduce((acc, a) => {
        acc[a.id] = formatArticle(a);
        return acc;
      }, []);
      feedsCallback(articles);
    })
}

const syncNewsLetter = (skeleton, layouts, feeds, newsletter, callBack) => {
  let blocs = [];
  let j = 0;
  skeleton.forEach(e => {
    let blocFeeds = [];
    if (feeds && e.feedsIds && e.feedsIds[0] != "0") {
      blocFeeds = e.feedsIds.map(e => feeds[e]);
    } else if (feeds && e.feedsIds) {
      blocFeeds = feeds.slice(j, j += e.feedsIds.length);
    }
    if (blocFeeds && blocFeeds.length > 0 && layouts && layouts.length > 0) {
      let bloc = {
        id: e.layoutId,
        design: layouts[e.layoutId],
        column: e.column,
        type: "NEWS_LAYOUT",
        feeds: blocFeeds,
      };
      blocs.push(bloc);
    }
  });

  let generatedBlocs = generateBlocs(blocs, true);

  let i = 0;
  newsletter.body.rows = newsletter.body.rows.reduce((acc, row) => {
    let data = extractDatafromRow(row);
    let b = generatedBlocs[i];

    if (!data || data.length <= 2) {
      acc.push(row)
    } else if (b && data && data.length > 2 && data[1] == "NEWS_LAYOUT") {
      acc.push(b.data);
      i++;
    }
    return acc;
  }, []);
  callBack(newsletter);
}

const extractDatafromRow = (row) => {
  try {
    return row.columns[0].contents[0].values.html.split(SEP);
  } catch (ex) {
    return null;
  }
}

export const getThemeBackgroundColor = (theme) => {
  const d = JSON.parse(theme.design);
  return d.body.values.backgroundColor;
}

export const prepareHtml = (html) => {
  var $ = cheerio.load(html.replace(/_SEP_.*_SEP_/g, ''));
  $("p").css('margin', "0")
  return $.html();
}

export const deductTemplateType = (html) => {
  let type = 'DRAG_AND_DROP';

  if (new RegExp(['{{SUBJECT}}', '{{BODY}}'].join("|")).test(html)) {
    type = 'WRAPPER';

  } else if (new RegExp(ALL_LAYOUT_STRING_CODES.map(({ key }) => key).join("|")).test(html)) {
    type = 'TEMPLATE'
  }

  return type;
}
