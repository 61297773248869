import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'i18n';
import {
  initMailingList,
  setMailingListData
} from "../../actions/actions/mailingList";
import Switch from 'common/Switch';
import {saveMailingList} from "../../actions/thunks/mailingList";
import {
  onError,
  onSuccess, ucFirst
} from "../../services/utils";
import {SimpleTabs} from "../ui/tabs/SimpleTabs";
import {LANGUAGES} from "../../config/Common";
import {NotificationManager} from "react-notifications";

@connect()
class MailingListForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mandatory: false
    };
  }

  handleMailingListInputChange = (e, inputName, lng) => {
    const value = (e.target) ? e.target.value : e;
    const data = {};
    data[`${inputName}${lng}`] = value;
    this.props.dispatch(setMailingListData(data));
  };

  handleMailingListMandatoryChange = (mandatoryCheck) => {
    const mandatory = mandatoryCheck ? 1 : 0;
    this.props.dispatch(setMailingListData({ mandatory }));
  };

  handleSaveMailingList = () => {
    const {dispatch, toggleListFormSideBar, data: {nameFr, nameNl, nameEn, id }} = this.props;

    if (!(nameFr || nameNl || nameEn)) {
      NotificationManager.warning( _('You must enter at least one name !'), _('warning'));
      return;
    }
    let notification = id ? {body: 'mailingListEdited', title: 'editSuccess'} :
      {body: 'mailingListAdded', title: 'addCampaignSuccess'};
    dispatch(saveMailingList()).then(res => {
      onSuccess(res, notification);
      toggleListFormSideBar();
      dispatch(initMailingList());
    }, err => onError(err));
  };

  render() {
    let {opened, toggleListFormSideBar, data, setActiveLanguage, activeLanguage, updating} = this.props;
    const ucFirstActiveLanguage = ucFirst(activeLanguage);
    let saveLabel = 'save';
    if (data.id) {
      saveLabel = 'edit';
    }
    if (updating) {
      saveLabel = 'Processing...';
    }

    return (
      <div id="mailing-lists-form">
        <div className={(opened ? "sidebar sidebar--opened" : "sidebar")}>
          {opened && <div className="sidebar__actions">
            <div className="row columns small-12">
              <span className="sidebar__close icomoon icon-tt-close"
                    onClick={toggleListFormSideBar}/>
            </div>
          </div>}
          <div className="sidebar__form">
            <h4 className="sidebar__header">{!data.id ? _('Add new mailing list') : _('Edit mailing list')}</h4>
            <div className="row">
              <span className="simple-tabs__info">{_('Enter the name and description of your mailing list')}
              <span className="fwl"> (3 {_('languages available')})</span>
              </span>
              <SimpleTabs
                active={activeLanguage}
                tabs={LANGUAGES.map(l => ({lng: l}))}
                setActiveTab={setActiveLanguage}
                language>
                <div className="columns small-12 columns-padding">
                  <span className="param__title">{_('Name')}</span>
                  <div className="small-12 margin-left-auto">
                    <input className={`${data[`name${ucFirstActiveLanguage}`] ? 'filled-box' : 'empty-box'}`}
                      type="text" onChange={(ev) => this.handleMailingListInputChange(ev, "name", ucFirstActiveLanguage)}
                      value={data[`name${ucFirstActiveLanguage}`]}/>
                  </div>
                </div>
                <div className="columns small-12 columns-padding">
                  <span className="param__title">{_('Description')}</span>
                  <div className="small-12 margin-left-auto">
                   <textarea value={data[`description${ucFirstActiveLanguage}`]}
                             rows={4}
                             className={`${data[`description${ucFirstActiveLanguage}`]} ? "filled-box" : "empty-box"}`}
                             onChange={(ev) => this.handleMailingListInputChange(ev, "description", ucFirstActiveLanguage)}/>
                  </div>
                </div>
              </SimpleTabs>
              <div className="columns small-12 columns-padding switch">
                <span className="param__title">{_('Mandatory mailing list')}</span>
                <Switch name="mandatory" isChecked={data['mandatory']}
                        onChange={(val) => this.handleMailingListMandatoryChange(val)} />
              </div>
              <div className="columns small-12 columns-padding buttons-container">
                <button onClick={toggleListFormSideBar} className="btn secondary">{_('cancel')}</button>
                <button className="btn primary" onClick={!updating && this.handleSaveMailingList}>{_(saveLabel)}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  data: store.mailingLists.data,
  updating: store.mailingLists.updating
});

export default connect(mapStateToProps, null)(MailingListForm);
