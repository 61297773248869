import React from 'react';
import CreateCampaignEmail from "./CreateCampaignEmail";
import Themes from "./Themes";
import GenericEmailing from "./GenericEmailing";
import CampaignsEmail from "./CampaignsEmail";
import MailingLists from "./MailingLists";
import Dashboard from "./Dashboard";

const emailingMenu = (props) => {
  const { items, allowCreation , allowDashboardPage } = props;
  return (
    <ul className="menu">
      {allowDashboardPage ? <Dashboard /> : null}
      {items.indexOf('CAMPAIGN_EMAIL') !== -1 ? <CampaignsEmail /> : null}
      {allowCreation ? <CreateCampaignEmail /> : null}
      {items.indexOf('THEMES_LIST') !== -1 ? <Themes /> : null}
      {(items.indexOf('GENERIC_EMAILING') !== -1) ? <GenericEmailing /> : null}
      {items.indexOf('MAILING_LISTS') !== -1 ? <MailingLists /> : null}
    </ul>
  )
}

export default emailingMenu;
