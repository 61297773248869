import React from 'react';
import { NavLink } from 'react-router-dom';

import _ from "i18n";
import { SVG_ICONS } from '../../config/Common';


export const SubMenuLink = ({ pathname, title, icon, cssClass }) => {
  return <li>
    <NavLink to={{ pathname }} activeClassName="active" className={`menu__link ${cssClass || ""}`}>
      <img src={"/img/icons/" + SVG_ICONS[icon]} />
      <span className="title">{_(title)}</span>
    </NavLink>
  </li>
}
