import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setRecipientsStatsFilterStatus } from 'actions';
import { BOUNCE_TYPES } from 'Common';
import { getRate } from 'utils';
import _ from 'i18n';

@connect((store) => {
  return {
    isFromCampaignStats: store.campaigns.stats.campaign.data.isFromCampaignStats,
    fetching: store.campaigns.stats.campaign.fetching,
  }
})export default class BounceDetails extends Component {

    searchBounceTypeDetails(status) {
        this.props.dispatch(setRecipientsStatsFilterStatus(status));
        let scrolIntoElem = document.getElementById("recipients-details");
        if (scrolIntoElem) {
            scrolIntoElem.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
    }

    render() {
        let { boucesDetails, isFromCampaignStats, fetching } = this.props;
        let totalBounce = 0;
        let bounces = [];

        boucesDetails && boucesDetails.forEach(({ total, bounceType }) => {
            totalBounce += parseInt(total);
            bounces[bounceType] = total;
        });

        return <div className="bounce-detail align-center stats-page__block">
            <h4 className="text-capitalize">{_('bounce details')}</h4>
            <div className="row small-11">
                <p>{_('bounce_info')}</p>
                {
                    BOUNCE_TYPES.map((item, i) => {
                        let itemCount = bounces[item] ? bounces[item] : 0;
                        let percent = getRate(itemCount, totalBounce);
                        return <div key={i} className="small-3 bounce-detail-item" style={isFromCampaignStats ? { cursor: 'default' } : {}}>
                            <h5>{_(item.toLowerCase() + "_bounce")}</h5>
                            <div className="bounce-detail-item-count">{itemCount}</div>
                            <div className="progress-bar">
                                <div className="progress-bar-container">
                                    <div className={`progress-bar-content bar-color-${i + 1}`} style={{ width: percent + "%" }}></div>
                                </div>
                                <div className="progress-bar-percent">{percent}%</div>
                            </div>
                            {(!isFromCampaignStats && !fetching) && <div className="progress-bar-overlay">
                                <div className="bubble" onClick={() => this.searchBounceTypeDetails(item)}>
                                    <i className="icon icon-magnifier"/>
                                </div>
                            </div>}
                        </div>
                    })
                }
            </div>
        </div>;
    }
}
