import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import Bubble from "common/Bubble";
import { SVG_SEND_ICON } from "../../services/svgIcones";
import { showEboxWidget } from "../../actions/actions/ebox/widget";
import { FULL_SCREEN_WIDGET } from "../../services/ebox/widget";
import { setCurrentNavPage } from "actions";
import { initEbox, setEboxRecipientType } from "../../actions/actions";
import { fetchUser } from "../../actions/thunks/user";
import EmailComposer from "../eBox/widget/EmailComposer";

const mapStateToProps = (state) => ({
  auth: state.auth,
  owner: state.ebox.owner.data,
  widgetStatus: state.ebox.widget.status,
  inAdminSearchMode: state.ebox.list.inAdminSearchMode,
  lng: state.params.lng,
});

const mapDispatchToProps = (dispatch) => ({
  initEbox: () => dispatch(initEbox()),
  setEboxRecipientType: (type) => dispatch(setEboxRecipientType(type)),
  showEboxWidget: () => dispatch(showEboxWidget()),
  setCurrentNavPage: () => dispatch(setCurrentNavPage("E_BOX")),
  fetchUser: (id) => dispatch(fetchUser(id)),
});

@connect(mapStateToProps, mapDispatchToProps)
class MailExpressPage extends Component {
  state = {
    sender: null,
  };

  componentDidMount() {
    const { setCurrentNavPage, setEboxRecipientType, fetchUser } = this.props;
    setCurrentNavPage();
    this.props.initEbox();

    const query = new URLSearchParams(location.search);
    const recipientType = query.get("type");
    const senderId = query.get("senderId");
    if (recipientType) {
      setEboxRecipientType(recipientType);
    }
    if (senderId) {
      fetchUser(senderId).then((result) => {
        this.setState({ sender: result.value?.data?.data });
      });
    }
  }

  render() {
    const { widgetStatus, showEboxWidget } = this.props;

    return (
      <div className="ttp-tab-container">
        <div>
          <div className={`campaign-bubbles`}>
            <Bubble
              label={_("new")}
              svgIcon={SVG_SEND_ICON}
              cssClass="mail-express"
              onBubbleClick={showEboxWidget}
            />
          </div>
          <div
            className={`overlay ${widgetStatus === FULL_SCREEN_WIDGET && "visible"}`}
          />
          {widgetStatus && <EmailComposer />}
        </div>
      </div>
    );
  }
}

export default MailExpressPage;
