import React from 'react';
import { ReactComponent as IconPerson } from 'assets/icons/person.svg';
import { ReactComponent as IconPeople } from 'assets/icons/people.svg';


export const FULL_SCREEN_WIDGET = "FULL_SCREEN";
export const OPENED_WIDGET = "OPENED";

const WIDGET_SIZE = {
  WIDGET_MAXIMIZED: { height: 566, width: 820 },
  WIDGET_MINIMIZED: { height: 36, width: 316 },
  WIDGET_HIDDEN: { height: 0, width: 0 },
  WIDGET_FULL_SCREEN: { height: 60, width: 29 },
}

export const callBackWidget = (status) => {
  if (!window.parent) {
    return;
  }
  let action;
  switch (status) {
    case "OPENED":
      action = "WIDGET_MAXIMIZED";
      break;
    case "MINIMIZED":
      action = "WIDGET_MINIMIZED";
      break;
    case FULL_SCREEN_WIDGET:
      action = 'WIDGET_FULL_SCREEN';
      break;
    default:
      action = "WIDGET_HIDDEN"
      break;
  }

  window.parent.postMessage({
    action,
    ...WIDGET_SIZE[action]
  }, '*');
}

export const postWidgetLoadedMessage = () => {
  if (!window.parent) {
    return;
  }
  window.parent.postMessage({
    action: 'WIDGET_LOADED'
  }, '*');
}

export const EBOX_TARGET_TYPE = {
  'EMAIL': {
    label: 'individual', icon: <IconPerson />
  },
  'FOLDER': {
    label: 'folder', icon: <IconPeople />
  },
  'MY_FOLDERS': {
    label: 'my folders', icon: <IconPeople />
  }
}

export const EBOX_TARGET_TYPE_SLIDER = {
  'EMAIL': {
    label: 'individual', icon: <IconPerson />
  },
  'FOLDER': {
    label: 'folder', icon: <IconPeople />
  }
}
