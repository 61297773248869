import React, { Component } from 'react';
import { convertDateFromUTC, getUserNameForAvatar } from 'utils';
import _ from 'i18n';

export class UserAvatar extends Component {
    render() {
        const { updatedAt, updatedBy } = this.props;
        if (updatedBy && updatedBy['avatarUrl']) {
            return <div className="item-avatar">
                <img src={updatedBy['avatarUrl']} />
                <div>
                    <span className="user-name">{`${updatedBy['lastName']} ${updatedBy['firstName']}`}</span>
                    <span className="updated-at">{updatedAt && convertDateFromUTC(updatedAt)}</span>
                </div>
            </div>
        } else if (updatedBy && !updatedBy['avatarUrl']) {
            return <div className="item-avatar">
                <div className="img-wrap empty-avatar"> <span
                    style={{ fontSize: 'inherit' }}>{getUserNameForAvatar(updatedBy['lastName'], updatedBy['firstName'])}</span></div>
                <div>
                    <span className="user-name">{`${updatedBy['lastName']} ${updatedBy['firstName']}`}</span>
                    <span className="updated-at">{updatedAt && convertDateFromUTC(updatedAt)}</span>
                </div>
            </div>
        } else if (!updatedBy) {
            return <div className="item-avatar">
                <div className="img-wrap empty-avatar"> <span
                    style={{ fontSize: 'inherit' }}>?</span></div>
                <div>
                    <span className="updated-at">{updatedAt && convertDateFromUTC(updatedAt)}</span>
                </div>
            </div>
        }

    }
}

export default UserAvatar
