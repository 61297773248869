import React from "react";
import { connect } from "react-redux";
import { TTP_API_URL } from "../../../../config";
import { SVG_COPY_ICON } from "../../../../services/svgIcones";
import _ from "i18n";
import { throwCatchedError } from "../../../../services/utils";

@connect((store) => ({
  currentCampaign: store.currentCampaign.email,
}))
class CampaignGoTo extends React.Component {
  copyToClipboard = () => {
    const campaignUrl = `${TTP_API_URL}/mailing/web-version?campaignId=${this.props.originalCampaignId}`;

    // Check if the Clipboard API is available
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(campaignUrl).catch((error) => {
        throwCatchedError(error);
      });
    } else {
      // Fallback for older browsers
      const tempInput = document.createElement("textarea");
      tempInput.value = campaignUrl;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    }
  };

  render() {
    const campaignUrl = `${TTP_API_URL}/mailing/web-version?campaignId=${this.props.originalCampaignId}`;

    return (
      <div className="campaign-go-to">
        <p className="campaign-go-to text">{_("Broadcast_link")} : </p>

        <input
          value={campaignUrl}
          readOnly={true}
          className="campaign-go-to url"
        />
        <div className="campaign-go-to go-to-separator"></div>

        <a
          className="campaign-go-to copy"
          onClick={this.copyToClipboard.bind(this)}
          target="_blank"
        >
          {SVG_COPY_ICON}
          <p>{_("To_copy")}</p>
        </a>
      </div>
    );
  }
}

export default CampaignGoTo;
