import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CAMPAIGN_LANGUAGE } from 'Common';
import { getUserNameForAvatar } from 'utils';
import _ from 'i18n';
import { S3_FOLDER_URL } from "Config";

@connect((state, ownProps) => ({
  user: state.ebox.owner.data,
}))
export default class OwnerDetails extends Component {

  renderOwnerPhone() {
    const { phone, intlPhone } = this.props.user;

    let ownerPhone = "--";
    if (intlPhone) {
      ownerPhone = intlPhone;
    } else if (!intlPhone && phone) {
      ownerPhone = phone.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return ownerPhone;
  }

  renderOwnerDetails = () => {
    const { lastName, firstName, language, avatar, avatarUrl, email, mainEmail } = this.props.user;

    return (
      <div className="ebox-owner">
        {
          avatarUrl || avatar ? <img className="ebox-owner__avatar" src={avatarUrl || (S3_FOLDER_URL + "/" + avatar)} /> : <div className="ebox-owner__avatar empty-avatar">
            <span>{getUserNameForAvatar(lastName, firstName)}</span>
          </div>
        }
        <div className="ebox-owner__data">
          <h4 className="ebox-owner__name">{lastName} {firstName}</h4>
          <ul className="ebox-owner__info">
            <li>
              <span>{_('mail')}</span>
              <div>
                {email && email.length > 0 ? email.map(({ email }, i, arr) => <span>{email} {i < arr.length - 1 ? ', ' : ''} </span>) : <span>{mainEmail}</span>}
              </div>
            </li>
            <li>
              <span>{_('tel')}</span>
              <div>
                {this.renderOwnerPhone()}
              </div>
            </li>
            {language && <li>
              <span>{_('langue')}</span>
              <div>
                <span>
                  <i className={`flag-icon flag-icon-${language == "en" ? "gb" : language}`} />
                  {_(CAMPAIGN_LANGUAGE[language])}
                </span>
              </div>
            </li>
            }
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;

    return (
      <div className={`ebox-explorer__details ${user && 'fill'}`}>
        {
          user ? this.renderOwnerDetails() : <img src="/img/user/avatar.png" />
        }

      </div>
    );
  }
}
