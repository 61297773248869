export const setFilterYear = (year) => ({
  type: "DASHBOARD_FILTER_YEAR",
  year
})
export const setTypeListFilter = (selected) => ({
  type: "DASHBOARD_FILTER_TYPE",
  selected
});
export const setRecipientTypeFilter = (selected) => ({
  type: "DASHBOARD_FILTER_RECIPIENT_TYPE",
  selected
});
export const fetchOpensChartData = (data) => ({
  type: "FETCH_DASHBOARD_OPEN_DATA_CHART",
  payload: data
});
export const fetchClicksChartData = (data) => ({
  type: "FETCH_DASHBOARD_CLICKS_DATA_CHART",
  payload: data
});
export const fetchCampaignsChartData = (data) => ({
  type: "FETCH_DASHBOARD_CAMPAIGNS_DATA_CHART",
  payload: data
});
export const fetchPercentageDataUndeliveredMail = (data) => ({
  type: "FETCH_DATA_UNDELIVERED_MAIL_ACTION",
  payload: data
});
export const fetchBestType = (data) => ({
  type: "FETCH_BEST_TYPE",
  payload: data
});
export const setDashboardFilterLanguage = (language) => {
  return {
    type: "DASHBOARD_FILTER_LANGUAGE",
    language
  };
};
export const fetchScoreData = (data) => ({
  type: "FETCH_DATA_SCORE_ACTION",
  payload: data
});

export const fetchStatsHistory = (data) => ({
  type: "FETCH_DASHBOARD_STATS_HISTORY_ACTION",
  payload: data
})

export const fetchMembersStats = (data) => ({
  type: "FETCH_MEMBERS_STATS",
  payload: data
});

export const fetchLinks = (data) => ({
  type: "FETCH_DASHBOARD_LINKS",
  payload: data
})

export const setFilterPeriod = (selected) => ({
  type: "DASHBOARD_FILTER_PERIOD",
  selected
})

export const setFilterMonth = (month) => ({
  type: "DASHBOARD_FILTER_MONTH",
  month
})
