import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import _ from "i18n";
import { SVG_ICONS } from "../../config/Common";

export default class SubMenuLinkUA extends Component {
  render() {
    const {
      title,
      icon,
      currentPage,
      isActive = false,
      handleSetCurrentPage,
    } = this.props;

    return (
      <li>
        <NavLink
          to={"/newsletter-ua/BASIC"}
          onClick={() => handleSetCurrentPage(currentPage, null)}
          activeClassName={`${isActive ? "active" : ""}`}
          className={`menu-ua__link`}
        >
          <img src={"/img/icons/" + SVG_ICONS[icon]} />
          <span className="title">{_(title)}</span>
        </NavLink>
      </li>
    );
  }
}
