import React, { Component } from "react";

import _ from "i18n";
import { getHistory } from "../../router/getHistory";

export default class SidePageHeader extends Component {
  goBack = () => {
    const history = getHistory();
    const query = new URLSearchParams(history.location.search);
    if (this.props.isUAVersion) {
      this.props.handleSetCurrentPage("LIST_PAGE");
    } else {
      if (query.get("returnto")) {
        window.location = query.get("returnto");
      } else if (this.props.returnTo) {
        getHistory().push(this.props.returnTo);
      } else {
        getHistory().goBack();
      }
    }
  };

  render() {
    const { subHeader, actionButton } = this.props;

    return (
      <div className={`side-page-header`}>
        <div className="side-page-header__info">
          <button
            className="btn side-page-header__button"
            onClick={this.goBack}
          >
            <img src="/img/icons/arrow-right.svg" />
            <span>{_("return")}</span>
          </button>
          <p className="sub-header"> {_(subHeader)} </p>
        </div>
        {actionButton &&
          actionButton.action && (
            <div className="side-page-header__action">
              <button
                className="btn secondary side-page-header__button"
                onClick={actionButton.action}
              >
                <i className={`icon-left ${actionButton.icon}`} />
                <span>{actionButton.label}</span>
              </button>
            </div>
          )}
      </div>
    );
  }
}
