import React, { Component } from 'react';
import _ from 'i18n';

export default class GroupsCell extends Component {

  constructor(props) {
    super(props);
    this.state = { viewMoreGroups: false };
  };

  render() {
    let { groups } = this.props;
    let { viewMoreGroups } = this.state;
    let groupsBlock = [];
    let groupsIn = [];
    let groupsInLength = 0;

    if (groups && groups.length) {
      for (let i = 0; i < groups.length; i++) {
        if (groups[i].status == 'INCLUDE') {
          groupsInLength = groupsInLength + 1;
          groupsIn.push(groups[i]);
        }
      }
    }

    if (groupsIn && groupsInLength) {
      for (let i = 0; i < groupsInLength && i < 3; i++) {
        if (groupsIn[i].status == 'INCLUDE') {
          groupsBlock.push(<span key={Object.values(groupsIn[i])[0]} className="list__row-group-cell-tag">{Object.values(Object.values(groupsIn[i])[2])[1]}</span>)
        }
      }
      if (groupsInLength > 3) {
        for (let i = 3; i < groupsInLength; i++) {
          if (groupsIn[i].status == 'INCLUDE') {
            groupsBlock.push(<span key={Object.values(groupsIn[i])[0]} className={viewMoreGroups ? 'list__row-group-cell-tag' : 'list__row-group-cell-tag hide'}>{Object.values(Object.values(groupsIn[i])[2])[1]}</span>)
          }
        }
      }
    }

    return (
      <div className="list__row-groups-cell-wrapper">
        {groupsInLength < 4 && <div className="groups-block groups-block-position">{groupsBlock}
        </div>}
        {groupsInLength > 3 && <div className="groups-block">{groupsBlock}
          <div className="more-groups-container" onClick={() => this.setState({ viewMoreGroups: !viewMoreGroups })}>
            <img src={`${viewMoreGroups ? "img/icons/ChevronUp.svg" : "img/icons/ChevronDown.svg"}`} />
          </div>
        </div>}
      </div>
    );
  }
}
