import React, { Component } from "react";
import { connect } from "react-redux";
import _ from 'i18n';
import {setTypeListFilter} from "../../../../actions/actions/dashboard";
import Select from "react-select";
import {getType} from "../../../../services/utils";

@connect((store) => {
  return {
    typeEmail: store.dashboard.typeEmail
  };
})
export default class CampaignsFilter extends Component {

  constructor(props) {
    super(props);

   this.typeOptions = getType().map((type) => {
      return {
        value: type.key,
        label: (
          <span>
          {_(type.value)}
          </span>
        ),
      };
    });
  }

  handleSelectType = (selected) => {
    const typ = selected?.value || "";
    this.props.dispatch(setTypeListFilter(typ))
  };

  render() {
    return (
      <div className="m-b-xs m-r-xs dashboard_fil">
        <div className="dashboard_fil__cont medium-12">
          <Select
            isSearchable={false}
            isClearable={true}
            placeholder={_("Type")}
            options={this.typeOptions}
            className='filter-select medium-3'
            classNamePrefix="ttp-select"
            onChange={this.handleSelectType}
          />
        </div>
      </div>
    );
  }
}

