import React, { Component } from 'react';
import { SubMenuLink } from "../../SubMenuLink";

import _ from "i18n";

export default class Themes extends Component {

  render() {
    return <SubMenuLink pathname="/templates" title="models" icon="TEMPLATES_LIGHT" />;
  }
};
