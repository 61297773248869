import React, {Component} from "react";

export default class TeamsFetching extends Component {

  render () {
     return <div className="team-list-item team-list-item-fetching">
      <div className="row align-middle team-header">
        <div className="small-1">
          <span className="team-avatar initials-avatar" data-initials=""></span>
        </div>
        <div className="small-7">
          <h4 className="gradiant"></h4>
          <p><span className="gradiant"></span></p>
          <div className="team-list-item__action">
            <span className="gradiant"></span>
            <span className="gradiant"></span>
            <span className="gradiant"></span>
          </div>
        </div>
        <div className="small-4 team-header--members">
          <span><i className="icon icon-user-follow initials-color" data-initials=""></i></span>
        </div>
      </div>
    </div>;
  }
}
