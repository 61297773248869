import React, { Component } from "react";

import ApproverFetching from './ApproverFetching';

export default class TeamsFeedbackFetching extends Component {

  render() {
    let members = [];
    for (let i = 0; i < 3; i++) {
      members.push(<div key={`feedback-item-fetching-${i}`} className="small-4 feedback-item">
        <ApproverFetching />
      </div>);
    }
    return <div className="team-list-item team-list-item--open team-list-item-fetching">
      <div className="row align-middle team-header">
        <div className="small-1">
          <span className="team-avatar initials-avatar" data-initials=""></span>
        </div>
        <div className="small-7">
          <h4 className="gradiant"></h4>
          <p><h5 className="gradiant"></h5></p>
        </div>
      </div>
      <div className="team-setting row align-middle">
        {members}
      </div>
    </div>
  }
}
