export * from './speaker';
export * from './slot';

export const fetchGroups = (resp) => {
  return {
    type: 'FETCH_GROUPS',
    payload: resp
  };
};

export const fetchEvents = (resp) => {
  return {
    type: 'FETCH_EVENTS',
    payload: resp
  };
};

export const fetchEventCycles = (resp) => {
  return {
    type: 'FETCH_EVENT_CYCLES',
    payload: resp
  };
};

export const fetchActivities = (resp) => {
  return {
    type: 'FETCH_ACTIVITIES',
    payload: resp
  };
};

export const fetchOptions = (resp) => {
  return {
    type: 'FETCH_OPTIONS',
    payload: resp
  };
};

export const fetchPlan = (resp) => {
  return {
    type: 'FETCH_FORMULAS',
    payload: resp
  };
};

export const setTagsSuggestions = payload => {
  return {
    type: "FETCH_TAGS_SUGGESTIONS",
    payload
  };
};

export const fetchSurveys = (resp) => {
  return {
    type: 'FETCH_SURVEYS',
    payload: resp
  };
};

export const fetchCouponModel = (resp) => {
  return {
    type: 'FETCH_COUPON_MODEL',
    payload: resp
  };
};

export const fetchClientFolders = payload => {
  return {
    type: "FETCH_CLIENT_FOLDERS",
    payload
  };
};
export const fetchContacts  = (resp) => {
  return {
    type: "FETCH_CONTACT_FOLDERS",
    payload: resp

  };
};

export const fetchMyClientFolders = payload => {
  return {
    type: "FETCH_MY_CLIENT_FOLDERS",
    payload
  };
};

export const fetchCupboards = (resp) => {
  return {
    type: 'FETCH_CUPBOARD',
    payload: resp
  };
};
export const fetchExternalType = (resp) => {
  return {
    type: 'FETCH_EXTERNAL_TYPE',
    payload: resp
  };
};
export const fetchProfession = (resp) => {
  return {
    type: 'FETCH_PROFESSION',
    payload: resp
  };
};
