import React, { Component } from "react";

import { CAMPAIGN_LANGUAGE } from 'Common';
import {amountFormatter} from 'utils';

import _ from 'i18n';


export default class Summary extends Component {

    render() {
        const { language, smsMessage, containDynamicFields, smsTotalCount, approximateCost } = this.props;
    
        return (
            <div className="row align-center">
                <div className="small-10">
                    <ul className="summary">
                        <li><div>{_('language')}:</div> <div className="text-uppercase">{_(CAMPAIGN_LANGUAGE[language])}</div></li>
                        <li><div>SMS:</div><div>{_(smsMessage)}</div></li>
                        <li><div>{_('containDynamicFields')}:</div><div>{containDynamicFields ? _('yes') : _('no')}</div></li>
                        <li><div>{_('smsTotalCount')}:</div><div>{_(smsTotalCount)}</div></li>
                        <li><div>{ containDynamicFields ? _('SMS approximate cost') : _('SMS cost')}:</div><div>{amountFormatter(approximateCost, 2, language)}</div></li>
                    </ul>
                </div>
            </div>
        )
    }

}