import React, { PureComponent } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import { EVENT_RECIPIENT_TYPES } from 'Common';
import { setCurrentEmailCampaignData } from "actions";
import { fetchGroups } from 'thunks';
import TTPSelect from "common/TTPSelect";

@connect((store) => {
  return {
    groups: store.filters.groups.items,
    allowedGroups: store.currentCampaign.email.allowedGroups,
    deniedGroups: store.currentCampaign.email.deniedGroups,
    recipientType: store.currentCampaign.email.recipientType,
  }
})
export default class GroupsFilters extends PureComponent {

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, recipientType } = this.props;
    if (prevProps.recipientType != recipientType && recipientType != 'MANUAL' && EVENT_RECIPIENT_TYPES.indexOf(recipientType) == -1) {
      dispatch(fetchGroups(recipientType));
    }
  }

  handleAllowedGroupChange = (value) => {
    this.props.dispatch(setCurrentEmailCampaignData({ allowedGroups: value }));
  }

  handleDeniedGroupChange = (value) => {
    this.props.dispatch(setCurrentEmailCampaignData({ deniedGroups: value }));
  }

  render() {
    const { allowedGroups, deniedGroups, groups } = this.props;

    return (<div className="row align-center">
      <div className="columns small-6 columns-padding">
        <div className="sidebar__form-control">
          <h1 className="param__title">{_('groupsIncluded')}</h1>
          <TTPSelect
            values={allowedGroups}
            placeholder={_('selectGroups')}
            options={groups}
            onChange={this.handleAllowedGroupChange}
            disabled={deniedGroups} />
        </div>
      </div>
      <div className="columns small-6 columns-padding">
        <div className="sidebar__form-control">
          <h1 className="param__title">{_('excludedGroups')}</h1>
          <TTPSelect
            values={deniedGroups}
            placeholder={_('excludeGroups')}
            options={groups}
            onChange={this.handleDeniedGroupChange}
            disabled={allowedGroups} />
        </div>
      </div>
    </div>);
  }
}
