import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import _ from 'i18n';
import { saveMedia } from 'thunks';
import TTPSelect from "common/TTPSelect";
import { onSuccess, onError, getLanguages } from 'utils';

class ImageUploader extends Component {
    constructor(props) {
        super(props);
        this.handleLabelChange = (e) => this.setState({ label: e.target.value });
        this.handleLanguageChange = (value) => this.setState({ language: value });
        this.state = { attachment: null, label: "", language: this.props.lng || "all", processing: false, mediaType: "THEME_IMAGE" }
    }

    handleDrop = (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length === 0) return;
        this.setState({ attachment: acceptedFiles[0] });
    };

    saveAttachment = () => {
        let { attachment, label, language, processing, mediaType } = this.state;
        if (!attachment || processing) {
            return;
        }
        this.setState({ processing: true });

        this.props.dispatch(saveMedia({ attachment, label, language, mediaType }))
            .then(res => this.onSuccessUpload(res), err => this.onErrorUpload(err));
    };

    onErrorUpload = (err) => {
        this.setState({ processing: false });
        onError(err);
    };

    onSuccessUpload = (res) => {
        onSuccess(res);
        this.setState({ attachment: null, label: "", processing: false });
        this.props.done(res.value.data.data.url);
    };

  renderDropzoneContainer() {
        if (!this.state.attachment) {
            return (
              <div>
                <span className="icon icon-cloud-upload"/>
                <p>{_('Drag and drop a picture here, or click to select picture to upload.')}</p>
              </div>
            );
        }
    }

    clearDropzone = (e) => {
        e.stopPropagation();
        this.setState({ attachment: null, label: "", mediaType: "THEME_IMAGE" });
    };

    handleMediaTypeChange = (mediaType) => {
        this.setState({ mediaType });
    };

    renderPreviewActionButtons() {
        if (!this.state.attachment) {
            return null
        }

    return (
      <div className="ttp-small-buttons row medium-10 flex-container">
        <button onClick={() => this.dropzone.open()} className="button upload"><i className="icon-left icon-cloud-download"/>{_('change')}</button>
        <button onClick={this.clearDropzone} className="button cancel">{_('cancel')}</button>
      </div>
    );
  }

    render() {
        const { attachment, processing, label, language } = this.state;
        const { closed } = this.props;

        return (
            <div className="uploader-container row align-center p-b-m">
                {this.renderPreviewActionButtons()}
                <Dropzone className="image-container medium-10"
                    ref={(node) => { this.dropzone = node; }}
                    style={{ backgroundImage: `url(${attachment ? attachment.preview : ''})` }}
                    onDrop={this.handleDrop}
                    multiple={false}
                    accept={'image/jpeg, image/png'}>
                    {this.renderDropzoneContainer()}
                </Dropzone>
                <div className="row medium-10">
                    <div className="medium-5 margin-right-auto">
                        <span className="param-label">{_('language')}</span>
                        <TTPSelect
                            simple={true}
                            cssClass={`${!language && "empty-field"}`}
                            values={language}
                            options={getLanguages().map(({ key, value }) => { return { id: key, label: _(value).toUpperCase() }; })}
                            onChange={this.handleLanguageChange} />
                    </div>
                    <div className="medium-5">
                        <span className="param-label">{_('label')}</span>
                        <input type="text" placeholder={_("label")} value={label}
                            onChange={this.handleLabelChange} />
                    </div>
                </div>
                <div className="row medium-10 align-right">
                    <button onClick={closed} className="btn secondary">{_('cancel')}</button>
                    <button className={`btn primary ${attachment == null && 'disabled'}`} disabled={attachment == null} onClick={this.saveAttachment}>
                        {processing ? _("Processing...").toUpperCase() : _("save")}
                    </button>
                </div>
            </div>);
    }
}

export default ImageUploader;
