import React from "react";
import {SVG_ICONS} from "../../config/Common";
import _ from "i18n";

export const ThemeCardFetching = () => {
  return <div className="theme-card gradiant">
    <div className={"theme-card__status"}>
      <div className={"theme-card__status__title gradiant"}></div>
      <div className={"theme-card__status__tags"}>
        <span className={"gradiant"}><img src={"/img/icons/" + SVG_ICONS["TAG"]} alt=""/></span>
        <span className={"gradiant"}><img src={"/img/icons/" + SVG_ICONS["TAG"]} alt=""/></span>
      </div>
    </div>
  </div>
};
