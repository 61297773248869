import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from './home/Footer';
import LoadingBar from 'react-redux-loading-bar';
import { NotificationContainer } from 'react-notifications';
import TTPDialog from 'common/TTPDialog';
import { HORIZONTAL } from 'constants';
import SubMenu from './menu/SubMenu';
import { inIframe } from 'utils';
import { Copyright } from "./common/Copyright";
import { SelectCommunity } from "./SelectCommunity/SelectCommunity";
import TTPLoader from "./ui/TTPLoader";
import Menu from './menu/Menu';
import ErrorBoundary from './error/ErrorBoundary';

@connect((store) => {
  return {
    auth: store.auth,
    fetched: store.auth.fetched,
    currentNavPage: store.params.currentNavPage,
    subMenuDirection: store.params.subMenuDirection,
    step: store.params.step,
    lng: store.params.lng,
  };
})
export default class Layout extends Component {

  state = { stickyMenu: false, refreshing: false };

  // componentDidMount() {
  //   window.addEventListener("scroll", this.handleScroll, false);
  // }
  //
  // componentWillUnmount() {
  //   this.setState({ stickyMenu: false });
  //   window.removeEventListener("scroll", this.handleScroll);
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.lng !== this.props.lng) {
      this.setState({ refreshing: true });
      setTimeout(() => {
        this.setState({ refreshing: false })
      }, 1000);
    }
  }


  handleScroll = () => {
    if (["SETTINGS", "CREATE_CAMPAIGN", "CREATE_CAMPAIGN_SMS", "SMS_BILLS"].indexOf(this.props.currentNavPage) > -1) {
      this.setState({ stickyMenu: false });
      return;
    }
    if (window.scrollY > 64 && !this.state.stickyMenu) {
      this.setState({ stickyMenu: true });
    } else if (window.scrollY <= 64 && this.state.stickyMenu) {
      this.setState({ stickyMenu: false });
    }
  };

  render() {
    const { subMenuDirection, currentNavPage, auth: { currentClient, user }, step } = this.props;
    const { stickyMenu, refreshing } = this.state;
    const showMenu = !inIframe() && !STEPS.some(s => s === step);

    if (refreshing) {
      return <TTPLoader cssClass={"fixed-loader"} />;
    }

    return (
      <div id="offCanvas-wrapper" className="off-canvas-wrapper">
        <LoadingBar className="loading-bar" />
        {user && !currentClient && user.clients && user.clients.length > 0 ? <SelectCommunity /> : <div>
          {showMenu && <Menu />}
          <NotificationContainer />
          <div className={`flex-container flex-child-auto ${subMenuDirection == HORIZONTAL ? 'flex-dir-column' : 'flex-dir-row'}`}>
            {showMenu && <SubMenu stickyMenu={stickyMenu} />}
            <div className="flex-child-auto flex-container flex-dir-column" style={showMenu && stickyMenu && subMenuDirection === HORIZONTAL ? { marginTop: "45px" } : {}}>
              <ErrorBoundary>
                {this.props.children}
              </ErrorBoundary>
              {(["HOME"].indexOf(currentNavPage) != -1) && showMenu ? <Footer /> : null}
              {showMenu && NAV_PAGES.some(p => p === currentNavPage) && <Copyright />}
            </div>
          </div>
        </div>}
        <div className="mask hide"></div>
        <TTPDialog />
      </div>
    );
  }
};

const NAV_PAGES = ["HOME", "CAMPAIGN_LIST", "SETTINGS", 'E_BOX', "TEMPLATES", "GENERIC_EMAILING", "CREATE_CAMPAIGN_SMS", "SMS_BILLS", 'MAILING_LISTS', 'THEME_CATEGORIES_LIST'];
const STEPS = ["HIDE_MENU", "MANAGE_THEME", "SELECT_SAMPLE", "SELECT_NEWS", "CREATE_CAMPAIGN"];
