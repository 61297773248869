import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'i18n';
import { setCurrentEmailData } from 'actions';
import { LANGUAGE_FLAGS } from '../../../../../config/Common';
import ItemHeader from '../../../ItemHeader';
import ApplyButton from '../../../../ui/ApplyButton';
import UserAvatar from '../../../../theme/UserAvatar';


export class ThemeItem extends Component {

  toogleTheme = () => {
    const { setCurrentEmailTheme, id, content, theme, preview } = this.props;
    let data = (theme && theme.id == id) ? null : { id, content, preview };
    setCurrentEmailTheme(data);
  };

  render() {
    const { id, name, originality, language, organization, theme, targetApp, updatedAt, updatedBy, preview } = this.props;
    const checked = theme && theme['id'] == id;

    return (
      <div className={`small-6 medium-4`}>
        <div className={`theme__item in-generic-emailing`}>
          <ItemHeader targetApp={targetApp} isPublic={!organization} />
          <div className="theme__item__preview" style={{ backgroundImage: `url(${(preview) ? `${preview}` : 'img/theme/no-theme.png'})` }} />
          <div className="theme__item__status">
            <div className="item-name">
              {name.length > 25 ? name.slice(0, 25) + "..." : name}
              <span className="item-lng">
                {LANGUAGE_FLAGS[language] && <span className={`flag-icon  ${LANGUAGE_FLAGS[language]}`}></span>}
              </span>
            </div>
            <div className="item-tags">
              <span className="item-tag"><i className="icon-tag"></i>{_(originality)}</span>
            </div>
            <UserAvatar updatedAt={updatedAt} updatedBy={updatedBy} />
          </div>
          <ApplyButton label="Apply the model" checked={checked} onToggleSelection={this.toogleTheme} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  theme: state.genericEmail.currentSetting.currentEmail.theme,
  loggedAs: state.auth.loggedAs,
});
const mapDispatchToProps = dispatch => ({
  setCurrentEmailTheme: (data) => dispatch(setCurrentEmailData({ theme: data })),

});
export default connect(mapStateToProps, mapDispatchToProps)(ThemeItem);
