import React from 'react';
import { connect } from 'react-redux';
import { setEBoxPageSize, setEboxSearchWord, setEboxStatus, setEboxRecipientType, setIsFolderEbox } from '../../../actions/actions';
import { EBOX_SETTING_STATUS } from '../../../constants';
import TTPFilterHorizontalBar from '../../common/filter/TTPFilterHorizontalBar';
import { CAMPAIGN_RECIPIENTS_TYPE } from 'Common';

import _ from "i18n";
import EboxType from './EboxType';


class ListFilter extends React.PureComponent {

  recipientTypeOptions = CAMPAIGN_RECIPIENTS_TYPE.map(type => {
    return { value: type, label: _(type) }
  })

  getEboxStatus = () => {
    const { filter: { isFolderEbox }, inOtherEbox, count } = this.props;

    const hideSent = inOtherEbox || isFolderEbox;

    return EBOX_SETTING_STATUS.filter(status => !(hideSent && status.key === 'SENT')).map(
      ({ key, value, color }) => (
        {
          label: _(key) + (key !== "SENT" ? ` (${count[value]})` : ''),
          value,
          color
        }
      ))
  }


  render() {
    const { filter: { pageSize, isFolderEbox, status, recipientType }, setEboxSearchWord, setEboxStatus, setEBoxPageSize, setEboxRecipientType, setIsFolderEbox } = this.props;

    return (
      <div className="row">
        <EboxType
          isFolderEbox={isFolderEbox}
          onChange={setIsFolderEbox}
        />
        <TTPFilterHorizontalBar
          cssClass={"medium-12"}
          filters={[
            { placeholder: _("type"), options: this.recipientTypeOptions, values: recipientType, action: setEboxRecipientType, isSimple: true },
          ]}
          searchInputValue={""}
          handleSearchInputChange={setEboxSearchWord}
          pageSize={pageSize}
          handleListPageSizeChange={setEBoxPageSize}
          onTabChange={setEboxStatus}
          hideAllTab
          selectedTab={status}
          tabs={this.getEboxStatus()}
        />
      </div>)
  }

}

const mapStateToProps = state => ({
  filter: state.ebox.list.filter,
  count: state.ebox.list.count,
})

const mapDispatchToProps = dispatch => ({
  setEboxSearchWord: (searchWord) => dispatch(setEboxSearchWord(searchWord)),
  setEboxRecipientType: (type) => dispatch(setEboxRecipientType(type)),
  setEBoxPageSize: (pageSize) => dispatch(setEBoxPageSize(pageSize)),
  setEboxStatus: (status) => dispatch(setEboxStatus(status)),
  setIsFolderEbox: (flag) => dispatch(setIsFolderEbox(flag)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ListFilter);
