import React from "react";
import { LANGUAGE_FLAGS, CAMPAIGN_LANGUAGE } from "Common";
import _ from "i18n";
import { SVG_INVALID } from "../../../services/svgIcones";

export const SimpleTabs = (props) => {
  const { children, active, tabs, setActiveTab, language } = props;
  return <div className="simple-tabs">
    <div className="simple-tabs__group">
      {language ? tabs.map(({ lng, hasError }) => {
        return <a key={lng} className={`tab ${lng === active ? "active" : ""}`}
          onClick={() => setActiveTab(lng)}>
          <span className={`flag-icon  ${LANGUAGE_FLAGS[lng]}`} /> {_(CAMPAIGN_LANGUAGE[lng])}
          {hasError && SVG_INVALID}
        </a>
      }) :
        tabs.map(({ key, title }) => <a key={key} onClick={() => setActiveTab(key)} className={`tab ${key === active ? "active" : ""}`}>{_(title)}</a>)
      }
    </div>
    {tabs && tabs.length > 0 && <div className="simple-tabs__content">
      {children}
    </div>}
  </div>
};
