export const setCurrentEmailingSetting = (data) => {
  return {
    type: 'SET_CURRENT_EMAILING_SETTING_DATA',
    data
  };
};

export const saveEmailsSettings = (payload) => {
  return {
    type: 'SAVE_EMAILS_SETTINGS',
    payload
  };
};

export const initCurrentEmailingSettings = (data = {}) => {
  return {
    type: 'INIT_CURRENT_EMAILING_SETTING_DATA',
    data
  };
};

export const fetchCurrentEmailingSetting = (resp) => {
  return {
    type: 'FETCH_CURRENT_EMAILING_SETTING',
    payload: resp
  };
};

export const fetchGenericEmails = (resp) => {
  return {
    type: 'FETCH_GENERIC_EMAILS',
    payload: resp
  };
};

export const initCurrentEmailData = (data = {}) => ({
  type: "INIT_CURRENT_EMAIL_DATA",
  data
});

export const setCurrentEmailData = (data) => ({
  type: "SET_CURRENT_EMAIL_DATA",
  data
});

export const saveCurrentGenericEmail = (payload) => ({
  type: "SAVE_CURRENT_GENERIC_EMAIL",
  payload
});

export const sendGenericEmailTest = (resp) => {
  return {
    type: 'SEND_GENERIC_EMAIL_TEST',
    payload: resp
  };
};

export const fetchOrganisationEmailsSettings = (payload) => ({
  type: 'FETCH_EMAILS_SETTINGS',
  payload
});

//page size + Pagination
export const setEmailSettingsPageSize = (pageSize) => {
  return {
    type: 'SET_EMAILS_SETTINGS_PAGE_SIZE',
    pageSize
  };
};

export const setEmailsSettingsPaginationPage = (pageNumber) => {
  return {
    type: 'SET_EMAILS_SETTINGS_PAGINATION_PAGE',
    pageNumber
  };
};

export const setEmailsSettingsTargetAppFilter = (targetApp) => {
  return {
    type: 'SET_EMAILS_SETTINGS_TARGET_APP_FILTER',
    targetApp
  };
};

export const setEmailsSettingsStatusFilter = (status) => {
  return {
    type: 'SET_EMAILS_SETTINGS_STATUS_FILTER',
    status
  };
};
