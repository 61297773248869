import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import EmailEditor from 'react-email-editor'

import sample from './sample.json'

import { fetchTemplates, fetchAppDynamicsFields, sendThemeTest } from 'thunks';
import { setStep, setCurrentNavPage, setTTPDialogCustomData, setNavigateBackUrl, setCurrentDndTheme } from "actions";
import { STEPS } from 'Common';

import _ from 'i18n';
import DIALOG_TYPES from "./../../constants/TTPDialog";
import { DRAG_DROP_ORIENTATION_OPTIONS, DRAG_DROP_THEME_TYPE_OPTIONS, DRAG_DROP_THEME_COLOR_OPTIONS } from '../../config/Common.js';
import ImageSelector from './gallery/ImageSelector';

import { generateBlocs, onError, onSuccess } from "utils";
import { UNLAYER_PROJECT_ID } from "Config";
import { CAMPAIGN_DYNAMIC_FIELDS } from "../../constants/dynamicFields";
import { fieldToSpecialLinks, getMergedFields, getMergedOneFields } from "../../services/mapper";
import SaveThemeForm from "./SaveThemeForm";
import SendTestForm from "../common/SendTestForm";
import { prepareHtml } from "../../services/block/theme";
import ActionBar from "../campaign/create/ActionBar";
import { SVG_VALID_ICON } from "../../services/svgIcones";
import Loader from "react-loaders";
import { setCurrentCampaignTheme, setThemeIsSaved } from '../../actions/actions';
import NotFound from '../notFound/NotFound';
import { S3_FOLDER_URL_PROD } from '../../config';

@connect((store) => {
  return {
    lng: store.params.lng,
    currentTheme: store.themes.currentDndTheme,
    currentTemplate: store.themes.newsletter.currentTemplate,
    fetching: store.themes.list.fetching,
    content: store.themes.content,
    images: store.medias.items,
    blocs: store.themes.newsletter.blocks,
    themes: store.themes.list.items,
    base: store.themes.newsletter.base,
    updating: store.themes.updating,
    isSaved: store.themes.isSaved,
    fields: {
      RECIPIENT: { "RECIPIENT": CAMPAIGN_DYNAMIC_FIELDS["RECIPIENT"] },
      SURVEY: { "SURVEY": CAMPAIGN_DYNAMIC_FIELDS["SURVEY"] },
      WTB: store.params.dynamicsFields.wtb.list || [],
      EVENT: store.params.dynamicsFields.event.list || [],
    },
    isEventFetched: store.params.dynamicsFields.event.fetched
  }
})
export default class DragDropEditor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: null, name: "Untitled", content: "", design: "", language: "", object: "", isPublic: 1,
      category: null, isDesignUpdated: false, main: 0, gdprThemeId: null, showImageGallery: false, done: null,
      images: [], generatedBlocs: [], type: "DRAG_AND_DROP", editorHasError: false, editorLoaded: false, themeCategory: ""
    };

    this.minHeight = "calc(" + window.innerHeight + "px - 4rem)";

    this.toggleTestForm = () => this.setState({ showTestForm: !this.state.showTestForm });

    const { dispatch, currentTheme, base, blocs, inCreateCampaign, childRef } = props;
    const { params } = this.props.match;
    if (!inCreateCampaign) {
      dispatch(setCurrentNavPage("CREATE_CAMPAIGN"));
      dispatch(setStep(STEPS['MANAGE_THEME']));
    }
    dispatch(fetchAppDynamicsFields("EVENT"));
    dispatch(fetchAppDynamicsFields("WTB"));

    const id = params?.templateId;
    if (id) {
      dispatch(fetchTemplates({ id })).then(res => {
        if (!res.value.data && !res.value.data.data || !res.value.data.data.length) {
          return;
        }
        let { id, name, content, design, language, object, isPublic, main, gdprThemeId, type, targetApp, organization, themeCategory } = res.value.data.data[0];
        this.setState({ id, name, content, design, language, object, isPublic, main, gdprThemeId, type, targetApp, organization, themeCategory });
        dispatch(setThemeIsSaved(true));
        this.loadDesign(design);
      })
    } else if (currentTheme) {
      let { id, name, content, design, language, object, isPublic, main, gdprThemeId, type, targetApp, organization, themeCategory } = currentTheme;
      this.state = { ...this.state, id, name: name || "Untitled", content, design, language, object, isPublic, main, gdprThemeId, type: type || "DRAG_AND_DROP", targetApp: targetApp || "EMAILING", organization, themeCategory };
      if (!inCreateCampaign && id) {
        dispatch(setThemeIsSaved(true));
      }
    } else {
      this.state = { ...this.state, type: this.props.location && this.props.location.state && this.props.location.state.type };
    }
  }

  componentDidMount() {
    const { base, blocs, inCreateCampaign, childRef } = this.props;

    if (base !== "TEMPLATE" && blocs && blocs.length > 0) {
      let generatedBlocs = generateBlocs(blocs, false, true);
      this.setState({ generatedBlocs });
    }

    if (inCreateCampaign && childRef) {
      childRef({ primaryAction: this.toggleSaveForm, secondaryAction: this.proceedToCampaignCreation, close: this.confirmCloseEditor, isSaved: this.isSaved, isUpdating: this.isUpdating });
      this.props.triggerRender && this.props.triggerRender();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { updating } = this.props;
    if (updating !== prevProps.updating) {
      this.props.triggerRender && this.props.triggerRender();
    }
  }

  componentWillUnmount() {
    const { dispatch, inCreateCampaign } = this.props;
    if (!inCreateCampaign) {
      dispatch(setStep(null));
      dispatch(setNavigateBackUrl(null));
      document.body.classList.remove("bg-d8");
      dispatch(setThemeIsSaved(false));
    }
  }

  confirmCloseEditor = () => {
    const { dispatch } = this.props;
    if (this.state.isDesignUpdated) {
      dispatch(setTTPDialogCustomData({
        'approvedAction': this.closeEditor,
        'message': _('Once you close your changes will be lost. Do you really want to continue?'),
        'type': DIALOG_TYPES["ALERT"],
      }));
    } else {
      this.closeEditor();
    }
  };

  closeEditor = () => {
    const { dispatch, history } = this.props;
    dispatch(setStep(null));
    window.parent.postMessage('CLOSE_VIEWER', "*");
    history.push('/campaign/selector');
  };

  translateAndUppercase = (option) => {
    option.label = _(option.label).toUpperCase();
    return option;
  };

  toggleImageGallery = () => {
    this.setState({ showImageGallery: false });
  };

  sendTest = (recipients) => {
    const { id, dispatch } = this.props;
    let editor = this.editor || unlayer;

    editor.exportHtml(({ html }) => {
      let data = { id, html: prepareHtml(html), emails: recipients };

      dispatch(sendThemeTest(data)).then(
        (res) => {
          this.toggleTestForm();
          onSuccess(res);
        },
        (err) => onError(err)
      )
    });
  };

  toggleSaveForm = (theme = null) => {
    const { showSaveForm } = this.state;
    if (theme) {
      this.setState({ ...theme, isDesignUpdated: false });
      this.props.triggerRender && this.props.triggerRender();
    }
    this.setState({ showSaveForm: !showSaveForm });
  };

  proceedToCampaignCreation = () => {
    const { dispatch, currentTheme } = this.props;
    let editor = this.editor || unlayer;
    editor.exportHtml(({ html, design }) => {
      let theme = { ...currentTheme, design, content: html };
      dispatch(setCurrentDndTheme(theme));
      dispatch(setCurrentCampaignTheme(theme));
      const count = (html.match(/<a[^>]*href=["']["']/g) || []).length;
      if(count > 0) {
        dispatch(setTTPDialogCustomData({
          'abortAction':  () => this.props.proceed(),
          'message': _("You have") + " " + count + " " + _("empty link(s) in your model") + "!",
          'proceedBtnLabel': _('No, fill in the links'),
          'abortBtnLabel': _('yesContinue'),
          'title': "Confirmation",
          'type': DIALOG_TYPES["SUCCESS"],
        }));
      } else {
        this.props.proceed();
      }
    });
  };

  isSaved = () => {
    return this.props.isSaved;
  };

  isUpdating = () => {
    return this.props.updating;
  };

  onAction = (actionCode) => {
    if (this[actionCode]) this[actionCode]();
  };

  getTranslationLng = () => {
    const { lng, currentTheme, currentTemplate } = this.props;
    let currentLanguage = "all";
    if (currentTemplate && currentTemplate.language) {
      currentLanguage = currentTemplate.language;
    } else if (currentTheme && currentTheme.language) {
      currentLanguage = currentTheme.language;
    }

    return currentLanguage === "all" ? lng : currentLanguage;
  };


  loadDesign = (design) => {
    let editor = this.editor || unlayer;
    this.props.triggerRender && this.props.triggerRender();
    try {
      if (design && (typeof design === 'string' || design instanceof String)) {
        design = JSON.parse(design);
      }
      editor.loadDesign(design);
    } catch (e) {
      console.warn(e);
      editor.loadDesign(sample);
    }
  };

  render() {
    const { lng, fetching, fields, updating, inCreateCampaign, isSaved, isEventFetched } = this.props;
    const { name, showImageGallery, done, type, showSaveForm, showTestForm, editorHasError, editorLoaded } = this.state;
    const Event = getMergedFields(fields.EVENT, 'EVENT', lng);
    const Recipient = getMergedOneFields(fields.RECIPIENT, lng);
    const Survey = getMergedOneFields(fields.SURVEY, lng);
    const Wtb = getMergedFields(fields.WTB, 'WTB', lng);
    const EventLinks = fieldToSpecialLinks(fields.EVENT, "EVENT", lng);
    const SurveyLinks = fieldToSpecialLinks(fields.SURVEY, "SURVEY", lng);
    const WtbLinks = fieldToSpecialLinks(fields.WTB, "WTB", lng);
    console.log(isEventFetched);

    let translationLng = this.getTranslationLng();
    let options = {
      display: "email",
      mergeTags: [
        Event,
        Recipient,
        Survey,
        Wtb,
        { name: _('sending date'), value: "{{SENT.DATE}}" }
      ].concat(type === "WRAPPER" ? [
        { name: _('subject'), value: "{{SUBJECT}}" },
        { name: _('body'), value: "{{BODY}}" },
      ] : []),
      translations: {
        "en-US": {
          "buttons.upload_image": "Select Image",
        },
        "fr-FR": {
          "buttons.upload_image": "Sélectionner une image",
        }
      },
      specialLinks: [
        {
          name: _('unsubscribe'),
          href: '{{UNSUBSCRIBE_URL}}',
        },
        EventLinks,
        SurveyLinks,
        WtbLinks
      ],
     features: {
        textEditor: {
          fontSizes: [ "8px", "9px","10px", "11px", "12px","13px", "14px", "15px","16px", "17px","18px", "19px", "20px"
            , "21px", "22px", "23px", "24px", "25px", "26px", "27px", "28px", "29px", "30px",
             "31px", "32px", "33px", "34px", "35px", "36px", "37px", "38px", "39px", "40px"
            , "41px", "42px", "43px", "44px", "45px", "46px", "47px", "48px", "49px", "50px"
            , "51px", "52px", "53px", "54px", "55px", "56px", "57px", "58px", "59px", "60px"
            , "61px", "62px", "63px", "64px", "65px", "66px", "67px", "68px", "69px", "70px"
            , "71px", "72px"
          ]
        }
      },
      fonts: {
        showDefaultFonts: true,
        customFonts: [
          {
            label: "Roboto",
            value: "'Roboto', sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Roboto"
          },
          {
            label: "Poppins",
            value: "'Poppins', sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Poppins"
          },
          {
            label: "Oswald",
            value: "'Oswald', sans-serif",
            url: "https://fonts.googleapis.com/css2?family=Oswald"
          },
          {
            label: "Bebas",
            value: "'Bebas', cursive",
            url: "https://fonts.googleapis.com/css2?family=Bebas"
          },
          {
            label: "Museo",
            value: "'Museo', cursive",
            url: "https://fonts.googleapis.com/css2?family=Museo"
          }
        ]}
    };


    const tools = {
      'custom#footer': {
        data: {
          now: new Date().getUTCFullYear(),
          iconsBaseUrl: `${S3_FOLDER_URL_PROD}/emailing/assets/social`,
          unsubscribe: _("unsubscribe", translationLng),
          web_version: _('web_version', translationLng),
        },
        properties: {
          orientation: {
            editor: {
              data: {
                options: DRAG_DROP_ORIENTATION_OPTIONS.map(this.translateAndUppercase),
              }
            }
          },
          themeForm: {
            editor: {
              data: {
                options: DRAG_DROP_THEME_TYPE_OPTIONS.map(this.translateAndUppercase),
              }
            }
          },
          themeColor: {
            editor: {
              data: {
                options: DRAG_DROP_THEME_COLOR_OPTIONS.map(this.translateAndUppercase),
              }
            }
          }
        }
      }
    }


    return <Fragment>
      <div className={`drag-drop-editor`}>
        {!inCreateCampaign && <ActionBar icon={"DRAG_DROP"} title={"Drag&Drop editor"} name={name} currentStepIndex={0}
          steps={[{
            actions: [{
              label: "save",
              cssClass: "primary",
              key: "toggleSaveForm",
              icon: SVG_VALID_ICON,
            }],
          }]} onAction={this.onAction} onClose={this.confirmCloseEditor}
          isSaved={isSaved}
          updating={updating}
        />}
        {fetching && <div className="drag-drop-editor__overlay">
          <Loader type="line-spin-fade-loader" />
        </div>}

        {!fetching && isEventFetched && (editorHasError ? <NotFound text="errorOccured" />
          :
          <EmailEditor
            id="drag-n-drop-editor"
            ref={editor => this.editor = editor}
            projectId={Number.parseInt(UNLAYER_PROJECT_ID)}
            onLoad={this.onLoad}
            minHeight={this.minHeight}
            options={options}
            tools={tools}
            locale={lng === "en" ? "en-US" : "fr-FR"}
          />
        )
        }
        {editorLoaded && <div className="send-btn">
          <button className={`btn send`} onClick={this.toggleTestForm}>
            <img src="/img/icons/EMAIL-light.svg" />
            {_('send a test')}
          </button>
        </div>}
      </div>
      {showImageGallery && <ImageSelector {...this.props} done={done} onCloseModal={this.toggleImageGallery} />}
      {showSaveForm && <SaveThemeForm {...this.props} {...this.state} onCloseModal={this.toggleSaveForm} />}
      {showTestForm && <SendTestForm processing={updating} onCloseModal={this.toggleTestForm} onSave={this.sendTest} />}
    </Fragment>
  }

  onLoad = () => {
    let { design } = this.state;
    let editor = this.editor || unlayer;

    this.loadDesign(design);

    editor.addEventListener('design:updated', () => {
      this.setState({ isDesignUpdated: true });
      this.props.dispatch(setThemeIsSaved(false));
      this.props.triggerRender && this.props.triggerRender();
    });

    editor.registerCallback('selectImage', (data, done) => {
      this.setState({ showImageGallery: true, done });
    });

    editor.addEventListener('design:loaded', (data) => {
      let hasError = !data?.design?.rows;
      this.setState({ editorLoaded: !hasError, editorHasError: hasError });
    });
  }

}
