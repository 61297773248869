import React from "react";

export const InfoBar = props => {
  const { message, description, changePersonalizedRecipients } = props;
  return (
    <div className="info">
      <div className="message">
        <div className="message-content">
          <span>{message}</span>
          <span
            className="desc_selection"
            onClick={changePersonalizedRecipients}
          >
            {description}
          </span>
        </div>
      </div>
    </div>
  );
};
