import React, { Component } from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import uuid from 'uuid';

import FeedCard from './FeedCard';

import { setNewsletterBlocks } from "actions";

const mapStateToProps = (state, ownProps) => ({
  cards: state.themes.newsletter.blocks,
  currentLayout: state.themes.newsletter.currentLayout,
});

const mapDispatchToProps = dispatch => ({
  setNewsletterBlocks: (blocks) => dispatch(setNewsletterBlocks(blocks)),
});

@connect(mapStateToProps, mapDispatchToProps)
class Container extends Component {

  constructor(props) {
    super(props);
    this.state = { cards: props.cards };
  }

  componentDidUpdate(prevProps, prevState) {
    const { cards } = this.props;
    if (prevProps.cards !== cards) {
      this.setState({ cards });
    }

    if (!this.props.currentLayout) {
      return;
    }
    let scrolIntoElem = document.getElementById(`layout-${this.props.currentLayout.id}`);
    if (scrolIntoElem) {
      scrolIntoElem.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
  }

  pushCard(card) {
    this.setState(update(this.state, {
      cards: {
        $push: [card]
      }
    }));
  }

  moveCard = (dragIndex, hoverIndex) => {
    const { cards } = this.state;
    const dragCard = cards[dragIndex];

    const newState = update(this.state, {
      cards: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      }
    });

    this.setState(newState);
    this.props.setNewsletterBlocks(newState.cards);
  }

  removeCard = (index) => {
    const newState = update(this.state, {
      cards: {
        $splice: [
          [index, 1]
        ]
      }
    })

    this.setState(newState);
    this.props.setNewsletterBlocks(newState.cards);
  }

  duplicateCard = (index) => {
    let duplicat = { ...this.state.cards[index] };
    if (!duplicat) {
      return;
    }
    const newState = update(this.state, {
      cards: {
        $splice: [
          [
            index + 1,
            0,
            { ...duplicat, feeds: [], id: uuid() }
          ]
        ]
      }
    })
    this.props.setNewsletterBlocks(newState.cards);
  }

  render() {
    const { cards } = this.state;
    const { backgroundColor } = this.props;
    return (
      <div style={{ width: "100%", backgroundColor: backgroundColor || "#FFF" }}>
        {cards.map((card, i) => {
          return (
            <FeedCard
              key={card.id}
              index={i}
              lastIndex={cards.length - 1}
              card={card}
              moveCard={this.moveCard}
              removeCard={() => this.removeCard(i)}
              duplicateCard={() => this.duplicateCard(i)}
            />
          );
        })}
      </div>
    );
  }
}

export default Container;
