import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setMediasLanguage, setMediasSearchWord, setMediasOrigin, setMediasPaginationPage } from "actions";
import { fetchMedias, saveMedia } from "thunks";
import { onError } from 'utils';
import ImageLibraryFetching from 'fetching/ImageLibraryFetching';

import _ from 'i18n';
import InfiniteScroll from "react-infinite-scroller";
import uuid from "uuid";
import SearchBox from "common/filter/SearchBox";
import {getLanguages} from "../../services/utils";
import TTPSelect from "../common/TTPSelect";

@connect((store) => {
  return {
    themeImages: store.medias.items,
    origin: store.medias.origin,
    searchWord: store.medias.filter.searchWord,
    nbResult: store.medias.nbResult,
    paginationPage: store.medias.filter.paginationPage,
    fetching: store.medias.fetching,
  }
})
export default class ImagesLibrary extends Component {

  constructor(props) {
    super(props);
    this.handleEditedImgChange = (editedImg) => this.setState({ editedImg });
    this.state = { searchWord: "", lng: null, editedImg: null, hasMoreThemeImages: true };
  }

  handleLanguageChange = (lng) => {
    let { editedImg } = this.state;
    this.setState({editedImg: { ...editedImg, language: lng }});
  };

  handleLabelChange = (e) => {
    let { editedImg } = this.state;
    this.setState({editedImg: { ...editedImg, label: e.target.value }});
  }

  changeImage(src) {
    let { selectedImg, onImageChange, manager } = this.props;
    if (!selectedImg || (manager && !selectedImg.classList.contains('ttp-alterable'))) {
      return;
    }
    selectedImg.classList.remove('img-selected');
    selectedImg.src = src;
    onImageChange();
  }

  getImagesSrc() {
    let { initialImages, initialOnly, manager, themeImages } = this.props;
    if (initialOnly) {
      return initialImages;
    }
    let images = themeImages;
    let imgsSrc = images.map(img => `${img.url}`) || [];
    for (let i = 0; i < initialImages.length; i++) {
      let imgIndex = imgsSrc.indexOf(initialImages[i]);
      if (!manager && imgIndex > -1) {
        images.splice(imgIndex, 1);
        imgsSrc.splice(imgIndex, 1);
      }
    }
    return images;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchWord != this.props.searchWord || prevState.lng != this.state.lng ||
      prevProps.origin != this.props.origin) {
      this.props.dispatch(fetchMedias());
    }
  }

  componentWillReceiveProps(nextProps) {
    const { paginationPage, dispatch } = nextProps;
    if (paginationPage !== this.props.paginationPage) {
      dispatch(fetchMedias());
    }
    if (nextProps.fetching !== this.props.fetching) {
      this.setState({ hasMoreThemeImages: !nextProps.fetching })
    }
  }

  handleSearchWordChange = (searchWord) => {
    this.props.dispatch(setMediasSearchWord(searchWord));
  };

  handleLanguageFilterChange = (language) => {
    const lng = (this.state.lng === language) ? null : language;
    this.setState({ lng });
    this.props.dispatch(setMediasLanguage(lng));
  };

  switchMediasOrigin = (isChecked) => {
    this.props.dispatch(setMediasOrigin((isChecked) ? "MEDIA" : "MAILING"));
  };

  renderSearchArea() {
    let { lng } = this.state;
    const { origin } = this.props;
    if (this.props.initialOnly) {
      return null;
    }
    return <div className="apply-all theme-img-search">
      {/*<div className="text-uppercase flex-container">
        <Switch isChecked={origin == "MEDIA"} onChange={this.switchMediasOrigin} />
        <span className="app-switch">{_('media app')}</span>
      </div>*/}
      <ul className="menu__language">
        {['fr', 'nl', 'en'].map((item) => <li key={"lng-" + item} className={(lng == item) ? 'selected' : ''} onClick={() => this.handleLanguageFilterChange(item)}>{item.toUpperCase()} </li>)
        }
      </ul>
      <SearchBox onChange={this.handleSearchWordChange} placeholder="search by label" />
    </div>;
  }

  updateImgData = () => {
    let { id, label, language } = this.state.editedImg;
    if (!label || !id) {
      document.getElementById('img-label').classList.add('border-danger');
    }
    this.props.dispatch(saveMedia({ id, label, language }))
      .then(res => this.handleEditedImgChange(null), err => onError(err));
  };

  renderLabelBlock(image) {
    let { editedImg } = this.state;
    if (!image.id) {
      return null;
    }
    if (editedImg && editedImg.id === image.id) {
      return <div>
        <textarea id="img-label" placeholder={_('label')} value={editedImg.label} onChange={this.handleLabelChange} />
        <TTPSelect
          placeholder={editedImg.language}
          values={editedImg.language}
          onChange={(lng)=>this.handleLanguageChange(lng)}
          options={getLanguages(true).map(({key, value}) => {return {id: key, label: _(value).toUpperCase()};})}
          simple={true}
          notClearable={true}
          cssClass={ 'm-b-s' }
        />
        <div className="edit-theme-controls">
          <button onClick={() => this.handleEditedImgChange(null)} className="btn secondary">{_('cancel')}</button>
          <button onClick={this.updateImgData} className='btn primary'>{_('save')}</button>
        </div>
      </div >;
    }
    return <span>{image.label}</span>;
  }

  loadThemeImages = () => {
    const { paginationPage, dispatch, fetching, nbResult, themeImages } = this.props;

    if (!fetching && themeImages.length < nbResult) {
      this.setState({ hasMoreThemeImages: false });
      dispatch(setMediasPaginationPage(paginationPage + 1));
    }
  };

  renderThemeImages() {
    const { fetching } = this.props;
    const { editedImg, hasMoreThemeImages } = this.state;
    let images = this.getImagesSrc();

    let items = [];
    if (images && images.length > 0) {
      images.forEach(image => {
        let imgPath = (image.id) ? `${image.url}` : image;
        let isEditMode = image.id && editedImg && editedImg.id == image.id;
        let labelBlock = this.renderLabelBlock(image);

        items.push(<div key={`image-${image.id || uuid()}`} className={`add-theme-img ${!isEditMode && "add-theme-img__preview"}`}>
            <img src={imgPath} />
            <span className="add-img">
            {image.id ? <i className="icon icon-note" onClick={() => this.handleEditedImgChange(image)}/> : null}
              <i className="icon icon-check" onClick={this.changeImage.bind(this, imgPath)}/>
          </span>
          {labelBlock}
        </div>
        )
      })
    }

    if (fetching) {
      items.push(<ImageLibraryFetching key="image-1" />, <ImageLibraryFetching key="image-2" />, <ImageLibraryFetching key="image-3" />);
    }

    return (items.length > 0) ? <InfiniteScroll
      pageStart={0}
      loadMore={this.loadThemeImages}
      hasMore={hasMoreThemeImages}
      useWindow={false}
      getScrollParent={() => this.scrollParentRef}
    >{items}
    </InfiniteScroll> : <div className="row align-center" style={{ marginTop: "1rem" }}>
        <span>{_('no_images')}</span>
      </div>
  }
  render() {
    return <div className="style-tools-palette column small-7 add-bloc-overflow-y" ref={(ref) => this.scrollParentRef = ref}>
      {this.renderSearchArea()}
      {this.renderThemeImages()}
    </div>;
  }
}
