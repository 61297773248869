import axios from '../axios-api';
import { isArraysEqual } from "../../services/utils";

export const getClientTextModel = (token, clientId, filterData) => {
  const fields = ['*, target'];
  const { textModelId, language, targetApp, isLimited, searchWord, targetType, scope, offset } = filterData;
  const requestUrl = `/mailing/text-model`;
  let filter = [];

  if ((scope && isArraysEqual(scope, ["COMMUNITY"]) && scope.length > 0)) {
    filter.push({
      property: 'client',
      value: clientId,
      operator: 'eq'
    });
  } else if (scope && isArraysEqual(scope, ["TAMTAM"]) && scope.length > 0) {
    filter.push({
      property: 'client',
      value: null,
      operator: 'null'
    });
  } else {
    filter.push({
      property: 'client',
      value: clientId,
      operator: 'eqOrNull'
    });
  }

  if (textModelId) {
    filter.push({
      property: 'id',
      value: textModelId,
      operator: 'eq'
    });
  }

  if (searchWord && searchWord.length > 0) {
    filter.push({
      property: 'object',
      value: searchWord,
      operator: 'like'
    });
  }

  if (language && language.length > 0 && ["ALL", "TOUS", "ALLE"].indexOf(language) === -1) {
    filter.push({
      property: 'language',
      value: language,
      operator: 'in'
    });
  }

  if (targetApp && targetApp.length > 0 && ["ALL", "TOUS", "ALLE"].indexOf(targetApp) === -1) {
    filter.push({
      property: 'targetApp',
      value: targetApp,
      operator: 'in'
    });
  } else {
    filter.push({
      property: 'targetApp',
      value: "SMS",
      operator: 'neq'
    });
  }


  if (targetType && targetType.length > 0 && ["ALL", "TOUS", "ALLE"].indexOf(targetType) === -1) {
    filter.push({
      property: 'targetType',
      value: targetType,
      operator: 'in'
    });
  }

  let sort = [{
    property: 'updatedAt',
    dir: 'desc',
  }];

  const params = {
    access_token: token,
    fields: fields.join(','),
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    start: textModelId ? 0 : offset
  }

  if (isLimited) {
    params["limit"] = 8;
  } else {
    params["nolimit"] = 1;
  }

  return axios.get(requestUrl, {
    params
  });
};

export const persistTextModel = (token, data) => {
  const requestUrl = `/mailing/text-model`;

  var formData = new FormData();
  formData.append('access_token', token);
  formData.append('content', data.content);
  formData.append('object', data.object);
  formData.append('name', data.name);
  formData.append('client', !data.isAdminMode ? data.clientId : null);

  ("all" === data.language) ? formData.append('language', '') : formData.append('language', data.language);
  (["ALL", "TOUS", "ALLE"].indexOf(data.targetApp) > -1) ? formData.append('targetApp', "EXPRESS") : formData.append('targetApp', data.targetApp);
  (data.targetApp !== "SMS") ? formData.append('main', data.main) : formData.append('main', 0);

  if (data.id) {
    formData.append('id', data.id);
  }

  if (data.targetType && data.targetType.length > 0) {
    formData.append('targetType', data.targetType);
  }
  return axios.post(requestUrl, formData);
};

export const removeTextModel = (token, textModelId, clientId) => {
  const requestUrl = `/mailing/text-model/${textModelId}`;
  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('_method', 'delete');
  return axios.post(requestUrl, formData, {
    params: { organization_id: clientId }
  });
};

export const sendTextModelEmailTest = (token, data) => {
  var formData = new FormData();
  const requestUrl = `/mailing/text-model/test`;
  formData.append('access_token', token);
  if (!data.emails || !data.textModelId) {
    return;
  }

  formData.append('textModelId', data.textModelId);
  data.emails.forEach((email, i) => {
    formData.append(`emails[]`, email);
  });

  return axios.post(requestUrl, formData);
};
