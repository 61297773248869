import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'i18n';
import { saveThemeCategory } from 'thunks';
import { onError, onSuccess } from 'utils';
import { LANGUAGE_FLAGS } from 'Common';

@connect()
export default class ThemeCategoryForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mandatory: false,
      nameFrHasError: false,
      nameEnHasError: false,
      nameNlHasError: false,
      nameFr: null, 
      nameEn: null, 
      nameNl: null
    };
  }

  handleNameChange = (e,lng) => {
    const name = (e.target) ? e.target.value : e;
    if(lng == "fr") {
        this.setState({nameFrHasError: name.length ===  0, nameFr: name});
    }
    else if(lng == "en") {
        this.setState({nameEnHasError: name.length ===  0, nameEn: name});
    }
    else if(lng == "nl") {
        this.setState({nameNlHasError: name.length ===  0, nameNl: name});
    }
  };

  saveThemeCategory = () => {
    const { dispatch, toggleListFormSideBar } = this.props;
    const { nameFr, nameEn, nameNl } = this.state;
    let data = { nameFr, nameEn, nameNl };
    dispatch(saveThemeCategory(data)).then(
        (res) => {
          onSuccess(res)
          this.setState({nameFr: "", nameEn: "", nameNl: ""});
          toggleListFormSideBar();
        },
        (err) => onError(err)
    );
  }

  render() {
    let {opened, toggleListFormSideBar, updating} = this.props;
    let { nameFr, nameEn, nameNl, nameFrHasError, nameEnHasError, nameNlHasError } = this.state;

    const nameCssClassFr = `${nameFr && 'filled-box'} ${!nameFr && !nameFrHasError && 'empty-box'} ${nameFrHasError && 'is-invalid-input'}`;
    const nameCssClassEn = `${nameEn && 'filled-box'} ${!nameEn && !nameEnHasError && 'empty-box'} ${nameEnHasError && 'is-invalid-input'}`;
    const nameCssClassNl = `${nameNl && 'filled-box'} ${!nameNl && !nameNlHasError && 'empty-box'} ${nameNlHasError && 'is-invalid-input'}`;

    return (
      <div id="mailing-lists-form">

        <div className={(opened ? "sidebar sidebar--opened" : "sidebar")}>

          {opened && <div className="sidebar__actions">
            <div className="row columns small-12">
              <span className="sidebar__close icomoon icon-tt-close"
                    onClick={toggleListFormSideBar}/>
            </div>
          </div>}

          <div className="sidebar__form">
            <h4 className="sidebar__header">{_('Add new theme category')}</h4>
            <h6>{_('Type the title of your category')} <span>({_('3 langues available')})</span></h6>
            <div className="row row-top">
                <div className="columns small-12 columns-padding">
                  <span className={`flag-icon  ${LANGUAGE_FLAGS["fr"]}`} >&nbsp;&nbsp;<span className="param__title">{_('french')}</span></span>
                  <div className="small-12 margin-left-auto">
                    <input className={nameCssClassFr} type="text" onChange={(ev) => this.handleNameChange(ev, "fr")} value={nameFr} placeholder={_('title') +" "+_('french')} />
                  </div>
                </div>
                <div className="columns small-12 columns-padding">
                <span className={`flag-icon  ${LANGUAGE_FLAGS["en"]}`} >&nbsp;&nbsp;<span className="param__title">{_('english')}</span></span>
                  <div className="small-12 margin-left-auto">
                    <input className={nameCssClassEn} type="text" onChange={(ev) => this.handleNameChange(ev, "en")} value={nameEn} placeholder={_('title') +" "+_('english')}/>
                  </div>
                </div>
                <div className="columns small-12 columns-padding">
                <span className={`flag-icon  ${LANGUAGE_FLAGS["nl"]}`} >&nbsp;&nbsp;<span className="param__title">{_('dutch')}</span></span>
                  <div className="small-12 margin-left-auto">
                    <input className={nameCssClassNl} type="text" onChange={(ev) => this.handleNameChange(ev, "nl")} value={nameNl} placeholder={_('title') +" "+ _('dutch')}/>
                  </div>
                </div>
            </div>
            <div className="row row-bottom"> 
              <div className="small-12 columns-padding buttons-container">
                <button onClick={toggleListFormSideBar} className="btn secondary">{_('cancel')}</button>
                <button className="btn primary" onClick={(nameFr && nameEn && nameNl && !updating) ? this.saveThemeCategory : null}
                        disabled={!nameFr}>{updating ? _('Processing...') : _('save')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
