import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageHeader from 'common/PageHeader';
import { fetchEBox, fetchEboxCountStatus } from "thunks";
import { setInAdminSearchMode } from "../../actions/actions/eBox";
import { clearSourcesTokens } from "actions";
import EboxPage from './EboxPage';

@connect((store) => {
  return {
    filter: store.ebox.list.filter,
    _requests: store.params.sourcesTokens,
  };
}, dispatch => ({
  fetchEBox: () => dispatch(fetchEBox()),
  fetchEboxCountStatus: (flag) => dispatch(fetchEboxCountStatus(flag)),
  setInAdminSearchMode: (flag) => dispatch(setInAdminSearchMode(flag)),
  clearSourcesTokens: () => dispatch(clearSourcesTokens()),
}))
export default class MyEbox extends Component {

  componentDidMount() {
    const { fetchEBox, fetchEboxCountStatus } = this.props;
    setInAdminSearchMode(false);
    fetchEBox();
    fetchEboxCountStatus(false);
  }

  componentDidUpdate(prevProps) {
    const { filter, fetchEBox, fetchEboxCountStatus } = prevProps;
    if (filter != this.props.filter) {
      fetchEBox();
      fetchEboxCountStatus(false);
    }
  }

  componentWillUnmount() {
    const { _requests, clearSourcesTokens } = this.props;
    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    clearSourcesTokens();
  }

  render() {
    return <div id="e-box-page">
      <PageHeader header="mailbox"
        subHeader="list of emails and SMS."
        icon="MAILBOX"
      />
      <EboxPage />
    </div>
  }
}
