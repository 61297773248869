export const fetchMailingLists = (resp) => {
  return {
    type: 'FETCH_MAILING_LIST',
    payload: resp
  };
};

export const saveMailingList = (resp) => {
  return {
    type: 'SAVE_MAILING_LIST',
    payload: resp
  };
};

export const setMailingListsSearchWord = (searchWord) => {
  return {
    type: 'SET_MAILING_LIST_SEARCH_WORD',
    searchWord
  }
};

export const setMailingListsPaginationPage = (pageNumber) => {
  return {
    type: 'SET_MAILING_LIST_PAGINATION_PAGE',
    pageNumber
  };
};

export const setMailingListsPageSize = (pageSize) => {
  return {
    type: 'SET_MAILING_LIST_PAGE_SIZE',
    pageSize
  };
};

export const unsubscribeFromMailingList = (resp) => {
  return {
    type: 'UNSUBSCRIBE_FROM_MAILING_LIST',
    payload: resp
  };
};

export const subscribeIntoMailingList = (resp) => {
  return {
    type: 'SUBSCRIBE_INTO_MAILING_LIST',
    payload: resp
  };
};

export const setMailingListData = (data) => {
  return {
    type: 'SET_MAILING_LIST_DATA',
    data
  };
};

export const initMailingList = () => {
  return {
    type: 'INIT_MAILING_LIST',
  };
};

export const initMailingListsFilter = () => {
  return {
    type: 'INIT_MAILING_LISTS_FILTER',
  };
};

export const initMailingListsItems = () => {
  return {
    type: 'INIT_MAILING_LISTS_ITEMS',
  };
};

export const setMailingListsMandatoryFilter = (mandatory) => {
  return {
    type: 'SET_MAILING_LISTS_MANDATORY_FILTER',
    mandatory
  };
};

export const removeMailingList = (resp) => {
  return {
    type: 'REMOVE_MAILING_LIST',
    payload: resp
  };
};
