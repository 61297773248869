export const createSMSBill = (resp) => {
    return {
        type: 'SAVE_SMS_BILL',
        payload: resp
    };
};
export const fetchSMSBills = (resp) => {
    return {
        type: 'FETCH_SMS_BILLS',
        payload: resp
    };
};

export const setSMSBillsPaginationPage = (pageNumber) => {
    return {
        type: 'SET_SMS_BILLS_PAGINATION_PAGE',
        pageNumber
    };
};

export const setSMSBillsPageSize = (pageSize) => {
    return {
        type: 'SET_SMS_BILLS_PAGE_SIZE',
        pageSize
    };
};
export const setSMSBillsListSortConfig = (columnKey, sortDirection) => {
    return {
        type: 'SET_SMS_BILLS_LIST_SORT_CONFIG',
        config: {
            "columnKey": columnKey,
            "sortDirection": sortDirection
        }
    };
};
export const getCountryPrice = (resp) => {
    return {
        type: 'GET_COUNTRY_PRICE',
        payload: resp
    };
};

export const getSMSBill = (resp) => {
    return {
        type: 'GET_SMS_BILL',
        payload: resp
    };
};
export const validateSmsBill = (resp) => {
    return {
        type: 'VALIDATE_SMS_BILL',
        payload: resp
    };
};

export const setSmsBillsListFilterStatus = (status) => {
    return {
        type: 'SET_SMS_BILLS_LIST_FILTER_STATUS',
        status
    };
};

export const setSmsBillsListFilterType = (smsBillType) => {
    return {
        type: 'SET_SMS_BILLS_LIST_FILTER_TYPE',
        smsBillType
    };
};
