import React, { Component } from "react";
import { connect } from 'react-redux';
import { setTTPDialogCustomData } from "actions";

import _ from 'i18n';
import DIALOG_TYPES from "TTPDialog";
import Summary from "./Summary";
import Target from "../../../common/campaign/Target";
import { SVG_SEND_ICON } from 'utils';

@connect((store) => {
    return {
        recipients: store.campaigns.recipients,
        smsCredit: store.auth.currentClient.smsCredit,
        auth: store.auth,
        sending: store.campaigns.list.sending,
        opened: store.currentCampaign.sms.currentSideBar == "SEND",
        manualSmsRecipients: store.currentCampaign.sms.manualSmsRecipients,
        smsMessage: store.currentCampaign.sms.smsMessage,
    }
})
export default class SendTab extends Component {

    componentDidMount() {
        this.props.onRef(this)
    }

    handleShowDialog() {
        const {dispatch} = this.props;
        let {onConfirm} = this.props;
        dispatch(setTTPDialogCustomData({
            'approvedAction': onConfirm,
            'message': _('confirmSendCampaign'),
            'proceedBtnLabel': _('yesSend'),
            'abortBtnLabel': _('no'),
            'title': "Confirmation of sending",
            'svgIcon': SVG_SEND_ICON
        }));
    }

    render () {
        let {opened, recipients, recipientType, smsCount, smsCredit, smsApproximateCost, manualSmsRecipients, manualSmsApproximateCost, sending} = this.props;
        const smsRecipientsCount = ('MANUAL' == recipientType)? manualSmsRecipients.length:recipients.nbResult;
        const {loggedAs, preference} = this.props.auth;
        const authorisedToSend = loggedAs == "ADMIN" || (preference && preference['allowSendSms']== 1);
        const approximateCost = ('MANUAL' == recipientType)? manualSmsApproximateCost : smsApproximateCost;
        let sentDisabled = smsRecipientsCount <= 0 || approximateCost > smsCredit || !authorisedToSend;


        return (
            <div id='campaign-send__tab' className={`${opened ? "" : "hide"}`}>
                <h4 className="sidebar__header">{_('smsSendTitle')}</h4>
                <Target count={smsRecipientsCount} type={_(recipientType)}/>
                <Summary {...this.props} smsTotalCount={smsCount*smsRecipientsCount} approximateCost={approximateCost}/>
                <div className="row align-center">
                    <div className="small-10">
                        <a className={`ttp-button ${!sentDisabled ? 'success' : 'disabled'}`} onClick={sentDisabled? "" : !sending && this.handleShowDialog.bind(this)} disabled={sentDisabled}>{sending ? _('Processing...'):_('confirm')}</a>
                    </div>
                </div>
            </div>
        );
    }
}
