import moment from "moment";
import "moment/min/locales";

export const API_DATE_FORMAT = "YYYY-MM-DD HH:mm";

export function getLocaleDatetimeFormat(language) {
  let format;
  switch (language) {
    case "en":
      format = "MMMM Do, YYYY  h:mm a";
      break;
    case "nl":
      format = "DD MMMM YYYY  h:mm a";
      break;
    case "fr":
    default:
      format = "DD MMMM YYYY à H:mm";
      break;
  }
  return format;
}

export function getDate(
  dateObject,
  language,
  isConverted = false,
  isScheduled = false
) {
  if (!dateObject || !dateObject.date) {
    return "";
  }
  const locale = getLocale(language);

  const format = getLocaleDatetimeFormat(language);
  const offsetMinutes = new Date().getTimezoneOffset();
  let date = moment(dateObject.date);

  if (Object.values(date)[5].toString() === "Invalid Date" || !date.isValid()) {
    date = moment(dateObject.date.replace(/-/g, "/"));
  }

  let tzMoment = date.locale(locale);
  if (!isConverted) {
    tzMoment = date.subtract(offsetMinutes, "minutes").locale(locale);
  }

  if (isScheduled) {
    return tzMoment;
  }

  return format ? tzMoment.format(format) : tzMoment;
}

export function convertDateToUTC(
  date,
  srcFormat = API_DATE_FORMAT,
  destFormat = API_DATE_FORMAT
) {
  if (!date) {
    return "";
  }

  var offsetMinutes = new Date().getTimezoneOffset();
  return moment(date, [srcFormat])
    .add(offsetMinutes, "minutes")
    .format(destFormat);
}

export function convertDateFromUTC(
  date,
  srcFormat = API_DATE_FORMAT,
  destFormat = API_DATE_FORMAT
) {
  if (!date) {
    return "";
  }

  var offsetMinutes = new Date().getTimezoneOffset();
  var tzMoment = moment(date, [srcFormat]).subtract(offsetMinutes, "minutes");

  return destFormat ? tzMoment.format(destFormat) : tzMoment;
}

export const getDateLabel = date => {
  const d = new Date(date);

  const result = d.toDateString().split(" ");

  const hours =
    parseInt(d.getHours(), 10) < 10 ? "0" + d.getHours() : d.getHours();
  const minutes =
    parseInt(d.getMinutes(), 10) < 10 ? "0" + d.getMinutes() : d.getMinutes();

  return (
    result[2] +
    " " +
    result[1] +
    " " +
    result[3] +
    ", at " +
    hours +
    ":" +
    minutes
  );
};

export function amountFormatter(amount, fractions = 2, language) {
  let locale = getLocale(language);
  let formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: fractions
  });
  return formatter.format(amount);
}

export function numberFormatter(amount, language) {
  let locale = getLocale(language);
  let formatter = new Intl.NumberFormat(locale, {});
  return formatter.format(amount);
}

export function getLocale(language) {
  let locale = "";

  switch (language) {
    case "en":
      locale = "en-GB";
      break;
    case "nl":
      locale = "nl-NL";
      break;
    case "fr":
    default:
      locale = "fr-Fr";
      break;
  }
  return locale;
}

export function formatDateRange(start, end, locale) {
  let startMoment = moment(start, [API_DATE_FORMAT]).locale(locale);
  let endMoment = moment(end, [API_DATE_FORMAT]).locale(locale);
  let startDate = startMoment.format("dddd DD MMMM");
  let endDate = startMoment.format("dddd DD MMMM");

  if (startDate && startDate == endDate) {
    return `${startDate.charAt(0).toUpperCase() +
      startDate.slice(1)} / ${startMoment.format("HH:mm")} - ${endMoment.format(
      "HH:mm"
    )}`;
  }

  return `${startMoment.format("MMMM DD, HH:mm")} - ${endMoment.format(
    "MMMM DD, HH:mm"
  )}`;
}

export const getEventNbMinutes = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) {
    return 0;
  }

  const startTime = moment(startDateTime);
  const endTime = moment(endDateTime);
  return endTime.diff(startTime, "minutes");
};
