import React, { Component } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import _ from "i18n";
import { CHARTS_COLORS } from "../../../../config/Common";
import { connect } from "react-redux";

class BarChartComponent extends Component {

  render() {
    const { dataChart, dataKeyX, dataKeysY,yAxisTitle, fetched, fetching } = this.props;
    if (!dataChart) return null;
    if( fetched === false && fetching === true )
      return<div className="chartImage"> <img src={"/img/Ripple-1s-150px.svg"} alt="" /> </div>;

    return (
      <div className="chartComponent">
        <p className="yAxisTitle">{_(yAxisTitle)}</p>
        <ResponsiveContainer>
          <BarChart className="lineChart"
            data={dataChart}
            margin={(dataKeysY.length === 1 && { top: 50}) || { top: 0 }}
          >
            <CartesianGrid vertical={false} strokeWidth={0.1} strokeDasharray="3 3" />

            <XAxis  dataKey={dataKeyX.key} name={dataKeyX.label} padding={{ left: 40, right:20 }} type="category" stroke="#6D7F92"
              axisLine={false} tickLine={false}
            />

            <YAxis  yAxisId="left" orientation="left" type={"number"} stroke="#6D7F92" padding={{ top: 35 }}
                    axisLine={false} tickLine={false}/>

            <Tooltip cursor={false} />

            {dataKeysY.length === 1 || (
              <Legend verticalAlign="top" height={60} className = "legendChart" iconType={"square"} iconSize={16} layout={"horizontal"}/>
            )}

            {dataKeysY.map((item, i) => {
              let color = CHARTS_COLORS.get(item.key);
              return (
                <Bar
                  key={i}
                  name={item.label}
                  yAxisId="left"
                  type="monotone"
                  dataKey={item.key}
                  barSize={8}
                  radius={[4, 4, 4, 4]}
                  fill={color}
                  animationEasing={"ease-in-out"}
                  animationDuration={5}
                  minPointSize={3}
                />
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  fetching : store.dashboard.fetching,
  fetched:  store.dashboard.fetched,
});
export default connect(mapStateToProps)(BarChartComponent);
