import React, { Component } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import _ from "i18n";
import { CHARTS_COLORS } from "../../../../config/Common";
import { connect } from "react-redux";

class LineChartComponent extends Component {
  render() {
    const { dataChart, dataKeyX, dataKeysY, yAxisTitle, isEvolutionTooltip,fetched, fetching } = this.props;
    if( fetched === false && fetching === true )
      return<div className="chartImage"> <img src={"/img/Ripple-1s-150px.svg"} alt="" /> </div>;

    if (!dataChart) return null;
    let tooltip;
    let value = 0;
    let signe = true;
    const CustomTooltip = ({ payload }) => {
      if (payload == null) return "";
      for (const bar of payload)
      {
        if(value > parseInt(bar.value))
          signe = false;
        if(value < parseInt(bar.value))
          signe = true;
        value = parseInt(bar.value);
        if (bar.dataKey === tooltip)
          return (
            <div className = "TooltipStyle"style={{backgroundColor: bar.color}}>
              { (isEvolutionTooltip && (
                <div>
                  <label className="polygonIcon">
                    {signe && <img src="/img/icons/polygon-plus.svg" /> ||  <img src="/img/icons/polygon-down.svg" />}
                  </label>
                  <label className="tooltipValue"> {parseInt(bar.value)}</label>
                </div>
              )) || (<label className="tooltipValue" style={{ textAlign: "center" }} > {parseInt(bar.value)} </label>)
              }
            </div>
          );
      }
      return "";
    };

    return (
      <div className="chartComponent">
        <p className="yAxisTitle"> {yAxisTitle}</p>
        <ResponsiveContainer>
          <LineChart
            className="lineChart"
            data={this.props.dataChart}
            padding = {{left:0}}
            margin={( dataKeysY.length == 1 && {top: 50, left:0}) || {top: 0, left:0}}
          >
            <CartesianGrid vertical={false} strokeWidth={0.1} strokeDasharray="3 3" />
            <XAxis  dataKey={dataKeyX.key} name={dataKeyX.label} padding={{ left: 10, right:20 }}  stroke="#6D7F92"
                    axisLine={false} tickLine={false} tickCount={31} domain={[1, 31]} />

            <YAxis  yAxisId="left" orientation="left" type={"number"} stroke="#6D7F92" padding={{ left: 0,top: 35 }} margin={{left:0}}
                    axisLine={false} tickLine={false}/>

            <Tooltip content={<CustomTooltip />} cursor={{opacity: 0 }}/>

            {dataKeysY.length == 1 || (
              <Legend verticalAlign="top" height={80} className = "legendChart" iconType={"square"} iconSize={16} layout={"horizontal"}/>
            )}

            {dataKeysY.map((item, i) => {
              let color = CHARTS_COLORS.get(item.key);
              return (
                <Line
                  key={i}
                  onMouseMove={() => (tooltip = item.key)}
                  name={item.label}
                  yAxisId="left"
                  type="monotone"
                  dataKey={item.key}
                  stroke={color}
                  dot={{ fill: item.color, strokeWidth: 6 }}
                  activeDot={{
                    r: 8,
                    onMouseOver: () => (tooltip = item.key),
                    onMouseLeave: () => (tooltip = item.key),
                  }}
                  strokeWidth={1.8}
                  animationEasing={"ease-in-out"}
                  animationDuration={5}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  fetching : store.dashboard.fetching,
  fetched:  store.dashboard.fetched,
});
export default connect(mapStateToProps)(LineChartComponent);
