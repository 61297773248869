import React, { Component } from 'react';
import { connect } from 'react-redux';
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import {stateToHTML} from 'draft-js-export-html';

import {setCurrentEmailCampaignData} from 'actions';

const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;
const plugins = [inlineToolbarPlugin];

import 'draft-js-inline-toolbar-plugin/lib/plugin.css';
import _ from 'i18n';

@connect((store) => {
  return {
    testComment: store.currentCampaign.email.testComment
  };
})
export default class InlineToolbarEditor extends Component {

  constructor(props){
    super(props);

    this.state = { editorState: createEditorStateWithText(props.testComment || '')};
  }

  onChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  focus = () => {
    this.editor.focus();
  };

  handleCommentChange = () => {
    this.props.dispatch(setCurrentEmailCampaignData({testComment: stateToHTML(this.state.editorState.getCurrentContent())||''}));
  }

  render() {
    return (
      <div className="inline-editor" onClick={this.focus}>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          placeholder={ _('comment')+' ...'}
          ref={(element) => { this.editor = element; }}
          onBlur={this.handleCommentChange}
        />
        <InlineToolbar />
      </div>
    );
  }
}
