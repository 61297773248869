import React from "react";
import Modal from "react-modal";
import { SVG_SAVE_ICON } from "../../../services/svgIcones";

export const TTPModal = props => {
  const {
    isOpen,
    onCancel,
    onConfirm,
    title,
    inProcess,
    actionFailed,
    labelError = "Error",
    labelNo = "No",
    labelYes = "Yes",
    children
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCancel}
      className={{
        base: "modalContent",
        afterOpen: "modalContentAfterOpen",
        beforeClose: "modalContentBeforeClose"
      }}
      overlayClassName={"modalOverlay"}
      closeTimeoutMS={200}
      ariaHideApp={false}
    >
      <div className="confirm duplicate">
        <div className="header">
          <div className="icon">{SVG_SAVE_ICON}</div>
          <span>{title}</span>
        </div>
        <div className="close-modal" onClick={onCancel}>
          <span className="m-l-xs icomoon icon-tt-close" />
        </div>
        <div className="modal_body">{children}</div>
        <div className="separator" />
        <div className="footer-modal">
          <button className="no" onClick={onCancel}>
            {labelNo}
          </button>

          <button className="yes" onClick={onConfirm}>
            {labelYes}
          </button>

          {inProcess &&
            !actionFailed && (
              <div className="deleting">{/*<IconLoader />*/}</div>
            )}
          {!inProcess &&
            actionFailed && <div className="deleting">{labelError}</div>}
        </div>
      </div>
    </Modal>
  );
};
