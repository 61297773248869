export const showEboxWidget = () => (
  {
    type: 'SHOW_EBOX_WIDGET',
  }
)

export const setEboxFromEmail = email => (
  {
    type: 'SET_EBOX_FROM_EMAIL',
    email
  }
)

export const hideEboxWidget = () => (
  {
    type: 'HIDE_EBOX_WIDGET',
  }
)

export const setEboxSubject = (subject) => (
  {
    type: 'SET_EBOX_SUBJECT',
    subject,
  }
)

export const setEboxMessage = (message) => (
  {
    type: 'SET_EBOX_MESSAGE',
    message,
  }
)

export const setEboxRecipients = (recipients) => (
  {
    type: 'SET_EBOX_RECIPIENTS',
    recipients,
  }
)


export const setEboxWidgetDynamicFields = (fields) => (
  {
    type: 'SET_EBOX_DYNAMIC_FIELDS_WIDGET',
    fields,
  }
)

export const setEboxCc = (cc) => (
  {
    type: 'SET_EBOX_CC',
    cc,
  }
)

export const setEboxReply = (reply) => (
  {
    type: 'SET_EBOX_REPLY',
    reply,
  }
)

export const setEboxWidgetStatus = (status) => (
  {
    type: 'SET_EBOX_WIDGET_STATUS',
    status,
  }
)

export const setTargetType = (targetType) => (
  {
    type: 'SET_EBOX_WIDGET_TARGET_TYPE',
    targetType,
  }
)

export const setTargetFolders = (targetFolders) => (
  {
    type: 'SET_EBOX_WIDGET_TARGET_FOLDERS',
    targetFolders,
  }
)

export const setEboxWidgetAttachments = (attachments) => (
  {
    type: 'SET_EBOX_WIDGET_ATTACHMENTS',
    attachments,
  }
)

export const sendTransactionalEmail = (payload) => (
  {
    type: 'SEND_TRANSACTIONAL_EMAIL',
    payload
  }
)
