import React from "react";
import { IS_EMAIL_VALID_UA } from "../../../../services/svgIcones";

export const NotificationBar = props => {
  const { message } = props;
  return (
    <div
      className={`grid-x align-middle
        ${"notification"}`}
    >
      <div
        className={`col small-11
        ${"message"}`}
      >
        <div className={`m-r-s ${"content"}`}>
          {IS_EMAIL_VALID_UA(true)} <span>{message} </span>
        </div>
      </div>
    </div>
  );
};
