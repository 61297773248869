import React, {Component} from 'react';
import _ from 'i18n';
import ToggleButton from "../ui/ToggleButton";
import {getLabel} from "../../services/utils";
import {LANGUAGE_FLAGS} from "../../config/Common";
import Application from "./Application";

export default class TextModelItemSimple extends Component {

  constructor(props) {
    super(props);
    this.toggleMoreContent = () => this.setState(prevState => ({ showMore: !prevState.showMore }));
    this.state = {
      showMore: false
    }
  }

  toogleTextEboxModel = () => {
    const { id, content, object, client, onApply, name, language, targetApp } = this.props;
    if (onApply) {
      onApply({ language, targetApp, name: name || "", object, text: content, originalTextId: id, scope: client ? "COMMUNITY" : "TAMTAM" });
    }
  }

  render() {
    const {object, language, name, current, type, targetApp, content, id} = this.props;
    const checked = current === id;
    const { showMore } = this.state;

    return (
        <div className={`text-model-ebox ${checked ? "current" : ""}`}>
          <div className="flex-container flex-dir-column">
            <div className="flex-container">
              <div className="text-model-ebox_header">
                <div className="text-model-ebox_header-name">
                  <span> {name ? name : object} </span>
                </div>
                <div className="text-model-ebox_header-type">
                  <span className="info-label"> {_('type')} : </span>
                  <span className="info-value"> {type ? getLabel(type, language) : _('undefined')} </span>
                </div>
                {language && LANGUAGE_FLAGS[language] && <div>
                  <span className="info-label"> {_('language')} : </span>
                  <span className={`language flag-icon ${LANGUAGE_FLAGS[language]} icon-left`}/>
                </div>}
              </div>
              </div>
            <p className="text-model-ebox_header-content"
               style={!showMore ? {overflow: "hidden", height: "3rem"} : {}}>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </p>
          </div>
          {targetApp ? (
            <span className="text-model-ebox__app"  >
                <Application name={targetApp}/>
            </span>
          ) : null}
          <ToggleButton
            cssClass={"apply-icon"}
            onToggleSelection={ this.toogleTextEboxModel }
            checked={checked} />

          {content && content.length >= 110 &&
          <div className="read-more" onClick={this.toggleMoreContent}>
            {_((showMore) ? 'Close' : 'Read more')}
          </div>
          }
        </div>
    )
  }
}



