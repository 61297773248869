import axios from '../axios-api';

export const getIdentitiesVerificationStatus = (token) => {
  const requestUrl = `/mailing/registered-mails`;

  return axios.get(requestUrl, {
    params: {
      access_token: token
    },
  });
};

export const getAppDynamicsFields = (token, appName) => {
  let requestUrl = "";

  if (!["EVENT", "WTB"].includes(appName)) {
    return;
  }
  if (appName === "EVENT") {
    requestUrl = `/event/dynamic-fileds`;
  }
  if (appName === "WTB") {
    requestUrl = `/dir/emailing/dynamic-fields`;
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token
    },
  });
};
