import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import TTPModal from "../ui/TTPModal";
import { EVENT_VISITOR_TYPES } from "constants";
import { PARTNER_TYPES, GUEST_STATUS } from 'Common';
import { modalStyle } from 'utils';

@connect((store) => {
  return {
    filters: store.currentCampaign.email.filters,
    lng: store.params.lng,
  }
})
export default class FiltersModal extends Component {

  getFilterValue = (filter) => {
    const { lng } = this.props;
    const mapper = {
      "visitor type": EVENT_VISITOR_TYPES,
      "Types": PARTNER_TYPES,
      "Included status": GUEST_STATUS,
      "Excluded status": GUEST_STATUS
    }

    let items;
    if (Object.keys(mapper).indexOf(filter.label) > -1) {
      items = mapper[filter.label].filter(type => filter.value.indexOf(type.id + "") > -1)
        .map(({ label, name }) => <span>{_(label || name)}</span>)
    } else {
      items = filter.value.map(key => <span> {(typeof key == 'object') ? key[lng] : key}</span>)
    }

    return (
      <div className="filter__value">
        {items}
      </div>
    )
  }

  render() {
    const { filters, lng, toggleFiltersModal } = this.props;

    const customModalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle.content,
        minHeight: "15rem",
        padding: 0,
      }
    };
    let filtersSection = filters.map((item, index) => {

      return (
        <div key={index} className="filter">
          <span className="filter__label text-capitalize m-r-l">{_(item.label).toUpperCase()} </span>
          {Array.isArray(item.value) ? this.getFilterValue(item)
            :
            <div>
              {item.value[lng] ? (item.value[lng] || item.value['fr'])
                : _(item.value)
              }
            </div>
          }
        </div>
      )
    });

    return (
      <TTPModal isOpen={true} onClose={toggleFiltersModal}
        modalStyle={customModalStyle} title={_('filters')}>
        <div id="list-form" className="p-b-s p-t-s">
          <div className="row align-center">
            <div className="column small-10 campaign_filters">
              {filtersSection}
            </div>
          </div>
        </div>
      </TTPModal>
    )
  }
}
