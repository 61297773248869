import React from 'react';
import Notifications from "./Notifications";
import CreateNotification from './CreateNotification';

const notificationMenu = (props) => {

    return (
        <ul className="menu">
            {props.items.indexOf('NOTIFICATIONS') !== -1 ? <Notifications /> : null}
            {props.items.indexOf('NOTIFICATIONS') !== -1 ? <CreateNotification /> : null}
        </ul>
    )
}

export default notificationMenu;