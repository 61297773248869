import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import Approver from "./Approver";
import { saveApprovalTeam, deleteApprovalTeam } from "thunks";

import _ from "i18n";
import { setTTPDialogCustomData } from "actions";
import { onSuccess, onError, stringToColor } from "utils";
import ApproverFetching from "fetching/ApproverFetching";
import UsersSuggestion from "common/UsersSuggestion";
import DIALOG_TYPES from "../../../constants/TTPDialog";
import { SVG_DELETE_ICON } from "utils";
import NotFound from "notFound/NotFound";
import { SVG_MINUS } from "../../../services/svgIcones";
import TTPSelect from "../../common/TTPSelect";
import { ucFirst } from "../../../services/common";

@connect()
export default class TeamItem extends Component {
  constructor(props) {
    super(props);
    this.state = { minApprovers: 0, members: [] };
  }

  componentDidMount() {
    let { members, minApprovers } = this.props;
    this.setState({ members, minApprovers });
  }

  componentWillReceiveProps(nextProps) {
    let { members, minApprovers } = nextProps;
    this.setState({ members, minApprovers });
  }

  handleMinApproverChange = (minApprovers) => {
    let { members } = this.state;
    if (minApprovers >= 0 && minApprovers <= members.length) {
      this.setState({ minApprovers });
    }
  };

  handleSelectUser = (newApprover) => {
    let { members } = this.state;
    members = members || [];
    let index = -1;
    for (let i = 0; i < members.length; i++) {
      if (members[i].id === newApprover.id) {
        index = i;
        break;
      }
    }
    let newApprovers = members.concat();
    if (index === -1) {
      newApprovers.push(newApprover);
    } else {
      newApprovers[index] = newApprover;
    }
    this.setState({ members: newApprovers });
  };

  renderUsersAutoSuggestion() {
    return <UsersSuggestion onSelectUser={this.handleSelectUser} />;
  }

  deleteTeam = (e) => {
    let { id, dispatch } = this.props;
    dispatch(
      setTTPDialogCustomData({
        approvedAction: () => dispatch(deleteApprovalTeam(id)),
        message: _("Are you sure you want to delete this team ?"),
        proceedBtnLabel: _("yes"),
        abortBtnLabel: _("cancel"),
        title: "Confirmation of deletion",
        type: DIALOG_TYPES["ALERT"],
        svgIcon: SVG_DELETE_ICON,
      }),
    );
    e.stopPropagation();
  };

  editTeam = (e) => {
    this.props.onEdit();
    e.stopPropagation();
  };

  handleDeleteApprover(id) {
    let { members, minApprovers } = this.state;
    let newApprovers = members.filter((element) => element.id !== id);
    if (newApprovers && minApprovers > newApprovers.length) {
      minApprovers = newApprovers.length;
    }
    this.setState({ members: newApprovers, minApprovers });
  }

  renderApprovers() {
    let { members } = this.state;

    if (this.props.fetching) {
      return (
        <div className="row">
          <ApproverFetching />
          <ApproverFetching />
          <ApproverFetching />
        </div>
      );
    }
    if (members && members.length > 0) {
      return (
        <div className="row">
          {members.map((user) => (
            <Approver
              onDeleteApprover={this.handleDeleteApprover.bind(this)}
              {...user}
              key={`user-${user.id}`}
              mainClass="small-4"
            />
          ))}
        </div>
      );
    }
    return (
      <div className="row align-center">
        <NotFound text="no_member_found" />
      </div>
    );
  }

  saveApprovalTeam = () => {
    const { id, name, description } = this.props;
    const { members, minApprovers } = this.state;

    let membersEmailsIds = [];
    membersEmailsIds = members.map((item) => item.id);

    this.props
      .dispatch(
        saveApprovalTeam({
          id,
          name,
          description,
          minApprovers,
          membersEmailsIds,
        }),
      )
      .then(
        (res) => {
          onSuccess(res);
        },
        (err) => onError(err),
      );
  };

  undoChanges = () => {
    let { members, minApprovers, toggleTeamDetail } = this.props;
    this.setState({ members, minApprovers });
    toggleTeamDetail();
  };

  renderMembersDetail() {
    const { opened, name, members, deleteState } = this.props;
    let initial = name.substring(0, 1);

    if (!opened) {
      return (
        <div className="small-3 team-header--members">
          {!members || members.length == 0 ? (
            <div>
              <span>
                <i
                  className="icon icon-user-follow initials-color"
                  data-initials={initial}
                  hidden={deleteState}
                ></i>
              </span>
            </div>
          ) : (
            <div className="team-header--members-avatars">
              {members.length > 5 && (
                <div className="small-avatar empty-avatar">
                  <span>+{members.length - 5}</span>
                </div>
              )}
              {members.map((member, i) => {
                let { user } = member;
                return i > 4 ? (
                  ""
                ) : (
                  <div
                    key={`member${i}`}
                    data-for={"member" + i}
                    data-tip={user.firstName + " " + user.lastName}
                  >
                    <ReactTooltip
                      id={"member" + i}
                      type="dark"
                      place="bottom"
                    />
                    <Approver
                      {...member}
                      avatarClass="small-avatar"
                      avatarOnly={true}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <span
            className={`label_delete`}
            hidden={!deleteState}
            onClick={this.deleteTeam}
          >
            {SVG_MINUS}
            {_("delete")}
          </span>
        </div>
      );
    }

    return (
      <div className="small-4 team-header--members ttp-small-buttons">
        <button onClick={this.undoChanges} className="cancel">
          {_("cancel")}
        </button>
        <button onClick={this.saveApprovalTeam} className="success">
          {_("save")}
        </button>
      </div>
    );
  }

  render() {
    const { minApprovers } = this.state;
    const {
      id,
      name,
      description,
      toggleTeamDetail,
      opened,
      deleteState,
      members,
      isDefault,
      setDefaultTeamId,
    } = this.props;

    let initial = name.substring(0, 1);
    let minApproversOptions = [{ label: "1", id: 1 }];
    for (let i = 2; i <= members.length; i++) {
      minApproversOptions.push({ label: i, id: i });
    }

    return (
      <div
        key={this.props.key}
        id={`team-${id}`}
        className={`team-list-item ${opened && "team-list-item--open"}`}
      >
        <div
          className={`row align-middle team-header ${isDefault && "selected"} ${deleteState && "delete"}`}
          onClick={deleteState ? this.deleteTeam : toggleTeamDetail}
        >
          <div className="small-1">
            <span
              className="team-avatar"
              style={{ background: stringToColor(name) }}
            >
              {initial}
            </span>
          </div>
          <div className="small-3">
            <span className="team-list-item__name">
              {name} &nbsp;
              {!deleteState && (
                <span onClick={this.editTeam}>
                  <i className="icon icon-pencil" />
                </span>
              )}
            </span>
            <p>{description}</p>
          </div>
          <div className="small-3">
            <span className="team-list-item__details">
              {_("approval_threshold")} : <span>{this.props.minApprovers}</span>
            </span>
            <br />
            <span className="team-list-item__details">
              {ucFirst(_("number of approvers"))} :&nbsp;
              <span>{this.props.members.length}</span>
            </span>
          </div>
          {this.renderMembersDetail()}
          <div
            onClick={(e) => e.stopPropagation()}
            className={`${opened ? "small-1" : "small-2"} team-list-item__default-team`}
            style={{ paddingRight: !opened && "30px" }}
          >
            <label
              className={`team-list-item__default-team-label ${isDefault && "selected"} `}
            >
              {_("principal")}
            </label>
            <input
              type="radio"
              name="defaultTeam"
              defaultChecked={isDefault}
              onChange={() => setDefaultTeamId(id)}
            />
          </div>
        </div>
        {opened && (
          <div className="team-setting row align-middle">
            <div className="column small-8">
              <span className="param-label">1 - {_("members")}</span>
            </div>
            <div className="column small-4 approver-user user-suggestion">
              <span className="approver-user__plus">
                <i className="icon icon-user-follow" />
              </span>
              {this.renderUsersAutoSuggestion()}
            </div>
            <div className="column small-12">{this.renderApprovers()}</div>
            <div className="column small-10">
              <span className="param-label">
                2 - {_("the approval threshold")}
              </span>
              <div className="setting-param">
                <p className="setting-param__detail">
                  {_("minimum_acceptance_paragraph")}
                </p>
                <TTPSelect
                  simple={true}
                  notClearable={true}
                  placeholder={_("1")}
                  values={minApprovers}
                  options={minApproversOptions}
                  onChange={this.handleMinApproverChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
