import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "uuid";

import TeamItem from "./TeamItem";
import NotFound from "notFound/NotFound";

import _ from "i18n";
import { setApprovalTeamsPaginationPage } from "actions";
import { fetchApprovalTeams } from "thunks";
import TeamsFetching from "fetching/TeamsFetching";
import InfiniteScroll from "react-infinite-scroller";

@connect((store) => {
  return {
    teams: store.approval.teams.items,
    fetching: store.approval.teams.fetching,
    nbResult: store.approval.teams.nbResult,
    paginationPage: store.approval.teams.paginationPage,
  };
})
export default class Teams extends Component {
  state = {
    currentTeam: null,
    hasMoreTeams: true,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.fetching != this.props.fetching) {
      this.setState({ hasMoreTeams: !nextProps.fetching });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.paginationPage !== this.props.paginationPage) {
      this.props.dispatch(fetchApprovalTeams());
    }
  }

  loadTeams = () => {
    const { paginationPage, dispatch, fetching, nbResult, teams } = this.props;

    if (!fetching && teams.length < nbResult) {
      this.setState({ hasMoreTeams: false });

      dispatch(setApprovalTeamsPaginationPage(paginationPage + 1));
    }
  };

  handleCurrentTeamChange = (team) => {
    let currentTeam = this.state.currentTeam == team ? null : team;
    this.setState({ currentTeam });
    if (currentTeam) {
      document.getElementById("team-" + currentTeam).scrollIntoView(true);
    }
  };

  render() {
    const { teams, fetching, onEdit, deleteState, setDefaultTeamId } = this.props;
    const { currentTeam, hasMoreTeams } = this.state;
    let items = [];

    if (teams && teams.length > 0) {
      teams.forEach((team) =>
        items.push(
          <TeamItem
            key={`team-${uuid()}`}
            toggleTeamDetail={() => this.handleCurrentTeamChange(team.id)}
            opened={currentTeam == team.id}
            {...team}
            onEdit={() => onEdit(team)}
            deleteState={deleteState}
            setDefaultTeamId={setDefaultTeamId}
          />,
        ),
      );
    }

    if (fetching) {
      items.push(
        <TeamsFetching key="theme-3" />,
        <TeamsFetching key="theme-1" />,
        <TeamsFetching key="theme-2" />,
      );
    }

    return items.length > 0 ? (
      <InfiniteScroll
        className="team-list"
        pageStart={0}
        loadMore={this.loadTeams}
        hasMore={hasMoreTeams}
      >
        <div>
          <div className="row small-8 team-list">{items}</div>
        </div>
      </InfiniteScroll>
    ) : (
      <NotFound mainClass="small-10" text={_("Oops no approval team found")} />
    );
  }
}
