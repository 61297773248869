import React, { Component } from 'react';
import { connect } from 'react-redux';

import Switch from 'common/Switch';
import SelectLanguages from 'common/SelectLanguages';
import ImageUploader from './ImageUploader';
import ImagesLibrary from './ImagesLibrary';
import { setStep, setTTPDialogCustomData, setCurrentNavPage, initMedias } from "actions";
import { fetchTemplates, fetchMedias, saveTheme, fetchTemplatesCategories } from 'thunks';
import _ from 'i18n';
import { STEPS } from 'Common';
import { onSuccess, onError } from 'utils';
import DIALOG_TYPES from '../../constants/TTPDialog';
import cheerio from 'cheerio';
import { S3_FOLDER_URL_PROD } from 'Config';
import TTPSelect from "../common/TTPSelect";

@connect((store) => {
  return {
    themes: store.themes.list.items,
    currentTheme: store.themes.currentSimpleTheme,
    categories: store.themes.categories.items,
    lng: store.auth.user.language,
    fetched: store.themes.categories.fetched
  }
})
export default class ThemeEditor extends Component {

  constructor(props) {
    super(props);
    this.handleActivePaletteChange = (activePalette) => this.setState({
      activePalette: (this.state.activePalette == activePalette) ? null : activePalette
    });
    this.handleNameChange = (e) => this.setState({ name: e.target.value });
    this.handleLanguageChange = (e) => this.setState({ language: e.target.value });
    this.handleCategoryChange = (themeCategory) => this.setState({ themeCategory });
    this.state = {
      name: '',
      allowDeletion: false,
      allowChange: false,
      language: 'fr',
      templateId: null,
      themeContent: null,
      originalTheme: '',
      selectedImg: null,
      activePalette: null,
      changed: false,
      images: [],
      type: "",
      themeCategory: null,
    };
  }

  componentDidMount() {
    const { dispatch, match: { params: routeParams }, currentTheme } = this.props;
    dispatch(setCurrentNavPage("SETTINGS"));
    dispatch(setStep(STEPS['MANAGE_THEME']));
    dispatch(initMedias());
    const id = routeParams.templateId;
    if (id && !currentTheme) {
      this.setState({ templateId: id });
      dispatch(fetchTemplates({ id }));

    } else if (currentTheme) {
      this.loadTheme(currentTheme);
    }
    dispatch(fetchMedias());
    document.body.classList.add("bg-d8");
    dispatch(fetchTemplatesCategories())
  }

  componentDidUpdate(prevProps) {
    if (this.props == prevProps) {
      return;
    }
    let { themes, currentTheme } = this.props;
    let { templateId } = this.state;
    let theme = (templateId && themes && themes[0] && templateId == themes[0].id) ? themes[0] : currentTheme;
    if (theme) {
      this.loadTheme(theme);
    }
  }

  loadTheme = (theme) => {
    let { content, name, language, type, images, themeCategory } = theme;
    const $ = cheerio.load(content);
    let themeImages = images || [];
    $("img").each((i, image) => {
      themeImages.push($(image).attr('src'));
    });
    this.setState({ originalTheme: content, name, language, themeContent: $("body").html(), images: themeImages, type, themeCategory });
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-d8");
    this.close();
  }

  handlePreviewClick(event) {
    const target = event.target;
    if (target.nodeName !== "IMG") {
      return false;
    }
    let { selectedImg } = this.state;
    if (selectedImg) {
      selectedImg.classList.remove('img-selected');
    }
    if (target !== selectedImg) {
      target.classList.add('img-selected');
    }
    selectedImg = (target === selectedImg) ? null : target;
    let allowDeletion = selectedImg && selectedImg.classList.contains('ttp-deletable');
    let allowChange = selectedImg && selectedImg.classList.contains('ttp-alterable');
    this.setState({ selectedImg, activePalette: (selectedImg != null) ? 1 : null, allowDeletion, allowChange });
  }

  closeThemeManager() {
    const { dispatch } = this.props
    if (!this.state.changed) {
      this.close();
      return;
    }
    dispatch(setTTPDialogCustomData({
      'approvedAction': this.close.bind(this),
      'message': _('Once you close your changes will be lost. Do you really want to continue?'),
      'type': DIALOG_TYPES["ALERT"],
    }));
  }

  close() {
    const { dispatch, history } = this.props;
    dispatch(setStep(null));
    window.parent.postMessage('CLOSE_VIEWER', "*");
    history.push('/templates');
  }

  removeImages() {
    let { selectedImg } = this.state;
    if (!selectedImg) {
      return;
    }
    selectedImg.parentNode.removeChild(selectedImg);
    this.setState({ selectedImg: null, changed: true });
  }

  reset() {
    let themeContent = this.state.originalTheme;
    let bodyHtml = /<body.*?>([\s\S]*)<\/body>/.exec(themeContent);
    if (bodyHtml && bodyHtml[1]) {
      themeContent = bodyHtml[1];
    }
    this.setState({ changed: false, themeContent });
    document.getElementById('theme-manager__preview').innerHTML = themeContent;
  }

  applyDeletionRule(isChecked) {
    let { selectedImg } = this.state;
    if (!selectedImg) {
      return;
    }
    selectedImg.classList.toggle('ttp-deletable', isChecked);
    this.setState({ changed: true, allowDeletion: isChecked });
  }

  applyChangeRule(isChecked) {
    let { selectedImg } = this.state;
    if (!selectedImg) {
      return;
    }
    selectedImg.classList.toggle('ttp-alterable', isChecked);
    this.setState({ changed: true, allowChange: isChecked });
  }

  renderThemeImages() {
    let { allowDeletion, allowChange } = this.state;

    return <div>
      <div className="apply-all">
        <div className="apply-all-item">
          <h3>{_('allow deletion')}</h3>
          <Switch name="can-remove" keyAttr="can-remove" isChecked={allowDeletion} onChange={this.applyDeletionRule.bind(this)} />
        </div>
        <div className="apply-all-item"><h3>{_('allow change')}</h3>
          <Switch name="can-change" keyAttr="can-change" isChecked={allowChange} onChange={this.applyChangeRule.bind(this)} />
        </div>
      </div>
      {this.renderImagesLibrary(true)}
    </div>;
  }

  renderImagesLibrary(initialOnly = false) {
    let { images, selectedImg } = this.state;

    return <ImagesLibrary initialOnly={initialOnly}
      initialImages={images}
      selectedImg={selectedImg}
      onImageChange={() => this.setState({ selectedImg: null, changed: true })}
    />
  }

  saveTheme() {
    let { name, language, images, themeCategory } = this.state;
    const { match: { params: routeParams } } = this.props;
    let id = routeParams.templateId;
    if (!name || name.length < 2) {
      document.getElementById('tmpl-name').classList.add('border-danger');
      return;
    }
    let content = document.getElementById('theme-manager__preview').innerHTML.replace('img-selected', '');
    let html = /<html.*?>([\s\S]*)<body.*>/.exec(this.state.originalTheme);
    if (html && html[0]) {
      content = `${html[0]}${content}</body></html>`;
    }
    let data = { name, language, content, images, themeCategory };
    data['id'] = id;
    this.props.dispatch(saveTheme(data))
      .then(res => { onSuccess(res); this.close() },
        err => onError(err)
      );
  }

  getCategories = () => {
    const { lng, categories, fetched } = this.props;
    let lang = lng.charAt(0).toUpperCase() + lng.slice(1).toLowerCase();
    return categories.map(category => {
      let label = category[`name${lang}`] || category['nameFr'];
      return { id: category.id, label };
    })
  };

  render() {
    const { themeContent, language, name, activePalette, type, themeCategory } = this.state;
    return <div id="theme-manager">
      <div className="row">
        <div className="row column small-6">
          <div id="style-tools-menu" className={`column small-7`}>
            <div id="style-tools-menu-logo">
              <img src={`${S3_FOLDER_URL_PROD}/apps/emailing.png`} />
              <h3>EMAILING</h3>
            </div>
            <ul>
              <li className="menu-item" onClick={this.reset.bind(this)}><i className="icon icon-reload"></i>{_('reset')}</li>
              {type == "replication" && <li className="menu-item" onClick={this.removeImages.bind(this)}><i className="icon icon-trash"></i>{_('delete')}</li>}
              <li className={`menu-item ${activePalette == 1 ? "menu-item-selected" : ""}`} onClick={() => this.handleActivePaletteChange(1)}><i className="icon icon-picture"></i>{_('Images used')}</li>
              <li className={`menu-item ${activePalette == 2 ? "menu-item-selected" : ""}`} onClick={() => this.handleActivePaletteChange(2)}><i className="icon icon-folder-alt"></i>{_('Images available')}</li>
              <li className={`menu-item ${activePalette == 3 ? "menu-item-selected" : ""}`} onClick={() => this.handleActivePaletteChange(3)}><i className="icon icon-cloud-upload"></i>{_('upload')}</li>
            </ul>
            <div id="style-tools-menu-controls">
              <div className="tmpl-form">
                <input id="tmpl-name" type="text" value={name} placeholder="Template name *"
                  onChange={this.handleNameChange}
                  onFocus={e => e.target.classList.remove('border-danger')} />
                <SelectLanguages all={true} language={language} onLanguageChange={this.handleLanguageChange} />
                <TTPSelect
                  simple={true}
                  notClearable={true}
                  values={themeCategory}
                  options={this.getCategoriesOptions() || [] }
                  onChange={this.handleCategoryChange.bind(this)} />
              </div>
              <button className='theme-btn-action' onClick={this.saveTheme.bind(this)}>{_('save')}</button>
              <button className="theme-btn-action cancel" onClick={this.closeThemeManager.bind(this)}>{_('close')}</button>
            </div>
          </div>
          <div className={`style-tools-palette column small-7 ${(activePalette == 1) ? "" : "start"} add-bloc-overflow-y`}>
            {(activePalette == 1) && this.renderThemeImages()}
          </div>
          <div className={`${(activePalette == 2) ? "" : "start"} `}>
            {(activePalette == 2) && this.renderImagesLibrary()}
          </div>
          <ImageUploader isActive={activePalette == 3} closeUploadForm={() => this.handleActivePaletteChange(null)} />}
      </div>
        <div className="column small-6">
          {themeContent && <div id="theme-manager__preview" onClick={this.handlePreviewClick.bind(this)} contentEditable="true" dangerouslySetInnerHTML={{__html: themeContent}}/>}
        </div>
      </div>
    </div>;
  }
}
