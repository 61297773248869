export * from "./speaker";
export * from "./slot";

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  nbResult: 0,
};
export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_GROUPS_PENDING": {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case "FETCH_GROUPS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
    }
    case "FETCH_GROUPS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }
    default:
      return state;
  }
};

const filtersInitialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};

export const eventsReducer = (state = filtersInitialState, action) => {
  switch (action.type) {
    case "FETCH_EVENTS_PENDING": {
      return {
        ...state,
        fetching: true,
      };
      break;
    }
    case "FETCH_EVENTS_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
      break;
    }
    case "FETCH_EVENTS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
      break;
    }
    default:
      return state;
  }
};

export const eventCyclesReducer = (state = filtersInitialState, action) => {
  switch (action.type) {
    case "FETCH_EVENT_CYCLES_PENDING": {
      return {
        ...state,
        fetching: true,
      };
      break;
    }
    case "FETCH_EVENT_CYCLES_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
      break;
    }
    case "FETCH_EVENT_CYCLES_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
      break;
    }
    default:
      return state;
  }
};

export const activitiesReducer = (state = filtersInitialState, action) => {
  switch (action.type) {
    case "FETCH_ACTIVITIES_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
      break;
    }
    case "FETCH_ACTIVITIES_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
      break;
    }
    default:
      return state;
  }
};

export const optionsReducer = (state = filtersInitialState, action) => {
  switch (action.type) {
    case "FETCH_OPTIONS_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
      break;
    }
    case "FETCH_OPTIONS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
      break;
    }
    default:
      return state;
  }
};

export const formulasReducer = (state = filtersInitialState, action) => {
  switch (action.type) {
    case "FETCH_FORMULAS_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult,
      };
      break;
    }
    case "FETCH_FORMULAS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
      break;
    }
    default:
      return state;
  }
};

const initialStateForCouponModels = {
  items: [],
  error: null,
  nbResult: 0,
};

export const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CONTACT_FOLDERS_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        items: data,
        error: null,
        nbResult,
      };
    }
    case "FETCH_CONTACT_FOLDERS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        items: [],
        error,
      };
      break;
    }

    default:
      return state;
  }
};

export const couponModelReducer = (
  state = initialStateForCouponModels,
  action,
) => {
  switch (action.type) {
    case "FETCH_COUPON_MODEL_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        items: data,
        error: null,
        nbResult,
      };
      break;
    }
    case "FETCH_COUPON_MODEL_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        items: [],
        error,
      };
      break;
    }
    default:
      return state;
  }
};

export const foldersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CLIENT_FOLDERS_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        items: data,
        error: null,
        nbResult,
      };
    }
    case "FETCH_CLIENT_FOLDERS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        items: [],
        error,
      };
      break;
    }
    case "FETCH_MY_CLIENT_FOLDERS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      return {
        items: data.folders,
        error: null,
        nbResult,
      };
    }
    case "FETCH_MY_CLIENT_FOLDERS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        items: [],
        error,
      };
      break;
    }
    default:
      return state;
  }
};

export const cupboardReducer = (state = filtersInitialState, action) => {
  switch (action.type) {
    case "FETCH_CUPBOARD_PENDING": {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case "FETCH_CUPBOARD_FULFILLED": {
      const { data } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
      };
    }
    case "FETCH_CUPBOARD_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }
    default:
      return state;
  }
};

const externalTypeInitialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};
export const externalTypeReducer = (state = externalTypeInitialState, action) => {
  switch (action.type) {
    case "FETCH_EXTERNAL_TYPE_PENDING": {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case "FETCH_EXTERNAL_TYPE_FULFILLED": {
      const { data } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
      };
    }
    case "FETCH_EXTERNAL_TYPE_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }
    default:
      return state;
  }
};
const professionInitialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};
export const professionReducer = (state = externalTypeInitialState, action) => {
  switch (action.type) {
    case "FETCH_PROFESSION_PENDING": {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case "FETCH_PROFESSION_FULFILLED": {
      const { data } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
      };
    }
    case "FETCH_PROFESSION_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }
    default:
      return state;
  }
};
