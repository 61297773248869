import React from 'react';
import _ from 'i18n';

export const INVOICE_REMINDER = [
  "INVOICE_RAPPEL1",
  "INVOICE_RAPPEL2",
  "INVOICE_RAPPEL3",
  "INVOICE_RAPPEL4",
]

export const ORDER_REMINDER = [
  "BDC_RAPPEL1",
  "BDC_RAPPEL2",
  "BDC_RAPPEL3",
  "BDC_RAPPEL4",
]

export const CREDIT_NOTE_REMINDER = [
  "NDC_RAPPEL1",
  "NDC_RAPPEL2",
  "NDC_RAPPEL3",
  "NDC_RAPPEL4",
]

export const getPaymentDocumentTypes = () => {
  return [
    {
      label: _('order'),
      options: [...formatOptions(ORDER_REMINDER, 'order')],
    },
    {
      label: _('invoice'),
      options: [...formatOptions(INVOICE_REMINDER, 'invoice')],
    },
    {
      label: _('credit_note'),
      options: [...formatOptions(CREDIT_NOTE_REMINDER, 'credit_note')]
    }
  ]
}

const formatOptions = (options, discriminant) => {
  return options.map((value, index) => (
    {
      value,
      label: (
        <div className="payment-type__option">
          <span className="m-r-xs">{_(discriminant)}:</span>
          <span>
            {_('reminder')}
            <i className={discriminant}>
              {index + 1}
            </i>
          </span>
        </div>
      ),
      discriminant,
      rappelType: index + 1
    }
  ))
}

export const resolvePaymentType = (discriminant, rappelType) => {
  if (!discriminant || !rappelType) {
    return null;
  }

  const selectedOption = getPaymentDocumentTypes().reduce((acc, { options }) => {
    return [...acc, ...options];
  }, [])
    .find(option => (option.discriminant == discriminant &&
      option.rappelType == rappelType));

  return selectedOption?.value;
}
