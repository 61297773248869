import React from 'react';
import SMSBills from "./SMSBills";
import CampaignsSMS from "./CampaignsSMS";
import CreateCampaignSMS from "./CreateCampaignSMS";

const smsMenu = (props) => {
    const { items, allowCreation } = props;

    return (
        <ul className="menu">
            {allowCreation ? <CreateCampaignSMS /> : null}
            {items.indexOf('CAMPAIGN_SMS') !== -1 ? <CampaignsSMS /> : null}
            {items.indexOf('SMS_BILLS') !== -1 ? <SMSBills /> : null}
        </ul>
    )
}

export default smsMenu;
