import React, { Component } from 'react';
import ActionsCell from './datatable/ActionsCell';
import SortHeaderCell from './datatable/SortHeaderCell';
import NotFound from 'notFound/NotFound';
import { connect } from 'react-redux';
import _ from 'i18n';
import { amountFormatter } from "utils";
import { SMSBillsDatatableFetching } from '../../fetching/DatatableFetching';
import SMSBillDetail from './SMSBillDetail';

@connect((store) => {
    return {
        smsBills: store.smsBills.list,
        smsBillsError: store.smsBills.list.error,
        token: store.auth.token,
        updated: store.smsBills.list.updated,
        updating: store.smsBills.list.updating,
        fetching: store.smsBills.list.fetching,
        language: store.params.lng,
    };
})

export default class SMSBillsList extends Component {

    state = {
        showDetail: false,
        billId: null
    }

    shouldComponentUpdate(nextProps, nextState) {
        let { smsBills } = nextProps;

        window.dispatchEvent(new Event('resize'));
        // Don't update component during ajax fetch new data process
        let condition1 = !smsBills.fetching && smsBills.fetched;
        // Result not found
        let condition2 = !smsBills.items.length || !smsBills.error;

        return condition1 || condition2;
    };

    closeModal = () => {
        this.setState({ showDetail: false });
    }

    showDetail = (billId) => {
        this.setState({ showDetail: true, billId });
    }

    render() {
        let { smsBills, smsBillsError, language, fetching } = this.props;
        const { showDetail, billId } = this.state;

        if (fetching) {
            return <SMSBillsDatatableFetching />
        }

        if (smsBillsError != null && smsBillsError.code == 404) {
            return <NotFound />;
        }
        return (
            <div className="row align-center columns">
                <div className="ttp-datatable small-12 small-centered columns list__datatable">
                    <div className="row ttp-datatable__header">
                        <div className="small-2">
                            <SortHeaderCell columnKey="updatedAt"> {_('updatedAt')}</SortHeaderCell>
                        </div>
                        <div className="small-1">{_('Type')}</div>
                        <div className="small-3">{_('campaignName')}</div>
                        <div className="small-2">{_('Credit')}/{_('Cost')}</div>
                        <div className="small-2">{_('Balance')}</div>
                        <div className="small-2">{_('status')}</div>
                    </div>
                    {
                        smsBills.items.map(item => <div key={`bill-${item.id}`} style={item.type === 'CREDIT' ? { cursor: 'unset' } : { cursor: 'pointer' }} className="row ttp-datatable__row">
                            <div className="small-2">{item.updatedAt}</div>
                            <div className="small-1">{_(item.type)}</div>
                            <div className="small-3"> {(item.type == 'DEBIT' && item.campaign) ? item.campaign['name'] : '-'}</div>
                            <div className="small-2"> {amountFormatter(item.amount, 2, language)}</div>
                            <div className="small-2"> {(!item.currentBalance || item.currentBalance === 0) ? '-' : amountFormatter(item.currentBalance, 2, language)}</div>
                            <ActionsCell item={item} onShowDetail={this.showDetail} />
                        </div>)}
                </div>
                {showDetail && billId && <SMSBillDetail onCloseModal={this.closeModal} id={billId} />}
            </div>)
    }
};
