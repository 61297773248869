import NavPages from 'NavPages';
import { HORIZONTAL } from 'constants';

// Language reducer
export const languageReducer = (state = 'fr', action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      return action.language;
    }
    default:
      return state;
  }
};

// SubMenuDirection reducer
export const subMenuDirectionReducer = (state = HORIZONTAL, action) => {
  switch (action.type) {
    case 'SET_SUB_MENU_DIRECTION': {
      return action.subMenuDirection;
    }
    default:
      return state;
  }
};

// Current Navigation Page Reducer
export const currentNavPageReducer = (state = NavPages.HOME_PAGE, action) => {
  switch (action.type) {
    case 'SET_CURRENT_NAV_PAGE': {
      return action.currentNavPage;
    }
    default:
      return state;
  }
};

//SourceToken Reducers
export const sourcesTokensReducer = (state = [], action) => {
  switch (action.type) {
    case 'PUSH_SOURCE_TOKEN': {
      let newItem = { sourceName: action.sourceName, sourceToken: action.sourceToken };
      let filteredItem = state.filter(
        item => item.sourceName === action.sourceName,
      );
      let mappedItems = state.map(
        item => item.sourceName === action.sourceName ? newItem : item,
      );
      let tab = [...state, newItem];
      return filteredItem.length > 0 ? mappedItems : tab;
    }
    case 'CLEAR_SOURCES_TOKENS': {
      return [];
    }
    default:
      return state;
  }
};

const initialStateForTTPDialogReducer = {
  showDialog: false,
  customData: [],
}
export const dialogReducer = (state = initialStateForTTPDialogReducer, action) => {
  switch (action.type) {
    case 'SET_TTP_DIALOG_SHOW': {
      return { ...state, showDialog: action.showDialog };
    }
    case 'SET_TTP_DIALOG_CUSTOM_DATA': {
      return { ...state, customData: action.customData, showDialog: true };
    }
    default:
      return state;
  }
};

export const stepReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_STEP': {
      return action.step;
    }
    default:
      return state;
  }
};

export const setApplication = (state = null, action) => {
  switch (action.type) {
    case "SET_APPLICATION": {
      return action.application || "EMAILING";
    }
    default:
      return state;
  }
};

const initialStateForSesEmails = {
  items: [],
  fetched: false,
  fetching: false
};

export const sesEmailsReducer = (state = initialStateForSesEmails, action) => {
  switch (action.type) {
    case 'FETCH_SES_EMAILS_STATUSES_PENDING': {
      return {
        ...state,
        fetching: true,
      }
    }
    case 'FETCH_SES_EMAILS_STATUSES_FULFILLED': {
      const { result, data } = action.payload.data;
      let items = [];
      if (result == "OK") {
        items = data;
      }
      return {
        ...state,
        items,
        fetching: false,
        fetched: true,
      };
    }
    case 'FETCH_SES_EMAILS_STATUSES_REJECTED': {
      return {
        ...state,
        fetching: false,
        fetched: false
      }
    }
    default:
      return state;
  }
}

export const setWindowHeight = (state = null, action) => {
  switch (action.type) {
    case "SET_WINDOW_HEIGHT": {
      return action.windowHeight || 720;
    }
    default:
      return state;
  }
};

const initialStateForDynamicsFields = {
  appName: null,
  event: {
    list: [],
    fetching: false,
    fetched: false,
  },
  wtb: {
    list: [],
    fetching: false,
    fetched: false,
  },
  updating: false
};

export const dynamicsFieldsReducer = (state = initialStateForDynamicsFields, action) => {
  switch (action.type) {
    case "SET_APP_NAME": {
      return { ...state, appName: action.appName };
    }
    case "FETCH_APP_DYNAMICS_FIELDS_PENDING": {
      let newState = { ...state, updating: true };
      if (state.appName) {
        newState[state.appName.toLowerCase()] = { fetching: true, fetched: false };
      }

      return newState;
    }
    case "FETCH_APP_DYNAMICS_FIELDS_FULFILLED": {
      const items = action.payload.data.data || [];
      const appName = action.payload.appName;
      let list = {};
      for (let type in items) {
        list[type] = Object.values(items[type]);
      }
      let newState = { ...state, updating: false };
      if (appName) {
        newState[appName.toLowerCase()] = { fetching: false, fetched: true, list };
      }

      return newState;
    }
    case "FETCH_APP_DYNAMICS_FIELDS_REJECTED": {
      let newState = { ...state, updating: false };
      if (state.appName) {
        newState[state.appName.toLowerCase()] = { fetching: false, fetched: true, list: [] };
      }
      return newState;
    }
    case "SET_EDITOR_MODAL": {
      return { ...state, modalOpened: action.modalOpened };
    }

    default:
      return state;
  }
};

