import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import { SVG_DELETE_ICON, SVG_VALID_ICON, SVG_BACK_ICON, SVG_EDIT_ICON, onError, onSuccess } from 'utils';

import { editThemeCategory } from 'thunks';
import { LANGUAGE_FLAGS } from 'Common';
@connect()
export default class ThemeCategory extends Component {
  constructor(props) {
    super(props);
    this.onInputchangeFr = this.onInputchangeFr.bind(this);
    this.onInputchangeEn = this.onInputchangeEn.bind(this);
    this.onInputchangeNl = this.onInputchangeNl.bind(this);
    this.state = {
      categoryNameFr: props.nameFr,
      categoryNameEn: props.nameEn,
      categoryNameNl: props.nameNl,
      showEditBtn: false,
      handled: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { categoryNameFr, categoryNameEn, categoryNameNl, handled, showEditBtn } = this.state;
    const changed = prevProps.nameFr != categoryNameFr || prevProps.nameEn != categoryNameEn || prevProps.nameNl != categoryNameNl;;
    if (changed !== showEditBtn && handled) {
        this.setState({ showEditBtn: changed });
    }
    if (!changed && handled) {
      this.setState({ handled: false });
    }
  }

  onInputchangeFr(event) {
    this.setState({
      categoryNameFr: event.target.value,
      handled: true 
    });
  }

  onInputchangeEn(event) {
    this.setState({
      categoryNameEn: event.target.value,
      handled: true 
    });
  }

  onInputchangeNl(event) {
    this.setState({
      categoryNameNl: event.target.value,
      handled: true 
    });
  }

  cancelEditThemeCategory = () => {
    this.setState({
      categoryNameFr: this.props.nameFr,
      categoryNameEn: this.props.nameEn,
      categoryNameNl: this.props.nameNl,
    })
  }

  handleEditThemeCategory = (id) => {
    const { dispatch, toogleDetails } = this.props;
    const { categoryNameFr, categoryNameEn, categoryNameNl } = this.state;
    dispatch(editThemeCategory({id,nameFr:categoryNameFr,nameEn:categoryNameEn,nameNl:categoryNameNl})).then((res) => { onSuccess(res); toogleDetails(); this.setState({ handled: false })}, err => onError(err));
  };

  render() {
    const { id, count, handleDeleteThemeCategory, toogleDetails, showDetails, expandedId } = this.props;
    const { categoryNameFr, categoryNameEn, categoryNameNl, showEditBtn, handled } = this.state;

      return <div key={id} className="columns medium-4 small-6">
        <div className={`theme-category ${count == 0 ? "notheme" : ""}`}>
        <div className="">{_(categoryNameFr)}</div>
          {handled && showEditBtn && <div className="edit-category">
            <span className="cancel-btn" onClick={() => this.cancelEditThemeCategory()}>{SVG_BACK_ICON}</span>
            <span onClick={() => this.handleEditThemeCategory(id)}>{SVG_VALID_ICON}</span>
          </div>}
          {!handled && count == 0 && <div className="delete-category" onClick={() => handleDeleteThemeCategory(id)}>{SVG_DELETE_ICON}</div>}
          {!handled && <div className="badge">{count}</div>}
          <span className="see-more" onClick={() => toogleDetails(id)}>
            <i className={`icon ${showDetails && expandedId == id ? 'icon-arrow-up' : 'icon-arrow-down'}`}></i>
          </span>
          {showDetails && expandedId == id && <div className="categories-inputs">
          <div className="columns small-12">
            <span className={`flag-icon  ${LANGUAGE_FLAGS["fr"]}`} ></span> 
            <input type="text" value={_(categoryNameFr)} onChange={this.onInputchangeFr} placeholder={_('title') +" "+_('french')} />
            {SVG_EDIT_ICON}
          </div>
          <div className="columns small-12">
            <span className={`flag-icon  ${LANGUAGE_FLAGS["en"]}`} ></span> 
            <input type="text" value={_(categoryNameEn)} onChange={this.onInputchangeEn} placeholder={_('title') +" "+_('english')} />
            {SVG_EDIT_ICON}
          </div>
          <div className="columns small-12">
            <span className={`flag-icon  ${LANGUAGE_FLAGS["nl"]}`} ></span> 
            <input type="text" value={_(categoryNameNl)} onChange={this.onInputchangeNl} placeholder={_('title') +" "+ _('dutch')} />
            {SVG_EDIT_ICON}
          </div>
          </div>}
        </div>
      </div>
  }
}