import React, { Component } from 'react';
import { connect } from 'react-redux';

import NotFound from 'notFound/NotFound';
import PageHeader from 'common/PageHeader';
import TTPPaginator from 'common/list/TTPPaginator';
import CampaignList from 'campaign/list/CampaignList';
import CampaignListFilter from 'campaign/list/CampaignListFilter';
import { setCurrentNavPage, setEmailPaginationPage, initCampaignListFilter, initCampaignListItems } from "actions";
import { fetchCampaigns } from 'thunks';

import _ from 'i18n';

@connect((store) => {
  return {
    campaigns: store.campaigns.list,
    auth: store.auth,
    paginationPage: store.campaigns.list.emailPaginationPage,
    pageSize: store.campaigns.list.emailPageSize,
    filter: store.campaigns.list.filter,
    currentNavPage: store.params.currentNavPage,
  }
})
export default class CampaignListPage extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setCurrentNavPage("CAMPAIGN_LIST"));
    dispatch(fetchCampaigns());
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, filter, paginationPage, pageSize, currentNavPage } = prevProps;
    if (filter !== this.props.filter || paginationPage !== this.props.paginationPage ||
      pageSize !== this.props.pageSize) {
      dispatch(fetchCampaigns());
    }

    if (currentNavPage !== "CREATE_CAMPAIGN" && currentNavPage !== this.props.currentNavPage) {
      dispatch(initCampaignListFilter());
      dispatch(initCampaignListItems());
      dispatch(fetchCampaigns());
    }
  }

  handleCampaignListPageChange = ({ selected }) => {
    this.props.dispatch(setEmailPaginationPage(selected + 1));
  };

  renderPaginate() {
    let { campaigns, paginationPage, pageSize } = this.props;
    if (campaigns.error != null && campaigns.error.code == 404) {
      return "";
    }
    return <TTPPaginator onPageChange={this.handleCampaignListPageChange}
      pageSize={pageSize}
      nbResult={campaigns.nbResult}
      paginationPage={paginationPage} />;
  }

  render() {
    let { campaigns } = this.props;
    return (
      <div className="campaign">
        <PageHeader header="list of campaigns" subHeader="Create, manage and send your email campaigns ." icon="EMAIL">
          <button className="btn secondary text-uppercase"
            onClick={() => this.props.history.push("/campaign/selector")}>
            <img src="img/icons/add.svg" />
            {_('create')}
          </button>
        </PageHeader>
        <CampaignListFilter />
        {(campaigns.error != null && campaigns.error.code == 404) ? <NotFound /> : <div className={`row align-center medium-11 m-t-s`} style={{position : "relative", zIndex : "0"}}>
          <CampaignList campaigns={campaigns} />
        </div>}
        {!campaigns.fetching && <div className={`row align-center margin-bottom-2`}>
          <div className={"column medium-11"}>
            {this.renderPaginate()}
          </div>
        </div>}
      </div>
    );
  }
}
