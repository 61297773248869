import React from "react";
import TTPModal from "../ui/TTPModal";
import { getLanguages } from "../../services/utils";
import { onError, onSuccess, modalStyle, hasValidLength, ucFirst } from 'utils';
import _ from 'i18n';
import TTPSelect from "../common/TTPSelect";
import { connect } from "react-redux";
import { saveTheme } from "thunks";
import { constructTemplateFromBlocks } from "../../services/block/newsletter";

const mapStateToProps = (state, ownProps) => ({
  blocks: state.themes.newsletter.blocks,
  template: state.themes.newsletter.currentTemplate,
  updating: state.themes.updating,
  categories: state.themes.categories.items,
  lng: state.auth.user.language
});

const mapDispatchToProps = dispatch => ({
  saveTheme: (template) => dispatch(saveTheme(template)),
  fetchTemplatesCategories: () => dispatch(fetchTemplatesCategories()),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class SaveTemplateForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      language: "all",
      hasError: false,
      value: props.themeCategory || null,
      options: props.categories && this.getCategories(),
      themeCategory: null
    };
    this.handleLanguageChange = (language) => this.setState({ language });
  }

  handleNameChange = (ev) => {
    const name = ev.target.value;
    this.setState({ name, hasError: !hasValidLength(name, 3) });
  };

  saveTemplate = () => {
    const { blocks, template, onClose, saveTheme } = this.props;
    const { name, language, themeCategory } = this.state;
    if (name && !hasValidLength(name, 3)) {
      this.setState({ hasError: true });
      return;
    }

    const data = {
      ...constructTemplateFromBlocks(blocks, template),
      name,
      language,
      type: "TEMPLATE",
      themeCategory
    };

    saveTheme(data).then(
      (res) => {
        onClose();
        onSuccess(res);
      },
      (err) => onError(err)
    )
  };

  getCategories = () => {
    const { lng, categories } = this.props;
    let lang = ucFirst(lng);
    return categories.filter(category => category['organization']).map(category => {
      let label = category[`name${lang}`] || category['nameFr'];
      return { id: category.id, value: label, label };
    })
  };

  handleChange = (option) => {
    this.setState({ themeCategory: option ? option.id : "", value: option.value });
  };

  render() {
    const { onClose, updating } = this.props;
    const { name, language, hasError, value, options } = this.state;
    return (<TTPModal title={_("save the template")} isOpen={true} onClose={onClose}
      modalStyle={{
        ...modalStyle,
        content: { ...modalStyle.content, padding: 0, overflow: "visible" }
      }}>
      <div className="row align-center m-t-l m-b-xxl">
        <div className="column small-8">
          <span className="param-label">{_('title')} <i className="asterisk">*</i></span>
          <div className="setting-param">
            <input className={hasError ? "border-danger" : ""} type="text" value={name}
              onChange={this.handleNameChange} />
          </div>
        </div>
        <div className="row small-8 align-justify">
          <div className="columns small-6 m-b-l">
            <span className="param-label">{_('language')}</span>
            <div className="setting-param">
              <TTPSelect
                simple={true}
                notClearable={true}
                values={language}
                placeholder={_('lang')}
                options={getLanguages(true).map(({ key, value }) => {
                  return { id: key, label: _(value).toUpperCase() };
                })}
                onChange={this.handleLanguageChange} />
            </div>
          </div>
          <div className="columns small-6 m-b-l m-b-xxl">
            <span className="param-label">{_('Category')}</span>
            <div className="setting-param">
              <TTPSelect
                simple={true}
                isObjects={true}
                notClearable={true}
                values={value}
                options={options}
                placeholder={_('Category')}
                onChange={this.handleChange} />
                </div>
          </div>
        </div>
      </div>
      <div className="templates-settings__footer">
        <button onClick={onClose} className="btn secondary">{_('cancel')}</button>
        <button onClick={!updating && this.saveTemplate}
          className="btn primary">{updating ? _('Processing...') : _("save")}</button>
      </div>
    </TTPModal>)
  }
}
