import React, { PureComponent } from "react";
import TagsInput from 'react-tagsinput';
import { connect } from 'react-redux';

import Target from 'common/campaign/Target';

import { setCurrentSmsCampaignData } from 'actions';
import { amountFormatter, PHONE_REGEX } from 'utils';
import _ from 'i18n';

const mapStateToProps = (state) => {
  return {
    opened: state.currentCampaign.sms.currentSideBar == "RECIPIENTS",
    manualSmsRecipients: state.currentCampaign.sms.manualSmsRecipients,
    recipientType: state.currentCampaign.sms.recipientType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onManualSmsRecipientsChange: (manualSmsRecipients) => dispatch(setCurrentSmsCampaignData({ manualSmsRecipients })),
  }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class ManualSMSRecipients extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { tag: '', invalidNumber: false };
  }

  pasteSplit(data) {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];
    return data.split(new RegExp(separators.join('|'))).map(d => d.split(' ').join(''));
  }

  handleChange(tags) {
    this.setState({ tags })
  }

  handleChangeInput(tag) {
    let regex = new RegExp(PHONE_REGEX);
    this.setState({ tag, invalidNumber: tag && !regex.test(tag) });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.opened || this.props.opened;
  }

  render() {
    const { opened, recipientType, manualSmsRecipients, onManualSmsRecipientsChange, smsCount, language, manualSmsApproximateCost } = this.props;
    const { invalidNumber } = this.state;


    return <div id="campaign-recipent__tab" className={((opened && "MANUAL" == recipientType) ? '' : 'hide')}>
      <div className="sidebar__form">
        <h4 className="sidebar__header">{_('selectTarget')}</h4>

        <Target count={manualSmsRecipients ? manualSmsRecipients.length : 0} type="MANUAL" />
        <Target count={smsCount} type="SMS" icon="icon-speech" />
        <Target count={amountFormatter(manualSmsApproximateCost, 2, language)} type="TOTAL COST" icon="icon-wallet" />

        <div className={`email-tags__input ${(!manualSmsRecipients.length) && "empty-box"}`}>
          <TagsInput
            value={manualSmsRecipients}
            onChange={onManualSmsRecipientsChange}
            inputValue={this.state.tag}
            addOnPaste={true}
            pasteSplit={this.pasteSplit}
            onlyUnique={true}
            validationRegex={PHONE_REGEX}
            addOnBlur={true}
            inputProps={{ placeholder: _('smsManualTargets') + " ex +32xxxxxxxx" }}
            onChangeInput={this.handleChangeInput.bind(this)}
          />
        </div>
        <span className={`form-error ${invalidNumber ? 'is-visible' : ''}`}> {_('invalidPhoneNumber')}</span>
      </div>
    </div>;
  }
}
