import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import ApproveCampaign from "./ApproveCampaign";
import RejectCampaign from "./RejectCampaign";

import {fetchApprovalFeedback} from "thunks";
import {setCurrentNavPage} from "actions";
import _ from "i18n";
import {S3_FOLDER_URL_PROD} from "Config";

@connect(store => {
  return {
    approvals: store.approval.list.items,
    fetching: store.approval.list.fetching,
    isUaNewsletter: store.currentCampaign.uaNewsletter.isUaNewsletter,
    idApproval: store.currentCampaign.uaNewsletter.idApproval,
    action: store.currentCampaign.uaNewsletter.approvalAction,
  };
})
export default class ApprovalResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {approval: null, decision: null, resubmit: false};
  }

  componentWillMount() {
    if (!this.props.isUaNewsletter) {
      const {
        dispatch,
        match: {params: routeParams}
      } = this.props;
      if (routeParams) {
        this.setState({decision: routeParams.decision});
        dispatch(fetchApprovalFeedback({id: routeParams.approvalId}));
      }
      dispatch(setCurrentNavPage("APPROVAL_RESPONSE"));
    } else {
      this.setState({decision: this.props.action});
      this.props.dispatch(fetchApprovalFeedback({id: this.props.idApproval}));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.approvals !== this.props.approvals) {
      this.setState({approval: this.props.approvals[0]});
    }
  }

  renderCantApproveBlock() {
    return (
      <div className="approval-warning">
        {_("Thank you for your collaboration")}
        <h6>{_("cant_submit_approval")} </h6>
        <button className="button btn__back"
                onClick={() => this.props.isUaNewsletter ? this.props.handleSetCurrentPage("LIST_PAGE") : null}>
          <i className="icon icon-action-undo"/>
          {this.props.isUaNewsletter ? _("goBackToHome") :
            <Link to="/">{_("goBackToHome")}</Link>}
        </button>
      </div>
    );
  }

  renderFetchingBlock() {
    return (
      <div className="fetching">
        <div className="row align-center">
          <h4 className="small-8 gradiant"/>
          <h4 className="small-5 gradiant"/>
        </div>
      </div>
    );
  }

  renderAlreadySubmittedBlock() {
    let {decision, decideAt} = this.props.approvals[0];
    return (
      <div className="align-center">
        <div className="approval-warning">
          {"ACCEPT" == decision
            ? _("You have already approved this version of campaign test on")
            : _("You have already rejected this version of campaign test on")}
          <b> {decideAt}</b>
          <h6>{_("you still want to resubmit?")}</h6>
        </div>
        <div className="buttons-block text-center">
          <button
            className="btn secondary"
            onClick={() => this.props.isUaNewsletter ? this.props.handleSetCurrentPage("LIST_PAGE") : this.props.history.push("/")}
          >
            {_("cancel")}
          </button>
          <button
            className="btn primary"
            onClick={() => this.setState({resubmit: true})}
          >
            {_("continue")}
          </button>
        </div>
      </div>
    );
  }

  getApprovalBlock() {
    let {decision, resubmit, approval} = this.state;
    let {fetching} = this.props;

    if (fetching) {
      return this.renderFetchingBlock();
    }

    if (!approval) {
      return this.renderCantApproveBlock();
    }

    let {id, version, campaign} = approval;
    if (
      !campaign.status ||
      (["DRAFT", "TEST"].indexOf(campaign.status) === -1 &&
        !campaign.scheduledDeliveryAt)
    ) {
      return this.renderCantApproveBlock();
    }

    if (!resubmit && ["ACCEPT", "DENY"].indexOf(approval.decision) !== -1) {
      return this.renderAlreadySubmittedBlock(approval.decision);
    }

    if ("accept" === decision) {
      return <ApproveCampaign id={id} version={version}
                              handleSetCurrentPage={this.props.handleSetCurrentPage}/>;
    } else if ("deny" === decision) {
      return <RejectCampaign id={id} version={version}
                             handleSetCurrentPage={this.props.handleSetCurrentPage}/>;
    }

    return null;
  }

  render() {
    return (
      <div className="approval-response">
        <div className="text-center">
          <img src={`${S3_FOLDER_URL_PROD}/apps/emailing.png`}/>
        </div>
        {this.getApprovalBlock()}
      </div>
    );
  }
}
