import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import { toggleAddBlockSideBar } from "actions";
import LayoutsList from "../blocks/layout/LayoutsList";

const mapStateToProps = (store, ownProps) => ({
  showAddBlockSideBar: store.themes.newsletter.showAddBlockSideBar,
  type: store.themes.blocks.filter.type,
});

const mapDispatchToProps = (dispatch) => ({
  closeAddBlockSideBar: () => dispatch(toggleAddBlockSideBar(false)),
});
@connect(mapStateToProps, mapDispatchToProps)
class AddBlockSideBar extends Component {
  getTitle = (type) => {
    return {
      NEWS_LAYOUT: "select an article layout",
      SLOT_LAYOUT: "select a slot layout",
      FORMATION_LAYOUT: "select a form layout",
      SPEAKER_LAYOUT: "select a speaker layout",
      STATIC_BLOCK: "select a static block",
    }[type];
  };

  render() {
    const { closeAddBlockSideBar, showAddBlockSideBar, type } = this.props;

    return (
      <div
        className={`add-block-side-bar medium-4 ${
          showAddBlockSideBar && "display"
        }`}
      >
        <div className="add-block-side-bar__header">
          <span
            onClick={closeAddBlockSideBar}
            className="icon icon-arrow-left"
          />
          <span className="margin-auto">
            {_(this.getTitle(type) || "layouts")}
          </span>
        </div>
        {showAddBlockSideBar && <LayoutsList />}
      </div>
    );
  }
}

export default AddBlockSideBar;
