import React, { Component } from "react";
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux';

import {
  setRecipientsPaginationPage,
  setRecipientsPageSize,
  setRecipientsListFilterName
} from 'actions';
import { RECIPIENT_ATTRIBUTE } from 'Common';
import _ from 'i18n';
import 'flag-icon-css/css/flag-icon.css'
import TTPPaginator from "common/list/TTPPaginator";
import SearchBox from "common/filter/SearchBox";
import PageSize from "common/list/PageSize";
import NotFound from 'notFound/NotFound';

@connect((store) => {
  return {
    recipients: store.campaigns.recipients
  }
})
export default class SMSRecipients extends Component {
  handleSearchInputChange = (value) => {
    const { dispatch } = this.props;
    dispatch(setRecipientsListFilterName(value));
  };

  handleRecipientListPageClick(data) {
    const { dispatch } = this.props;
    let selectedPage = data.selected;
    dispatch(setRecipientsPaginationPage(selectedPage + 1));

  };

  handleSelectRecipientListPageSize(event) {
    const { dispatch } = this.props;
    let pageSize = event;
    dispatch(setRecipientsPageSize(pageSize));
  }

  shouldComponentUpdate(nextProps, nextState) {
    let { recipients } = nextProps;

    if (recipients?.items?.length !== this.props.recipients?.items?.length) {
      window.dispatchEvent(new Event('resize'));
    }
    // Don't update component during ajax fetch new data process
    let condition1 = !recipients.fetching && recipients.fetched;
    // Result not found
    let condition2 = !recipients.items.length || !recipients.error;

    return condition1 || condition2;
  };

  render() {
    const { recipients, recipientType } = this.props;
    const attributes = RECIPIENT_ATTRIBUTE[recipientType];
    if (!recipientType || recipientType == "MANUAL") {
      return null;
    }

    let notfoundBlock = <NotFound />;

    return (
      <div className="recipients-datatable_wrapper">
        <div className="small-10 list-filter__bar">
          <SearchBox onChange={this.handleSearchInputChange}
            value={recipients.filter.name} />
          <PageSize pageSize={recipients.filter.pageSize}
            onChange={this.handleSelectRecipientListPageSize.bind(this)} />
        </div>
        {(recipients.items.length == 0) ? notfoundBlock :
          <div>
            <div className="row align-center">
              <div className="ttp-datatable small-10 columns">
                <div className="row ttp-datatable__header">
                  <div className="small-4">{_('name')}</div>
                  <div className="small-4">{_('Phone number')}&nbsp;
                    <span data-for="info-tooltip" className="icon-info"
                      data-tip={_('Phone numbers with check marks<br>have been verified by our system')}></span>
                    <ReactTooltip id="info-tooltip" type='dark'
                      effect="float" multiline={true}
                      place="bottom" />
                  </div>
                  <div className="small-4">{_('country')}</div>
                </div>
                {recipients.items.map(target => {
                  return (
                    <div key={`recipient-${target.id}`}
                      className="row ttp-datatable__row">
                      <div
                        className="small-4">{((attributes.firstName) ? target[attributes.firstName] + " " : "") + target[attributes.lastName]}</div>
                      <div className="small-4">
                        {target[attributes.phoneNumber]}&nbsp;<span
                          className="icon-check"></span>
                      </div>
                      <div className="small-4">
                        <span
                          className={`flag-icon flag-icon-${target.countryCode}`}></span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            < TTPPaginator
              onPageChange={this.handleRecipientListPageClick.bind(this)}
              pageSize={recipients.filter.pageSize}
              nbResult={recipients.nbResult}
              paginationPage={recipients.filter.paginationPage} />
          </div>
        }
      </div>
    );
  }
}
