import React, {PureComponent} from "react";
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip'

import {amountFormatter} from 'utils';
import {initCampaignRecipientsFilter, setCurrentSmsCampaignData} from 'actions';
import {fetchSMSRecipients, getSmsApproximateCost, fetchGroups} from 'thunks';
import SMSRecipients from './SMSRecipients';
import {CAMPAIGN_RECIPIENTS_TYPE} from 'Common';

import _ from 'i18n';
import TTPSelect from "common/TTPSelect";
import Target from "../../../common/campaign/Target";

@connect((state) => {
  return {
    opened: state.currentCampaign.sms.currentSideBar == "RECIPIENTS",
    recipientType: state.currentCampaign.sms.recipientType,
    groups: state.filters.groups.items,
    allowedGroups: state.currentCampaign.sms.allowedGroups,
    deniedGroups: state.currentCampaign.sms.deniedGroups,
    language: state.currentCampaign.sms.language,
    recipients: state.campaigns.recipients,
    _requests: state.params.sourcesTokens,
  }
})
export default class RecipientsTab extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      "searchQuery": "",
      smsApproximateCost: 0,
      totalPriceByCountry: 0,
      language: this.props.language
    };
    this.handleSearchQueryChange = (searchQuery) => this.setState({searchQuery});
  }

  componentDidMount() {
    let {recipientType} = this.props;
    this.props.dispatch(initCampaignRecipientsFilter());
    if (recipientType === "CONTACT") {
      this.renderRecipientDatatableAndSmsCost(this.props);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {recipients: {filter}, recipientType, dispatch, smsCount, language, allowedGroups, deniedGroups} = this.props;
    let {totalPriceByCountry} = this.state;

    if (language !== prevProps.language && prevProps.recipientType !== "MANUAL") {
      dispatch(initCampaignRecipientsFilter());
      this.renderRecipientDatatableAndSmsCost(this.props)
    } else if ((recipientType !== prevProps.recipientType || allowedGroups !== prevProps.allowedGroups || deniedGroups !== prevProps.deniedGroups) && this.props.recipientType !== "MANUAL") {
      dispatch(initCampaignRecipientsFilter());
      this.renderRecipientDatatableAndSmsCost(this.props);
      dispatch(fetchGroups(recipientType));
    }

    if (prevProps.smsCount !== this.props.smsCount) {
      this.setState({smsApproximateCost: totalPriceByCountry * smsCount});
      this.props.onSmsApproximateCostChange(totalPriceByCountry);
    }

    if (filter !== prevProps.recipients.filter && recipientType === "CONTACT") {
      this.renderRecipientDatatableAndSmsCost(this.props);
      dispatch(fetchGroups(recipientType));
    }
  }

  renderRecipientDatatableAndSmsCost(filter) {
    let {deniedGroups, allowedGroups, language, recipientType} = filter;
    const {dispatch} = this.props;
    if (CAMPAIGN_RECIPIENTS_TYPE.indexOf(recipientType) === -1) {
      return;
    }
    let filterData = {
      groupsIn: allowedGroups,
      groupsNin: deniedGroups,
      language,
      recipientType
    };
    if (recipientType && recipientType !== "MANUAL") {
      dispatch(fetchSMSRecipients(filterData));
    }
    dispatch(getSmsApproximateCost(filterData)).then(res =>
      this.handleSmsApproximateCostChange(res.value.data.data.totalPriceByCountry), err => this.handleSmsApproximateCostChange(0));
  };

  handleSmsApproximateCostChange(totalPriceByCountry) {
    let smsApproximateCost = totalPriceByCountry * this.props.smsCount;

    this.setState({
      smsApproximateCost: smsApproximateCost,
      totalPriceByCountry: totalPriceByCountry
    });
    this.props.onSmsApproximateCostChange(totalPriceByCountry);
  }

  handleGroupsChange = (key, value) => {
    const data = {};
    data[key] = value;
    this.props.dispatch(setCurrentSmsCampaignData(data));
  }

  renderContactFilters() {
    const {allowedGroups, deniedGroups, groups} = this.props;

    return (<div className="row align-center">
      <div className="columns small-6 columns-padding">
        <h1 className="param__title">{_('groupsIncluded')}</h1>
        <TTPSelect
          values={allowedGroups}
          placeholder={_('selectGroups')}
          options={groups}
          onChange={(group) => this.handleGroupsChange("allowedGroups", group)}
          disabled={deniedGroups}/>
      </div>
      <div className="columns small-6 columns-padding">
        <h1 className="param__title">{_('excludedGroups')}</h1>
        <TTPSelect
          values={deniedGroups}
          placeholder={_('excludeGroups')}
          options={groups}
          onChange={(group) => this.handleGroupsChange("deniedGroups", group)}
          disabled={allowedGroups}/>
      </div>
    </div>);
  }

  render() {
    const {opened, recipients, recipientType, smsCount, containDynamicFields} = this.props;
    const {searchQuery, smsApproximateCost, language} = this.state;

    return (
      <div id="campaign-recipent__tab"
           className={`${(opened && recipientType != "MANUAL") ? '' : 'hide'}`}>
        <div className="sidebar__form">
          <h4 className="sidebar__header">{_('selectTarget')}</h4>
          <Target
            count={recipients.recipientsTotalCount ? recipients.recipientsTotalCount : 0}
            type={_(recipientType)}/>
          <Target count={smsCount} type="SMS" icon="icon-speech"/>
          <Target containDynamicFields={containDynamicFields}
                  count={amountFormatter(smsApproximateCost, 2, language)}
                  type={containDynamicFields ? _('TOTAL APPROXIMATE COST') : _('TOTAL COST')}
                  icon="icon-wallet"/>
          {this.renderContactFilters()}
          <SMSRecipients
            recipientType={recipientType}
            searchQuery={searchQuery}
            onSearchQueryChange={this.handleSearchQueryChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}
