import React, { Component } from "react";
import { connect } from "react-redux";

import Switch from "common/Switch";
import TeamForm from "./TeamForm";

import _ from "i18n";
import { onSuccess, onError } from "utils";
import { setCurrentNavPage, initApprovalTeams } from "actions";
import {
  fetchApprovalTeams,
  persistClientSettings,
  updateDefaultApprovalTeam,
} from "thunks";
import TTPTab from "ui/tabs/TTPTab";
import SidePageHeader from "common/SidePageHeader";
import { SVG_PLUS, SVG_MINUS } from "../../../services/svgIcones";
import Teams from "../teams/Teams";

@connect((store) => {
  return {
    settings: store.auth.currentSetting,
  };
})
export default class ApprovalSettings extends Component {
  constructor(props) {
    super(props);
    this.toggleTeamForm = (team = {}) =>
      this.setState({
        showCreateForm: !this.state.showCreateForm,
        team,
      });
    this.switchTab = (tab) => this.setState({ currentTab: tab });
    this.handleAutoSendChange = (autoSend) =>
      this.setState({
        autoSend,
        showButtons:
          autoSend !== this.props.settings.autoSend ||
          this.props.settings.mandatoryTest !== this.state.mandatoryTest,
      });
    this.handleMandatoryTestChange = (mandatoryTest) =>
      this.setState({
        mandatoryTest,
        showButtons:
          mandatoryTest !== this.props.settings.mandatoryTest ||
          this.props.settings.autoSend !== this.state.autoSend,
      });

    this.state = {
      showCreateForm: false,
      currentTab: "TEAMS",
      autoSend: false,
      mandatoryTest: false,
      action: null,
      showButtons: false,
      teamId: null,
    };
  }

  componentDidMount() {
    this.undoChanges();
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(initApprovalTeams());
    dispatch(setCurrentNavPage("SETTINGS"));
    dispatch(fetchApprovalTeams());
  }

  componentDidUpdate(prevProps, prevState) {
    let { autoSend, mandatoryTest, fetched } = this.props.settings;
    if (prevProps.settings.fetching && fetched) {
      this.setState({
        autoSend: autoSend,
        mandatoryTest: mandatoryTest,
      });
    }
  }

  persistPreferences = () => {
    let { autoSend, mandatoryTest } = this.state;
    const { enableDashboard } = this.props.settings;

    let emailingPreferences = { autoSend, mandatoryTest, enableDashboard };
    this.props.dispatch(persistClientSettings({ emailingPreferences })).then(
      (res) => {
        onSuccess(res);
        this.setState({ showButtons: false });
      },
      (err) => onError(err),
    );
  };

  undoChanges = () => {
    const { autoSend, mandatoryTest } = this.props.settings;
    this.setState({ autoSend, mandatoryTest, showButtons: false });
  };

  setCurrentAction = (action) =>
    this.setState((prevState) => ({
      action: prevState.action === action ? null : action,
    }));

  setDefaultTeamId = (id) => {
    this.setState({ teamId: id });
  };

  handleUpdateDefaultTeam = () => {
    const { dispatch } = this.props;
    const { teamId } = this.state;

    if (teamId !== null) {
      dispatch(updateDefaultApprovalTeam(teamId)).then(
        (res) => onSuccess(res),
        (err) => onError(err),
      );
    }
  };

  render() {
    const {
      showCreateForm,
      currentTab,
      autoSend,
      mandatoryTest,
      team,
      action,
      showButtons,
    } = this.state;
    const { updating } = this.props.settings;
    const { isUAVersion } = this.props;

    return (
      <div className="approval-page">
        {isUAVersion ? (
          <div className="newsletter-ua-page__sub-header is-ua-approval">
            <div className="newsletter-ua-page__sub-header__info">
              <div className="newsletter-ua-page__sub-header__info__title">
                <img src={"/img/icons/approval-header.svg"} />
                <div className="title">
                  <h3>{_("approval")}</h3>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <SidePageHeader subHeader="approval" />
        )}
        <div className="ttp-tab-container">
          <TTPTab
            tabs={tabs}
            activeTab={currentTab}
            selectTab={this.switchTab}
          />
          {currentTab === "TEAMS" && (
            <div>
              <div className="row minus-add__actions">
                <button
                  onClick={() => this.handleUpdateDefaultTeam()}
                  className="success"
                >
                  {_("save")}
                </button>
                <span
                  className={`${REMOVE_ACTION == action ? "minus__action" : "minus"}`}
                  onClick={() => this.setCurrentAction(REMOVE_ACTION)}
                >
                  {SVG_MINUS}
                </span>
                <span className="add" onClick={this.toggleTeamForm}>
                  {SVG_PLUS}
                </span>
              </div>
              <Teams
                setDefaultTeamId={this.setDefaultTeamId}
                onEdit={this.toggleTeamForm}
                deleteState={action === REMOVE_ACTION}
              />
            </div>
          )}
          <div
            className={`row small-8 settings-tab align-center ${currentTab !== "SETTINGS" ? "hide" : ""}`}
          >
            <div className="column small-10">
              <div className="row medium-12 title">
                <h3>{_("Configure your community's test  preferences")}</h3>
              </div>
            </div>
            <div className="column small-10">
              <div className="setting_item">
                <span className="param-label text-capitalize">
                  {_("approval is required")}
                </span>
                <div className="setting-param">
                  <p className="setting-param__detail">
                    {" "}
                    {_("approval_obligatory_paragraph")}
                  </p>
                  <Switch
                    name="mandatoryTest"
                    isChecked={mandatoryTest}
                    onChange={(val) => this.handleMandatoryTestChange(val)}
                  />
                </div>
              </div>
            </div>
            <div className="column small-10">
              <div className="setting_item">
                <span className="param-label text-capitalize">
                  {_("automatic sending")}
                </span>
                <div className="setting-param">
                  <p className="setting-param__detail">
                    {_("automatic_sending_paragraph")}{" "}
                  </p>
                  <Switch
                    name="autoSend"
                    isChecked={autoSend}
                    onChange={(val) => this.handleAutoSendChange(val)}
                  />
                </div>
              </div>
            </div>
            {showButtons && (
              <div className="row small-10 align-right">
                <button onClick={this.undoChanges} className="btn secondary">
                  {_("cancel")}
                </button>
                <button
                  onClick={!updating && this.persistPreferences}
                  className="btn primary"
                >
                  {updating ? _("Processing...") : _("save")}
                </button>
              </div>
            )}
          </div>
        </div>
        {showCreateForm && (
          <TeamForm {...team} onCloseModal={this.toggleTeamForm} />
        )}
      </div>
    );
  }
}

export const tabs = [
  {
    id: "TEAMS",
    label: "teams",
  },
  {
    id: "SETTINGS",
    label: "configurations",
  },
];

const REMOVE_ACTION = "REMOVE";
