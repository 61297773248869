import { ucFirst } from "./common";
import _ from "i18n";

export const mapEventsData = (response, language) => {
  if (!response || !response.value) {
    return [];
  }
  const lng = ucFirst(language);
  const options = response.value.data.data.map((event) => {
    let languages = JSON.parse(event.languages || "") || [];
    const types = event.emailsTypes || [];
    return {
      label: event[`name${lng}`] || event['nameFr'] || event['nameNl'] || event['nameEn'],
      id: event.id + "",
      fromNameFr: event.contactFr,
      fromNameEn: event.contactEn,
      fromNameNl: event.contactNl,
      fromEmailFr: event.emailContactFr,
      fromEmailEn: event.emailContactEn,
      fromEmailNl: event.emailContactNl,
      languages: Object.keys(languages).filter(l => languages[l] == "1").map(l => l.toLowerCase()),
      types,
      count: `${types.length} ${_('emails types')}`
    };
  });
  return options;
}

export const mapSurveysData = (response, language) => {
  if (!response.value) {
    return [];
  }
  const lng = ucFirst(language);
  let options = response.value.data.data.map(({ id, title, languages, emailsTypes }) => {
    let label = title[lng] || title['fr'] || title['nl'] || title['nameEN'];
    const types = emailsTypes || [];
    return {
      label,
      id: id + "",
      languages: languages ? languages.split(",") : [],
      types,
      count: `${types.length} ${_('emails types')}`
    };
  });
  return options;
};

export const getAppTypes = (types, app) => {
  const appTypes = types.reduce((acc, type) => {
    if (type.targetApp === app) {
      acc.push(type);
    }
    return acc;
  }, []);

  return appTypes;
};


export const getMergedFields = (fields, src, lng) => ({
  name: src,
  mergeTags: Object.entries(fields).map(([name, innerFields]) => ({
    name,
    mergeTags: innerFields.map((field) => ({
      name: (field.title[lng] ? field.title[lng] : field.title[ucFirst(lng)]).toUpperCase(),
      value: field.code ? field.code : ""
    }))
  }))
});

export const getMergedOneFields = (fields, lng) => {
  return Object.entries(fields).map((innerFields) => ({
    name: innerFields[0],
    mergeTags: innerFields[1].map((field) => ({
      name: (field.title[lng] ? field.title[lng] : field.title[ucFirst(lng)]).toUpperCase(),
      value: field.code ? field.code : ""
    }))
  }))[0]
};


export const fieldToSpecialLinks = (fields, src, lng) => ({
  name: src,
  specialLinks: Object.values(fields).reduce((links, innerFields) => {
    innerFields.forEach(field => {
      if (field.code.search("URL") > -1) {
        links.push({ name: (field.title[lng] ? field.title[lng] : field.title[ucFirst(lng)]).toUpperCase(), href: field.code ? field.code : "" })
      }
    });
    return links;
  }, [])
});
