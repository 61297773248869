import React from "react";
import { SVG_ICONS } from '../../config/Common';

export const TTPSelector = ({cssClass, icon, tabName, tabLabel, tabDescription, onClick}) => (
  <div className={`ttp-selector ${cssClass || ""}`} onClick={onClick}>
    <div className="icon">
      <img src={"/img/icons/" + SVG_ICONS[icon]} />
    </div>
    <div className="labels">
      <h5>{tabName}</h5>
      <h3>{tabLabel}</h3>
      <p>
        {tabDescription}
      </p>
    </div>
    <div className="ellipse" />
  </div>
);