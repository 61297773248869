import Loader from "react-loaders";
import React from "react";
import _ from "i18n";

export const LoadingSuggestion = ({
  containerProps,
  loadingsSuggestions,
  suggestionsError,
  children,
  value,
  onScroll,
  allUsers,
  fetching,
}) => {
  return (
    <div {...containerProps} onScroll={onScroll}>
      {children}
      {!fetching && loadingsSuggestions && (
        <div className="loading-suggestion">
          {_("Loading suggestions ...")}{" "}
          <Loader type="line-spin-fade-loader" active />
        </div>
      )}
      {!loadingsSuggestions &&
        suggestionsError &&
        suggestionsError.code === 404 &&
        value &&
        (allUsers?.length === 0 || !allUsers) && (
          <div className="loading-suggestion"> {_("Nothing Found")}</div>
        )}
    </div>
  );
};
