import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from 'react-infinite-scroller';

import { setSpeakersPaginationPage, setSpeakersEvent, setSpeakersSearchWord, initSpeakers } from "actions";
import { fetchEventSpeakers } from "thunks";
import NotFound from 'notFound/NotFound';
import SpeakerItem from "./SpeakerItem";
import SpeakerItemFetching from 'fetching/blocks/SpeakerItemFetching';
import EventFilter from "blocks/EventFilter";

const mapStateToProps = (state, ownProps) => ({
  speakers: state.filters.speakers.items,
  nbResult: state.filters.speakers.nbResult,
  filter: state.filters.speakers.filter,
  fetching: state.filters.speakers.fetching,
  eventFetching: state.filters.events.fetching,
  lng: state.params.lng,
});

const mapDispatchToProps = dispatch => ({
  fetchEventSpeakers: () => dispatch(fetchEventSpeakers()),
  setSpeakersPaginationPage: (page) => dispatch(setSpeakersPaginationPage(page)),
  setSpeakersEvent: (event) => dispatch(setSpeakersEvent(event)),
  setSpeakersSearchWord: (searchWord) => dispatch(setSpeakersSearchWord(searchWord)),
  initSpeakers: () => dispatch(initSpeakers()),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class SpeakersList extends Component {

  state = {
    hasMoreItems: true,
  }

  componentDidMount() {
    this.props.initSpeakers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filter, fetchEventSpeakers, fetching } = this.props;
    if (filter !== prevProps.filter) {
      fetchEventSpeakers();
    }
    if (fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !fetching,
      })
    }
  }

  loadItems = () => {
    const { filter, fetching, nbResult, speakers, setSpeakersPaginationPage } = this.props;
    if (!fetching && speakers.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      setSpeakersPaginationPage(currentPage + 1);
    }
  }

  render() {
    const { speakers, fetching, eventFetching, filter, setSpeakersEvent, setSpeakersSearchWord, lng, showSelected, selectedFeeds } = this.props;
    const { hasMoreItems } = this.state;

    let items = [];


    if (speakers && speakers.length) {
      items = speakers.map((item) => {
        const order = selectedFeeds.indexOf(item.id);
        return (showSelected || order < 0) ? <SpeakerItem order={order >= 0 ? order : 1000} isSelected={selectedFeeds.includes(item.id)} key={item.id} {...item} lng={lng} /> : null;
      });
    }

    if (fetching || eventFetching) {
      for (let i = 0; i < 4; i++) {
        items.push(<div key={`fetch-${i}`} className={`medium-3 column`} style={{ order: 1001 }}><SpeakerItemFetching /></div>)
      }
    }

    let showSpeakers = filter.event && items && items.length;

    return (
      <div className="medium-12">
        <div id="feeds-container" style={{ height: `${(0.9 * window.innerHeight) - 20}px` }} ref={(ref) => this.scrollParentRef = ref} className="column">
          <EventFilter filter={filter} onEventChange={setSpeakersEvent} onSearchWordChange={setSpeakersSearchWord} hideViewModes={true} />
          {(showSpeakers || eventFetching) ?
            <InfiniteScroll
              className={"feeds-list"}
              pageStart={0}
              loadMore={this.loadItems}
              useWindow={false}
              hasMore={hasMoreItems}>
              {showSelected && selectedFeeds.length > 0 && <div className={"masonry-separator medium-12"} />}
              {items}
            </InfiniteScroll>
            : <NotFound mainClass="medium-10" text={!filter.event && "Select event"} />}
        </div>
      </div>);
  }
}
