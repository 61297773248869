import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { DragSource } from "react-dnd";
import { compose } from "redux";
import { NotificationManager } from "react-notifications";
import {
  addFeedToCurrentLayout,
  removeFeedFromLayout,
  addFeedToNewsletter,
} from "actions";
import { TEMPLATE_TYPES } from "constants";
import { formatDateRange, getEventNbMinutes } from "utils";
import { S3_FOLDER_URL, TTP_EVENT_URL, APP_ENV } from "Config";
import _ from "i18n";
import ToggleButton from "../ui/ToggleButton";
import {
  RESIZE_ARTICLE_COVER,
  RESIZE_SPEAKER,
  RESIZE_TRAINING_COVER
} from "../../constants";
import { SVG_UP_ICON } from "../../services/svgIcones";

class FormationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
    this.toogleDetails = () =>
      this.setState((prevState) => ({ showMore: !prevState.showMore }));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.id !== this.props.id ||
      nextProps.isSelected !== this.props.isSelected ||
      nextState.showMore !== this.state.showMore ||
      nextProps.viewMode !== this.props.viewMode ||
      nextProps.order !== this.props.order
    );
  }

  addFormation = () => {
    const { currentLayout, base, blocks } = this.props;
    const formation = formatFormation(this.props);

    if (
      base === "TEMPLATE" &&
      (!currentLayout || currentLayout.type !== formation.type)
    ) {
      if (!blocks.some(({ feeds, column }) => feeds.length < column)) {
        NotificationManager.info(_("All blocks are filled"));
        return;
      }
      this.props.addFeedToNewsletter(formation);
      return;
    }
    if (!currentLayout && base !== "TEMPLATE") {
      NotificationManager.info(_("Select a layout for your block first"));
      return;
    }

    if (currentLayout.type !== "FORMATION_LAYOUT") {
      NotificationManager.info(
        _("Current layout is not the appropriate block type")
      );
      return;
    }

    this.props.addFeedToCurrentLayout(formation);
  };

  removeFormation = () => {
    const { removeFeedFromLayout, id } = this.props;
    removeFeedFromLayout(id);
  };

  renderFormationCover = () => {
    const { languages } = this.props;
    const lngSuffix = extractFormationLanguage(languages);
    let urlBanner = this.props["urlBanner" + lngSuffix];

    return (
      <div>
        <div
          className="feed__media"
          style={{
            backgroundImage: `url(${
              this.props.isSelected
                ? this.props.cover
                : `${S3_FOLDER_URL}/${this.props[
                    `urlBanner${lngSuffix}`
                  ]?.replace("eventsFolder", "events-folder")}`
            } )`,
          }}
        />
      </div>
    );
  };

  render() {
    const {
      languages,
      startDateTime,
      endDateTime,
      speakers,
      isSelected,
      connectDragSource,
      viewMode,
      order,
    } = this.props;

    const listMode = viewMode === "LIST";
    const { showMore } = this.state;
    let speakersNames = [];

    if (speakers) {
      speakersNames = speakers.map((speaker, i) =>
        speaker.speakerName ? (
          <div key={`speaker-${i}`}>-- {speaker.speakerName}</div>
        ) : (
          ""
        )
      );
    }

    const lngSuffix = extractFormationLanguage(languages);
    const startDate = moment(this.props.startDateTime).format(
      "dddd, MMMM DD, YYYY"
    );

    return connectDragSource(
      <div className={`medium-${listMode ? 12 : 6} column`} style={{ order }}>
        <div
          className={`feed ${showMore && "feed-expanded"} ${
            isSelected ? "selected" : ""
          }`}
        >
          <img className="feed__expander" src="/img/utils/expander.png" />
          <div
            className="flex-container flex-dir-column"
            onClick={
              isSelected ? this.removeFormation : this.addFormation.bind(this)
            }
          >
            <div className="flex-container">
              {this.renderFormationCover()}
              <div className="feed_content">
                <div className="feed_author">
                  <div className="flex-container flex-dir-column">
                    <span className="date">
                      {isSelected ? this.props[`startDate`] : startDate}
                    </span>
                  </div>
                </div>
                <h3 className="feed_content-title">
                  <a target="_blank">
                    {this.props[`name${lngSuffix}`]
                      ? this.props[`name${lngSuffix}`]
                      : this.props[`name`]}
                  </a>
                </h3>
                {listMode && !showMore && (
                  <p className="feed_content-summary">
                    {this.props[`name${lngSuffix}`].slice(0, 210) + "..."}
                  </p>
                )}
              </div>
            </div>
            {showMore && (
              <div className="feed-detail">
                <p className="feed_content-summary m-t-s m-b-s">
                  {this.props[`name${lngSuffix}`]}
                </p>
                {speakersNames && speakersNames.length > 0 && (
                  <div className="feed__tags">
                    <span>{speakersNames}</span>
                  </div>
                )}
              </div>
            )}
          </div>
          <span
            className="feed__category"
            style={{ backgroundColor: "rgb(103, 102, 102)" }}
          >
            {_("speakers")}
          </span>
          <ToggleButton
            cssClass={"apply-icon"}
            onToggleSelection={
              isSelected ? this.removeFormation : this.addFormation.bind(this)
            }
            checked={isSelected}
          />
          {isSelected && <div className={"feed-order"}>{order + 1}</div>}
          <div className="read-more" onClick={this.toogleDetails}>
            {_(showMore ? "close" : "see more")}
            <span className={`${!showMore && "down"}`}>{SVG_UP_ICON}</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLayout: state.themes.newsletter.currentLayout,
  event: state.filters.formations.filter.event,
  blocks: state.themes.newsletter.blocks,
  base: state.themes.newsletter.base,
});
const mapDispatchToProps = (dispatch) => ({
  addFeedToCurrentLayout: (article) =>
    dispatch(addFeedToCurrentLayout(article)),
  addFeedToNewsletter: (formation) => dispatch(addFeedToNewsletter(formation)),
  removeFeedFromLayout: (id) => dispatch(removeFeedFromLayout(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  DragSource(
    TEMPLATE_TYPES["FORMATION_LAYOUT"],
    {
      canDrag: (props) => !props.isSelected,
      beginDrag: (props) => {
        return formatFormation(props);
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )
)(FormationItem);

const formatFormation = (props) => {
  const { id, languages, startDateTime, endDateTime, urlBanner } = props;

  const lngSuffix = extractFormationLanguage(languages);
  const displayReplayable = (isReplayable) => {
    return isReplayable ? _("replayable", lngSuffix?.toLowerCase() ?? "") : "";
  };

  let dateTime = formatDateRange(
    startDateTime,
    endDateTime,
    lngSuffix.toLowerCase()
  );

  let duration = getEventNbMinutes(startDateTime, endDateTime);
  let priceSuffix = " €";
  let durationSuffix = " min";
  let speakerAvatars = props["speakers-abstract"].speakers.map((speaker) =>
    speaker.pictureUrl
      ? speaker.pictureUrl.includes("https")
        ? speaker.pictureUrl + RESIZE_SPEAKER
        : S3_FOLDER_URL + "/events-folder" + speaker.pictureUrl + RESIZE_SPEAKER
      : null
  );

  let pictureUrls = speakerAvatars.filter((avatar) => avatar !== null);

  let speakerName;
  let speakerSecondName;
  let speakerHeadline;
  let speakerSecondHeadline;

  // Code for 2 SPEAKERS MAX IN THE NEWSLETTER FOR THE MOMENT
  if (pictureUrls.length === 2) {
    const [speakerOne, speakerSecond] = props["speakers-abstract"].speakers;

    speakerName = `${speakerOne.firstName} ${speakerOne.lastName}`;
    speakerSecondName = `${speakerSecond.firstName} ${speakerSecond.lastName}`;
    speakerHeadline = speakerOne[`headline${lngSuffix}`] || "";
    speakerSecondHeadline = speakerSecond[`headline${lngSuffix}`] || "";
    if (speakerHeadline.startsWith('{') && typeof JSON.parse(speakerHeadline) === 'object'){
      speakerHeadline = JSON.parse(speakerHeadline).title
    }
    if (speakerHeadline.startsWith('{') && typeof JSON.parse(speakerHeadline) === 'object'){
      speakerSecondHeadline = JSON.parse(speakerSecondHeadline).title
    }
  } else if (pictureUrls.length > 7) {
    // code for 7 speakers or more
    speakerName = props["speakers-abstract"].count + " " + _("speakers");
    speakerSecondName = "";
    speakerHeadline = "";
    speakerSecondHeadline = "";
  } else {
    // Code for other cases
    speakerName = props["speakers-abstract"].speakers
      .map((speaker) => speaker?.firstName + " " + speaker?.lastName)
      .join(" ,");
    speakerSecondName = "";
    speakerHeadline = props["speakers-abstract"].speakers
      .map((speaker) =>
        speaker[`headline${lngSuffix}`]
          ? speaker[`headline${lngSuffix}`].startsWith('{') && typeof JSON.parse(speaker[`headline${lngSuffix}`]) === 'object' ? JSON.parse(speaker[`headline${lngSuffix}`]).title :  `${speaker[`headline${lngSuffix}`]}`
          : ""
      )
      .join(" ,");
    speakerSecondHeadline = "";
  }

  return {
    id,
    startDate: dateTime,
    cover: `${S3_FOLDER_URL}/${props[`urlBanner${lngSuffix}`].replace(
      "eventsFolder",
      "events-folder"
    )}${RESIZE_TRAINING_COVER}`,

    name: props[`name${lngSuffix}`],

    speakerName,
    speakerSecondName,
    speakerHeadline,
    speakerSecondHeadline,

    speakerAvatar:
      pictureUrls.length >= 3
        ? "https://tamtam.s3.eu-west-1.amazonaws.com/production/emailing/utils/multiple_avatar.svg"
        : pictureUrls.length > 0
        ? pictureUrls[0]
        : "https://ui-avatars.com/api/?bold=true&background=0D8ABC&color=fff&size=130",

    speakerSecondAvatar: pictureUrls.length == 2 ? pictureUrls[1] : "",

    duration: duration + durationSuffix,
    price:
      props[`nonMemberPrice`] === 0
        ? _("free")
        : props[`nonMemberPrice`] + priceSuffix,
    formDesc: displayReplayable(props[`isReplayable`]),
    ReadMore: _("Read more", lngSuffix && lngSuffix.toLowerCase()),
    type: TEMPLATE_TYPES["FORMATION_LAYOUT"],
    formationUrl: `${TTP_EVENT_URL}/plan-selector?eventId=${id}`,
    nameFr: props[`nameFr`],
    nameEn: props[`nameEn`],
    nameNl: props[`nameNl`],
  };
};

const extractFormationLanguage = languages => {
  if (!languages) {
    return undefined; // Default language when languages is undefined
  }

  const lngs = JSON.parse(languages);
  let result = "";

  Object.entries(lngs).map(([key, value]) => {
    if (parseInt(value) === 1) {
      result = key;
    }
  });

  if (!result) {
    return "Fr";
  }

  return result;
};
