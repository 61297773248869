import React, { Component } from "react";
import { connect } from "react-redux";

import _ from "i18n";

import { setBlocksType } from "actions";
import { TEMPLATE_TYPES } from "constants";

const mapStateToProps = (state, ownProps) => ({
  currentBlockType: state.themes.blocks.filter.type,
});

const mapDispatchToProps = (dispatch) => ({
  setBlocksType: (type) => dispatch(setBlocksType(type)),
});

@connect(mapStateToProps, mapDispatchToProps)
export default class BlockTypeSelector extends Component {
  render() {
    const { setBlocksType, currentBlockType, blockTypes } = this.props;

    let types = [];

    let activeTab =
      !blockTypes || blockTypes.length === 0
        ? "NEWS_LAYOUT"
        : blockTypes.indexOf(currentBlockType) < 0
        ? blockTypes[0]
        : currentBlockType;

    if (
      !blockTypes ||
      blockTypes.length === 0 ||
      blockTypes.indexOf(TEMPLATE_TYPES["NEWS_LAYOUT"]) > -1
    ) {
      types.push({ text: "articles", value: TEMPLATE_TYPES["NEWS_LAYOUT"] });
    }
    if (blockTypes.indexOf(TEMPLATE_TYPES["SPEAKER_LAYOUT"]) > -1) {
      types.push({ text: "speakers", value: TEMPLATE_TYPES["SPEAKER_LAYOUT"] });
    }
    if (blockTypes.indexOf(TEMPLATE_TYPES["SLOT_LAYOUT"]) > -1) {
      types.push({ text: "slots", value: TEMPLATE_TYPES["SLOT_LAYOUT"] });
    }
    if (blockTypes.indexOf(TEMPLATE_TYPES["FORMATION_LAYOUT"]) > -1) {
      types.push({
        text: "Formations",
        value: TEMPLATE_TYPES["FORMATION_LAYOUT"],
      });
    }

    return (
      <div className="tool-header">
        {types.map(({ text, value }, i) => (
          <div
            key={i}
            className={`tool-header__item ${activeTab == value && "active"}`}
            onClick={() => setBlocksType(value)}
          >
            <h5>{_(text)}</h5>
          </div>
        ))}
      </div>
    );
  }
}
