import React, { Component } from 'react';
import { getUserNameForAvatar } from 'utils';

import _ from 'i18n';

export default class Approver extends Component {

  render() {
    const { id, email, user, onDeleteApprover, mainClass, avatarClass, avatarOnly, approverIcon } = this.props;
    const { firstName, lastName, avatarUrl } = user;

    let avatarDiv = null;
    if (avatarUrl != undefined) {
      avatarDiv = (
        <div id={`avatar-${id}`} className={avatarClass ? avatarClass : "approver-avatar"}
          style={{ backgroundImage: `url(${avatarUrl})` }}>
          {onDeleteApprover && <div className="delete-approver" onClick={() => onDeleteApprover(id)}><span className="icon icon-trash"></span></div>}
          {approverIcon && <div className="approver-icon">
            <i id={`check-${id}`} className={`icon ${approverIcon}`}></i>
          </div>}
        </div>
      );
    } else {
      avatarDiv = (
        <div id={`avatar-${id}`} className={`${avatarClass ? avatarClass : "approver-avatar"} empty-avatar`} >
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
          {approverIcon && <div className="approver-icon">
            <i id={`check-${id}`} className={`icon ${approverIcon}`}></i>
          </div>}
          {onDeleteApprover && <div className="delete-approver" onClick={() => onDeleteApprover(id)}><span className="icon icon-trash"></span></div>}
        </div>
      );
    }
    if (avatarOnly) {
      return avatarDiv;
    }
    return (
      <div className={`approver ${mainClass ? mainClass : ""}`} key={`user-${user.id}`} >
        {avatarDiv}
        <div className="approver-info">
          <h3 className="approver-info-name">{lastName}  {firstName}</h3>
          <h5 className="approver-info-email">{email}</h5>
        </div>
      </div>
    );
  }
};
