import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import { fetchMailingLists } from "thunks";
import { setCurrentEmailCampaignData } from "actions";
import TTPSelect from "../../../common/TTPSelect";
import { ucFirst } from "../../../../services/utils";

@connect((store) => {
  return {
    lists: store.mailingLists.items,
    lng: store.params.lng,
    mailingLists: store.currentCampaign.email.mailingLists,
  }
})
export default class MailingListFilters extends Component {

  constructor(props) {
    super(props);
    this.props.dispatch(fetchMailingLists());
  }

  renderListOption = (option) => {
    return <div className="select-option">
      <span className="select-option__label">{option.label}</span>
      <span className="select-option__content">{option.subscribers} {_('subscribed contacts')}</span>
    </div>;
  };

  onMailingListsChange = (mailingLists) => {
    this.props.dispatch(setCurrentEmailCampaignData({ mailingLists }));
  };

  render() {
    const { mailingLists, lists, lng } = this.props;
    let listOptions = lists.map(list => {
      return {
        label: list[`name${ucFirst(lng)}`] || list[`nameFr`] || list[`nameNl`],
        subscribers: list.subscribersCount,
        id: list.id + ''
      };
    });

    return (
      <div className="columns small-6 columns-padding">
        <div className="sidebar__form-control">
          <h1 className="param__title">{_('MAILING_LIST')}</h1>
          <TTPSelect
            notClearable={true}
            values={mailingLists}
            placeholder={_('MAILING_LIST')}
            options={listOptions}
            onChange={this.onMailingListsChange}
          />
        </div>
      </div>
    );
  }
}
