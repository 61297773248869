import React, { Component } from 'react';
import { connect } from "react-redux";
import LineChartComponent from './LineChartComponent';
import _ from 'i18n';

class OpensEvolutionChart extends Component {
  render() {
    const {dataOpens} = this.props;
    const dataKeysY = [
      { key: "totalEmailsOpen", label: _("Percentage of openings")}
    ];
    const dataKeyX ={ key: "date", label: _("date") };
    const yAxisTitle = _("Percentage of openings") + " %";
    return (
      <LineChartComponent dataChart={dataOpens} dataKeysY = {dataKeysY} dataKeyX = {dataKeyX} yAxisTitle = {yAxisTitle}/>
    );
  }
}
const mapStateToProps = (store) => ({
  dataOpens: store.dashboard.dataOpens
});
export default connect(mapStateToProps)(OpensEvolutionChart);
