import React, { Component } from 'react';
import { connect } from "react-redux";
import LineChartComponent from './LineChartComponent';
import _ from 'i18n';

class AverageScoreChart extends Component {
  render() {
    const {dataScore} = this.props;
    const dataKeysY = [
      { key: "averageScore", label: "Average score"}
    ];
    const yAxisTitle = "Average score";
    const dataKeyX ={ key: "date", label: "date" };
    return (
      <LineChartComponent dataChart={dataScore} dataKeysY = {dataKeysY} dataKeyX = {dataKeyX} yAxisTitle = {yAxisTitle} isEvolutionTooltip = {true}/>
    );
  }
}
const mapStateToProps = (store) => ({
  dataScore: store.dashboard.dataScore
});
export default connect(mapStateToProps)(AverageScoreChart);
