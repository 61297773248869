import React, { Component } from 'react';
import { connect } from 'react-redux';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import _ from "i18n";
import { setCurrentEmailData } from 'actions';
import { saveCurrentGenericEmail } from 'thunks';
import { onSuccess, onError, hasValidLength } from '../../../../services/utils';
import RichEditor from "../../../common/editor/RichEditor";
import {
  setFreeSectionDatePickerDate,
  setFreeSectionDatePickerDateTime, setFreeSectionDatePickerTime
} from "../../../../actions/actions/textModel";

class TextForm extends Component {

  state = {
    object: {
      value: this.props.object,
      valid: !this.props.object || hasValidLength(this.props.object, 5, 200)
    },
    contentState: {
      value: this.props.body,
      valid: this.props.body && this.props.body.length > 1
    }
  }

  componentDidMount() {
    this.props.setFreeSectionDatePickerDateTime(null);
    this.props.setFreeSectionDatePickerDate(null);
    this.props.setFreeSectionDatePickerTime(null);
  }

  handleObjectChange = (e) => {
    const value = e.target ? e.target.value : e;

    this.setState({
      object: {
        value,
        valid: hasValidLength(value, 5, 200)
      }
    });
  };

  handleContentChange = (content) => {
    this.props.setCurrentEmailData({ body: content });

    this.setState({
      contentState: {
        value: content,
        valid: !!content.trim()
      }
    });
  }

  saveText = () => {
    const { object, contentState } = this.state;
    const { setCurrentEmailData, saveCurrentGenericEmail, closed } = this.props;
    const objectIsValid = hasValidLength(object.value, 5, 200);
    if (!(objectIsValid && contentState.valid)) {
      this.setState(prevState => ({ object: { ...prevState.object, valid: objectIsValid } }));
      return;
    }
    setCurrentEmailData({ object: object.value, text: contentState.value, source: "CUSTOM" })
    saveCurrentGenericEmail().then((res) => {
      onSuccess(res, { body: 'Data saved successfully' });
      closed();
    }, (err) => onError(err)
    );
  }

  render() {
    const { object, contentState } = this.state;
    const { updating, closed, targetApp } = this.props;
    const saveDisabled = !(object.valid && contentState.valid);

    return (
      <div className="text-form">
        <RichEditor
          content={contentState.value}
          subject={object.value}
          onEditorChange={this.handleContentChange}
          onObjectChange={this.handleObjectChange}
          fieldsType={targetApp}
          subjectHasError={!object.valid}
        />
        <div className="text-form__actions">
          <button onClick={closed} className="btn secondary">{_('cancel')}</button>
          <button disabled={saveDisabled} onClick={(!updating && !saveDisabled) && this.saveText} className={`btn primary ${saveDisabled && 'disabled'}`}>{updating ? _('Processing...') : _('save')}</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  updating: state.genericEmail.currentSetting.currentEmail.updating,
  targetApp: state.genericEmail.currentSetting.targetApp,
})

const mapDispatchToProps = dispatch => ({
  setCurrentEmailData: (data) => dispatch(setCurrentEmailData(data)),
  saveCurrentGenericEmail: () => dispatch(saveCurrentGenericEmail()),
  setFreeSectionDatePickerDateTime: (dateTime) => dispatch(setFreeSectionDatePickerDateTime(dateTime)),
  setFreeSectionDatePickerDate: (date) => dispatch(setFreeSectionDatePickerDate(date)),
  setFreeSectionDatePickerTime: (time) => dispatch(setFreeSectionDatePickerTime(time)),
})
export default connect(mapStateToProps, mapDispatchToProps)(TextForm);
