import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from "i18n";
import DatePicker from "react-datepicker";
import cheerio from "cheerio";
import {setEboxMessage} from "../../actions/actions/ebox/widget";
import moment from "moment";
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/nl';
import {
  setFreeSectionDatePickerDate,
  setFreeSectionDatePickerDateTime, setFreeSectionDatePickerTime
} from "../../actions/actions/textModel";
import TTPSelect from "../common/TTPSelect";
import Select from 'react-select';


class FillFreeSectionForm extends Component {
  state = {
    inputDateTime: this.props.datepickerDateTime,
    inputDate: this.props.datepickerDate,
    inputTime: this.props.datepickerTime,
    currentCurrency: "€",
  };

  componentDidUpdate(prevProps, prevState) {
    const {selectedText} = this.props;
    if (prevProps.selectedText !== selectedText) {
      this.setState({ inputDateTime: null,inputDate: null,inputTime: null });
    }
  }

  handleChangeFsInput = (e, fsQuestion) => {
    const {content, setEboxMessage, onEditorChange, isExpressEditor} = this.props;
    const $ = cheerio.load(content);
    let freeSections = $('span').data('type', "FREE_SECTION");
    let fsSpans = [];
    for (let i = 0; i < freeSections.length; i++) {
      fsSpans.push(freeSections[i]);
    }

    let currentFreeSection = fsSpans.filter(freeSection => freeSection.attribs["data-question"] === fsQuestion);
    currentFreeSection[0].attribs["data-name"] = e.target ? e.target.value : e;
    currentFreeSection[0].attribs["style"] = "";

    if (!e || e === "Invalid date" || (!e.target && !e.split(" ")[0]) || (e.target && !e.target.value)) {
      currentFreeSection[0].attribs["data-formated-name"] = "-";
    } else {
      currentFreeSection[0].children[0].data = e.target ? e.target.value : e;
      currentFreeSection[0].attribs["data-formated-name"] = "";
    }

    let replacedContent = $.html();
    if (isExpressEditor) {
      setEboxMessage(replacedContent);
    } else {
      onEditorChange(replacedContent);
    }
  };

  handleCurrencyChange = (e, fsValue, fsQuestion) => {
    let currentCurrency = e.target.value;
    this.setState({currentCurrency});
    this.handleChangeFsInput(fsValue + " " + currentCurrency, fsQuestion);
  };

  renderFreeSectionFormItem = (spanElement) => {
    const { inputDateTime, inputDate, inputTime, currentCurrency } = this.state;
    const fsQuestion = spanElement.getAttribute("data-question");
    const fsFormat = spanElement.getAttribute("data-format");
    const fsExample = spanElement.getAttribute("data-example") || "";
    const fsDateFormat = spanElement.getAttribute("data-format-date");
    const fsOptions = spanElement.getAttribute("data-options") ? JSON.parse(spanElement.getAttribute("data-options")) : [];
    const fsOptionsData = fsOptions.map( choice  => { return { label: choice, value: choice } });

    let fsValue = spanElement.getAttribute("data-formated-name") ? "" : spanElement.getAttribute("data-name");
    let fsCurrency = "€";
    if (fsFormat === "CURRENCY") {
      fsCurrency = fsValue.split(" ")[1];
      fsValue = fsValue.split(" ")[0];
    }
    let fsInput = "";

    switch (true) {
      case fsFormat === "NUMBER":
        fsInput = <div className="small-5 free-section-form__item-input">
          <input className="fs-input" type="number" min="0" max="10000"
                 value={fsValue}
                 placeholder="0"
                 onChange={(e) => this.handleChangeFsInput(e, fsQuestion)}/>
          </div>;
      break;
      case fsFormat === "TEXT":
        fsInput = <div className="small-8 free-section-form__item-input">
              <textarea className="fs-textarea fs-input"
                        value={fsValue}
                        rows="4" placeholder={_('Enter the text') + "..."}
                        onChange={(e) => this.handleChangeFsInput(e, fsQuestion)}/>
          </div>;
        break;
        case fsFormat === "CURRENCY":
          fsInput = <div className="small-10 free-section-form__item-input">
            <input className="input-currency fs-input"
                   value={fsValue}
                   type="number" min="0.00"
                   step=".01" placeholder="0.00"
                   onChange={(e) => this.handleChangeFsInput(e.target.value + " " + currentCurrency, fsQuestion)}/>
            <select className="currency" value={fsCurrency} onChange={(e) => this.handleCurrencyChange(e, fsValue, fsQuestion)}>
              <option value="€">€</option>
              <option value="$">$</option>
            </select>
            <span className="free-section-form__item-example">{_('example')}:<span>{fsExample}</span></span>
          </div>;
        break;
      case fsFormat === "LIST":
        fsInput = <div className="small-4 free-section-form__item-input">
          <Select
            placeholder={_("Select an option") + "..."}
            isSearchable={true}
            options={fsOptionsData}
            value={fsOptionsData.filter(option => option.value === fsValue)}
            onChange={(e) => this.handleChangeFsInput(e.value, fsQuestion)}
            classNamePrefix="ttp-select"
          />
        </div>;
        break;
      case fsFormat.includes("DATE TIME"):
        fsInput = <div className="small-10 free-section-form__item-input">
          <DatePicker selected={inputDateTime} onChange={(date) => {
            this.setState({inputDateTime: date});
            this.props.setFreeSectionDatePickerDateTime(date);
            this.handleChangeFsInput(moment(date).format(fsDateFormat), fsQuestion);
          }}
                      timeInputLabel="Time:"
                      dateFormat="dd/MM/yyyy h:mm aa"
                      className="fs-input"
                      placeholderText={_('Select a date') + "..."}
                      showTimeInput/>
          {inputDateTime && <span className="free-section-form__item-example">{_('overview')}: <span>{moment(inputDateTime).format(fsDateFormat)}</span></span>}
        </div>;
      break;
      case fsFormat.includes("DATE ("):
        fsInput = <div className="small-10 free-section-form__item-input">
          <DatePicker selected={inputDate} onChange={(date) => {
            this.setState({inputDate: date});
            this.props.setFreeSectionDatePickerDate(date);
            this.handleChangeFsInput(moment(date).format(fsDateFormat), fsQuestion);
          }}
                      dateFormat="dd/MM/yyyy"
                      className="fs-input"
                      placeholderText={_('Select a date') + "..."}
          />
          {inputDate && <span className="free-section-form__item-example">{_('overview')}: <span>{moment(inputDate).format(fsDateFormat)}</span></span>}
        </div>;
      break;
      case fsFormat.includes("TIME ("):
        fsInput = <div className="small-10 free-section-form__item-input">
          <DatePicker selected={inputTime} onChange={(date) => {
            this.setState({inputTime: date});
            this.props.setFreeSectionDatePickerTime(date);
            this.handleChangeFsInput(moment(date).format(fsDateFormat), fsQuestion);
          }}
                      timeInputLabel="Time:"
                      showTimeSelectOnly
                      dateFormat="h:mm:s aa"
                      className="fs-input"
                      placeholderText={_('Enter a time') + "..."}
                      onCalendarClose={() => this.handleChangeFsInput(moment(inputTime).format(fsDateFormat), fsQuestion)}
                      showTimeInput/>
          {inputTime && <span className="free-section-form__item-example">{_('overview')}: <span>{moment(inputTime).format(fsDateFormat)}</span></span>}
        </div>;
      break;
    }

    return <div className="columns small-12 free-section-form__item m-b-xs">
      <div className="small-3 free-section-form__item-question">
        <span>{fsQuestion}</span>
      </div>
      {fsInput}
    </div>;
  };

  render() {
    const { content, lng } = this.props;
    moment.locale(lng);

    var wrapperSpan= document.createElement('div');
    wrapperSpan.innerHTML= content;
    let freeSections = [...wrapperSpan.querySelectorAll('[data-type]')];

    return (
        <div className="free-section-form">
            {freeSections.length !== 0 && freeSections.map(freeSectionItem =>
              this.renderFreeSectionFormItem(freeSectionItem)
            )}
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setEboxMessage: (message) => dispatch(setEboxMessage(message)),
  setFreeSectionDatePickerDateTime: (dateTime) => dispatch(setFreeSectionDatePickerDateTime(dateTime)),
  setFreeSectionDatePickerDate: (date) => dispatch(setFreeSectionDatePickerDate(date)),
  setFreeSectionDatePickerTime: (time) => dispatch(setFreeSectionDatePickerTime(time)),
});
const mapStateToProps = (state) => ({
  lng: state.params.lng,
  datepickerDateTime: state.textModels.datepickerDateTimeFormat,
  datepickerDate: state.textModels.datepickerDateFormat,
  datepickerTime: state.textModels.datepickerTimeFormat,
  selectedText: state.currentCampaign.email.textModel,
});

export default connect(mapStateToProps, mapDispatchToProps)(FillFreeSectionForm);


