import React, { Component } from 'react';
import _ from 'i18n';

class Radio extends Component {
    
  render() {
    const { onChange, checked, title, name } = this.props;

    return (
      <div className="radio">
        <label>
          <input type="radio" name={name} checked={checked} onChange={onChange}/>
          <span className="ttp-radio"></span>
          <span>{ _(title)}</span>
        </label>
      </div>
    );
  }
}

export default Radio;
