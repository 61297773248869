export const fetchWebNotifications = (resp) => {
    return {
        type: 'FETCH_WEB_NOTIFICATIONS',
        payload: resp
    };
};

export const readNotification = (resp) => {
    return {
        type: 'READ_NOTIFICATION',
        payload: resp
    };
};

export const fetchPushNotifications = (resp) => {
    return {
        type: 'FETCH_PUSH_NOTIFICATIONS',
        payload: resp
    };
};

export const initPushNotifications = () => {
    return {
        type: 'INIT_PUSH_NOTIFICATIONS',
    };
};

export const setPushNotificationsPaginationPage = (pageNumber) => {
    return {
        type: 'SET_PUSH_NOTIFICATIONS_PAGINATION_PAGE',
        pageNumber
    };
};

export const setPushNotificationsPageSize = (pageSize) => {
    return {
        type: 'SET_PUSH_NOTIFICATIONS_PAGE_SIZE',
        pageSize
    };
};

export const initCurrentNotificationData = (data = {}) => {
    return {
        type: 'INIT_CURRENT_NOTIFICATION_DATA',
        data
    };
};

export const setCurrentNotificationData = (data) => {
    return {
        type: 'SET_CURRENT_NOTIFICATION_DATA',
        data
    };
};

export const setCurrentNotificationFilters = (data) => {
    return {
        type: 'SET_CURRENT_NOTIFICATION_FILTERS',
        data
    };
};

export const setNotificationRecipientsPaginationPage = (pageNumber) => {
    return {
        type: 'SET_NOTIFICATION_RECIPIENTS_PAGINATION_PAGE',
        pageNumber
    };
};

export const setNotificationRecipientsPageSize = (pageSize) => {
    return {
        type: 'SET_NOTIFICATION_RECIPIENTS_PAGE_SIZE',
        pageSize
    };
};

export const setNotificationRecipientsSearchWord = (searchWord) => {
    return {
        type: 'SET_NOTIFICATION_RECIPIENTS_SEARCH_WORD',
        searchWord
    };
};

export const fetchNotificationRecipients = (resp) => {
    return {
        type: 'FETCH_NOTIFICATION_RECIPIENTS',
        payload: resp
    };
};

export const createNotification = (resp) => {
return {
    type: "CREATE_NOTIFICATION",
    payload: resp
  };
};

export const deleteNotification = (resp) => {
  return {
    type: "DELETE_NOTIFICATION",
    payload: resp
  };
};

export const duplicateNotificationData = (resp) => {
    return {
        type: 'DUPLICATE_NOTIFICATION',
        payload: resp
    };
};
