import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';

import { setCurrentNavPage } from "actions";
import lottie from "lottie-web";
import { S3_FOLDER_URL_PROD } from 'Config';

@connect((store) => ({
  application: (store.params.application || "EMAILING").toLocaleLowerCase(),
}))
export default class HomePage extends Component {
  anim = null;

  componentDidMount() {
    this.props.dispatch(setCurrentNavPage("HOME"));
    this.intiAnimation();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { application } = this.props;
    if (application != prevProps.application) {
      this.intiAnimation();
    }
  }

  intiAnimation = () => {
    const { application } = this.props;
    if (this.anim) {
      this.anim.destroy();
    }
    this.anim = lottie.loadAnimation({
      container: this.refs.animationContainer,
      renderer: 'svg',
      loop: 1,
      autoplay: true,
      path: `${S3_FOLDER_URL_PROD}/emailing/assets/animations/${application || "emailing"}-header.json`,
    });
  };

  componentWillUnmount() {
    if (this.anim) {
      this.anim.destroy();
    }
  }

  handleClickToPause = () => {
    if (!this.anim) {
      return;
    }
    if (this.anim.isPaused) {
      this.anim.goToAndPlay(0);
    } else {
      this.anim.pause();
    }
  };

  renderTitle = (application) => {
    switch (application) {
      case "emailing": {
        return <div className="sending-header__title">
          <h3>{_("Send your emailings")}</h3>
          <h4>{_("to the look & feel of your community")} <span>{_("with little effort!")}</span></h4>
        </div>
      }
      case "sms": {
        return <div className="sending-header__title">
          <h3>{_("Send your sms campaigns")}</h3>
        </div>
      }
      default:
        return null;
    }
  };

  render() {
    const { application } = this.props;
    return (
      <div className="home-page" id="home-page">
        <div className={"sending-header"}>
          {this.renderTitle(application)}
          <div className={"sending-header__animation"} ref={"animationContainer"} onClick={this.handleClickToPause} />
          <video controls poster={`/img/pictos/${application}-poster.jpg`}>
            <source src={`${S3_FOLDER_URL_PROD}/emailing/assets/${application}.mp4`} type="video/mp4" />
          </video>
        </div>
      </div>)
  };
};
