import React from "react";
import _ from "i18n";

export const RecipientsListFetching = () => {
    return <div className="ttp-datatable small-11">
        <div className="row ttp-datatable__header">
            <div className="small-5 p-l-xs p-r-xs">{_("name")}</div>
            <div className="small-7 p-l-xs p-r-xs">{_("email")}</div>
        </div>
        {[1, 2, 3, 4, 5, 6, 7].map(i => {
            return (
                <div key={"key--" + i} className="row ttp-datatable__row">
                    <div className="small-5 p-l-xs p-r-xs gradiant"/>
                    <div className="small-7 p-l-xs p-r-xs gradiant"/>
                </div>
            );
        })}
    </div>
}
