export const getNewsletterArticles = resp => {
  return {
    type: "FETCH_NEWSLETTER_UA_ARTICLES",
    payload: resp
  };
};

export const getNewsletterArticlesByPeriod = resp => {
  return {
    type: "FETCH_NEWSLETTER_UA_ARTICLES_BY_PERIOD",
    payload: resp
  };
};
export const updateIsUsedNewsletterArticles = resp => {
  return {
    type: "UPDATE_IS_USED_ARTICLES_NEWSLETTER",
    payload: resp
  };
};
