import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeader from "../common/PageHeader";
import LanguageChart from "./charts/PercentageChart/LanguageChart";
import GenderChart from "./charts/PercentageChart/GenderChart";
import AgeChart from "./charts/PercentageChart/AgeChart";
import AuthorizationModeChart from "./charts/PercentageChart/AuthorizationModeChart";
import TotalMembersComponent from "./communityStatistics/totalMembers/TotalMembersComponent";
import {
  fetchCampaignsData,
  fetchMembersStats,
  fetchStatsHistory,
} from "../../actions/thunks/dashboard";
import DashboardFilter from "./DashboardFilter";
import ChartContainer from "./charts/ChartContainer";
import AverageScoreChart from "./charts/LineChart/AverageScoreChart";
import TotalClicksChart from "./charts/Barchart/TotalClicksChart";
import CommunityComponent from "../common/Community/CommunityComponent";
import OpensEvolutionChart from "./charts/LineChart/OpensEvolutionChart";
import SubscribersEvoultionChart from "./charts/LineChart/SubscribersEvoultionChart";
import ActiveEvolutionChart from "./charts/LineChart/ActiveEvolutionChart";
import CampaignsChart from "./charts/Barchart/CampaignsChart";
import BestLinksClicked from "./BestLink/BestLinksClicked";
import BouncesChart from "./charts/LineChart/BouncesChart";

import _ from "i18n";

class DashboardPage extends Component {
  componentDidMount() {
    this.props.dispatch(fetchMembersStats());
    this.props.dispatch(fetchStatsHistory());
  }
  componentDidUpdate(prevProps, prevState) {
    const { filter } = prevProps;
    if (filter !== this.props.filter) {
      this.props.dispatch(fetchStatsHistory());
      this.props.dispatch(fetchCampaignsData());
    }
  }
  render() {
    return (
      <div className={"dashboard"}>
        <PageHeader
          header="Dashboard"
          subHeader="view your campaigns stats"
          icon="DASHBOARD"
        />
        <div className="row m-t-l" style={{ maxWidth: "98.5%" }}>
          <div className="cell " style={{ width: "14.6666666%" }}>
            <div className="m-t-xs m-b-xs m-r-xs">
              <CommunityComponent />
            </div>
            <div className="m-b-xs m-r-xs">
              <TotalMembersComponent />
            </div>
            <div className="m-b-xs m-r-xs">
              <LanguageChart />
            </div>
            <div className="m-b-xs m-r-xs">
              <GenderChart />
            </div>
            <div className="m-b-xs m-r-xs">
              <AgeChart />
            </div>
            <div className="m-b-xs m-r-xs">
              <AuthorizationModeChart />
            </div>
            <div className="m-t-xs m-b-xs m-r-xs">
              <BestLinksClicked />
            </div>
          </div>
          <div className="cell " style={{ width: "84.3%" }}>
            <DashboardFilter />

            <div className="container">
              <h1>{_("My community")}</h1>
              <div className="contentContainer">
                <div className="chartBlock">
                  <ChartContainer
                    title={_("Evolution of the number of openings")}
                  >
                    <AverageScoreChart />
                  </ChartContainer>
                </div>
                <div className="chartBlock">
                  <ChartContainer title={_("Evolution of active people")}>
                    <ActiveEvolutionChart />
                  </ChartContainer>
                </div>
              </div>
            </div>

            <div className="container">
              <h1>{_("Community engagement")}</h1>
              <div className="contentContainer">
                <div className="chartBlock">
                  <ChartContainer
                    title={_("Evolution of the number of openings")}
                  >
                    <OpensEvolutionChart />
                  </ChartContainer>
                </div>
                <div className="chartBlock">
                  <ChartContainer
                    title={_(
                      "Evolution of the number of new subscribers / unsubscribers"
                    )}
                  >
                    <SubscribersEvoultionChart />
                  </ChartContainer>
                </div>
                <div style={{width:"100%"}}>
                  <ChartContainer
                    title={_("Evolution of the number of clicks")}
                  >
                    <TotalClicksChart />
                  </ChartContainer>
                </div>
              </div>
            </div>

            <div className="container">
              <h1> {_("Emailing in sending")} </h1>
              <div>
                <div className="chartBlock">
                  <ChartContainer title={_("Number of mailings per language")}>
                    <CampaignsChart />
                  </ChartContainer>
                </div>
              </div>
            </div>

            <div className="container">
              <h1>{_("Performance of my emailings")}</h1>
              <div>
                <div className="chartBlock">
                  <ChartContainer title={_("Bounces data")}>
                    <BouncesChart />
                  </ChartContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  error: store.dashboard.error,
  filter: store.dashboard.filter,
});
export default connect(mapStateToProps)(DashboardPage);
