import React, { Component } from "react";
import { connect } from 'react-redux';
import _ from 'i18n';
import {setEventsFilters} from 'actions';
import { fetchEventCycles} from "thunks";
import TTPSelect from "common/TTPSelect";
import {GUEST_STATUS} from "../../../../../config/Common";
import {ucFirst} from "../../../../../services/common";

@connect((store) => {
  return {
    eventCycles: store.filters.eventCycles.items,
    eventFilters: store.currentCampaign.email.eventFilters,
    campaignLanguage: store.currentCampaign.email.language
  }
},(dispatch) => {
  return {
    fetchEventCycles: () => dispatch(fetchEventCycles()),
    handleCycleChange: (cycleId) => dispatch(setEventsFilters({ property: 'cycleId', operator: 'eq', value: cycleId })),
    handleStepsInChange: (steps) => dispatch(setEventsFilters({ property: 'step', operator: 'in', value: (steps.length > 0) ? steps : "" })),
    handleStepsNinChange: (steps) => dispatch(setEventsFilters({ property: 'step', operator: 'nin', value: (steps.length > 0) ? steps : "" })),
  }
})
export default class CycleGuestFilters extends Component {

  componentWillMount() {
    const { fetchEventCycles} = this.props;
    fetchEventCycles();
  }

  componentDidUpdate(prevProps, prevState) {
    const {eventCycles, handleCycleChange, eventFilters, campaignLanguage, fetchEventCycles} = this.props;
    if (eventCycles && eventCycles.length > 0 && eventFilters.length === 0) {
      handleCycleChange(eventCycles[0]['id']);
    }
    if (prevProps.campaignLanguage !== campaignLanguage) {
      fetchEventCycles();
    }
  }

  handleCycleChange(cycle) {
    const { handleCycleChange, handleStepsNinChange,  handleStepsInChange } = this.props;

    handleCycleChange(cycle);

    //init all
    handleStepsNinChange([]);
    handleStepsInChange([]);
  }


  render () {
    const {eventCycles, campaignLanguage, eventFilters, handleStepsNinChange, handleStepsInChange} = this.props;


    let filterMapping = eventFilters.reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});

    let selectedCycle = filterMapping['cycleId-eq'] || "";
    let includedSteps = filterMapping['step-in'] || [];
    let excludedSteps = filterMapping['step-nin'] || [];

    let lng = ucFirst(campaignLanguage);
    let cycleOptions = eventCycles.map(eventCycle => {
      return {
        name: eventCycle[`name${lng}`] || eventCycle['nameFr'] || eventCycle['nameNl'] || eventCycle['nameEn'] ,
        id: eventCycle.id + ""
      };
    });

    return (
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Cycle')}</h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={selectedCycle}
              placeholder={_('Select cycle')}
              options={cycleOptions}
              onChange={this.handleCycleChange.bind(this)} />
          </div>
        </div>

        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Included status')}</h1>
            <TTPSelect
              values={includedSteps}
              placeholder={_('Select status')}
              options={GUEST_STATUS}
              onChange={handleStepsInChange}
              disabled={excludedSteps} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Excluded status')}</h1>
            <TTPSelect
              values={excludedSteps}
              placeholder={_('Exclude status')}
              options={GUEST_STATUS}
              onChange={handleStepsNinChange}
              disabled={includedSteps} />
          </div>
        </div>

      </div>
    );
  }
}
