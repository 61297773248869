import React from "react";
import { SVG_SHARE_ICON } from "../../../../services/svgIcones";
import _ from "i18n";

const BroadcastedCampaign = (props) => {
  return (
    <div className="broadcasted-campaign-header">
      <div className="broadcasted-campaign-container">
        <div className="icon-container">{SVG_SHARE_ICON}</div>
        <h2 className="broadcasted-campaign-text">
          {_("Share_newsletter_campaign")}
        </h2>
        <h2 className="broadcasted-campaign-text second-header">
          {props.campaignName}
        </h2>
      </div>
    </div>
  );
};

export default BroadcastedCampaign;
