import React, { Component } from 'react';

class TextModelFetching extends Component {
  render() {
    return <div className={`small-6 generic-email-list__item generic-email-fetching`}>
      <div className="generic-email__content">
        <div className="generic-email__model">
          <div className="generic-email__model__content">
            <div className="header">
              <div>
                <h6 className="gradiant" />
                <h6 className="generic-email__title gradiant" />
              </div>
            </div>
            <div className="generic-email__model__title">
              <div className="title">
                <h3 className="gradiant" />
              </div>
            </div>
            <div className="generic-email__text">
              <h4 className="gradiant" />
              <h4 className="gradiant" />
              <h6 className="gradiant" />
              <h3 className="gradiant" />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default TextModelFetching;
