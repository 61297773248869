import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import SubMenuLinkUA from "./SubMenuLinkUA";

@connect((store) => {
  return {
    currentClient: store.auth.currentClient,
  };
})
export default class SubMenuUA extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { handleSetCurrentPage, currentPage, currentClient, plan } =
      this.props;
    return (
      <ul className="menu">
        <SubMenuLinkUA
          currentPage={"LIST_PAGE"}
          title={_("list_campaigns")}
          icon="INBOX_LIGHT"
          isActive={currentPage === "LIST_PAGE"}
          handleSetCurrentPage={handleSetCurrentPage}
        />
        {plan === "PERSONALIZED" && (
          <SubMenuLinkUA
            currentPage={"PROGRAM_PAGE"}
            title={_("program_newsletters")}
            icon="CALENDAR_NEWSLETTER"
            isActive={currentPage === "PROGRAM_PAGE"}
            handleSetCurrentPage={handleSetCurrentPage}
          />
        )}
        {(currentClient?.id === 4 || plan === "PERSONALIZED") && (
          <SubMenuLinkUA
            currentPage={"APPROVAL_PAGE"}
            title={_("approval")}
            icon="APPROVAL"
            isActive={currentPage === "APPROVAL_PAGE"}
            handleSetCurrentPage={handleSetCurrentPage}
          />
        )}
      </ul>
    );
  }
}
