import React, { Component, createRef } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { SVG_MAIL_PLUS } from "utils";
import _ from "i18n";

class ValidMailSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.selectedOption || null,
      menuIsOpen: false,
    };
    this.selectRef = createRef();
  }

  componentDidMount() {
    this.selectRef.current?.blur();
  }

  handleMenuClose = () => {
    this.setState({ menuIsOpen: false }, () => this.selectRef.current?.blur());
  };

  customStyles = (error) => ({
    control: (provided) => ({
      ...provided,
      border: error ? "1px solid red" : provided.border,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "32px",
    }),
  });

  dropdownIndicator = (props) => {
    const { menuIsOpen } = this.state;

    return (
      <components.DropdownIndicator {...props}>
        <span>
          <svg
            className={menuIsOpen ? "rotate-icon" : "normal-icon"}
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
          >
            <path
              d="M6.79241 8.47063C6.3921 8.99064 5.6079 8.99064 5.20759 8.47063L2.04322 4.35999C1.53703 3.70243 2.00579 2.75 2.83563 2.75L9.16437 2.75C9.99421 2.75 10.463 3.70243 9.95678 4.36L6.79241 8.47063Z"
              fill="#6D7F92"
            />
          </svg>
        </span>
      </components.DropdownIndicator>
    );
  };

  handleChange = (selectedOption) => {
    const { onChange, target } = this.props;
    this.setState({ selectedOption });
    onChange?.(selectedOption, target);
  };

  render() {
    const { menuIsOpen, selectedOption } = this.state;
    const { options, error } = this.props;

    return (
      <CreatableSelect
        className="react-select-container"
        classNamePrefix="react-select"
        styles={this.customStyles(error)}
        onChange={this.handleChange}
        options={options}
        value={!menuIsOpen ? selectedOption : null}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: this.dropdownIndicator,
        }}
        formatCreateLabel={() => (
          <div className="special-option">
            {SVG_MAIL_PLUS}
            <span>{_("new_mail_address")}</span>
          </div>
        )}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => this.setState({ menuIsOpen: true })}
        placeholder={_("new_mail_address")}
        ref={this.selectRef}
        onMenuClose={this.handleMenuClose}
      />
    );
  }
}

export default ValidMailSelect;
