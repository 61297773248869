import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';

import ThemeItem from './ThemeItem';
import ThemeFetching from 'fetching/ThemeFetching';
import NotFound from 'notFound/NotFound';
import { clearSourcesTokens, setThemesPaginationPage, setThemeType, setThemeLanguage, setThemeScope } from "actions";
import { fetchTemplates } from 'thunks';
import { TEMPLATE_TYPES, COMMUNITY_SCOPE } from 'constants';
import _ from 'i18n';
import { initTemplatesList } from '../../../../../actions/actions';
import { SCOPE_TABS, TAMTAM_SCOPE } from '../../../../../constants';
import TTPTab from '../../../../ui/tabs/TTPTab';

@connect()
class ThemeList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      hasMoreItems: true,
      inModal: false,
      currentScope: COMMUNITY_SCOPE,
    };
  }

  componentDidMount() {
    let { dispatch, genericCurrentEmailLanguage, initThemesList, setThemeScope } = this.props;
    initThemesList();
    setThemeScope(COMMUNITY_SCOPE);

    dispatch(setThemeType([TEMPLATE_TYPES['SIMPLE'], TEMPLATE_TYPES['WRAPPER']]));
    dispatch(setThemeLanguage(genericCurrentEmailLanguage));

    const modal = document.querySelector(".ReactModal__Content");
    const inModal = modal && modal.querySelector("#template-page");
    this.setState({ inModal });
  }

  componentDidUpdate(prevProps) {
    let { filter, dispatch, fetching } = this.props;

    if (fetching !== prevProps.fetching) {
      this.setState({
        hasMoreItems: !this.props.fetching,
      })
    }

    if (prevProps.filter === filter) {
      return;
    }

    filter.type = (!filter.type?.length) ? [TEMPLATE_TYPES['SIMPLE'], TEMPLATE_TYPES['WRAPPER']] : filter.type;
    filter = { ...filter, getUserAvatar: true, isBlock: true };
    dispatch(fetchTemplates(filter));
  }

  componentWillUnmount() {
    const { _requests } = this.props;
    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    this.props.dispatch(clearSourcesTokens());
  }

  handleScopeChange = (currentScope) => {
    this.setState({ currentScope });
    this.props.setThemeScope((currentScope == COMMUNITY_SCOPE) ? COMMUNITY_SCOPE : TAMTAM_SCOPE);
  }

  loadItems = () => {
    const { filter, dispatch, fetching, nbResult, themes } = this.props;

    if (!fetching && themes.length < nbResult) {
      let currentPage = filter.paginationPage;
      this.setState({ hasMoreItems: false });
      dispatch(setThemesPaginationPage(currentPage + 1));
    }
  }

  renderThemes() {
    const { themes, fetching } = this.props;
    const { hasMoreItems, inModal } = this.state;

    let items = [];
    if (themes && themes.length > 0) {
      for (let theme of themes) {
        items.push(<ThemeItem
          key={`theme-${theme.id}`}
          {...theme}
        />);
      }
    }
    if (fetching) {
      for (let index = 0; index < 4; index++) {
        items.push(<ThemeFetching inGenericEmailing={true} key={`theme-fetching-${index}`} />);
      }
    }

    return (items.length > 0) ? <InfiniteScroll
      className={`template-list row medium-11 p-t-s`}
      pageStart={0}
      loadMore={this.loadItems}
      hasMore={hasMoreItems}
      {...(inModal ? { useWindow: false, getScrollParent: () => this.scrollParentRef } : {})}
    >
      {items}
    </InfiniteScroll> : <NotFound mainClass={"small-11"} />;
  }

  render() {
    let { inModal, currentScope } = this.state;

    return (
      <div id="template-page"
        className={`small-12 columns ${inModal ? "in-modal" : ""}`}
        ref={(ref) => this.scrollParentRef = ref}>
        <div className="row align-center">
          <TTPTab tabs={SCOPE_TABS} type="simple" activeTab={currentScope} selectTab={this.handleScopeChange} />
        </div>
        {this.renderThemes()}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  themes: store.themes.list.items,
  nbResult: store.themes.list.nbResult,
  paginationPage: store.themes.filter.paginationPage,
  fetching: store.themes.list.fetching,
  filter: store.themes.filter,
  _requests: store.params.sourcesTokens,
  genericCurrentEmailLanguage: store.genericEmail.currentSetting.currentEmail.language,
  genericCurrentApp: store.genericEmail.currentSetting.targetApp,
});

const mapDispatchToProps = (dispatch) => ({
  setThemeScope: (scope) => dispatch(setThemeScope(scope)),
  initThemesList: () => dispatch(initTemplatesList()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ThemeList);


