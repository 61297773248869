import React, { Component } from "react";

import { CAMPAIGN_LANGUAGE } from 'Common';
import Switch from 'common/Switch';

import _ from 'i18n';
import { IS_EMAIL_VALID } from "../../../services/utils";
import LinksInventoryModal from "./LinksInventory/LinksInventoryModal";

export default class Summary extends Component {

  constructor(props) {
    super(props);

    this.setShowLinks = (showLinks) => this.setState({ showLinks });
    this.state = { showLinks: false };
  }

  render() {
    const { language, from, fromName, typePage, attachmentsCount, notifyCommunityChannel, isFromEmailValid, approved, onSwitchChange } = this.props;
    return (
      <div className="small-6">
        <ul className="summary">
          <li className="email-field">
            <div>{_('fromEmail')}:</div>
            <div>
              {from}
              {IS_EMAIL_VALID(isFromEmailValid)}
            </div>
          </li>
          <li><div>{_('fromName')}:</div> <div>{fromName}</div></li>
          <li><div>{_('type')}:</div> <div className="text-uppercase">{_(typePage)}</div></li>
          <li><div>{_('language')}:</div> <div className="text-uppercase">{_(CAMPAIGN_LANGUAGE[language])}</div></li>
          <li><div>{_('attachments')}:</div> <div >{attachmentsCount}</div></li>
          <li><div>{_('approved')}:</div> <div className="text-uppercase">{(approved == 1) ? _('yes') : _('no')}</div></li>
          {(typePage != 'WEB_PAGE') && <li>
            <div>{_('links inventory')}:</div>
            <div onClick={() => this.setShowLinks(true)} className="flex-container">
              {_('view')}
              <a><i className="icon-right icon-share-alt"></i></a>
            </div>
          </li>
          }
          <li><div>{_('notify talk community channel')}:</div> <div><Switch isChecked={notifyCommunityChannel} onChange={onSwitchChange} /></div></li>
        </ul>
        {this.state.showLinks && <LinksInventoryModal onClose={() => this.setShowLinks(false)} />}

      </div>
    )
  }

}
