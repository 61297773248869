import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import _ from 'i18n';
import { inIframe, ucFirst } from '../../../../services/utils';
import { initCurrentEmailData } from 'actions';

class EmptyEmail extends PureComponent {

    addEmail = () => {
        const { lng, type, initCurrentEmailData, settingsId } = this.props;
        const { emptyEmailItem } = this.refs;
        const data = {
            theme: this.props[`globalTheme${ucFirst(lng)}`] || null,
            language: lng,
            targetType: type,
            settingsId,
            action: 'EDIT',
        };
        if (inIframe() && emptyEmailItem) {
            emptyEmailItem.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
            const windowHeight = this.props.windowHeight || 720;
            const offsetTop = emptyEmailItem.offsetTop;
            data["offsetTop"] = (offsetTop + windowHeight + 64 > window.innerHeight) ? (window.innerHeight - windowHeight - 64) : offsetTop;
        }
        setTimeout(() => {
            initCurrentEmailData(data);
        }, 400);
    }

    render() {
        const { lng } = this.props;

        return (
            <div ref={"emptyEmailItem"} className="generic-email-list__item empty-block" onClick={this.addEmail}>
                <span className="icon-wrapper" >
                    <img src="/img/icons/add.svg" alt="Add" />
                </span>
                {<div className="message">
                    <span>{_('Add this language')}</span>
                    <img alt="" src={`/img/flags/${lng}.svg`} />
                </div>
                }
            </div>
        );
    }
}
const mapStateToProps = state => ({
    globalThemeFr: state.genericEmail.currentSetting.themeFr,
    globalThemeNl: state.genericEmail.currentSetting.themeNl,
    globalThemeEn: state.genericEmail.currentSetting.themeNl,
    settingsId: state.genericEmail.currentSetting.id,
    windowHeight: state.params.windowHeight,
});

const mapDispatchToProps = dispatch => ({
    initCurrentEmailData: (data) => dispatch(initCurrentEmailData(data))
});
export default connect(mapStateToProps, mapDispatchToProps)(EmptyEmail);
