export const stringToColor = (string) => {
    let hash = 0;
    let i;

    if (string) {
      for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    }

    let colour = '';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += `00${value.toString(16)}`.substr(-2);
    }

    if (parseInt(colour, 16) > 15658734) return '#eeeeee';
    return `#${colour}`;
}
