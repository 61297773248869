import React, { Component } from 'react';
import { SubMenuLink } from "../../SubMenuLink";

import _ from "i18n";

export default class GenericEmailing extends Component {

  render() {
    return <SubMenuLink pathname="/emails" title="Generic emails" icon="GENERIC_EMAIL_LIGHT" />
  }
};
