import cheerio from "cheerio";
import _ from "i18n";
import uuid from "uuid";
import { TEMPLATE_TYPES } from "constants";

import {
  findRowLayoutType,
  indexLayoutDynamicFields,
  findRowColumnCount,
  removeDynamicFieldsIndexes,
} from "./rowParser";
import {
  escapeJsonSpecialChars,
  ALL_LAYOUT_STRING_CODES,
  EMPTY_COLUMN_EXAMPLE,
  UNREFERENCED_BLOCK_CODES,
  FAKE_FEEDS,
} from "./helpers";

import { store } from "../../index.js";

const ROW_CONTAINER_CLASSES = [".email-row-container", ".u-row-container"];

export const decomposeNewsletter = (template, callback) => {
  let design = JSON.parse(template.design);

  const $ = cheerio.load(template.content);
  const rowsContainers = $(ROW_CONTAINER_CLASSES.join(","));

  let skeleton = (design.body.rows || []).reduce((acc, rowDesign, index) => {
    const rowHtml = cheerio.html(rowsContainers[index] || "");
    const rowType = findRowLayoutType(rowHtml);
    const column = findRowColumnCount(rowHtml, rowType);

    acc.push({
      id: uuid(),
      type: rowType,
      column,
      design: indexLayoutDynamicFields(JSON.stringify(rowDesign), column),
      content: indexLayoutDynamicFields(rowHtml, column),
      feeds: [],
    });

    return acc;
  }, []);

  callback(skeleton);
};

export const replaceContentFeed = (
  content,
  feeds = [],
  escapeJsonChars = false,
) => {
  const tagsIndexed = feeds?.length > 0;
  if (!tagsIndexed) {
    feeds = [
      Object.keys(FAKE_FEEDS).reduce((acc, feedType) => {
        acc = { ...acc, ...FAKE_FEEDS[feedType] };
        return acc;
      }, []),
    ];
  }

  return ALL_LAYOUT_STRING_CODES.reduce((acc, e) => {
    feeds.forEach((feed, index) => {
      let property =
        !feed[e.property] ||
        (Array.isArray(feed[e.property]) && feed[e.property].length === 0)
          ? ""
          : feed[e.property];
      const value = escapeJsonChars
        ? escapeJsonSpecialChars(property)
        : feed[e.property];
      acc = acc.replace(
        new RegExp(tagsIndexed ? `${e.key}__${index}` : e.key, "g"),
        value,
      );
    });

    return acc;
  }, content + "");
};

export const replaceUnreferencedBlock = (content = "") => {
  const fiduciaryLogo = store.getState().auth.currentClient.avatarUrl;
  const nlNewsletter =
    store.getState().currentCampaign.email.numberOfNewsletter;
  const introduction = store.getState().currentCampaign.email.introduction;
  return Object.keys(UNREFERENCED_BLOCK_CODES).reduce((acc, code) => {
    if (code === "FIDUCIARY_LOGO") {
      acc = acc.replace(code, fiduciaryLogo);
    } else if (code === "NL_NEWSLETTER") {
      acc = acc.replace(code, nlNewsletter);
    } else if (code === "INTRODUCTION_NL") {
      acc = acc.replace(code, introduction);
    } else {
      acc = acc.replace(code, UNREFERENCED_BLOCK_CODES[code]());
    }
    return acc;
  }, content + "");
};

export const assembleNewsletter = (template, blocks, callback) => {
  let generatedBlocks = extractBlocksDesign(blocks);

  const design = JSON.parse(template.design);
  let baseTemplate = {
    ...design,
    body: { ...design.body, rows: generatedBlocks },
  };
  callback(baseTemplate);
};

/**
 * Get blocks design with feeds replacement
 */
export const extractBlocksDesign = (blocks) => {
  return blocks.reduce((generatedBlocks, block) => {
    let design;
    if (
      TEMPLATE_TYPES["UNREFERENCED_BLOCK"] == block.type ||
      block.feeds?.length > 0
    ) {
      try {
        if (TEMPLATE_TYPES["UNREFERENCED_BLOCK"] !== block.type) {
          design = replaceContentFeed(block.design, block.feeds, true);

          design = JSON.parse(design);
          design.columns = design.columns.map((column) => {
            let col = JSON.stringify(column);
            let regex = new RegExp(
              "(" +
                ALL_LAYOUT_STRING_CODES.map(({ key }) => key).join("|") +
                ")",
              "g",
            );
            let s = (col.match(regex) || []).length;
            if (s > 0) {
              return EMPTY_COLUMN_EXAMPLE;
            }
            return column;
          });
        } else {
          let content = replaceUnreferencedBlock(block.design);
          design = JSON.parse(content);
        }
      } catch (ex) {
        console.error(ex);
      }
    }
    return design ? generatedBlocks.concat(design) : generatedBlocks;
  }, []);
};

/**
 * Build new template (design & html) from existed template & blocks
 */
export const constructTemplateFromBlocks = (blocks, template) => {
  const $ = cheerio.load(template.content);

  $("body > table > tbody > tr > td").html(
    blocks
      .map(({ content }) => {
        return content;
      })
      .join(""),
  );

  const rows = blocks.map(({ design }) =>
    JSON.parse(removeDynamicFieldsIndexes(design)),
  );

  let design = JSON.parse(removeDynamicFieldsIndexes(template.design));
  design.body.rows = rows;
  return {
    content: removeDynamicFieldsIndexes($.html()),
    design,
  };
};
