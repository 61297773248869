import React, { Component } from "react";
import _ from "i18n";

class ValidMailInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { target, emailValue, onInputChange, onButtonClick, isFocused, errorMsg } =
      this.props;

    return (
      <div className="valid-email-container">
        <div className="email-input">
          <input
            autoFocus={isFocused}
            className="input-box"
            type="email"
            placeholder={_("mail_address")}
            value={emailValue || ""}
            onChange={(e) => onInputChange(e, target)}
          />
          {errorMsg && <span className="email-error-msg">{errorMsg}</span>}
        </div>
        <button
          className="columns btn success fix-btn"
          onClick={(e) => onButtonClick(target)}
          disabled={!emailValue || errorMsg}
        >
          {_("Fix")}
        </button>
      </div>
    );
  }
}

export default ValidMailInput;
