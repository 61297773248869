
// Roles
export const ADMIN_ROLE = 'ADMIN';
export const USER_ROLE = 'USER';

export const TTP_SUPERVISOR_ROLE = 'TTP_ADMIN_SUPERVISOR';
export const TTP_ADMIN_ROLE = 'TTP_ADMIN';

// privileges
export const CAN_CREATE_EMAIL = 'allowCreateCampaign';
export const CAN_CREATE_SMS = 'allowCreateSms';
