import { createStore as createReduxStore } from "redux";

import { createReducer } from "./createReducer";
import { createEnhancer } from "./createEnhancer";

export const createStore = (initialState = {}) => {
  const reducer = createReducer();
  const enhancer = createEnhancer();

  const store = createReduxStore(reducer, initialState, enhancer);

  return store;
};
