import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import _ from "i18n";
import { getStatusColorClass } from "../../../services/campaign";
import { getDate } from "../../../services/date";
import { SVG_DELETE_ICON, ucFirst } from 'utils';
import { SVG_RESEND_ICON, SVG_SEND_ICON } from "../../../services/svgIcones";

export class MailItem extends Component {

  render() {
    let { language, item, handleResendMail, handleDeleteMail, viewEBoxPreview, status } = this.props;
    const colorClass = getStatusColorClass(item.status, item.openCount, item.clickAt);

    if (!item.campaign) {
      return null
    }

    return (
      <div key={`mail-${item.id}`} className={`row list-item border-${colorClass}`}
        onClick={() => viewEBoxPreview(item)}>
        <div className='list-item__border'>
          {SVG_SEND_ICON}
        </div>
        <div className={`list-item__sender small-3 ${(status !== 'SENT') && "p-r-s"}`}><span
          className="e-box-from-name list-item__header ">{status !== 'SENT' ? item.campaign['fromName'] ? item.campaign['fromName'] : _('undefined') : ucFirst(_("to")) + " : "} </span>
          {status !== 'SENT' ? <br /> : "  "} {status === 'SENT' ? item.email : item.campaign['from']}
        </div>
        <div className="small-5 p-l-s p-r-s">{item.hasAttachments &&
          <i className="icon-left icon-paper-clip" />}{item.campaign['object']}</div>
        <div className="small-2 p-l-s p-r-s">
          <div className="padding-left">
            <div className="list-item__sub-header">
              <i className="icon-left icon-clock" />
              <span>{getDate((!item.sentAt) ? item.createdAt : item.sentAt, language)}</span>
            </div>
          </div>
        </div>
        <div className="medium-2 p-l-s p-r-s">
          <div className={"list-item__status"}>
            <i className={`bg-${colorClass}`} />
            {_(item.status)}
          </div>
          <div className="list-item__actions">
            <div className="resend-icon" data-for={"resend" + item.id} data-tip={_('resend')} onClick={(e) => {
              e.stopPropagation();
              handleResendMail({ id: item.id, email: item.email })
            }}>
              {SVG_RESEND_ICON}
              <ReactTooltip id={"resend" + item.id} type='dark' place="bottom" />
            </div>
            {
              (item.status) === "DELETED" ? "" :
                <div className="delete-icon" data-for={"delete" + item.id} data-tip={_('delete')} onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteMail(item.id)
                }}>
                  {SVG_DELETE_ICON}
                  <ReactTooltip id={"delete" + item.id} type='dark' place="bottom" />
                </div>
            }
          </div>

        </div>
      </div>
    )
  }
}
