import React, { Component } from 'react'
import { connect } from 'react-redux';

import { getRate } from 'utils';
import _ from 'i18n';

import { TEMPLATE_TYPES } from 'types';

const mapStateToProps = (state, ownProps) => ({
  blocks: state.themes.newsletter.blocks,
  base: state.themes.newsletter.base,
});

@connect(mapStateToProps)
export default class ProgressBar extends Component {

  render() {
	const { blocks } = this.props;
	let totalBlocks = 0;
	let filledBlocks = 0;

	for(let i=0; i<blocks.length; i++){
		const { type, feeds, column } = blocks[i];
		if([TEMPLATE_TYPES["UNREFERENCED_BLOCK"], TEMPLATE_TYPES["STATIC_BLOCK"]].indexOf(type) != -1){
			continue;
		}

		totalBlocks += parseInt(column || 1);
		if(feeds && feeds.length>0){
			filledBlocks += feeds.length;
		}
	}
	const rate = getRate(filledBlocks, totalBlocks);

	if(!totalBlocks){
		return null;
	}

    return <div className="tool-header__progress-bar">
      <div className="sh-progress-value1">
        {_('filled blocks')}: {`${filledBlocks}/${totalBlocks}`}
      </div>
      <div className="sh-progress-content">
        <div className="sh-progress-content-container">
          <div className="sh-progress-status">
            <div className="sh-progress-status-value" style={{width: `${rate}%`}}/>
          </div>
          <div className="sh-progress-value2">
            {Math.round(rate)}%
          </div>
        </div>
      </div>
    </div>
  }
}
