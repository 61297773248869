import * as actions from "actions";
import {
  getCampaignStats,
  getRecipientsStats,
  reSendEmail,
  getSMSCampaignStats,
  getSMSRecipientsStats,
  editCampaignLink,
} from "TamtamAPI";
import { pushSourceToken } from "utils";

export const fetchCampaignStats = (campaignId) => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken("FETCH_CAMPAIGN_STATS", dispatch);
    const { token } = getState().auth;

    return dispatch(
      actions.fetchCampaignStats(
        getCampaignStats(token, campaignId, sourceToken)
      )
    );
  };
};

export const fetchRecipientsStats = (campaignId, nolimit) => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken("FETCH_RECIPIENTS_STATS", dispatch);
    const { token } = getState().auth;
    const {
      paginationPage,
      pageSize,
    } = getState().campaigns.stats.recipients.list;
    const {
      name,
      status,
      linkId,
    } = getState().campaigns.stats.recipients.filter;

    return dispatch(
      actions.fetchRecipientsStats(
        getRecipientsStats(
          token,
          paginationPage,
          pageSize,
          campaignId,
          name,
          status,
          linkId,
          sourceToken,
          nolimit
        )
      )
    );
  };
};

export const reSendToRecipient = ({ id, email, updateEmail }) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.reSendEmail(reSendEmail(token, id, email, updateEmail))
    );
  };
};

export const updateCampaignLink = ({ id, link }) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.updateCampaignLink(editCampaignLink(token, id, link))
    );
  };
};

export const fetchSMSCampaignStats = (campaignSMSId) => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken("FETCH_CAMPAIGN_STATS", dispatch);
    const { token } = getState().auth;

    return dispatch(
      actions.fetchCampaignStats(
        getSMSCampaignStats(token, campaignSMSId, sourceToken)
      )
    );
  };
};

export const fetchSMSRecipientsStats = (campaignSMSId) => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken("FETCH_RECIPIENTS_STATS", dispatch);
    const { token } = getState().auth;
    const {
      paginationPage,
      pageSize,
    } = getState().campaigns.stats.recipients.list;
    const {
      name,
      status,
      linkId,
    } = getState().campaigns.stats.recipients.filter;
    let clientId = getState().auth.currentClient.id;

    return dispatch(
      actions.fetchRecipientsStats(
        getSMSRecipientsStats(
          token,
          paginationPage,
          pageSize,
          campaignSMSId,
          name,
          status,
          linkId,
          sourceToken
        )
      )
    );
  };
};
