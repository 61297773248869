import React, { Component } from "react";
import { connect } from 'react-redux';
import Select, { components } from 'react-select';

import { setEventsFilters } from 'actions';
import _ from 'i18n';
import TTPSelect from "common/TTPSelect";
import { getPaymentDocumentTypes, resolvePaymentType } from "../../../../../services/payment";
import Application from "../../../../common/Application";
import AppRefSelector from "./AppRefSelector";

@connect((store) => {
  return {
    events: store.filters.events.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters
  }
}, (dispatch) => {
  return {
    handleAppRefChange: (appsRef) => dispatch(setEventsFilters({ property: 'appRef', operator: 'in', value: (appsRef.length > 0) ? appsRef : "" })),
    handleReminderTypeChange: (rappelType) => dispatch(setEventsFilters({ property: 'rappelType', operator: 'eq', value: rappelType })),
    handleAppTypeChange: (appType) => dispatch(setEventsFilters({ property: 'appType', operator: 'eq', value: appType })),
    handleDiscriminantChange: (discriminant) => dispatch(setEventsFilters({ property: 'discriminant', operator: 'eq', value: discriminant })),
  }
})
class PaymentFilters extends Component {

  reminderTypesOptions = getPaymentDocumentTypes();
  appTypesOptions = [
    { label: <Application name={'EVENT'} />, id: 'registering' },
    { label: <Application name={'EVENT_CYCLE'} />, id: 'cycle_registering' }
  ];

  componentDidMount() {
    const { eventFilters, handleReminderTypeChange, handleDiscriminantChange, handleAppTypeChange } = this.props;

    let filterMapping = this.getFiltersMapping(eventFilters);
    if (!filterMapping['discriminant-eq']) {
      handleReminderTypeChange(1);
      handleDiscriminantChange('order');
    }
    if (!filterMapping['appType-eq']) {
      handleAppTypeChange('registering');
    }
  }

  getFiltersMapping = (filters) => {
    return filters.reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});
  }

  handleTypeChange = (selectedValue) => {
    this.props.handleReminderTypeChange(selectedValue?.rappelType);
    this.props.handleDiscriminantChange(selectedValue?.discriminant);
  }

  handleAppTypeChange = (appType) => {
    this.props.handleAppTypeChange(appType);
    this.props.handleAppRefChange([]);
  }

  render() {
    const { eventFilters, handleAppRefChange } = this.props;

    let filterMapping = this.getFiltersMapping(eventFilters);

    const appRefValues = filterMapping['appRef-in']?.map(id => id + "") || "";
    const selectedAppType = filterMapping['appType-eq'] || "registering";
    const resolvedType = resolvePaymentType(filterMapping['discriminant-eq'], filterMapping['rappelType-eq']);

    let options = this.reminderTypesOptions.reduce((acc, o) => acc.concat(o.options), []);
    const selectedType = options.find(({ value }) => value == resolvedType);


    return (
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('type')}</h1>
            <TTPSelect
              notClearable
              simple
              values={selectedAppType}
              placeholder={_('Select type')}
              options={this.appTypesOptions}
              onChange={this.handleAppTypeChange} />
          </div>
        </div>
        {
          <AppRefSelector
            appType={selectedAppType}
            appRefs={appRefValues}
            onChange={handleAppRefChange}
          />
        }
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('reminder type')}</h1>
            <Select
              value={selectedType}
              placeholder={_('Select type')}
              onChange={this.handleTypeChange}
              options={this.reminderTypesOptions}
              className={`ttp-select ${selectedType ? "empty-box" : ''}`}
              classNamePrefix="ttp-select"
              components={{ Option: CustomTypeOption }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentFilters;

const CustomTypeOption = props => {
  const { data: { discriminant, rappelType } } = props;

  return <components.Option {...props} >
    <span className="payment-type__option">
      {_('reminder')}
      <i className={discriminant}>
        {rappelType}
      </i>
    </span>
  </components.Option>
};
