import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSelectedUsers } from 'actions';
import _ from 'i18n';
import {
  SVG_ADD_ICON, SVG_CLOSE_ICON,
  SVG_SETTINGS_ICON,
} from "../../services/svgIcones";
import Bubble from 'common/Bubble';
import { TTP_HOME_URL, TTP_EMAILING_URL } from 'Config';

@connect((store) => {
  return {
    language: store.params.lng,
  }
})
export default class Bubbles extends Component {

  toggleSelectAll() {
    let { handleSelectDeselectSvg, handleSettingsTab } = this.props;

    const selectAll = document.getElementsByClassName('bubble__select-all').item(0);
    const usersAvatar = document.getElementsByClassName('employee');
    const childSpan = selectAll.getElementsByTagName('span').item(0);

    if (selectAll.classList.contains('deselect')) {
      for (let i = 0; i < usersAvatar.length; i++) {
        usersAvatar[i].classList.remove('selected');
        usersAvatar[i].getElementsByClassName('icon').item(0).classList.remove('active');
      }
      selectAll.classList.remove('deselect');
      childSpan.textContent = _('Select All');
      handleSelectDeselectSvg("select");
      handleSettingsTab(false);
    } else {
      for (let i = 0; i < usersAvatar.length; i++) {
        usersAvatar[i].classList.add('selected');
        usersAvatar[i].getElementsByClassName('icon').item(0).classList.add('active');
      }
      selectAll.classList.add('deselect');
      childSpan.textContent = _('Deselect All');
      handleSelectDeselectSvg("deselect");
      handleSettingsTab(true);
    }
  }

  displaySetting() {
    const { dispatch } = this.props;

    const mask = document.getElementsByClassName('mask').item(0).classList;
    const sidebar = document.getElementById('settings__sidebar');
    const controls = document.getElementById('controls');

    mask.remove('hide');
    if (sidebar && controls) {
      sidebar.classList.add('display');
      controls.classList.add('display');
    }
    const selectedUsers = document.getElementById('setting-users').getElementsByClassName('selected');
    let ids = [];
    if (selectedUsers && selectedUsers.length > 0) {
      for (let i = 0; i < selectedUsers.length; i++) {
        ids.push(parseInt(selectedUsers.item(i).id.split('-')[1]));
      }
    }
    dispatch(setSelectedUsers(ids));
  }

  renderNewUserLink() {
    const addUserLink = `${TTP_HOME_URL}users/create`;
    const returnto = encodeURI(`${TTP_EMAILING_URL}settings`);

    return window.location = `${addUserLink}?returnto=${returnto}`;
  }

  render() {
    let { selectDeselectSvg, settingsIsActive, language } = this.props;

    return (
      <div className="campaign-bubbles bubbles">
        <div className="circleSvg" onClick={this.renderNewUserLink.bind(this)}>
          {SVG_ADD_ICON}
          <span
            style={(language === "en" ? { paddingLeft: '1rem' } : { paddingLeft: '.5rem' })}>{_('New user')}
          </span>
        </div>

        <div
          className={`${(selectDeselectSvg === SVG_CLOSE_ICON) && "active"} bubble__select-all circleSvg`}
          onClick={this.toggleSelectAll.bind(this)}>
          {selectDeselectSvg}
          <span
            style={(language === "en" ? { paddingLeft: '1rem' } : { paddingLeft: '.5rem' })}>{_('Select All')}</span>
        </div>

        {settingsIsActive &&
          <Bubble onBubbleClick={this.displaySetting.bind(this)}
            label="Preferences" svgIcon={SVG_SETTINGS_ICON} />
        }

      </div>
    );
  }
};
