import React, { Component } from 'react';
import { getSMSBillById } from 'thunks';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import 'flag-icon-css/css/flag-icon.css'

import _ from 'i18n';
import { setCurrentNavPage } from "actions";
import { amountFormatter, numberFormatter, getDate, modalStyle } from "utils";

@connect((store) => {
  return {
    detail: store.smsBills.list.detail,
    language: store.params.lng,
  };
})
export default class SMSBillDetail extends Component {

  componentWillMount() {
    const { dispatch, id } = this.props;
    dispatch(setCurrentNavPage("SETTINGS"));
    dispatch(getSMSBillById(id));
  }

  reduceBillDataTotals(billDataObj) {
    var dump = {};
    dump['recipientsCount'] = 0;
    dump['smsCountByCountry'] = 0;
    dump['totalAmountByCountry'] = 0;
    let totals = billDataObj.reduce((prev, obj) => {
      dump['totalAmountByCountry'] += parseFloat(obj.totalAmountByCountry);
      dump['recipientsCount'] += parseInt(obj.recipientsCount);
      dump['smsCountByCountry'] += parseInt(obj.smsCountByCountry);
      return dump;
    }, dump);
    return totals;
  }

  render() {
    const { detail: { detailedBill }, onCloseModal, language } = this.props;
    const totals = this.reduceBillDataTotals(detailedBill);

    let campaignName = "";
    let creationDate = "";

    if (detailedBill && detailedBill.length) {
      campaignName = detailedBill[0].campaign;
      creationDate = getDate(detailedBill[0].createdAt, language);
    }

    let customModalStyle = {
      ...modalStyle,
      content: {
        ...modalStyle['content'],
        top: '30%',
        width: "90%",
        minHeight: "40%",
        height: "auto",
        padding: "45px",
        paddingBottom: "45px",
        inset: "50% 40px auto 50%"
      }
    };
    return (
      <ReactModal isOpen={true} contentLabel="Modal" style={customModalStyle}
        onRequestClose={onCloseModal}
        shouldCloseOnOverlayClick={true}>
        <span onClick={onCloseModal} className="modal-close close"></span>
        <div id="sms-bill-detail">
          <h4>{_('detailed_bill')}</h4>
          <div className="bill-detail row">
            <div>{_('campaign_name')}: <span>{campaignName}</span></div>
            <div>{_('campaign_created_at')}:
              <span>{creationDate}</span>
            </div>
          </div>
          <div className="row align-center ttp-datatable">
            <div className="row medium-12 align-center ttp-datatable__header">
              <div className="columns medium-1 text-center">{_('country')}</div>
              <div className="columns medium-2 text-right">{_('unit_price')}</div>
              <div className="columns medium-3 text-right">{_('delivered_by_country')}</div>
              <div className="columns medium-3 text-right">{_('sms_delivered_by_country')}</div>
              <div className="columns medium-3 text-right">{_('total_amount_by_country')}</div>
            </div>
            {detailedBill.map(item => {
              if (item.price && item.smsCountByCountry) {
                return <div key={`bill-detail-${item.id}`} className="row medium-12 align-center ttp-datatable__row">
                  <div className="columns medium-1 text-center"><span
                    className={`flag-icon flag-icon-${item.countryCode}`}></span></div>
                  <div className="columns medium-2 text-right">{amountFormatter(item.price, 3, language)}</div>
                  <div className="columns medium-3 text-right">
                    <span className="align-center">{numberFormatter(item.recipientsCount, 0, language)}</span>
                  </div>
                  <div className="columns medium-3 text-right">{numberFormatter(item.smsCountByCountry, 0, language)}</div>
                  <div className="columns medium-3 text-right">{amountFormatter(item.totalAmountByCountry, 2, language)}</div>
                </div>
              }
            })}
          </div>
          <div className="bill-detail row">
            <div>{_('total_delivered_recipients')}: <span>{(totals) ? numberFormatter(totals.recipientsCount, 0, language) : ''}</span></div>
            <div>{_('total_delivered_sms')}: <span>{(totals) ? numberFormatter(totals.smsCountByCountry, 0, language) : ''}</span></div>
            <div>{_('amount_due')}: <span>{(totals) ? amountFormatter(totals.totalAmountByCountry, 2, language) : ''}</span></div>
          </div>
        </div>
      </ReactModal>
    );
  }
}
