import React, { Component } from "react";
import { connect } from "react-redux";
import LineChartComponent from "./LineChartComponent";
import _ from 'i18n';

class ActiveEvolutionChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChart: [],
      dataKeysY:[{ key: "totalVeryActive", label: "trés active" }]
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const { totalVeryActive } = prevProps;
    if (totalVeryActive !== this.props.totalVeryActive) {
      this.setState({
        dataChart: this.props.totalVeryActive,
        dataKeysY:[{ key: "totalVeryActive", label: _("Very active") }]
      });
    }
  }
  buttonsCoponent = () => {
    return (
      <div className="buttonComponent">
        <button
          className={((this.state.dataKeysY[0].key !== "totalVeryActive") && "button_line" ) || "button_line active"}
          onClick={() =>
            this.setState({
              dataChart: this.props.totalVeryActive,
              dataKeysY:[{ key: "totalVeryActive", label: _("Very active") }]
            })
          }
        >
          {_("Very active")}
        </button>
        <button
          className={(this.state.dataKeysY[0].key !== "totalModeratelyActive")? "button_line": "button_line active"}
          style={{ width: "28%" }}
          onClick={() =>  this.setState({ dataChart: this.props.totalModeratelyActive,dataKeysY:[{ key: "totalModeratelyActive", label: _("Moderately active")}] })}
        >
          {_("Moderately active")}
        </button>
        <button
          className={(this.state.dataKeysY[0].key !== "totalLowActive")? "button_line": "button_line active"}
          onClick={() => this.setState({ dataChart: this.props.totalLowActive,dataKeysY:[{ key: "totalLowActive", label: _("Not very active") }]  })}
        >
          {_("Not very active")}
        </button>
        <button
          className={(this.state.dataKeysY[0].key !== "totalNotActive")? "button_line": "button_line active"}
          onClick={() => this.setState({ dataChart: this.props.totalNotActive,dataKeysY:[{ key: "totalNotActive", label: _("Inactive") }]  })}
        >
          {_("Inactive")}
        </button>
      </div>
    );
  };

  render() {
    const dataKeyX = { key: "date", label: "date" };
    const yAxisTitle = "Number of persons";
    return (
      <div>
        {this.buttonsCoponent()}
        <LineChartComponent
          dataChart={this.state.dataChart}
          dataKeysY={this.state.dataKeysY}
          dataKeyX={dataKeyX}
          yAxisTitle={yAxisTitle}
        />
      </div>
    );
  }
}
const mapStateToProps = (store) => ({
  totalVeryActive: store.dashboard.totalVeryActive,
  totalNotActive: store.dashboard.totalNotActive,
  totalModeratelyActive: store.dashboard.totalModeratelyActive,
  totalLowActive: store.dashboard.totalLowActive,
});
export default connect(mapStateToProps)(ActiveEvolutionChart);
