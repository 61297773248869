import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'i18n';
import TypeEmails from "./TypeEmails";
import Preview from "./email/Preview";
import Editor from "./email/Editor";
import { ucFirst } from 'utils';
import Test from './email/Test';
import Warning from './Warning';
import TypeEmailsFetching from "../../fetching/TypeEmailsFetching";
import { setThemeLanguage } from "actions";
import CheckBoxBtn from "../../common/CheckBoxBtn";
import { postResizeMessage } from '../../../services/utils';

class EmailsList extends Component {

  resizerTimeOutId = null;

  state = {
    activeTypes: [],
    emails: [],
    isVisible: 'NONE',
  };

  componentDidMount() {
    this.props.fetchEmails(this.props.settingsId);
    postResizeMessage();
    this.props.setThemeLanguage(null);

  }

  componentDidUpdate(prevProps) {
    const { emails } = this.props;
    if (emails !== prevProps.emails) {
      const items = emails.reduce((rv, ge) => {
        rv[ge.targetType] = rv[ge.targetType] || [];
        rv[ge.targetType][ge.language] = ge;
        return rv;
      }, {});
      this.setState({ emails: items });
      setTimeout(postResizeMessage, 300);
    }
  }

  setActiveTypes = (isVisible, type = null) => {
    const { types } = this.props;
    const { activeTypes } = this.state;

    switch (isVisible) {
      case "NONE":
        this.setState({ activeTypes: [], isVisible: "NONE" });
        break;
      case "ALL":
        this.setState({ activeTypes: (types.map(currentType => currentType.id)), isVisible: "ALL" });
        break;
      case "ONE": {
        if (activeTypes.some((tid) => tid === type)) {
          this.setState(
            { activeTypes: activeTypes.filter(tid => tid !== type), isVisible: "ONE" }
          );
        } else {
          this.setState({ activeTypes: [...activeTypes, type], isVisible: "ONE" });
        }
        break;
      }
    }
    clearTimeout(this.resizerTimeOutId);
    this.resizerTimeOutId = setTimeout(postResizeMessage, 1000);
  };

  renderWarningData() {
    const { types, languages, userLanguage, fetching } = this.props;
    const { emails } = this.state;
    if (fetching || !types || !types.length) {
      return null;
    }
    let warnings = [];
    let sortedTypes = this.handleSortTypes(types);
    sortedTypes.forEach(type => {
      const activeLanguages = Object.keys(emails[type.id] ? emails[type.id] : []) || [];
      const unavailableLanguages = languages.filter(lng => activeLanguages.indexOf(lng) < 0);
      if (unavailableLanguages && unavailableLanguages.length) {
        warnings.push(
          <Warning
            key={type.id}
            languages={unavailableLanguages}
            label={type[`label${ucFirst(userLanguage)}`]}
            required={type.required}
            clicked={() => this.handleWarningNoteClick(type)}
          />
        );
      }
    });

    return warnings;
  }

  handleWarningNoteClick = (type) => {
    const { activeTypes } = this.state;
    const element = document.getElementById(type.id);
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    if (activeTypes !== type.id) {
      this.setActiveTypes("ONE", type.id);
    }
  };

  handleSortTypes = (types) => {
    return types.sort((a, b) => (a.rank > b.rank) ? 1 : -1)
  };

  renderGenericEmailItem = () => {
    const { types, fetching, languages, settingsId } = this.props;
    const { emails, activeTypes } = this.state;

    let sortedTypes = this.handleSortTypes(types);

    if (fetching) {
      return <TypeEmailsFetching />;
    }
    return sortedTypes.map((type, i) => (
      <TypeEmails
        id={emails[type] ? emails[type]["id"] : null}
        key={`type-${i}`}
        settingsId={settingsId}
        emails={emails[type.id] ? emails[type.id] : []}
        languages={languages}
        active={activeTypes.some(t => t === type.id)}
        type={type}
        onToggleActive={() => this.setActiveTypes("ONE", type.id)}
        onPreview={this.showPreview}
      />
    )
    )
  };

  toggleShowAll = (e) => {
    const { isVisible } = this.state;
    const allVisible = isVisible === "ALL";
    this.setActiveTypes(allVisible ? "NONE" : "ALL");
    e.stopPropagation();
    e.preventDefault();
  };


  render() {
    const { fetching, onReset } = this.props;
    const { isVisible } = this.state;
    const allVisible = isVisible === "ALL";
    let warnings = this.renderWarningData();
    return (
      <div className="email-list-step">
        <div className="sidebar__actions">
          <div className="row">
            {(!fetching && warnings && warnings.length > 0) && <div className="columns small-12 sidebar__warning">
              <h5><i className="icon-left icon-pin" />{_('UnavailableLanguagesNote')}</h5>
              <div className="row notes-container">
                {warnings}
              </div>
            </div>
            }
            <div className="columns small-12 emails-actions">
              <button className="text-capitalize" onClick={onReset}>
                <i className='icon  icon-reload' />
                {_('reset emails')}
              </button>
              <button onClick={this.toggleShowAll}>
                <CheckBoxBtn label={_('show all')} checked={allVisible ? "ALL" : ""} />
              </button>
            </div>
          </div>
        </div>
        {this.renderGenericEmailItem()}
        {/* email actions*/}
        <Test />
        <Preview />
        <Editor />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  emails: state.genericEmail.currentSetting.emails.items,
  settingsId: state.genericEmail.currentSetting.id,
  types: state.genericEmail.currentSetting.types || [],
  languages: state.genericEmail.currentSetting.languages,
  fetching: state.genericEmail.currentSetting.emails.fetching,
  activeLanguage: state.themes.filter.language,
  userLanguage: state.params.lng
});
const mapDispatchToProps = (dispatch) => ({
  setThemeLanguage: (lng) => dispatch(setThemeLanguage(lng)),

});
export default connect(mapStateToProps, mapDispatchToProps)(EmailsList);
