import React, {Component} from 'react';
import {connect} from 'react-redux';

import PageHeader from 'common/PageHeader';
import {
  clearSourcesTokens,
  setCurrentNavPage
} from "../../actions/actions/params";
import {SVG_ADD_ICON, SVG_EDIT_ICON} from "../../services/svgIcones";
import _ from 'i18n';
import BackDrop from "../ui/BackDrop";
import MailingListForm from "./MailingListForm";
import {fetchMailingLists} from "../../actions/thunks/mailingList";
import NotFound from 'notFound/NotFound';
import MailingListItem from "./MailingListItem";
import MailingListsFetching from '../fetching/MailingListsFetching';
import {
  initMailingList,
  initMailingListsFilter, initMailingListsItems,
  setMailingListsPaginationPage
} from "../../actions/actions/mailingList";
import TTPPaginator from 'common/list/TTPPaginator';
import MailingListsFilter from "./MailingListsFilter";
import {ucFirst} from "../../services/common";

@connect()
class MailingListsPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listFormOpened: false,
      iconStatus: "ADD",
      activeLanguage: "fr"
    };

    this.handleChangeIcon = (iconStatus) => {this.setState({iconStatus})};
    this.setActiveLanguage = (activeLanguage) => this.setState({ activeLanguage });
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(setCurrentNavPage("MAILING_LISTS"));
    dispatch(initMailingListsFilter());
    dispatch(fetchMailingLists());
  }

  componentDidUpdate(prevProps, prevState) {
    const {dispatch} = this.props;
    if (prevProps.filter !== this.props.filter) {
      dispatch(fetchMailingLists());
      dispatch(initMailingListsItems());
    }
  }

  componentWillUnmount() {
    const {_requests, dispatch} = this.props;
    _requests.forEach(
      req => req.sourceToken.cancel("Operation canceled by the user.")
    );
    dispatch(clearSourcesTokens());
  }

  renderMailingLists() {
    const {mailingLists, fetching} = this.props;
    let items = [];

    if (mailingLists && mailingLists.length > 0) {
      for (let mailingList of mailingLists) {
        let mailingListData = this.getMailingListDataByLanguage(mailingList);
        items.push(<MailingListItem {...mailingList}
                                    name={mailingListData.name}
                                    description={mailingListData.description}
                                    key={`mailing-list-${mailingList.id}`}
                                    closeListForm={this.toggleListFormSideBar}
                                    handleChangeIcon={this.handleChangeIcon}
        />);
      }
    }
    if (fetching) {
      for (let index = 0; index < 8; index++) {
        items.push(<MailingListsFetching
          key={`mailing-list-fetching-${index}`}/>);
      }
    }

    return items;
  }

  handleMailingListPageClick = ({selected}) => {
    this.props.dispatch(setMailingListsPaginationPage(selected + 1));
  };

  getMailingListDataByLanguage = (mailingList) => {
    const {language} = this.props;
    const ucFirstUserLanguage = ucFirst(language);

    let dataByLanguage = {};

    if (mailingList[`name${ucFirstUserLanguage}`]) {
      dataByLanguage.name = mailingList[`name${ucFirstUserLanguage}`];
      dataByLanguage.description = mailingList[`description${ucFirstUserLanguage}`];
    } else {
      ['En', 'Nl', 'Fr'].map(lng => {
        if (mailingList[`name${lng}`]) {
          dataByLanguage.name = mailingList[`name${lng}`];
          dataByLanguage.description = mailingList[`description${lng}`];
        }
      });
    }

    return dataByLanguage;
  };

  toggleListFormSideBar = () => {
    const {dispatch, data: {id}} = this.props;
    this.setState({listFormOpened: !this.state.listFormOpened, activeLanguage: "fr"});
    if (id) {
      dispatch(initMailingList());
    }
  };
  
  render() {
    const {language, fetching, filter: {pageSize, paginationPage}, error, nbResult} = this.props;
    const {listFormOpened, iconStatus, activeLanguage} = this.state;

    let headerProps = {
      header: "mailing lists",
      subHeader: "Manage mailing lists .",
      icon: 'MAILING_LIST',
    };

    return (
      <div className="mailing-lists">
        <PageHeader  {...headerProps}/>
        <div className="medium-11 row">
          <MailingListsFilter/>
        </div>
        {(error != null && error.code === 404) ?
          <NotFound mainClass="small-12"/> :
          <div className={`row medium-11 mailing-lists__container`}>
            {this.renderMailingLists()}
          </div>}
        <div className="campaign-bubbles bubbles">
          <div className="circleSvg" onClick={this.toggleListFormSideBar}>
            {iconStatus === "ADD" ? SVG_ADD_ICON : SVG_EDIT_ICON}
            <span
              style={(language === "fr" ? {paddingLeft: '.5rem'} : {paddingLeft: '1rem'})}>{_('New list')}
          </span>
          </div>
        </div>
        <BackDrop show={listFormOpened} clicked={this.toggleListFormSideBar}/>
        <MailingListForm
          opened={listFormOpened}
          toggleListFormSideBar={this.toggleListFormSideBar}
          setActiveLanguage={this.setActiveLanguage}
          activeLanguage={activeLanguage}
        />
        {(!fetching && error == null) &&
        <TTPPaginator onPageChange={this.handleMailingListPageClick}
                      pageSize={pageSize}
                      nbResult={nbResult}
                      paginationPage={paginationPage}/>
        }
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  _requests: store.params.sourcesTokens,
  language: store.params.lng,
  mailingLists: store.mailingLists.items,
  fetching: store.mailingLists.fetching,
  filter: store.mailingLists.filter,
  error: store.mailingLists.error,
  nbResult: store.mailingLists.nbResult,
  data: store.mailingLists.data
});

export default connect(mapStateToProps, null)(MailingListsPage);

