import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import { getDate } from 'utils';
import Viewer from "../../common/Viewer";
import { TTP_API_URL } from "Config";
import { Attachment } from "../attachments/Attachment";

@connect((store) => {
  return {
    mailData: store.ebox.list.eBoxMailData.mailData,
    fetching: store.ebox.list.eBoxMailData.fetching,
    lang: store.params.lng,
    auth: store.auth,
    owner: store.ebox.owner.data,
  }
})
export default class MailViewer extends Component {

  renderAttachments = (fetching, attachments) => {
    return <div className={"attachment"}>
      {fetching ?
        [1, 2, 3].map(i => <div key={"fetching" + i} className={"attachment__container gradiant"} />) :
        (attachments && attachments.length > 0 ? attachments.map((url, i) => <Attachment key={"attachment-" + i} url={url} type={"ALL"} />) :
          <div className="attachment__container notfound">{_("no attachment found")}</div>)
      }
    </div>
  };


  render() {
    let { fetching, isOpen, onClose, lang, inOtherEbox, handleResendMail,
      mailData: { id, preview, cid, from, fromName, mainEmail, object, date, attachments, email, folder },
      auth: { user },
      owner,
    } = this.props;
    const url = TTP_API_URL + "/mailing/web-version/" + (cid || '');
    const u = !inOtherEbox ? user : owner;
    const avatar = u.avatarUrl ? u.avatarUrl :
      "https://ui-avatars.com/api/?background=18A0FB&color=fff&bold=true&name=" + u.lastName + "+" + u.firstName;
    return <Viewer isOpen={isOpen} onClose={onClose} preview={preview} url={url} loading={fetching}>
      <div className="viewer__info">
        <div className="user-avatar">
          <img src={avatar} alt="" />
        </div>
        <div className="user-info">
          <div className="viewer__aside__title">{u.lastName + " " + u.firstName || "---"}</div>
          {mainEmail && <div className="viewer__aside__subtitle">{_('to')} : {mainEmail}</div>}
        </div>
        <div className={"spacer"} />
        <div className={"resend-btn"} onClick={() => handleResendMail({ id, email: mainEmail })}>
          <i className="icon icon-paper-plane" />
        </div>
      </div>
      <div className="viewer__aside__container">
        <div className="viewer__aside__block">
          <div className="viewer__aside__title">{_('from')} :</div>
          <div className="viewer__aside__subtitle"><stong>{fromName}</stong> &lt;{from}&gt;</div>
        </div>
        <div className="viewer__aside__block">
          <div className="viewer__aside__title">{_('to')} :</div>
          <div className="viewer__aside__subtitle">&lt;{email}&gt;</div>
        </div>
        {folder &&
          <div className="viewer__aside__block">
            <div className="viewer__aside__title">{_('folder')} :</div>
            <div className="viewer__aside__subtitle">{folder}</div>
          </div>
        }
        <div className="viewer__aside__block">
          <div className="viewer__aside__title">{_('subject')} :</div>
          <div className="viewer__aside__text">{object}</div>
        </div>
        <div className="viewer__aside__block">
          <div className="viewer__aside__title">{_('date')} :</div>
          <div className="viewer__aside__text">{getDate(date, lang)}</div>
        </div>
      </div>
      {this.renderAttachments(fetching, attachments)}
    </Viewer>;
  }
}
