import axios from '../../axios-api';

export const persistEmailsSettings = (token, data) => {
  const requestUrl = `/mailing/generic-email-settings`;

  const formData = new FormData();
  formData.append('access_token', token);

  if (data.id) {
    formData.append('id', data.id);
  }
  ['Fr', 'Nl', 'En'].map(lng => {
    formData.append(`fromName${lng}`, data[`fromName${lng}`]);
    formData.append(`fromEmail${lng}`, data[`fromEmail${lng}`]);
    formData.append(`theme${lng}`, (data[`theme${lng}`] && data[`theme${lng}`].id) ? data[`theme${lng}`].id : null);

  });
  if (data.languages && data.languages.length) {
    data.languages.forEach((lng, index) => formData.append(`languages[${index}]`, lng))
  }
  formData.append('types', JSON.stringify(data.types || []));
  formData.append('targetApp', data.targetApp);
  formData.append('organization', data.organization);
  formData.append('targetId', data.target ? data.target.id : null);
  if (data.action) {
    formData.append('action', data.action);
  }

  return axios.post(requestUrl, formData);
}

export const getGenericEmails = (token, data) => {
  const requestUrl = `/mailing/generic-email`;
  const fields = ['*', 'textModel', 'theme'];

  const { id, targetId, targetApp, targetType, language, organization } = data;

  let filter = [];

  if (id) {
    filter.push({
      property: "settings",
      value: id,
      operator: "eq"
    });
  }
  if (organization) {
    filter.push({
      property: "genericEmailSettings.organization",
      value: organization,
      operator: "eq"
    });
  }
  if (targetApp) {
    filter.push({
      property: "genericEmailSettings.targetApp",
      value: targetApp,
      operator: "eq"
    });
  }
  if (targetId) {
    filter.push({
      property: "genericEmailSettings.targetId",
      value: targetId,
      operator: "eq"
    });
  }
  if (targetType) {
    filter.push({
      property: "targetType",
      value: targetType,
      operator: "eq"
    });
  }
  if (language) {
    filter.push({
      property: "language",
      value: language,
      operator: "eq"
    });
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
      filter: JSON.stringify(filter),
      nolimit: 1,
    }
  }
  );
};

export const getEmailsSettings = (token, data) => {
  const requestUrl = `/mailing/generic-email-settings`;
  const filter = [
    {
      property: 'organization',
      value: data.organization,
      operator: 'eq'
    }
  ];
  if (data.id) {
    filter.push({
      property: 'id',
      value: data.id,
      operator: 'eq'
    });
  }
  if (data.targetApp) {
    filter.push({
      property: 'targetApp',
      value: data.targetApp,
      operator: 'eq'
    });
  }
  if (data.targetId) {
    filter.push({
      property: 'targetId',
      value: data.targetId,
      operator: 'eq'
    });
  }
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: ['*', 'types', 'theme'].join(","),
      filter: JSON.stringify(filter),
    }
  }
  );
}

export const persistGenericEmail = (token, data) => {
  const requestUrl = `/mailing/generic-email`;
  var formData = new FormData();
  formData.append('access_token', token);
  if (data.id) {
    formData.append('id', data.id);
  }
  formData.append('object', data.object);
  formData.append('text', data.text);
  formData.append('targetType', data.targetType);
  formData.append('settings', data.settingsId);
  formData.append('language', data.language);
  formData.append('originalTextId', data.originalTextId);
  formData.append('theme', data.theme ? data.theme.id : null);
  formData.append('updatedBy', data.updatedBy);
  formData.append('source', data.source);

  return axios.post(requestUrl, formData);
}

export const sendTestEmails = (token, data) => {
  var formData = new FormData();
  const requestUrl = `/mailing/generic-email/test`;
  formData.append('access_token', token);
  if (!data.emails || !data.genericEmailId) {
    return;
  }

  formData.append('genericEmailId', data.genericEmailId);
  data.emails.forEach((email, i) => {
    formData.append(`emails[${i}]`, email);
  });

  return axios.post(requestUrl, formData);
};

let sort = [
  {
    property: 'updatedAt',
    dir: 'desc'
  }
];

export const getOrganisationEmailsSettings = (token, organizationId, status, pageSize, targetApp, paginationPage) => {
  const requestUrl = `/mailing/generic-email-settings`;


  const filter = [
    {
      property: 'organization',
      value: organizationId,
      operator: 'eq'
    }
  ];

  if (status && status.length > 0) {
    filter.push({
      property: 'status',
      value: status,
      operator: 'in',
    });
  }

  if (targetApp && targetApp.length > 0) {
    filter.push({
      property: 'targetApp',
      value: targetApp,
      operator: 'in',
    });
  }

  return axios.get(requestUrl, {
    params: {
      limit: pageSize,
      start: (!paginationPage) ? 0 : ((paginationPage - 1) * pageSize),
      sort: JSON.stringify(sort),
      access_token: token,
      filter: JSON.stringify(filter),
    }
  }
  );
};

export const resetCurrentGenericEmail = (token, genericEmailId) => {
  const requestUrl = `/mailing/reset-generic-email`;
  var formData = new FormData();

  formData.append('access_token', token);
  formData.append('id', genericEmailId);
  return axios.post(requestUrl, formData);
};
