import * as actions from 'actions';
import { pushSourceToken } from 'utils';
import { getNotifications, readWebNotification, getPushNotifications, getNotificationRecipients, persistNotification, getNotification, deleteNotification, duplicateNotificationData } from 'TamtamAPI';
import { getHistory } from '../../router/getHistory';

export const fetchWebNotifications = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;

    let clientId = currentClient ? currentClient.id : null;

    return dispatch(actions.fetchWebNotifications(
      getNotifications(token, clientId)
    ));
  };
};

export const fetchPushNotifications = () => {
  return (dispatch, getState) => {
    const { token, currentClient } = getState().auth;
    let filter = getState().notifications.push.list.filter;
    let clientId = currentClient ? currentClient.id : null;

    return dispatch(actions.fetchPushNotifications(
      getPushNotifications(token, clientId, filter)
    ));
  };
};

export const readNotification = (notificationId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.readNotification(
      readWebNotification(token, notificationId).then((res) => {
        return res.data.data;
      }, err => err)
    ));
  };
};

export const fetchNotificationRecipients = () => {
  return (dispatch, getState) => {
    let sourceToken = pushSourceToken('FETCH_NOTIFICATION_RECIPIENTS', dispatch);

    const { token } = getState().auth;
    const { language, filters, appName, recipients: { filter } } = getState().notifications.push.currentNotification;
    const clientId = getState().auth.currentClient.id;
    /*if (filter.searchWord && !isTotal) {
        filterData = { name, ...filterData };
    }*/
    const data = {
      clientId,
      appName,
      filters,
      language,
      ...filter
    };

    return dispatch(actions.fetchNotificationRecipients(
      getNotificationRecipients(token, data, sourceToken)
    ));
  };
};

export const saveNotification = (data) => {
  return (dispatch, getState) => {
    const { currentNotification } = getState().notifications.push;
    const { auth } = getState();
    const { detached, currentClient, user } = auth;
    let clientId = (currentClient && currentClient.id) || null;
    let userId = (detached && user) ? user.id : null;

    const { token } = auth;
    if (token === "" || (!clientId && !userId)) {
      return;
    }
    data = {
      ...data,
      ...currentNotification,
      targetsCounts: currentNotification.recipients.recipientsTotalCount
    };

    return dispatch(actions.createNotification(
      persistNotification(token, data, clientId, userId).then((res) => {
        const history = getHistory();
        history.push("/notifications");
        return res.data.data;
      }, err => err)
    ));
  };
};

export const fetchPushNotification = (notificationId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;
    let sourceToken = pushSourceToken('FETCH_NOTIFICATION', dispatch);

    return dispatch(actions.fetchPushNotifications(
      getNotification(token, notificationId, sourceToken),
    ));
  };
};

export const removeNotification = (notificationId) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(actions.deleteNotification(
      deleteNotification(token, notificationId)
    ));
  };
};

export const duplicateNotification = (notificationId) => (dispatch, getState) => {
  const { token } = getState().auth;

  return dispatch(actions.duplicateNotificationData(
    duplicateNotificationData(token, notificationId)));
};
