import React, { Component } from 'react';
import { connect } from 'react-redux';
import { onError, amountFormatter } from 'utils';
import _ from 'i18n';
import SMSBuyCredit from './SMSBuyCredit';
import SMSBillsList from './SMSBillsList';
import 'react-notifications/lib/notifications.css';
import { fetchSmsBills } from 'thunks';
import { LIST_PAGE_SIZES } from 'Common';
import { setCurrentNavPage, setSMSBillsPaginationPage, setSMSBillsPageSize, setSmsBillsListFilterStatus, setSmsBillsListFilterType } from "actions";
import { getRealCredit, getAuthorizedCredit } from "thunks";
import PageHeader from 'common/PageHeader';
import TTPPaginator from 'common/list/TTPPaginator';
import SMSBillsFilter from './SMSBillsFilter';

@connect((store) => {
    return {
        smsBills: store.smsBills.list,
        auth: store.auth,
        smsCredit: store.auth.currentClient.smsCredit,
        language: store.params.lng,
        paginationPage: store.smsBills.list.paginationPage,
        pageSize: store.smsBills.list.pageSize,
    }
})

export default class SMSBillsListPage extends Component {
    constructor(props) {
        super(props);
        this.toggleBuySideBar = () => this.setState({ buyIsOpened: !this.state.buyIsOpened });
        this.state = this.getDefaultState();
    }

    getDefaultState() {
        let smsCredit = this.props.smsCredit;

        return {
            buyIsOpened: false,
            smsCredit: smsCredit,
        };
    }

    componentWillUpdate(nextProps, nextState) {
        let { auth, smsBills } = nextProps;
        const { dispatch } = this.props;

        if (auth.currentClient != this.props.auth.currentClient && !smsBills.fetching) {
            dispatch(setCurrentNavPage("SMS_BILLS"));
            this.renderSmsBillsListPage(1, LIST_PAGE_SIZES[0]);
        }
    }

    componentWillMount() {
        const { dispatch, paginationPage, pageSize } = this.props;
        dispatch(setCurrentNavPage("SMS_BILLS"));
        dispatch(setSmsBillsListFilterStatus(""));
        this.renderSmsBillsListPage(paginationPage, pageSize);
        dispatch(getRealCredit()).then(res =>
            this.handleRealCredit(res.value.data.data.smsCredit), err => onError());
    }

    handleRealCredit(credit) {
        this.setState({ smsCredit: credit });
    }

    closeBuySideBar() {
        this.setState({
            buyIsOpened: false
        });
    }

    handleSelectSMSBillsListPageSize(event) {
        let pageSize = event.target.value;
        this.renderSmsBillsListPage(1, pageSize);
    };


    handleSMSBillsListPageClick(data) {
        let selectedPage = data.selected;
        this.renderSmsBillsListPage(selectedPage + 1);
    };

    handleSelectBillType(event) {
        const { dispatch } = this.props;
        let smsBillType = event.target.value;
        dispatch(setSmsBillsListFilterType(smsBillType));
        this.renderSmsBillsListPage(1);
    };

    isCreditSufficient() {
        let { smsCredit } = this.state;
        return (smsCredit > 0);
    }

    renderSmsBillsListPage(paginationPage, pageSize = null) {
        const { dispatch } = this.props;
        dispatch(setSMSBillsPaginationPage(paginationPage));
        if (pageSize) {
            dispatch(setSMSBillsPageSize(pageSize));
        }
        dispatch(fetchSmsBills(true));
    };

    renderPaginate() {
        let { smsBills, paginationPage } = this.props;
        if (smsBills.error != null && smsBills.error.code == 404 && smsBills.fetching) {
            return "";
        }
        return <TTPPaginator onPageChange={this.handleSMSBillsListPageClick.bind(this)}
            pageSize={smsBills.pageSize}
            nbResult={smsBills.nbResult}
            paginationPage={paginationPage} />
    }

    render() {
        let { buyIsOpened, smsCredit } = this.state;
        let { language } = this.props;

        return (
            <div id="sms-bills-page" className="page">
                <PageHeader header="SMS Bills" subHeader="view your invoices and your payment history ."
                    icon="FINANCES_SMS">
                    <button className="btn secondary text-uppercase" onClick={this.toggleBuySideBar}>
                        <img src="img/icons/add.svg" />
                        {_('Buy credit')}
                    </button>
                </PageHeader>
                <SMSBillsFilter />

                <div className="campaign-bubbles bubbles">
                    <div className="info">
                        <img src="/img/icons/money.svg" />
                        <div>
                            <span>{_('authorized_balance')}</span>
                            <span><b>{amountFormatter(smsCredit, 2, language)}</b></span>
                        </div>
                    </div>
                </div>
                <div className="row align-center">
                    <div className="medium-12 columns">
                        <SMSBillsList
                        />
                    </div>
                </div>
                {this.renderPaginate()}

                <div className="overlay" style={(buyIsOpened ? { display: "block" } : { display: "none" })} onClick={this.closeBuySideBar.bind(this)}/>

                <SMSBuyCredit
                    opened={buyIsOpened}
                    onCloseSideBar={this.toggleBuySideBar}
                />
            </div>
        );
    }
}
