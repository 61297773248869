import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import { getDate } from 'utils';
import Viewer from "../../common/Viewer";

@connect((store) => {
    return {
        smsData: store.ebox.smsList.eBoxSmsData.smsData,
        fetching: store.ebox.smsList.eBoxSmsData.fetching,
        language: store.params.lng,
        user: store.auth.user
    }
})
export default class SmsViewer extends Component {

    constructor(props) {
        super(props);
    };

    render() {
        let { fetching, isOpen, onClose, language,
          smsData: { date, phoneNumber, senderPhoneNumber, senderName, name, message}, user} = this.props;
        const avatar = user.avatarUrl ? user.avatarUrl :
            "https://ui-avatars.com/api/?background=18A0FB&color=fff&bold=true&name=" + user.lastName + "+" + user.firstName;
      return <Viewer isOpen={isOpen} onClose={onClose} isSmsEbox={true}
                     message={message} loading={fetching}>
            <div className="viewer__info">
                <div className="user-avatar">
                    <img src={avatar} alt="" />
                </div>
                <div className="user-info">
                    <div className="viewer__aside__title">{user.lastName + " " + user.firstName || "---"}</div>
                </div>
                <div className={"spacer"} />
            </div>
            <div className="viewer__aside__container">
                <div className="viewer__aside__block">
                    <div className="viewer__aside__title">{_('from')} :</div>
                    <div className="viewer__aside__subtitle"><stong>{senderName}</stong> &lt;{senderPhoneNumber}&gt;</div>
                </div>
                <div className="viewer__aside__block">
                  <div className="viewer__aside__title">{_('to')} :</div>
                  <div className="viewer__aside__subtitle">{phoneNumber}</div>
                </div>
                <div className="viewer__aside__block">
                    <div className="viewer__aside__title">{_('campaignName')} :</div>
                    <div className="viewer__aside__text">{name}</div>
                </div>
                <div className="viewer__aside__block">
                    <div className="viewer__aside__title">{_('date')} :</div>
                    <div className="viewer__aside__text">{getDate(date, language)}</div>
                </div>
            </div>
        </Viewer>;
    }
}
