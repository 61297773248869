import { pushSourceToken as pushSourceTokenAction } from 'actions';
import { APP_ENV } from 'Config';

import axios from 'axios';
import { COMMUNITY_DEFAULT_LOGO_URL, COMMUNITY_NAME_MAX_LENGTH } from "../config/Common";

export function getCookie(cname) {
  let name = cname + '=';
  let cookieValue = document.cookie;

  // Replace %E9 with its corresponding UTF-8 encoded value
  cookieValue = cookieValue.replace(/%E9/g, "%C3%A9");

// Replace %E8 with its corresponding UTF-8 encoded value
  cookieValue = cookieValue.replace(/%E8/g, "%C3%A8");

  let decodedCookie = decodeURIComponent(cookieValue);

  let ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

export const clearAllCookies = () => {
  const dtExpire = new Date();

  dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);
  setCookie(`ttp_auth_${APP_ENV}`, '', dtExpire, '/', 'tamtam.pro');
  setCookie('XSRF-TOKEN', '', dtExpire, '/', 'tamtam.pro');
  setCookie(`ttp_session_${APP_ENV}`, '', dtExpire, '/', 'tamtam.pro');
  setCookie(`ttp_community_${APP_ENV}`, '', dtExpire, '/', 'tamtam.pro');
}

export function setCookie(name, value, expires, path, domain, secure) {
  document.cookie = name + ' = ' + escape(value) + '  ' +
    ((expires === undefined) ? '' : ('; expires = ' + expires.toGMTString())) +
    ((path === undefined) ? '' : ('; path = ' + path)) +
    ((domain === undefined) ? '' : ('; domain = ' + domain)) +
    ((secure === true) ? '; secure' : '');
}

export function getSourceToken() {
  let CancelToken = axios.CancelToken;
  return CancelToken.source();
}

export function updateSourceToken(oldSourceToken, newSourceToken) {
  if (oldSourceToken) {
    oldSourceToken.cancel('Operation canceled by the user.');
  }

  return newSourceToken;
}

export function pushSourceToken(sourceName, dispatch) {
  let sourceToken = getSourceToken();

  dispatch(pushSourceTokenAction(sourceName, sourceToken));

  return sourceToken;
}

export function getCommunityDisplayName(community) {
  return (
    community.abbreviation ||
    (community.name.length <= COMMUNITY_NAME_MAX_LENGTH
      ? community.name
      : community.name.substring(0, COMMUNITY_NAME_MAX_LENGTH) + " ...")
  );
}

export function getCommunityLogoUrl(community) {
  return community && community.avatarUrl
    ? community.avatarUrl
    : COMMUNITY_DEFAULT_LOGO_URL;
}
