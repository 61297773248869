import React, { Component } from "react";
import { connect } from 'react-redux';
import InputMoment from 'input-moment';
import moment from 'moment';

import { setTTPDialogCustomData, setCurrentEmailCampaignData } from "actions";

import _ from 'i18n';
import Summary from "./Summary";
import Radio from "common/Radio";
import Target from "common/campaign/Target";
import { SVG_SEND_ICON } from "../../../services/svgIcones";

const DATE_FORMAT = "DD-MM-YYYY HH:mm";

@connect((store) => {
  return {
    auth: store.auth,
    tamtamRecipientsCount: store.campaigns.recipients.nbResult,
    opened: store.currentCampaign.email.currentSideBar === "SEND",
    manualRecipientsCount: store.currentCampaign.email.manualRecipients.length,
    attachmentsCount: store.currentCampaign.email.attachments?.length || 0,
    recipientType: store.currentCampaign.email.recipientType,
    fromName: store.currentCampaign.email.fromName,
    from: store.currentCampaign.email.from,
    isFromEmailValid: store.currentCampaign.email.fromEmailValid,
    language: store.currentCampaign.email.language,
    scheduledDeliveryAt: store.currentCampaign.email.scheduledDeliveryAt,
    approved: store.currentCampaign.email.approved,
    typePage: store.currentCampaign.email.type,
    notifyCommunityChannel: store.currentCampaign.email.notifyCommunityChannel,
    newsLetter: store.currentCampaign.email.newsLetter,
    approvalRequired: store.auth.currentSetting.mandatoryTest,
    updating: store.currentCampaign.email.updating,
    currentCampaign: store.currentCampaign.email,
    approvalTeams: store.currentCampaign.email.approvalTeams,
  }
})
export default class SendTab extends Component {

  constructor(props) {
    super(props);
    this.state = { scheduleCampaign: false, showInputMoment: false };
    this.handleCancelSchedulingDelivery = this.handleCancelSchedulingDelivery.bind(this);

    this.toggleInputMoment = (showInputMoment) => this.setState({ showInputMoment });
    this.toogleScheduleBlock = () => { this.setState({ scheduleCampaign: !this.state.scheduleCampaign }) };
  }

  componentDidMount() {
    this.props.onRef(this)
  }

  handleShowDialog = () => {
    const { dispatch, onConfirm, currentCampaign, goPrevious} = this.props;
    let themeContent = currentCampaign.mutatedContent ? currentCampaign.mutatedContent : currentCampaign.html ;
    let count = (themeContent.match(/<a[^>]*href=["']["']/g) || []).length;
    if(count > 0) {
      dispatch(setTTPDialogCustomData({
        'approvedAction': goPrevious,
        'abortAction': onConfirm,
        'message': _("You have") + " " + count + " " + _("empty link(s) in your model") + "!",
        'proceedBtnLabel': _('No, fill in the links'),
        'abortBtnLabel': _('yesSend'),
        'title': "Confirmation of sending",
        'svgIcon': SVG_SEND_ICON,
      }));
    } else {
      dispatch(setTTPDialogCustomData({
        'approvedAction': onConfirm,
        'message': _('confirmSendCampaign'),
        'proceedBtnLabel': _('yesSend'),
        'abortBtnLabel': _('no'),
        'title': "Confirmation of sending",
        'svgIcon': SVG_SEND_ICON
      }));
    }
  };

  handleCancelSchedulingDelivery() {
    this.props.dispatch(setCurrentEmailCampaignData({ scheduledDeliveryAt: '' }));
    this.toogleScheduleBlock();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.scheduledDeliveryAt) {
      this.setState({ scheduleCampaign: true });
    }
  }

  toggleChannelNotification = (notifyCommunityChannel) => {
    this.props.dispatch(setCurrentEmailCampaignData({ notifyCommunityChannel }));
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.opened || this.props.opened;
  }

  handleScheduledDeliveryAtChange = scheduledDeliveryAt => {
    this.props.dispatch(setCurrentEmailCampaignData({ scheduledDeliveryAt }));
  }

  render() {
    let { opened, isFromEmailValid, manualRecipientsCount, tamtamRecipientsCount,
      recipientType, scheduledDeliveryAt, approvalRequired, approved, updating, attachmentsCount, approvalTeams } = this.props;
    let { scheduleCampaign, showInputMoment } = this.state;

    const { loggedAs, preference, detached } = this.props.auth;
    const authorisedToSend = detached || loggedAs === "ADMIN" || (preference && preference['allowSendCampaign'] == 1);
    const recipientsCount = ('MANUAL' === recipientType) ? manualRecipientsCount : tamtamRecipientsCount;

    const approvalCondition = approvalRequired && (!approved || approved === 0) && !scheduledDeliveryAt;
    const allowSend = authorisedToSend && isFromEmailValid && recipientsCount > 0 && (!approvalRequired || (!approvalCondition && approvalTeams && approvalTeams.length > 0));

    return (
      <div id='campaign-send__tab' className={`${opened ? "" : "hide"}`}>
        <h4 className="sidebar__header">{_('sendTitle')}</h4>
        <Target count={recipientsCount} type={recipientType} />
        <div className="row">
          <Summary
            {...this.props}
            attachmentsCount={attachmentsCount}
            onSwitchChange={this.toggleChannelNotification}
          />
          <div className="small-6 row columns right-side">
            <Radio title="Send your campaign immediately" checked={!scheduleCampaign} onChange={this.handleCancelSchedulingDelivery} />
            <Radio title="Schedule updating of your campaign for a specific time" checked={scheduleCampaign} onChange={this.toogleScheduleBlock} />
            <div className={`columns ${!(scheduleCampaign || scheduledDeliveryAt) && "hide"}`}>
              <input
                placeholder={_("delivery date")}
                type="text"
                value={(scheduledDeliveryAt && scheduledDeliveryAt.format(DATE_FORMAT)) || ""}
                readOnly
                className={scheduledDeliveryAt ? "filled-box" : "empty-box"}
                onFocus={() => this.toggleInputMoment(true)}
              />
              <a className="clear text-uppercase" onClick={this.handleCancelSchedulingDelivery}>{_('cancel')}</a>
              {showInputMoment && <div className="input-moment">
                <InputMoment
                  moment={scheduledDeliveryAt || moment()}
                  onChange={this.handleScheduledDeliveryAtChange}
                  onSave={this.toggleInputMoment}
                  prevMonthIcon="icon-arrow-left"
                  nextMonthIcon="icon-arrow-right"
                />
              </div>
              }
            </div>

            <div className="columns" >
              <button className={`ttp-button ${allowSend ? 'success' : 'disabled'}`} onClick={(allowSend) ? !updating && this.handleShowDialog : null} disabled={!allowSend}>
                {updating ? _('Processing...') : _('confirm')}
              </button>
            </div>

          </div>
        </div>
      </div>
    );
  }

}
