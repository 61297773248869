const initialState = {
  allowOpenModels: false,
  modalFieldsOpened : false,
};

export const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EDITOR_ALLOW_OPEN_MODELS': {
      return {...state, allowOpenModels: action.allowOpenModels};
    }
    case "SET_FIELDS_EDITOR_MODAL": {
      return { ...state, modalFieldsOpened: action.modalFieldsOpened };
    }
    default:
      return state;
  }
};
