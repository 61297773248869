export const APP_ENV = process.env.REACT_APP_APP_ENV;
export const TTP_API_URL = process.env.REACT_APP_TTP_API_URL;
export const TTP_HOME_URL = process.env.REACT_APP_TTP_HOME_URL;
export const TTP_BLOG_URL = process.env.REACT_APP_TTP_BLOG_URL;

export const TTP_BE_ACCOUNTANTS_BLOG =
  process.env.REACT_APP_TTP_BE_ACCOUNTANTS_BLOG;
export const TTP_FFF_BLOG = process.env.REACT_APP_TTP_FFF_BLOG;
export const TTP_DEGANDPARTNERS_BLOG =
  process.env.REACT_APP_TTP_DEGANDPARTNERS_BLOG;
export const TTP_EMAILING_URL = process.env.REACT_APP_TTP_EMAILING_URL;
export const TTP_EVENT_URL = process.env.REACT_APP_TTP_EVENT_URL;
export const TTP_TALK_URL = process.env.REACT_APP_TTP_TALK_URL;
export const TTP_PORTAL_URL = process.env.REACT_APP_TTP_PORTAL_URL;

export const S3_FOLDER_URL = process.env.REACT_APP_S3_FOLDER_URL;
export const UNLAYER_PROJECT_ID = process.env.REACT_APP_UNLAYER_PROJECT_ID;

export const S3_FOLDER_URL_PROD = "https://s3.tamtam.pro/prod";
export const URL_HASH_KEY = "Y3YZVA6uSLVqJhAWHdsb9CsGgcBXIhmY";
