import React, { Component } from 'react'
import _ from 'i18n';
import { hideEboxWidget, showEboxWidget, setEboxWidgetStatus } from "../../../actions/actions/ebox/widget";
import { connect } from "react-redux";
import { SVG_MINUS, SVG_SEND_ICON, SVG_MAXIMIZE_ICON } from '../../../services/utils';
import { FULL_SCREEN_WIDGET } from '../../../services/ebox/widget';

const mapStateToProps = (state, ownProps) => ({
  widgetStatus: state.ebox.widget.status,
  sending: state.ebox.widget.sending,
});

const mapDispatchToProps = dispatch => ({
  hideEboxWidget: () => dispatch(hideEboxWidget()),
  showEboxWidget: () => dispatch(showEboxWidget()),
  setEboxWidgetStatus: (status) => dispatch(setEboxWidgetStatus(status)),
});
@connect(mapStateToProps, mapDispatchToProps)

export default class TopBar extends Component {

  hideWidget = (e) => {
    e.stopPropagation();
    this.props.hideEboxWidget();
  }

  minimizeWidget = () => {
    this.props.setEboxWidgetStatus('MINIMIZED');
  }

  maximizeWidget = () => {
    this.props.showEboxWidget();
  }

  toggleFullScreen = (e) => {
    const { widgetStatus } = this.props;
    e.stopPropagation();
    this.props.setEboxWidgetStatus(widgetStatus === FULL_SCREEN_WIDGET ? "OPENED" : FULL_SCREEN_WIDGET);
  }


  render() {
    const { widgetStatus, sending } = this.props;
    const isMinimized = widgetStatus === "MINIMIZED";

    return (
      <div className={`widget_top-bar ${isMinimized && 'minimized-bar'}`}
        onClick={(isMinimized) ? this.maximizeWidget : this.minimizeWidget}
      >
        <div className={`columns columns-padding flex-container align-middle`}>
          {SVG_SEND_ICON}
          <span>{sending ? _('sending in progress') + "..." : _('mail express')}</span>
        </div>
        <div className={"resize flex-container align-middle"}>
          {(isMinimized) ? <span onClick={this.maximizeWidget}>{SVG_MAXIMIZE_ICON}</span> :
            <span onClick={this.minimizeWidget}>{SVG_MINUS}</span>}
          <span onClick={this.hideWidget} className="m-l-xs icomoon icon-tt-close" />
        </div>
      </div>
    );
  }

}
