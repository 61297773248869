const initialState = {
  fetching: false,
  fetched: false,
  updating: false,
  updated: false,
  items: [],
  error: null,
  nbResult: 0,
};

const getUsersInfos = (users) => {
  return users.map((user) => ({
    ...user,
    title: user.firstName + " " + user.lastName,
  }));
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USERS_SETTINGS_PENDING": {
      return { ...state, fetching: true };
      break;
    }
    case "FETCH_USERS_SETTINGS_FULFILLED": {
      const { data, nbResult } = action.payload.data;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: getUsersInfos(data),
        nbResult: nbResult,
        filter: { ...state.filter },
      };
      break;
    }
    case "FETCH_USERS_SETTINGS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case "canceled":
          return state;
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }

      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };

      break;
    }
    case "SAVE_USERS_PENDING": {
      return { ...state, updated: false, updating: true };
      break;
    }
    case "UPDATE_USERS": {
      return {
        ...state,
        updating: false,
        updated: true,
        items: state.items.map((item) =>
          action.payload.selectedUsers.includes(item.id)
            ? {
                ...item,
                emailingAuthorisation: {
                  role: action.payload.role,
                  preferences: {
                    allowCreateCampaign: action.payload.allowCreateCampaign,
                    allowSendCampaign: action.payload.allowSendCampaign,
                  },
                },
              }
            : item,
        ),
      };
      break;
    }
    case "SAVE_USERS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updated: false,
        updating: false,
        error,
      };
      break;
    }
    case "INIT_SAVE_USERS": {
      return {
        ...state,
        updating: false,
        updated: false,
      };
      break;
    }
    case "FIX_USER_MAIN_EMAIL": {
      return {
        ...state,
      };
      break;
    }
    default:
      return state;
  }
};
