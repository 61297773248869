import React, { Component } from 'react';
import { connect } from 'react-redux';

import _ from 'i18n';
import {SVG_DELETE_ICON, SVG_EDIT_ICON} from "../../services/svgIcones";
import DIALOG_TYPES from "../../constants/TTPDialog";
import {setTTPDialogCustomData} from "../../actions/actions/params";
import {removeMailingList} from "../../actions/thunks/mailingList";
import {setMailingListData} from "../../actions/actions/mailingList";


@connect()
export default class MailingListActions extends Component {

  deleteMailingList = () => {
    const { dispatch, data: {id} } = this.props;
    dispatch(setTTPDialogCustomData({
      approvedAction: () => dispatch(removeMailingList(id)),
      message: _('Are you sure you want to delete this mailing list') + '?',
      'type': DIALOG_TYPES["ALERT"],
      'title': "Confirmation of deletion",
      'svgIcon': SVG_DELETE_ICON,
    }));
  };

  editMailingList = () => {
    const {data, closeListForm} = this.props;
    this.props.dispatch(setMailingListData(data));
    closeListForm();
  };

  render() {
    return  <div className="mailing-list-actions">
      <div className="mailing-list-actions__action edit-action" onClick={this.editMailingList} title={_("edit")}>
        {SVG_EDIT_ICON}
      </div>
      <div className="mailing-list-actions__action delete-action" onClick={this.deleteMailingList} title={_("delete")}>
        {SVG_DELETE_ICON}
      </div>
    </div>
  }
}
