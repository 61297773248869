import React, { Component } from 'react';
import shortId from 'shortid';
import {SVG_ICONS} from "../../config/Common";

class CheckBoxBtn extends Component {

  render() {
    const { onChange, disabled, checked, label, labelClass } = this.props;
    const id = this.props.id || shortId.generate();

    return (
      <div className="check-box-container">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={(!disabled && onChange) ? e => onChange(e.target.checked) : null}
        />
        <label htmlFor={id} className={`check-box ${labelClass}`} />
        <img src={"/img/icons/" + SVG_ICONS["CHECK"]} onClick={(!disabled && onChange) ? e => onChange(e.target.checked) : null} />
        <span>{label || ""}</span>
      </div>
    );
  }
}

export default CheckBoxBtn;
